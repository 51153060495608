import { BACKEND_CONTENT_TYPES } from '~api/content/types';
import {
  AviatorIcon,
  CasinoIcon,
  InplayIcon,
  JackpotIcon,
  LiveCasinoIcon,
  PromotionIcon,
  SportsIcon,
  VirtualSportsIcon,
} from '~icons/menu';
import { useAppSelector } from '~store';
import { SVGElement } from '~types/general';

export enum MENU_ICON_TYPES {
  Aviator = 'aviator',
  Casino = 'casino',
  Inplay = 'inplay',
  LiveCasino = 'live-casino',
  Sports = 'sports',
  VirtualSports = 'virtual-sports',
  Promotion = 'promotion',
  Jackpot = 'jackpot',
}

type IconsMap = {
  [key in MENU_ICON_TYPES]: SVGElement;
};

export const iconsMap: IconsMap = {
  [MENU_ICON_TYPES.Aviator]: AviatorIcon,
  [MENU_ICON_TYPES.Casino]: CasinoIcon,
  [MENU_ICON_TYPES.Inplay]: InplayIcon,
  [MENU_ICON_TYPES.LiveCasino]: LiveCasinoIcon,
  [MENU_ICON_TYPES.Sports]: SportsIcon,
  [MENU_ICON_TYPES.VirtualSports]: VirtualSportsIcon,
  [MENU_ICON_TYPES.Promotion]: PromotionIcon,
  [MENU_ICON_TYPES.Jackpot]: JackpotIcon,
};

export const useHeaderMenu = () => {
  const { menuContents } = useAppSelector((state) => state.userUIState);

  return {
    menu: menuContents[BACKEND_CONTENT_TYPES.HEADER] || [],
  };
};
