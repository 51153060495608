import { Bonuses } from '~api/bonus/types';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { BonusRuleDesktop } from '~components/molecules/UserProfile/components/BonusRules/BonusRuleDesktop';
import { BonusRuleMobile } from '~components/molecules/UserProfile/components/BonusRules/BonusRuleMobile';
import { useMedia } from '~hooks/useMedia';
interface PreparedBonus {
  title: string;
  description: string;
}

type PreparedBonuses = PreparedBonus[];

export const Rules = ({
  bonuses,
  isLoading,
}: {
  bonuses: Bonuses;
  isLoading: boolean;
}) => {
  const { isMobileOrTablet } = useMedia();
  const mappedBonuses: PreparedBonuses = bonuses
    .filter(({ remainingAmount }) => remainingAmount > 0)
    .map(({ title, description }) => {
      return {
        title,
        description,
      };
    });

  if (isLoading) {
    return (
      <Box
        fullWidth
        flexRow
        justifyCenter
        css={{
          pt: '80px',
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box
      fullWidth
      flexCol
      css={{
        overflow: 'auto',
        p: '$3',
      }}
    >
      <ScrollArea>
        {mappedBonuses.map(({ title, description }) =>
          isMobileOrTablet ? (
            <BonusRuleMobile title={title} description={description} />
          ) : (
            <BonusRuleDesktop title={title} description={description} />
          ),
        )}
      </ScrollArea>
    </Box>
  );
};
