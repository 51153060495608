import { RefObject, useEffect } from 'react';

export const useComponentObserver = (
  componentRef: RefObject<HTMLDivElement>,
  callback: (height: number) => void,
) => {
  useEffect(() => {
    if (componentRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === componentRef.current) {
            const height = entry.contentRect.height;

            callback(height);
          }
        }
      });

      observer.observe(componentRef.current);

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, []);
};
