import React, { memo, ReactNode, useMemo } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ContactUsButton } from '~components/atoms/ContactUsButton';
import { Logo } from '~components/atoms/Logo/Logo';
import { SettingsDropdown } from '~components/atoms/SettingsDropdown';
import { SignIn } from '~components/molecules/Authentication/components/sign-in/SignIn';
import { SignUp } from '~components/molecules/Authentication/components/sign-up/SignUp';
import { UserProfileBar } from '~components/molecules/UserProfile/components/UserProfileBar/UserProfileBar';
import { HeaderCloseButton } from '~components/organisms/HeaderComponent/Header/HeaderCloseButton';
import { HeaderMenu } from '~components/organisms/HeaderComponent/Header/HeaderMenu';
import { HEADER_HEIGHT, LAPTOP_HEADER_HEIGHT } from '~constants/ui';
import useIsInIframe from '~hooks/useIsInIframe';
import { useAppSelector } from '~store';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';

const StyledHeader = styled('header', {
  position: 'relative',
  fontFamily: 'inherit',
  height: HEADER_HEIGHT + 'px',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '$primaryOne',
  '@xs_sm': {
    borderBottom: '1px solid $primaryTwo',
    height: 'unset',
  },
  '@md': {
    height: LAPTOP_HEADER_HEIGHT + 'px',
  },
  variants: {
    inModal: {
      true: {
        backgroundColor: '$primaryOne',
        '@xs_sm': {
          p: '10px  0 10px 0 !important',
          borderBottom: '1px solid $primaryTwo',
        },
      },
    },
  },
});

const StyledHeaderTopWrapper = styled(Box, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$primaryOne',
});

const StyledHeaderTop = styled(Box, {
  width: '100%',
  display: 'flex',
  maxWidth: '1920px',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$primaryOne',
  p: '$3 $5',
  '@xs_sm': {
    p: '10px $5',
    height: 'unset',
  },
  variants: {
    inModal: {
      true: {
        backgroundColor: '$primaryOne',
        '@xs_sm': {
          p: '0 52px 0 $4 !important',
        },
      },
    },
  },
});

const StyledHeaderBottomWrapper = styled(Box, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$secondaryOne',
  '@xs_sm': {
    backgroundColor: '$primaryOne',
    height: 'unset',
  },
  zIndex: 1,
});

const StyledHeaderBottom = styled(Box, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1920px',
  justifyContent: 'space-between',
  backgroundColor: '$secondaryOne',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  p: '$3 $5',
  '@xs_sm': {
    borderTop: '1px solid $secondaryOne',
    backgroundColor: '$primaryOne',
    p: '10px $4',
    height: 'unset',
  },
  zIndex: 2,
});

const StyledMainBox = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '.5rem',
  div: {
    height: '44px',
    '@xs_sm': {
      height: '28px',
    },
  },
});

interface HeaderProps {
  children?: ReactNode;
  showSettings?: boolean;
  showContactUs?: boolean;
  showCloseButton?: boolean;
  inModal?: boolean;
  showMenu?: boolean;
}

export const Header = memo(
  ({
    inModal = false,
    showSettings,
    showContactUs,
    showCloseButton,
    showMenu = true,
  }: HeaderProps) => {
    const isInIframe = useIsInIframe();
    const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);

    const memoHeaderTop = useMemo(() => {
      const primary = !isUserLoggedIn ? (
        <StyledMainBox>
          <SignIn />
          <SignUp />
        </StyledMainBox>
      ) : (
        <UserProfileBar />
      );

      return (
        <StyledHeaderTopWrapper>
          <StyledHeaderTop
            inModal={inModal}
            flexRow
            justifyContentBetween
            alignCenter
            gap={2}
          >
            <Logo type="header" isHeader />
            <Box flexRow alignCenter gap={2}>
              {showContactUs && <ContactUsButton />}
              {primary}
              {showSettings && <SettingsDropdown />}
              {showCloseButton && <HeaderCloseButton />}
            </Box>
          </StyledHeaderTop>
        </StyledHeaderTopWrapper>
      );
    }, [showContactUs, showSettings, isUserLoggedIn, showCloseButton]);

    const memoHeaderBottom = useMemo(() => {
      return (
        showMenu && (
          <StyledHeaderBottomWrapper>
            <StyledHeaderBottom>
              {!inModal && !isInIframe && (
                <>
                  <HeaderMenu />
                </>
              )}
            </StyledHeaderBottom>
          </StyledHeaderBottomWrapper>
        )
      );
    }, [showMenu, isInIframe, inModal]);

    return (
      <Box
        css={{
          backgroundColor: '$primaryOne',
          gridArea: 'header',
        }}
        flexRow
        justifyCenter
        fullWidth
      >
        <StyledHeader inModal={inModal}>
          {memoHeaderTop} {memoHeaderBottom}
        </StyledHeader>
      </Box>
    );
  },
);
