import { styled } from 'stitches.config';

import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';

export const StyledText = styled(Text, {
  fontSize: '$14 !important',
  lineHeight: '$24 !important',
  fontWeight: '$medium !important',
  textAlign: 'center !important',
  variants: {
    smallFont: {
      true: {
        fontSize: '$12 !important',
        lineHeight: '$16 !important',
      },
    },
  },
});

export const StyledButton = styled(Button, {
  height: '32px !important',
  flex: 1,
  borderRadius: '$6 !important',
  defaultVariants: {
    size: 'small',
    noBorder: true,
  },
});
