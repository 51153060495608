import { styled } from 'stitches.config';

import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { Box } from '~components/atoms/Box';
import { EventNavigationLink } from '~components/atoms/EventNavigationLink';
import { StyledFavoriteIcon } from '~components/atoms/StyledFavoriteIcon';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { useLiveEventDetails } from '~components/molecules/LiveMenu/components/liveEventDetails';
import { MAIN_PAGE_TABS } from '~components/molecules/MainHighlights/helpers';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { useAppDispatch, useAppSelector } from '~store';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';
import { SportEventMainData, StoreSportEvent } from '~types/events';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getFormattedMarketsCount } from '~utils/numberUtils';
import { getParsedExtraData } from '~utils/sportHelpers';

const StyledMarketCount = styled(Tag, {
  cursor: 'pointer',
  color: '$textSecondaryOne',
  backgroundColor: '$textSecondaryOne',
  '&:hover': {
    backgroundColor: '$textPrimaryOne',
  },
});

export interface MobileEventFooterProps {
  eventData: StoreSportEvent | undefined;
  isLive?: boolean;
}

export const MobileEventFooterData = ({
  eventData,
  isLive,
}: MobileEventFooterProps) => {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { mobileMainPageTab } = useAppSelector((state) => state.mobileState);

  const {
    marketsCount,
    extraData,
    startDate,
    id: eventId = '',
    sportId,
    countryId,
    leagueId,
  } = eventData || {};
  const parsedExtraData = getParsedExtraData(extraData);

  const { formattedMatchStatus } = useLiveEventDetails(
    marketsCount,
    startDate,
    parsedExtraData,
  );
  const time = formatDateTime(startDate || '', TIME_FORMATS.TIME_12H);

  const { favoritePrematchEvents } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();

  const handleFavoriteClick = async (isEventFavorite: boolean) => {
    try {
      if (isEventFavorite) {
        await deleteFavoriteEventMutation({
          eventId,
        });
        dispatch(
          setFavoritePrematchEvents(
            favoritePrematchEvents.filter(
              (favoriteEvent) => favoriteEvent.id !== eventId,
            ),
          ),
        );
      } else {
        await addFavoriteEventMutation({
          eventId,
        });
        if (eventData) {
          dispatch(
            setFavoritePrematchEvents([
              ...favoritePrematchEvents,
              eventData as SportEventMainData,
            ]),
          );
        }
      }
    } catch (e) {
      console.error('Failed to handle favorite event', e);
    }
  };

  const isFav = !!favoritePrematchEvents?.find((favoriteEvent) => {
    return favoriteEvent.id === eventId;
  });

  return (
    <Box css={{ borderTop: '1px solid $primaryTwo' }} fullWidth>
      <Box flexRow fullWidth css={{ p: '$2' }}>
        <Box justifyContentBetween fullWidth flexRow alignCenter>
          <Text color="textSecondaryOne" level="14-20">
            {time}{' '}
            {mobileMainPageTab === MAIN_PAGE_TABS.LIVE_HIGHLIGHTS || isLive
              ? `, ${formattedMatchStatus}`
              : ``}
          </Text>
        </Box>
        <Box flexRow alignCenter gap={3}>
          {isUserLoggedIn && (
            <Box
              css={{
                color: isFav
                  ? '$pressedOne !important'
                  : '$textSecondaryOne !important',
                width: '$5',
                height: '$5',
                '&:hover': { color: '$textPrimaryOne' },
              }}
              onClick={async () => {
                await handleFavoriteClick(isFav);
              }}
            >
              <StyledFavoriteIcon width={16} height={16} />
            </Box>
          )}
          <EventNavigationLink
            eventId={eventId}
            sportId={sportId}
            countryId={countryId}
            leagueId={leagueId}
            isLive={isLive}
            onClick={() => {
              dispatch(
                setMobileActiveTab(
                  isLive ? MOBILE_MENU.LIVE : MOBILE_MENU.SPORT,
                ),
              );
            }}
          >
            <StyledMarketCount
              text={getFormattedMarketsCount(marketsCount)}
              size="small"
              css={{
                width: '32px',
                height: '14px',
                lineHeight: '$15',
              }}
            />
          </EventNavigationLink>
        </Box>
      </Box>
    </Box>
  );
};
