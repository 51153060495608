import React from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { useTranslation } from '~hooks';

interface FiltersButtonsProps {
  onReset: () => void;
  onApply: () => void;
  isDisabled?: boolean;
}

const StyledButton = styled(Button, {
  fontSize: '14px !important',
  lineHeight: '16px !important',
  fontWeight: '$bold !important',
  textAlign: 'center',
  width: '100px',
  height: '32px',
});

export const FiltersButtons = ({
  onReset,
  onApply,
  isDisabled = false,
}: FiltersButtonsProps) => {
  const { localized } = useTranslation();

  return (
    <Box flexRow gap={2}>
      <StyledButton variant="secondary" size="small" onClick={onReset}>
        {localized('transactionHistory.button.reset')}
      </StyledButton>
      <StyledButton size="small" onClick={onApply} disabled={isDisabled}>
        {localized('transactionHistory.button.show')}
      </StyledButton>
    </Box>
  );
};
