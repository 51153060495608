import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useVerifyEmailToken } from '~api/auth/authMutations';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { getParamFromQuery } from '~utils/url';

export const useEmailTokenVerify = () => {
  const { search } = useLocation();
  const { removeQueryParams } = useRouterQuery();
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const {
    verifyEmailTokenMutation,
    verifyEmailTokenIsLoading: isLoading,
    verifyEmailTokenData,
  } = useVerifyEmailToken();
  const dispatch = useAppDispatch();
  const [isRequestSent, setIsRequestSent] = useState(false);

  const verifyEmailToken = getParamFromQuery(
    search,
    QUERY_PARAMS.VERIFY_EMAIL_TOKEN,
  );

  const isSuccess = verifyEmailTokenData?.isSuccess;

  const loadVerifyEmailToken = async () => {
    try {
      await verifyEmailTokenMutation({
        token: verifyEmailToken!,
      }).unwrap();
    } catch (error) {
      console.error('Verify email token error: ', error);
    }
  };

  const handleModalClose = () => {
    removeQueryParams([QUERY_PARAMS.VERIFY_EMAIL_TOKEN]);
    dispatch(closeDialog());
  };

  useEffect(() => {
    if (verifyEmailToken && !isRequestSent) {
      loadVerifyEmailToken();
      setIsRequestSent(true);
    }

    if (!verifyEmailToken) {
      removeQueryParams([QUERY_PARAMS.VERIFY_EMAIL_TOKEN]);
    }
  }, [verifyEmailToken, isRequestSent]);

  useEffect(() => {
    return () => {
      handleModalClose();
    };
  }, []);

  return {
    verifyEmailToken,
    verifyEmailTokenData,
    isLoading,
    isMobileOrTablet,
    isSuccess,
    localized,
    handleModalClose,
  };
};
