import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BetslipItem } from '~api/betslip/types';
import { EVENT_STATUS, MARKET_STATUS } from '~constants/common';
import { RootState } from '~store';
import {
  GroupItem,
  Market,
  MarketWithEventId,
  SportEvent,
  StoreSportEvent,
} from '~types/events';

export interface EventsState {
  eventsMap: Record<string, StoreSportEvent>;
  marketsMap: Record<string, Market>;
  marketGroups: GroupItem[];
  selectedFavoriteEventId: string | null;
}

export interface EventsStateUpdate {
  eventsMap?: Record<string, StoreSportEvent>;
  marketsMap?: Record<string, Market>;
}

const initialState: EventsState = {
  eventsMap: {},
  marketsMap: {},

  marketGroups: [],
  selectedFavoriteEventId: null,
};

const addOrUpdateEvent = (state: EventsState, event: StoreSportEvent) => {
  const { id, marketIds = [] } = event;
  const existingEvent = state.eventsMap[id];
  const existingEventMarketIds = existingEvent?.marketIds || [];

  state.eventsMap[id] = existingEvent
    ? {
        ...existingEvent,
        ...event,
        marketIds: [...new Set([...marketIds, ...existingEventMarketIds])],
      }
    : event;
};

const handleMarket = (state: EventsState, market: Market) => {
  if (
    market.status === MARKET_STATUS.ACTIVE ||
    market.status === MARKET_STATUS.PLACEHOLDER
  ) {
    const existingMarket = state.marketsMap[market.id] || ({} as Market);

    if (existingMarket) {
      const existingSelections = existingMarket.selections || [];
      const selections = market.selections || [];
      const newSelections = selections.filter((selection) => {
        return !existingSelections.find(
          (existingSelection) => existingSelection.id === selection.id,
        );
      });

      const updatedSelections = existingSelections.map((existingSelection) => {
        const selection = selections.find(
          (selection) => selection.id === existingSelection.id,
        );

        return selection || existingSelection;
      });
      const resultSelections = [...updatedSelections, ...newSelections];

      state.marketsMap[market.id] = {
        ...existingMarket,
        ...market,
        selections: resultSelections,
      };
    } else {
      state.marketsMap[market.id] = market;
    }
  } else {
    delete state.marketsMap[market.id];
  }
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    addEventsWithMarkets: (
      state,
      { payload: { events = [], markets = [] } },
    ) => {
      events.forEach((event: StoreSportEvent) =>
        addOrUpdateEvent(state, event),
      );
      markets.forEach((market: Market) => handleMarket(state, market));
    },
    rewriteEvent: (state, action: PayloadAction<SportEvent>) => {
      if (!action.payload) return;

      const { id, markets, ...restEvent } = action.payload;

      state.eventsMap[id] = {
        id,
        ...restEvent,
        marketIds: markets?.map(({ id }) => id),
      };
      state.marketGroups = restEvent.marketGroups!;
      markets?.forEach((market) => handleMarket(state, market));
    },
    replaceEvents: (state, action: PayloadAction<SportEvent[]>) => {
      action.payload.forEach(({ id, markets, ...restEvent }) => {
        const { marketIds = [], ...restExistingEvent } =
          state.eventsMap[id] || {};

        if (markets) {
          state.eventsMap[id] = {
            ...restExistingEvent,
            ...restEvent,
            marketIds: [
              ...new Set([...marketIds, markets.map(({ id }) => id)]),
            ],
          } as StoreSportEvent;
          markets.forEach((market) => handleMarket(state, market));
        }
      });
    },
    addEvents: (state, { payload }) => {
      payload.forEach((event: StoreSportEvent) =>
        addOrUpdateEvent(state, event),
      );
    },
    addMarkets: (state, { payload }) => {
      payload.forEach((market: Market) => handleMarket(state, market));
    },
    updateEventExtraData: (
      state,
      action: PayloadAction<{ id: string; extraData: string }>,
    ) => {
      const { id, extraData } = action.payload;
      const currentEvent = state.eventsMap[id];

      if (currentEvent) {
        currentEvent.extraData = extraData;
      }
    },
    updateEventData: (state, action: PayloadAction<SportEvent>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, markets, status, ...restEvent } = action.payload;

      if (!id) return;

      if (
        status &&
        [
          EVENT_STATUS.ACTIVE,
          EVENT_STATUS.IN_PROGRESS,
          EVENT_STATUS.ABOUT_TO_START,
        ].includes(status)
      ) {
        const currentEvent = state.eventsMap[id || ''];

        state.eventsMap[id] = {
          id,
          ...currentEvent,
          ...restEvent,
          marketIds: currentEvent?.marketIds || [],
          status,
        } as StoreSportEvent;
      } else {
        delete state.eventsMap[id];
      }
    },
    updateEventMarketCount: (
      state,
      action: PayloadAction<{ id: string; count: number }>,
    ) => {
      const { id, count } = action.payload;
      const currentEvent = state.eventsMap[id];

      if (currentEvent) {
        currentEvent.marketsCount = count;
      }
    },
    updateEventMarket: (state, action: PayloadAction<MarketWithEventId>) => {
      const { eventId, id: marketId, status } = action.payload;

      const storeEvent = state.eventsMap[eventId];

      if (storeEvent) {
        const { marketIds = [] } = storeEvent;
        const marketIndex = marketIds.indexOf(marketId);

        if (status === MARKET_STATUS.ACTIVE) {
          if (marketIndex === -1) {
            marketIds.push(marketId);
          }
        } else {
          if (marketIndex !== -1) {
            marketIds.splice(marketIndex, 1);
          }
        }

        storeEvent.marketIds = marketIds;

        handleMarket(state, action.payload);
      }
    },
    updateBetStopEvent: (state, action: PayloadAction<string>) => {
      const eventData = state.eventsMap[action.payload];

      if (eventData) {
        const { marketIds = [] } = eventData;

        marketIds.forEach((marketId) => {
          const market = state.marketsMap[marketId];

          if (market) {
            market.status = MARKET_STATUS.SUSPENDED;
          }
        });
      }
    },
    updateMapsState: (state, action: PayloadAction<EventsStateUpdate>) => {
      const { eventsMap, marketsMap } = action.payload;

      if (eventsMap) {
        state.eventsMap = eventsMap;
      }

      if (marketsMap) {
        state.marketsMap = marketsMap;
      }
    },
    handleRemoveEvent: (state, action: PayloadAction<string>) => {
      if (state.eventsMap[action.payload]) {
        delete state.eventsMap[action.payload];
      }
    },
    setEndedEventStatus: (state, action: PayloadAction<string>) => {
      const eventData = state.eventsMap[action.payload];

      if (eventData) {
        eventData.status = EVENT_STATUS.FINISHED;
      }
    },
    setSelectedFavoriteEventId: (state, action: PayloadAction<string>) => {
      state.selectedFavoriteEventId = action.payload;
    },
    resetMapsState: () => {},
    removeMarkets: (state, action: PayloadAction<string[]>) => {
      const { events } = JSON.parse(
        localStorage.getItem('persist:betslip') || '{}',
      );
      const parsedEvents = JSON.parse(events);
      const mapperMarketIds = parsedEvents.map(
        ({ marketId }: BetslipItem) => marketId,
      );

      action.payload
        .filter((marketId) => !mapperMarketIds.includes(marketId))
        .forEach((marketId) => {
          delete state.marketsMap[marketId];
        });
    },
  },
});

export const {
  addEvents,
  replaceEvents,
  rewriteEvent,
  addEventsWithMarkets,
  updateEventMarket,
  updateEventExtraData,
  updateEventData,
  updateEventMarketCount,
  updateBetStopEvent,
  resetMapsState,
  updateMapsState,
  handleRemoveEvent,
  setEndedEventStatus,
  removeMarkets,
  setSelectedFavoriteEventId,
} = eventsSlice.actions;

export const selectEventById = createSelector(
  (state: RootState, eventId: string) => {
    const event = state.events.eventsMap[eventId];
    const markets = event?.marketIds?.map((marketId) => {
      return state.events.marketsMap[marketId];
    });

    return {
      ...event,
      markets,
    };
  },
  (event) => event,
);

export const selectEventStatusById = createSelector(
  (state: RootState, eventId: string) =>
    state.events.eventsMap[eventId]?.status,
  (status) => status,
);

export const betslipStateByEventsData = createSelector(
  (state: RootState, events: BetslipItem[]) => {
    return events.map((event) => {
      const eventData = state.events.eventsMap[event.eventId];
      const marketData = state.events.marketsMap[event.marketId];
      const selectionData = marketData?.selections?.find(
        (selection) => selection.id === event.selectionId,
      );

      return {
        ...event,
        ...eventData,
        market: {
          ...marketData,
          selection: selectionData,
        },
      };
    });
  },
  (resultData) => resultData,
);

export default eventsSlice.reducer;
