import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { RelworxPayload, RelworxResponse } from '~api/relworx/types';

export const relworxApi = createApi({
  reducerPath: 'relworxApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.RELWORX }),
  endpoints: (builder) => ({
    relworxDeposit: builder.mutation<RelworxResponse, RelworxPayload>({
      query: (body) => ({
        url: `/deposit`,
        method: 'POST',
        body,
      }),
    }),
    relworxWithdraw: builder.mutation<RelworxResponse, RelworxPayload>({
      query: (body) => ({
        url: `/withdrawal`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useRelworxWithdrawMutation, useRelworxDepositMutation } =
  relworxApi;
