import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSS } from '@stitches/react';

import { useFlutterwaveCardChargeWithAuth } from '~api/flutterwave/flutterwaveMutations';
import { ADD_PAYMENT_ACCOUNT_FORM_STATUSES } from '~constants/payments';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNewPaymentMethodFormStatus,
  setCountDown,
  setFlwRef,
  setIsLoading,
} from '~store/slices/paymentsSlice';
import { addTwoMinutesToCurrentTimeISO } from '~utils/formatDateTime';

export interface AddressState {
  zip: string;
  country: string;
  street: string;
  state: string;
  city: string;
}

export interface AddressField {
  name: string;
  placeholder?: string;
  css?: CSS;
}

const additionalZipCss = {
  mb: '$2',
  '& > input': {
    textAlign: 'center',
  },
};

const options: AddressField[] = [
  {
    name: 'zipcode',
    css: additionalZipCss,
  },
  {
    name: 'country',
  },
  {
    name: 'street',
  },
  {
    name: 'state',
  },
  {
    name: 'city',
  },
];

const defaultState = (options: AddressField[]): AddressState => {
  const state = {} as AddressState;

  options.forEach(({ name }) => {
    state[name as keyof AddressState] = '';
  });

  return state;
};

export const useCardAddressForm = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { addingNewPaymentMethodFormStatus, txRef } = useAppSelector(
    (state) => state.payments,
  );
  const { flutterwaveCardChargeWithAuthMutation } =
    useFlutterwaveCardChargeWithAuth();

  const [state, setState] = useState(defaultState(options));
  const [isValid, setIsValid] = useState(false);

  const formOptions: AddressField[] = useMemo(
    () =>
      options.map((option) => {
        return {
          ...option,
          placeholder: localized(`cardAddressFormPlaceholders.${option.name}`),
        };
      }),
    [],
  );

  const handleStateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [],
  );

  useEffect(() => {
    for (const key in state) {
      if (
        !state[key as keyof AddressState] ||
        !state[key as keyof AddressState].length
      ) {
        setIsValid(false);

        return;
      }
    }

    setIsValid(true);
  }, [state]);

  const onSubmit = async () => {
    const payload = {
      mode: addingNewPaymentMethodFormStatus,
      txRef,
      ...state,
    };

    dispatch(setIsLoading(true));
    const response = await flutterwaveCardChargeWithAuthMutation(payload);

    if (
      'data' in response &&
      response?.data?.meta?.authorization?.mode ===
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP
    ) {
      const flwRef = response?.data?.data?.flwRef;

      if (flwRef) {
        dispatch(dispatch(setCountDown(null)));
        dispatch(setFlwRef(flwRef));
        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP,
          ),
        );
        dispatch(setCountDown(addTwoMinutesToCurrentTimeISO()));
      }
    }
  };

  const onBack = () => {
    dispatch(
      setAddingNewPaymentMethodFormStatus(
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
      ),
    );
  };

  return {
    isValid,
    state,
    formOptions,
    handleStateChange,
    onBack,
    onSubmit,
    localized,
  };
};
