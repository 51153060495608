import { useMemo } from 'react';
import { Column } from 'react-table';

import { useTranslation } from '~hooks/useTranslation';
import { JackpotBetTableEvent } from '~types/jackpot';

import {
  EventNameCell,
  EventStatusCell,
  LeagueCell,
  OddsCell,
  ScoreCell,
  SelectionsCell,
} from '../components';

export const useJackpotBetslipsEventsTable = () => {
  const { localized } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: localized('jackpot.betslipHistory.league'),
        accessor: 'league',
        Cell: ({ row }) => <LeagueCell event={row.original} />,
      },
      {
        Header: localized('jackpot.betslipHistory.eventName'),
        accessor: 'eventName',
        Cell: ({ row }) => <EventNameCell event={row.original} />,
      },
      {
        Header: localized('jackpot.betslipHistory.score'),
        accessor: 'score',
        Cell: ({ row }) => <ScoreCell event={row.original} />,
      },
      {
        Header: localized('jackpot.betslipHistory.selection'),
        accessor: 'selection',
        Cell: ({ row }) => <SelectionsCell event={row.original} />,
      },
      {
        Header: localized('jackpot.betslipHistory.odds'),
        accessor: 'odds',
        Cell: ({ row }) => <OddsCell event={row.original} />,
      },
      {
        Header: localized('jackpot.betslipHistory.status'),
        accessor: 'status',
        Cell: ({ row }) => <EventStatusCell event={row.original} />,
      },
    ],
    [],
  ) as Column<JackpotBetTableEvent>[];

  return {
    columns,
  };
};
