import { useEffect } from 'react';

import { useLazyGetFavoriteTournaments } from '~api/sportEvent/sportEventQueries';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setFavoriteTournaments,
  setIsTopTournamentsLoaded,
} from '~store/slices/sportGroupsSlice';
import { SPORT_MENUS } from '~types/sportMenus';

export const useGetSportGroups = () => {
  const dispatch = useAppDispatch();
  const { menu } = useQueryParams();
  const { lazyGetFavoriteTournamentsQuery } = useLazyGetFavoriteTournaments();
  const { isFavoriteTournamentsLoaded } = useAppSelector(
    (state) => state.sportGroupsState,
  );
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const menuParam = menu || SPORT_MENUS.PREMATCH;

  useEffect(() => {
    if (
      menuParam === SPORT_MENUS.PREMATCH &&
      isUserLoggedIn &&
      !isFavoriteTournamentsLoaded
    ) {
      const loadFavoriteTournaments = async () => {
        const favoriteTournaments =
          await lazyGetFavoriteTournamentsQuery().unwrap();

        dispatch(setFavoriteTournaments(favoriteTournaments));
        dispatch(setIsTopTournamentsLoaded(true));
      };

      loadFavoriteTournaments();
    }
  }, [isUserLoggedIn, menuParam, isFavoriteTournamentsLoaded]);
};
