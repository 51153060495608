import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SportEventState {
  searchPerformed: boolean;
  searchQuery: string;
}

const initialState: SportEventState = {
  searchPerformed: false,
  searchQuery: '',
};

export const sportEventSlice = createSlice({
  name: 'sportEvent',
  initialState,
  reducers: {
    changeSearchPerformed: (state, action: PayloadAction<boolean>) => {
      state.searchPerformed = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { changeSearchPerformed, setSearchQuery } =
  sportEventSlice.actions;

export default sportEventSlice.reducer;
