import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

export const BonusRuleMobile = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Box
      flexCol
      gap={2}
      css={{
        mb: '$2',
        background: '$primaryTwo',
        p: '$2 $3',
        borderRadius: '$8',
      }}
    >
      <Text
        css={{
          px: '$3',
        }}
        level="12-20"
        color="accentPrimaryTwo"
      >
        {title}
      </Text>
      {!!description && (
        <Box
          css={{
            background: '$secondaryTwo',
            borderRadius: '$8',
          }}
        >
          <Box
            flexRow
            gap={2}
            alignCenter
            css={{
              p: '$2 $3',
              color: '$textPrimaryOne',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
