import {
  useLazyGetBannerByGroupNameQuery,
  useLazyGetInfoPageQuery,
  useLazyGetMenuContentQuery,
} from './contentApi';

export const useLazyGetMenuContent = () => {
  const [query, { data, error, isLoading }] = useLazyGetMenuContentQuery();

  return {
    lazyGetMenuContentQuery: query,
    lazyGetMenuContentData: data,
    lazyGetMenuContentError: error,
    lazyGetMenuContentIsLoading: isLoading,
  };
};

export const useLazyGetBannerByGroupName = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetBannerByGroupNameQuery();

  return {
    lazyGetBannerByGroupNameQuery: query,
    lazyGetBannerByGroupNameData: data,
    lazyGetBannerByGroupNameError: error,
    lazyGetBannerByGroupNameIsLoading: isLoading,
  };
};

export const useLazyGetInfoPage = () => {
  const [query, { data, error, isFetching }] = useLazyGetInfoPageQuery();

  return {
    lazyGetInfoQuery: query,
    lazyGetInfoData: data,
    lazyGetInfoQueryError: error,
    lazyGetInfoQueryIsFetching: isFetching,
  };
};
