import { StoreSportEvent } from '~types/events';
import { SoccerExtraData } from '~types/sportExtraData';
import { getSoccerDetails } from '~utils/sportHelpers';

import { useSportScoreboard } from './useSportScoreboard';

export const useSoccerScoreboard = (
  restEventData: Omit<StoreSportEvent, 'extraData'>,
  parsedExtraData: SoccerExtraData | null,
) => {
  const {
    sportName,
    countryName,
    leagueName,
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    isMatchStarted,
    formattedStartDate,
  } = useSportScoreboard(restEventData, parsedExtraData);

  const { time } =
    (parsedExtraData && getSoccerDetails(parsedExtraData, true)) || {};

  return {
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    time,
    sportName,
    countryName,
    leagueName,
    isMatchStarted,
    formattedStartDate,
  };
};
