import { ExtraData } from '~types/sportExtraData/ExtraData';

export const useTwoTeamScore = (extraData: ExtraData | null) => {
  let homeTeamScore = 0;
  let awayTeamScore = 0;

  if (extraData) {
    if (extraData.HomeScore >= 0 && extraData.AwayScore >= 0) {
      homeTeamScore = extraData.HomeScore || 0;
      awayTeamScore = extraData.AwayScore || 0;
    } else if (
      'Statistics' in extraData &&
      extraData.Statistics?.TotalStatistics
    ) {
      const [homeTeam, awayTeam] = extraData.Statistics.TotalStatistics;

      homeTeamScore = homeTeam?.HomeAway || 0;
      awayTeamScore = awayTeam?.HomeAway || 0;
    }
  }

  return { homeTeamScore, awayTeamScore };
};
