import { memo, useMemo } from 'react';

import { ToggleGroup } from '~components/atoms/ToggleGroup';
import {
  ToggleGroupItemComponent,
  ToggleType,
} from '~components/atoms/ToggleMarket/ToggleGroupItemComponent';
import { useAppSelector } from '~store';

interface ToggleSportMarketProps {
  mainMarketsSelected: Record<number, number>;
  sportId: number;
  noMargin?: boolean;
  handleValueChange?: (marketId: string) => void;
  variant?: ToggleType;
}

export const ToggleEventMarkets = memo(
  ({
    mainMarketsSelected,
    sportId,
    noMargin,
    handleValueChange,
    variant = 'market',
  }: ToggleSportMarketProps) => {
    const { mainMarkets } = useAppSelector((state) => state.mainMarkets);

    const sportMainMarketSelected = mainMarketsSelected[sportId] || 0;
    const defaultMainMarketId =
      mainMarkets.find(({ id }) => id === sportId)?.markets[0]?.id || 0;

    const marketsItems = useMemo(() => {
      const _markets =
        mainMarkets.find(({ id }) => id === sportId)?.markets ?? [];

      return _markets.map((market) => (
        <ToggleGroupItemComponent
          type={variant}
          key={market.id}
          market={market}
          marketSelected={mainMarketsSelected}
          sportId={sportId}
        />
      ));
    }, [mainMarkets, mainMarketsSelected, sportId]);

    const mainMarketValue =
      sportMainMarketSelected.toString() || defaultMainMarketId.toString();

    if (!marketsItems?.length) return null;

    return (
      <ToggleGroup
        type="single"
        variant={variant}
        onValueChange={handleValueChange}
        value={mainMarketValue}
        rovingFocus={false}
        loop={false}
        css={{
          margin: noMargin ? '0' : '$2 0',
        }}
      >
        {marketsItems}
      </ToggleGroup>
    );
  },
);
