import React, { MouseEvent } from 'react';
import { CSS } from 'stitches.config';

import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useFormatAmountWithCurrency, useTranslation } from '~hooks';

interface CashOutButtonProps {
  handleCashOutClick: (e: MouseEvent) => void;
  css?: CSS;
  textLevel?: string;
  textIsBold?: boolean;
  cashOutAmount: number;
}

export const CashOutButton = ({
  handleCashOutClick,
  css,
  textLevel,
  textIsBold = false,
  cashOutAmount,
}: CashOutButtonProps) => {
  const { localized } = useTranslation();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  return (
    <Button
      variant="light"
      fullWidth
      onClick={handleCashOutClick}
      css={{
        gap: '$1',
        p: '$1 $2',
        '&:hover': {
          '> .cashoutLabel, > .cashoutAmount': {
            color: '$textButtons',
          },
        },
        ...css,
      }}
    >
      <Text
        className="cashoutLabel"
        color="textPrimaryOne"
        level={textLevel || '14-20'}
        fontWeight={textIsBold ? 'bold' : 'normal'}
      >
        {localized('betslip.cashOut') + ':'}
      </Text>
      <Text
        className="cashoutAmount"
        level={textLevel || '14-20'}
        color="yellowPrimary"
        fontWeight={textIsBold ? 'bold' : 'normal'}
      >
        {getCommaSeparatedAmount(cashOutAmount)}
      </Text>
    </Button>
  );
};
