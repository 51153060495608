import React from 'react';

import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { StyledSearchResultWrapper } from '~components/molecules/GamesModalFilters/components/styled.components';
import { useSearchModalParams } from '~components/molecules/GamesModalFilters/hooks/useSearchModalParams';
import { GamesSlider } from '~components/molecules/GamesSlider';
import { ProvidersList } from '~components/molecules/ProvidersList';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import {
  selectMostLikedGames,
  selectMostSearchedGames,
} from '~store/slices/gamesSlice';
import { Providers } from '~types/providers';

interface SearchDefaultStateProps {
  filteredDefaultProviders: Providers;
}

export const SearchDefaultState = ({
  filteredDefaultProviders,
}: SearchDefaultStateProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet, isLargeDesktop, isLaptopOrDesktop } = useMedia();
  const mostSearchedGames = useAppSelector(selectMostSearchedGames);
  const mostLikedGames = useAppSelector(selectMostLikedGames);

  const {
    popularSearchesSlidesPerView,
    popularSearchesSlideHeight,
    mostLikedSlidesPerView,
    mostLikedSearchesSlideHeight,
  } = useSearchModalParams();

  return (
    <>
      <GamesSlider
        isSearch
        css={{
          borderBottom: '1px solid $secondaryTwo',
          p: '$4 $5',
          '@md': {
            p: '$3 $4',
          },
          '@xs_sm': {
            p: '$4',
          },
        }}
        titleLevel={isMobileOrTablet ? '14-20' : '16-28'}
        games={mostSearchedGames}
        slidesPerView={popularSearchesSlidesPerView}
        slideHeight={popularSearchesSlideHeight}
        label={localized('casino.mostPupularSearches')}
        withContainer={!isMobileOrTablet}
        withDots={isMobileOrTablet}
        gameCardVariant={isLaptopOrDesktop || isLargeDesktop ? 'xs' : undefined}
      />
      <Separator
        verticalSpace={0}
        css={{
          background: '$primaryTwo',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <StyledSearchResultWrapper>
        <Text
          level={isMobileOrTablet ? '14-20' : '16-28'}
          textTransform="uppercase"
          css={{
            pl: '$1',
            pb: '$3',
            '@md': {
              pb: '$3',
            },
          }}
        >
          {localized('casino.popularProviders')}
        </Text>
        <ProvidersList providers={filteredDefaultProviders} />
      </StyledSearchResultWrapper>
      <Separator
        verticalSpace={0}
        css={{
          background: '$primaryTwo',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <GamesSlider
        isSearch
        css={{
          p: '$4 $5',
          '@md': {
            p: '$3 $4',
          },
          '@xs_sm': {
            p: '$4',
          },
        }}
        titleLevel={isMobileOrTablet ? '14-20' : '16-28'}
        games={mostLikedGames}
        slidesPerView={mostLikedSlidesPerView}
        slideHeight={mostLikedSearchesSlideHeight}
        label={localized('casino.mostLiked')}
        withContainer={!isMobileOrTablet}
        withDots={isMobileOrTablet}
        gameCardVariant={isLaptopOrDesktop || isLargeDesktop ? 'xs' : undefined}
        colSpan={2}
      />
    </>
  );
};
