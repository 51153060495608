import React, { useEffect } from 'react';

import { Box } from '~components/atoms/Box';
import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  nigeriaPaymentMethodToggleButtons,
  PAYMENT_PROVIDER,
} from '~constants/payments';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setActivePaymentTab,
  setAddingNewPaymentMethodFormStatus,
  setSelectedNigeriaMethod,
} from '~store/slices/paymentsSlice';

export const PaymentMethodType = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedNigeriaDepositProvider, selectedNigeriaMethod } =
    useAppSelector((state) => state.payments);

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        setActivePaymentTab({
          paymentProvider:
            selectedNigeriaDepositProvider === 'flutterwave'
              ? PAYMENT_PROVIDER.FLUTTERWAVE
              : PAYMENT_PROVIDER.PAYSTACK,
          paymentType: USER_PAYMENT_TABS.DEPOSIT,
        }),
      );
    }, 50);
  }, []);

  return (
    <Box
      css={{
        p: '$3',
        borderBottom: '1px solid $secondaryTwo',
        '@xs_sm': {
          borderBottom: 'none',
          p: '0 0 $2',
        },
      }}
    >
      <ToggleGroup
        type="single"
        variant="payment"
        onValueChange={(value) => {
          dispatch(
            setActivePaymentTab({
              paymentProvider:
                selectedNigeriaMethod === 'flutterwave'
                  ? PAYMENT_PROVIDER.FLUTTERWAVE
                  : PAYMENT_PROVIDER.PAYSTACK,
              paymentType: USER_PAYMENT_TABS.DEPOSIT,
            }),
          );
          dispatch(setSelectedNigeriaMethod(value));
          dispatch(
            setAddingNewPaymentMethodFormStatus(
              ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
            ),
          );
        }}
        value={selectedNigeriaMethod}
        rovingFocus={false}
        loop={false}
        css={{
          backgroundColor: '$secondaryTwo',
        }}
      >
        {nigeriaPaymentMethodToggleButtons.map(({ Icon, label, value }) => {
          return (
            <ToggleGroupItem key={value} type="payment" value={value}>
              <Box
                flexRow
                alignCenter
                justifyCenter
                css={{
                  width: '28px',
                  height: '28px',
                  marginTop: '2px',
                }}
              >
                <Icon />
              </Box>
              <Text as="span">{localized(label)}</Text>
            </ToggleGroupItem>
          );
        })}
      </ToggleGroup>
    </Box>
  );
};
