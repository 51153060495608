import { useEffect } from 'react';

import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { resetPersonalDetailsState } from '~store/slices/personalDetailsSlice';

export const useUserProfileDialog = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.userState);

  const { mainTab } = useAppSelector((state) => state.personalDetails);

  useEffect(() => {
    return () => {
      dispatch(resetPersonalDetailsState());
    };
  }, []);

  return {
    localized,
    profile,
    mainTab,
  };
};
