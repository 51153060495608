import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

export const StyledBreakDownScoreCard = styled(Box, {
  position: 'relative',
  display: 'flex',
  gap: '$6',
  backgroundColor: '$primaryTwoOpacity',
  borderRadius: '$8',
  p: '$2 $6',
});

export const BreakdownScoreCardColumn = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
});

export const ScoreColumnCell = styled(Text, {
  defaultVariants: {
    level: 'sm-3',
    fontWeight: 'medium',
    textAlign: 'center',
  },
});

export const BreakdownScoreCardTitle = styled(ScoreColumnCell, {
  defaultVariants: {
    color: 'textPrimaryOne',
  },
});

export const BreakdownScoreCardColumnRowHome = styled(ScoreColumnCell, {
  defaultVariants: {
    color: 'greenPrimary',
  },
});

export const BreakdownScoreCardColumnRowAway = styled(ScoreColumnCell, {
  defaultVariants: {
    color: 'accentPrimaryTwo',
  },
});

export const ServerIconWrapper = styled(Text, {
  position: 'absolute',
  left: '$6',
  lineHeight: '$20 !important',
  '& *': {
    fill: '$textPrimaryOne',
  },
});

export const StyledBreakdownScore = styled(Box, {
  display: 'flex',
  gap: '$6',
});

export const BreakdownScoreColumn = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
});

export const BreakdownScoreColumnCell = styled(Text, {
  defaultVariants: {
    level: 'sm2-4',
    fontWeight: 'medium',
    textAlign: 'center',
    color: 'textPrimaryOne',
  },
});

export const BreakdownScoreColumnTitle = styled(BreakdownScoreColumnCell);

export const BreakdownScoreColumnRow = styled(BreakdownScoreColumnCell);

export const ScoreboardActionsPanel = styled(Box, {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '$primaryOneOpacity',
  p: '$3 $4 $4',
  minHeight: '46px',
});
