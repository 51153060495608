import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import {
  Banks,
  ExistingPaymentMethod,
  LimitsResponse,
  SavedBanks,
  TransactionHistoryFilter,
  TransactionResponse,
  TransactionStatusResponse,
  WithdrawalPayload,
} from '~api/transaction/types';
import { buildQueryUrl } from '~utils/buildQueryUrl';

export const transactionApi = createApi({
  reducerPath: 'transactionApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.TRANSACTION }),
  endpoints: (builder) => ({
    getAuthorizedDepositFlutterwave: builder.query<
      ExistingPaymentMethod[],
      void
    >({
      query: () => ({
        url: `/Card/flutterwave`,
        method: 'GET',
      }),
    }),
    setDefaultCardFlutterwave: builder.mutation<void, string>({
      query: (id) => ({
        url: `/Card/flutterwave/default/${id}`,
        method: 'POST',
      }),
    }),
    setDefaultCardPaystack: builder.mutation<void, string>({
      query: (id) => ({
        url: `/Card/paystack/default/${id}`,
        method: 'POST',
      }),
    }),
    deleteCard: builder.mutation<void, string>({
      query: (id) => ({
        url: `/Card/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteBank: builder.mutation<void, string>({
      query: (id) => ({
        url: `/BankAccount/${id}`,
        method: 'DELETE',
      }),
    }),
    getTransactions: builder.query<
      TransactionResponse,
      TransactionHistoryFilter | undefined
    >({
      query: (params) => {
        let url = 'Transactions/paymenthistory';

        if (params) {
          url = buildQueryUrl(url, params);
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getAllTransactions: builder.query<
      TransactionResponse,
      TransactionHistoryFilter | undefined
    >({
      query: (params) => {
        let url = 'Transactions/history';

        if (params) {
          url = buildQueryUrl(url, params);
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getTransactionStatus: builder.query<TransactionStatusResponse, string>({
      query: (status: string) => ({
        url: `Transactions/${status}/status`,
        method: 'GET',
      }),
    }),
    getAuthorizedDepositPaystack: builder.query<ExistingPaymentMethod[], void>({
      query: () => ({
        url: `/Card/paystack`,
        method: 'GET',
      }),
    }),
    setDefaultFlutterwaveBank: builder.mutation<void, string>({
      query: (id) => ({
        url: `/BankAccount/flutterwave/deposit/default/${id}`,
        method: 'POST',
      }),
    }),
    setDefaultPaystackBank: builder.mutation<void, string>({
      query: (id) => ({
        url: `/BankAccount/paystack/withdrawal/default/${id}`,
        method: 'POST',
      }),
    }),
    getWithdrawalBanks: builder.query<Banks, void>({
      query: () => ({
        url: '/Withdrawals/supportedBanks',
        method: 'GET',
      }),
    }),
    getWithdrawalSavedBanks: builder.query<SavedBanks, void>({
      query: () => ({
        url: '/BankAccount/withdrawals',
        method: 'GET',
      }),
    }),
    setDefaultWithdrawalBank: builder.mutation<void, string>({
      query: (id) => ({
        url: `/BankAccount/withdrawals/default/${id}`,
        method: 'POST',
      }),
    }),
    makeWithdrawal: builder.mutation<
      { transactionId: string },
      WithdrawalPayload
    >({
      query: (body) => ({
        url: `/Withdrawals`,
        method: 'POST',
        body,
      }),
    }),
    getWithdrawalLimits: builder.query<LimitsResponse, void>({
      query: () => ({
        url: `/Withdrawals/checkPartnerLimits`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetAuthorizedDepositFlutterwaveQuery,
  useSetDefaultCardFlutterwaveMutation,
  useSetDefaultCardPaystackMutation,
  useDeleteCardMutation,
  useDeleteBankMutation,
  useLazyGetTransactionsQuery,
  useLazyGetAllTransactionsQuery,
  useLazyGetAuthorizedDepositPaystackQuery,
  useLazyGetTransactionStatusQuery,
  useLazyGetWithdrawalBanksQuery,
  useLazyGetWithdrawalSavedBanksQuery,
  useMakeWithdrawalMutation,
  useSetDefaultWithdrawalBankMutation,
  useLazyGetWithdrawalLimitsQuery,
} = transactionApi;
