import React from 'react';
import { Row } from 'react-table';

import { BetslipHistoryDetails } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { TextWithTooltip } from '~components/atoms/TextWithTooltip';
import { Text } from '~components/atoms/Typography';
import { EVENT_STATUS } from '~constants/common';
import { useRouterQuery, useTranslation } from '~hooks';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { MATCH_STATUS_NOT_STARTED } from '~types/sportExtraData/generalExtraDataTypes';
import { SPORT_MENUS } from '~types/sportMenus';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';
import { getFormattedMatchStatus } from '~utils/matchStatusHelpers';
import parseSportDetails from '~utils/parseSportDetails';
import { getParsedExtraData } from '~utils/sportHelpers';
import { buildSearchQuery } from '~utils/url';

import { BetHistoryCellProps } from '../../types';

import { StatusCell } from './components/StatusCell';

export const BetslipHistorySportCountryInnerCell = ({
  row: {
    original: {
      league,
      event,
      extraData,
      eventId,
      startDate,
      sportId,
      eventStatus,
    },
  },
}: {
  row: Row<BetslipHistoryDetails>;
}) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const isLive = EVENT_STATUS.IN_PROGRESS === eventStatus;
  const { navigateWithHistory } = useRouterQuery();
  const parsedExtraData = getParsedExtraData(extraData);
  const matchStatus = getFormattedMatchStatus(
    parsedExtraData?.MatchStatusId,
    localized,
  );

  const handleEventClick = () => {
    const searchQuery = buildSearchQuery({
      eventId,
      menu: isLive ? SPORT_MENUS.LIVE : SPORT_MENUS.PREMATCH,
    });

    navigateWithHistory(searchQuery);
    dispatch(closeDialog());
  };

  return (
    <Box
      ellipsis
      flexRow
      alignCenter
      gap={3}
      css={{
        cursor: 'pointer',
      }}
      onClick={handleEventClick}
    >
      <Box css={{ transform: 'translateY(3px)', color: '$textSecondaryOne' }}>
        {getSportIcon(sportId, {
          width: 16,
          height: 16,
        })}
      </Box>
      <Box ellipsis flexCol>
        <TextWithTooltip text={event} />
        <TextWithTooltip text={league} />
        {!isLive ? (
          <TextWithTooltip
            text={formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
          />
        ) : (
          <Box flexRow alignCenter gap={2}>
            <Tag
              color="accentPrimaryTwo"
              text={localized('search.results.live')}
              size="small"
              css={{
                height: '14px',
                lineHeight: '$14',
              }}
            />
            <TextWithTooltip text={matchStatus} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const BetslipHistoryMarketSelectionInnerCell = ({
  row,
}: {
  row: Row<BetslipHistoryDetails>;
}) => {
  const { getFormattedOdd, odds } = useWebsiteSettings();

  return (
    <Box flexCol alignStart css={{ paddingRight: '$5' }}>
      <Text level="xxxs-2" css={{ textWrap: 'balance' }}>
        {row.original.market} -
      </Text>
      <Text level="xxxs-2" css={{ textWrap: 'balance' }}>
        {row.original.selection}
      </Text>
      <Text level="xxxs-2" color="yellowPrimary">
        {getFormattedOdd(row.original.odd, odds)}
      </Text>
    </Box>
  );
};

export const BetslipHistoryStatusInnerCell = ({
  row: {
    original: { status },
  },
}: BetHistoryCellProps) => {
  return <StatusCell status={status} isInnerTable />;
};

export const BetslipHistoryResultInnerCell = ({
  row: {
    original: { extraData, sportId },
  },
}: BetHistoryCellProps) => {
  const parsedExtraData = getParsedExtraData(extraData);
  const isStarted = parsedExtraData?.MatchStatusId !== MATCH_STATUS_NOT_STARTED;
  const periodData = parseSportDetails(extraData, sportId);

  return (
    <Box flexCol>
      {isStarted && parsedExtraData && Object.keys(parsedExtraData).length ? (
        <Text level="xxxs-2" color="yellowPrimary">
          {parsedExtraData.HomeScore}-{parsedExtraData.AwayScore}
        </Text>
      ) : null}

      {periodData && (
        <Text
          css={{
            whiteSpace: 'normal',
            maxWidth: '120px',
          }}
          level="xxxs-2"
        >
          {periodData}
        </Text>
      )}
    </Box>
  );
};
