import React from 'react';

import { Box } from '~components/atoms/Box';
import { StoreSportEvent } from '~types/events';
import { IceHockeyExtraData } from '~types/sportExtraData';

import { IceHockeyScoreboard } from '../scoreboards';

interface IceHockeyEventOverviewProps {
  restEventData: Omit<StoreSportEvent, 'extraData'>;
  parsedExtraData: IceHockeyExtraData | null;
}

export const IceHockeyEventOverview = ({
  restEventData,
  parsedExtraData,
}: IceHockeyEventOverviewProps) => {
  return (
    <>
      <IceHockeyScoreboard
        restEventData={restEventData}
        parsedExtraData={parsedExtraData}
      />
      <Box></Box>
    </>
  );
};
