export const formatNumber = (
  num: number,
  decimalPlaces: number = 2,
): string => {
  const multiplier = Math.pow(10, decimalPlaces);

  return (Math.floor(num * multiplier) / multiplier).toString();
};

export const oddFormat = (odd: number | string) => {
  if (typeof odd === 'string') {
    return odd;
  }

  return formatNumber(odd);
};

export const getFormattedMarketsCount = (marketsCount: number | undefined) => {
  return !marketsCount || marketsCount <= 0 ? '0' : `+${marketsCount}`;
};

export const formatCardNumber = (cardNumber: string): string =>
  cardNumber
    .replaceAll(' ', '')
    .replace(/(\d{4}(?!$))/g, '$1 ')
    .slice(0, 19);

export const isOdd = (number: number) => {
  return number % 2 !== 0;
};

export const getNumbersFromStr = (str: string) => {
  return str.replace(/[^\d]/gi, '');
};

export const commaSeparatedNumber = (num: number = 0, noDecimal?: boolean) => {
  return (num || 0).toLocaleString('en-US', {
    minimumFractionDigits: noDecimal ? 0 : 2,
    maximumFractionDigits: noDecimal ? 0 : 2,
  });
};

export const formatNumberToStringWithSpaces = (x?: number) => {
  if (x === 0 || (x && x < 1000)) {
    return x;
  }

  if (!x) {
    return '';
  }

  const parts = x.toString().split('.');

  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ?? '';

  return parts.join('.');
};

// Helper function to calculate factorial
export const factorial = (n: number): number => {
  if (n === 0) return 1;

  return n * factorial(n - 1);
};

// Helper function to calculate combinations (n choose k)
export const calculateCombinations = (n: number, k: number): number => {
  if (k > n) return 0;

  return factorial(n) / (factorial(k) * factorial(n - k));
};
