import React, { memo, useEffect } from 'react';
import { styled } from 'stitches.config';

import { FooterComponent } from '~components/organisms/FooterComponent/FooterComponent';
import { MobileHeaderContainer } from '~components/organisms/HeaderComponent/Header';
import { HeaderComponent } from '~components/organisms/HeaderComponent/HeaderComponent';
import { MobileContentContainer } from '~components/organisms/MobileContentContainer';
import { MobileTabComponent } from '~components/organisms/MobileTabComponent';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { usePageScrollPosition } from '~hooks/usePageScrollPosition';
import { RouteConfig } from '~routes';
import { useAppDispatch } from '~store';
import { setMobileActiveTab } from '~store/slices/mobileSlice';

const MobileLayoutContainer = styled('div', {
  height: '100%',
});

const activeMobileActiveTabsPages = [
  ROUTE_TAB_NAMES.BASE,
  ROUTE_TAB_NAMES.BETSLIP,
];

const hideMobileTabRoutesList = [
  ROUTE_TAB_NAMES.BETSLIP,
  ROUTE_TAB_NAMES.PROFILE,
  ROUTE_TAB_NAMES.TRANSACTIONS,
  ROUTE_TAB_NAMES.SETTINGS,
  ROUTE_TAB_NAMES.BONUSES,
  ROUTE_TAB_NAMES.HISTORY,
  ROUTE_TAB_NAMES.PROFILE_DETAILS,
  ROUTE_TAB_NAMES.INVITE_FRIENDS,
  ROUTE_TAB_NAMES.PAYMENTS,
  ROUTE_TAB_NAMES.GAME,
];

export const MobilePageTemplate = memo(
  ({ header, footer, component, path }: RouteConfig) => {
    const dispatch = useAppDispatch();

    usePageScrollPosition();
    const isGamePage = path === ROUTE_TAB_NAMES.GAME;

    useEffect(() => {
      if (!activeMobileActiveTabsPages.includes(path)) {
        dispatch(setMobileActiveTab(null));
      }
    }, [path]);

    const isMobileTabsVisible = !hideMobileTabRoutesList.some((route) =>
      path.includes(route),
    );

    return (
      <MobileLayoutContainer>
        {!isGamePage && (
          <MobileHeaderContainer>
            {header !== undefined ? header : <HeaderComponent path={path} />}
          </MobileHeaderContainer>
        )}
        <MobileContentContainer>{component}</MobileContentContainer>
        {footer !== undefined ? footer : <FooterComponent path={path} />}
        {isMobileTabsVisible && <MobileTabComponent />}
      </MobileLayoutContainer>
    );
  },
);
