import React from 'react';

import { Countries } from '~api/category/types';

import { CountryMenuContent } from '../CountryMenuContent';
import { useCountryMenu } from '../hooks/useCountryMenu';

interface CountryMenuProps {
  sportId: number;
  countries: Countries;
  initialCountries: Countries;
}

export const CountryMenu = ({
  sportId,
  countries,
  initialCountries = [],
}: CountryMenuProps) => {
  const {
    countryMenuRole,
    getLeaguesData,
    openCountries,
    handleOnValueChange,
  } = useCountryMenu(initialCountries, sportId.toString());

  return (
    <CountryMenuContent
      {...{
        sportId,
        initialCountries,
        countries,
        countryMenuRole,
        getLeaguesData,
        openCountries,
        handleOnValueChange,
      }}
    />
  );
};
