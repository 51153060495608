import React from 'react';

import { useTranslation } from '~hooks/useTranslation';

import { AuthTabsList } from '../AuthTabs';

export const enum SIGN_IN_TABS {
  SIGN_IN = 'SIGN_IN',
}

export const SignInTabs = [SIGN_IN_TABS.SIGN_IN];

export const SignInTabList = () => {
  const { localized } = useTranslation();

  return (
    <AuthTabsList tabs={SignInTabs} ariaLabel={localized('signIn.ariaLabel')} />
  );
};
