import { Box } from '~components/atoms/Box';
import { TabsList } from '~components/atoms/Tabs';
import {
  StyledTabsContent,
  StyledTabsRoot,
} from '~components/atoms/Tabs/styled.components';
import useIsInIframe from '~hooks/useIsInIframe';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setContentTab } from '~store/slices/personalDetailsSlice';
import { ContentsMap } from '~types/general';

import { USER_BONUSES_TABS } from '../UserProfile/components/UserProfileDialog';

import { ActiveBonusesContent } from './components/ActiveBonusesContent';
import { BonusHistory } from './components/BonusHistory';

const tabsContentMap: ContentsMap = {
  [USER_BONUSES_TABS.ACTIVE_BONUSES]: <ActiveBonusesContent />,
  [USER_BONUSES_TABS.BONUS_HISTORY]: <BonusHistory />,
};

const bonusesTabs = [
  USER_BONUSES_TABS.ACTIVE_BONUSES,
  USER_BONUSES_TABS.BONUS_HISTORY,
];

export const BonusesWrapper = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const isInIframe = useIsInIframe();
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const currentContentTab =
    contentTab === USER_BONUSES_TABS.BONUS_HISTORY
      ? USER_BONUSES_TABS.BONUS_HISTORY
      : USER_BONUSES_TABS.ACTIVE_BONUSES;

  if (!currentContentTab) {
    return null;
  }

  return (
    <StyledTabsRoot
      onValueChange={(value) => {
        dispatch(setContentTab(value as USER_BONUSES_TABS));
      }}
      value={currentContentTab as string}
      fullHeight
    >
      <Box flexCol gap={2} fullHeight>
        {isInIframe ? null : (
          <TabsList
            uppercase
            tabs={bonusesTabs}
            ariaLabel={localized('bonuses.ariaLabel')}
          />
        )}
        <StyledTabsContent value={currentContentTab as USER_BONUSES_TABS}>
          {tabsContentMap[currentContentTab as USER_BONUSES_TABS]}
        </StyledTabsContent>
      </Box>
    </StyledTabsRoot>
  );
};
