import React from 'react';

import { Box } from '~components/atoms/Box';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import { useAppSelector } from '~store';
import { selectMainMarketsBySportId } from '~store/slices/mainMarketsSlice';
import { JackpotBetEvent } from '~types/jackpot';

export const SelectionsCell = ({ event }: { event: JackpotBetEvent }) => {
  const { selections, marketName, sportId } = event;

  const mainMarkets = useAppSelector(selectMainMarketsBySportId(sportId));
  const market = mainMarkets?.markets.find(
    (market) => market.name === marketName,
  );

  if (!market) return null;

  return (
    <Box flexCol>
      {selections.map((selection) => {
        const { selectionId } = selection;
        const marketSelection = market.selections.find(
          (selection) => selection.id === selectionId,
        );

        if (!marketSelection) return null;

        return (
          <StyledTableText key={selectionId}>
            {marketSelection.name}
          </StyledTableText>
        );
      })}
    </Box>
  );
};
