import { UserProfileData } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';

import { UserGeneralInfoSection } from '../UserGeneralInfoSection';
import {
  UserProfileNavigation,
  UserProfileNavigationMobile,
} from '../UserProfileNavigation';

import {
  StyledBalanceInfoButton,
  StyledBalanceInfoButtonText,
  StyledUserProfileInput,
} from './styled.components';
import { UserProfileSidebarBalances } from './UserProfileSidebarBalances';
import { useUserProfileSidebar } from './useUserProfileSidebar';

interface UserProfileSidebarProps {
  profile: UserProfileData;
}

export const UserProfileSidebar = ({ profile }: UserProfileSidebarProps) => {
  const {
    applyPromoCodeIsLoading,
    isMobileOrTablet,
    promoCode,
    handleApplyPromoCode,
    handleDepositClick,
    handleLogout,
    handleWithdrawClick,
    localized,
    setPromoCode,
  } = useUserProfileSidebar({ profile });

  return (
    <Box
      flexCol
      css={{
        borderRadius: '$8',
        width: '288px',
        gap: '$1',
        '@xs_sm': {
          width: '100%',
          gap: '$2',
          pb: '0',
        },
      }}
    >
      <Box
        flexCol
        css={{
          gap: '$1',
          '@xs_sm': {
            gap: '$2',
          },
        }}
      >
        <Box
          flexCol
          gap={3}
          css={{
            backgroundColor: '$primaryTwo',
            borderRadius: '$8',
            p: '$3',
            '@xs_sm': {
              backgroundColor: '$primaryTwo',
            },
          }}
        >
          <UserGeneralInfoSection profile={profile} />
          <Box flexCol gap={2}>
            <UserProfileSidebarBalances />
            <Box flexRow justifyContentBetween alignCenter gap={2}>
              <StyledBalanceInfoButton fullWidth onClick={handleDepositClick}>
                <StyledBalanceInfoButtonText isButton>
                  {localized('userProfile.personalProfileInfo.deposit')}
                </StyledBalanceInfoButtonText>
              </StyledBalanceInfoButton>
              <StyledBalanceInfoButton fullWidth onClick={handleWithdrawClick}>
                <StyledBalanceInfoButtonText isButton>
                  {localized('userProfile.personalProfileInfo.withdraw')}
                </StyledBalanceInfoButtonText>
              </StyledBalanceInfoButton>
            </Box>
          </Box>
        </Box>
        {isMobileOrTablet ? (
          <UserProfileNavigationMobile />
        ) : (
          <UserProfileNavigation />
        )}
        <Box
          flexCol
          gap={3}
          css={{
            borderRadius: '$8',
            backgroundColor: '$primaryTwo',
            p: '$3',
            '@xs_sm': {
              backgroundColor: '$primaryTwo',
            },
          }}
        >
          <Text level="sm-3">
            {localized('userProfile.personalProfileInfo.havePromoCode')}
          </Text>
          <StyledUserProfileInput
            css={{ backgroundColor: '$secondaryTwo' }}
            placeholder={localized(
              'userProfile.personalProfileInfo.inputYourPromoCode',
            )}
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <Button
            size="small"
            fullWidth
            css={{
              height: '32px',
              borderRadius: '$6',
            }}
            onClick={handleApplyPromoCode}
            isLoading={applyPromoCodeIsLoading}
            disabled={!promoCode}
          >
            <Text isButton level="14-24" fontWeight="bold" textAlign="center">
              {localized('userProfile.personalProfileInfo.apply')}
            </Text>
          </Button>
        </Box>
      </Box>
      <Button
        size="small"
        variant="red"
        fullWidth
        onClick={handleLogout}
        css={{
          height: '32px',
          borderRadius: '$6',
        }}
      >
        <Text
          color="redPrimary"
          level="14-24"
          fontWeight="bold"
          textAlign="center"
        >
          {localized('userProfile.personalProfileInfo.logout')}
        </Text>
      </Button>
    </Box>
  );
};
