import { forwardRef, ReactNode } from 'react';
import { CSS } from 'stitches.config';

import { Box } from '../Box';

import {
  StyledChevron,
  StyledContent,
  StyledHeader,
  StyledTrigger,
} from './styled.components';

interface AccordionTriggerProps {
  children: ReactNode;
  openDataState?: boolean;
  mobileMenu?: boolean;
  mobileEventMenu?: boolean;
  isTransparentBackground?: boolean;
  noPadding?: boolean;
  dark?: boolean;
  primaryTwo?: boolean;
  isAlwaysRoundCorners?: boolean;
}

export const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(
  (
    {
      children,
      mobileMenu,
      openDataState,
      mobileEventMenu,
      isTransparentBackground,
      noPadding,
      dark,
      primaryTwo,
      isAlwaysRoundCorners,
      ...props
    },
    forwardedRef,
  ) => (
    <StyledHeader>
      <StyledTrigger
        {...props}
        isTransparentBackground={isTransparentBackground}
        mobileMenu={mobileMenu}
        mobileEventMenu={mobileEventMenu}
        openDataState={openDataState}
        noPadding={noPadding}
        dark={dark}
        primaryTwo={primaryTwo}
        isAlwaysRoundCorners={isAlwaysRoundCorners}
        ref={forwardedRef}
        className="trigger"
      >
        {children}
        <StyledChevron aria-hidden width={16} height={16} className="chevron" />
      </StyledTrigger>
    </StyledHeader>
  ),
);

interface AccordionContentProps {
  children: ReactNode;
  css?: CSS;
  mobileMenu?: boolean;
  mobileMenuSeparated?: boolean;
}

export const AccordionContent = forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(
  (
    { children, css, mobileMenu, mobileMenuSeparated, ...props },
    forwardedRef,
  ) => (
    <StyledContent
      {...props}
      css={css}
      ref={forwardedRef}
      mobileMenu={mobileMenu}
      mobileMenuSeparated={mobileMenuSeparated}
    >
      <Box className="contentWrapper">{children}</Box>
    </StyledContent>
  ),
);
