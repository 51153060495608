import React from 'react';
import { styled } from 'stitches.config';

import { useTranslation } from '~hooks/useTranslation';
import { SVGElement } from '~types/general';

import {
  IframeSidebarSubItem,
  IframeSidebarSubItemProps,
} from './IframeSidebarSubItem';

type Props = {
  icon: SVGElement;
  title: string;
  onClick: () => void;
  withoutBorder: boolean;
  isActive: boolean;
  subitems?: IframeSidebarSubItemProps[];
};

const StyledIframeSidebarItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '4px',
  color: '$textPrimaryOne',
  fontSize: '14px',
  '&:hover': {
    color: '$accentPrimaryTwo',
  },
});

export const IframeSidebarItem = ({
  title,
  icon: Icon,
  onClick,
  withoutBorder,
  isActive,
  subitems = [],
}: Props) => {
  const { localized } = useTranslation();

  return (
    <StyledIframeSidebarItem
      onClick={onClick}
      css={{
        borderTop: withoutBorder ? 'none' : '1px solid $primaryTwo',
        color: subitems.length
          ? '$textPrimaryOne !important'
          : isActive
            ? '$accentPrimaryTwo'
            : '$textPrimaryOne',
        fontWeight: isActive ? 500 : 400,
        cursor: subitems.length ? 'default' : 'pointer',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          height: '56px',
        }}
      >
        <Icon width={20} height={20} />
        {localized(title).toUpperCase()}
      </div>

      {subitems.map((sub) => (
        <IframeSidebarSubItem {...sub} />
      ))}
    </StyledIframeSidebarItem>
  );
};
