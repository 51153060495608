import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledGameWrapper = styled(Box, {
  borderRadius: '$8',
  background: '$primaryOne',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  '& > p': {
    textAlign: 'center',
  },
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '$8',
  },
});

export const StyledGameWrapperSkeleton = styled(StyledGameWrapper, {
  p: 0,
  background: '$primaryTwo-300',
  border: 'none',
  '&[data-col="2"]': {
    gridColumn: 'span 2',
    aspectRatio: '2/1',
  },
  '&[data-col="1"]': {
    gridColumn: 'span 1',
    aspectRatio: '1/1',
  },
});

export const StyledGameContainer = styled(StyledGameWrapper, {
  position: 'relative',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  '&[data-col="2"]': {
    gridColumn: 'span 2',
    aspectRatio: '2/1',
  },
  '&[data-col="1"]': {
    gridColumn: 'span 1',
    aspectRatio: '1/1',
  },
  variants: {
    noAspectRatio: {
      true: {
        aspectRatio: 'unset !important',
      },
    },
    sliderCard: {
      true: {
        height: 'inherit',
      },
    },
  },
});
