import { useCallback, useEffect, useState } from 'react';

import { SOCKET_TYPE } from '~socket-service/constants';
import { useAppSelector } from '~store';

import SocketEventsService from '../SocketEventsService';
import SocketLiveEventService from '../SocketLiveEventService';

type EventCallback = (data: any) => void;
type EventCallbacks = Record<string, EventCallback>;

const useSocket = (socketType: SOCKET_TYPE) => {
  const { socketEventsService, socketLiveEventsService } = useAppSelector(
    (state) => state.signalRSockets,
  );
  const [socketService, setSocketService] = useState<
    SocketLiveEventService | SocketEventsService | null
  >(null);

  useEffect(() => {
    setSocketService(
      socketType === SOCKET_TYPE.WEB
        ? socketEventsService
        : socketLiveEventsService,
    );
  }, [socketType, socketEventsService, socketLiveEventsService]);

  const [isSocketEventsServiceConnected, setIsSocketEventsServiceConnected] =
    useState(false);
  const [isUpdateEventsServiceConnected, setIsUpdateEventsServiceConnected] =
    useState(false);

  useEffect(() => {
    if (socketEventsService) {
      setIsSocketEventsServiceConnected(
        socketEventsService?.socket?.readyState === 1,
      );
    }
  }, [socketEventsService?.socket?.readyState]);

  useEffect(() => {
    if (socketLiveEventsService) {
      setIsUpdateEventsServiceConnected(
        socketLiveEventsService?.socket?.state === 'Connected',
      );
    }
  }, [socketLiveEventsService?.socket?.state]);

  const listening = useCallback(
    (eventCallbacks: EventCallbacks) => {
      if (socketService) {
        socketService && socketService.addListener(eventCallbacks);

        return () => {
          socketService && socketService.removeListener(eventCallbacks);
        };
      }

      return () => {};
    },
    [socketService],
  ) as (eventCallbacks: EventCallbacks) => () => void;

  const sendMessage = useCallback(
    (event: string | number, data?: unknown) => {
      socketService && socketService.sendMessage(event, data);
    },
    [socketService],
  );

  return {
    listening,
    sendMessage,
    isSocketReady:
      socketType === SOCKET_TYPE.WEB
        ? isSocketEventsServiceConnected
        : isUpdateEventsServiceConnected,
  };
};

export default useSocket;
