import React, { useLayoutEffect } from 'react';

import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';

import { LuckyDipTooltip } from './LuckyDipTooltip';

export const LuckyDipTooltipDialog = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const newTrail = [
      {
        label: localized('buttons.back'),
        onClickCallback: () => {
          dispatch(closeDialog());
        },
        forceCallback: true,
      },
      {
        label: localized('jackpot.luckyDipInfo'),
      },
    ];

    dispatch(setBreadcrumbTrail(newTrail));
  }, []);

  return (
    <DialogMobileContent>
      <Box flexCol>
        <Breadcrumb />
        <LuckyDipTooltip />
      </Box>
    </DialogMobileContent>
  );
};
