import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { QUERY_PARAMS, QueryParameters } from '~constants/common';

export const useQueryParams = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const defaultQueryParams: QueryParameters = {};

  searchParams.forEach((value, key) => {
    if (Object.values(QUERY_PARAMS).includes(key as QUERY_PARAMS)) {
      defaultQueryParams[key as QUERY_PARAMS] = value;
    }
  });

  const [queryParams, setQueryParams] =
    useState<QueryParameters>(defaultQueryParams);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const newQueryParams: QueryParameters = {};

    searchParams.forEach((value, key) => {
      if (Object.values(QUERY_PARAMS).includes(key as QUERY_PARAMS)) {
        newQueryParams[key as QUERY_PARAMS] = value;
      }
    });

    setQueryParams(newQueryParams);
  }, [search]);

  return queryParams;
};
