import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';

import {
  AuthorizedDepositPayload,
  FlutterwaveBanks,
  FlutterwaveCardChargePayload,
  FlutterwaveCardChargeResponse,
  FlutterwavePaymentAuth,
  FlutterwavePaymentValidate,
  FlwChargeValidateResponse,
} from './types';

export const flutterwaveApi = createApi({
  reducerPath: 'flutterwaveApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.FLUTTERWAVE }),
  endpoints: (builder) => ({
    flutterwaveCardCharge: builder.mutation<
      FlutterwaveCardChargeResponse,
      FlutterwaveCardChargePayload
    >({
      query: (body) => ({
        url: `/carddeposit`,
        method: 'POST',
        body,
      }),
    }),
    bankDepositFlutterwave: builder.mutation<
      FlutterwaveCardChargeResponse,
      FlutterwaveCardChargePayload
    >({
      query: (body) => ({
        url: `/deposit`,
        method: 'POST',
        body,
      }),
    }),
    flutterwaveCardChargeWithAuth: builder.mutation<
      FlwChargeValidateResponse,
      FlutterwavePaymentAuth
    >({
      query: ({ txRef, ...body }) => ({
        url: `/ChargeCardWithAuth/${txRef}`,
        method: 'POST',
        body,
      }),
    }),
    validateCartCharge: builder.mutation<
      FlwChargeValidateResponse,
      FlutterwavePaymentValidate
    >({
      query: (body) => ({
        url: `/ValidateChargeCard`,
        method: 'POST',
        body,
      }),
    }),
    authorizedDepositFlutterwave: builder.mutation<
      FlwChargeValidateResponse,
      AuthorizedDepositPayload
    >({
      query: (body) => ({
        url: '/ChargeCardWithToken',
        method: 'POST',
        body,
      }),
    }),
    getFlutterwaveBanks: builder.query<FlutterwaveBanks, void>({
      query: () => ({
        url: `/getbanks`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFlutterwaveCardChargeMutation,
  useFlutterwaveCardChargeWithAuthMutation,
  useValidateCartChargeMutation,
  useAuthorizedDepositFlutterwaveMutation,
  useBankDepositFlutterwaveMutation,
  useLazyGetFlutterwaveBanksQuery,
} = flutterwaveApi;
