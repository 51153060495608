import { memo, MouseEvent, useRef } from 'react';

import {
  useDeleteMarketFromFavorites,
  useSetMarketAsFavorite,
} from '~api/market/marketQueries';
import { Box } from '~components/atoms/Box';
import { StyledFavoriteIcon } from '~components/atoms/StyledFavoriteIcon';
import { Text } from '~components/atoms/Typography';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoriteMarkets } from '~store/slices/mainMarketsSlice';

import { AccordionTrigger } from './MarketsAccordion';

interface MarketsItemHeaderProps {
  name: string;
  isFavoriteMarket: boolean;
  marketId: number;
}

export const MarketsItemHeader = memo(
  ({ name, isFavoriteMarket, marketId }: MarketsItemHeaderProps) => {
    const { sportId } = useQueryParams();
    const dispatch = useAppDispatch();
    const { isUserLoggedIn } = useAppSelector((state) => state.userState);
    const { favoriteMarkets } = useAppSelector((state) => state.mainMarkets);
    const { setMarketAsFavoriteMutation } = useSetMarketAsFavorite();
    const { deleteMarketFromFavoriteMutation } = useDeleteMarketFromFavorites();
    const favoriteWrapperRef = useRef<HTMLDivElement>(null);

    const updateFavoriteList = () => {
      if (sportId) {
        const isSportAlreadyFavorite = favoriteMarkets.find(
          (favoriteMarket) => favoriteMarket.sportId === parseInt(sportId),
        );

        let updatedFavorites = [];

        if (isSportAlreadyFavorite) {
          updatedFavorites = favoriteMarkets.map((favoriteMarket) => {
            let updatedMarketList: number[] = [];

            if (favoriteMarket.sportId === parseInt(sportId)) {
              if (isFavoriteMarket) {
                updatedMarketList = favoriteMarket.marketIds.filter(
                  (ongoingMarketId) => ongoingMarketId !== marketId,
                );
              } else {
                updatedMarketList = [...favoriteMarket.marketIds, marketId];
              }

              return {
                ...favoriteMarket,
                marketIds: updatedMarketList,
              };
            }

            return favoriteMarket;
          });
        } else {
          updatedFavorites = [
            ...favoriteMarkets,
            {
              sportId: parseInt(sportId),
              marketIds: [marketId],
            },
          ];
        }

        dispatch(setFavoriteMarkets(updatedFavorites));
      }
    };

    const handleFavoriteClick = async (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (marketId && sportId) {
        if (!isFavoriteMarket) {
          await setMarketAsFavoriteMutation({
            marketId,
            sportId: parseInt(sportId),
          }).then(() => {
            updateFavoriteList();
          });
        } else {
          await deleteMarketFromFavoriteMutation({
            marketId,
            sportId: parseInt(sportId),
          }).then(() => {
            updateFavoriteList();
          });
        }
      }

      if (favoriteWrapperRef.current) {
        favoriteWrapperRef.current.blur();
      }
    };

    return (
      <AccordionTrigger>
        <Box flexRow gap={2} alignCenter>
          {isUserLoggedIn && (
            <Box
              css={{
                svg: {
                  color: isFavoriteMarket
                    ? '$pressedOne !important'
                    : '$textSecondaryOne !important',
                },
                '@xs_sm': {
                  '&:focus': {
                    'svg path': {
                      fill: '$textPrimaryOne !important',
                    },
                  },
                },
              }}
              onClick={handleFavoriteClick}
              ref={favoriteWrapperRef}
            >
              <StyledFavoriteIcon />
            </Box>
          )}
          <Text level="sm-3">{name}</Text>
        </Box>
      </AccordionTrigger>
    );
  },
);
