import { StoreSportEvent } from '~types/events';
import { ExtraData } from '~types/sportExtraData';

import { useSportScoreboard } from './useSportScoreboard';

export const useFallbackScoreboard = (
  restEventData: Omit<StoreSportEvent, 'extraData'>,
  parsedExtraData: unknown,
) => {
  const {
    sportName,
    countryName,
    leagueName,
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    isMatchStarted,
    formattedStartDate,
    isMobileOrTablet,
  } = useSportScoreboard(restEventData, parsedExtraData as ExtraData);

  return {
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    sportName,
    countryName,
    leagueName,
    isMatchStarted,
    formattedStartDate,
    isMobileOrTablet,
  };
};
