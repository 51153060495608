import { RefObject, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ArrowUpIcon } from '~icons';
import debounce from '~utils/debounce';
import { zIndex } from '~utils/zIndex';

import { StyledScrollToTopButton } from './styled.components';

interface ScrollToTopButtonProps {
  scrollableContainerRef: RefObject<HTMLDivElement>;
  showButtonAtScroll?: number;
  elevation?: number;
}

export const ScrollToTopButton = ({
  scrollableContainerRef,
  showButtonAtScroll = 500,
  elevation = zIndex.fixed,
}: ScrollToTopButtonProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = debounce(400, () => {
      if (
        scrollableContainerRef.current &&
        scrollableContainerRef.current.scrollTop > showButtonAtScroll
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });

    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.removeEventListener(
          'scroll',
          handleScroll,
        );
      }
    };
  }, [scrollableContainerRef, showButtonAtScroll]);

  const scrollToTop = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {createPortal(
        <StyledScrollToTopButton
          onClick={scrollToTop}
          css={{
            zIndex: elevation,
          }}
        >
          <ArrowUpIcon width={20} height={20} />
        </StyledScrollToTopButton>,
        document.body,
      )}
    </>
  );
};
