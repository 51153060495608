import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledCategoryItem = styled(Box, {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
  position: 'relative',
  span: {
    display: 'inline-block',
    textAlign: 'center',
    maxWidth: '60px',
  },
  svg: {
    width: '36px',
    height: '36px',
    color: '$textSecondaryOne',
  },
  '& > div:nth-child(1)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '$10',
    width: '60px',
    height: '60px',
    background: '$secondaryOne',
    border: '1px solid transparent',
    '@xs_sm': {
      background: '$secondaryOne',
    },
  },
  '@xs_sm': {
    backgroundColor: 'transparent',
    span: {
      maxWidth: '54px',
    },
    svg: {
      width: '16px',
      height: '16px',
    },
  },
  '&[data-active="true"]': {
    position: 'relative',
    '@xs_sm': {
      position: 'sticky',
    },
    right: 0,
    left: 0,
    zIndex: 1,
    background: '$primaryOne',
    svg: {
      color: '$accentPrimaryTwo !important',
    },
    span: {
      fontWeight: '$bold',
      color: '$accentPrimaryTwo !important',
    },
    '& > div:nth-child(1)': {
      border: '1px solid $accentSecondaryTwo',
    },
  },
  '&[data-active="false"]&:hover': {
    svg: {
      color: '$hoverPrimary',
    },
    span: {
      color: '$hoverPrimary',
    },
    '& > div:nth-child(1)': {
      border: '1px solid $hoverPrimary',
    },
  },
  variants: {
    mobile: {
      true: {
        padding: '$2 $4',
        svg: {
          width: '22px',
          height: '22px',
        },
        '& > div:nth-child(1)': {
          width: '40px',
          height: '40px',
        },
      },
    },
  },
});

export const StyledFilterButton = styled(Box, {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$secondaryOne',
  padding: '10px',
  gap: '$1',
  borderRadius: '$10',
  '*': {
    whiteSpace: 'nowrap',
    color: '$textSecondaryOne !important',
  },
  '&:hover': {
    '*': {
      color: '$hoverPrimary !important',
    },
    outline: '1px solid $hoverPrimary',
  },
  '&:active': {
    outline: '1px solid $pressedOne',

    '*': {
      color: '$pressedOne !important',
    },
  },
  '& > svg': {
    width: '$5',
    height: '$5',
  },
  variants: {
    mobile: {
      true: {
        background: '$secondaryOne',
        borderRadius: '$4',
        minWidth: '40px',
        height: '28px',
        '& > svg': {
          width: '$4',
          height: '$4',
        },
      },
    },
    isActive: {
      true: {
        '*': {
          color: '$accentPrimaryOne !important',
        },
      },
    },
  },
});
