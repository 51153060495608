import React from 'react';

import { Box } from '~components/atoms/Box';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { FiltersButtons } from '~components/atoms/FiltersButtons';
import { Select } from '~components/atoms/Select';
import { useTranslation } from '~hooks/useTranslation';

import { useTransactionHistoryFilter } from '../hooks/useTransactionHistoryFilter';

export const TransactionHistoryFilter = () => {
  const { localized } = useTranslation();
  const {
    transactionStatus,
    transactionType,
    range,
    statusesOptions,
    typesOptions,
    onRangeChange,
    onTransactionStatusChange,
    onTransactionTypeChange,
    onReset,
    onApply,
  } = useTransactionHistoryFilter();

  return (
    <Box flexRow justifyContentBetween alignCenter>
      <Box flexRow gap={2}>
        <Box flexRow gap={2}>
          <DateRangePicker
            isFutureDatesDisabled
            onOkRange={(dates) => onRangeChange(dates)}
            value={range}
          />
        </Box>
        <Select
          placeholder={localized('transactionHistory.allTypes')}
          value={transactionType?.toString() ?? 'All Types'}
          isCustomContentWidth
          contentWidth={100}
          onChange={(value) => onTransactionTypeChange(value as string)}
          options={typesOptions}
          css={{
            borderRadius: '$8',
            backgroundColor: '$secondaryTwo',
          }}
          contentCss={{
            backgroundColor: '$secondaryTwo',
          }}
        />
        <Select
          placeholder={localized('transactionHistory.allStatuses')}
          isCustomContentWidth
          contentWidth={100}
          value={transactionStatus?.toString() ?? 'All Statuses'}
          onChange={(value) => onTransactionStatusChange(value as string)}
          options={statusesOptions}
          css={{
            borderRadius: '$8',
            backgroundColor: '$secondaryTwo',
          }}
          contentCss={{
            backgroundColor: '$secondaryTwo',
          }}
        />
      </Box>
      <FiltersButtons onReset={onReset} onApply={onApply} />
    </Box>
  );
};
