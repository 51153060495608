import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledWrapper = styled(Box, {
  display: 'flex',
  width: '100%',
  overflow: 'auto',
  '@xs_sm': {
    height: 'auto',
  },
});

export const StyledPage = styled(Box, {
  display: 'grid',
  backgroundColor: '$primaryTwo',
  gridTemplateColumns: '324px 1fr 324px',
  gridTemplateAreas: `
          'sidebar content rightSidebar'
        `,
  gap: '$1',
  maxWidth: '1920px',
  width: '100%',
  '@md': {
    gridTemplateColumns: '286px 1fr 286px',
  },
  variants: {
    isJackpot: {
      true: {
        '@md': {
          gridTemplateColumns: '324px 1fr',
          gridTemplateAreas: 'sidebar content',
        },
      },
    },
    isMultiView: {
      true: {
        pl: '$1',
        gridTemplateColumns: '1fr 324px',
        gridTemplateAreas: `
          'content rightSidebar'
        `,
        '@md': {
          gridTemplateColumns: '1fr 286px',
        },
      },
    },
  },
});

export const ModalPagesStyledContainer = styled(Box, {
  p: '65px $3 65px $3',
  minHeight: '100vh',
  backgroundColor: '$secondaryOne',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});
