import { useMemo } from 'react';

import { BetslipItems } from '~api/betslip/types';
import { SportEventItem, SportEventItems } from '~api/sportEvent/types';
import { UseBetslipStakesReturn } from '~components/molecules/Betslip/hooks/useBetslipStakes';
import { useAppSelector } from '~store';
import { Market } from '~types/events';

const getMetaMessageData = (
  events: BetslipItems,
  eventsData: SportEventItems,
  betErrorCode: number | null,
  betErrorEvent: string | null,
) => {
  const { eventId, marketId, selectionId } = events.find(
    (event) => event.eventId === betErrorEvent,
  ) || { eventId: '', marketId: '', selectionId: '' };

  if (!eventId || !marketId || !selectionId) return [];

  const event = eventsData.find(
    (eventData) => eventData.id === eventId,
  ) as SportEventItem;
  const market = event?.markets.find(
    (marketData) => marketData.id === marketId,
  ) as Market;
  const eventName = event?.name || '';
  const { name: marketName, selections } = market || {
    name: '',
    selections: [],
  };
  const { name: selectionName } = selections.find(
    (selection) => selection.id === selectionId,
  ) || { name: '' };

  switch (betErrorCode) {
    case 520:
      return [eventName, marketName];
    case 521:
    case 522:
    case 523:
      return [eventName, marketName, selectionName];
    default:
      return [];
  }
};

interface UseBetslipErrorMessageProps {
  stakeProps: UseBetslipStakesReturn;
}

export const useBetslipErrorMessage = ({
  stakeProps,
}: UseBetslipErrorMessageProps): string[] => {
  const { duplicateBetsCountLimit = 0 } = useAppSelector(
    (state) => state.settings,
  );

  return useMemo(() => {
    if (!stakeProps) return [];
    const {
      betErrorCode,
      betErrorEvent,
      betErrorMessage,
      events,
      eventsData,
      localized,
      localizedError,
    } = stakeProps;

    if (!betErrorCode) {
      return [localizedError('betslip.error')];
    }

    if (betErrorEvent) {
      const metaMessageData = getMetaMessageData(
        events,
        eventsData,
        betErrorCode,
        betErrorEvent,
      );

      if (metaMessageData.length === 0) {
        return [localizedError('betslip.error')];
      }

      return [
        localized(`betslip.errorCode.${betErrorCode}`),
        ...metaMessageData,
      ];
    }

    if (betErrorMessage === 'userExceededDuplicateBetsLimit') {
      return [
        localizedError('betslip.errors.userExceededDuplicateBetsLimit', {
          max: duplicateBetsCountLimit,
        }),
      ];
    }

    if (betErrorMessage === 'applicationMaintenance') {
      return [localizedError('betslip.errors.applicationMaintenance')];
    }

    return [localizedError('betslip.error')];
  }, [duplicateBetsCountLimit, stakeProps]);
};
