import React, { Fragment } from 'react';
import { useTable, UseTableOptions } from 'react-table';

import { TableHeader } from '~components/molecules/BetslipHistorySection/components/TableHeader';
import {
  StyledCellComponent,
  StyledExpandedTable,
  StyledInnerTableTBody,
  StyledTableRow,
} from '~components/molecules/BetslipHistorySection/styled.components';
import { JACKPOT_SELECTION_STATUS } from '~constants/jackpot';
import { JackpotBetEvent } from '~types/jackpot';

import { useJackpotBetslipsEventsTable } from './hooks/useJackpotBetslipsEventsTable';

export const columnWidths: Record<number, string> = {
  0: '200px',
  1: '200px',
  2: '66px',
  3: '87px',
  4: '64px',
};

export const JackpotBetslipsEventsTable = ({
  events,
}: {
  events: JackpotBetEvent[];
}) => {
  const { columns } = useJackpotBetslipsEventsTable();

  const {
    getTableProps: getInnerTableProps,
    getTableBodyProps: getInnerTableBodyProps,
    headerGroups,
    rows: innerRows,
    prepareRow: prepareInnerRow,
  } = useTable({
    columns,
    data: events || [],
  } as UseTableOptions<JackpotBetEvent>);

  return (
    <StyledExpandedTable {...getInnerTableProps()} className="expandedTable">
      <TableHeader
        headerGroups={headerGroups}
        columnWidths={columnWidths}
        customStyles={{
          color: '$textSecondaryOne',
          backgroundColor: '$primaryTwo',
          textAlign: 'left',
          '&:first-child': {
            paddingLeft: '$3',
          },
        }}
      />
      <StyledInnerTableTBody {...getInnerTableBodyProps()}>
        {innerRows.map((row, rowIndex) => {
          prepareInnerRow(row);

          return (
            <Fragment key={rowIndex}>
              <StyledTableRow {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => {
                  const { selections } = row.original;
                  const isStatusVoid = selections.some(
                    (selection) =>
                      selection.status === JACKPOT_SELECTION_STATUS.REFUND,
                  );

                  return (
                    <td
                      {...cell.getCellProps()}
                      key={`cell-${cellIndex}`}
                      className={isStatusVoid ? 'void' : ''}
                      style={{
                        height: 'inherit',
                        maxWidth: columnWidths[cellIndex]
                          ? columnWidths[cellIndex]
                          : 'none',
                      }}
                    >
                      <StyledCellComponent>
                        {cell.render('Cell')}
                      </StyledCellComponent>
                    </td>
                  );
                })}
              </StyledTableRow>
            </Fragment>
          );
        })}
      </StyledInnerTableTBody>
    </StyledExpandedTable>
  );
};
