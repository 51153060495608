import { useRef } from 'react';

import {
  useBalanceChangePredictionMutation,
  useCashOutMutation,
  useCreateJackpotBetMutation,
  useOpenBetMutation,
} from '~api/betslip/betslipApi';
import { BalancePredictionPayload } from '~api/betslip/types';
import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

export const useOpenBet = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useOpenBetMutation();

  return {
    openBetMutation: mutation,
    openBetData: data,
    openBetError: error,
    openBetIsError: isError,
    openBetErrorCode: getErrorCode(error, isError),
    openBetErrorMessage: getErrorMessage(error),
    openBetIsSuccess: isSuccess,
    openBetIsLoading: isLoading,
  };
};

export const useCashOutRequest = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useCashOutMutation();

  return {
    cashOutMutation: mutation,
    cashOutData: data,
    cashOutError: error,
    cashOutIsError: isError,
    cashOutErrorCode: getErrorCode(error, isError),
    cashOutErrorMessage: getErrorMessage(error),
    cashOutIsSuccess: isSuccess,
    cashOutIsLoading: isLoading,
  };
};

export const useBalanceChangePrediction = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useBalanceChangePredictionMutation();

  const executeMutation = (params: BalancePredictionPayload) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    return mutation({
      ...params,
      signal: abortControllerRef.current.signal,
    });
  };

  return {
    balanceChangePredictionMutation: executeMutation,
    balanceChangePredictionData: data,
    balanceChangePredictionError: error,
    balanceChangePredictionIsError: isError,
    balanceChangePredictionErrorCode: getErrorCode(error, isError),
    balanceChangePredictionErrorMessage: getErrorMessage(error),
    balanceChangePredictionIsSuccess: isSuccess,
    balanceChangePredictionIsLoading: isLoading,
  };
};

export const useCreateJackpotBet = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useCreateJackpotBetMutation();

  return {
    createJackpotBetMutation: mutation,
    createJackpotData: data,
    createJackpotBetError: error,
    createJackpotBetIsError: isError,
    createJackpotBetErrorCode: getErrorCode(error, isError),
    createJackpotBetErrorMessage: getErrorMessage(error),
    createJackpotBetIsSuccess: isSuccess,
    createJackpotBetIsLoading: isLoading,
  };
};
