import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Bonuses } from '~api/bonus/types';

const initialState = {
  bonuses: [] as Bonuses,
  isBonusesLoading: false,
};

export const bonusesSlice = createSlice({
  name: 'bonuses',
  initialState,
  reducers: {
    setBonuses: (state, action: PayloadAction<Bonuses>) => {
      state.bonuses = action.payload;
    },
    setIsBonusesLoading: (state, action: PayloadAction<boolean>) => {
      state.isBonusesLoading = action.payload;
    },
  },
});

export const { setBonuses, setIsBonusesLoading } = bonusesSlice.actions;

export default bonusesSlice.reducer;
