import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_CONNECTED_STATUS,
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';

export const useLiveEventConnection = ({ loaded }: { loaded: boolean }) => {
  const { eventId } = useQueryParams();

  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const [socket, setSocket] = useState<HubConnection>();

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  useEffect(() => {
    if (
      loaded &&
      eventId &&
      socket &&
      socket?.state === SIGNAL_R_SOCKET_CONNECTED_STATUS
    ) {
      socket?.invoke(
        SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_FIXTURE_MESSAGE,
        eventId,
      );
    }

    return () => {
      if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
        socket?.invoke(SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_FIXTURE_MESSAGE);
      }
    };
  }, [socket, eventId, loaded]);
};
