import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TransactionHistoryFilter, Transactions } from '~api/transaction/types';
import { RangeValue } from '~components/atoms/DateRangePicker';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '~types/transactions';

export interface TransactionHistoryState {
  transactionType: TRANSACTION_TYPE | null;
  transactionStatus: TRANSACTION_STATUS | null;
  range?: RangeValue | null;
  transactions: Transactions;
  filterParams: TransactionHistoryFilter;
  isLoaded: boolean;
}

const initialState: TransactionHistoryState = {
  transactionType: null,
  transactionStatus: null,
  range: null,
  transactions: [],
  filterParams: {} as TransactionHistoryFilter,
  isLoaded: false,
};

export const transactionHistorySlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTransactionType: (
      state,
      action: PayloadAction<TRANSACTION_TYPE | null>,
    ) => {
      state.transactionType = action.payload;
    },
    setTransactionStatus: (
      state,
      action: PayloadAction<TRANSACTION_STATUS | null>,
    ) => {
      state.transactionStatus = action.payload;
    },
    setRange: (state, action: PayloadAction<RangeValue | null>) => {
      state.range = action.payload;
    },
    resetFilters: (state) => {
      state.transactionType = null;
      state.transactionStatus = null;
      state.range = null;
    },
    setTransactions: (state, action: PayloadAction<Transactions>) => {
      state.transactions = action.payload;
    },
    updateTransactionStatus: (
      state,
      action: PayloadAction<{ id: string; status: TRANSACTION_STATUS }>,
    ) => {
      const { id: transactionId, status } = action.payload;

      state.transactions = state.transactions.map((transaction) => {
        if (transaction.id === transactionId) {
          return {
            ...transaction,
            status,
          };
        }

        return transaction;
      });
    },
    setFilterParams: (
      state,
      action: PayloadAction<TransactionHistoryFilter>,
    ) => {
      state.filterParams = action.payload;
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
  },
});

export const {
  setTransactionType,
  setTransactionStatus,
  setRange,
  resetFilters,
  setTransactions,
  updateTransactionStatus,
  setFilterParams,
  setIsLoaded,
} = transactionHistorySlice.actions;

export default transactionHistorySlice.reducer;
