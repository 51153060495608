import React from 'react';

import {
  StyledAuthTabsList,
  StyledAuthTabsRoot,
  StyledAuthTabsTrigger,
} from './styled.components';

interface AuthTabsProps {
  children?: React.ReactNode;
  activeTab?: string;
}

export const AuthTabs = ({ activeTab, children }: AuthTabsProps) => {
  return <StyledAuthTabsRoot value={activeTab}>{children}</StyledAuthTabsRoot>;
};

interface AuthTabsListProps {
  tabs: string[];
  ariaLabel?: string;
}

export const AuthTabsList = ({ tabs, ariaLabel }: AuthTabsListProps) => {
  return (
    <StyledAuthTabsList aria-label={ariaLabel}>
      {tabs.map((tab) => (
        <StyledAuthTabsTrigger value={tab} disabled asChild key={tab}>
          <span></span>
        </StyledAuthTabsTrigger>
      ))}
    </StyledAuthTabsList>
  );
};
