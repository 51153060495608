import { createApi } from '@reduxjs/toolkit/query/react';

import { BetslipItems } from '~api/betslip/types';
import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { SportEventsMainData } from '~types/events';

import {
  LiveDataMock,
  SportEventItems,
  TopInplayEvents,
  Tournaments,
} from './types';

export const sportEventApi = createApi({
  reducerPath: 'sportEventApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.SNAPSHOT }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getBetslipAddedEvents: builder.mutation<SportEventItems, BetslipItems>({
      query: (body) => ({
        url: 'EventSelection',
        method: 'POST',
        body,
      }),
    }),
    getPrematchEventsByLeagueId: builder.query<
      LiveDataMock,
      { dateTo: string; leagueId: string }
    >({
      query: ({ leagueId, dateTo }) => ({
        url: `Event/prematch?LeagueId=${leagueId}${
          dateTo ? `&dateTo=${dateTo}` : ''
        }`,
        method: 'GET',
      }),
    }),
    getFavoriteTournaments: builder.query<Tournaments, void>({
      query: () => ({
        url: 'favorite/tournament',
        method: 'GET',
      }),
    }),
    setTournamentAsFavorite: builder.mutation<void, Record<string, number>>({
      query: ({ id }) => ({
        url: `favorite/tournament/${id}`,
        method: 'POST',
      }),
    }),
    deleteTournamentFromFavorites: builder.mutation<
      void,
      Record<string, number>
    >({
      query: ({ id }) => ({
        url: `favorite/tournament/${id}`,
        method: 'DELETE',
      }),
    }),
    getTopEventInplay: builder.query<TopInplayEvents, void>({
      query: () => ({
        url: 'top/match/in-play',
        method: 'GET',
      }),
    }),
    getFavoriteEventInplay: builder.query<SportEventsMainData, void>({
      query: () => ({
        url: 'favorite/match/in-play',
        method: 'GET',
      }),
    }),
    getFavoriteEventPrematch: builder.query<SportEventsMainData, void>({
      query: () => ({
        url: 'favorite/match/pre-match',
        method: 'GET',
      }),
    }),
    addFavoriteEvent: builder.mutation<void, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `favorite/match/${eventId}`,
        method: 'POST',
      }),
    }),
    deleteFavoriteEvent: builder.mutation<void, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `favorite/match/${eventId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetFavoriteTournamentsQuery,
  useSetTournamentAsFavoriteMutation,
  useDeleteTournamentFromFavoritesMutation,
  useGetBetslipAddedEventsMutation,
  useLazyGetTopEventInplayQuery,
  useLazyGetFavoriteEventInplayQuery,
  useLazyGetFavoriteEventPrematchQuery,
  useAddFavoriteEventMutation,
  useDeleteFavoriteEventMutation,
} = sportEventApi;
