import {
  useGetUsersQuery,
  useLazyGetUserDocumentsQuery,
  useLazyGetUserProfileQuery,
  useLazyGetUserSessionsQuery,
} from './userApi';

export const useGetUsers = () => {
  const { data, error, isLoading } = useGetUsersQuery();

  return {
    getUsersData: data,
    getUsersError: error,
    getUsersIsLoading: isLoading,
  };
};

export const useLazyGetUserProfile = () => {
  const [query, { data, error, isLoading }] = useLazyGetUserProfileQuery();

  return {
    lazyGetUserProfileQuery: query,
    lazyGetUsersData: data,
    lazyGetUsersError: error,
    lazyGetUsersIsLoading: isLoading,
  };
};

export const useLazyGetUserSessions = () => {
  const [query, { data, error, isLoading }] = useLazyGetUserSessionsQuery();

  return {
    lazyGetUserSessionsQuery: query,
    lazyGetUserSessionsData: data,
    lazyGetUserSessionsError: error,
    lazyGetUserSessionsIsLoading: isLoading,
  };
};

export const useLazyGetUserDocuments = () => {
  const [query, { data, error, isLoading }] = useLazyGetUserDocumentsQuery();

  return {
    lazyGetUserDocumentsQuery: query,
    lazyGetUserDocumentsData: data,
    lazyGetUserDocumentsError: error,
    lazyGetUserDocumentsIsLoading: isLoading,
  };
};
