import { Box } from '~components/atoms/Box';
import {
  StyledTabsContent,
  StyledTabsRoot,
} from '~components/atoms/Tabs/styled.components';
import { BETSLIP_HISTORY_TABS_LIST } from '~components/molecules/BetslipHistorySection/constants';
import { JackpotBetslipHistory } from '~components/molecules/BetslipHistorySection/Jackpot/JackpotBetslipHistory';
import { USER_BETSLIP_HISTORY_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import useIsInIframe from '~hooks/useIsInIframe';
import { useAppDispatch, useAppSelector } from '~store';
import { setContentTab } from '~store/slices/personalDetailsSlice';
import { ContentsMap } from '~types/general';

import { BetslipHistory } from '../Sports/BetslipHistory';

import { BetslipHistoryTabs } from './BetslipHistoryTabs';

export const BetslipHistoryWrapper = () => {
  const dispatch = useAppDispatch();
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const isInIframe = useIsInIframe();
  const selectedContentTab = BETSLIP_HISTORY_TABS_LIST.includes(
    contentTab as USER_BETSLIP_HISTORY_TABS,
  )
    ? contentTab
    : USER_BETSLIP_HISTORY_TABS.SPORTS;

  const tabsContentMap: ContentsMap = {
    [USER_BETSLIP_HISTORY_TABS.SPORTS]: <BetslipHistory />,
    [USER_BETSLIP_HISTORY_TABS.JACKPOT]: <JackpotBetslipHistory />,
  };

  return (
    <StyledTabsRoot
      onValueChange={(value) =>
        dispatch(setContentTab(value as USER_BETSLIP_HISTORY_TABS))
      }
      value={selectedContentTab as string}
      fullHeight
    >
      <Box flexCol gap={2} fullHeight>
        {isInIframe ? null : <BetslipHistoryTabs />}
        <StyledTabsContent
          value={selectedContentTab as USER_BETSLIP_HISTORY_TABS}
        >
          {tabsContentMap[selectedContentTab as USER_BETSLIP_HISTORY_TABS]}
        </StyledTabsContent>
      </Box>
    </StyledTabsRoot>
  );
};
