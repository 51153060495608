import { useEffect } from 'react';

import { PrematchParams } from '~api/category/types';
import { QUERY_PARAMS } from '~constants/common';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { setIsLoading } from '~store/slices/prematchMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { conditionalAdd, convertKeysToUpperCase } from '~utils/objectHelpers';

export const preparePrematchQuery = (
  sportId?: string,
  countryId?: string,
  leagueId?: string,
  dateTo?: string | null,
) => {
  const params: Partial<PrematchParams> = {};

  conditionalAdd(params, QUERY_PARAMS.SPORT_ID, Number(sportId));
  conditionalAdd(params, QUERY_PARAMS.COUNTRY_ID, Number(countryId));
  conditionalAdd(params, QUERY_PARAMS.LEAGUE_ID, Number(leagueId));
  conditionalAdd(params, QUERY_PARAMS.DATE_TO, dateTo);

  return convertKeysToUpperCase(params);
};

export const useSportWithCurrentLeague = () => {
  const dispatch = useAppDispatch();
  const { countryId, leagueId, sportId } = useQueryParams();
  const { sendWsMessage, isPrimaryDataLoaded } = useEventsLoadingSocket();
  const {
    sports = [],
    isLoading,
    dateToFilter,
    isMobileFetchBlocked,
  } = useAppSelector((state) => state.prematchMenu);
  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    sendWsMessage(ACTION_TYPE.GET_COUNTS, { DateTo: dateToFilter });
  }, [dateToFilter]);

  useEffect(() => {
    if (isMobileFetchBlocked) return;

    if (isPrimaryDataLoaded) {
      dispatch(setIsLoading(true));
      sendWsMessage(ACTION_TYPE.GET_PREMATCH, {
        ...preparePrematchQuery(sportId, countryId, leagueId, dateToFilter),
        Language: language,
      });
    }
  }, [
    sportId,
    countryId,
    leagueId,
    language,
    dateToFilter,
    isPrimaryDataLoaded,
    isMobileFetchBlocked,
  ]);

  return {
    sports,
    isLoading: isLoading,
    isFetching: isLoading,
  };
};
