import { Fragment } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { StyledCategoryItem } from '~components/molecules/GamesFilters/styled.components';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import {
  selectNotEmptyCategories,
  toggleSelectedCategoryId,
} from '~store/slices/gamesSlice';
import { getSanitizedHTML } from '~utils/htmlUtils';

interface CategoriesListProps {
  isFilter?: boolean;
  mobile?: boolean;
  value?: string | null;
  onChange?: (value: string | null) => void;
}

export const CategoriesList = ({
  isFilter = false,
  mobile = false,
  value,
  onChange,
}: CategoriesListProps) => {
  const dispatch = useAppDispatch();
  const { selectedCategoryId } = useAppSelector((state) => state.games);
  const categories = useAppSelector(selectNotEmptyCategories);
  const { isMobileOrTablet } = useMedia();

  const handleCategoryClick = (id: string) => {
    if (onChange) {
      onChange(id === value ? null : id);

      return;
    }

    dispatch(toggleSelectedCategoryId(id));
  };

  return (
    <Box
      flexRow
      justifyContentAround
      alignCenter={!isMobileOrTablet}
      alignStart={true}
      css={{
        width: mobile ? 'fit-content' : '100%',
        gap: mobile ? 0 : '2rem',
        '@sm': {
          overflowX: 'auto',
          pb: isFilter ? 0 : '$4',
          gap: '1rem',
          marginTop: 0,
        },
        '@md': {
          pb: isFilter ? 0 : '$4',
          gap: '1rem',
          marginTop: 0,
        },
      }}
    >
      {!!categories.length &&
        categories.map(({ id, name, iconSvg }, index) => {
          const isActive = onChange ? id === value : id === selectedCategoryId;

          return (
            <Fragment key={id}>
              <StyledCategoryItem
                mobile={mobile}
                onClick={() => handleCategoryClick(id)}
                data-active={isActive}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      iconSvg &&
                      getSanitizedHTML(
                        iconSvg.replace(
                          /fill="#[0-9a-fA-F]+"/g,
                          'fill="currentColor"',
                        ),
                      ),
                  }}
                />
                <Box
                  css={{
                    position: 'relative',
                  }}
                >
                  <Text
                    level={isMobileOrTablet ? '10-12' : '12-16'}
                    fontWeight={'bold'}
                    as="span"
                    css={{
                      visibility: isActive ? 'visible' : 'hidden',
                    }}
                  >
                    {name}
                  </Text>
                  <Text
                    css={{
                      position: 'absolute',
                      bottom: '4px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      visibility: !isActive ? 'visible' : 'hidden',
                      maxWidth: '90% !important',
                    }}
                    level={isMobileOrTablet ? '10-12' : '12-16'}
                    as="span"
                  >
                    {name}
                  </Text>
                </Box>
              </StyledCategoryItem>
              {index < categories.length - 1 && mobile && (
                <Box
                  key={id + '-separator'}
                  css={{
                    marginTop: '$4',
                    minWidth: '1px',
                    borderRadius: '$4',
                    height: '1.5rem',
                    background: '$secondaryOne',
                  }}
                />
              )}
            </Fragment>
          );
        })}
    </Box>
  );
};
