import { useEffect } from 'react';

import { BONUS_STATUS } from '~api/bonus/types';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

import { useBonuses } from '../../useBonuses';

export const useActiveBonusesContent = () => {
  const { localized } = useTranslation();
  const { bonuses, isBonusesLoading } = useAppSelector(
    (state) => state.bonuses,
  );

  const { loadBonuses } = useBonuses();

  useEffect(() => {
    loadBonuses({ queryParams: { Status: BONUS_STATUS.ACTIVE } });
  }, []);

  return {
    bonuses,
    isBonusesLoading,
    localized,
  };
};
