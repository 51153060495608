export enum TIME_RANGES {
  ALL = 'all',
  TODAY = 'today',
  THREE_HOURS = '3',
  SIX_HOURS = '6',
  TWELVE_HOURS = '12',
  TWENTY_FOUR_HOURS = '24',
  FORTY_EIGHT_HOURS = '48',
  SEVENTY_TWO_HOURS = '72',
}
