import { createSelector, createSlice } from '@reduxjs/toolkit';

import { FavoriteMarkets, SportMainMarkets } from '~api/market/types';
import { RootState } from '~store';

export interface MainMarketsState {
  mainMarkets: SportMainMarkets;
  favoriteMarkets: FavoriteMarkets[];
}

const initialState: MainMarketsState = {
  mainMarkets: [],
  favoriteMarkets: [],
};

export const mainMarketsSlice = createSlice({
  name: 'mainMarkets',
  initialState,
  reducers: {
    setMainMarkets: (state, action) => {
      state.mainMarkets = action.payload;
    },
    setFavoriteMarkets: (state, action) => {
      state.favoriteMarkets = action.payload;
    },
  },
});

const selectFavoriteMarkets = (state: RootState) =>
  state.mainMarkets.favoriteMarkets;
const selectSportId = (_: unknown, sportId: number) => sportId;

export const selectFavoriteSportMarkets = createSelector(
  [selectFavoriteMarkets, selectSportId],
  (favoriteMarkets: FavoriteMarkets[], sportId: number) => {
    if (!favoriteMarkets) return [];

    return (
      favoriteMarkets.find(
        (favoriteMarket) => favoriteMarket.sportId === sportId,
      )?.marketIds || []
    );
  },
);

export const getMainMarkets = (state: RootState) =>
  state.mainMarkets.mainMarkets;

export const selectMainMarketsBySportId = (sportId?: number) => {
  return createSelector(getMainMarkets, (mainMarkets) => {
    return mainMarkets.find((market) => market.id === sportId);
  });
};

export const { setMainMarkets, setFavoriteMarkets } = mainMarketsSlice.actions;

export default mainMarketsSlice.reducer;
