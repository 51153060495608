import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Categories,
  GAME_TYPE,
  Games,
  GamesStats,
  LikedGamesResponse,
  PopularResponse,
} from '~api/games/types';
import { GAME_PROVIDERS, PROVIDERS_MAP } from '~constants/providers';
import { FILTER_TYPE } from '~types/games';
import { ProviderFilters, ProvidersList } from '~types/providers';

export interface GameState {
  launchUrl: string | null;
  games: Games;
  gamesLoaded: boolean;
  currentVersion: number;
  categories: Categories;
  selectedCategoryId: string | null;
  providers: ProvidersList;
  providersFilters: ProviderFilters;
  selectedProviderId: GAME_PROVIDERS | null;
  filterType: FILTER_TYPE | null;
  popularGames: GamesStats;
  popularLoaded: boolean;
  filteredGames: Games;
  isLive: boolean;
  isVirtual: boolean;
  isDemo: boolean;
  isFromSearch: boolean;
}

const initialState: GameState = {
  launchUrl: null,
  games: [],
  gamesLoaded: false,
  categories: [],
  currentVersion: -1,
  selectedCategoryId: null,
  providers: [],
  providersFilters: {},
  selectedProviderId: null,
  filterType: FILTER_TYPE.FILTERS,
  popularGames: [],
  popularLoaded: false,
  filteredGames: [],
  isLive: false,
  isVirtual: false,
  isDemo: false,
  isFromSearch: false,
};

export const gamesSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setLaunchUrl: (state, action: PayloadAction<string | null>) => {
      state.launchUrl = action.payload;
    },
    setGames: (state, action: PayloadAction<Games>) => {
      state.games = action.payload;
    },
    setGamesLoaded: (state, action: PayloadAction<boolean>) => {
      state.gamesLoaded = action.payload;
    },
    setCurrentVersion: (state, action: PayloadAction<number>) => {
      state.currentVersion = action.payload;
    },
    setCategories: (state, action: PayloadAction<Categories>) => {
      state.categories = action.payload;
    },
    setSelectedCategoryId: (state, action: PayloadAction<string | null>) => {
      state.selectedCategoryId = action.payload;
    },
    toggleSelectedCategoryId: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId =
        state.selectedCategoryId === action.payload ? null : action.payload;
      state.filteredGames = [];
    },
    setFilterType: (state, action: PayloadAction<FILTER_TYPE>) => {
      state.filterType = action.payload;
    },
    setPopular: (state, action: PayloadAction<PopularResponse>) => {
      const { games, gameProviders } = action.payload;

      state.popularGames = games;
      state.providers = gameProviders.map(({ gameProvider, order }) => ({
        ...PROVIDERS_MAP[gameProvider],
        gameCount: state.games.filter(
          ({ gameProviderId }) => gameProviderId === gameProvider,
        ).length,
        order,
      }));

      state.popularLoaded = true;
    },
    setProvidersFilters: (state, action: PayloadAction<ProviderFilters>) => {
      state.providersFilters = action.payload;
    },
    setFilteredGames: (state, action: PayloadAction<Games>) => {
      state.filteredGames = action.payload;
    },
    setSelectedProviderId: (state, action: PayloadAction<number | null>) => {
      state.selectedProviderId = action.payload;
    },
    setIsLive: (state, action: PayloadAction<boolean>) => {
      state.isLive = action.payload;
    },
    setIsDemo: (state, action: PayloadAction<boolean>) => {
      state.isDemo = action.payload;
    },
    setIsFromSearch: (state, action: PayloadAction<boolean>) => {
      state.isFromSearch = action.payload;
    },
    setLikedGames: (state, action: PayloadAction<LikedGamesResponse>) => {
      state.games = state.games.map((game) => ({
        ...game,
        isLiked: action.payload.likedGameIds.includes(game.id),
      }));
    },
    setToggleGameLike: (state, action: PayloadAction<string>) => {
      state.games = state.games.map((game) =>
        game.id === action.payload ? { ...game, isLiked: !game.isLiked } : game,
      );
    },
    setIsVirtual: (state, action: PayloadAction<boolean>) => {
      state.isVirtual = action.payload;
    },
  },
});

export const {
  setLaunchUrl,
  setGames,
  setCurrentVersion,
  setGamesLoaded,
  setCategories,
  setSelectedCategoryId,
  setFilterType,
  setPopular,
  setProvidersFilters,
  setSelectedProviderId,
  setFilteredGames,
  setIsLive,
  setIsDemo,
  setIsFromSearch,
  setLikedGames,
  setToggleGameLike,
  toggleSelectedCategoryId,
  setIsVirtual,
} = gamesSlice.actions;

export const selectGamesState = (state: { games: GameState }) => state.games;

export const selectCategoriesState = (state: { games: GameState }) =>
  state.games.categories;

export const selectGames = createSelector(
  selectGamesState,
  ({ isLive, isVirtual, games }) => {
    if (isLive) {
      return games.filter(({ gameType }) => gameType === GAME_TYPE.LIVE);
    }

    if (isVirtual) {
      return games.filter(({ gameType }) => gameType === GAME_TYPE.VIRTUAL);
    }

    return games.filter(({ gameType }) => gameType === GAME_TYPE.SLOT);
  },
);

const getFilteredGames = (games: Games, popularGames: GamesStats) => {
  const mappedGames = popularGames
    .map(({ gameId }) => games.find(({ id }) => id === gameId))
    .filter((item) => item) as Games;

  if (mappedGames.length > 10) {
    return mappedGames.slice(0, 10);
  } else {
    return mappedGames;
  }
};

export const selectMostLikedGames = createSelector(
  selectGamesState,
  ({ isLive, isVirtual, games, popularGames }) => {
    let gamesArr: Games = games.filter(
      ({ gameType }) => gameType === GAME_TYPE.SLOT,
    );

    if (isLive) {
      gamesArr = games.filter(({ gameType }) => gameType === GAME_TYPE.LIVE);
    }

    if (isVirtual) {
      gamesArr = games.filter(({ gameType }) => gameType === GAME_TYPE.VIRTUAL);
    }

    const popularGamesArr = [...popularGames].sort(
      (a, b) => b.likedCount - a.likedCount,
    );

    return getFilteredGames(gamesArr, popularGamesArr);
  },
);

export const selectMostSearchedGames = createSelector(
  selectGamesState,
  ({ isLive, isVirtual, games, popularGames }) => {
    let gamesArr: Games = games.filter(
      ({ gameType }) => gameType === GAME_TYPE.SLOT,
    );

    if (isLive) {
      gamesArr = games.filter(({ gameType }) => gameType === GAME_TYPE.LIVE);
    }

    if (isVirtual) {
      gamesArr = games.filter(({ gameType }) => gameType === GAME_TYPE.VIRTUAL);
    }

    const mostSearchedGames = [...popularGames].sort(
      (a, b) => b.launchedFromSearchCount - a.launchedFromSearchCount,
    );

    return getFilteredGames(gamesArr, mostSearchedGames);
  },
);

export const selectNotEmptyCategories = createSelector(
  [selectCategoriesState, selectGames],
  (categories, games) => {
    const categoriesIdsSet = new Set<string>();

    games.forEach((game) =>
      game.categoryIds.forEach((id) => categoriesIdsSet.add(id)),
    );

    return categories.filter((category) => categoriesIdsSet.has(category.id));
  },
);

export const selectProviders = createSelector(
  selectGamesState,
  (games) => games.providers,
);

export const selectProvidersFilters = createSelector(
  selectGamesState,
  (games) => games.providersFilters,
);

export const selectSelectedProviderId = createSelector(
  selectGamesState,
  (games) => games.selectedProviderId,
);

export const selectSortedProviders = createSelector(
  [selectProviders, selectProvidersFilters],
  (providers, providersFilters) => {
    const sortedProviders = [...providers];

    if (providersFilters?.popular) {
      return sortedProviders.sort((a, b) => a.order - b.order);
    } else if (providersFilters?.alphabet) {
      return sortedProviders.sort((a, b) =>
        providersFilters.alphabet === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name),
      );
    } else if (providersFilters?.amount) {
      return sortedProviders.sort((a, b) =>
        providersFilters.amount === 'asc'
          ? a.gameCount - b.gameCount
          : b.gameCount - a.gameCount,
      );
    } else {
      return sortedProviders;
    }
  },
);

export const selectGamesByProviderId = createSelector(
  [selectGames, selectSelectedProviderId, selectGamesState],
  (games, providerId, { isLive, isVirtual }) => {
    if (!providerId) return [];

    let gamesArr: Games = games.filter(
      ({ gameType }) =>
        gameType === GAME_TYPE.LIVE || gameType === GAME_TYPE.SLOT,
    );

    if (isLive) {
      gamesArr = games.filter(({ gameType }) => gameType === GAME_TYPE.LIVE);
    }

    if (isVirtual) {
      gamesArr = games.filter(({ gameType }) => gameType === GAME_TYPE.VIRTUAL);
    }

    return providerId
      ? gamesArr.filter((game) => game.gameProviderId === providerId)
      : [];
  },
);

export default gamesSlice.reducer;
