import { useEffect, useState } from 'react';

type ConnectionStatus = {
  isOnline: boolean;
  isDisconnected: boolean;
  isReconnected: boolean;
};

export const useNetworkStatus = (): ConnectionStatus => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false);
  const [isReconnected, setIsReconnected] = useState<boolean>(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      if (isDisconnected) {
        setIsReconnected(true);
      }

      setIsDisconnected(false);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setIsReconnected(false);
      setIsDisconnected(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isDisconnected]);

  return { isOnline, isDisconnected, isReconnected };
};
