import { GAME_PROVIDERS } from '~constants/providers';
import { ProvidersStats } from '~types/providers';

export enum GAME_TYPE {
  SLOT = 1,
  LIVE = 2,
  VIRTUAL = 3,
}

export interface Game {
  id: string;
  name: string;
  gameType: GAME_TYPE;
  gameProviderId: GAME_PROVIDERS;
  largeImageUrl: string | null;
  smallImageUrl: string;
  minStake: number;
  maxStake: number;
  platform: number;
  hasDemo: boolean;
  categoryIds: string[];
  isLiked?: boolean;
}

export type Games = Game[];

export interface GamesResponse {
  currentVersion: number;
  games: Games;
}

export interface Category {
  id: string;
  name: string;
  iconSvg: string;
  order: number;
}

export type Categories = Category[];

export interface CasinoResponse {
  currentVersion: number;
  games: Games;
  categories: Categories;
}

export interface LikedGamesResponse {
  likedGameIds: string[];
}

export interface LaunchGamePayload {
  gameId?: string;
  gameUrlPath?: string;
  returnUrl?: string;
  depositUrl?: string;
  homeUrl?: string;
  isFromSearch?: boolean;
}

export interface LaunchGameResponse {
  launchUrl: string;
}

interface GameStats {
  gameId: string;
  likedCount: number;
  launchedCount: number;
  launchedDemoCount: number;
  launchedFromSearchCount: number;
  betCount: number;
  gameProvider: number;
}

export type GamesStats = GameStats[];

export interface PopularResponse {
  games: GamesStats;
  gameProviders: ProvidersStats;
}
