const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const shouldHover = {
  '&:hover': {
    cursor: 'pointer',
    color: '$hoverPrimary !important',
    '*': {
      color: '$hoverPrimary !important',
    },
  },
  '&:active': {
    color: '$pressedOne !important',
    '*': {
      color: '$pressedOne !important',
    },
  },
};

export { ellipsis, shouldHover };
