import { PhoneNumberField } from '~components/atoms/PhoneNumberField';

import { PERSONAL_DETAILS_FORM_FIELDS } from './constants';

export const ReadOnlyPhoneNumberField = ({
  phoneNumber,
  protectedPhoneNumber,
  isValidPhoneNumber = true,
}: {
  phoneNumber: string;
  isValidPhoneNumber?: boolean;
  protectedPhoneNumber?: boolean;
}) => {
  return (
    <PhoneNumberField
      key={`${PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD}-readonly`}
      phoneNumberId={`${PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD}-readonly`}
      isValidPhoneNumber={isValidPhoneNumber}
      editable
      readonly
      withMask
      isFullView={false}
      phoneNumber={phoneNumber}
      protectedPhoneNumber={protectedPhoneNumber}
    />
  );
};
