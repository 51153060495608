import { useTranslation } from '~hooks/useTranslation';
import { ExtraData } from '~types/sportExtraData/ExtraData';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import {
  getFormattedMatchStatus,
  getMatchStatus,
} from '~utils/matchStatusHelpers';
import { getFormattedMarketsCount } from '~utils/numberUtils';

export const useLiveEventDetails = (
  marketsCount: number | undefined,
  startDate: string | undefined,
  extraData: ExtraData,
) => {
  const { localized } = useTranslation();

  const getFormattedStartDate = (startDate: string | undefined) => {
    if (!startDate) return null;

    return formatDateTime(startDate, TIME_FORMATS.TIME_12H);
  };

  return {
    formattedMarketsCount: getFormattedMarketsCount(marketsCount),
    formattedStartDate: getFormattedStartDate(startDate),
    formattedMatchStatus: getFormattedMatchStatus(
      getMatchStatus(extraData),
      localized,
    ),
  };
};
