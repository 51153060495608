import React from 'react';

import { TextWithTooltip } from '~components/atoms/TextWithTooltip';
import { useTranslation } from '~hooks/useTranslation';
import {
  TransactionHistoryCellProps,
  transactionsTranslationsMap,
  transactionsTypeColorsMap,
} from '~types/transactions';

export const TypeCell = ({
  row: {
    original: { type },
  },
}: TransactionHistoryCellProps) => {
  const { localized } = useTranslation();

  return (
    <TextWithTooltip
      color={transactionsTypeColorsMap[type]}
      text={localized(transactionsTranslationsMap[type])}
    />
  );
};
