import {
  useLazyGetFavoriteEventInplayQuery,
  useLazyGetFavoriteEventPrematchQuery,
  useLazyGetFavoriteTournamentsQuery,
  useLazyGetTopEventInplayQuery,
} from './sportEventApi';

export const useLazyGetFavoriteTournaments = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetFavoriteTournamentsQuery();

  return {
    lazyGetFavoriteTournamentsQuery: query,
    lazyGetFavoriteTournamentsData: data,
    lazyGetFavoriteTournamentsError: error,
    lazyGetFavoriteTournamentsIsLoading: isLoading,
  };
};

export const useLazyGetTopEventInplay = () => {
  const [query, { data, error, isFetching }] = useLazyGetTopEventInplayQuery();

  return {
    lazyGetTopEventInplayQuery: query,
    lazyGetTopEventInplayData: data,
    lazyGetTopEventInplayError: error,
    lazyGetTopEventInplayIsFetching: isFetching,
  };
};

export const useLazyGetFavoriteEventInplay = () => {
  const [query, { data, error, isFetching }] =
    useLazyGetFavoriteEventInplayQuery();

  return {
    lazyGetFavoriteEventInplayQuery: query,
    lazyGetFavoriteEventInplayData: data,
    lazyGetFavoriteEventInplayError: error,
    lazyGetFavoriteEventInplayIsFetching: isFetching,
  };
};

export const useLazyGetFavoriteEventPrematch = () => {
  const [query, { data, error, isFetching }] =
    useLazyGetFavoriteEventPrematchQuery();

  return {
    lazyGetFavoriteEventPrematchQuery: query,
    lazyGetFavoriteEventPrematchData: data,
    lazyGetFavoriteEventPrematchError: error,
    lazyGetFavoriteEventPrematchIsFetching: isFetching,
  };
};
