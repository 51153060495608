import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

import {
  useLoginUserMutation,
  useRefreshTokenMutation,
  useRegisterUserMutation,
  useResetPasswordCodeMutation,
  useResetPasswordMutation,
  useSignInWhatsAppMutation,
  useSignUpWhatsAppMutation,
  useVerifyCodeMutation,
  useVerifyEmailTokenMutation,
  useVerifyMessagerAuthMutation,
  useVerifyPhoneNewCodeMutation,
  useVerifyUserMutation,
} from './authApi';

export const useLoginUser = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useLoginUserMutation();

  return {
    loginUserMutation: mutation,
    loginUserData: data,
    loginUserError: error,
    loginUserIsError: isError,
    loginUserErrorCode: getErrorCode(error, isError),
    loginUserErrorMessage: getErrorMessage(error),
    loginUserIsSuccess: isSuccess,
    loginUserIsLoading: isLoading,
  };
};

export const useRefreshToken = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useRefreshTokenMutation();

  return {
    refreshTokenMutation: mutation,
    refreshTokenData: data,
    refreshTokenError: error,
    refreshTokenIsError: isError,
    refreshTokenErrorCode: getErrorCode(error, isError),
    refreshTokenErrorMessage: getErrorMessage(error),
    refreshTokenIsSuccess: isSuccess,
    refreshTokenIsLoading: isLoading,
  };
};

export const useRegisterUser = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useRegisterUserMutation();

  return {
    registerUserMutation: mutation,
    registerUserData: data,
    registerUserError: error,
    registerUserIsError: isError,
    registerUserErrorCode: getErrorCode(error, isError),
    registerUserErrorMessage: getErrorMessage(error),
    registerUserIsSuccess: isSuccess,
    registerUserIsLoading: isLoading,
  };
};

export const useResetPasswordCode = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useResetPasswordCodeMutation();

  return {
    resetPasswordCodeMutation: mutation,
    resetPasswordCodeData: data,
    resetPasswordCodeError: error,
    resetPasswordCodeIsError: isError,
    resetPasswordCodeErrorCode: getErrorCode(error, isError),
    resetPasswordCodeErrorMessage: getErrorMessage(error),
    resetPasswordCodeIsSuccess: isSuccess,
    resetPasswordCodeIsLoading: isLoading,
  };
};

export const useResetPassword = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useResetPasswordMutation();

  return {
    resetPasswordMutation: mutation,
    resetPasswordData: data,
    resetPasswordError: error,
    resetPasswordIsError: isError,
    resetPasswordErrorCode: getErrorCode(error, isError),
    resetPasswordErrorMessage: getErrorMessage(error),
    resetPasswordIsSuccess: isSuccess,
    resetPasswordIsLoading: isLoading,
  };
};

export const useVerifyUser = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useVerifyUserMutation();

  return {
    verifyUserMutation: mutation,
    verifyUserData: data,
    verifyUserError: error,
    verifyUserIsError: isError,
    verifyUserErrorCode: getErrorCode(error, isError),
    verifyUserErrorMessage: getErrorMessage(error),
    verifyUserIsSuccess: isSuccess,
    verifyUserIsLoading: isLoading,
  };
};

export const useVerifyCode = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useVerifyCodeMutation();

  return {
    verifyCodeMutation: mutation,
    verifyCodeData: data,
    verifyCodeError: error,
    verifyCodeIsError: isError,
    verifyCodeErrorCode: getErrorCode(error, isError),
    verifyCodeErrorMessage: getErrorMessage(error),
    verifyCodeIsSuccess: isSuccess,
    verifyCodeIsLoading: isLoading,
  };
};

export const useVerifyPhoneNewCode = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useVerifyPhoneNewCodeMutation();

  return {
    verifyPhoneNewCodeMutation: mutation,
    verifyPhoneNewCodeData: data,
    verifyPhoneNewCodeError: error,
    verifyPhoneNewCodeIsError: isError,
    verifyPhoneNewCodeErrorCode: getErrorCode(error, isError),
    verifyPhoneNewCodeErrorMessage: getErrorMessage(error),
    verifyPhoneNewCodeIsSuccess: isSuccess,
    verifyPhoneNewCodeIsLoading: isLoading,
  };
};

export const useVerifyEmailToken = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useVerifyEmailTokenMutation();

  return {
    verifyEmailTokenMutation: mutation,
    verifyEmailTokenData: data,
    verifyEmailTokenError: error,
    verifyEmailTokenIsError: isError,
    verifyEmailTokenErrorCode: getErrorCode(error, isError),
    verifyEmailTokenErrorMessage: getErrorMessage(error),
    verifyEmailTokenIsSuccess: isSuccess,
    verifyEmailTokenIsLoading: isLoading,
  };
};

export const useSignUpWhatsApp = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSignUpWhatsAppMutation();

  return {
    signUpWhatsAppMutation: mutation,
    signUpWhatsAppData: data,
    signUpWhatsAppError: error,
    signUpWhatsAppIsError: isError,
    signUpWhatsAppErrorCode: getErrorCode(error, isError),
    signUpWhatsAppErrorMessage: getErrorMessage(error),
    signUpWhatsAppIsSuccess: isSuccess,
    signUpWhatsAppIsLoading: isLoading,
  };
};

export const useSignInWhatsApp = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSignInWhatsAppMutation();

  return {
    signInWhatsAppMutation: mutation,
    signInWhatsAppData: data,
    signInWhatsAppError: error,
    signInWhatsAppIsError: isError,
    signInWhatsAppErrorCode: getErrorCode(error, isError),
    signInWhatsAppErrorMessage: getErrorMessage(error),
    signInWhatsAppIsSuccess: isSuccess,
    signInWhatsAppIsLoading: isLoading,
  };
};

export const useVerifyMessagerAuth = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useVerifyMessagerAuthMutation();

  return {
    verifyMessagerAuthMutation: mutation,
    verifyMessagerAuthData: data,
    verifyMessagerAuthError: error,
    verifyMessagerAuthIsError: isError,
    verifyMessagerAuthErrorCode: getErrorCode(error, isError),
    verifyMessagerAuthErrorMessage: getErrorMessage(error),
    verifyMessagerAuthIsSuccess: isSuccess,
    verifyMessagerAuthIsLoading: isLoading,
  };
};
