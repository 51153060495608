import { MutableRefObject, useEffect } from 'react';

export const useOuterClick = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: (e?: Event) => void,
  eventType?: string,
): void => {
  useEffect(() => {
    const handleClickOutside: EventListener = (event: Event) => {
      const mouseEvent = event as MouseEvent;

      if (ref.current && !ref.current.contains(mouseEvent.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener(eventType || 'mousedown', handleClickOutside);

    return () => {
      document.removeEventListener(
        eventType || 'mousedown',
        handleClickOutside,
      );
    };
  }, [ref, callback]);
};
