export const ODDS: { value: string; label: string; title: string }[] = [
  { value: 'decimal', label: 'Decimal', title: 'Decimal' },
  { value: 'fractional', label: 'Fractional', title: 'Fractional' },
  { value: 'hongKong', label: 'Hong Kong', title: 'Hong Kong' },
  { value: 'american', label: 'American', title: 'American' },
  { value: 'indo', label: 'Indo', title: 'Indo' },
  { value: 'malay', label: 'Malay', title: 'Malay' },
];

export const oddsMap: Record<string, number> = {
  decimal: 1,
  fractional: 2,
  hongKong: 3,
  american: 4,
  indo: 5,
  malay: 6,
};
