import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Box } from '~components/atoms/Box';
import { Cross } from '~components/atoms/Cross';
import { Tooltip } from '~components/atoms/Tooltip';
import { Text } from '~components/atoms/Typography';
import { MAXIMUM_UPLOAD_FILE_SIZE } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { AttachFileIcon } from '~icons';

import { PrimitiveFileUploadWrapper } from './styled.components';

interface FileUploadInputProps {
  value: File | null;
  onChange: (file: File | null) => void;
  placeholder?: string;
}

export const AttachFileInput = ({
  value,
  onChange,
  placeholder,
}: FileUploadInputProps) => {
  const { localized } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles[0]);
  }, []);

  const handleFileDelete = () => {
    onChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: MAXIMUM_UPLOAD_FILE_SIZE,
    multiple: false,
    noDrag: true,
    noClick: !!value,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <PrimitiveFileUploadWrapper
      {...getRootProps()}
      flexRow
      alignCenter
      css={{ position: 'relative' }}
    >
      <Text
        color={value ? 'textPrimaryOne' : 'textSecondaryOne'}
        fontWeight="normal"
        level="sm-4"
        ellipsis
      >
        {value && value.name ? value.name : placeholder}
      </Text>
      <input {...getInputProps()} />
      {value ? (
        <Cross
          onClick={(e) => {
            e.stopPropagation();
            handleFileDelete();
          }}
        />
      ) : (
        <Tooltip
          text={localized(
            'userProfile.personalProfileInfo.accountVerification.documentFileUpload.tooltip',
          )}
        >
          <Box
            shouldHover
            flexRow
            className="icon"
            css={{
              position: 'absolute',
              right: '$3',
              color: '$textSecondaryOne',
            }}
          >
            <AttachFileIcon />
          </Box>
        </Tooltip>
      )}
    </PrimitiveFileUploadWrapper>
  );
};
