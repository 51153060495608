import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';

export const ProviderFiltersWrapper = styled(Box, {
  display: 'flex',
  gap: '$3',
  backgroundColor: '$secondaryOne',
  borderRadius: '$10',
  padding: '$3',
  '@xl': {
    padding: '$4',
  },
  '@md': {
    padding: '$2',
  },
  '@xs_sm': {
    padding: '$2',
  },
});

export const ProviderFilterButton = styled(Button, {
  display: 'flex',
  gap: '$2',
  border: '1px solid $secondaryTwo',
  borderRadius: '$6',
  width: '180px',
  '@lg': {
    width: '168px',
  },
  '@md': {
    width: '140px',
  },
  '@sm': {
    width: '140px',
  },
  '@xs': {
    width: '100px',
  },
  svg: {
    pointerEvents: 'none',
  },

  // Active state
  variants: {
    active: {
      true: {
        border: '1px solid $accentSecondaryTwo',
        '& > p, svg': {
          color: '$accentPrimaryTwo',
          fontWeight: '$medium',
        },
      },
    },
  },

  // Text style
  '& > p': {
    textTransform: 'uppercase',
    '@xl': {
      fontSize: '18px !important',
      lineHeight: '$32 !important',
    },
    '@lg': {
      fontSize: '$16 !important',
      lineHeight: '$28 !important',
    },
    '@md': {
      fontSize: '$14 !important',
      lineHeight: '$20 !important',
    },
    '@sm': {
      fontSize: '$14 !important',
      lineHeight: '$20 !important',
    },
    '@xs': {
      fontSize: '$12 !important',
      lineHeight: '$20 !important',
    },
  },
});
