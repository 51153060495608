import { useMemo } from 'react';
import { Column } from 'react-table';

import { BetslipHistoryDetails } from '~api/betslip/types';
import { useTranslation } from '~hooks/useTranslation';

import {
  BetslipHistoryMarketSelectionInnerCell,
  BetslipHistoryResultInnerCell,
  BetslipHistorySportCountryInnerCell,
  BetslipHistoryStatusInnerCell,
} from './BetslipHistoryInnerTableCells';

export const useBetslipHistoryInnerTable = () => {
  const { localized } = useTranslation();

  const columns = useMemo(
    () =>
      [
        {
          Header: localized(
            'betslipHistory.betslipHistoryTable.innerTableHeaders.sport',
          ),
          accessor: 'sport',
          Cell: BetslipHistorySportCountryInnerCell,
        },
        {
          Header: localized(
            'betslipHistory.betslipHistoryTable.innerTableHeaders.result',
          ),
          accessor: 'result',
          Cell: BetslipHistoryResultInnerCell,
        },
        {
          Header: localized(
            'betslipHistory.betslipHistoryTable.innerTableHeaders.market',
          ),
          accessor: 'market',
          Cell: BetslipHistoryMarketSelectionInnerCell,
        },
        {
          Header: localized(
            'betslipHistory.betslipHistoryTable.innerTableHeaders.status',
          ),
          accessor: 'status',
          Cell: BetslipHistoryStatusInnerCell,
        },
      ] as Column<BetslipHistoryDetails>[],
    [],
  );

  return {
    columns,
  };
};
