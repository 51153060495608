import React, { useRef, useState } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import useDimensions from '~hooks/useDimensions';
import { useMedia } from '~hooks/useMedia';
import { useOuterClick } from '~hooks/useOuterClick';
import { useTranslation } from '~hooks/useTranslation';
import { CloseIcon, CopyToClipboardIcon, GreenCheckIcon } from '~icons';

const StyledPopoverContent = styled(Box, {
  borderRadius: '$8',
  p: '$3 $4 $4 $4',
  width: 260,
  variants: {
    tone: {
      main: {
        backgroundColor: '$primaryOne',
        border: '1px solid $primaryTwo',
      },
      secondary: {
        backgroundColor: '$primaryTwo',
        border: '1px solid $secondaryTwo',
      },
      blueDark: {
        backgroundColor: '$secondaryTwo',
        border: '1px solid $primaryTwo',
      },
    },
  },
  defaultVariants: {
    tone: 'main',
  },
});

export const BetslipBalancesPopover = ({
  betSlipCodes,
}: {
  betSlipCodes: number[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { copiedVal, isCopied, handleCopy } = useCopyToClipboard();
  const popoverRef = useRef(null);
  const [triggerRef, dimensions] = useDimensions();

  useOuterClick(popoverRef, () => {
    setIsOpen(false);
  });

  return (
    <Box
      flexRow
      css={{
        position: 'relative',
      }}
    >
      <Box
        ref={triggerRef}
        onClick={() => setIsOpen(!isOpen)}
        flexRow
        alignCenter
        justifyCenter
        css={{
          cursor: 'pointer',
          width: '$5',
          height: '$5',
          background: isOpen ? '$textPrimaryOne' : '$textSecondaryOne',
          borderRadius: '50%',
          lineHeight: 0,
        }}
      >
        <Text level="13-16" color="primaryTwo">
          {betSlipCodes.length}
        </Text>
      </Box>
      {isOpen && (
        <StyledPopoverContent
          ref={popoverRef}
          css={{
            position: 'absolute',
            top: isMobileOrTablet ? 'calc(100% + 8px)' : '50px',
            left: !isMobileOrTablet ? '50%' : -(dimensions?.left || 0),
            transform: isMobileOrTablet ? 'none' : 'translateX(-50%)',
            width: isMobileOrTablet ? '99vw' : '300px',
            padding: '16px',
            borderRadius: '12px',
            zIndex: 1000,
          }}
        >
          <Box flexCol gap={2}>
            <Box
              flexRow
              css={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                color: '$textSecondaryOne',
                cursor: 'pointer',
              }}
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon />
            </Box>
            <Text level="14-20" textTransform="uppercase">
              {localized('bonuses.betsIds')}
            </Text>
            <ScrollArea
              css={{
                maxHeight: '240px',
                overflowY: 'auto',
              }}
            >
              <Box flexCol gap={1} fullWidth>
                {betSlipCodes.map((betSlipCode) => (
                  <Box
                    key={betSlipCode.toString()}
                    flexRow
                    justifyContentBetween
                    alignCenter
                    css={{
                      padding: '8px 12px',
                      borderRadius: '6px',
                      background: '$secondaryTwo',
                    }}
                  >
                    <Text level="14-20">{betSlipCode}</Text>
                    <Box
                      css={{
                        display: 'flex',
                        cursor: 'pointer',
                        color: '$textSecondaryOne',
                        width: '24px',
                      }}
                      onClick={() => {
                        handleCopy(betSlipCode.toString());
                      }}
                    >
                      {isCopied && copiedVal === betSlipCode.toString() ? (
                        <GreenCheckIcon />
                      ) : (
                        <CopyToClipboardIcon />
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </ScrollArea>
          </Box>
        </StyledPopoverContent>
      )}
    </Box>
  );
};
