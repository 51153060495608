import { useEffect, useRef } from 'react';

import { useLazyGetAuthInfo } from '~api/auth/authQueries';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { setSessionId } from '~store/slices/signalRSocketsSlice';
import { login } from '~store/slices/userSlice';
import { setAuthCookiesData } from '~utils/cookies';

export const useListenTokens = () => {
  const { isOpen } = useAppSelector((state) => state.globalDialog);
  const dispatch = useAppDispatch();
  const { sessionId } = useAppSelector((state) => state.signalRSockets);
  const intervalRef = useRef<NodeJS.Timeout>();
  const { lazyGetAuthInfoQuery } = useLazyGetAuthInfo();

  const clearIntervalFunc = () => {
    if (intervalRef.current) {
      dispatch(setSessionId(null));
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }
  };

  const loadTokens = async (sessionId: string) => {
    try {
      const response = await lazyGetAuthInfoQuery(sessionId).unwrap();
      const { token, refreshToken, expiration } = response;

      setAuthCookiesData({ token, refreshToken, expiration });

      dispatch(login());
      dispatch(closeDialog());
      clearIntervalFunc();
      dispatch(setSessionId(null));
    } catch (e) {
      console.error('Failed to get tokens', e);
    }
  };

  useEffect(() => {
    if (sessionId) {
      intervalRef.current = setInterval(() => {
        loadTokens(sessionId);
      }, 2500);
    }
  }, [sessionId]);

  useEffect(() => {
    if (!isOpen) {
      clearIntervalFunc();
    }
  }, [isOpen, sessionId]);
};
