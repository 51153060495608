type Listener = (...args: unknown[]) => void;

class CustomEvent {
  events: { [key: string]: Listener[] } = {};

  on(event: string, listener: Listener) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.events[event].push(listener);
  }

  off(event: string, listener: Listener) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.events[event] = this.events[event]?.filter((l) => l !== listener);
  }

  emit(event: string, ...args: unknown[]) {
    this.events[event]?.forEach((listener) => listener(...args));
  }
}

export const customEvent = new CustomEvent();
