import { memo, useMemo } from 'react';

import { InPlayMenuSports } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { ToggleSportMarket } from '~components/atoms/ToggleMarket/ToggleSportMarket';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { TopEventGroupMobile } from '~components/molecules/PrematchMenu/SportMenu/TopEventGroupMobile';
import { SportSliderItem } from '~components/molecules/PrematchMenu/SportSliderItem';
import { useTopEventsSocket } from '~socket-service/hooks/useTopEventsSocket/useTopEventsSocket';

import { useLiveMenuGroups } from '../hooks/useLiveMenuGroups';
import { useLiveSportMobileMenu } from '../hooks/useLiveSportMobileMenu';

import { LiveCountryMenu } from './LiveCountryMenu';
import { LiveFavoriteEventGroup } from './LiveFavoriteEventGroup';
import { LiveMenuGroupsMobile } from './LiveMenuGroupsMobile';
export const LiveSportMenuMobile = memo(
  ({ sports }: { sports: InPlayMenuSports }) => {
    const {
      activeSportId,
      countriesData,
      isTopLiveEventsOpen,
      isFavoriteEventsOpen,
      getSportEventsCount,
      handleOnValueChange,
    } = useLiveSportMobileMenu(sports);

    const { data: topLiveEvents } = useTopEventsSocket({ isLive: true });
    const { liveGroupsMemo } = useLiveMenuGroups({ topLiveEvents });

    const touchSliderItems = useMemo(
      () =>
        sports.map(({ id, name, countries }) => (
          <SportSliderItem
            key={id}
            sport={id}
            label={name}
            count={getSportEventsCount(countries)}
            className={id.toString() === activeSportId ? 'active' : ''}
            onChange={handleOnValueChange}
          />
        )),
      [sports, getSportEventsCount, handleOnValueChange, activeSportId],
    );

    const bottomContent = useMemo(() => {
      if (isTopLiveEventsOpen) {
        return <TopEventGroupMobile isLive />;
      }

      if (isFavoriteEventsOpen) {
        return (
          <LiveFavoriteEventGroup
            sports={sports}
            sportGroupsMemo={liveGroupsMemo}
          />
        );
      }

      if (!activeSportId) return null;

      return (
        <Box
          css={{
            background: '$primaryOne',
            p: '$1',
          }}
        >
          <ToggleSportMarket sportId={parseInt(activeSportId)} />
          <LiveCountryMenu
            sportId={parseInt(activeSportId)}
            countries={countriesData}
          />
        </Box>
      );
    }, [
      countriesData,
      activeSportId,
      isTopLiveEventsOpen,
      isFavoriteEventsOpen,
    ]);

    if (
      !sports.length ||
      (!activeSportId && !(isTopLiveEventsOpen || isFavoriteEventsOpen))
    ) {
      return null;
    }

    return (
      <Box flexCol css={{ gap: '2px' }}>
        <LiveMenuGroupsMobile />
        <TouchSlider>{touchSliderItems}</TouchSlider>
        {bottomContent}
      </Box>
    );
  },
);
