import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SportGroups } from '~api/category/types';
import { TopPrematchEvents, Tournaments } from '~api/sportEvent/types';
import { RootState } from '~store';
import { SportEventsGroups, SportEventsMainData } from '~types/events';

export interface DateGroupsBySportOpenAccordion {
  sportId: string;
  dateGroups: string[];
}

export type DateGroupsBySportOpenAccordions = DateGroupsBySportOpenAccordion[];

export interface SportGroupsState {
  favoriteTournaments: Tournaments;
  isFavoriteTournamentsLoaded: boolean;
  topTournaments: Tournaments;
  isTopTournamentsLoaded: boolean;
  openSportGroups: string[];
  topPrematchEventsOpenSports: string[];
  topPrematchEventsOpenDateGroupsBySport: DateGroupsBySportOpenAccordions;
  topSportEvents: TopPrematchEvents;
  isTopSportEventsLoaded: boolean;
  favoritePrematchEvents: SportEventsMainData;
  favoritePrematchEventsLoaded: boolean;
  shouldOpenTopTournaments?: boolean;
  groups: SportEventsGroups;
  groupsData: SportGroups;
}

const initialState: SportGroupsState = {
  favoriteTournaments: [],
  isFavoriteTournamentsLoaded: false,
  topTournaments: [],
  isTopTournamentsLoaded: false,
  openSportGroups: [],
  topSportEvents: [],
  isTopSportEventsLoaded: false,
  topPrematchEventsOpenSports: [],
  topPrematchEventsOpenDateGroupsBySport: [],
  favoritePrematchEvents: [],
  favoritePrematchEventsLoaded: false,
  shouldOpenTopTournaments: true,
  groups: [],
  groupsData: [],
};

export const sportGroupsSlice = createSlice({
  name: 'sportGroups',
  initialState,
  reducers: {
    setFavoriteTournaments: (state, action: PayloadAction<Tournaments>) => {
      state.favoriteTournaments = action.payload;
    },
    setIsFavoriteTournamentsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isFavoriteTournamentsLoaded = action.payload;
    },
    setTopTournaments: (state, action: PayloadAction<Tournaments>) => {
      state.topTournaments = action.payload;
    },
    setIsTopTournamentsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isTopTournamentsLoaded = action.payload;
    },
    setOpenSportGroups: (state, action: PayloadAction<string[]>) => {
      state.openSportGroups = action.payload;
    },
    setTopSportEvents: (state, action: PayloadAction<TopPrematchEvents>) => {
      state.topSportEvents = action.payload;
    },
    setIsTopSportEventsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isTopSportEventsLoaded = action.payload;
    },
    setTopPrematchEventsOpenSports: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.topPrematchEventsOpenSports = action.payload;
    },
    setTopPrematchEventsOpenDateGroupsBySport: (
      state,
      action: PayloadAction<DateGroupsBySportOpenAccordion>,
    ) => {
      const { sportId, dateGroups } = action.payload;
      const sportIndex = state.topPrematchEventsOpenDateGroupsBySport.findIndex(
        (sport) => sport.sportId === sportId,
      );

      if (sportIndex !== -1) {
        state.topPrematchEventsOpenDateGroupsBySport[sportIndex] = {
          sportId,
          dateGroups,
        };
      }
    },
    setTopPrematchEventsOpenDateGroupsBySports: (
      state,
      action: PayloadAction<DateGroupsBySportOpenAccordions>,
    ) => {
      state.topPrematchEventsOpenDateGroupsBySport = action.payload;
    },
    setFavoritePrematchEvents: (
      state,
      action: PayloadAction<SportEventsMainData>,
    ) => {
      state.favoritePrematchEvents = action.payload;
      state.favoritePrematchEventsLoaded = true;
    },
    setShouldOpenTopTournaments: (state, action: PayloadAction<boolean>) => {
      state.shouldOpenTopTournaments = action.payload;
    },
    setGroups: (state, action: PayloadAction<SportEventsGroups>) => {
      state.groups = action.payload;
    },
    setGroupsData: (state, action: PayloadAction<SportGroups>) => {
      state.groupsData = action.payload;
    },
  },
});

const getSportGroups = (state: RootState) => state.sportGroupsState;

export const selectFavoritePrematchEvents = createSelector(
  [getSportGroups],
  (state: SportGroupsState) => state.favoritePrematchEvents,
);

export const selectOpenSportGroups = createSelector(
  [getSportGroups],
  (state: SportGroupsState) => state.openSportGroups,
);

export const {
  setFavoriteTournaments,
  setIsFavoriteTournamentsLoaded,
  setTopTournaments,
  setIsTopTournamentsLoaded,
  setOpenSportGroups,
  setTopSportEvents,
  setIsTopSportEventsLoaded,
  setTopPrematchEventsOpenSports,
  setTopPrematchEventsOpenDateGroupsBySport,
  setTopPrematchEventsOpenDateGroupsBySports,
  setFavoritePrematchEvents,
  setShouldOpenTopTournaments,
  setGroups,
  setGroupsData,
} = sportGroupsSlice.actions;

export default sportGroupsSlice.reducer;
