import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';

export const StyledTopSectionWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$4',
  width: '100%',
  backgroundColor: '$secondaryOne !important',
  padding: '$4',
  borderRadius: '$12',
  variants: {
    embedded: {
      true: {
        backgroundColor: '$secondaryOne !important',
      },
    },
  },
});

export const StyledTitleText = styled(Text, {
  textTransform: 'uppercase',
  textAlign: 'center !important',
  fontSize: '16px',
  lineHeight: '28px',
  '@lg_xl': {
    fontSize: '18px',
    lineHeight: '24px',
  },
  '@xs': {
    fontSize: '14px',
    lineHeight: '20px',
  },
});

export const StyledSupportText = styled(Text, {
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center !important',
  '@xs': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

export const StyledWorkingHoursText = styled(Text, {
  color: '$textSecondaryOne !important',
  fontSize: '14px',
  lineHeight: '20px',
  '@lg_xl': {
    fontSize: '16px',
    lineHeight: '24px',
  },
  '@xs': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

export const StyledInviteFriendsButton = styled(Button, {
  width: '100%',
  fontSize: '$18 !important',
  lineHeight: '$24 !important',
  padding: '$2 !important',
  '@sm': {
    maxWidth: '362px',
  },
  '@xs': {
    fontSize: '$16 !important',
    lineHeight: '$20 !important',
  },
});

export const StyledInfoContentWrapper = styled(Box, {
  width: '100%',
  '@lg_xl': {
    gap: '$3',
    flexDirection: 'row',
    alignItems: 'center',
    width: '1280px',
  },
  figure: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  variants: {
    embedded: {
      true: {
        width: '100%',
      },
    },
  },
});

export const StyledInfoPageContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '100vw',
  color: '$textPrimaryOne',
  backgroundColor: '$primaryOne',
  a: {
    color: '$textPrimaryOne !important',
  },
  variants: {
    embedded: {
      true: {
        maxHeight: '588px',
        overflowY: 'scroll',
        backgroundColor: 'transparent',
        padding: '$4 $5',
      },
    },
    withPaddings: {
      true: {
        padding: '$3',
      },
    },
  },
});
