import { useLazyGetSearchEventsQuery } from '~api/search/searchApi';

export const useLazyGetSearchEvents = () => {
  const [query, { data, error, isLoading }] = useLazyGetSearchEventsQuery();

  return {
    lazyGetSearchEventsQuery: query,
    lazyGetSearchEventsData: data,
    lazyGetSearchEventsError: error,
    lazyGetSearchEventsIsLoading: isLoading,
  };
};
