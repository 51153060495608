import {
  useLazyGetCasinoQuery,
  useLazyGetLikedGamesQuery,
  useLazyGetPopularQuery,
} from '~api/games/gamesApi';

export const useGetCasino = () => {
  const [query, { data, error, isLoading }] = useLazyGetCasinoQuery();

  return {
    getCasinoQuery: query,
    getCasinoData: data,
    getCasinoError: error,
    getCasinoIsLoading: isLoading,
  };
};

export const useGetPopularGames = () => {
  const [query, { data, error, isLoading }] = useLazyGetPopularQuery();

  return {
    getPopularGamesQuery: query,
    getPopularGamesData: data,
    getPopularGamesError: error,
    getPopularGamesIsLoading: isLoading,
  };
};

export const useGetLikedGames = () => {
  const [query, { data, error, isLoading }] = useLazyGetLikedGamesQuery();

  return {
    getLikedGamesQuery: query,
    getLikedGamesData: data,
    getLikedGamesError: error,
    getLikedGamesIsLoading: isLoading,
  };
};
