import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { VectorDownIcon, VectorUpIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  selectGamesState,
  setProvidersFilters,
} from '~store/slices/gamesSlice';
import { PROVIDERS_FILTER_TYPE, ProviderSortState } from '~types/providers';
import { isEmptyObject } from '~utils/objectHelpers';

import {
  ProviderFilterButton,
  ProviderFiltersWrapper,
} from './styled.components';

export const ProvidersFilters = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { providersFilters } = useAppSelector(selectGamesState);

  const getSortValue = (
    sortBy: PROVIDERS_FILTER_TYPE,
  ): boolean | ProviderSortState => {
    // Sort by popularity
    if (sortBy === PROVIDERS_FILTER_TYPE.POPULAR) {
      return !providersFilters?.[sortBy];
    }

    // Sort by amount/alphabet
    return providersFilters?.[sortBy] === 'asc' ? 'desc' : 'asc';
  };

  const handleSortChange = (sortBy: PROVIDERS_FILTER_TYPE) => {
    dispatch(setProvidersFilters({ [sortBy]: getSortValue(sortBy) }));
  };

  return (
    <ProviderFiltersWrapper>
      <ProviderFilterButton
        active={providersFilters?.popular || isEmptyObject(providersFilters)}
        variant="transparent"
        onClick={() => handleSortChange(PROVIDERS_FILTER_TYPE.POPULAR)}
      >
        <Text>
          {localized(
            `casino.providersFilters.${PROVIDERS_FILTER_TYPE.POPULAR}`,
          )}
        </Text>
      </ProviderFilterButton>
      <ProviderFilterButton
        active={!!providersFilters?.alphabet}
        variant="transparent"
        onClick={() => handleSortChange(PROVIDERS_FILTER_TYPE.ALPHABET)}
      >
        <Text>
          {localized(
            `casino.providersFilters.${PROVIDERS_FILTER_TYPE.ALPHABET}`,
          )}
        </Text>
        {providersFilters?.alphabet === 'asc' ? (
          <VectorUpIcon />
        ) : (
          <VectorDownIcon />
        )}
      </ProviderFilterButton>
      <ProviderFilterButton
        active={!!providersFilters?.amount}
        variant="transparent"
        onClick={() => handleSortChange(PROVIDERS_FILTER_TYPE.AMOUNT)}
      >
        <Text>
          {localized(`casino.providersFilters.${PROVIDERS_FILTER_TYPE.AMOUNT}`)}
        </Text>
        {providersFilters?.amount === 'asc' ? (
          <VectorUpIcon />
        ) : (
          <VectorDownIcon />
        )}
      </ProviderFilterButton>
    </ProviderFiltersWrapper>
  );
};
