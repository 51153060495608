import { ErrorData, ErrorMessage } from '~constants/payments';

export function extractErrorMessage(error: ErrorData): string | null {
  try {
    const [messageStr] = error.data.message;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const jsonMessage = messageStr.split('Error: ')[2].split(' \n ')[0];

    if (!jsonMessage) {
      return null;
    }

    const nestedError: ErrorMessage = JSON.parse(jsonMessage);

    return nestedError.message;
  } catch (e) {
    return null;
  }
}
