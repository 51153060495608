import React from 'react';
import { styled } from 'stitches.config';

import { useTranslation } from '~hooks/useTranslation';

export type IframeSidebarSubItemProps = {
  title: string;
  onClick: () => void;
  isActive: boolean;
};

const StyledIframeSidebarSubItem = styled('div', {
  height: '56px',
  width: '160px',
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  cursor: 'pointer',
  color: '$textPrimaryOne',
  gap: '8px',
  borderTop: '1px solid $primaryTwo',
  marginInlineStart: 'auto',
  fontSize: '14px',
  '&:hover': {
    color: '$accentPrimaryTwo',
  },
});

export const IframeSidebarSubItem = ({
  title,
  onClick,
  isActive,
}: IframeSidebarSubItemProps) => {
  const { localized } = useTranslation();

  return (
    <StyledIframeSidebarSubItem
      onClick={onClick}
      css={{
        color: isActive ? '$accentPrimaryTwo' : '$textPrimaryOne',
        fontWeight: isActive ? 500 : 400,
      }}
    >
      {localized(title).toUpperCase()}
    </StyledIframeSidebarSubItem>
  );
};
