import React from 'react';

import { TextWithTooltip } from '~components/atoms/TextWithTooltip';
import { useTranslation } from '~hooks/useTranslation';
import {
  statusTranslationsMap,
  TRANSACTION_STATUS_CODE_COLORS,
  TransactionHistoryCellProps,
} from '~types/transactions';

export const StatusCell = ({
  row: {
    original: { status },
  },
}: TransactionHistoryCellProps) => {
  const { localized } = useTranslation();

  return (
    <TextWithTooltip
      color={TRANSACTION_STATUS_CODE_COLORS[status]}
      text={localized(statusTranslationsMap[status])}
    />
  );
};
