import React from 'react';

import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

interface DocumentUploadButtonProps {
  documentType: string | null;
  file: File | null;
  handleFileUpload: () => void;
}

export const DocumentUploadButton = ({
  documentType,
  file,
  handleFileUpload,
}: DocumentUploadButtonProps) => {
  const { localized } = useTranslation();

  return (
    <Button
      size="medium"
      onClick={handleFileUpload}
      disabled={!file || !documentType}
      css={{
        width: '128px',
        height: '32px',
        borderRadius: '$6',
        '@xs_sm': {
          width: '100%',
          height: 'auto',
        },
      }}
    >
      <Text
        isButton
        fontWeight="bold"
        textAlign="center"
        textTransform="uppercase"
        css={{
          fontSize: '$14',
          lineHeight: '$16',
          '@xs_sm': {
            fontSize: '$16',
            lineHeight: '$20',
          },
        }}
      >
        {localized('userProfile.personalProfileInfo.upload')}
      </Text>
    </Button>
  );
};
