import React from 'react';

import { BetslipMessageError } from '~components/molecules/Betslip/components/BetslipStakes/BetslipMessageError';
import { BetslipMessageSuccess } from '~components/molecules/Betslip/components/BetslipStakes/BetslipMessageSuccess';

interface BetslipInfoMessageProps {
  isQuickBet?: boolean;
  isProcessingFailed?: boolean;
  betslipErrorMessages?: string[];
  handleErrorMessageContinueClick: () => void;
}

export const BetslipInfoMessage = ({
  isQuickBet = false,
  isProcessingFailed,
  betslipErrorMessages,
  handleErrorMessageContinueClick,
}: BetslipInfoMessageProps) =>
  isProcessingFailed ? (
    <BetslipMessageError
      isQuickBet={isQuickBet}
      errorMessages={betslipErrorMessages}
      handleErrorMessageContinueClick={handleErrorMessageContinueClick}
    />
  ) : (
    <BetslipMessageSuccess isQuickBet={isQuickBet} />
  );
