import { FooterDesktop } from '~components/organisms/FooterComponent/FooterDesktop';
import { MobileFooter } from '~components/organisms/FooterComponent/MobileFooter';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useMedia } from '~hooks';
import useIsInIframe from '~hooks/useIsInIframe';

interface FooterComponentProps {
  path: ROUTE_TAB_NAMES;
}

export const FooterComponent = ({ path }: FooterComponentProps) => {
  const isInfoPage = path === ROUTE_TAB_NAMES.INFO;
  const isInFrame = useIsInIframe();
  const { isMobileOrTablet } = useMedia();

  if (isInFrame) {
    return null;
  }

  return !isMobileOrTablet ? (
    <FooterDesktop isInfoPage={isInfoPage} />
  ) : (
    <MobileFooter />
  );
};
