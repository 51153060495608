import { useEffect, useMemo, useState } from 'react';

import { LiveGroups } from '~api/category/types';
import { TopPrematchEvents } from '~api/sportEvent/types';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { FavoriteGameIcon, GameIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsFavoriteEventsOpen,
  setIsTopLiveEventsOpen,
  setOpenLiveGroups,
} from '~store/slices/liveGroupsSlice';
import { setOpenedSports } from '~store/slices/liveMenuSlice';
import { updateLiveMenuState } from '~store/slices/menusSlice';
import { LiveEventsGroups } from '~types/events';
import { SPORT_GROUPS } from '~types/sportGroups';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

import { LIVE_GROUP_COMPONENTS } from '../constants';

export const useLiveMenuGroups = ({
  topLiveEvents = [],
}: {
  topLiveEvents: TopPrematchEvents;
}) => {
  const { sportId, countryId, leagueId, menu } = useQueryParams();
  const { liveMenuSports: sports } = useAppSelector((state) => state.liveMenu);

  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { isTopLiveEventsOpen, openLiveGroups, isFavoriteEventsOpen } =
    useAppSelector((state) => state.liveGroupsState);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { favoriteInplayEvents = [] } = useAppSelector(
    (state) => state.liveGroupsState,
  );
  const { liveMenuSports } = useAppSelector((state) => state.liveMenu);
  const [activeGroup, setActiveGroup] = useState<LIVE_GROUP_COMPONENTS | null>(
    null,
  );

  let defaultSportId = sportId;

  if (!defaultSportId && sports && sports[0]) {
    defaultSportId = sports[0].id.toString();
  }

  const handleTopEventsClickMobile = () => {
    setActiveGroup(LIVE_GROUP_COMPONENTS.TOP_EVENT);

    dispatch(setIsTopLiveEventsOpen(true));
    dispatch(setIsFavoriteEventsOpen(false));
    updateQueryParams({
      [QUERY_PARAMS.GROUP]: SPORT_GROUPS.TOP_EVENTS,
    } as QueryParams<typeof QUERY_PARAMS>);
  };

  const handleFavoriteEventsClickMobile = () => {
    setActiveGroup(LIVE_GROUP_COMPONENTS.FAVORITE_EVENT);
    dispatch(setIsFavoriteEventsOpen(true));
    dispatch(setIsTopLiveEventsOpen(false));
    updateQueryParams(
      {
        [QUERY_PARAMS.MENU]: menu,
        [QUERY_PARAMS.SPORT_ID]: sportId,
        [QUERY_PARAMS.COUNTRY_ID]: countryId,
        [QUERY_PARAMS.LEAGUE_ID]: leagueId,
        [QUERY_PARAMS.GROUP]: undefined,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );
  };

  const handleOnValueChange = async (e: string[]) => {
    dispatch(setOpenLiveGroups(e));
  };

  const groupsMemo: LiveEventsGroups = useMemo(() => {
    const groups: LiveEventsGroups = [];

    if (topLiveEvents.length) {
      groups.push({
        name: LIVE_GROUP_COMPONENTS.TOP_EVENT,
        label: localized('inplay.topEvents'),
        id: 1,
        icon: GameIcon,
        onClick: handleTopEventsClickMobile,
      });
    }

    if (favoriteInplayEvents.length && isUserLoggedIn) {
      groups.push({
        name: LIVE_GROUP_COMPONENTS.FAVORITE_EVENT,
        label: localized('inplay.favoriteEvents'),
        id: 2,
        icon: FavoriteGameIcon,
        onClick: handleFavoriteEventsClickMobile,
      });
    }

    return groups;
  }, [topLiveEvents, favoriteInplayEvents, isUserLoggedIn]);

  const groupCountsMemo = useMemo(() => {
    const favoriteInplayEventsCount = favoriteInplayEvents.length || 0;
    const topEventsCount = topLiveEvents.reduce((acc, { dateGroups }) => {
      return (
        acc + dateGroups.reduce((acc, { matches }) => acc + matches.length, 0)
      );
    }, 0);

    return {
      [LIVE_GROUP_COMPONENTS.FAVORITE_EVENT]: favoriteInplayEventsCount,
      [LIVE_GROUP_COMPONENTS.TOP_EVENT]: topEventsCount,
    };
  }, [topLiveEvents, favoriteInplayEvents, isUserLoggedIn]);

  const liveGroupsMemo = useMemo(() => {
    const sportGroups: LiveGroups = (liveMenuSports || [])?.map(
      ({ id, name }) => ({
        id,
        name,
        favoriteEvents: [],
      }),
    );

    favoriteInplayEvents.forEach((event) => {
      sportGroups?.forEach((sportGroup) => {
        if (sportGroup.id === event.sportId) {
          sportGroup.favoriteEvents = [...sportGroup.favoriteEvents, event];
        }
      });
    });

    return sportGroups;
  }, [liveMenuSports, favoriteInplayEvents]);

  useEffect(() => {
    if (isTopLiveEventsOpen) {
      setActiveGroup(LIVE_GROUP_COMPONENTS.TOP_EVENT);
    }

    if (isFavoriteEventsOpen) {
      setActiveGroup(LIVE_GROUP_COMPONENTS.FAVORITE_EVENT);
    }

    if (!isTopLiveEventsOpen && !isFavoriteEventsOpen) {
      setActiveGroup(null);
    }
  }, [isTopLiveEventsOpen, isFavoriteEventsOpen]);

  useEffect(() => {
    if (!favoriteInplayEvents.length) {
      if (isFavoriteEventsOpen) {
        dispatch(setIsFavoriteEventsOpen(false));
        dispatch(setOpenedSports(defaultSportId!));

        updateQueryParams({
          [QUERY_PARAMS.SPORT_ID]: defaultSportId,
          [QUERY_PARAMS.MENU]: SPORT_MENUS.LIVE,
        } as QueryParams<typeof QUERY_PARAMS>);

        dispatch(updateLiveMenuState({ sportId: defaultSportId }));
      }
    }
  }, [favoriteInplayEvents, isFavoriteEventsOpen]);

  return {
    liveMenuSports,
    groupsMemo,
    groupCountsMemo,
    openLiveGroups,
    liveGroupsMemo,
    activeGroup,
    handleOnValueChange,
  };
};
