import { useEffect, useState } from 'react';

import { TopPrematchEvents } from '~api/sportEvent/types';
import useSocket from '~socket-service';
import { SOCKET_TYPE } from '~socket-service/constants';
import { useAppSelector } from '~store';
import { selectLanguage } from '~store/slices/websiteSettings';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

type UseTopEventsSocketProp = {
  shouldListenUpdates?: boolean;
  isLive?: boolean;
};

export const useTopEventsSocket = ({
  // shouldListenUpdates, // use it for listen updates when back-end is ready
  isLive,
}: UseTopEventsSocketProp = {}): {
  data: TopPrematchEvents;
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { dateToFilter } = useAppSelector((state) => state.prematchMenu);

  const {
    listening: listeningWebSocket,
    sendMessage: sendWSMessage,
    isSocketReady: isWSSocketReady,
  } = useSocket(SOCKET_TYPE.WEB);

  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    if (!isWSSocketReady) return;
    setIsLoading(true);
    sendWSMessage(ACTION_TYPE.GET_TOP_GAMES, {
      Language: language,
      DateTo: dateToFilter,
      IsLive: isLive,
    });
  }, [isWSSocketReady, isLive]);

  useEffect(() => {
    if (!isWSSocketReady) return;

    const stopListeningWebSocket = listeningWebSocket({
      [ACTION_TYPE.GET_TOP_GAMES]: (data) => {
        setData(data);
        setIsLoading(false);
      },
    });

    return () => {
      stopListeningWebSocket();
    };
  }, [isWSSocketReady]);

  return {
    isLoading,
    data,
  };
};
