import React, { forwardRef } from 'react';
import * as AccordionUI from '@radix-ui/react-accordion';
import { CSS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

import {
  StyledChevron,
  StyledContent,
  StyledHeader,
  StyledTrigger,
} from './styled.components';

interface AccordionTriggerProps {
  children: React.ReactNode;
}

export const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledChevron />
    </StyledTrigger>
  </StyledHeader>
));

interface AccordionContentProps {
  children: React.ReactNode;
  css?: CSS;
}

export const AccordionContent = forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, css, ...props }, forwardedRef) => (
  <StyledContent {...props} css={css} ref={forwardedRef}>
    <Box className="contentWrapper">{children}</Box>
  </StyledContent>
));

export const AccordionItem = styled(AccordionUI.Item, {
  overflow: 'hidden',
  position: 'relative',
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
  borderBottom: '1px solid $primaryOne',
  '&:first-child': {
    borderTop: '1px solid $primaryOne',
  },
  '&:last-child': {
    borderRadius: '0 0 $8 $8',
  },
});

export const AccordionRoot = styled(AccordionUI.Root, {
  width: '100%',
});
