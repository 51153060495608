import { ProvidersFilters } from '~components/molecules/Providers/components/ProvidersFilters';
import { ProvidersModalWrapper } from '~components/molecules/Providers/styled.components';
import { ProvidersList } from '~components/molecules/ProvidersList';
import { useAppSelector } from '~store';
import { selectGames, selectSortedProviders } from '~store/slices/gamesSlice';

export const Providers = () => {
  const sortedProviders = useAppSelector(selectSortedProviders);
  const games = useAppSelector(selectGames);

  const providers = sortedProviders
    .map((provider) => {
      const gameCount = games.filter(
        ({ gameProviderId }) => gameProviderId === provider.id,
      ).length;

      return { ...provider, gameCount };
    })
    .filter(({ gameCount }) => gameCount);

  return (
    <ProvidersModalWrapper>
      {providers.length > 4 && <ProvidersFilters />}
      <ProvidersList
        customStyles={{ border: '1px solid $secondaryOne' }}
        providers={providers}
      />
    </ProvidersModalWrapper>
  );
};
