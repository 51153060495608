import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { USER_DEFAULT_BALANCE } from '~store/slices/userSlice';

import {
  GetUsersResponse,
  PromoCodePayload,
  UserChangePasswordPayload,
  UserDocumentResponse,
  UserProfileData,
  UserProfilePayload,
  UserSessionsResponse,
  VerifyUserIdPayload,
} from './types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.ID }),
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponse, void>({
      query: () => ({
        url: 'User',
        method: 'GET',
      }),
    }),
    getUserProfile: builder.query<UserProfileData, void>({
      query: () => ({
        url: 'Profile',
        method: 'GET',
      }),
    }),
    updateUserProfile: builder.mutation<UserProfileData, UserProfilePayload>({
      query: (data) => ({
        url: 'Profile',
        method: 'POST',
        body: data,
      }),
    }),
    changeUserPassword: builder.mutation<void, UserChangePasswordPayload>({
      query: (data) => ({
        url: 'User/changePassword',
        method: 'POST',
        body: data,
      }),
    }),
    getUserSessions: builder.query<
      UserSessionsResponse,
      { limit: number; page: number }
    >({
      query: ({ limit, page }) => ({
        url: `UserSession?Limit=${limit}&Page=${page}`,
        method: 'GET',
      }),
    }),
    terminateOtherSessions: builder.mutation<void, void>({
      query: () => ({
        url: 'UserSession/terminateOther',
        method: 'POST',
      }),
    }),
    applyPromoCode: builder.mutation<void, PromoCodePayload>({
      query: (data) => ({
        url: 'Profile/promo',
        method: 'POST',
        body: data,
      }),
    }),
    getUserDocuments: builder.query<UserDocumentResponse[], void>({
      query: () => ({
        url: 'profile/document',
        method: 'GET',
      }),
    }),
    uploadUserDocument: builder.mutation<void, FormData>({
      query: (data) => ({
        url: 'profile/document',
        method: 'POST',
        body: data,
      }),
    }),
    idVerify: builder.mutation<boolean, VerifyUserIdPayload>({
      query: (data) => ({
        url: 'IdVerification',
        method: 'POST',
        body: data,
      }),
    }),
    changeDefaultBalance: builder.mutation<void, USER_DEFAULT_BALANCE>({
      query: (balanceType) => ({
        url: 'User/changeDefaultBalance',
        method: 'POST',
        body: {
          balanceType,
        },
      }),
    }),
    deactivateUser: builder.mutation<void, void>({
      query: () => ({
        url: 'Profile/deactivate',
        method: 'POST',
      }),
    }),
    callUserBack: builder.mutation<void, string>({
      query: (phoneNumber) => ({
        url: 'CallbackRequest',
        method: 'POST',
        body: {
          phoneNumber,
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useChangeUserPasswordMutation,
  useLazyGetUserSessionsQuery,
  useTerminateOtherSessionsMutation,
  useApplyPromoCodeMutation,
  useLazyGetUserDocumentsQuery,
  useUploadUserDocumentMutation,
  useChangeDefaultBalanceMutation,
  useIdVerifyMutation,
  useDeactivateUserMutation,
  useCallUserBackMutation,
} = userApi;
