import React from 'react';

import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { StyledFooterCloseModalWrapper } from '~components/organisms/FooterComponent/FooterCloseModal/styled.components';
import { useRouterQuery, useTranslation } from '~hooks';

export const FooterCloseModal = () => {
  const { navigateHome } = useRouterQuery();
  const { localized } = useTranslation();

  return (
    <StyledFooterCloseModalWrapper>
      <Button
        size="small"
        variant="secondary"
        fullWidth
        onClick={navigateHome}
        css={{
          height: '32px',
          borderRadius: '$6',
          backgroundColor: '$primaryTwo',
        }}
      >
        <Text
          color="textPrimaryOne"
          level="12-24"
          fontWeight="medium"
          textAlign="center"
        >
          {localized('userProfile.personalProfileInfo.close')}
        </Text>
      </Button>
    </StyledFooterCloseModalWrapper>
  );
};
