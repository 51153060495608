import React, { ReactNode, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ScrollToTopButton } from '~components/atoms/ScrollToTopButton';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { MOBILE_TAB_HEIGHT } from '~constants/ui';
import useIsInIframe from '~hooks/useIsInIframe';
import { useAppSelector } from '~store';

const StyledMobileContentContainer = styled(Box, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  '-webkit-overflow-scrolling': 'touch',
});

export interface MobileContentContainerProps {
  children: ReactNode;
}

const noPaddingPages: string[] = [
  ROUTE_TAB_NAMES.BETSLIP,
  ROUTE_TAB_NAMES.PROFILE,
  ROUTE_TAB_NAMES.TRANSACTIONS,
  ROUTE_TAB_NAMES.SETTINGS,
  ROUTE_TAB_NAMES.BONUSES,
  ROUTE_TAB_NAMES.HISTORY,
  ROUTE_TAB_NAMES.PROFILE_DETAILS,
  ROUTE_TAB_NAMES.INVITE_FRIENDS,
  ROUTE_TAB_NAMES.PAYMENTS,
];

export const MobileContentContainer = ({
  children,
}: MobileContentContainerProps) => {
  const { mobileHeaderHeight } = useAppSelector((state) => state.mobileState);
  const componentRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [, secondSegment] = pathname.split('/');

  const isInFrame = useIsInIframe();

  return (
    <StyledMobileContentContainer>
      <Box
        flexCol
        gap={1}
        css={{
          pt: `${mobileHeaderHeight + 4}px`,
          pb: !noPaddingPages.includes(secondSegment || '')
            ? `calc(${MOBILE_TAB_HEIGHT}px + ${isInFrame ? '0.25rem' : '0px'})`
            : 0,
        }}
        ref={componentRef}
      >
        {children}
      </Box>
      <ScrollToTopButton scrollableContainerRef={componentRef} />
    </StyledMobileContentContainer>
  );
};
