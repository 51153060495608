import { useLazyGetLeaguesByCountryQuery } from './categoryApi';

export const useLazyGetLeaguesByCountry = () => {
  const [query, { data, error, isLoading }] = useLazyGetLeaguesByCountryQuery();

  return {
    lazyGetLeaguesByCountryQuery: query,
    lazyGetLeaguesByCountryData: data,
    lazyGetLeaguesByCountryError: error,
    lazyGetLeaguesByCountryIsLoading: isLoading,
  };
};
