import { useMemo, useState } from 'react';

import { Box } from '~components/atoms/Box';
import {
  StyledCloseButton,
  StyledInfoText,
  StyledInfoTextWrapper,
  StyledPopoverTrigger,
} from '~components/atoms/InfoPopover/styled.components';
import { InfoItem } from '~components/atoms/InfoPopover/types';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { CloseIcon, InfoIcon } from '~icons';

interface InfoPopoverProps {
  title: string;
  itemsList: InfoItem[];
  iconWidth?: number;
  iconHeight?: number;
}

export const InfoPopover = ({
  title,
  itemsList,
  iconWidth = 12,
  iconHeight = 12,
}: InfoPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobileOrTablet } = useMedia();

  const memoizedItems = useMemo(
    () =>
      itemsList.map(({ value, label, color }) => (
        <StyledInfoTextWrapper key={label} color={color}>
          <StyledInfoText textTransform="capitalize" color={color}>
            {label}
          </StyledInfoText>
          <StyledInfoText
            fontWeight="medium"
            color={color || 'accentPrimaryTwo'}
          >
            {value}
          </StyledInfoText>
        </StyledInfoTextWrapper>
      )),
    [itemsList],
  );

  return (
    <Box flexRow>
      <Popover isOpen={isOpen}>
        <PopoverContent
          noArrow={isMobileOrTablet}
          side="bottom"
          sideOffset={isMobileOrTablet ? 16 : 8}
          align="center"
          tone="secondary"
          width={isMobileOrTablet ? '100vw' : 300}
          css={{
            mr: '14px',
            p: '$4',
            background: '$secondaryTwo',
            '@xs_sm': {
              mr: 'unset',
              background: '$footerBackgroundOpacity',
              backdropFilter: 'blur(4px)',
              borderRadius: '$12',
            },
          }}
        >
          <Box
            flexCol
            css={{
              '@xs_sm': {
                p: '$4',
                background: '$secondaryTwo',
                borderRadius: '$8',
                position: 'relative',
              },
            }}
          >
            <Box flexCol gap={3}>
              {isMobileOrTablet && (
                <StyledCloseButton onClick={() => setIsOpen(false)}>
                  <CloseIcon />
                </StyledCloseButton>
              )}

              <Text level="14-20" textTransform="uppercase">
                {title}
              </Text>

              <Box flexCol gap={1}>
                {memoizedItems}
              </Box>
            </Box>
          </Box>
        </PopoverContent>
        <PopoverTrigger asChild>
          <StyledPopoverTrigger
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <InfoIcon width={iconWidth} height={iconHeight} />
          </StyledPopoverTrigger>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};
