import React from 'react';

import { Box } from '~components/atoms/Box';
import { InfoPopover } from '~components/atoms/InfoPopover';
import { InfoItem } from '~components/atoms/InfoPopover/types';
import { Loader } from '~components/atoms/Loader';
import {
  StyledBetslipInfoWrapper,
  StyledBetslipText,
} from '~components/molecules/Betslip/components/styled.components';
import { useFormatAmountWithCurrency, useTranslation } from '~hooks';

interface BetslipPossibleWinProps {
  payout: number;
  payoutInfoItems: InfoItem[];
  isPossibleWinLoading?: boolean;
}

export const BetslipPayout = ({
  payout,
  payoutInfoItems,
  isPossibleWinLoading = false,
}: BetslipPossibleWinProps) => {
  const { localized } = useTranslation();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  return (
    <StyledBetslipInfoWrapper>
      <Box flexRow alignCenter gap={1} css={{ color: '$textSecondaryOne' }}>
        <StyledBetslipText underline>
          {localized('betslip.payout')}
        </StyledBetslipText>
        {payout > 0 && (
          <InfoPopover
            title={`${localized('betslip.payoutPopover.info')}:`}
            itemsList={payoutInfoItems}
          />
        )}
      </Box>

      {isPossibleWinLoading ? (
        <Loader
          css={{
            width: '13px',
            height: '13px',
            color: '$yellowPrimary',
            display: 'flex',
            alignItems: 'center',
          }}
        />
      ) : (
        <StyledBetslipText color="yellowPrimary">
          {getCommaSeparatedAmount(payout)}
        </StyledBetslipText>
      )}
    </StyledBetslipInfoWrapper>
  );
};
