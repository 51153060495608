import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useAppSelector } from '~store';

export const useLeagueEventConnection = ({ eventId }: { eventId: string }) => {
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const [socket, setSocket] = useState<HubConnection>();

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  useEffect(() => {
    if (!socket || !eventId) return;

    socket.invoke(SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_FIXTURE_MESSAGE, eventId);

    return () => {
      socket?.invoke(SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_FIXTURE_MESSAGE);
    };
  }, [socket, eventId]);
};
