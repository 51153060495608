import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Label } from '~components/atoms/Label';
import { PasswordRequirements } from '~components/atoms/PasswordRequirements';
import { PasswordRequirementsPopover } from '~components/atoms/PasswordRequirementsPopover';
import { PasswordStrengthIndicator } from '~components/atoms/PasswordStrengthIndicator';
import { Separator } from '~components/atoms/Separator';
import { ErrorMessage, Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { EyeIcon, EyeSlashIcon, GreenCheckIcon } from '~icons';

import { StyledHideShowPasswordWrapper } from '../ChangePasswordForm';

import { ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS } from './constants';
import { useChangePasswordForm } from './useChangePasswordForm';

interface ChangePasswordFormProps {
  onTogglePasswordChanged: (val: boolean) => void;
  isPasswordChanged?: boolean;
}

export const ChangePasswordForm = ({
  onTogglePasswordChanged,
  isPasswordChanged: isPasswordChangedProp,
}: ChangePasswordFormProps) => {
  const { localized, localizedError } = useTranslation();
  const {
    isPasswordPopoverOpen,
    setIsPasswordPopoverOpen,
    showNewPassword,
    setShowNewPassword,
    showCurrentPassword,
    setShowCurrentPassword,
    passwordStrength,
    isValidPassword,
    handleNewPasswordOnChange,
    onPasswordBlur,
    errors,
    register,
    newPassword,
    onSubmit,
    changeUserPasswordIsLoading,
    localizedCharacteristicsItems,
    isMobileOrTablet,
    eyeIconSizes,
    isPasswordChanged,
    isChangePasswordDisabled,
  } = useChangePasswordForm({ onTogglePasswordChanged });

  return (
    <Box
      flexRow
      fullHeight
      fullWidth
      gap={2}
      alignEnd
      css={{
        '@xs_sm': {
          flexDirection: 'column',
          alignItems: 'initial',
          p: '$2 $3 $3',
          gap: '0 !important',
          backgroundColor: '$primaryTwo',
          borderRadius: '$8',
        },
      }}
    >
      <Box
        css={{
          '@xs_sm': {
            mb: '$2',
          },
        }}
      >
        <Box
          flexRow
          justifyContentBetween
          css={{
            color: '$textSecondaryOne',
            width: '260px',
            '@xs_sm': {
              width: 'auto',
            },
          }}
        >
          <Label
            htmlFor={
              ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.CURRENT_PASSWORD_FIELD
            }
            smallFont
            inheritColors
          >
            {localized(
              'userProfile.personalProfileInfo.accountSettings.changePassword.currentPassword.label',
            )}
          </Label>
          {!!errors.currentPassword && (
            <ErrorMessage message={errors.currentPassword.message!} />
          )}
        </Box>
        <Box
          css={{
            position: 'relative',
          }}
        >
          <Input
            id={ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.CURRENT_PASSWORD_FIELD}
            personalDetail
            inputBorderFocus
            autoComplete="new-password"
            css={{
              backgroundColor: '$secondaryTwo',
              width: '100%',
              height: '32px',
              color: errors.currentPassword && '$redPrimary',
              boxShadow: errors.currentPassword
                ? '$elementErrorBorder'
                : 'none',
              '@xs_sm': {
                height: '$7',
              },
            }}
            placeholder={localized(
              'userProfile.personalProfileInfo.accountSettings.changePassword.currentPassword.placeholder',
            )}
            {...register(
              ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.CURRENT_PASSWORD_FIELD,
            )}
            type={showCurrentPassword ? 'text' : 'password'}
          />
          <StyledHideShowPasswordWrapper
            shouldHover
            role="button"
            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
          >
            {showCurrentPassword ? (
              <EyeIcon {...eyeIconSizes} />
            ) : (
              <EyeSlashIcon {...eyeIconSizes} />
            )}
          </StyledHideShowPasswordWrapper>
        </Box>
      </Box>
      <Box
        css={{
          '@xs_sm': {
            mb: '$1',
          },
        }}
      >
        <Box flexRow justifyContentBetween css={{ color: '$textSecondaryOne' }}>
          <Label
            htmlFor={ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD}
            smallFont
            inheritColors
          >
            {localized(
              'userProfile.personalProfileInfo.accountSettings.changePassword.newPassword.label',
            )}
          </Label>
          {!!newPassword && !errors.newPassword && isValidPassword && (
            <PasswordStrengthIndicator
              strength={passwordStrength}
              isCompactView
              smallFont
              containerBgColor="primaryTwo"
              css={{ mb: '$1' }}
            />
          )}
        </Box>
        <Box
          css={{
            position: 'relative',
            width: '260px',
            '@xs_sm': {
              width: 'auto',
            },
          }}
        >
          <Input
            id={ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD}
            personalDetail
            inputBorderFocus
            css={{
              backgroundColor: '$secondaryTwo',
              width: '100%',
              height: '32px',
              boxShadow: errors.newPassword ? '$elementErrorBorder' : 'none',
              color: errors.newPassword && '$redPrimary',
              '@xs_sm': {
                height: '$7',
              },
            }}
            placeholder={localized(
              'userProfile.personalProfileInfo.accountSettings.changePassword.newPassword.placeholder',
            )}
            {...register(
              ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD,
              {
                validate: {
                  passwordRequirementsError: () => {
                    return (
                      isValidPassword ||
                      localizedError('password.requirementsNotMet')
                    );
                  },
                },
              },
            )}
            onFocus={() => setIsPasswordPopoverOpen(true)}
            onBlur={(e) => {
              onPasswordBlur(e);
              setIsPasswordPopoverOpen(false);
            }}
            onChange={handleNewPasswordOnChange}
            type={showNewPassword ? 'text' : 'password'}
          />
          <StyledHideShowPasswordWrapper
            shouldHover
            role="button"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? (
              <EyeIcon {...eyeIconSizes} />
            ) : (
              <EyeSlashIcon {...eyeIconSizes} />
            )}
          </StyledHideShowPasswordWrapper>

          {isMobileOrTablet ? (
            isPasswordPopoverOpen ? (
              <Box
                css={{
                  p: '$2 $3',
                  margin: '$2 0',
                  backgroundColor: '$primaryTwo',
                  borderRadius: '$8',
                  border: '1px solid $secondaryOne',
                }}
              >
                <PasswordRequirements
                  localizedCharacteristicsItems={localizedCharacteristicsItems}
                />
              </Box>
            ) : null
          ) : (
            <PasswordRequirementsPopover
              isPasswordPopoverOpen={isPasswordPopoverOpen}
              localizedCharacteristicsItems={localizedCharacteristicsItems}
            />
          )}
        </Box>
      </Box>
      {isMobileOrTablet && (
        <Separator
          verticalSpace={3}
          shrinkOut={3}
          css={{ backgroundColor: '$primaryTwo' }}
        />
      )}
      <Button
        size="small"
        fullWidth
        css={{
          width: '168px',
          height: '32px',
          borderRadius: '$6',
          px: '0',
          '@xs_sm': {
            width: '100%',
            height: '$7',
          },
        }}
        isLoading={changeUserPasswordIsLoading}
        onClick={onSubmit}
        disabled={isChangePasswordDisabled || isPasswordChanged}
      >
        <Text isButton level="14-20" fontWeight="bold" textAlign="center">
          {localized(
            `userProfile.personalProfileInfo.accountSettings.changePassword.${
              isPasswordChanged ? 'changedButton' : 'button'
            }`,
          )}
        </Text>
      </Button>
      {isPasswordChangedProp && isMobileOrTablet && (
        <Box
          flexCol
          gap={2}
          justifyCenter
          alignCenter
          css={{
            width: 'calc(100vw - 32px)',
            position: 'absolute',
            borderRadius: '$8',
            top: '120px',
            left: '$4',
            padding: '$4',
            backgroundColor: '$greenSecondary',
          }}
        >
          <GreenCheckIcon width={26} height={26} />
          <Text textTransform="uppercase" textAlign="center" level="14-20">
            {localized(
              'userProfile.personalProfileInfo.accountSettings.changePassword.passwordChangedTitle',
            )}
          </Text>
          <Text textTransform="uppercase" textAlign="center" level="12-20">
            {localized(
              'userProfile.personalProfileInfo.accountSettings.changePassword.passwordChangedDescription',
            )}
          </Text>
        </Box>
      )}
    </Box>
  );
};
