import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { TimeIcon } from '~icons';

interface MatchStatusProps {
  matchStatus: string | null | undefined;
  time?: string | null | undefined;
}

export const MatchStatus = ({ matchStatus, time }: MatchStatusProps) => {
  const { isMobileOrTablet } = useMedia();

  if (!matchStatus && !time) {
    return null;
  }

  return (
    <Box
      css={{
        mb: '$2',
        '@xs_sm': {
          backgroundColor: '$primaryTwoOpacity',
          mb: 0,
        },
      }}
      flexRow
      justifyCenter={!isMobileOrTablet}
      justifyContentStart={isMobileOrTablet}
      alignCenter
      gap={2}
    >
      <Box
        css={{
          flexGrow: 1,
          width: isMobileOrTablet ? 'min-content' : `calc((100% - 15px) / 2)`,
          '@xs': {
            flexGrow: 0,
          },
          '@sm': {
            flexGrow: 0,
          },
        }}
      >
        <Text
          level="sm2-4"
          fontWeight="medium"
          textAlign={time ? 'right' : 'center'}
          css={{
            '@xs': {
              width: 'max-content',
              p: '$2',
            },
            '@sm': {
              width: 'max-content',
              p: '$2',
            },
          }}
        >
          {matchStatus}
        </Text>
      </Box>

      {time && (
        <>
          <Box css={{ color: '$textPrimaryOne', lineHeight: '0' }}>
            <TimeIcon width={15} height={15} />
          </Box>
          <Box
            css={{
              flexGrow: 1,
              width: `calc((100% - 15px) / 2)`,
            }}
          >
            <Text level="sm2-4" fontWeight="medium">
              {time}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};
