import { ChangeEvent } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import dayjs from 'dayjs';

import { DATE_FORMATS } from '~utils/formatDateTime';
import { onlyCharsWithSpaces, removeEmptySpaces } from '~utils/sanitizers';

import {
  PERSONAL_DETAILS_FORM_FIELDS,
  PersonalDetailsFormInputs,
} from '../constants';

export const useFieldChangeHandlers = (
  setValue: UseFormSetValue<PersonalDetailsFormInputs>,
) => {
  const handleChange =
    (field: PERSONAL_DETAILS_FORM_FIELDS, sanitizer: (str: string) => string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const sanitizedValue = sanitizer(event.target.value);

      setValue(field, sanitizedValue);
    };

  return {
    handleUserNameChange: handleChange(
      PERSONAL_DETAILS_FORM_FIELDS.USER_FIRST_NAME_FIELD,
      onlyCharsWithSpaces,
    ),
    handleUserSurnameChange: handleChange(
      PERSONAL_DETAILS_FORM_FIELDS.USER_LAST_NAME_FIELD,
      onlyCharsWithSpaces,
    ),
    handleUserEmailChange: handleChange(
      PERSONAL_DETAILS_FORM_FIELDS.USER_EMAIL_FIELD,
      removeEmptySpaces,
    ),
    handleMiddleNameChange: handleChange(
      PERSONAL_DETAILS_FORM_FIELDS.USER_MIDDLE_NAME_FIELD,
      onlyCharsWithSpaces,
    ),
    handleIdNumberChange: handleChange(
      PERSONAL_DETAILS_FORM_FIELDS.USER_ID_FIELD,
      removeEmptySpaces,
    ),
    handlePhoneNumberUpdate: (input: string): void => {
      setValue(PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD, input);
    },
    handleBirthDateUpdate: (date: dayjs.Dayjs) => {
      setValue(
        PERSONAL_DETAILS_FORM_FIELDS.BIRTH_DATE_FIELD,
        date ? date.format(DATE_FORMATS.DATE_YEAR_FIRST) : null,
      );
    },
    handleGenderUpdate: (value: string | number) => {
      setValue(
        PERSONAL_DETAILS_FORM_FIELDS.USER_GENDER_FIELD,
        value.toString(),
      );
    },
  };
};
