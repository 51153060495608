import { ReactNode, useEffect, useRef } from 'react';

import { Box } from '~components/atoms/Box';
import { useAppDispatch } from '~store';
import { setMobileHeaderHeight } from '~store/slices/mobileSlice';
import { zIndex } from '~utils/zIndex';

interface MobileHeaderContainerProps {
  children: ReactNode;
}

export const MobileHeaderContainer = ({
  children,
}: MobileHeaderContainerProps) => {
  const componentRef = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (componentRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === componentRef.current) {
            const height = entry.contentRect.height;

            dispatch(setMobileHeaderHeight(height));
          }
        }
      });

      observer.observe(componentRef.current);

      return () => {
        dispatch(setMobileHeaderHeight(0));
        observer.disconnect();
      };
    }

    return undefined;
  }, []);

  return (
    <Box
      ref={componentRef}
      css={{
        '@xs_sm': {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: zIndex.fixed,
        },
      }}
    >
      {children}
    </Box>
  );
};
