import { PayoutInfo } from '~api/betslip/types';
import { InfoItem } from '~components/atoms/InfoPopover/types';
import { STAKE_TAX_PAYER } from '~components/molecules/Betslip/constants';
import { useFormatAmountWithCurrency } from '~hooks/useFormatAmountWithCurrency';
import { useTranslation } from '~hooks/useTranslation';

export const useStakePayout = (payoutInfo: PayoutInfo) => {
  const { localized } = useTranslation();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  const {
    stake,
    stakeAfterTax,
    stakeTax,
    stakeTaxPercent,
    stakeTaxPayer,
    win,
    possibleWin,
    possibleIncomeTax,
    incomeTaxPercent,
  } = payoutInfo;

  const checkIfShowPayoutInfo = (): boolean =>
    (!!stakeTaxPercent || !!incomeTaxPercent) &&
    stakeTaxPayer === STAKE_TAX_PAYER.PLAYER;

  // Total Stake, Excise Tax, Stake After Tax
  const getStakeInfoItems = (): InfoItem[] => [
    {
      label: localized('betslip.payoutPopover.totalStake'),
      value: getCommaSeparatedAmount(stake),
    },
    {
      label: `${localized('betslip.payoutPopover.exciseTax')} (${stakeTaxPercent}%)`,
      value: getCommaSeparatedAmount(-(stakeTax ?? 0)),
    },
    {
      label: localized('betslip.payoutPopover.stakeAfterTax'),
      value: getCommaSeparatedAmount(stakeAfterTax),
    },
  ];

  // Possible Win, Win
  const getWinOrPossibleWinItem = (): InfoItem | null => {
    if (win) {
      return {
        label: localized('betslip.payoutPopover.win'),
        value: getCommaSeparatedAmount(win),
      };
    } else if (possibleWin) {
      return {
        label: localized('betslip.payoutPopover.possibleWin'),
        value: getCommaSeparatedAmount(possibleWin),
      };
    }

    return null;
  };

  // WHT
  const getIncomeTaxItem = (hasAnyWinAmount: boolean): InfoItem => ({
    label: hasAnyWinAmount
      ? `${localized('betslip.payoutPopover.wht')} (${incomeTaxPercent}%)`
      : `${localized('betslip.payoutPopover.wht')}`,
    value: hasAnyWinAmount
      ? getCommaSeparatedAmount(-(possibleIncomeTax ?? 0))
      : `${incomeTaxPercent}%`,
    color: 'red',
  });

  // Payout
  const getPayoutItem = (): InfoItem | null => {
    if (possibleIncomeTax && (possibleWin || win)) {
      return {
        label: localized('betslip.payout'),
        value: getCommaSeparatedAmount(
          (win ?? possibleWin ?? 0) - possibleIncomeTax,
        ),
      };
    }

    return null;
  };

  const getPayoutInfoItems = (): InfoItem[] => {
    const items = getStakeInfoItems();
    const winOrPossibleWinItem = getWinOrPossibleWinItem();

    if (winOrPossibleWinItem) {
      items.splice(3, 0, winOrPossibleWinItem);
    }

    if (incomeTaxPercent) {
      items.push(getIncomeTaxItem(!!(possibleWin || win)));
    }

    const payoutItem = getPayoutItem();

    if (payoutItem) {
      items.push(payoutItem);
    }

    return items;
  };

  const showPayoutInfo = checkIfShowPayoutInfo();
  const payoutInfoItems = getPayoutInfoItems();

  return {
    showPayoutInfo,
    payoutInfoItems,
  };
};
