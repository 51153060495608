import { useBalanceChangePrediction } from '~api/betslip/betslipMutations';
import { BalancePredictionPayload, Bet } from '~api/betslip/types';
import { useFormatBetslipPayload } from '~components/molecules/Betslip/hooks/useFormatBetslipPayload';
import { useDebouncedEffect } from '~hooks/useDebouncedEffect';
import { useAppDispatch, useAppSelector } from '~store';
import {
  removeBalanceChangesMapItem,
  setBalanceChangesOnBetData,
  setStakePossibleWinLoadingMap,
} from '~store/slices/betslipSlice';
import { calculateSystemBetOptions } from '~utils/betslip';

import {
  BETSLIP_ERRORS,
  BETSLIP_VALUES_MAP_OPTIONS,
  MIN_SYSTEM_BET_EVENTS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../constants';

export const useBetslipPossibleWinCheck = () => {
  const dispatch = useAppDispatch();
  const { balanceChangePredictionMutation } = useBalanceChangePrediction();
  const { defaultBalance, isUserLoggedIn } = useAppSelector(
    (state) => state.userState,
  );
  const {
    events,
    eventsData,
    stakeType,
    aggregatedBetAmount,
    singleBetsAmountMap,
    systemBetOption,
    betslipErrors,
  } = useAppSelector((state) => state.betslip);
  const { separateBonusBalance } = useAppSelector((state) => state.settings);

  const { formatBetslipPayload } = useFormatBetslipPayload();

  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const aggregatedBetsDefaultError =
    (stakeType !== SPORT_BETSLIP_TYPE_OPTIONS.SINGLE &&
      betslipErrors.includes(BETSLIP_ERRORS.NOT_COMBINABLE)) ||
    betslipErrors.includes(BETSLIP_ERRORS.MIN_SELECTIONS);

  const stakeTypeMap: Record<SPORT_BETSLIP_TYPE_OPTIONS, number> = {
    [SPORT_BETSLIP_TYPE_OPTIONS.SINGLE]: 1,
    [SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE]: 2,
    [SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM]: 4,
  };

  const handleBalanceChanges = async (payload: BalancePredictionPayload) => {
    try {
      const response = await balanceChangePredictionMutation(payload);

      if ('data' in response) {
        dispatch(setBalanceChangesOnBetData(response.data));
      }
    } finally {
      dispatch(setStakePossibleWinLoadingMap({}));
    }
  };

  useDebouncedEffect(
    () => {
      if (aggregatedBetsDefaultError || !eventsData.length || !events.length) {
        dispatch(setStakePossibleWinLoadingMap({}));
        dispatch(
          removeBalanceChangesMapItem(BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED),
        );

        return;
      }

      if (stakeType !== SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
        dispatch(
          setStakePossibleWinLoadingMap({
            [BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED]: true,
          }),
        );
      }

      const bets: Bet[] = formatBetslipPayload(events, eventsData);

      const sumStake = bets.reduce(
        (acc, bet) =>
          acc + (bet?.stake || bet?.realStake || bet?.bonusStake || 0),
        0,
      );

      if (!bets.length || !sumStake) {
        dispatch(setStakePossibleWinLoadingMap({}));

        return;
      }

      const payload: BalancePredictionPayload = {
        bets,
        separateBonusBalance,
        betSlipType: stakeTypeMap[stakeType],
      };

      if (isSystemBet && events.length >= MIN_SYSTEM_BET_EVENTS) {
        payload.systemBetCombinationSize = systemBetOption
          ? Number(systemBetOption.value)
          : Number(calculateSystemBetOptions(events.length)[0]?.value);
      }

      handleBalanceChanges(payload);
    },
    [
      eventsData.length,
      events.length,
      stakeType,
      aggregatedBetAmount,
      singleBetsAmountMap,
      defaultBalance,
      systemBetOption,
      isUserLoggedIn,
      aggregatedBetsDefaultError,
    ],
    300,
  );
};
