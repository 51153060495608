import React from 'react';
import * as ToggleGroupUI from '@radix-ui/react-toggle-group';
import { CSS, styled } from 'stitches.config';

import { ellipsis } from '~utils/cssUtils';

import { BasicButton } from './Button/Button';

export const ToggleGroup = styled(ToggleGroupUI.Root, {
  borderRadius: '$8',
  display: 'flex',
  width: '100%',
  variants: {
    variant: {
      toggle: {
        border: '1px solid $accentPrimaryOne',
        gap: '$1',
        p: '$1',
      },
      bet: {
        gap: '1px',
      },
      market: {
        border: '1px solid $primaryTwo',
        px: '2px',
      },
      lightMarket: {
        border: '1px solid $secondaryOne',
        px: '2px',
      },
      payment: {
        border: '1px solid $primaryTwo',
        background: '$primaryTwo',
      },
      userProfileMobileTab: {
        border: '1px solid $secondaryTwo',
        borderRadius: '$6',
        p: '2px',
        gap: '$1',
      },
    },
  },
  defaultVariants: {
    variant: 'toggle',
  },
});

export const ToggleGroupButton = styled(BasicButton, {
  display: 'flex',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  fontFamily: 'inherit',
  width: '100%',
  alignItems: 'center',
  flexGrow: '1',
  backgroundColor: 'transparent',
  color: '$textPrimaryOne',
  fontSize: '$14',
  lineHeight: '$20',
  ...ellipsis,
  variants: {
    variant: {
      toggle: {
        textAlign: 'center',
        pt: '7px',
        pb: '5px',
        textTransform: 'uppercase',
        gap: '$1',
        justifyContent: 'center',
        borderRadius: '$4',
        '&:hover': {
          backgroundColor: '$hoverSecondary',
        },
        '&[data-state=on]': {
          color: '$textButtons',
          backgroundColor: '$accentPrimaryOne',
        },
        '&[data-state=off]': {
          backgroundColor: 'transparent',
        },
        '& .label': {
          fontWeight: '$medium',
        },
        '& .count': {
          fontWeight: '$normal',
        },
        '&[data-state=on] *': {
          fontWeight: '$bold',
        },
        '&[data-state=off]:hover *': {
          color: '$hoverPrimary',
        },
      },
      bet: {
        backgroundColor: '$primaryTwo',
        px: '$3',
        py: '$1',
        borderRadius: 0,
        '&[data-state=off]:hover *': {
          color: '$hoverPrimary',
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$secondaryOne',
        },
        '&:first-child': {
          borderTopLeftRadius: '$6',
          borderBottomLeftRadius: '$6',
        },
        '&:last-child': {
          borderTopRightRadius: '$6',
          borderBottomRightRadius: '$6',
        },
        '&[data-state=on]': {
          backgroundColor: '$accentPrimaryOne',
          color: '$textButtons',
          '*': {
            color: '$textButtons',
          },
        },
      },
      market: {
        backgroundColor: 'transparent',
        width: '100%',
        py: '$1',
        m: '$1 2px',
        borderRadius: 0,
        '& > span': {
          color: '$textPrimaryOne',
          fontSize: '$xxxs',
          width: '100%',
          textAlign: 'center',
          lineHeight: '$20',
          fontWeight: '$medium',
        },
        '&[data-state="off"]:not([data-disabled])': {
          '&:hover *': {
            color: '$hoverPrimary',
          },
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$primaryTwo',
        },
        '&': {
          borderRadius: '$6',
        },
        '&[data-state=on]': {
          backgroundColor: '$primaryTwo',
          fontWeight: '$bold',
          color: '$textButtons',
          '*': {
            color: '$textButtons',
          },
          '> span': {
            fontWeight: '$bold',
          },
        },
      },
      lightMarket: {
        backgroundColor: '$primaryTwo',
        width: '100%',
        py: '$1',
        m: '$1 2px',
        borderRadius: 0,
        '& > span': {
          color: '$textPrimaryOne',
          fontSize: '$xxxs',
          width: '100%',
          textAlign: 'center',
          lineHeight: '$20',
          fontWeight: '$medium',
        },
        '&[data-state="off"]:not([data-disabled])': {
          '&:hover *': {
            color: '$hoverPrimary',
          },
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$secondaryOne',
        },
        '&': {
          borderRadius: '$6',
        },
        '&[data-state=on]': {
          backgroundColor: '$primaryTwo',
          fontWeight: '$bold',
          color: '$textButtons',
          '*': {
            color: '$textButtons',
          },
          '> span': {
            fontWeight: '$bold',
          },
        },
      },
      userProfileMobileTab: {
        width: '100%',
        p: '6px',
        borderRadius: '$4',
        '& > span': {
          color: '$textPrimaryOne',
          fontSize: '$14',
          lineHeight: '$20',
          width: '100%',
          textAlign: 'center',
          fontWeight: '$medium',
          textTransform: 'uppercase',
        },
        '&[data-state="off"]:not([data-disabled])': {
          '&:hover *': {
            color: '$hoverPrimary',
          },
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$primaryTwo',
        },
        '&[data-state=on]': {
          backgroundColor: '$secondaryTwo',
          fontWeight: '$bold',
          color: '$textPrimaryOne',
          '*': {
            color: '$textPrimaryOne',
          },
        },
        '&[data-state=on] > span': {
          fontWeight: '$bold',
        },
      },
      payment: {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        p: '6px $2',
        gap: '$1',
        margin: '$1',
        borderRadius: 0,
        '& > div > svg': {
          width: '28px',
          height: '28px',
          color: '$textSecondaryOne',
        },
        '& > span': {
          whiteSpace: 'normal',
          overflow: 'hidden',
          textTransform: 'uppercase',
          textAlign: 'left',
          color: '$textPrimaryOne',
          fontSize: '$xxs',
          lineHeight: '$16',
          fontWeight: '$medium',
        },
        '&[data-state="off"]:not([data-disabled])': {
          '&:hover *': {
            color: '$hoverPrimary',
          },
        },
        '&[data-disabled]': {
          cursor: 'default',
          backgroundColor: '$primaryTwo',
        },
        '&': {
          borderRadius: '$6',
        },
        '&[data-state=on]': {
          fontWeight: '$bold',
          color: 'accentPrimaryTwo',
          '*': {
            color: '$accentPrimaryTwo',
          },
        },
        '&[data-state=on] > span': {
          fontWeight: '$bold',
        },
        '@xs_sm': {
          flexDirection: 'column',
          p: '$1',
          gap: '$1',
          '& > span': {
            textAlign: 'center',
            fontSize: '$10',
            lineHeight: '$12',
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'toggle',
  },
});

interface ToggleGroupItemProps {
  value: string | number;
  children: React.ReactNode;
  type?:
    | 'toggle'
    | 'bet'
    | 'market'
    | 'payment'
    | 'userProfileMobileTab'
    | 'lightMarket';
  disabled?: boolean;
  css?: CSS;
}

export const ToggleGroupItem = ({
  value,
  children,
  disabled = false,
  type = 'toggle',
  css,
}: ToggleGroupItemProps) => {
  return (
    <ToggleGroupUI.Item
      css={css}
      onClick={(e) => e.stopPropagation()}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      value={value}
      asChild
      disabled={disabled}
    >
      <ToggleGroupButton onClick={(e) => e.stopPropagation()} variant={type}>
        {children}
      </ToggleGroupButton>
    </ToggleGroupUI.Item>
  );
};
