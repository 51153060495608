import { useCallback } from 'react';

import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { SPORT_TAB_CONTENTS } from '~components/organisms/MobileTabContents';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams, useRouterQuery } from '~hooks';
import { useAppDispatch } from '~store';
import { setSelectedFavoriteEventId } from '~store/slices/eventsSlice';
import { setSportTabActiveContent } from '~store/slices/mobileSlice';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';
import {
  SportEvent,
  SportEventMainData,
  SportEventsMainData,
} from '~types/events';
import { QueryParams } from '~utils/url';

export const useEventList = (events: SportEventsMainData) => {
  const dispatch = useAppDispatch();
  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();
  const { updateQueryParams } = useRouterQuery();

  const { sportId, countryId, leagueId, menu } = useQueryParams();

  const handleFavoriteClick = async (
    isEventFavorite: boolean,
    eventData: SportEvent,
  ) => {
    if (isEventFavorite) {
      await deleteFavoriteEventMutation({
        eventId: eventData.id,
      }).then(() => {
        dispatch(
          setFavoritePrematchEvents(
            events.filter((event) => event.id !== eventData.id),
          ),
        );
      });
    } else {
      await addFavoriteEventMutation({
        eventId: eventData.id,
      }).then(() => {
        if (eventData) {
          dispatch(
            setFavoritePrematchEvents([
              ...events,
              eventData as SportEventMainData,
            ]),
          );
        }
      });
    }
  };

  const handleLoadEventData = useCallback(
    (id: string) => {
      dispatch(setSelectedFavoriteEventId(id));
      dispatch(
        setSportTabActiveContent(SPORT_TAB_CONTENTS.PREMATCH_EVENT_MARKETS),
      );

      updateQueryParams(
        {
          [QUERY_PARAMS.COUNTRY_ID]: countryId,
          [QUERY_PARAMS.LEAGUE_ID]: leagueId,
          [QUERY_PARAMS.MENU]: menu,
          [QUERY_PARAMS.SPORT_ID]: sportId,
          [QUERY_PARAMS.EVENT_ID]: id,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    },
    [sportId, countryId, leagueId],
  );

  return {
    setSelectedFavoriteEventId,
    handleFavoriteClick,
    handleLoadEventData,
  };
};
