import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { SPORT_GROUP_COMPONENTS } from '~components/molecules/PrematchMenu/constants';
import { useTopEventsSocket } from '~socket-service/hooks/useTopEventsSocket/useTopEventsSocket';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionRoot,
  MultiAccordionTrigger,
} from '../../MultiAccordion';
import { useSportMenuGroups } from '../hooks/useSportMenuGroups';

import { FavoritePrematchEventGroup } from './FavoritePrematchEventGroup';
import { TopEvent } from './TopEvent';
import { TournamentsGroup } from './TournamentsGroup';

const groupComponentsMap = {
  [SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT]: TournamentsGroup,
  [SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT]: TournamentsGroup,
  [SPORT_GROUP_COMPONENTS.FAVORITE_EVENT]: FavoritePrematchEventGroup,
};

export const SportMenuGroups = memo(() => {
  const { data: topSportEvents } = useTopEventsSocket();
  const {
    sports,
    groups,
    groupCountsMemo,
    sportGroupsMemo,
    openSportGroups,
    isMobileOrTablet,
    handleOnValueChange,
  } = useSportMenuGroups({ topSportEvents });

  if (!groups.length) {
    return null;
  }

  return (
    <>
      {groups?.map(({ id, name, icon: Icon, label }) => {
        if (name == SPORT_GROUP_COMPONENTS.TOP_EVENT) {
          return <TopEvent key={id} count={groupCountsMemo[name]} />;
        }

        const Component = groupComponentsMap[name];

        return (
          <MultiAccordionRoot
            key={id}
            type="multiple"
            role={MULTI_ACCORDION_ROLES.PARENT}
            value={openSportGroups}
            onValueChange={handleOnValueChange}
          >
            <MultiAccordionItem
              value={id.toString()}
              role={MULTI_ACCORDION_ROLES.PARENT}
            >
              <MultiAccordionTrigger
                title={label}
                iconComponent={
                  <Box
                    css={{
                      color: '$textSecondaryOne',
                      '&:hover': {
                        color: '$textPrimaryOne',
                      },
                    }}
                  >
                    <Icon />
                  </Box>
                }
                css={{
                  '& .text': {
                    fontSize: '$16',
                    lineHeight: '$28',
                  },
                }}
                count={groupCountsMemo[name]}
                role={MULTI_ACCORDION_ROLES.PARENT}
              />
              {groupCountsMemo[name] && (
                <MultiAccordionContent role={MULTI_ACCORDION_ROLES.PARENT}>
                  <MultiAccordionRoot
                    disabled
                    type="multiple"
                    value={(sports || []).map(({ id }) => id.toString())}
                    role={MULTI_ACCORDION_ROLES.CHILD}
                  >
                    <Component
                      sportGroupsMemo={sportGroupsMemo}
                      componentName={name}
                    />
                  </MultiAccordionRoot>
                </MultiAccordionContent>
              )}
            </MultiAccordionItem>
          </MultiAccordionRoot>
        );
      })}
      {!isMobileOrTablet && (
        <Separator
          verticalSpace="1"
          shrinkOut="0"
          css={{
            backgroundColor: '$primaryTwo',
          }}
        />
      )}
    </>
  );
});
