import { EVENT_STATUS, QUERY_PARAMS } from '~constants/common';
import { setActiveMenu } from '~features/sportsMenu/sportsMenuSlice';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import {
  changeSearchPerformed,
  setSearchQuery,
} from '~store/slices/sportEventSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

export const useSearchResult = (
  status: EVENT_STATUS,
  sportId: number,
  countryId: number,
  leagueId: number,
  eventId: string,
) => {
  const { nextHour } = useQueryParams();
  const { updateQueryParams } = useRouterQuery();

  const { localized } = useTranslation();

  const dispatch = useAppDispatch();
  const isLive = status === EVENT_STATUS.IN_PROGRESS;

  const handleSearchResultClick = () => {
    const menuToOpen = isLive ? SPORT_MENUS.LIVE : SPORT_MENUS.PREMATCH;

    dispatch(changeSearchPerformed(false));
    dispatch(setSearchQuery(''));

    updateQueryParams(
      {
        [QUERY_PARAMS.SPORT_ID]: sportId || undefined,
        [QUERY_PARAMS.COUNTRY_ID]: countryId || undefined,
        [QUERY_PARAMS.LEAGUE_ID]: leagueId || undefined,
        [QUERY_PARAMS.MENU]: menuToOpen,
        [QUERY_PARAMS.EVENT_ID]: eventId || undefined,
        nextHour: nextHour || undefined,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );

    dispatch(setActiveMenu(menuToOpen));
  };

  return {
    isLive,
    localized,
    handleSearchResultClick,
  };
};
