import { PasswordCharacteristicsItem } from '~hooks/usePasswordCharacteristics';
import { useTranslation } from '~hooks/useTranslation';
import { GreenCheckIcon, RedCrossIcon } from '~icons';

import { Box } from './Box';
import { Text } from './Typography';

interface PasswordRequirementsProps {
  localizedCharacteristicsItems: PasswordCharacteristicsItem[];
}

export const PasswordRequirements = ({
  localizedCharacteristicsItems,
}: PasswordRequirementsProps) => {
  const { localized } = useTranslation();

  return (
    <>
      <Box
        css={{
          mb: '$3',
        }}
      >
        <Text textTransform="uppercase" level="sm-3">
          {localized('password.passwordMust')}
        </Text>
      </Box>
      <Box flexCol gap={4}>
        {localizedCharacteristicsItems.map((item) => (
          <Box flexRow gap={3} alignCenter key={item.label}>
            {item.satisfies ? <GreenCheckIcon /> : <RedCrossIcon />}
            <Text level="xxs-2">{item.label}</Text>
          </Box>
        ))}
      </Box>
    </>
  );
};
