import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';

import {
  AddressState,
  useCardAddressForm,
} from '../../hooks/useCardAddressForm';
import { StyledBaseInputWrapper } from '../StyledBaseInput';

export const AddCardAddressForm = () => {
  const {
    formOptions,
    isValid,
    handleStateChange,
    state,
    onSubmit,
    onBack,
    localized,
  } = useCardAddressForm();

  return (
    <Box fullWidth gap={2} flexCol>
      <Box flexCol fullWidth alignCenter justifyCenter css={{ mb: '$2' }}>
        <Text
          css={{
            fontSize: '17px',
            lineHeight: '$24',
          }}
          textTransform="uppercase"
        >
          {localized('cardAddressFormText.title')}
        </Text>
        <Text textAlign="center" level="sm-3">
          {localized('cardAddressFormText.description')}
        </Text>
      </Box>

      {formOptions.map(({ name, placeholder, css }) => (
        <StyledBaseInputWrapper css={css}>
          <Input
            onChange={handleStateChange}
            value={state[name as keyof AddressState]}
            placeholder={placeholder}
            name={name}
            css={{
              width: '100%',
              lineHeight: '$24',
              p: '0 $2',
            }}
          />
        </StyledBaseInputWrapper>
      ))}
      <Box
        flexRow
        fullWidth
        gap={2}
        css={{
          marginTop: '$4',
        }}
      >
        <Button variant="secondary" fullWidth onClick={onBack}>
          {localized('buttons.back')}
        </Button>
        <Button fullWidth disabled={!isValid} onClick={onSubmit}>
          {localized('buttons.confirm')}
        </Button>
      </Box>
    </Box>
  );
};
