import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Button } from '~components/atoms/Button';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();

  return (
    <Button
      variant="secondary"
      onClick={() => dispatch(openDialog(DIALOGS.SIGN_IN))}
      css={{
        fontSize: '$18 !important',
        lineHeight: '$24 !important',
        p: '$2',
        width: '160px',
        height: '44px',
        textAlign: 'center',
        backgroundColor: '$primaryTwo',
        '@xs_sm': {
          fontSize: '$14 !important',
          lineHeight: '$20 !important',
          p: '$1 0',
          width: '$13',
          borderRadius: '$4',
          height: '28px !important',
        },
      }}
    >
      {localized('buttons.signIn')}
    </Button>
  );
};
