import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_CONNECTED_STATUS,
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';

interface EventsMessageData {
  ids: string[];
  MarketId: number;
}

export const useUpcomingHubConnection = () => {
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const { upcomingEvents, activeMainHighlightsSportId } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const { isMobileOrTablet } = useMedia();

  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const [socket, setSocket] = useState<HubConnection | null>(null);
  const [eventsData, setEventsData] = useState<EventsMessageData[]>([]);

  useEffect(() => {
    const upcomingEventsDataArr: EventsMessageData[] = [];

    if (!activeMainHighlightsSportId) return;
    if (upcomingEvents && upcomingEvents.length) {
      upcomingEvents.forEach(({ sportId, dateGroups }) => {
        if (sportId !== parseInt(activeMainHighlightsSportId)) return;

        const mainSportMarkets = mainMarkets.find(
          (item) => item.id === sportId,
        )?.markets;

        dateGroups.forEach(({ matches }) => {
          matches.forEach(({ id: EventId = '' }) => {
            mainSportMarkets?.forEach(({ id: marketId }) => {
              const savedMarket = upcomingEventsDataArr.find(
                (item) => item.MarketId === marketId,
              );

              if (savedMarket) {
                savedMarket.ids.push(EventId);
              } else {
                upcomingEventsDataArr.push({
                  ids: [EventId],
                  MarketId: marketId,
                });
              }
            });
          });
        });
      });
    }

    setEventsData(upcomingEventsDataArr);
  }, [
    upcomingEvents,
    activeMainHighlightsSportId,
    mainMarkets,
    isMobileOrTablet,
  ]);

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  const subscribeEvents = async (eventsData: EventsMessageData[]) => {
    try {
      const groupedData: Record<number, string[]> = {};

      eventsData.forEach((item) => {
        const marketId = item.MarketId;

        if (!groupedData[marketId]) {
          groupedData[marketId] = [];
        }

        groupedData[marketId] = (groupedData[marketId] || []).concat(item.ids);
      });

      const resPayload: { MarketId: number; ids: string[] }[] = [];

      Object.entries(groupedData).forEach(([key, value]) => {
        resPayload.push({ MarketId: parseInt(key), ids: value });
      });

      await socket?.invoke(
        SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS,
        resPayload,
      );
    } catch (e) {
      console.error('Failed to subscribe events: ', e);
    }
  };

  useEffect(() => {
    if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
      socket.invoke(SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_INPLAY_MENU);
    }

    return () => {
      socket?.invoke(SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_INPLAY_MENU);
    };
  }, [socket, socket?.state]);

  useEffect(() => {
    if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
      subscribeEvents(eventsData);
    }

    return () => {
      socket?.invoke(
        SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS,
      );
    };
  }, [socket, eventsData]);
};
