import { sportIdToSportNameMap, SPORTS } from '~constants/sport';
import {
  BasketballExtraData,
  IceHockeyExtraData,
  SnookerExtraData,
  SoccerExtraData,
  TennisExtraData,
  VolleyballExtraData,
} from '~types/sportExtraData';
import {
  getBasketballDetails,
  getIceHockeyDetails,
  getSnookerDetails,
  getSoccerDetails,
  getTennisDetails,
  getVolleyballDetails,
} from '~utils/sportHelpers';

const parseSportDetails = (extraData: any, sportId: number) => {
  let details;

  const parsedExtraData = JSON.parse(extraData);

  const sportName = sportIdToSportNameMap[sportId];

  switch (sportName) {
    case SPORTS.BASKETBALL:
      details = getBasketballDetails(
        parsedExtraData as BasketballExtraData,
        true,
      )?.shortDetails;
      break;
    case SPORTS.ICE_HOCKEY:
      details = getIceHockeyDetails(
        parsedExtraData as IceHockeyExtraData,
        true,
      )?.details;
      break;
    case SPORTS.SNOOKER:
      details = getSnookerDetails(
        parsedExtraData as SnookerExtraData,
        true,
      )?.details;
      break;
    case SPORTS.SOCCER:
      details = getSoccerDetails(
        parsedExtraData as SoccerExtraData,
        true,
      )?.shortDetails;
      break;
    case SPORTS.TENNIS:
      details = getTennisDetails(
        parsedExtraData as TennisExtraData,
      )?.shortDetails;

      break;
    case SPORTS.VOLLEYBALL:
      details = getVolleyballDetails(
        parsedExtraData as VolleyballExtraData,
        true,
      )?.shortDetails;
      break;
    // Add more cases for other sports as needed
    default:
      details = null;
      break;
  }

  return details;
};

export default parseSportDetails;
