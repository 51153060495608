import { useMemo, useState } from 'react';

import { useLazyGetBetslipHistory } from '~api/betslip/betslipQueries';
import { BetslipHistoryPayload } from '~api/betslip/types';
import { useAppDispatch, useAppSelector } from '~store';
import { setIsBetslipHistoryLoading } from '~store/slices/betslipSlice';
import { setBetslipHistory, setParams } from '~store/slices/openBetSlice';

const PAGING_LIMIT = 20;

export const useBetslipHistory = () => {
  const { lazyGetBetslipHistoryQuery } = useLazyGetBetslipHistory();
  const { betslipHistory, params: filterParams } = useAppSelector(
    (state) => state.openBets,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const dispatch = useAppDispatch();

  const loadBetslipHistory = useMemo(() => {
    return async (
      params: BetslipHistoryPayload | undefined,
      loadMore?: boolean,
    ) => {
      try {
        if (!loadMore) {
          setIsLoading(true);
          dispatch(setParams({}));
          betslipHistory.length && dispatch(setBetslipHistory([]));
        }

        if (params) {
          dispatch(setParams(params));
        }

        const page = !loadMore
          ? 1
          : Math.ceil(betslipHistory.length / PAGING_LIMIT) + 1;

        dispatch(setIsBetslipHistoryLoading(true));
        const { data, total } = await lazyGetBetslipHistoryQuery({
          ...(params || {}),
          ...(loadMore ? filterParams : {}),
          limit: PAGING_LIMIT,
          page,
        }).unwrap();

        setTotal(total);
        dispatch(setIsBetslipHistoryLoading(false));

        if (!data) {
          setIsLoading(false);

          return;
        }

        dispatch(
          setBetslipHistory([...(!loadMore ? [] : betslipHistory), ...data]),
        );
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };
  }, [betslipHistory, filterParams]);

  const loadMore = () => loadBetslipHistory(undefined, true);

  return {
    isLoading,
    loadBetslipHistory,
    betHistory: betslipHistory,
    total,
    loadMore,
  };
};
