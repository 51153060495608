import React from 'react';

import { Box } from '~components/atoms/Box';
import { InfoPopover } from '~components/atoms/InfoPopover';
import { JackpotBetHistoryCellProps } from '~components/molecules/BetslipHistorySection/Jackpot/JackpotBetslipsDesktopTable/types';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import { useFormatAmountWithCurrency, useTranslation } from '~hooks';
import { useStakePayout } from '~hooks/useStakePayout';

export const StakeCell = ({
  row: { original },
}: JackpotBetHistoryCellProps) => {
  const { localized } = useTranslation();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  const { stake, incomeTaxPercent, stakeTaxPercent, stakeTax, stakeTaxPayer } =
    original;

  const { showPayoutInfo, payoutInfoItems } = useStakePayout({
    stake: stake + stakeTax,
    stakeAfterTax: stake,
    stakeTax,
    stakeTaxPercent,
    stakeTaxPayer,
    incomeTaxPercent,
  });

  return (
    <Box flexRow gap={2} alignCenter>
      <StyledTableText>{getCommaSeparatedAmount(stake)}</StyledTableText>
      {showPayoutInfo && (
        <InfoPopover
          title={`${localized('betslip.payoutPopover.info')}:`}
          itemsList={payoutInfoItems}
        />
      )}
    </Box>
  );
};
