import React from 'react';

import { TableIconExpandCell } from '~components/molecules/BetslipHistorySection/components/TableIconExpandCell';
import { CellContentPropsOuter } from '~components/molecules/BetslipHistorySection/types';

export const ExpandRowCell = ({
  cell: { column, render },
  isOpen,
  onClick,
}: CellContentPropsOuter) => {
  if (column.id === 'icon') {
    return <TableIconExpandCell isOpen={isOpen} onClick={onClick} />;
  }

  return render('Cell');
};
