import React from 'react';

import { Box } from '~components/atoms/Box';
import { StoreSportEvent } from '~types/events';
import { BasketballExtraData } from '~types/sportExtraData';

import { BasketballScoreboard } from '../scoreboards';

interface BasketballEventOverviewProps {
  restEventData: Omit<StoreSportEvent, 'extraData'>;
  parsedExtraData: BasketballExtraData | null;
}

export const BasketballEventOverview = ({
  restEventData,
  parsedExtraData,
}: BasketballEventOverviewProps) => {
  return (
    <>
      <BasketballScoreboard
        restEventData={restEventData}
        parsedExtraData={parsedExtraData}
      />
      <Box></Box>
    </>
  );
};
