import React, { CSSProperties, FC, ReactNode, useRef, useState } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { CSS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks';
import { CheckedIcon, ChevronDownIcon, ChevronUpIcon } from '~icons';

export type SelectOptionType = {
  label: unknown;
  value: unknown;
  title?: string;
};

type Props = {
  lightView?: boolean;
  label?: string;
  placeholder?: string;
  value: any;
  setValue: (value: any) => void;
  options: SelectOptionType[];
  additionalSelectedComponents?: Record<string, ReactNode>;
  handleDropdownVisibleChange?: (open: boolean) => void;
  dropdownWidth?: string;
  dropdownStyles?: CSS;
  dropdownContentStyles?: CSSProperties;
  icon?: ReactNode;
  style?: CSS;
  disabled?: boolean;
  labelRender?: (props: SelectOptionType) => ReactNode;
};

const StyledSelect = styled(Select, {
  height: '36px',
  variants: {
    lightView: {
      true: {
        '& .ant-select-selector': {
          backgroundColor: '$secondaryTwo !important',
          padding: '0px 5px !important',
        },
        '& .ant-select-selection-placeholder': {
          padding: '0px 5px !important',
        },
      },
    },
    withLabel: {
      true: {
        background: '$primaryOne !important',
        '> .ant-select-selector': {
          background: 'transparent !important',
          '> .ant-select-arrow': {
            color: 'transparent !important',
          },
        },
      },
    },
  },
});

const StyledLabel = styled('span', {
  fontSize: '14px',
  lineHeight: '20px',
  color: '$textSecondaryOne',
  variants: {
    withLabel: {
      true: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
});
const StyledValue = styled('span', {
  fontWeight: 500,
  color: '$textPrimaryOne',
  fontSize: '14px',
  lineHeight: '20px',
  variants: {
    lightView: {
      true: {
        gap: 0,
      },
    },
    withLabel: {
      true: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
});
const StyledChevronUpIcon = styled(ChevronUpIcon, {
  color: '$textSecondaryOne',
});
const StyledChevronDownIcon = styled(ChevronDownIcon, {
  color: '$textSecondaryOne',
});
const StyledCheckedIcon = styled(CheckedIcon, {
  color: '$textSecondaryOne',
});

export const SelectWithLabel: FC<Props> = ({
  label,
  value,
  setValue,
  options,
  lightView,
  placeholder,
  additionalSelectedComponents,
  handleDropdownVisibleChange,
  dropdownWidth = '100%',
  dropdownStyles = {},
  dropdownContentStyles = {},
  icon,
  style = {},
  disabled,
  labelRender,
}) => {
  const { isMobileOrTablet } = useMedia();
  const [isOpen, setOpen] = useState(false);
  const selectRef = useRef<BaseSelectRef>(null);

  const getSuffixIcon = () => {
    if (icon) {
      return null;
    }

    if (disabled) return null;

    return isOpen ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />;
  };

  const onDropdownVisibleChange = (open: boolean) => {
    setOpen(open);
    handleDropdownVisibleChange && handleDropdownVisibleChange(open);
  };

  return (
    <StyledSelect
      disabled={disabled}
      lightView={lightView}
      placeholder={placeholder}
      ref={selectRef}
      withLabel={!!label}
      options={options}
      labelRender={(props) => {
        if (labelRender) {
          return labelRender(props);
        }

        if (icon) {
          return icon;
        }

        const { title, value } = props as SelectOptionType;

        return (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              justifyContent: !label ? 'flex-start' : 'flex-end',
            }}
          >
            {label && <StyledLabel withLabel={!!label}>{label}:</StyledLabel>}
            <StyledValue withLabel={!!label}>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <div style={{ flexShrink: 0 }}>
                  {additionalSelectedComponents
                    ? additionalSelectedComponents[value as string] || null
                    : null}
                </div>
                {title}
              </div>
            </StyledValue>
          </div>
        );
      }}
      value={value}
      onChange={setValue}
      suffixIcon={getSuffixIcon()}
      onDropdownVisibleChange={onDropdownVisibleChange}
      menuItemSelectedIcon={<StyledCheckedIcon />}
      optionRender={(props) => {
        if (labelRender) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return labelRender(props.data);
        }

        if (icon) {
          return icon;
        }

        const { title, value } = props.data as SelectOptionType;

        return (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              justifyContent: !label ? 'flex-start' : 'flex-end',
            }}
          >
            {label && <StyledLabel withLabel={!!label}>{label}:</StyledLabel>}
            <StyledValue withLabel={!!label}>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <div style={{ flexShrink: 0 }}>
                  {additionalSelectedComponents
                    ? additionalSelectedComponents[value as string] || null
                    : null}
                </div>
                {title}
              </div>
            </StyledValue>
          </div>
        );
      }}
      dropdownStyle={{
        ...(dropdownWidth !== '100%' && { width: 'auto !important' }),
        ...dropdownContentStyles,
      }}
      dropdownRender={(menu) => {
        return (
          <Box
            css={{
              width: dropdownWidth,
              backgroundColor: '$secondaryTwo !important',
              '& .ant-select-item:not(:last-child)': {
                borderBottom: isMobileOrTablet
                  ? '1px solid $primaryTwo'
                  : 'none',
              },
              ...dropdownStyles,
            }}
          >
            {menu}
          </Box>
        );
      }}
      css={{
        ...style,
      }}
    />
  );
};
