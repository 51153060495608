import Cookies, { CookieAttributes } from 'js-cookie';
import psl from 'psl';

import { REFRESH_TOKEN_DAYS_BEFORE_EXPIRATION } from '~constants/auth';
import { isDevMode } from '~utils/isDevMode';

const defaultOptions: CookieAttributes = isDevMode
  ? {}
  : {
      secure: true,
      sameSite: 'None',
    };

export enum COOKIES_NAMES {
  ACCESS_TOKEN = 'jwtToken',
  REFRESH_TOKEN = 'refreshToken',
  ACCESS_TOKEN_EXPIRATION = 'jwtTokenExpiration',
  VERIFICATION_CODE_EXPIRATION = 'verificationCodeExpiration',
}

interface SetCookiesData {
  token: string;
  refreshToken: string;
  expiration: string;
}

export const setCookie = (
  name: COOKIES_NAMES,
  value: string,
  options: Cookies.CookieAttributes | undefined = {},
) => {
  return Cookies.set(name, value, { ...options, ...defaultOptions });
};

export const getCookie = (name: COOKIES_NAMES): string | undefined => {
  return Cookies.get(name);
};

export const setAuthCookiesData = ({
  token,
  refreshToken,
  expiration,
}: SetCookiesData) => {
  const refreshTokenExpirationDate = new Date();

  refreshTokenExpirationDate.setDate(
    refreshTokenExpirationDate.getDate() + REFRESH_TOKEN_DAYS_BEFORE_EXPIRATION,
  );
  const pslData = psl.parse(window.location.hostname);

  if ('domain' in pslData) {
    setCookie(COOKIES_NAMES.ACCESS_TOKEN, token, {
      expires: refreshTokenExpirationDate,
      domain: pslData.domain || '',
    });
    setCookie(COOKIES_NAMES.REFRESH_TOKEN, refreshToken, {
      expires: refreshTokenExpirationDate,
      domain: pslData.domain || '',
    });
    setCookie(COOKIES_NAMES.ACCESS_TOKEN_EXPIRATION, expiration, {
      expires: refreshTokenExpirationDate,
      domain: pslData.domain || '',
    });
  } else {
    setCookie(COOKIES_NAMES.ACCESS_TOKEN, token, {
      expires: refreshTokenExpirationDate,
    });
    setCookie(COOKIES_NAMES.REFRESH_TOKEN, refreshToken, {
      expires: refreshTokenExpirationDate,
    });
    setCookie(COOKIES_NAMES.ACCESS_TOKEN_EXPIRATION, expiration, {
      expires: refreshTokenExpirationDate,
    });
  }
};

export const deleteAuthCookiesData = () => {
  const pslData = psl.parse(window.location.hostname);

  if ('domain' in pslData) {
    setCookie(COOKIES_NAMES.ACCESS_TOKEN, '', {
      domain: pslData.domain || '',
    });
    setCookie(COOKIES_NAMES.REFRESH_TOKEN, '', {
      domain: pslData.domain || '',
    });
    setCookie(COOKIES_NAMES.ACCESS_TOKEN_EXPIRATION, '', {
      domain: pslData.domain || '',
    });
  } else {
    setCookie(COOKIES_NAMES.ACCESS_TOKEN, '');
    setCookie(COOKIES_NAMES.REFRESH_TOKEN, '');
    setCookie(COOKIES_NAMES.ACCESS_TOKEN_EXPIRATION, '');
  }
};
