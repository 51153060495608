import React from 'react';

import { Box } from '~components/atoms/Box';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { FiltersButtons } from '~components/atoms/FiltersButtons';
import { Select } from '~components/atoms/Select';

import { useBonusHistoryFilters } from './useBonusHistoryFilters';

export const BonusHistoryFilters = () => {
  const {
    selectedDates,
    statusesOptions,
    statusValue,
    minimumAge,
    handleReset,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  } = useBonusHistoryFilters();

  return (
    <Box flexRow justifyContentBetween>
      <Box flexRow gap={2}>
        <Box flexRow gap={3}>
          <DateRangePicker
            isFutureDatesDisabled
            legalAge={minimumAge}
            onOkRange={(dates) => setSelectedDates(dates)}
            value={selectedDates}
          />
        </Box>
        <Select
          ariaLabel={localized('bonuses.status.all')}
          placeholder={localized('bonuses.status.all')}
          value={statusValue.toString()}
          onChange={(value) => setStatusValue(value as string)}
          options={statusesOptions}
          css={{
            width: '180px',
            height: '32px',
            borderRadius: '$8',
            backgroundColor: '$secondaryTwo',
          }}
          contentCss={{
            backgroundColor: '$secondaryTwo',
          }}
        />
      </Box>
      <FiltersButtons onReset={handleReset} onApply={handleUpdateHistory} />
    </Box>
  );
};
