import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

import { SEPARATOR } from './MainScore';

interface ScoreDateContentProps {
  firstScore: string | number | null | undefined;
  secondScore: string | number | null | undefined;
  separator: SEPARATOR;
  plateWidth: number;
  isMatchStarted: boolean;
  formattedStartDate?: string;
}

export const ScoreDateContent = ({
  firstScore,
  secondScore,
  separator,
  plateWidth,
  isMatchStarted,
  formattedStartDate,
}: ScoreDateContentProps) => {
  if (!isMatchStarted) {
    return (
      <Box
        flexRow
        alignCenter
        justifyCenter
        css={{
          backgroundColor: '$textSecondaryOneOpacity',
          height: '32px',
          width: `${plateWidth}px`,
        }}
      >
        <Text level="15-24" fontWeight="medium">
          {formattedStartDate}
        </Text>
      </Box>
    );
  }

  return (
    <Box flexRow justifyCenter css={{ width: `${plateWidth}px` }} gap={2}>
      <Text fontWeight="medium" level="25-32">
        {firstScore}
      </Text>
      <Text fontWeight="medium" level="25-32">
        {separator}
      </Text>
      <Text fontWeight="medium" level="25-32">
        {secondScore}
      </Text>
    </Box>
  );
};
