import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

import {
  useAuthorizedDepositPaystackMutation,
  useBankDepositPaystackMutation,
  usePaystackCardChargeMutation,
  usePaystackWithdrawMutation,
} from './paystackApi';

export const usePaystackCardCharge = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    usePaystackCardChargeMutation();

  return {
    paystackCardChargeMutation: mutation,
    paystackCardChargeData: data,
    paystackCardChargeError: error,
    paystackCardChargeIsError: isError,
    paystackCardChargeErrorCode: getErrorCode(error, isError),
    paystackCardChargeErrorMessage: getErrorMessage(error),
    paystackCardChargeIsSuccess: isSuccess,
    paystackCardChargeIsLoading: isLoading,
  };
};

export const usePaystackWithdraw = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    usePaystackWithdrawMutation();

  return {
    paystackWithdrawMutation: mutation,
    paystackWithdrawData: data,
    paystackWithdrawError: error,
    paystackWithdrawIsError: isError,
    paystackWithdrawIsSuccess: isSuccess,
    paystackWithdrawErrorCode: getErrorCode(error, isError),
    paystackWithdrawErrorMessage: getErrorMessage(error),
    paystackWithdrawIsLoading: isLoading,
  };
};

export const useAuthorizedDepositPaystack = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useAuthorizedDepositPaystackMutation();

  return {
    authorizedDepositPaystackMutation: mutation,
    authorizedDepositPaystackData: data,
    authorizedDepositPaystackError: error,
    authorizedDepositPaystackIsError: isError,
    authorizedDepositPaystackIsSuccess: isSuccess,
    authorizedDepositPaystackErrorCode: getErrorCode(error, isError),
    authorizedDepositPaystackErrorMessage: getErrorMessage(error),
    authorizedDepositPaystackIsLoading: isLoading,
  };
};

export const useBankDepositPaystack = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useBankDepositPaystackMutation();

  return {
    bankDepositPaystackMutation: mutation,
    bankDepositPaystackData: data,
    bankDepositPaystackError: error,
    bankDepositPaystackIsError: isError,
    bankDepositPaystackIsSuccess: isSuccess,
    bankDepositPaystackErrorCode: getErrorCode(error, isError),
    bankDepositPaystackErrorMessage: getErrorMessage(error),
    bankDepositPaystackIsLoading: isLoading,
  };
};
