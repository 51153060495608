import { Box } from '~components/atoms/Box';
import { RoundLabel } from '~components/atoms/UnorderedList';

export const CardMask = () => {
  return (
    <Box
      flexRow
      alignCenter
      css={{
        '& > div': {
          margin: '2px',
        },
      }}
    >
      <RoundLabel />
      <RoundLabel />
      <RoundLabel />
      <RoundLabel />
    </Box>
  );
};
