import * as AccordionUI from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { ellipsis, shouldHover } from '~utils/cssUtils';
import { slideDown, slideUp } from '~utils/keyframes';

export const StyledMultiAccordionRoot = styled(AccordionUI.Root, {
  borderRadius: '$10',
  width: '100%',
  '@xs_sm': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1px',
  },
  variants: {
    role: {
      parent: {},
      child: {},
    },
    mobile: {
      true: {
        backgroundColor: '$primaryTwo',
        padding: '$1 $2 $3',
        gap: '$1',
      },
    },
  },
  defaultVariants: {
    role: 'parent',
  },
});

export const StyledMultiAccordionItem = styled(AccordionUI.Item, {
  position: 'relative',
  overflow: 'hidden',

  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
  variants: {
    role: {
      parent: {},
      child: {
        '&:first-child > h3 > button': {
          borderTopLeftRadius: '$10',
          borderTopRightRadius: '$10',
        },
        '&:last-child': {
          borderBottomLeftRadius: '$10',
          borderBottomRightRadius: '$10',
        },
        '@xs_sm': {
          '&:first-child > h3 > button': {
            borderTopLeftRadius: '$8',
            borderTopRightRadius: '$8',
          },
          '&:last-child': {
            borderBottomLeftRadius: '$8',
            borderBottomRightRadius: '$8',
          },
        },
      },
    },
  },
  defaultVariants: { role: 'parent' },
});

export const StyledHeader = styled(AccordionUI.Header, {
  all: 'unset',
  display: 'flex',
  '&:last-child': {
    borderBottomLeftRadius: '$10',
    borderBottomRightRadius: '$10',
  },
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
});

export const StyledMultiAccordionTrigger = styled(AccordionUI.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  pl: '46px',
  pr: '68px',
  py: '10px',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '$md',
  lineHeight: '$16',
  color: '$textPrimaryOne',
  backgroundColor: '$primaryOne',
  cursor: 'pointer',
  ...ellipsis,
  '@xs_sm': {
    py: '$4',
  },
  '& .prefix': {
    color: '$textSecondaryOne',
  },
  '& .text': {
    ...ellipsis,
  },
  '&:hover > .postfix .count, &[data-state="open"] > .postfix .count': {
    color: '$textPrimaryOne',
  },
  '&:hover > .prefix, &[data-state="open"] > .prefix': {
    color: '$textPrimaryOne',
  },
  ...shouldHover,
  variants: {
    role: {
      parent: {
        '& .text': {
          fontSize: '$16',
          lineHeight: '$28',
          '@xs_sm': {
            fontSize: '$14',
            lineHeight: '$20',
          },
        },
        '&:active .text, &[data-state="open"] .text': {
          fontWeight: '$medium',
        },
        '@xs_sm': {
          '& .text': {
            fontSize: '$14',
            lineHeight: '$20',
          },
        },
      },
      child: {
        backgroundColor: '$primaryTwo',
        fontSize: '$sm',
        mt: 1,
        '@xs_sm': {
          py: '10px',
          pl: '32px',
          '& .prefix': {
            left: '2px',
          },
          '& .postfix': {
            right: '$1',
            '& .count': {
              backgroundColor: 'transparent',
              color: '$textSecondaryOne !important',
            },
          },
          '& .text': {
            fontSize: '$12',
            lineHeight: '$16',
          },
        },
      },
    },
    isPrefixWithPadding: {
      true: {
        '@xs_sm': {
          pl: '46px !important',
          '& .prefix': {
            left: '14px !important',
          },
        },
      },
    },
  },
});

export const StyledContentMultiAccordion = styled(AccordionUI.Content, {
  overflow: 'hidden',
  backgroundColor: '$primaryOne',
  p: 0,
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  variants: {
    role: {
      parent: {
        '@xs_sm': {
          px: '$1',
          '&:after': {
            content: '""',
            display: 'block',
            height: '$2',
          },
          '& > .contentWrapper': {
            backgroundColor: '$primaryTwo',
            borderRadius: '$8',
            p: '$2',
          },
        },
      },
      child: {
        backgroundColor: '$primaryTwo',
        '& > .contentWrapper': {
          p: '$3',
          pt: 0,
          '@xs_sm': {
            p: 0,
          },
        },
      },
    },
  },
});
