import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  StyledResultSeparator,
  StyledSearchResultWrapper,
} from '~components/molecules/GamesModalFilters/components/styled.components';
import { ProvidersList } from '~components/molecules/ProvidersList';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { Providers } from '~types/providers';

interface SearchResultProvidersProps {
  providers: Providers;
}

export const SearchResultProviders = ({
  providers,
}: SearchResultProvidersProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  return (
    <>
      <StyledSearchResultWrapper>
        <Box
          flexRow
          alignCenter
          fullWidth
          justifyContentBetween
          css={{
            pb: '$4',
            pl: '$3',
          }}
        >
          <Text
            level={isMobileOrTablet ? '14-20' : '16-28'}
            textTransform="uppercase"
          >
            {localized('casino.providers')}
          </Text>
          <Text
            level={isMobileOrTablet ? '14-20' : '16-28'}
            color="textSecondaryOne"
          >
            {providers.length}
          </Text>
        </Box>
        <ProvidersList providers={providers} />
      </StyledSearchResultWrapper>
      <StyledResultSeparator verticalSpace={0} />
    </>
  );
};
