import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import {
  INFO_PAGE_BASE_PATH,
  INFO_PAGE_SECTION_NAMES,
} from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { InfoFilledIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';

export const DeactivatedAccount = () => {
  const { isMobileOrTablet } = useMedia();
  const navigate = useNavigate();
  const { isUserBlocked } = useAppSelector((state) => state.userState);
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();

  const onContinue = () => {
    dispatch(closeDialog());
  };

  const onContactUs = () => {
    dispatch(closeDialog());
    navigate(`${INFO_PAGE_BASE_PATH}/${INFO_PAGE_SECTION_NAMES.CONTACT_US}`);
  };

  return (
    <Box
      flexCol
      fullWidth
      css={{
        '@xs_sm': {
          p: '$4 $3 $3',
        },
      }}
    >
      <Box
        flexCol
        alignCenter
        gap={1}
        css={{
          margin: '0 auto',
          color: '$primaryTwo',
          svg: {
            width: '2rem',
            height: '2rem',
          },
          '@md_lg_xl': {
            p: '28px 69px 19px',
          },
        }}
      >
        <InfoFilledIcon />
        <Text textTransform="uppercase" level="14-20" css={{ mt: '$1' }}>
          {localized(
            isUserBlocked
              ? 'deactivatedAccount.titleBlocked'
              : 'deactivatedAccount.titleDeactivated',
          )}
        </Text>
        <Text textTransform="uppercase" level="12-20">
          {localized('deactivatedAccount.description')}
        </Text>
        <Text textTransform="uppercase" level="12-20">
          {localized('deactivatedAccount.description2')}
        </Text>
      </Box>
      <Separator
        shrinkOut={isMobileOrTablet ? 3 : 0}
        css={{
          background: '$primaryTwo',
        }}
        verticalSpace={isMobileOrTablet ? 3 : 0}
      />
      <Box
        flexCol
        gap={4}
        css={{
          '@md_lg_xl': {
            p: '1.5rem 2rem 2rem',
          },
        }}
      >
        <Button fullWidth onClick={onContactUs}>
          {localized('buttons.support')}
        </Button>
        <Button variant="secondary" fullWidth onClick={onContinue}>
          {localized('buttons.continue')}
        </Button>
      </Box>
    </Box>
  );
};
