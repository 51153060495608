import React, { ComponentProps } from 'react';
import { COLOR_KEYS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Label } from '~components/atoms/Label';

const StyledLabel = styled(Label, {
  pr: '$2',
  margin: 0,
  variants: {
    strength: {
      weak: {
        color: '$redPrimary',
      },
      average: {
        color: '$orangePrimary',
      },
      strong: {
        color: '$bluePrimary',
      },
      excellent: {
        color: '$greenPrimary',
      },
    },
  },
  defaultVariants: {
    strength: 'weak',
  },
});

const StyledIndicator = styled('div', {
  position: 'absolute',
  left: 0,
  height: '2px',
  borderRadius: '$4 0 0 $4',
  variants: {
    strength: {
      weak: {
        backgroundColor: '$redPrimary',
        width: '25%',
      },
      average: {
        backgroundColor: '$orangePrimary',
        width: '50%',
      },
      strong: {
        backgroundColor: '$bluePrimary',
        width: '75%',
      },
      excellent: {
        backgroundColor: '$greenPrimary',
        width: '100%',
        borderRadius: '$4',
      },
    },
  },
  defaultVariants: {
    strength: 'weak',
  },
});

export const StyledContainer = styled(Box, {
  display: 'inline-flex',
  fontFamily: 'inherit',
  position: 'relative',
  height: '2px',
  borderRadius: '$4',
});

interface PasswordStrengthIndicatorProps extends ComponentProps<typeof Box> {
  strength?: 'weak' | 'average' | 'strong' | 'excellent';
  isCompactView?: boolean;
  smallFont?: boolean;
  containerBgColor?: COLOR_KEYS;
}

export const PasswordStrengthIndicator = ({
  strength = 'weak',
  isCompactView = false,
  smallFont = false,
  containerBgColor = 'primaryTwo',
  ...props
}: PasswordStrengthIndicatorProps) => {
  return (
    <Box flexRow alignCenter justifyContentEnd {...props}>
      <StyledLabel strength={strength} smallFont={smallFont}>
        {strength}
      </StyledLabel>
      <StyledContainer
        css={{
          width: isCompactView ? '60px' : '112px',
          backgroundColor: `$${containerBgColor}`,
        }}
      >
        <StyledIndicator strength={strength} />
      </StyledContainer>
    </Box>
  );
};
