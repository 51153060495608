import { ReactNode } from 'react';
import { CSS } from 'stitches.config';

import { useMedia } from '~hooks/useMedia';

import {
  ScrollableBox,
  StyledTouchSliderInnerWrapper,
  StyledTouchSliderWrapper,
} from './styled.components';

interface TouchSliderProps {
  children?: ReactNode;
  type?: 'desktop' | 'sportGroup' | 'menu';
  fullWidth?: boolean;
  noGradient?: boolean;
  css?: CSS;
}

export const TouchSlider = ({
  children,
  type,
  fullWidth,
  noGradient,
  css,
}: TouchSliderProps) => {
  const { isMobileOrTablet } = useMedia();

  return (
    <StyledTouchSliderWrapper
      css={css}
      type={type}
      noGradient={noGradient || !isMobileOrTablet}
      fullWidth={fullWidth}
    >
      <ScrollableBox>
        <StyledTouchSliderInnerWrapper type={type} fullWidth={fullWidth}>
          {children}
        </StyledTouchSliderInnerWrapper>
      </ScrollableBox>
    </StyledTouchSliderWrapper>
  );
};
