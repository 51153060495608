import * as TabsUI from '@radix-ui/react-tabs';
import { styled } from 'stitches.config';

export const StyledAuthTabsRoot = styled(TabsUI.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '410px',
  p: '$0',
  '@xs': {
    width: '100%',
  },
});

export const StyledAuthTabsList = styled(TabsUI.List, {
  flexShrink: 0,
  display: 'flex',
  gap: 6,
  margin: '$5 0',
});

export const StyledAuthTabsTrigger = styled(TabsUI.Trigger, {
  all: 'unset',
  backgroundColor: '$secondaryTwo',
  height: '2px',
  lineHeight: 0,
  display: 'flex',
  flex: 1,
  borderRadius: '$6',
  userSelect: 'none',
  '&[data-state="active"]': {
    backgroundColor: '$accentPrimaryOne',
  },
});

export const StyledAuthTabsContent = styled(TabsUI.Content, {
  flexGrow: 1,
  outline: 'none',
  '@xs_sm': {
    pb: '$12',
  },
});
