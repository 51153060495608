import { useState } from 'react';
import copy from 'copy-to-clipboard';

export interface ShareData {
  title?: string;
  text?: string;
  url?: string;
}

enum COPY_LABELS {
  COPY = 'Copy link',
  COPIED = 'Copied',
}

export const useCopyToClipboard = () => {
  const [copiedVal, setCoppiedVal] = useState<string | undefined>(undefined);
  const [isCopied, setIsCopied] = useState(false);
  const [copyLinkLabel, setCopyLinkLabel] = useState<string>('Copy link');

  const handleCopy = (value: string | undefined) => {
    if (!value) return;
    setCopyLinkLabel(COPY_LABELS.COPIED);
    setCoppiedVal(value);
    copy(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
      setCopyLinkLabel(COPY_LABELS.COPY);
    }, 2000);
  };

  const handleCopyId = (id: string) => (e: React.MouseEvent) => {
    handleCopy(id);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleShare = async (shareData: ShareData) => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        setCopyLinkLabel(COPY_LABELS.COPIED);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
          setCopyLinkLabel(COPY_LABELS.COPY);
        }, 2000);
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      console.log('Web Share API not supported');
    }
  };

  return {
    copiedVal,
    isCopied,
    handleCopy,
    handleShare,
    handleCopyId,
    copyLinkLabel,
  };
};
