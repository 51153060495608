import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { zIndex } from '~utils/zIndex';

export const StyledDialogHeaderWrapper = styled(Box, {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: zIndex.composition.modal.fixed,
});
