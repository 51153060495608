import {
  ClipboardEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getNumbersFromStr } from '~utils/numberUtils';

import { getCode, getFields, padArray } from './helpers';
import { InputProps } from './types';

export const useNumberCodeFieldInput = (
  size: number,
  setCode: (input: string) => void,
  clearError?: () => void,
) => {
  const [inputs, setInputs] = useState<InputProps[]>([]);
  const inputsContainerRef = useRef<HTMLDivElement>(null);

  const setInputsValue = (index: number, value: string) => {
    setInputs((inputs) => {
      const newInputs = [...inputs];

      newInputs[index]!.value = value;

      return newInputs;
    });
  };

  const handleChange = (i: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    const nextField = e.currentTarget.nextSibling as HTMLInputElement;
    const prevField = e.currentTarget.previousSibling as HTMLInputElement;

    clearError && clearError();

    if (e.currentTarget.value.length > 0) {
      setInputsValue(i, e.currentTarget.value);
      nextField && nextField.focus();
    }

    if (e.key === 'Backspace') {
      prevField && prevField.focus();
      setInputsValue(i, '');
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const sanitizedPasteData = getNumbersFromStr(
      e.clipboardData.getData('text'),
    );

    const firstSixCharsArray = padArray(
      sanitizedPasteData.slice(0, size).split(''),
      size,
    );

    populateFields(firstSixCharsArray);
    setInputs(firstSixCharsArray.map((value, i) => ({ id: i, value })));
  };

  const populateFields = (codeArr: string[]) => {
    const fields = inputsContainerRef.current?.childNodes;

    let isFocusSet = false;

    for (let i = 0; i < fields!.length; i++) {
      const isFirstAndIsEmptyCode = i === 0 && !codeArr[i];
      const isLastAndFocusIsNotSet = !isFocusSet && i === fields!.length - 1;

      (fields![i] as HTMLInputElement).value = codeArr[i]!;

      if (codeArr[i] && !codeArr[i + 1] && fields![i + 1]) {
        fields![i + 1] && (fields![i + 1] as HTMLInputElement).focus();
        isFocusSet = true;
      }

      if (isFirstAndIsEmptyCode || isLastAndFocusIsNotSet) {
        (fields![i] as HTMLInputElement).focus();
        isFocusSet = true;
      }
    }
  };

  useEffect(() => {
    setInputs(getFields(size));
  }, [size]);

  useEffect(() => {
    setCode(getCode(inputs));
  }, [inputs]);

  return {
    inputs,
    inputsContainerRef,
    handlePaste,
    handleChange,
  };
};
