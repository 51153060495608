import React from 'react';

import { SportEventItem } from '~api/sportEvent/types';
import { ArrowOddIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { SWIPE_COMPONENT_TYPES } from '~components/atoms/SwipeComponent';
import { Text } from '~components/atoms/Typography';
import { SELECTION_STATUS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { LockIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { addStake, removeStakeBySelection } from '~store/slices/betslipSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
import {
  selectIsEmptyBetslip,
  selectIsSelectionInBetslip,
} from '~store/slices/selectors/betslip';
import { Selection } from '~types/events';
import { isQuickBet } from '~utils/isQuickBet';

interface UseMarketSelectionProps {
  selection: Selection | null | undefined;
  index: number;
  eventId: string;
  marketId: string | undefined;
  isTotalLabel?: boolean;
  totalLabel?: string;
  eventData: SportEventItem;
  isMarketLocked: boolean;
}

export const useMarketSelection = ({
  selection,
  eventId,
  marketId,
  isTotalLabel,
  totalLabel,
  eventData,
  isMarketLocked,
}: UseMarketSelectionProps) => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { odd, status, id } = selection || {
    odd: null,
    status: null,
    selectionId: null,
  };
  const isInBetslip = useAppSelector(selectIsSelectionInBetslip(id as string));
  const isEmptyBetslip = useAppSelector(selectIsEmptyBetslip);
  const { quickBet } = useAppSelector((state) => state.settings);

  let selectionContent;

  if (status === SELECTION_STATUS.OPEN && !isMarketLocked) {
    selectionContent = <ArrowOddIndicator odd={odd} isLeagueEvents />;
  } else {
    selectionContent = (
      <Box css={{ color: '$textPrimaryOne' }}>
        <LockIcon width={16} height={16} />
      </Box>
    );
  }

  if (!selection) {
    selectionContent = (
      <Text
        level={isMobileOrTablet ? '12-20' : '14-28'}
        fontWeight="medium"
        color="accentPrimaryTwo"
      >
        -
      </Text>
    );
  }

  if (isTotalLabel) {
    selectionContent = (
      <Text
        level={isMobileOrTablet ? '12-20' : '14-28'}
        fontWeight="medium"
        color="textSecondaryOne"
      >
        {totalLabel || '-'}
      </Text>
    );
  }

  const handleSelectionClick = () => {
    if (odd && marketId) {
      if (
        isEmptyBetslip &&
        eventData?.status &&
        isQuickBet(eventData?.status, quickBet)
      ) {
        dispatch(setIsSwipeOpen(SWIPE_COMPONENT_TYPES.QUICK_BET));
      }

      if (isInBetslip) {
        dispatch(removeStakeBySelection(id));
      } else {
        dispatch(addStake({ eventId, marketId, selectionId: id, eventData }));
      }
    }
  };

  return {
    selectionContent,
    handleSelectionClick,
    isInBetslip,
    odd,
  };
};
