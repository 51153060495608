import React, { useMemo } from 'react';

import { AbsoluteDialogs } from '~components/atoms/AbsoluteDialogs';
import { DesktopLayout } from '~components/atoms/layouts/DesktopLayout';
import { MobileLayout } from '~components/atoms/layouts/MobileLayout';
import { ConnectionStatus } from '~components/molecules/ConnectionStatus';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { useOnAppInit } from '~useOnAppInit';

const App = () => {
  const { isAppInitDataReady } = useOnAppInit();
  const { isMobileOrTablet } = useMedia();
  const isMobile = useMemo(() => navigator.userAgent.indexOf('Mobi') > -1, []);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  const renderLayout = useMemo(
    () => (
      <>
        {isMobileOrTablet || isMobile ? <MobileLayout /> : <DesktopLayout />}
        <AbsoluteDialogs />
        <ConnectionStatus />
      </>
    ),
    [isMobileOrTablet, isMobile, isUserLoggedIn],
  );

  if (!isAppInitDataReady) return null;

  return renderLayout;
};

export default App;
