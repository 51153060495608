import {
  useLazyGetAuthInfoQuery,
  useLazyGetUserLimitsQuery,
} from '~api/auth/authApi';

export const useLazyGetAuthInfo = () => {
  const [query, { data, error, isLoading }] = useLazyGetAuthInfoQuery();

  return {
    lazyGetAuthInfoQuery: query,
    lazyGetAuthInfoData: data,
    lazyGetAuthInfoError: error,
    lazyGetAuthInfoIsLoading: isLoading,
  };
};

export const useLazyGetUserLimits = () => {
  const [query, { data, error, isLoading }] = useLazyGetUserLimitsQuery();

  return {
    lazyGetUserLimitsQuery: query,
    lazyGetUserLimitsData: data,
    lazyGetUserLimitsError: error,
    lazyGetUserLimitsIsLoading: isLoading,
  };
};
