import React, { FC } from 'react';
import { styled } from 'stitches.config';

import { useTranslation } from '~hooks/useTranslation';
import { SVGElement } from '~types/general';

type Props = {
  icon: SVGElement;
  title: string;
  isDesktop: boolean;
  onClick?: () => void;
};

const StyledIframeHeaderItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
  fontSize: 14,
  fontWeight: 500,
  cursor: 'pointer',
  color: '$textPrimaryOne',
  borderRadius: 6,
  '&:hover': {
    color: '$accentPrimaryTwo',
  },
});

export const IframeHeaderItem: FC<Props> = ({
  icon: Icon,
  title,
  isDesktop,
  onClick,
}) => {
  const { localized } = useTranslation();

  return (
    <StyledIframeHeaderItem
      key={title}
      css={{
        border: isDesktop ? 'none' : '1px solid $secondaryTwo',
        width: isDesktop ? 'auto' : 36,
        height: isDesktop ? 'auto' : 36,
      }}
      onClick={onClick}
    >
      <Icon width={20} height={20} />
      {isDesktop && localized(title).toUpperCase()}
    </StyledIframeHeaderItem>
  );
};
