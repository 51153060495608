import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledNavigationContainer = styled(Box, {
  cursor: 'pointer',
  height: '$5',
  '&:hover *': {
    color: '$accentPrimaryTwo',
  },
  variants: {
    mobile: {
      true: {
        position: 'relative',
        justifyContent: 'flex-start',
        backgroundColor: '$primaryTwo',
        borderRadius: 0,
        height: 'auto',
        p: '$4 $8',
        '&:not(:last-child)': {
          borderBottom: '1px solid $secondaryOne',
        },
        '&:first-child': {
          borderTopLeftRadius: '$8',
          borderTopRightRadius: '$8',
        },
        '&:last-child': {
          borderBottomLeftRadius: '$8',
          borderBottomRightRadius: '$8',
        },
        '& .icon': {
          position: 'absolute',
          left: '$3',
          color: '$textSecondaryOne',
        },
        '&:active': {
          '& > *': {
            color: '$accentPrimaryTwo',
            fontWeight: '$medium',
          },
          '& .icon': {
            color: '$accentPrimaryTwo',
          },
        },
      },
    },
    active: {
      true: {
        '& *': {
          color: '$accentPrimaryTwo',
        },
      },
    },
    sub: {
      true: {
        p: '$3 $3 $3 0',
        backgroundColor: 'transparent',
      },
    },
  },
});
