export const getDesktopColumnSpan = (index: number) => {
  const pattern = [
    [2, 1, 1, 1, 1, 1, 1],
    [1, 1, 1, 1, 1, 1, 2],
    [1, 1, 1, 2, 1, 1, 1],
  ];

  const row = Math.floor(index / 7) % 3;

  const position = index % 7;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return pattern[row][position];
};

export const getTabletColumnSpan = (index: number) => {
  const pattern = [
    [2, 1, 1, 1],
    [1, 1, 1, 2],
  ];

  const row = Math.floor(index / 4) % 2;

  const position = index % 4;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return pattern[row][position];
};

export const getMobileColumnSpan = (index: number) => {
  const pattern = [
    [2, 1],
    [1, 2],
  ];

  const row = Math.floor(index / 2) % 2;

  const position = index % 2;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return pattern[row][position];
};

export const getResponsiveProps = (variant?: string) => {
  return {
    xs: variant === 'xs',
    sm: variant === 'sm',
    xl: variant === 'xl',
  };
};
