import React from 'react';

import { Text } from '~components/atoms/Typography';
import { ListItem, UnorderedList } from '~components/atoms/UnorderedList';
import { useTranslation } from '~hooks/useTranslation';

export const AccountVerificationCriteria = () => {
  const { localized } = useTranslation();

  return (
    <UnorderedList>
      <Text
        css={{
          fontSize: '$12',
          lineHeight: '$20',
          '@xs_sm': {
            fontSize: '$11',
            lineHeight: '$16',
          },
        }}
      >
        {localized(
          'userProfile.personalProfileInfo.accountVerification.infoPopover.description',
        )}
      </Text>
      {Array.from({ length: 6 }).map((_, index) => (
        <ListItem key={index + 1}>
          {localized(
            `userProfile.personalProfileInfo.accountVerification.infoPopover.criteria${
              index + 1
            }`,
          )}
        </ListItem>
      ))}
    </UnorderedList>
  );
};
