import React, { useState } from 'react';
import { Select } from 'antd';
import { BaseOptionType } from 'rc-select/es/Select';
import { SelectHandler } from 'rc-select/lib/Select';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import {
  CheckedIcon,
  ChevronDownIcon,
  GrayCrossIcon,
  SearchIcon,
} from '~icons';

interface Option {
  label: string;
  value: string;
}

interface AutocompleteProps {
  options: Option[];
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
}

const StyledSelect = styled(Select, {
  height: '36px',
  '& .ant-select-selector': {
    backgroundColor: '$secondaryTwo !important',
    padding: '0px 5px !important',
  },
});

export const Autocomplete = ({
  options,
  onChange,
  value,
  placeholder,
}: AutocompleteProps) => {
  const { localized } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || null);
  const [searchValue, setSearchValue] = useState('');
  const handleSelect = (value: string, option: Option | undefined) => {
    if (option) {
      setSelectedValue(value);
      setIsOpened(false);
      onChange(value);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box
      fullWidth
      css={{
        '& .ant-select': {
          width: '100% !important',
        },
        '& .ant-select-selector > span': {
          marginLeft: '$2',
        },
      }}
    >
      <StyledSelect
        value={selectedValue}
        searchValue={searchValue}
        onSearch={handleSearchChange}
        onSelect={handleSelect as SelectHandler<unknown, BaseOptionType>}
        options={options}
        showSearch
        allowClear={{
          clearIcon: (
            <Box shouldHover>
              <GrayCrossIcon width={14} height={14} />
            </Box>
          ),
        }}
        notFoundContent={
          <Text
            color="primaryTwo"
            level="14-24"
            css={{ width: '100%', textAlign: 'center' }}
          >
            {localized('noResults')}
          </Text>
        }
        placeholder={placeholder}
        menuItemSelectedIcon={<CheckedIcon />}
        onDropdownVisibleChange={setIsOpened}
        open={isOpened}
        onClear={() => {
          setSelectedValue(null);
        }}
        suffixIcon={
          <Box css={{ color: '$textSecondaryOne' }}>
            {selectedValue ? null : isOpened ? (
              <SearchIcon />
            ) : (
              <ChevronDownIcon />
            )}
          </Box>
        }
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
      />
    </Box>
  );
};
