import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';

const LuckyDipText = styled(Text, {
  fontSize: '14px !important',
  lineHeight: '24px !important',
  fontWeight: '$normal',
});

export const LuckyDipTooltip = () => {
  const { isMobileOrTablet } = useMedia();

  return (
    <Box flexCol gap={2} css={{ padding: isMobileOrTablet ? '$4' : 0 }}>
      <LuckyDipText textTransform="uppercase">
        Fancy winning it big with a very small stake?
      </LuckyDipText>
      <LuckyDipText>
        Simply fill in your Pick17 - 1X2 jackpot coupon with your 17 winners or
        13 winners for Pick13 - 1X2 Jackpot and let the teams do the rest for
        you.
      </LuckyDipText>
      <LuckyDipText>
        If your gut feeling is telling you a match could go either way, then you
        can choose to cover your options by selecting two or even all three
        outcomes from the event.
      </LuckyDipText>
      <LuckyDipText>
        If you fancy your chances from lady luck, try the lucky dip button. When
        you click this button, a random coupon will be generated you can either
        play as it is, or amend and add selections to if you fancy some hedges.
      </LuckyDipText>
      <LuckyDipText>Best of luck!</LuckyDipText>
    </Box>
  );
};
