import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

import {
  StyledActionButton,
  StyledErrorText,
  StyledInput,
  StyledInputContainer,
  StyledSuccessMessage,
} from './styled.components';
import { InputWithButtonProps } from './types';

export const InputWithButton = ({
  input,
  button,
  isSuccess,
  successText,
  error,
}: InputWithButtonProps) => {
  const { customComponent, placeholder, onChange, isError } = input;
  const { text, onClick, isDisabled, isLoading } = button;

  return (
    <Box
      flexCol
      gap={1}
      css={{
        '@lg': {
          width: '100%',
        },
      }}
    >
      <StyledInputContainer>
        {isSuccess ? (
          <StyledSuccessMessage>
            <Text level="18-24" fontWeight="bold" textTransform="uppercase">
              {successText}
            </Text>
          </StyledSuccessMessage>
        ) : (
          <>
            <Box flexRow fullWidth>
              {customComponent || (
                <StyledInput
                  placeholder={placeholder}
                  onChange={onChange}
                  css={{
                    color: isError ? '$redPrimary' : '$textPrimaryOne',
                    backgroundColor: 'transparent',
                  }}
                />
              )}
            </Box>

            <StyledActionButton
              onClick={onClick}
              disabled={isDisabled}
              isLoading={isLoading}
            >
              {text}
            </StyledActionButton>
          </>
        )}
      </StyledInputContainer>
      {error && <StyledErrorText>{error}</StyledErrorText>}
    </Box>
  );
};
