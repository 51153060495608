import {
  documentsStatusMap,
  documentsStatusToTypeMap,
  ExtractedDocumentTypeStatuses,
  UserDocumentResponse,
} from '~api/user/types';
import { useDocumentDownload } from '~hooks/useDocumentDownload';
import { useTranslation } from '~hooks/useTranslation';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import { DataRow } from '../types';

export const useUserVerificationData = (data: UserDocumentResponse[]) => {
  const { localized } = useTranslation();
  const { downloadFile } = useDocumentDownload();

  const preparedData: DataRow[] = data
    .map(({ id, type, status, created, file }) => {
      return {
        documentType:
          documentsStatusToTypeMap[type as ExtractedDocumentTypeStatuses],
        date: formatDateTime(created, TIME_FORMATS.DATE),
        status: documentsStatusMap[status],
        documentName: file.fileName,
        id,
        url: file.url,
      };
    })
    .reverse();

  return { localized, preparedData, downloadFile };
};
