import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TopInplayEvents } from '~api/sportEvent/types';
import { SportEventsMainData } from '~types/events';

export interface LiveGroupsState {
  topLiveEvents: TopInplayEvents;
  isTopLiveEventsLoaded: boolean;
  selectedTopLiveEventsSport: number | null;
  isTopLiveEventsOpen: boolean;
  isFavoriteEventsOpen: boolean;
  openLiveGroups: string[];
  favoriteInplayEvents: SportEventsMainData;
}

const initialState: LiveGroupsState = {
  topLiveEvents: [],
  isTopLiveEventsLoaded: false,
  selectedTopLiveEventsSport: null,
  isTopLiveEventsOpen: false,
  isFavoriteEventsOpen: false,
  openLiveGroups: [],
  favoriteInplayEvents: [],
};

export const liveGroupsSlice = createSlice({
  name: 'liveGroups',
  initialState,
  reducers: {
    setTopLiveEvents: (state, action: PayloadAction<TopInplayEvents>) => {
      state.topLiveEvents = action.payload;
    },
    setIsTopLiveEventsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isTopLiveEventsLoaded = action.payload;
    },
    setIsTopLiveEventsOpen: (state, action: PayloadAction<boolean>) => {
      state.isTopLiveEventsOpen = action.payload;
    },
    setSelectedTopLiveEventsSport: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.selectedTopLiveEventsSport = action.payload;
    },
    setOpenLiveGroups: (state, action: PayloadAction<string[]>) => {
      state.openLiveGroups = action.payload;
    },
    setIsFavoriteEventsOpen: (state, action: PayloadAction<boolean>) => {
      state.isFavoriteEventsOpen = action.payload;
    },
    setFavoriteInplayEvents: (
      state,
      action: PayloadAction<SportEventsMainData>,
    ) => {
      state.favoriteInplayEvents = action.payload;
    },
  },
});

export const {
  setTopLiveEvents,
  setIsTopLiveEventsLoaded,
  setIsTopLiveEventsOpen,
  setSelectedTopLiveEventsSport,
  setOpenLiveGroups,
  setIsFavoriteEventsOpen,
  setFavoriteInplayEvents,
} = liveGroupsSlice.actions;

export default liveGroupsSlice.reducer;
