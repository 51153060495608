import React from 'react';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks';

export const PageLoader = () => {
  const { localized } = useTranslation();

  return (
    <Box flexCol alignCenter gap={3} css={{ padding: '32px' }}>
      <Loader css={{ color: '$textSecondaryOne' }} />
      <Text color="textSecondaryOne" level="sm-3" fontWeight="medium">
        {localized('betslipHistory.loading')}
      </Text>
    </Box>
  );
};
