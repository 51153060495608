import * as oddslib from 'oddslib';

type OddsFormats = {
  decimal: string;
  fractional: string;
  hongKong: string;
  american: string;
  indo: string;
  malay: string;
};

export const convertOdds = (decimalOdds: number): OddsFormats => {
  if (decimalOdds <= 1)
    return {
      decimal: decimalOdds.toString(),
      fractional: decimalOdds.toString(),
      hongKong: decimalOdds.toString(),
      american: decimalOdds.toString(),
      indo: decimalOdds.toString(),
      malay: decimalOdds.toString(),
    };
  const odds = oddslib.from('decimal', decimalOdds);

  // Manual conversion for Hong Kong odds
  const hongKongOdds = (decimalOdds: number): string => {
    return (decimalOdds - 1).toFixed(2);
  };

  // Manual conversion for Indo odds
  const indoOdds = (decimalOdds: number): string => {
    const hkOdds = parseFloat(hongKongOdds(decimalOdds));

    return (hkOdds >= 1 ? hkOdds : -1 / hkOdds).toFixed(2);
  };

  // Manual conversion for Malay odds
  const malayOdds = (decimalOdds: number): string => {
    const hkOdds = parseFloat(hongKongOdds(decimalOdds));

    return (hkOdds >= 1 ? 1 / hkOdds : -hkOdds).toFixed(2);
  };

  return {
    decimal: decimalOdds.toFixed(2),
    fractional: odds.to('fractional') as string,
    hongKong: hongKongOdds(decimalOdds),
    american: (odds.to('moneyline') as number).toFixed(0),
    indo: indoOdds(decimalOdds),
    malay: malayOdds(decimalOdds),
  };
};
