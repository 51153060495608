import { ReactNode } from 'react';
import * as TooltipUI from '@radix-ui/react-tooltip';

import {
  StyledTooltipArrow,
  StyledTooltipArrowBorder,
  StyledTooltipContent,
  StyledTooltipContentText,
} from './styled.components';

interface TooltipProps {
  text: string;
  children: ReactNode;
  tone?: 'black';
}

export const Tooltip = ({ text, children, tone = 'black' }: TooltipProps) => {
  return (
    <TooltipUI.Provider delayDuration={0}>
      <TooltipUI.Root>
        <TooltipUI.Trigger asChild>{children}</TooltipUI.Trigger>
        <TooltipUI.Portal>
          <StyledTooltipContent sideOffset={12} tone={tone}>
            <StyledTooltipContentText tone={tone}>
              {text}
            </StyledTooltipContentText>
            <StyledTooltipArrowBorder tone={tone} />
            <StyledTooltipArrow tone={tone} />
          </StyledTooltipContent>
        </TooltipUI.Portal>
      </TooltipUI.Root>
    </TooltipUI.Provider>
  );
};
