import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useAppDispatch } from '~store';
import {
  setAdjustedBetAmount,
  setStakePossibleWinLoadingMap,
} from '~store/slices/betslipSlice';
import { commaSeparatedNumber } from '~utils/numberUtils';

import {
  BETSLIP_VALUES_MAP_OPTIONS,
  betslipAdjustmentOptions,
} from '../../constants';

export const BetAdjustmentButtons = () => {
  const dispatch = useAppDispatch();

  return (
    <Box justifyContentBetween flexRow gap={2} css={{ paddingTop: '$3' }}>
      {betslipAdjustmentOptions.map((item) => (
        <Button
          key={`option-${item}`}
          variant="secondary"
          css={{
            backgroundColor: '$primaryTwo',
            flex: 1,
            boxShadow: 'none',
            '&:hover': {
              color: '$hoverPrimary',
            },
          }}
          onClick={() => {
            dispatch(setAdjustedBetAmount({ main: item.toString() }));
            dispatch(
              setStakePossibleWinLoadingMap({
                [BETSLIP_VALUES_MAP_OPTIONS.ALL]: true,
              }),
            );
          }}
        >
          <Text
            textAlign="center"
            css={{
              fontSize: '$sm',
              lineHeight: '$32',
              '@xs_sm': {
                lineHeight: '$28',
              },
            }}
          >
            {commaSeparatedNumber(item, true)}
          </Text>
        </Button>
      ))}
    </Box>
  );
};
