import { BetslipHistoryDetails } from '~api/betslip/types';
import {
  SPORT_BETSLIP_TYPE_CODES,
  SPORT_BETSLIP_TYPES_MAP,
} from '~components/molecules/Betslip/constants';
import { BETSLIP_STATUS } from '~constants/common';
import { calculateCombinations } from '~utils/numberUtils';
import { capitalizeFirstLetter } from '~utils/stringUtils';

import { BET_SECTIONS_VALUES, STATUS_ICONS } from './constants';

export const isValidStatus = (
  status: number | undefined,
): status is BETSLIP_STATUS =>
  !!status && Object.values(BETSLIP_STATUS).some((value) => value === status);

export const getStatusIcon = (status?: number) => {
  if (!isValidStatus(status)) {
    return null;
  }

  return STATUS_ICONS[status as BETSLIP_STATUS];
};

export const getBetType = (
  type: SPORT_BETSLIP_TYPE_CODES,
  postfix?: string,
) => {
  return capitalizeFirstLetter(
    SPORT_BETSLIP_TYPES_MAP[type] + (postfix ? ` ${postfix}` : ''),
  );
};

export const getSectionName = (details?: BetslipHistoryDetails[]) => {
  const [detail] = details || [];
  const section = Object.values(BET_SECTIONS_VALUES).find(
    (key) => !!detail?.[key as keyof typeof detail],
  );

  return capitalizeFirstLetter(section || '');
};

export const getSystemBetOptionsLabel = (
  eventsCount: number,
  systemBetCombinationSize: number | null,
) => {
  if (!systemBetCombinationSize) return '';

  const combinationsCount = systemBetCombinationSize
    ? calculateCombinations(eventsCount, systemBetCombinationSize)
    : 0;

  return `${systemBetCombinationSize}/${eventsCount} (${combinationsCount} opt.)`;
};
