import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

export const useBreadcrumbEmptyCallback = () => {
  const { updateQueryParams } = useRouterQuery();
  const { sportId, countryId, eventId, leagueId, menu } = useQueryParams();

  const handleEmptyBreadcrumbClick = () => {
    let params = {};

    if (menu === SPORT_MENUS.PREMATCH) {
      if (eventId) {
        params = { sportId, countryId, leagueId, menu };
      } else if (leagueId) {
        params = { sportId, countryId, menu };
      }
    } else {
      return;
    }

    updateQueryParams(params as QueryParams<typeof QUERY_PARAMS>, true);
  };

  return {
    handleEmptyBreadcrumbClick,
  };
};
