import { useMemo } from 'react';

import { MainMarket } from '~api/market/types';
import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { MARKETS_GAP, PREMATCH_SELECTION_HEIGHT } from '~constants/mainPage';
import { useMedia } from '~hooks/useMedia';

import { MarketsData } from './hooks/useEventsListItem';
import { EventMarket } from './EventMarket';
import { getLiveSelectionHeight } from './helpers';

export interface MarketsEventDataProps {
  marketsData: MarketsData;
  mainMarkets: MainMarket[];
  eventId: string;
  isLive?: boolean;
  eventData: SportEventItem;
}

export const MarketsEventData = ({
  marketsData,
  mainMarkets,
  eventId,
  isLive,
  eventData,
}: MarketsEventDataProps) => {
  const { isLaptop } = useMedia();

  const marketsItems = useMemo(() => {
    return marketsData.map((market, index) => (
      <EventMarket
        eventData={eventData}
        key={`${market?.id}-${index}`}
        market={market}
        mainMarket={mainMarkets[index]}
        eventId={eventId}
        isLive={isLive}
      />
    ));
  }, [marketsData, mainMarkets, eventId, isLive]);

  return (
    <Box
      flexRow
      alignCenter
      fullHeight
      css={{
        minHeight: `${
          isLive ? getLiveSelectionHeight(isLaptop) : PREMATCH_SELECTION_HEIGHT
        }px`,
        pt: !isLive || isLaptop ? '$1' : '$2',
        gap: `${MARKETS_GAP}px`,
        mb: isLive ? 0 : '$1',
      }}
    >
      {marketsItems}
    </Box>
  );
};
