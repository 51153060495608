export const baseStylesOverwriteCss = {
  '*': { boxSizing: 'border-box' },
  html: {
    backgroundColor: '$primaryTwo',
    // '@xs_sm': {
    //   height: '100%',
    //   overflow: 'hidden',
    // },
  },
  body: {
    margin: 0,
    p: 0,
    fontFamily: 'Poppins, Noto Sans',
  },
  'body, #root': {
    '@xs_sm': {
      height: '100%',
    },
  },
  // Styles that remove arrows from input[type=number]
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
  '::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    background: '$textSecondaryOne',
    borderRadius: '5px',
    border: '1px solid transparent',
  },
  '.STATSCOREWidget--partialSwitcher__icon': {
    display: 'none !important',
  },
};
