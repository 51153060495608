import { useEffect, useState } from 'react';

import { calculateTimeRemainingInSeconds } from '~utils/formatDateTime';

export const useCountdownTimer = (futureTime: string) => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemainingInSeconds(futureTime),
  );
  const isTimerAvailable = timeRemaining > 0;

  const resetTimer = (newFutureTime: string) => {
    setTimeRemaining(calculateTimeRemainingInSeconds(newFutureTime));
  };

  useEffect(() => {
    if (isTimerAvailable) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => Math.max(0, prevTime - 1));
      }, 1000);

      return () => clearInterval(timer);
    }

    return undefined;
  }, [isTimerAvailable]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  return {
    timeRemaining,
    formatTime,
    isTimerAvailable,
    resetTimer,
  };
};
