import { useEffect, useState } from 'react';

// Custom hook to get and track the URL hash value
export function useHash(): string {
  // State to store the current hash
  const [hash, setHash] = useState<string>(window.location.hash);

  // Effect to update the hash state when the hash changes
  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash);
    };

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []); // Empty dependency array means this effect runs only once on mount

  return hash;
}
