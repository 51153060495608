import React, { Component, ReactNode } from 'react';

interface ErrorBoundaryState {
  hasError: string | null;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: error.toString() };
  }

  render() {
    if (this.state.hasError) {
      return (
        <span>
          Oops! Something went wrong:
          <br />
          {this.state.hasError}
        </span>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
