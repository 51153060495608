import { Countries, Leagues } from '~api/category/types';

import { MULTI_ACCORDION_ROLES, MultiAccordionRoot } from '../MultiAccordion';

import { CountryMenuItem } from './CountryMenuItem';

interface CountryMenuContentProps {
  sportId: number;
  initialCountries: Countries;
  countries: Countries;
  countryMenuRole: MULTI_ACCORDION_ROLES;
  openCountries: string[];
  getLeaguesData: (countryId: number) => Leagues;
  handleOnValueChange: (value: string[]) => void;
}

export const CountryMenuContent = ({
  sportId,
  countries = [],
  initialCountries,
  countryMenuRole,
  getLeaguesData,
  openCountries,
  handleOnValueChange,
}: CountryMenuContentProps) => {
  return (
    <MultiAccordionRoot
      type="multiple"
      role={countryMenuRole}
      value={openCountries}
      onValueChange={handleOnValueChange}
    >
      {(initialCountries?.length > 0 ? initialCountries : countries).map(
        ({ id, events, name, leagues, code }) => (
          <CountryMenuItem
            key={id}
            {...{
              id,
              events,
              name,
              leagues,
              sportId,
              code,
              getLeaguesData,
              countryMenuRole,
            }}
          />
        ),
      )}
    </MultiAccordionRoot>
  );
};
