import { Location } from 'react-router-dom';
import { createSlice } from '@reduxjs/toolkit';

export interface HistoryBrowserState {
  previousLocation: Location | null;
}

const initialState: HistoryBrowserState = {
  previousLocation: null,
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setPreviousLocation: (state, action) => {
      state.previousLocation = action.payload;
    },
  },
});

export const { setPreviousLocation } = historySlice.actions;

export default historySlice.reducer;
