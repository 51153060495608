export const betonly = {
  colors: {
    primaryOne: '#0A0F14',
    primaryTwo: '#1F2730',
    secondaryOne: '#182029',
    secondaryTwo: '#323B47',
    textButtons: '#FAFAFA',
    textPrimaryOne: '#FAFAFA',
    textSecondaryOne: '#CCCCCC',
    textPrimaryTwo: '#0A0F14',
    accentPrimaryOne: '#048531',
    accentSecondaryOne: '#023D17',
    accentPrimaryTwo: '#F5CA14',
    accentSecondaryTwo: '#3D3305',
    pressedOne: '#035720',
    redPrimary: '#EB3731',
    redSecondary: '#3D0E0D',
    greenPrimary: '#059939',
    greenSecondary: '#023D17',
    yellowPrimary: '#F5CA14',
    yellowSecondary: '#3D3305',
    bluePrimary: '#1F60FF',
    orangePrimary: '#F07300',
    hoverPrimary: '#20A850',
    hoverSecondary: '#0A0F14',

    // COLORS WITH OPACITY
    primaryOneOpacity: 'rgba(10, 15, 20, 0.7)',
    greenPrimaryOpacity: 'rgba(5, 153, 57, 0.2)',
    yellowPrimaryOpacity: 'rgba(245, 202, 20, 0.2)',
    primaryTwoOpacity: 'rgba(31, 39, 48, 0.7)',
    footerBackgroundOpacity: 'rgba(24, 32, 41, 0.80)',
    blackOpacity0: 'rgba(10, 15, 20, 0.0)',
  },
  shadows: {
    elementHoverBorder: 'inset 0 0 0 1px #20A850', // hoverPrimary
    elementActiveBorder: 'inset 0 0 0 1px #035720', // pressedOne
    elementErrorBorder: 'inset 0 0 0 1px #EB3731', // redPrimary
    elementNeutralBorder: 'inset 0 0 0 1px #FAFAFA', // textPrimaryOne
  },
};
