import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { ChevronDownIcon } from '~icons';

export const StyledUserProfileBarContainer = styled(Box, {
  borderRadius: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid $accentPrimaryOne',
  height: '100%',
  maxHeight: '40px',
  minHeight: '40px',
  p: '2px',
  color: '$textPrimaryOne',
  svg: {
    width: '24px',
    height: '24px',
  },
  '@xs_sm': {
    maxHeight: '28px',
    minHeight: '28px',
    svg: {
      width: '20px',
      height: '20px',
    },
  },
  '&:hover': {
    border: '1px solid $textPrimaryOne !important',
    cursor: 'pointer',
  },
  variants: {
    isOpened: {
      true: {
        background: '$accentSecondaryOne',
      },
    },
    isSquare: {
      true: {
        aspectRatio: 1,
      },
    },
    isRounded: {
      true: {
        borderRadius: '50%',
      },
    },
    isFooter: {
      true: {
        border: 'none',
        background: 'transparent',
      },
    },
  },
});

export const StyledUserProfileInfoContainer = styled(Box, {
  height: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  gap: '$2',
  p: '$2',

  '&:active *': {
    '@xs_sm': {
      color: '$accentPrimaryOne',
    },
  },
  '@xs_sm': {
    p: '$1',
    gap: '$1',
  },
});

export const StyledChevron = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '&[data-state="open"]': {
    transform: 'rotate(180deg)',
    fill: '$textPrimaryOne',
    stroke: '$textPrimaryOne',
  },
  '&:hover path, &[data-state="open"] path': {
    fill: '$textPrimaryOne',
    stroke: '$textPrimaryOne',
  },
});

export const StyledDepositButton = styled(Button, {
  width: '160px',
  lineHeight: '$24',
  py: '$1',
  borderRadius: '$4 !important',
  maxHeight: '36px',
  svg: {
    width: '25px !important',
    height: '15.58px !important',
  },
  '& >  svg': {
    color: '$textPrimaryOne !important',
    width: '25px !important',
    height: '15.58px !important',
  },
  '@md': {
    width: '136px',
  },
  '@xs_sm': {
    width: 'unset',
    lineHeight: '2rem !important',
    fontSize: '$xxs !important',
    p: '$1 !important',
    maxHeight: '24px',
  },
  '@xxs': {
    minWidth: '2rem !important',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '$1',
  },
  variants: {
    separateBonusBalance: {
      true: {
        height: '46px',
        maxHeight: '46px !important',
        '@xs_sm': {
          height: '2rem',
          minWidth: '2rem !important',
          maxHeight: '2rem !important',
          svg: {
            width: '25px !important',
            height: '15.58px !important',
          },
        },
      },
    },
  },
});
