import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const Separator = styled(Box, {
  display: 'block',
  backgroundColor: '$primaryTwo',
  height: '1px',
  width: '100%',
  variants: {
    verticalSpace: {
      0: {
        marginTop: '$0',
        mb: '$0',
      },
      1: {
        marginTop: '$1',
        mb: '$1',
      },
      2: {
        marginTop: '$2',
        mb: '$2',
      },
      3: {
        marginTop: '$3',
        mb: '$3',
      },
      4: {
        marginTop: '$4',
        mb: '$4',
      },
      5: {
        marginTop: '$5',
        mb: '$5',
      },
      6: {
        marginTop: '$6',
        mb: '$6',
      },
      7: {
        marginTop: '$7',
        mb: '$7',
      },
      14: {
        marginTop: '14px',
        marginBottom: '14px',
      },
    },
    shrinkOut: {
      0: {
        marginLeft: '$0',
        width: `calc(100% + (2 * $0))`,
      },
      1: {
        marginLeft: '-$1',
        width: `calc(100% + (2 * $1))`,
      },
      2: {
        marginLeft: '-$2',
        width: `calc(100% + (2 * $2))`,
      },
      3: {
        marginLeft: '-$3',
        width: `calc(100% + (2 * $3))`,
      },
      4: {
        marginLeft: '-$4',
        width: `calc(100% + (2 * $4))`,
      },
      5: {
        marginLeft: '-$5',
        width: `calc(100% + (2 * $5))`,
      },
      6: {
        marginLeft: '-$6',
        width: `calc(100% + (2 * $6))`,
      },
      7: {
        marginLeft: 'calc(-$7)',
        width: `calc(100% + (2 * $7))`,
      },
      32: {
        marginLeft: '-32px',
        width: `calc(100% + (2 * 32px))`,
      },
      64: {
        marginLeft: '-64px',
        width: `calc(100% + (2 * 64px))`,
      },
    },
  },
  defaultVariants: {
    verticalSpace: 6,
    shrinkOut: 7,
  },
});
