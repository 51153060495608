import { useState } from 'react';

import { BalanceChange } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { useFormatAmountWithCurrency } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { CloseIcon, InfoIcon } from '~icons';

interface BetslipBalancesPopoverProps {
  balanceChange?: BalanceChange;
}

export const BetslipBalancesPopover = ({
  balanceChange,
}: BetslipBalancesPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  if (!balanceChange) {
    return null;
  }

  const { realBetAmount, bonusChanges } = balanceChange;

  return (
    <Box flexRow>
      <Popover isOpen={isOpen}>
        <PopoverContent
          noBorder={isMobileOrTablet}
          noArrow={isMobileOrTablet}
          side="bottom"
          sideOffset={isMobileOrTablet ? 0 : 16}
          align="start"
          tone="secondary"
          width={isMobileOrTablet ? '100vw' : 300}
          css={{
            mr: '14px',
            p: '$4',
            '@xs_sm': {
              mr: 'unset',
              background: '$footerBackgroundOpacity',
              backdropFilter: 'blur(4px)',
              borderRadius: '$12',
            },
          }}
        >
          <Box
            flexCol
            css={{
              '@xs_sm': {
                p: '$4',
                background: '$secondaryTwo',
                border: '1px solid $pressedOne',
                borderRadius: '$8',
                position: 'relative',
              },
            }}
          >
            <Box flexCol gap={3}>
              {isMobileOrTablet && (
                <Box
                  flexRow
                  css={{
                    position: 'absolute',
                    top: '$3',
                    right: '$3',
                    color: '$textSecondaryOne',
                  }}
                  onClick={() => setIsOpen(false)}
                >
                  <CloseIcon />
                </Box>
              )}
              <Text level="14-20" textTransform="uppercase">
                {localized('betslip.balancePopover.title')}
              </Text>
              <Box flexCol gap={1}>
                <Box
                  flexRow
                  justifyContentBetween
                  css={{
                    backgroundColor: '$primaryTwo',
                    borderRadius: '$6',
                    p: '$2 $3',
                  }}
                >
                  <Text
                    level={isMobileOrTablet ? '13-16' : '14-20'}
                    textTransform="capitalize"
                  >
                    {localized('betslip.balancePopover.mainBalance')}
                  </Text>
                  <Text
                    level={isMobileOrTablet ? '13-16' : '14-20'}
                    fontWeight="medium"
                    color="yellowPrimary"
                  >
                    {getCommaSeparatedAmount(realBetAmount)}
                  </Text>
                </Box>
                <Box
                  flexCol
                  gap={2}
                  css={{
                    backgroundColor: '$primaryTwo',
                    borderRadius: '$6',
                    p: '$2 $3',
                  }}
                >
                  <Box flexRow justifyContentBetween>
                    <Text
                      level={isMobileOrTablet ? '13-16' : '14-20'}
                      textTransform="capitalize"
                    >
                      {localized('betslip.balancePopover.bonusBalance')}
                    </Text>
                    {!bonusChanges?.length && (
                      <Text
                        level={isMobileOrTablet ? '13-16' : '14-20'}
                        fontWeight="medium"
                        color="yellowPrimary"
                      >
                        {getCommaSeparatedAmount()}
                      </Text>
                    )}
                  </Box>
                  {!!bonusChanges?.length && (
                    <Box flexCol gap={1}>
                      {bonusChanges?.map(
                        ({ userBonusBalanceId, title, betAmount }) => (
                          <Box
                            key={userBonusBalanceId}
                            flexRow
                            alignCenter
                            justifyContentBetween
                            css={{
                              backgroundColor: '$secondaryTwo',
                              borderRadius: '$4',
                              p: '$1 $2',
                            }}
                          >
                            <Text level={isMobileOrTablet ? '11-16' : '12-20'}>
                              {title}
                            </Text>
                            <Text
                              level={isMobileOrTablet ? '11-16' : '12-20'}
                              fontWeight="medium"
                              color="yellowPrimary"
                            >
                              {getCommaSeparatedAmount(betAmount)}
                            </Text>
                          </Box>
                        ),
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </PopoverContent>
        <PopoverTrigger asChild>
          <Box
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            css={{
              cursor: 'pointer',
              color: '$textSecondaryOne',
              lineHeight: 0,
              '&:hover': { color: '$pressedOne' },
            }}
          >
            <InfoIcon width={12} height={12} />
          </Box>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};
