import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '~store';

export interface TranslationState {
  tFunction: (key: string, options?: object) => string;
}

const initialState: TranslationState = {
  tFunction: () => '',
};

export const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setTranslationFunction: (
      state,
      action: PayloadAction<(key: string, options?: object) => string>,
    ) => {
      state.tFunction = action.payload;
    },
  },
});

export const { setTranslationFunction } = translationSlice.actions;

// Memoized selector for the `tFunction`
export const selectTranslationFunction = createSelector(
  (state: RootState) => state.translation.tFunction,
  (tFunction) => tFunction,
);

export default translationSlice.reducer;
