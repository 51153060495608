import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { ScrollArea } from '~components/atoms/ScrollArea';
import {
  StyledUserTabContentSeparator,
  StyledUserTabContentTitle,
  StyledUserTabContentWrapper,
} from '~components/atoms/TabContent';
import { Text } from '~components/atoms/Typography';

import { ActiveBonusCard } from '../ActiveBonusCard';

import { useActiveBonusesContent } from './useActiveBonusesContent';

const BONUSES_HEIGHT = '460px';

const StyledBonusesContainer = styled(Box, {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '$3',
});

export const ActiveBonusesContent = () => {
  const { bonuses, isBonusesLoading, localized } = useActiveBonusesContent();

  return (
    <Box flexCol gap={3}>
      <StyledUserTabContentWrapper>
        <StyledUserTabContentTitle>
          {localized('bonuses.yourActiveBonuses')}
        </StyledUserTabContentTitle>
        <StyledUserTabContentSeparator />
        {isBonusesLoading ? (
          <Box flexCol alignCenter gap={3} css={{ py: '32px' }}>
            <Loader css={{ color: '$textSecondaryOne' }} />
            <Text color="textSecondaryOne" level="20-32" fontWeight="medium">
              {localized('bonuses.loading')}
            </Text>
          </Box>
        ) : !bonuses.length ? (
          <Box flexRow alignCenter justifyCenter css={{ py: '51px' }}>
            <Text color="textSecondaryOne" level="20-32" fontWeight="medium">
              {localized('bonuses.noActiveBonuses')}
            </Text>
          </Box>
        ) : (
          <Box css={{ height: BONUSES_HEIGHT, p: '$2 0 $1' }}>
            <ScrollArea>
              <StyledBonusesContainer>
                {bonuses.map((bonus) => (
                  <ActiveBonusCard key={bonus.id} bonus={bonus} />
                ))}
              </StyledBonusesContainer>
            </ScrollArea>
          </Box>
        )}
      </StyledUserTabContentWrapper>
    </Box>
  );
};
