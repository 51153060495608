import { QueryParameters } from '~constants/common';
import { SPORT_MENUS } from '~types/sportMenus';

export const getPreparedParamsString = (search: QueryParameters) => {
  const {
    sportId = '',
    countryId = '',
    leagueId = '',
    eventId = '',
    menu = '',
  } = search;

  const pathname = window.location.pathname;

  if (pathname !== '/') return;

  let resQueryString = '';

  if (menu === SPORT_MENUS.PREMATCH || !menu.length) {
    if (eventId) {
      resQueryString = `${sportId}-${countryId}-${leagueId}-${eventId}-${menu.length ? menu : SPORT_MENUS.PREMATCH}`;
    } else if (menu === SPORT_MENUS.PREMATCH && leagueId && !eventId) {
      resQueryString = `${sportId}-${countryId}-${leagueId}-${menu.length ? menu : SPORT_MENUS.PREMATCH}`;
    } else {
      resQueryString = `${menu.length ? menu : SPORT_MENUS.PREMATCH}`;
    }
  } else if (menu === SPORT_MENUS.LIVE) {
    if (eventId) {
      resQueryString = `${sportId}-${countryId}-${leagueId}-${eventId}-${menu}`;
    } else {
      resQueryString = `${menu}`;
    }
  }

  return resQueryString;
};
