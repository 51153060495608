import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InPlayMenuSports, InPlaySportEvent } from '~api/sportEvent/types';
import { Market, SportEvent, UpdateEventData } from '~types/events';

export interface LiveMenuState {
  isLiveMenuLoaded: boolean;
  liveMenuSports: InPlayMenuSports;
  liveHighlightsSports: InPlayMenuSports;
  mainMarketsSelected: Record<number, number>;
  defaultMarketsSelected: Record<number, number>;
  marketsOfFavoriteEventsSelected: Record<number, number>;
  openedCountryLeagues: string[];
  openedSports: string[];
  addedLeagueId: string | null;
  addedSportId: string | null;
  updatedSportMarket: {
    sportId: number;
    marketId: number;
    prevMarketId: number | undefined;
  } | null;
  loadingSportId: number | null;
  loadingEventId: string | null;
  needReopenCard?: boolean;
  liveEventData: SportEvent | null;
  marketGroups: Record<string, Market[]>;
  isEventLoaded: boolean;
  isLiveMenuLoading: boolean;
  widgetKey: string | null;
}

const initialState: LiveMenuState = {
  isLiveMenuLoaded: false,
  liveMenuSports: [],
  liveHighlightsSports: [],

  mainMarketsSelected: {},
  defaultMarketsSelected: {},
  marketsOfFavoriteEventsSelected: {},
  openedCountryLeagues: [],
  openedSports: [],
  addedLeagueId: null,
  addedSportId: null,
  updatedSportMarket: null,
  loadingSportId: null,
  loadingEventId: null,
  liveEventData: null,
  marketGroups: {},
  isEventLoaded: false,
  isLiveMenuLoading: true,
  widgetKey: null,
};

export interface EventPayload {
  eventId: string;
  sportId: number;
  countryId: number;
  leagueId: number;
}

export const liveMenuSlice = createSlice({
  name: 'liveMenu',
  initialState,
  reducers: {
    setLiveMenuSports: (state, action: PayloadAction<InPlayMenuSports>) => {
      state.liveMenuSports = action.payload;
      state.isLiveMenuLoaded = true;
    },
    setIsLiveMenuLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLiveMenuLoaded = action.payload;
    },
    resetAddedLeagueId: (state) => {
      state.addedLeagueId = null;
    },
    resetAddedSportId: (state) => {
      state.addedSportId = null;
    },
    setOpenedCountryLeagues: (state, action: PayloadAction<string[]>) => {
      const addedItems = action.payload.filter(
        (item) => !state.openedCountryLeagues.includes(item),
      );

      if (addedItems[0]) {
        state.addedLeagueId = addedItems[0];
      }

      state.openedCountryLeagues = [...new Set([...action.payload])];
    },
    resetUpdatedSportMarket: (state) => {
      state.updatedSportMarket = null;
    },
    addOpenedCountryLeagues: (state, action: PayloadAction<string[]>) => {
      const leagueIds: string[] = [];

      state.liveMenuSports.forEach((sport) => {
        sport.countries.forEach((country) => {
          country.leagues.forEach(({ id }) => {
            leagueIds.push(id.toString());
          });
        });
      });
      const nextOpenedCountryLeagues = new Set([
        ...action.payload,
        ...state.openedCountryLeagues,
      ]);

      state.openedCountryLeagues = [...nextOpenedCountryLeagues].filter(
        (leagueId) => leagueIds.includes(leagueId),
      );
    },
    setOpenedSports: (state, action: PayloadAction<string[] | string>) => {
      if (Array.isArray(action.payload)) {
        const addedItems = action.payload.filter(
          (item) => !state.openedSports.includes(item),
        );

        const addedSportId = addedItems[0];

        if (addedSportId) {
          const leagueIds = state.liveMenuSports
            .filter((sport) => sport.id === parseInt(addedSportId))
            .flatMap((sport) =>
              sport.countries.flatMap((country) =>
                country.leagues.map((league) => league.id.toString()),
              ),
            );

          state.openedCountryLeagues = [
            ...new Set([...state.openedCountryLeagues, ...leagueIds]),
          ];

          state.addedSportId = addedSportId;
        }

        state.openedSports = [...new Set([...action.payload])];
      } else {
        const addedItem = action.payload;

        if (addedItem) {
          state.addedSportId = addedItem;
        }

        state.openedCountryLeagues = state.liveMenuSports
          .filter((sport) => sport.id === parseInt(addedItem))
          .flatMap((sport) =>
            sport.countries.flatMap((country) =>
              country.leagues.map((league) => league.id.toString()),
            ),
          );
        state.openedSports = [action.payload];
      }
    },
    addOpenedSports: (state, action: PayloadAction<string>) => {
      const sportIds: string[] = [];
      const sportId = action.payload;

      if (sportId) {
        const leagueIds = state.liveMenuSports
          .filter((sport) => sport.id === parseInt(sportId))
          .flatMap((sport) =>
            sport.countries.flatMap((country) =>
              country.leagues.map((league) => league.id.toString()),
            ),
          );

        state.openedCountryLeagues = [
          ...new Set([...state.openedCountryLeagues, ...leagueIds]),
        ];
      }

      state.liveMenuSports.forEach((sport) => {
        sportIds.push(`${sport.id}`);
      });

      const nextOpenedSports = new Set([action.payload, ...state.openedSports]);

      state.openedSports = [...nextOpenedSports].filter((sportId) =>
        sportIds.includes(sportId),
      );
    },
    removeLiveMenuEvent: (state, action: PayloadAction<EventPayload>) => {
      const { eventId, sportId, countryId, leagueId } = action.payload;
      const menu = state.liveMenuSports;
      const sportIndex = menu.findIndex((sport) => sport.id === sportId);

      if (sportIndex && sportIndex === -1) return;
      const countries = menu[sportIndex]?.countries ?? [];
      const countryIndex = countries.findIndex(
        (country) => country.id === countryId,
      );
      const leagues = countries[countryIndex]?.leagues ?? [];
      const leagueIndex = leagues.findIndex((league) => league.id === leagueId);

      const events = leagues[leagueIndex]?.events ?? [];
      const resEvents = events.filter(({ id }) => id !== eventId);

      if (resEvents.length) {
        if (leagues[leagueIndex]) {
          leagues[leagueIndex]!.events = resEvents;
        }

        return;
      }

      let removedLeagueId: number;

      if (resEvents.length === 0) {
        removedLeagueId = leagues[leagueIndex]?.id || 0;
        state.openedCountryLeagues = state.openedCountryLeagues.filter(
          (leagueId) => parseInt(leagueId) !== removedLeagueId,
        );
        leagues.splice(leagueIndex, 1);
        const newLeagueId = leagues[leagueIndex]?.id;

        if (newLeagueId) {
          const newLeagueIndex = state.openedCountryLeagues.findIndex(
            (leagueId) => parseInt(leagueId) === newLeagueId,
          );

          if (newLeagueIndex === -1) {
            state.openedCountryLeagues.push(newLeagueId.toString());
          }
        }
      }

      if (leagues.length === 0) {
        countries.splice(countryIndex, 1);
      }

      if (countries.length === 0) {
        menu.splice(sportIndex, 1);
      }
    },
    addLiveMenuEvent: (state, action: PayloadAction<UpdateEventData>) => {
      const menu = state.liveMenuSports;
      const {
        id: eventId,
        sportId,
        sportName,
        countryId,
        countryName,
        leagueId,
        leagueName,
      } = action.payload;

      let sportIndex = menu.findIndex((sport) => sport.id === sportId);

      if (sportIndex === -1) {
        menu.push({ id: sportId || 0, name: sportName || '', countries: [] });
        sportIndex = menu.length - 1;
      }

      const countries = menu[sportIndex]?.countries ?? [];

      let countryIndex = countries.findIndex(
        (country) => country.id === countryId,
      );

      if (countryIndex === -1) {
        countries.push({
          id: countryId || 0,
          name: countryName || '',
          leagues: [],
        });
        countryIndex = countries.length - 1;
      }

      const leagues = countries[countryIndex]?.leagues ?? [];

      let leagueIndex = leagues.findIndex((league) => league.id === leagueId);

      if (leagueIndex === -1 && leagueId) {
        leagues.push({ id: leagueId || 0, name: leagueName || '', events: [] });
        leagueIndex = leagues.length - 1;
        state.openedCountryLeagues.push(leagueId.toString());
      }

      const events = leagues[leagueIndex]?.events ?? [];
      const eventIndex = events.findIndex(({ id }) => id === eventId);

      if (eventIndex !== -1) return;
      events.push(action.payload as InPlaySportEvent);
    },
    setDefaultMainMarketsSelected: (
      state,
      action: PayloadAction<Record<number, number>>,
    ) => {
      state.mainMarketsSelected = action.payload;
      state.defaultMarketsSelected = action.payload;
      state.marketsOfFavoriteEventsSelected = action.payload;
    },
    setMainMarketsSelected: (
      state,
      action: PayloadAction<{ sportId: number; marketId: number }>,
    ) => {
      const { sportId, marketId } = action.payload;

      state.updatedSportMarket = {
        sportId,
        marketId,
        prevMarketId: state.mainMarketsSelected[sportId],
      };
      state.loadingSportId = sportId;
      state.mainMarketsSelected = {
        ...state.mainMarketsSelected,
        [sportId]: marketId,
      };
    },
    setMarketsOfFavoriteEventsSelected: (
      state,
      action: PayloadAction<{ sportId: number; marketId: number }>,
    ) => {
      const { sportId, marketId } = action.payload;

      state.marketsOfFavoriteEventsSelected = {
        ...state.marketsOfFavoriteEventsSelected,
        [sportId]: marketId,
      };
    },
    setLoadingEventId: (state, action: PayloadAction<string | null>) => {
      state.loadingEventId = action.payload;
    },
    setLoadingSportId: (state, action: PayloadAction<number | null>) => {
      state.loadingSportId = action.payload;
    },
    setLiveHighlightsSports: (
      state,
      action: PayloadAction<InPlayMenuSports>,
    ) => {
      state.liveHighlightsSports = action.payload;
    },
    handleAddHightlightEvent: (
      state,
      action: PayloadAction<UpdateEventData>,
    ) => {
      const highlightsSports = state.liveHighlightsSports;
      const {
        id: eventId,
        sportId,
        sportName,
        countryId,
        countryName,
        leagueId,
        leagueName,
      } = action.payload;

      let sportIndex = highlightsSports.findIndex(
        (sport) => sport.id === sportId,
      );

      if (sportIndex === -1) {
        highlightsSports.push({
          id: sportId || 0,
          name: sportName || '',
          countries: [],
        });
        sportIndex = highlightsSports.length - 1;
      }

      const countries = highlightsSports[sportIndex]?.countries ?? [];

      let countryIndex = countries.findIndex(
        (country) => country.id === countryId,
      );

      if (countryIndex === -1) {
        countries.push({
          id: countryId || 0,
          name: countryName || '',
          leagues: [],
          code: '',
        });
        countryIndex = countries.length - 1;
      }

      const leagues = countries[countryIndex]?.leagues ?? [];

      let leagueIndex = leagues.findIndex((league) => league.id === leagueId);

      if (leagueIndex === -1 && leagueId) {
        leagues.push({ id: leagueId || 0, name: leagueName || '', events: [] });
        leagueIndex = leagues.length - 1;
      }

      const events = leagues[leagueIndex]?.events ?? [];
      const eventIndex = events.findIndex(({ id }) => id === eventId);

      if (eventIndex !== -1) return;
      events.push({ ...action.payload, markets: [] } as InPlaySportEvent);
    },
    handleRemoveHightlightEvent: (
      state,
      action: PayloadAction<EventPayload>,
    ) => {
      const { eventId, sportId, countryId, leagueId } = action.payload;
      const highlightsSports = state.liveHighlightsSports;
      const sportIndex = highlightsSports.findIndex(
        (sport) => sport.id === sportId,
      );

      if (sportIndex && sportIndex === -1) return;
      const countries = highlightsSports[sportIndex]?.countries ?? [];
      const countryIndex = countries.findIndex(
        (country) => country.id === countryId,
      );
      const leagues = countries[countryIndex]?.leagues ?? [];
      const leagueIndex = leagues.findIndex((league) => league.id === leagueId);

      const events = leagues[leagueIndex]?.events ?? [];
      const resEvents = events.filter(({ id }) => id !== eventId);

      if (resEvents.length) {
        if (leagues[leagueIndex]) {
          leagues[leagueIndex]!.events = resEvents;
        }

        return;
      }

      if (resEvents.length === 0) {
        leagues.splice(leagueIndex, 1);
      }

      if (leagues.length === 0) {
        countries.splice(countryIndex, 1);
      }

      if (countries.length === 0) {
        state.liveHighlightsSports.splice(sportIndex, 1);
      }
    },
    resetLiveMenuSlice: (state) => {
      const copyDefaultMarketsSelected = { ...state.defaultMarketsSelected };

      Object.assign(state, initialState);
      state.defaultMarketsSelected = copyDefaultMarketsSelected;
      state.mainMarketsSelected = copyDefaultMarketsSelected;
    },
    setLiveEventData: (state, action: PayloadAction<SportEvent | null>) => {
      state.liveEventData = action.payload;
    },
    setMarketGroups: (
      state,
      action: PayloadAction<Record<string, Market[]>>,
    ) => {
      state.marketGroups = action.payload;
    },
    setIsEventLoaded: (state, action: PayloadAction<boolean>) => {
      state.isEventLoaded = action.payload;
    },
    setIsLiveMenuLoading: (state, action: PayloadAction<boolean>) => {
      state.isLiveMenuLoading = action.payload;
    },
    setWidgetKey: (state, action: PayloadAction<string | null>) => {
      state.widgetKey = action.payload;
    },
  },
});

export const {
  setLiveMenuSports,
  setIsLiveMenuLoaded,
  setLiveHighlightsSports,
  setMarketsOfFavoriteEventsSelected,
  addOpenedCountryLeagues,
  resetAddedLeagueId,
  resetUpdatedSportMarket,
  setOpenedSports,
  setOpenedCountryLeagues,
  addOpenedSports,
  removeLiveMenuEvent,
  setDefaultMainMarketsSelected,
  setMainMarketsSelected,
  addLiveMenuEvent,
  resetAddedSportId,
  setLoadingEventId,
  setLoadingSportId,
  setWidgetKey,
  resetLiveMenuSlice,
  setLiveEventData,
  setMarketGroups,
  setIsEventLoaded,
  setIsLiveMenuLoading,
} = liveMenuSlice.actions;

export default liveMenuSlice.reducer;
