import { ReactNode } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

import { Text } from './Typography';

export const RoundLabel = styled('div', {
  width: '6px',
  height: '6px',
  borderRadius: '$round',
  backgroundColor: '$textPrimaryOne',
  flexGrow: 0,
  flexShrink: 0,
  m: '$2 $2 $2 0',
});

interface ListItemProps {
  children: ReactNode;
}

export const ListItem = ({ children, ...props }: ListItemProps) => {
  return (
    <Box flexRow alignCenter>
      <RoundLabel />
      <Text
        css={{
          fontSize: '$12',
          lineHeight: '$20',
          '@xs_sm': {
            fontSize: '$11',
            lineHeight: '$16',
          },
        }}
        {...props}
      >
        {children}
      </Text>
    </Box>
  );
};

interface UnorderedListProps {
  children: ReactNode;
}

export const UnorderedList = ({ children }: UnorderedListProps) => {
  return (
    <Box flexCol gap={2}>
      {children}
    </Box>
  );
};
