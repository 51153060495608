import React from 'react';

import { VerifyEmailTokenResponse } from '~api/auth/types';
import { Loader } from '~components/atoms/Loader';
import { SuccessTab } from '~components/molecules/Authentication/components/sign-up/SuccessTab';

import { StyledVerifyEmailToken } from './styled.components';

interface VerifyEmailTokenContentProps {
  isSuccess: boolean | undefined;
  isLoading: boolean;
  verifyEmailTokenData: VerifyEmailTokenResponse | undefined;
  handleModalClose: () => void;
}

export const VerifyEmailTokenContent = ({
  isSuccess,
  isLoading,
  verifyEmailTokenData,
  handleModalClose,
}: VerifyEmailTokenContentProps) => {
  return (
    <StyledVerifyEmailToken>
      {!isLoading && verifyEmailTokenData ? (
        <SuccessTab
          isEmailVerified
          isSuccess={isSuccess}
          handleModalClose={handleModalClose}
        />
      ) : (
        <Loader
          css={{
            color: '$primaryTwo',
            mt: '$10',
            '& > svg': {
              width: '$8',
              height: '$8',
            },
          }}
        />
      )}
    </StyledVerifyEmailToken>
  );
};
