import {
  ResetPasswordFormInputs,
  SigninFormInputs,
  SignupFormInputs,
} from '~components/molecules/Authentication/types';

export const SIGNUP_FORM_DEFAULT_VALUES: SignupFormInputs = {
  usernameField: '',
  phoneNumberField: '',
  emailField: '',
  passwordField: '',
  promotionCodeField: '',
  agreeToTermsField: false,
  numberCodeField: '',
};

export const SIGNIN_FORM_DEFAULT_VALUES: SigninFormInputs = {
  usernameField: '',
  passwordField: '',
  emailField: '',
  phoneNumberField: '',
};

export const RESET_PASSWORD_FORM_DEFAULT_VALUES: ResetPasswordFormInputs = {
  phoneNumberField: '',
  numberCodeField: '',
  newPasswordField: '',
};

export const USERNAME_MIN_LIMIT = 3;

export const USERNAME_MAX_LIMIT = 30;

export const DEFAULT_PHONE_FIELD_MASK = '### - ### - ####';

export const DEFAULT_PHONE_MASK_NO_DASH = '##########';

export const REFRESH_TOKEN_SECONDS_BEFORE_EXPIRATION = 10;

export const REFRESH_TOKEN_DAYS_BEFORE_EXPIRATION = 7;

export const INPUT_CODE_LENGTH = 6;
