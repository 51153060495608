import React from 'react';
import { CSS } from 'stitches.config';

import { Tooltip } from '~components/atoms/Tooltip';
import { Text } from '~components/atoms/Typography';
import { useIsTextOverflowing } from '~hooks/useIsTextOverflowing';

interface OddsCellProps {
  text: string;
  color?: string;
  css?: CSS;
}

export const TextWithTooltip = ({ text = '', color, css }: OddsCellProps) => {
  const { isOverflowing, spanRef } = useIsTextOverflowing();

  const TextContent = (
    <Text
      ellipsis
      as="span"
      level="xxxs-2"
      color={color}
      ref={spanRef}
      css={{ ...css }}
    >
      {text}
    </Text>
  );

  return isOverflowing ? (
    <Tooltip text={text}>{TextContent}</Tooltip>
  ) : (
    TextContent
  );
};
