import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BetslipHistoryItems,
  BetslipHistoryPayload,
  OpenedBet,
  OpenedBets,
} from '~api/betslip/types';
import { CASH_OUT_ASK_TYPES } from '~components/molecules/CashOut/constants';

export interface OpenedBetsState {
  openBets: OpenedBets;
  betslipHistory: BetslipHistoryItems;
  isCashOut: boolean;
  cashOutBet: OpenedBet | null;
  cashOutAskType?: CASH_OUT_ASK_TYPES;
  cashOutSuccess?: boolean;
  params: BetslipHistoryPayload;
  cashOutProcessed: boolean;
  isCashoutOpen?: boolean;
}

const initialState: OpenedBetsState = {
  openBets: [],
  isCashOut: false,
  cashOutBet: null,
  cashOutAskType: CASH_OUT_ASK_TYPES.ALL,
  betslipHistory: [],
  cashOutSuccess: false,
  params: {},
  cashOutProcessed: false,
  isCashoutOpen: false,
};

export const openBetsSlice = createSlice({
  name: 'openBets',
  initialState,
  reducers: {
    setOpenBets: (state, action: PayloadAction<OpenedBets>) => {
      state.openBets = action.payload;
    },
    setCashOutBet: (state, action: PayloadAction<OpenedBet | null>) => {
      state.cashOutBet = action.payload;
      state.isCashOut = action.payload !== null;
    },
    setIsCashOut: (state, action: PayloadAction<boolean>) => {
      state.isCashOut = action.payload;
    },
    setCashOutAskType: (state, action: PayloadAction<CASH_OUT_ASK_TYPES>) => {
      state.cashOutAskType = action.payload;
    },
    setCashOutSuccess: (state, action: PayloadAction<boolean>) => {
      state.cashOutSuccess = action.payload;
    },
    setCashOutProcessed: (state, action: PayloadAction<boolean>) => {
      state.cashOutProcessed = action.payload;
    },
    setBetslipHistory: (state, action: PayloadAction<BetslipHistoryItems>) => {
      state.betslipHistory = action.payload;
    },
    setParams: (state, action: PayloadAction<BetslipHistoryPayload>) => {
      state.params = action.payload;
    },
    setCashedOutBet: (state, action: PayloadAction<string>) => {
      state.betslipHistory = state.betslipHistory.map((bet) => {
        if (bet.id === action.payload) {
          return {
            ...bet,
            status: 8,
            winAmount: bet.availableCashout,
            availableCashout: 0,
          };
        }

        return bet;
      });
    },
    setIsCashoutOpen: (state, action: PayloadAction<boolean>) => {
      state.isCashoutOpen = action.payload;
    },
    removeOpenBet: (state, action: PayloadAction<string>) => {
      state.openBets = state.openBets.filter(
        (bet) => bet.id !== action.payload,
      );
    },
  },
});

export const {
  setOpenBets,
  setCashOutBet,
  setIsCashOut,
  setCashOutAskType,
  setCashOutSuccess,
  setBetslipHistory,
  setParams,
  setCashOutProcessed,
  setCashedOutBet,
  removeOpenBet,
  setIsCashoutOpen,
} = openBetsSlice.actions;

export default openBetsSlice.reducer;
