import React from 'react';
import { CellProps } from 'react-table';

import { BetslipHistoryItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { StakeBalancesInfo } from '~components/atoms/StakeBalancesInfo/StakeBalancesInfo';
import { Text } from '~components/atoms/Typography';
import { useFormatAmountWithCurrency } from '~hooks';

export const StakeCell = ({ value, row }: CellProps<BetslipHistoryItem>) => {
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();
  const { bonusBalanceUsages, stake } = row.original;

  return (
    <Box flexRow gap={2} alignCenter>
      <Text level="12-20">{getCommaSeparatedAmount(value)}</Text>
      {!!bonusBalanceUsages?.length && (
        <StakeBalancesInfo
          balanceChanges={bonusBalanceUsages}
          fullStake={stake}
        />
      )}
    </Box>
  );
};
