import React, { MouseEvent } from 'react';

import { Box } from '~components/atoms/Box';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { CopyToClipboardIcon, GreenCheckIcon } from '~icons';
import { ellipsis } from '~utils/cssUtils';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';

import { JackpotBetHistoryCellProps } from '../types';

export const DetailsCell = ({
  row: { original },
}: JackpotBetHistoryCellProps) => {
  const { handleCopy, isCopied } = useCopyToClipboard();
  const { id, name, sportId, firstEventStartDate } = original;

  const handleCopyJackpotId = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    handleCopy(id);
  };

  return (
    <Box flexRow alignCenter gap={2} fullWidth>
      <Box css={{ color: '$textSecondaryOne' }}>
        {getSportIcon(sportId, { width: 16, height: 16 })}
      </Box>
      <Box flexCol css={{ ...ellipsis }}>
        <StyledTableText>{name}</StyledTableText>
        <StyledTableText>
          {formatDateTime(firstEventStartDate, TIME_FORMATS.DATE_TIME_12H)}
        </StyledTableText>
        <Box flexRow gap={1} alignCenter>
          <StyledTableText ellipsis>ID: {id}</StyledTableText>
          <Box
            css={{
              display: 'flex',
              cursor: 'pointer',
              color: '$textSecondaryOne',
              width: '$3',
            }}
            onClick={handleCopyJackpotId}
          >
            {isCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
