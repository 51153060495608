export enum CASH_OUT_ASK_TYPES {
  ALWAYS = '1',
  HIGHER = '2',
  ALL = '3',
}

type LocalizedFunction = (key: string, args?: object) => string;

export const getCashoutTypeOptions = (localized: LocalizedFunction) => {
  return [
    {
      label: localized('betslip.cashOutAsk.always'),
      value: CASH_OUT_ASK_TYPES.ALWAYS,
    },
    {
      label: localized('betslip.cashOutAsk.higher'),
      value: CASH_OUT_ASK_TYPES.HIGHER,
    },
    {
      label: localized('betslip.cashOutAsk.all'),
      value: CASH_OUT_ASK_TYPES.ALL,
    },
  ];
};
