import React from 'react';
import { COLOR_KEYS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { SkeletonCompact } from '~components/atoms/Skeletons/SkeletonCompact';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useUserBalance } from '~hooks/useUserBalance';
import { useAppSelector } from '~store';

interface BalanceInfoProps {
  balance?: number;
  freeBetBalance?: number;
  size?: 'small' | 'medium';
  textAlign?: 'left' | 'center' | 'right';
  color?: COLOR_KEYS;
}

const StyledBalanceText = styled(Text, {
  fontSize: '16px',
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  '@xs_sm': {
    fontSize: '12px',
    lineHeight: '16px',
  },
});

const getLevel = (size: 'small' | 'medium', isMobileOrTablet: boolean) => {
  if (size === 'medium') {
    return isMobileOrTablet ? '12-20' : '16-24';
  } else {
    return isMobileOrTablet ? '14-20' : '14-24';
  }
};

export const BalanceInfo = ({
  balance = 0,
  freeBetBalance = 0,
  size = 'medium',
  textAlign = 'left',
  color = 'textPrimaryOne',
}: BalanceInfoProps) => {
  const { isMobileOrTablet } = useMedia();
  const {
    isUserProfileLoaded,
    totalBalanceText,
    mainBalanceText,
    freeBetBalanceText,
  } = useUserBalance(balance, freeBetBalance);
  const { separateBonusBalance } = useAppSelector((state) => state.settings);
  const isSmallWindowScrolledOrDialogScrolled = false;

  const level = getLevel(size, isMobileOrTablet);

  const balanceValue =
    mainBalanceText && freeBetBalanceText
      ? totalBalanceText
      : mainBalanceText || freeBetBalanceText;

  // If separateBonusBalance is true, display balance and free bet balance separately without possibility to select balance
  return separateBonusBalance ? (
    <Box
      css={{
        display: 'flex',
        flexDirection: isSmallWindowScrolledOrDialogScrolled ? 'row' : 'column',
        gap:
          !isSmallWindowScrolledOrDialogScrolled && isUserProfileLoaded
            ? 0
            : '$1',
      }}
    >
      {isUserProfileLoaded ? (
        <StyledBalanceText color="accentPrimaryTwo">
          {mainBalanceText}
        </StyledBalanceText>
      ) : (
        <SkeletonCompact
          css={{
            width: '100%',
            minWidth: '100px',
            height: '14px',
            display: 'flex',
            alignItems: 'center',
            border: 'none',
          }}
        />
      )}
      {isSmallWindowScrolledOrDialogScrolled && (
        <StyledBalanceText>/</StyledBalanceText>
      )}
      {isUserProfileLoaded ? (
        <StyledBalanceText>{`${freeBetBalanceText}`}</StyledBalanceText>
      ) : (
        <SkeletonCompact
          css={{
            width: '100%',
            minWidth: '50px',
            height: '14px',
            display: 'flex',
            alignItems: 'center',
            border: 'none',
          }}
        />
      )}
    </Box>
  ) : (
    <Text
      level={level}
      textAlign={textAlign}
      ellipsis
      color={color}
      css={{
        minWidth: '80px',
        whiteSpace: 'nowrap',
        '@xs_sm': {
          minWidth: '40px',
        },
      }}
    >
      {isUserProfileLoaded ? (
        balanceValue
      ) : (
        <SkeletonCompact
          css={{
            width: '100%',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            border: 'none',
          }}
        />
      )}
    </Text>
  );
};
