import { SportEventItem } from '~api/sportEvent/types';

export const SELECTION_WIDTH = 64;

export const LAPTOP_SELECTION_WIDTH = 48;

export const SELECTIONS_GAP = 1;

export const MARKETS_GAP = 8;

export const SELECTIONS_COUNT = 3;

export const LIVE_SELECTION_HEIGHT = 76;

export const LIVE_LAPTOP_SELECTION_HEIGHT = 60;

export const PREMATCH_SELECTION_HEIGHT = 52;

export const MIN_MAIN_EVENT_DATA_WIDTH = 260;

export const MIN_LAPTOP_MAIN_EVENT_DATA_WIDTH = 290;

export const TOTAL_MARKET_NAME = 'Total';

export type HighlightsData = Record<string, string[]>;

export interface TopEventsDateGroupIds {
  date: string;
  matches: SportEventItem[];
}

export type TopPrematchDateGroupsIds = TopEventsDateGroupIds[];

export type PrematchUpcomingHighlightsData = Record<
  string,
  TopPrematchDateGroupsIds
>;

export type PrematchTopEventsData = PrematchUpcomingHighlightsData;

export enum MAIN_PAGE_SECTIONS {
  LIVE_HIGHLIGHTS = 'liveHighlights',
  HIGHLIGHTS = 'highlights',
  UPCOMING = 'upcoming',
}
