import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useRef,
} from 'react';

export interface SharedRefContextProps {
  ref: MutableRefObject<HTMLDivElement | null>;
  setRef: (ref: MutableRefObject<HTMLDivElement | null>) => void;
}

export const SharedRefContext = createContext<SharedRefContextProps | null>(
  null,
);

interface SharedRefProviderProps {
  children: ReactNode;
}

export const SharedRefProvider = ({ children }: SharedRefProviderProps) => {
  const sharedRef = useRef<HTMLDivElement | null>(null);

  const setRef = (ref: MutableRefObject<HTMLDivElement | null>) => {
    sharedRef.current = ref.current;
  };

  return (
    <SharedRefContext.Provider value={{ ref: sharedRef, setRef }}>
      {children}
    </SharedRefContext.Provider>
  );
};
