import {
  useLazyGetBetslipByCodeQuery,
  useLazyGetBetslipHistoryQuery,
  useLazyGetOpenBetsQuery,
} from '~api/betslip/betslipApi';

export const useLazyGetOpenBets = () => {
  const [query, { data, error, isLoading }] = useLazyGetOpenBetsQuery();

  return {
    lazyGetOpenBetsQuery: query,
    lazyGetOpenBetsData: data,
    lazyGetOpenBetsError: error,
    lazyGetOpenBetsIsLoading: isLoading,
  };
};

export const useLazyGetBetslipHistory = () => {
  const [query, { data, error, isLoading }] = useLazyGetBetslipHistoryQuery();

  return {
    lazyGetBetslipHistoryQuery: query,
    lazyGetBetslipHistoryData: data,
    lazyGetBetslipHistoryError: error,
    lazyGetBetslipHistoryIsLoading: isLoading,
  };
};

export const useLazyGetBetslipByCode = () => {
  const [query, { data, error, isLoading }] = useLazyGetBetslipByCodeQuery();

  return {
    lazyGetBetslipByCodeQuery: query,
    lazyGetBetslipByCodeData: data,
    lazyGetBetslipByCodeError: error,
    lazyGetBetslipByCodeIsLoading: isLoading,
  };
};
