import { useAppSelector } from '~store';
import { selectFavoriteSportMarkets } from '~store/slices/mainMarketsSlice';
import { Market, SportEvent } from '~types/events';

import { MarketContent } from './MarketContent';
import { AccordionContent } from './MarketsAccordion';
import { MarketsItemHeader } from './MarketsItemHeader';

interface MarketsListItemProps {
  marketsData: Market[];
  eventId: string;
  eventData: SportEvent;
}

export const MarketsListItem = ({
  marketsData,
  eventId,
  eventData,
}: MarketsListItemProps) => {
  const { sportId } = eventData;
  const favoriteSportMarkets = useAppSelector((state) =>
    selectFavoriteSportMarkets(state, sportId as number),
  );

  const favorite = favoriteSportMarkets.includes(
    marketsData[0]?.marketId as unknown as number,
  );

  if (!marketsData?.length) return null;

  const { name = 'Market', marketId = '' } = marketsData[0] || {};

  return (
    <>
      <MarketsItemHeader
        name={name}
        marketId={parseInt(marketId)}
        isFavoriteMarket={favorite}
      />
      <AccordionContent>
        <MarketContent
          marketsData={marketsData}
          eventId={eventId}
          eventData={eventData}
        />
      </AccordionContent>
    </>
  );
};
