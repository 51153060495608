import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BetAmount, BetslipItems } from '~api/betslip/types';
import { SportEventItems } from '~api/sportEvent/types';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  removeStakeBySelection,
  setBetslipProcessed,
  setShowBetslipNotification,
  setStakeType,
} from '~store/slices/betslipSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../constants';

export interface UseBetslipStakesReturn {
  isProcessingFailed: boolean;
  isProcessed: boolean;
  duplicatedMultipleBetStakes: string[];
  singleBetsAmountMap: Record<string, BetAmount>;
  stakeType: SPORT_BETSLIP_TYPE_OPTIONS;
  betErrorCode: number | null;
  betErrorEvent: string | null;
  betErrorMessage: string | null;
  events: BetslipItems;
  eventsData: SportEventItems;
  showBetslipNotification: boolean;
  localized: (key: string) => string;
  localizedError: (key: string, params?: object) => string;
  handleRemoveStake: (selectionId: string) => void;
  handleErrorMessageCloseClick: () => void;
  handleErrorMessageContinueClick: () => void;
}

export const useBetslipStakes = (
  quickBet: boolean = false,
): UseBetslipStakesReturn => {
  const navigate = useNavigate();
  const { localized, localizedError } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    events,
    singleBetsAmountMap,
    duplicatedMultipleBetStakes,
    stakeType,
    isProcessed,
    eventsData,
    isProcessingFailed,
    betErrorCode,
    betErrorEvent,
    betErrorMessage,
    showBetslipNotification,
  } = useAppSelector((state) => state.betslip);
  const { isOpen: isDialogOpen } = useAppSelector(
    (state) => state.globalDialog,
  );

  useEffect(() => {
    const showNotification =
      (!!events.length && isProcessingFailed) ||
      (!events.length && isProcessed);

    if (showNotification) {
      dispatch(setShowBetslipNotification(true));
    }
  }, [events, isProcessed, isProcessingFailed]);

  useEffect(() => {
    if ((events.length > 1 || isDialogOpen) && quickBet) {
      dispatch(setIsSwipeOpen(null));
    }
  }, [events, isDialogOpen, quickBet]);

  useEffect(() => {
    if (quickBet) {
      dispatch(setStakeType(SPORT_BETSLIP_TYPE_OPTIONS.SINGLE));
    }
  }, [quickBet]);

  const handleRemoveStake = useCallback((selectionId: string) => {
    if (quickBet) {
      dispatch(setIsSwipeOpen(null));
    }

    dispatch(removeStakeBySelection(selectionId));
  }, []);

  const handleErrorMessageCloseClick = () => {
    if (!isProcessingFailed) {
      navigate(ROUTE_TAB_NAMES.BASE);
    }

    dispatch(setBetslipProcessed(false));
    dispatch(setIsSwipeOpen(null));
    dispatch(setShowBetslipNotification(false));
    dispatch(closeDialog());
  };

  const handleErrorMessageContinueClick = () => {
    dispatch(setBetslipProcessed(false));
    dispatch(setShowBetslipNotification(false));
  };

  return {
    isProcessingFailed,
    isProcessed,
    duplicatedMultipleBetStakes,
    singleBetsAmountMap,
    stakeType,
    betErrorCode,
    betErrorEvent,
    betErrorMessage,
    events,
    eventsData,
    showBetslipNotification,
    handleErrorMessageCloseClick,
    handleErrorMessageContinueClick,
    localized,
    localizedError,
    handleRemoveStake,
  };
};
