import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import {
  CasinoResponse,
  GamesResponse,
  LaunchGamePayload,
  LaunchGameResponse,
  LikedGamesResponse,
  PopularResponse,
} from '~api/games/types';

export const gamesApi = createApi({
  reducerPath: 'gamesApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.GAMES }),
  endpoints: (builder) => ({
    getGames: builder.query<GamesResponse, number>({
      query: (version) => ({
        url: `/game`,
        method: 'GET',
        params: { frontVersion: version },
      }),
    }),
    getPopular: builder.query<PopularResponse, void>({
      query: () => ({
        url: `/Game/popular`,
        method: 'GET',
      }),
    }),
    getCasino: builder.query<CasinoResponse, number>({
      query: (version) => ({
        url: `/Casino`,
        method: 'GET',
        params: { frontVersion: version },
      }),
    }),
    getLikedGames: builder.query<LikedGamesResponse, void>({
      query: () => ({
        url: `/Game/liked_games`,
        method: 'GET',
      }),
    }),
    launchGameReal: builder.mutation<LaunchGameResponse, LaunchGamePayload>({
      query: (body) => ({
        url: '/game/launch/real',
        method: 'POST',
        body: body,
      }),
    }),
    launchGameDemo: builder.mutation<LaunchGameResponse, LaunchGamePayload>({
      query: (body) => ({
        url: '/game/launch/demo',
        method: 'POST',
        body: body,
      }),
    }),
    likeGame: builder.mutation<void, string>({
      query: (gameId) => ({
        url: `/Game/like/${gameId}`,
        method: 'POST',
      }),
    }),
    dislikeGame: builder.mutation<void, string>({
      query: (gameId) => ({
        url: `/Game/dislike/${gameId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLazyGetGamesQuery,
  useLaunchGameRealMutation,
  useLazyGetPopularQuery,
  useLaunchGameDemoMutation,
  useLazyGetCasinoQuery,
  useLikeGameMutation,
  useDislikeGameMutation,
  useLazyGetLikedGamesQuery,
} = gamesApi;
