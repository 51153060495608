import { Box } from '~components/atoms/Box';
import { EventNavigationLink } from '~components/atoms/EventNavigationLink';
import { Text } from '~components/atoms/Typography';
import { StoreSportEvent } from '~types/events';
import { ellipsis } from '~utils/cssUtils';
import { splitStringOnVs } from '~utils/stringUtils';

export interface MobilePrematchEventDataProps {
  eventData: StoreSportEvent | undefined;
}

export const MobilePrematchEventData = ({
  eventData,
}: MobilePrematchEventDataProps) => {
  const { name, id = '', sportId, countryId, leagueId } = eventData || {};
  const [homeTeamName, awayTeamName] = splitStringOnVs(name || '');

  return (
    <Box
      fullWidth
      flexCol
      justifyCenter
      css={{
        ...ellipsis,
      }}
    >
      <EventNavigationLink
        eventId={id}
        sportId={sportId}
        countryId={countryId}
        leagueId={leagueId}
      >
        <Box
          flexCol
          css={{
            flexShrink: 0,
            textDecoration: 'inherit',
            '&:hover > p': {
              color: '$accentPrimaryOne',
            },
          }}
        >
          <Text
            level="12-20"
            ellipsis
            css={{
              pr: '$1',
            }}
          >
            {homeTeamName}
          </Text>
          <Text
            level="12-20"
            ellipsis
            css={{
              pr: '$1',
            }}
          >
            {awayTeamName}
          </Text>
        </Box>
      </EventNavigationLink>
    </Box>
  );
};
