import { useLazyGetProfileBonus } from '~api/bonus/bonusQueries';
import { ProfileBonusPayload } from '~api/bonus/types';
import { useAppDispatch, useAppSelector } from '~store';
import { setBonuses, setIsBonusesLoading } from '~store/slices/bonusesSlice';

export const useBonuses = () => {
  const dispatch = useAppDispatch();
  const { bonuses } = useAppSelector((state) => state.bonuses);
  const { lazyGetProfileBonusQuery, lazyGetProfileBonusIsLoading } =
    useLazyGetProfileBonus();

  const loadBonuses = async (params: ProfileBonusPayload) => {
    try {
      dispatch(setIsBonusesLoading(true));
      const bonuses = await lazyGetProfileBonusQuery(params).unwrap();

      dispatch(setBonuses(bonuses));
    } catch (error) {
      console.error('Unable to load bonuses', error);
    } finally {
      dispatch(setIsBonusesLoading(false));
    }
  };

  return {
    bonuses,
    isLoading: lazyGetProfileBonusIsLoading,
    loadBonuses,
  };
};
