import { MainMarketSelection, SportMainMarkets } from '~api/market/types';

export const getMainMarketData = (
  sportId: number,
  mainMarkets: SportMainMarkets,
) => {
  const relevantSport = mainMarkets.find((sport) => sport.id === sportId);
  const marketOrderMap: Map<number, number> = new Map();
  const marketNameMap: Map<number, string> = new Map();
  const selectionNameMap: Map<string, string> = new Map();
  const selectionOrderMap: Map<string, number> = new Map();
  const allSelectionsFromMainMarkets: Map<string, MainMarketSelection> =
    new Map();

  relevantSport?.markets.forEach((market, index) => {
    marketOrderMap.set(market.id, index);
    marketNameMap.set(market.id, market.name);

    market.selections.forEach((selection, sIndex) => {
      const key = `${sportId}-${market.id}-${selection.id}`;

      selectionNameMap.set(selection.id, selection.name);
      selectionOrderMap.set(key, sIndex);
      allSelectionsFromMainMarkets.set(key, selection);
    });
  });

  const marketIdsFromMainMarkets =
    relevantSport?.markets.map((market) => market.id) || [];

  return {
    relevantSport,
    marketOrderMap,
    marketNameMap,
    selectionNameMap,
    selectionOrderMap,
    allSelectionsFromMainMarkets,
    marketIdsFromMainMarkets,
  };
};
