import { useAppSelector } from '~store';
import { getMobileSettings } from '~store/slices/selectors/settings';

export const usePhoneNumber = () => {
  const mobileSettings = useAppSelector(getMobileSettings);
  const { mobileCode } = mobileSettings;

  const getPhoneNumberWithCode = (phoneNumber: string) => {
    return `${mobileCode}${phoneNumber}`;
  };

  const getPhoneNumberWithoutCode = (phoneNumber: string) => {
    if (phoneNumber.startsWith('+')) {
      const codeLength = `${mobileCode}`.length;

      return phoneNumber.slice(codeLength);
    }

    return phoneNumber;
  };

  const formatPhoneNumberPayload = (phoneNumber: string) => {
    const phoneWithoutCode = getPhoneNumberWithoutCode(phoneNumber);
    const startsWithZero = phoneWithoutCode.startsWith('0');
    const formattedPhoneNumber = startsWithZero
      ? phoneWithoutCode.slice(1)
      : phoneWithoutCode;

    return getPhoneNumberWithCode(formattedPhoneNumber);
  };

  return {
    getPhoneNumberWithCode,
    getPhoneNumberWithoutCode,
    formatPhoneNumberPayload,
  };
};
