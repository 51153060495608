import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

import {
  useAuthorizedDepositFlutterwaveMutation,
  useBankDepositFlutterwaveMutation,
  useFlutterwaveCardChargeMutation,
  useFlutterwaveCardChargeWithAuthMutation,
  useValidateCartChargeMutation,
} from './flutterwaveApi';

export const useFlutterwaveCardCharge = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useFlutterwaveCardChargeMutation();

  return {
    flutterwaveCardChargeMutation: mutation,
    flutterwaveCardChargeData: data,
    flutterwaveCardChargeError: error,
    flutterwaveCardChargeIsError: isError,
    flutterwaveCardChargeErrorCode: getErrorCode(error, isError),
    flutterwaveCardChargeErrorMessage: getErrorMessage(error),
    flutterwaveCardChargeIsSuccess: isSuccess,
    flutterwaveCardChargeIsLoading: isLoading,
  };
};

export const useFlutterwaveCardChargeWithAuth = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useFlutterwaveCardChargeWithAuthMutation();

  return {
    flutterwaveCardChargeWithAuthMutation: mutation,
    flutterwaveCardChargeWithAuthData: data,
    flutterwaveCardChargeWithAuthError: error,
    flutterwaveCardChargeWithAuthIsError: isError,
    flutterwaveCardChargeWithAuthErrorCode: getErrorCode(error, isError),
    flutterwaveCardChargeWithAuthErrorMessage: getErrorMessage(error),
    flutterwaveCardChargeWithAuthIsSuccess: isSuccess,
    flutterwaveCardChargeWithAuthIsLoading: isLoading,
  };
};

export const useValidateCartCharge = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useValidateCartChargeMutation();

  return {
    validateCartChargeMutation: mutation,
    validateCartChargeData: data,
    validateCartChargeError: error,
    validateCartChargeIsError: isError,
    validateCartChargeIsSuccess: isSuccess,
    validateCartChargeErrorCode: getErrorCode(error, isError),
    validateCartChargeErrorMessage: getErrorMessage(error),
    validateCartChargeIsLoading: isLoading,
  };
};

export const useAuthorizedDepositFlutterwave = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useAuthorizedDepositFlutterwaveMutation();

  return {
    authorizedDepositFlutterwaveMutation: mutation,
    authorizedDepositFlutterwaveData: data,
    authorizedDepositFlutterwaveError: error,
    authorizedDepositFlutterwaveIsError: isError,
    authorizedDepositFlutterwaveIsSuccess: isSuccess,
    authorizedDepositFlutterwaveErrorCode: getErrorCode(error, isError),
    authorizedDepositFlutterwaveErrorMessage: getErrorMessage(error),
    authorizedDepositFlutterwaveIsLoading: isLoading,
  };
};

export const useBankDepositFlutterwave = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useBankDepositFlutterwaveMutation();

  return {
    bankDepositFlutterwaveMutation: mutation,
    bankDepositFlutterwaveData: data,
    bankDepositFlutterwaveError: error,
    bankDepositFlutterwaveIsError: isError,
    bankDepositFlutterwaveIsSuccess: isSuccess,
    bankDepositFlutterwaveErrorCode: getErrorCode(error, isError),
    bankDepositFlutterwaveErrorMessage: getErrorMessage(error),
    bankDepositFlutterwaveIsLoading: isLoading,
  };
};
