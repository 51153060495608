import { useEffect } from 'react';

import { useLazyGetFavoriteEventPrematch } from '~api/sportEvent/sportEventQueries';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import { useAppDispatch, useAppSelector } from '~store';
import { setReloadPrematch } from '~store/slices/prematchMenuSlice';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';

import { useSportWithCurrentLeague } from './useSportsWithCurrentLeague';

export const usePrematchMenu = () => {
  const { activeSportTabGroup: activeTabGroup = SPORT_TAB_GROUPS.ALL } =
    useAppSelector((state) => state.mobileState);
  const dispatch = useAppDispatch();
  const { lazyGetFavoriteEventPrematchQuery } =
    useLazyGetFavoriteEventPrematch();

  const { sports } = useAppSelector((state) => state.prematchMenu);
  const { favoritePrematchEventsLoaded } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const { isLoading } = useSportWithCurrentLeague();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  useEffect(() => {
    const loadFavoriteEvents = async () => {
      const favoritePrematchEvents =
        await lazyGetFavoriteEventPrematchQuery().unwrap();

      dispatch(setFavoritePrematchEvents(favoritePrematchEvents));
    };

    if (isUserLoggedIn && !favoritePrematchEventsLoaded) {
      loadFavoriteEvents();
    }
  }, [isUserLoggedIn, favoritePrematchEventsLoaded]);

  useEffect(() => {
    if (activeTabGroup === SPORT_TAB_GROUPS.ALL)
      dispatch(setReloadPrematch(true));
  }, [activeTabGroup]);

  return {
    sports,
    isLoading,
  };
};
