import * as ProgressUI from '@radix-ui/react-progress';
import { styled } from 'stitches.config';

interface ProgressBarProps {
  progress?: number;
}

const StyledProgressRoot = styled(ProgressUI.Root, {
  flexShrink: 1,
  position: 'relative',
  overflow: 'hidden',
  background: '$yellowSecondary',
  borderRadius: '$16',
  width: '174px',
  height: '$2',
  transform: 'translateZ(0)',
});

const StyledProgressIndicator = styled(ProgressUI.Indicator, {
  backgroundColor: '$yellowPrimary',
  width: '100%',
  height: '100%',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
  borderRadius: '$16',
});

export const ProgressBar = ({ progress = 0 }: ProgressBarProps) => {
  if (progress > 100 || progress < 0) {
    throw new Error('Progress value should be between 0 and 100');
  }

  return (
    <StyledProgressRoot value={progress}>
      <StyledProgressIndicator
        css={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </StyledProgressRoot>
  );
};
