import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { LabelWithOppositeText } from '~components/atoms/LabelWithOppositeText';
import { Text } from '~components/atoms/Typography';
import { CompletedContent } from '~components/molecules/Authentication/components/VerifyPersonalDetails/CompletedContent';
import {
  fieldsArray,
  VERIFY_PERSONAL_DETAILS_FIELDS,
} from '~components/molecules/Authentication/components/VerifyPersonalDetails/constants';
import { SIGNUP_FORM_FIELDS } from '~components/molecules/Authentication/types';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { AlertIcon2 } from '~icons';

import { useVerifyPersonalDetails } from './useVerifyPersonalDetails';

export const VerifyPersonalDetailsContent = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const {
    profile,
    formState,
    isFormValid,
    isLoading,
    isProcessed,
    isSuccess,
    errorMessage,
    handleContinue,
    handleButtonClick,
    handleFormValueChange,
  } = useVerifyPersonalDetails();

  if (isProcessed) {
    return (
      <CompletedContent
        isSuccess={isSuccess}
        onContinue={handleContinue}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <Box
      fullWidth
      css={{
        borderRadius: '$8',
        border: '1px solid $primaryTwo',
        maxWidth: !isMobileOrTablet ? '392px' : '100%',
      }}
    >
      <Box
        fullWidth
        flexCol
        justifyCenter
        alignCenter
        gap={2}
        css={{
          p: '$4 60px',
          borderBottom: '1px solid $primaryTwo',
        }}
      >
        <Box
          css={{ width: '26px', height: '26px' }}
          flexCol
          justifyCenter
          alignCenter
        >
          <AlertIcon2 />
        </Box>
        <Text level="14-20" textTransform="uppercase" textAlign={'center'}>
          {localized('verifyPersonalDetails.title')}
        </Text>
      </Box>
      <Box
        flexCol
        gap={2}
        css={{ p: '$2 $3', borderBottom: '1px solid $primaryTwo' }}
      >
        {fieldsArray.map((field) => (
          <Box fullWidth fullHeight flexCol>
            <LabelWithOppositeText
              isRequired={field !== VERIFY_PERSONAL_DETAILS_FIELDS.MIDDLE_NAME}
            >
              {localized(`verifyPersonalDetails.${field}.label`)}
            </LabelWithOppositeText>
            <Input
              disabled={!!(profile && profile[field])}
              id={SIGNUP_FORM_FIELDS.USERNAME_FIELD}
              name={SIGNUP_FORM_FIELDS.USERNAME_FIELD}
              value={formState[field]}
              placeholder={localized(
                `verifyPersonalDetails.${field}.placeholder`,
              )}
              onChange={(e) => handleFormValueChange(field, e.target.value)}
            />
          </Box>
        ))}
      </Box>
      <Box
        fullWidth
        flexCol
        justifyCenter
        alignCenter
        gap={2}
        css={{
          p: '$4',
        }}
      >
        <Button
          fullWidth
          isLoading={isLoading}
          disabled={!isFormValid}
          onClick={handleButtonClick}
          css={{
            p: '$2',
            marginTop: '$3',
            '@xs_sm': {
              height: '$7',
              mt: '$1',
            },
          }}
        >
          <Text level="16-24" fontWeight="bold" textAlign="center">
            {localized('verifyPersonalDetails.continue')}
          </Text>
        </Button>
        <Box
          fullWidth
          flexCol
          justifyCenter
          alignCenter
          css={{
            borderRadius: '$8',
            border: '1px solid $primaryTwo',
            p: '$2 $3',
          }}
        >
          <Text
            level="12-14"
            css={{
              position: 'relative',
              '&:before': {
                content: '*',
                left: '-10px',
                color: '$redPrimary',
                position: 'absolute',
              },
            }}
          >
            {localized('verifyPersonalDetails.description')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
