import { useEffect, useState } from 'react';

import { SportMainMarkets } from '~api/market/types';
import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { Market, SportEventItem } from '~api/sportEvent/types';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';
import { SportEventMainData } from '~types/events';
import { deepClone } from '~utils/deepClone';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import { getMainMarketData } from '../../helpers/getMainMarketsData';
import { getMarketDataFormatted } from '../../helpers/marketUtils';
import { useLeagueEventConnection } from '../../hooks/useLeagueEventConnection';

interface LeagueEventHookParams {
  eventData: SportEventItem;
  mainMarkets?: SportMainMarkets;
  sportId?: number;
  maxMarketsCount?: number;
}

export const useLeagueEvent = ({
  eventData,
  mainMarkets,
  sportId,
  maxMarketsCount,
}: LeagueEventHookParams) => {
  const dispatch = useAppDispatch();

  const eventId = eventData?.id;
  const markets = eventData?.markets;

  const { favoritePrematchEvents } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const { markets: mainMarketsData = [] } =
    mainMarkets?.find(({ id }) => id === sportId) || {};

  let defaultMarketsData: Market[] = mainMarketsData.length ? markets : [];

  if (maxMarketsCount && defaultMarketsData.length > maxMarketsCount) {
    defaultMarketsData = defaultMarketsData.slice(0, maxMarketsCount);
  }

  const [marketData, setMarketData] = useState<Market[]>(
    defaultMarketsData as Market[],
  );
  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();

  useLeagueEventConnection({ eventId });

  const handleFavoriteClick = async (isEventFavorite: boolean) => {
    if (isEventFavorite) {
      await deleteFavoriteEventMutation({
        eventId,
      });

      dispatch(
        setFavoritePrematchEvents(
          favoritePrematchEvents.filter(
            (favoriteEvent) => favoriteEvent.id !== eventData?.id,
          ),
        ),
      );
    } else {
      await addFavoriteEventMutation({
        eventId,
      });
      if (eventData) {
        dispatch(
          setFavoritePrematchEvents([
            ...favoritePrematchEvents,
            eventData as SportEventMainData,
          ]),
        );
      }
    }
  };

  useEffect(() => {
    if (!sportId || !mainMarkets) return;

    const {
      relevantSport,
      marketNameMap,
      selectionNameMap,
      selectionOrderMap,
      allSelectionsFromMainMarkets,
    } = getMainMarketData(sportId, mainMarkets);

    if (!relevantSport) {
      return;
    }

    const mapMarkets = markets
      .filter((market) => Boolean(market))
      .map((marketItem) => {
        const market = deepClone(marketItem);

        const formattedMarket = getMarketDataFormatted({
          marketOriginal: marketItem!,
          marketNameMap,
          allSelectionsFromMainMarkets,
          sportId,
          relevantSport,
          selectionOrderMap,
          selectionNameMap,
        });

        market.selections = formattedMarket.selections;
        market.name = formattedMarket.name;
        market.status = formattedMarket.status;
        market.line = formattedMarket.line;

        return market;
      });

    if (mapMarkets && !areTwoObjectsEqual(marketData, mapMarkets)) {
      setMarketData(mapMarkets);
    }
  }, [markets, marketData]);

  return {
    marketData,
    handleFavoriteClick,
  };
};
