import { useEffect, useState } from 'react';

import { useNetworkStatus } from '~hooks/useNetworkStatus';

export const useReconnectedRequest = (handler: () => void) => {
  const { isOnline, isReconnected, isDisconnected } = useNetworkStatus();
  const [needRefetch, setNeedRefetch] = useState(false);

  useEffect(() => {
    if (isDisconnected) setNeedRefetch(true);
  }, [isDisconnected]);

  useEffect(() => {
    if (isReconnected && isOnline && needRefetch) {
      handler();
      setNeedRefetch(false);
    }
  }, [isReconnected, isOnline, needRefetch]);
};
