import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';

export const StyledUserTabContentWrapper = styled(Box, {
  borderRadius: '$8',
  border: '1px solid $secondaryTwo',
  p: '$2 $3',
});

export const StyledUserTabContentTitle = styled(Text, {
  textTransform: 'uppercase',
  defaultVariants: {
    color: 'textSecondaryOne',
    fontWeight: 'medium',
    level: 'sm-4',
  },
});

export const StyledUserTabContentSeparator = styled(Separator, {
  backgroundColor: '$secondaryTwo',
  defaultVariants: {
    verticalSpace: 2,
    shrinkOut: 3,
  },
});
