import { InputProps } from './types';

export const getFields = (size: number) =>
  Array.from({ length: size }, (_, i) => ({ id: i, value: '' }));

export const getCode = (inputs: InputProps[]) => {
  return inputs.map((input) => input.value).join('');
};

export const padArray = (array: string[], size: number) => {
  const paddedArray = [...array];

  while (paddedArray.length < size) {
    paddedArray.push('');
  }

  return paddedArray;
};
