import {
  useGetAddressMutation,
  useValidateAddressMutation,
  useWithdrawalMutation,
} from '~api/cryptoPayments/cryptoPaymentsApi';

export const useGetAddress = () => {
  const [mutate, { data, error, isLoading }] = useGetAddressMutation();

  return {
    getAddressMutation: mutate,
    getAddressData: data,
    getAddressError: error,
    getAddressIsLoading: isLoading,
  };
};

export const useWithdrawal = () => {
  const [mutate, { data, error, isLoading }] = useWithdrawalMutation();

  return {
    withdrawalMutation: mutate,
    withdrawalData: data,
    withdrawalError: error,
    withdrawalIsLoading: isLoading,
  };
};

export const useValidateAddress = () => {
  const [mutate, { data, error, isLoading }] = useValidateAddressMutation();

  return {
    validateAddressMutation: mutate,
    validateAddressData: data,
    validateAddressError: error,
    validateAddressIsLoading: isLoading,
  };
};
