import { ChangeEvent, useState } from 'react';

import { BetslipHistoryPayload } from '~api/betslip/types';
import { RangeValue } from '~components/atoms/DateRangePicker';
import { getLocalizedBetslipStatusOptions } from '~components/molecules/BetslipHistorySection/constants';
import { BET_HISTORY_EVENT_CODE_LENGTH } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import {
  formatDateToEndOfDayISOString,
  formatDateToStartOfDayISOString,
} from '~utils/formatDateTime';
import { conditionalAdd } from '~utils/objectHelpers';
import { keepDigitsAndLetters } from '~utils/sanitizers';

export interface UseBetslipHistoryFiltersReturn {
  code: string;
  isValidationFailed: boolean;
  activeParamsNumber: number;
  selectedDates: RangeValue | null;
  statusesOptions: { label: string; value: string }[];
  statusValue: string;
  minimumAge: number;
  handleReset: () => void;
  handleSetCode: (e: ChangeEvent<HTMLInputElement>) => void;
  handleUpdateHistory: () => void;
  localized: (key: string) => string;
  setSelectedDates: React.Dispatch<React.SetStateAction<RangeValue | null>>;
  setStatusValue: React.Dispatch<React.SetStateAction<string>>;
}

interface UseBetslipHistoryFiltersProps {
  loadBetslipHistory: (
    params: BetslipHistoryPayload | undefined,
    loadMore?: boolean,
  ) => void;
}

export const useBetslipHistoryFilters = ({
  loadBetslipHistory,
}: UseBetslipHistoryFiltersProps): UseBetslipHistoryFiltersReturn => {
  const { localized } = useTranslation();
  const { minimumAge } = useAppSelector((state) => state.settings);

  const statusesOptionsMemo = getLocalizedBetslipStatusOptions(localized);

  const [statusValue, setStatusValue] = useState<string>(
    statusesOptionsMemo[0]!.value,
  );
  const [selectedDates, setSelectedDates] = useState<RangeValue | null>(null);
  const dateFrom = selectedDates && selectedDates[0];
  const dateTo = selectedDates && selectedDates[1];
  const [code, setCode] = useState('');
  const [activeParamsNumber, setActiveParamsNumber] = useState(0);
  const isValidationFailed =
    !!code && code.length < BET_HISTORY_EVENT_CODE_LENGTH;

  const handleReset = () => {
    setStatusValue(statusesOptionsMemo[0]!.value);
    setCode('');
    setSelectedDates(null);
    loadBetslipHistory({});
    setActiveParamsNumber(0);
  };

  const handleUpdateHistory = () => {
    const params: BetslipHistoryPayload = {};
    const parsedStatus = parseInt(statusValue);

    conditionalAdd(
      params,
      'Status',
      parsedStatus > 0 ? parsedStatus : undefined,
    );
    conditionalAdd(params, 'Code', code ? code : undefined);

    if (selectedDates && dateFrom && dateTo) {
      const dateFromISO = formatDateToStartOfDayISOString(dateFrom);
      const dateToISO = formatDateToEndOfDayISOString(dateTo);

      conditionalAdd(params, 'DateFrom', dateFromISO);
      conditionalAdd(params, 'DateTo', dateToISO);
    }

    setActiveParamsNumber(
      // DateTo is not included in the number of active params because it is second part of one range params pair
      Object.keys(params).length - ('DateTo' in params ? 1 : 0),
    );

    loadBetslipHistory(params);
  };

  const handleSetCode = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim().toUpperCase();
    const sanitizedValue = keepDigitsAndLetters(value);

    if (sanitizedValue.length <= BET_HISTORY_EVENT_CODE_LENGTH) {
      setCode(sanitizedValue);
    }
  };

  return {
    code,
    isValidationFailed,
    activeParamsNumber,
    selectedDates,
    statusesOptions: statusesOptionsMemo,
    statusValue,
    minimumAge,
    handleReset,
    handleSetCode,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  };
};
