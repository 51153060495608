import { CSSProperties } from 'react';

type SideMap = Record<string, 'Top' | 'Bottom' | 'Left' | 'Right'>;
type SpaceProps = Record<
  string,
  keyof Pick<CSSProperties, 'margin' | 'padding'>
>;
type SpaceUtilities = Record<string, (value: string) => CSSProperties>;

const generateSpaceUtil = (): SpaceUtilities => {
  const sidesMap: SideMap = {
    t: 'Top',
    b: 'Bottom',
    l: 'Left',
    r: 'Right',
  };

  const spaceProps: SpaceProps = {
    m: 'margin',
    p: 'padding',
  };

  const properties: string[] = ['m', 'p'];
  const sides: string[] = ['t', 'b', 'l', 'r', 'x', 'y', ''];

  const stitchesUtils: SpaceUtilities = {};

  properties.forEach((property) => {
    sides.forEach((side) => {
      stitchesUtils[`${property}${side ? side : ''}`] = (value: string) => {
        const cssProps: CSSProperties = {};

        if (side === 'x') {
          cssProps[`${spaceProps[property]!}Left`] = value;
          cssProps[`${spaceProps[property]!}Right`] = value;
        } else if (side === 'y') {
          cssProps[`${spaceProps[property]!}Top`] = value;
          cssProps[`${spaceProps[property]!}Bottom`] = value;
        } else if (side) {
          cssProps[`${spaceProps[property]!}${sidesMap[side]!}`] = value;
        } else {
          cssProps[`${spaceProps[property]!}`] = value;
        }

        return cssProps;
      };
    });
  });

  return stitchesUtils;
};

export default generateSpaceUtil;
