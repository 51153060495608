import { EMAIL_VALIDATION_REGEX } from './regex';

const EMAIL_CHARACTER_LIMIT = 256;

export const validateEmail = (email: string) => {
  return (
    String(email).toLowerCase().match(EMAIL_VALIDATION_REGEX) &&
    email.length <= EMAIL_CHARACTER_LIMIT
  );
};
