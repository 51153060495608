import { MENU_ICON_TYPES } from '~components/organisms/HeaderComponent/Header/useHeaderMenu';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { SPORT_MENUS } from '~types/sportMenus';

export const getIsMenuItemActive = (
  item: MENU_ICON_TYPES,
  url: string,
  menuQuery: string = '',
): boolean => {
  switch (item) {
    case MENU_ICON_TYPES.Inplay:
      return menuQuery === 'live';

    case MENU_ICON_TYPES.Sports: {
      const isBasePage = window.location.pathname === ROUTE_TAB_NAMES.BASE;

      return isBasePage && menuQuery !== SPORT_MENUS.LIVE;
    }

    // We perform this check not to highlight casino, when Aviator is selected (/casino/1_Aviator)
    case MENU_ICON_TYPES.Casino: {
      return window.location.pathname === `/${ROUTE_TAB_NAMES.GAMES}`;
    }

    case MENU_ICON_TYPES.Aviator: {
      const aviatorGamePath = url.split('/').pop();

      return window.location.pathname.includes(aviatorGamePath || '');
    }

    default: {
      return window.location.href.includes(url);
    }
  }
};
