import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

import {
  useDeleteMarketFromFavoritesMutation,
  useLazyGetFavoriteMarketsQuery,
  useSetMarketAsFavoriteMutation,
} from './marketApi';

export const useLazyGetFavoriteMarkets = () => {
  const [query, { data, error, isLoading }] = useLazyGetFavoriteMarketsQuery();

  return {
    lazyGetFavoriteMarketsQuery: query,
    lazyGetFavoriteMarketsData: data,
    lazyGetFavoriteMarketsError: error,
    lazyGetFavoriteMarketsIsLoading: isLoading,
  };
};

export const useSetMarketAsFavorite = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSetMarketAsFavoriteMutation();

  return {
    setMarketAsFavoriteMutation: mutation,
    setMarketAsFavoriteData: data,
    setMarketAsFavoriteError: error,
    setMarketAsFavoriteIsError: isError,
    setMarketAsFavoriteErrorCode: getErrorCode(error, isError),
    setMarketAsFavoriteErrorMessage: getErrorMessage(error),
    setMarketAsFavoriteIsSuccess: isSuccess,
    setMarketAsFavoriteIsLoading: isLoading,
  };
};

export const useDeleteMarketFromFavorites = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useDeleteMarketFromFavoritesMutation();

  return {
    deleteMarketFromFavoriteMutation: mutation,
    deleteMarketFromFavoriteMutationData: data,
    deleteMarketFromFavoriteMutationError: error,
    deleteMarketFromFavoriteMutationIsError: isError,
    deleteMarketFromFavoriteMutationErrorCode: getErrorCode(error, isError),
    deleteMarketFromFavoriteMutationErrorMessage: getErrorMessage(error),
    deleteMarketFromFavoriteMutationIsSuccess: isSuccess,
    deleteMarketFromFavoriteMutationIsLoading: isLoading,
  };
};
