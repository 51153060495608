import {
  BasketballExtraData,
  IceHockeyExtraData,
  SnookerExtraData,
  SoccerExtraData,
  TennisExtraData,
  VolleyballExtraData,
} from '~types/sportExtraData';
import { shouldShowMatchDetail } from '~utils/matchStatusHelpers';

export enum SERVER {
  HOME = 1,
  AWAY = 2,
}

const getNthMinute = (time: string): number => {
  const [minutesStr, secondsStr] = time.split(':');
  const minutes = parseInt(minutesStr!);
  const seconds = parseInt(secondsStr!);

  if (seconds > 0) {
    return minutes + 1;
  }

  return minutes;
};

export const getSportDetails = (
  extraData:
    | BasketballExtraData
    | IceHockeyExtraData
    | SnookerExtraData
    | VolleyballExtraData,
  showTime: boolean = true,
) => {
  if (!extraData || Object.keys(extraData).length === 0) {
    return {
      shortDetails: '-',
      details: '-',
      periodScores: [],
      time: '-',
    };
  }

  const { PeriodScores, HomeScore, AwayScore, EventClock } = extraData || {};

  const { RemainingTimeInPeriod } = EventClock || {};

  const remainingTime = RemainingTimeInPeriod || '';

  if (!PeriodScores || PeriodScores.length === 0) {
    return null;
  }

  const periodScores = PeriodScores.sort((a, b) => a.Number - b.Number).reduce(
    (acc, period) => {
      acc.push(`(${period.HomeScore}:${period.AwayScore})`);

      return acc;
    },
    [] as string[],
  );

  const scoreString = `${HomeScore} : ${AwayScore}`;
  const periodScoresString = periodScores.join(', ');

  return {
    shortDetails: periodScoresString,
    details: `${scoreString}, ${periodScoresString} ${
      showTime ? remainingTime : ''
    }`.trim(),
    periodScores: PeriodScores.sort((a, b) => a.Number - b.Number).map(
      (score) => ({
        homeScore: score.HomeScore,
        awayScore: score.AwayScore,
        matchStatusId: score.Id,
        setNumber: score.Number,
      }),
    ),
    time: remainingTime,
  };
};

export const getBasketballDetails = (
  extraData: BasketballExtraData,
  showTime: boolean,
) => {
  return getSportDetails(extraData, showTime);
};

export const getIceHockeyDetails = (
  extraData: IceHockeyExtraData,
  showTime: boolean,
) => {
  return getSportDetails(extraData, showTime);
};

export const getSnookerDetails = (
  extraData: SnookerExtraData,
  showTime: boolean,
) => {
  return getSportDetails(extraData, showTime);
};

export const getVolleyballDetails = (
  extraData: VolleyballExtraData,
  showTime: boolean,
) => {
  const { Properties } = extraData;
  const { CurrentServer } = Properties || {};

  return {
    ...getSportDetails(extraData, showTime),
    server: CurrentServer,
  };
};

interface PeriodScore {
  HomeScore: number;
  AwayScore: number;
  Type: number;
  Id: number;
  Number: number;
}

interface MatchData {
  HomeScore: number;
  AwayScore: number;
  PeriodScores: PeriodScore[];
  Status: number;
}

function parseMatchDataToString(data: MatchData): string {
  let ftScore = '';
  let htScore = '';

  let extraTimeScore = '';
  let penaltyScore = '';

  if (!data.PeriodScores) return '';

  // Check if the match has completed (Full Time)
  if (data.Status === 3) {
    // Find the last period score
    const firstPeriod = data.PeriodScores[0];
    const secondPeriod = data.PeriodScores[1];

    const resHomeScore =
      (firstPeriod?.HomeScore || 0) + (secondPeriod?.HomeScore || 0);
    const resAwayScore =
      (firstPeriod?.AwayScore || 0) + (secondPeriod?.AwayScore || 0);

    ftScore = `FT: ${resHomeScore}-${resAwayScore}`;
  }

  // Find half-time score (assumed as the first period)
  const halfTimePeriod = data.PeriodScores.find(
    (period) => period.Number === 1,
  );

  if (halfTimePeriod) {
    htScore = `HT: ${halfTimePeriod.HomeScore}-${halfTimePeriod.AwayScore}`;
  }

  // Find half-time score (assumed as the first period)
  const extraTimePeriod = data.PeriodScores.find(
    (period) => period.Number === 3,
  );

  if (extraTimePeriod) {
    extraTimeScore = `ET: ${extraTimePeriod.HomeScore}-${extraTimePeriod.AwayScore}`;
  }

  const penaltyPeriod = data.PeriodScores.find((period) => period.Number === 4);

  if (penaltyPeriod) {
    penaltyScore = `AP: ${penaltyPeriod.HomeScore}-${penaltyPeriod.AwayScore}`;
  }

  return [ftScore, htScore, extraTimeScore, penaltyScore]
    .filter((score) => score)
    .join(', ');
}

export const getSoccerDetails = (
  extraData: SoccerExtraData,
  showTime: boolean,
) => {
  if (!extraData || Object.keys(extraData).length === 0) {
    return {
      shortDetails: '-',
      details: '-',
      time: '-',
    };
  }

  const { EventTime = '00:00', StoppageTime } = extraData.EventClock || {};
  const minute = getNthMinute(EventTime);
  const extraMinute = StoppageTime ? getNthMinute(StoppageTime) : null;
  const showMatchDetails = shouldShowMatchDetail(extraData);

  return {
    shortDetails: parseMatchDataToString(extraData as unknown as MatchData),
    details:
      parseMatchDataToString(extraData as unknown as MatchData) +
      ' ' +
      (showMatchDetails && showTime
        ? `${minute}${extraMinute ? `+${extraMinute}` : ''}\``
        : ''),
    time: EventTime,
  };
};

export const getTennisDetails = (extraData: TennisExtraData) => {
  const { PeriodScores, HomeScore = 0, AwayScore = 0, Properties } = extraData;

  const {
    CurrentServer,
    HomeGameScore = 0,
    AwayGameScore = 0,
  } = Properties || {};

  if (!PeriodScores || PeriodScores.length === 0) {
    return null;
  }

  const homeScore = HomeGameScore === 50 ? 'A' : HomeGameScore;
  const awayScore = AwayGameScore === 50 ? 'A' : AwayGameScore;
  const homeServer = CurrentServer === SERVER.HOME ? '*' : '';
  const awayServer = CurrentServer === SERVER.AWAY ? '*' : '';

  const periodScores = PeriodScores.sort((a, b) => a.Number - b.Number).reduce(
    (acc, period) => {
      acc.push(`(${period.HomeScore}:${period.AwayScore})`);

      return acc;
    },
    [] as string[],
  );

  const scoreString = `${HomeScore} : ${AwayScore}`;
  const gameScoreString = [
    homeScore,
    homeServer,
    ':',
    awayScore,
    awayServer,
  ].join('');

  const periodScoresString = periodScores.join(', ');

  return {
    shortDetails: periodScoresString,
    details: `${scoreString}, ${periodScoresString} ${gameScoreString}`,
    homeGameScore: homeScore,
    awayGameScore: awayScore,
    server: CurrentServer,
    periodScores: PeriodScores.sort((a, b) => a.Number - b.Number).map(
      (score) => ({
        homeScore: score.HomeScore,
        awayScore: score.AwayScore,
        setNumber: score.Number,
        id: score.Id,
      }),
    ),
  };
};

export const getParsedExtraData = (extraData?: string) => {
  return extraData ? JSON.parse(extraData) : null;
};
