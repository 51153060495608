import {
  useRelworxDepositMutation,
  useRelworxWithdrawMutation,
} from './relworxApi';

export const useRelworxDeposit = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useRelworxDepositMutation();

  return {
    relworxDepositMutation: mutation,
    relworxDepositData: data,
    relworxDepositError: error,
    relworxDepositIsError: isError,
    relworxDepositIsSuccess: isSuccess,
    relworxDepositIsLoading: isLoading,
  };
};

export const useRelworxWithdraw = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useRelworxWithdrawMutation();

  return {
    relworxWithdrawMutation: mutation,
    relworxWithdrawData: data,
    relworxWithdrawError: error,
    relworxWithdrawIsError: isError,
    relworxWithdrawIsSuccess: isSuccess,
    relworxWithdrawIsLoading: isLoading,
  };
};
