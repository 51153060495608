import { MouseEvent } from 'react';

import { useCashOutRequest } from '~api/betslip/betslipMutations';
import { CashOutPayload } from '~api/betslip/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  removeOpenBet,
  setCashedOutBet,
  setCashOutProcessed,
  setCashOutSuccess,
  setIsCashOut,
  setIsCashoutOpen,
} from '~store/slices/openBetSlice';

export const useCashOut = (
  noCloseIcon: boolean = false,
  onCashoutProcessed?: () => void,
  isBetHistory?: boolean,
) => {
  const isBetHistoryCashOut = noCloseIcon;
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);
  const { cashOutAskType, cashOutBet } = useAppSelector(
    (state) => state.openBets,
  );
  const { availableCashout = 0 } = cashOutBet || {};
  const { cashOutMutation, cashOutIsLoading } = useCashOutRequest();

  const dispatch = useAppDispatch();

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(setIsCashoutOpen(false));
  };

  const handleCashOut = async (e: MouseEvent) => {
    e.stopPropagation();
    try {
      dispatch(setCashOutSuccess(false));
      const payload: CashOutPayload = {
        betSlipId: cashOutBet?.id || '',
        policy: Number(cashOutAskType),
        cashout: availableCashout,
      };

      const response = await cashOutMutation(payload);

      if ('error' in response) {
        dispatch(setCashOutSuccess(false));
      } else {
        if (isBetHistory) {
          dispatch(setCashedOutBet(cashOutBet?.id || ''));
        } else {
          dispatch(removeOpenBet(cashOutBet?.id || ''));
        }

        dispatch(setCashOutSuccess(true));
        dispatch(setIsCashOut(false));
      }

      if (!isBetHistoryCashOut) {
        dispatch(openDialog(DIALOGS.CASHOUT_SUCCESS));
      } else {
        onCashoutProcessed && onCashoutProcessed();
      }
    } catch (e) {
      dispatch(setCashOutSuccess(false));
      console.log(e);
    }

    dispatch(setCashOutProcessed(true));
  };

  return {
    availableCashout,
    currency,
    cashOutAskType,
    isLoading: cashOutIsLoading,
    dispatch,
    localized,
    handleClose,
    handleCashOut,
  };
};
