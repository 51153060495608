import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BreadcrumbItem, BreadcrumbState } from '~types/breadcrumb';

const initialState: BreadcrumbState = {
  trail: [],
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    // Set the entire breadcrumb trail, useful for initializing
    setBreadcrumbTrail: (state, action: PayloadAction<BreadcrumbItem[]>) => {
      state.trail = action.payload;
    },
    // Push a breadcrumb onto the trail
    pushBreadcrumb: (state, action: PayloadAction<BreadcrumbItem>) => {
      state.trail.push(action.payload);
    },
    // Pop the last breadcrumb off the trail
    popBreadcrumb: (state) => {
      state.trail.pop();
    },
    // Clear the breadcrumb trail
    clearBreadcrumbTrail: (state) => {
      state.trail = [];
    },
  },
});

export const {
  pushBreadcrumb,
  popBreadcrumb,
  setBreadcrumbTrail,
  clearBreadcrumbTrail,
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
