import { useEffect, useState } from 'react';

import {
  DOCUMENT_TYPES,
  documentsTypeToStatusMap,
  UserDocumentResponse,
} from '~api/user/types';
import { useUploadUserDocument } from '~api/user/userMutations';
import { useLazyGetUserDocuments } from '~api/user/userQueries';
import { useTranslation } from '~hooks/useTranslation';

export const useAccountVerificationContent = () => {
  const { localized } = useTranslation();
  const [documents, setDocuments] = useState<UserDocumentResponse[]>([]);
  const [documentType, setDocumentType] = useState(
    DOCUMENT_TYPES.INTERNATIONAL_PASSPORT,
  );
  const [file, setFile] = useState<File | null>(null);
  const { uploadUserDocumentMutation } = useUploadUserDocument();
  const { lazyGetUserDocumentsQuery } = useLazyGetUserDocuments();

  const documentTypeOptions = [
    {
      value: DOCUMENT_TYPES.INTERNATIONAL_PASSPORT,
      label: localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.internationalPassport',
      ),
      title: localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.internationalPassport',
      ),
    },
    {
      value: DOCUMENT_TYPES.NATIONAL_PASSPORT,
      label: localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.nationalPassport',
      ),
      title: localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.nationalPassport',
      ),
    },
    {
      value: DOCUMENT_TYPES.DRIVING_LICENSE,
      label: localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.driverLicense',
      ),
      title: localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.driverLicense',
      ),
    },
  ];

  const handleFileAttach = (file: File | null) => {
    setFile(file);
  };

  const handleFileUpload = async () => {
    if (!file || !documentType) return;

    const formData = new FormData();

    formData.append('Type', `${documentsTypeToStatusMap[documentType]}`);
    formData.append('File', file);

    try {
      await uploadUserDocumentMutation(formData);
      loadUserDocuments();
    } catch (err) {
      console.error('Failed to upload file:', err);
    }

    setFile(null);
  };

  const handleDocumentTypeChange = (value: DOCUMENT_TYPES) => {
    setDocumentType(value);
  };

  const loadUserDocuments = async () => {
    try {
      const response = await lazyGetUserDocumentsQuery().unwrap();

      setDocuments(response);
    } catch (err) {
      console.error('Failed to load user documents:', err);
    }
  };

  useEffect(() => {
    loadUserDocuments();
  }, []);

  return {
    documents,
    documentType,
    documentTypeOptions,
    file,
    localized,
    handleFileAttach,
    handleFileUpload,
    handleDocumentTypeChange,
  };
};
