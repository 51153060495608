import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  useResetPassword,
  useResetPasswordCode,
  useVerifyCode,
} from '~api/auth/authMutations';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { RESET_PASSWORD_FORM_DEFAULT_VALUES } from '~constants/auth';
import { HTTP_STATUS_CODES } from '~constants/common';
import { usePhoneNumber } from '~hooks/usePhoneNumber';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { closeDialog, openDialog } from '~store/slices/globalDialogSlice';
import { setIsUserBlocked } from '~store/slices/userSlice';
import { COOKIES_NAMES, setCookie } from '~utils/cookies';

import {
  RESET_PASSWORD_FORM_FIELDS,
  ResetPasswordFormInputs,
  SIGNIN_FORM_FIELDS,
} from '../../types';
import { AuthTabs, StyledAuthTabsContent } from '../AuthTabs';
import { AuthTemplate } from '../AuthTemplate';

import { EnterNewPasswordTab } from './EnterNewPasswordTab';
import { ProvideNumberTab } from './ProvideNumberTab';
import { RESET_PASSWORD_TABS } from './ResetPasswordTabList';
import { VerifyNumberTab } from './VerifyNumberTab';

export const ResetPasswordContent = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(
    RESET_PASSWORD_TABS.PROVIDE_NUMBER,
  );
  const formMethods = useForm<ResetPasswordFormInputs>({
    defaultValues: RESET_PASSWORD_FORM_DEFAULT_VALUES,
  });
  const [code, setCode] = useState('');
  const { handleSubmit, register, getValues, setError } = formMethods;
  const { localizedError } = useTranslation();
  const {
    resetPasswordCodeMutation,
    resetPasswordCodeData,
    resetPasswordCodeIsLoading,
    resetPasswordCodeIsSuccess,
    resetPasswordCodeErrorCode,
    resetPasswordCodeIsError,
  } = useResetPasswordCode();
  const { verifyCodeMutation } = useVerifyCode();
  const { resetPasswordMutation, resetPasswordIsLoading } = useResetPassword();
  const { resetPasswordToken, verificationCodeExpiration } =
    resetPasswordCodeData || {};
  const [phoneNumber, setPhoneNumber] = useState('');
  const { getPhoneNumberWithoutCode, getPhoneNumberWithCode } =
    usePhoneNumber();

  const phoneNumberInput = register(
    RESET_PASSWORD_FORM_FIELDS.PHONE_NUMBER_FIELD,
  );

  const goToTab = (tabName: RESET_PASSWORD_TABS) => {
    setActiveTab(tabName);
  };

  const handleBack = () => {
    dispatch(openDialog(DIALOGS.SIGN_IN));
  };

  const handleReset = async () => {
    try {
      const phoneWithoutCode = getPhoneNumberWithoutCode(
        getValues(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD),
      );
      const startsWithZero = phoneWithoutCode.startsWith('0');
      const phoneNumber = startsWithZero
        ? phoneWithoutCode.slice(1)
        : phoneWithoutCode;

      const result = await resetPasswordCodeMutation({
        phoneNumber: getPhoneNumberWithCode(phoneNumber),
      }).unwrap();
      const { verificationCodeExpiration } = result;

      setPhoneNumber(phoneNumber);

      setCookie(
        COOKIES_NAMES.VERIFICATION_CODE_EXPIRATION,
        verificationCodeExpiration,
        {
          expires: new Date(verificationCodeExpiration),
        },
      );
      goToTab(RESET_PASSWORD_TABS.VERIFY_NUMBER);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (['userDeactivated', 'userBlocked'].includes(err.data.message[0])) {
        dispatch(openDialog(DIALOGS.DEACTIVATED_ACCOUNT));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(setIsUserBlocked(err.data.message[0] === 'userBlocked'));
      }

      // show error if for some reason the provideNumber call fails
      console.error('Failed submitting the number: ', err);
    }
  };

  const handleNextTab = async () => {
    const numberCode = getValues(RESET_PASSWORD_FORM_FIELDS.NUMBER_CODE_FIELD);
    const formattedPhoneNumber = phoneNumber.startsWith('0')
      ? phoneNumber.slice(1)
      : phoneNumber;

    try {
      await verifyCodeMutation({
        phoneNumber: getPhoneNumberWithCode(formattedPhoneNumber),
        verificationCode: numberCode,
      }).unwrap();
      goToTab(RESET_PASSWORD_TABS.ENTER_NEW_PASSWORD);
    } catch (err) {
      setError(RESET_PASSWORD_FORM_FIELDS.NUMBER_CODE_FIELD, {
        type: 'invalidCodeNumber',
      });
    }
  };

  const handleChangePassword = async () => {
    const phoneWithoutCode = getPhoneNumberWithoutCode(
      getValues(RESET_PASSWORD_FORM_FIELDS.PHONE_NUMBER_FIELD),
    );

    const startsWithZero = phoneWithoutCode.startsWith('0');
    const phoneNumber = startsWithZero
      ? phoneWithoutCode.slice(1)
      : phoneWithoutCode;

    try {
      await resetPasswordMutation({
        password: getValues(RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD),
        resetPasswordToken: resetPasswordToken!,
        phoneNumber: getPhoneNumberWithCode(phoneNumber),
        verificationCode: code,
      });
      dispatch(closeDialog());
    } catch (err) {
      console.error('Failed to set a new password: ', err);
    }
  };

  const handleCodeChange = (code: string) => {
    setCode(code);
  };

  useEffect(() => {
    if (
      resetPasswordCodeIsError &&
      resetPasswordCodeErrorCode === HTTP_STATUS_CODES.NOT_FOUND
    ) {
      setError('phoneNumberField', {
        type: HTTP_STATUS_CODES.NOT_FOUND,
        message: localizedError('phoneNumber.invalid'),
      });
    }
  }, [resetPasswordCodeIsError, resetPasswordCodeErrorCode, formMethods]);

  return (
    <AuthTemplate>
      <AuthTabs activeTab={activeTab}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(() => {})}>
            <StyledAuthTabsContent value={RESET_PASSWORD_TABS.PROVIDE_NUMBER}>
              <ProvideNumberTab
                onBack={handleBack}
                onReset={handleReset}
                phoneNumberInput={phoneNumberInput}
                resetPasswordCodeIsLoading={resetPasswordCodeIsLoading}
              />
            </StyledAuthTabsContent>
            <StyledAuthTabsContent value={RESET_PASSWORD_TABS.VERIFY_NUMBER}>
              <VerifyNumberTab
                onNextTab={handleNextTab}
                phoneNumberInput={phoneNumberInput}
                verificationCodeExpiration={verificationCodeExpiration}
                onCodeChange={handleCodeChange}
                onReset={handleReset}
                resetPasswordCodeIsSuccess={resetPasswordCodeIsSuccess}
                resetPasswordIsLoading={resetPasswordIsLoading}
              />
            </StyledAuthTabsContent>
            <StyledAuthTabsContent
              value={RESET_PASSWORD_TABS.ENTER_NEW_PASSWORD}
            >
              <EnterNewPasswordTab
                onChangePassword={handleChangePassword}
                resetPasswordIsLoading={resetPasswordIsLoading}
              />
            </StyledAuthTabsContent>
          </form>
        </FormProvider>
      </AuthTabs>
    </AuthTemplate>
  );
};
