import { useEffect, useRef, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_CONNECTED_STATUS,
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

export interface EventsMessageData {
  ids: string[];
  MarketId: number;
}

export const useLiveMenuHubConnection = () => {
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const {
    liveMenuSports: liveMenu,
    mainMarketsSelected,
    openedSports,
    openedCountryLeagues,
  } = useAppSelector((state) => state.liveMenu);
  const { isMobileOrTablet } = useMedia();
  const prevPayloadRef = useRef<{ MarketId: number; ids: string[] }[]>();

  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const [socket, setSocket] = useState<HubConnection | null>(null);
  const [eventsData, setEventsData] = useState<EventsMessageData[]>([]);

  useEffect(() => {
    return () => {
      socket?.invoke(SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_INPLAY_MENU);
      socket?.invoke(
        SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS,
      );
    };
  }, []);

  useEffect(() => {
    const eventsDataArr: EventsMessageData[] = [];

    liveMenu.forEach(({ id: sportId, countries }) => {
      if (!openedSports.includes(sportId.toString())) return;
      countries.forEach(({ leagues }) => {
        leagues.forEach(({ events, id }) => {
          if (!openedCountryLeagues.includes(id.toString())) return;
          const eventIds = events
            .map((event) => event.id)
            .filter((item) => item) as string[];
          const selectedMarketId = mainMarketsSelected[sportId]
            ? mainMarketsSelected[sportId]
            : null;

          if (selectedMarketId && (selectedMarketId as number)) {
            const savedMarket = eventsDataArr.find(
              (item) => item.MarketId === selectedMarketId,
            );

            if (savedMarket) {
              savedMarket.ids = [...savedMarket.ids, ...eventIds];
            } else {
              eventsDataArr.push({
                ids: eventIds,
                MarketId: selectedMarketId as number,
              });
            }
          }
        });
      });
    });
    setEventsData(eventsDataArr);
  }, [
    liveMenu,
    mainMarkets,
    openedCountryLeagues,
    isMobileOrTablet,
    openedSports,
    mainMarketsSelected,
  ]);

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  const subscribeEvents = async (eventsData: EventsMessageData[]) => {
    try {
      const groupedData: Record<number, string[]> = {};

      eventsData.forEach((item) => {
        const marketId = item.MarketId;

        if (!groupedData[marketId]) {
          groupedData[marketId] = [];
        }

        groupedData[marketId] = (groupedData[marketId] || []).concat(item.ids);
      });

      const resPayload: { MarketId: number; ids: string[] }[] = [];

      Object.entries(groupedData).forEach(([key, value]) => {
        resPayload.push({ MarketId: parseInt(key), ids: value });
      });

      if (areTwoObjectsEqual(prevPayloadRef.current, resPayload)) return;
      await socket?.invoke(
        SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS,
        resPayload,
      );
      prevPayloadRef.current = resPayload;
    } catch (e) {
      console.error('Failed to subscribe events: ', e);
    }
  };

  useEffect(() => {
    if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
      subscribeEvents(eventsData);
    }
  }, [socket, eventsData]);

  useEffect(() => {
    if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
      socket.invoke(SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_INPLAY_MENU);
    }
  }, [socket]);
};
