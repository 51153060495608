import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  UserRegisterResponse,
  VerifyPhoneNewCodeResponse,
} from '~api/auth/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Checkbox } from '~components/atoms/Checkbox';
import { NumberCodeFieldInput } from '~components/atoms/NumberCodeFieldInput';
import { PhoneNumberField } from '~components/atoms/PhoneNumberField';
import { Separator } from '~components/atoms/Separator';
import { ErrorMessage, Text } from '~components/atoms/Typography';
import { INPUT_CODE_LENGTH, SIGNUP_FORM_DEFAULT_VALUES } from '~constants/auth';
import { useCountdownTimer } from '~hooks/useCountdownTimer';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { COOKIES_NAMES, getCookie, setCookie } from '~utils/cookies';

import { SIGNUP_FORM_FIELDS, SignupFormInputs } from '../../types';

import { SignUpTabList } from './SignUpTabList';

interface VerifyNumberProps {
  verifyUserIsLoading: boolean;
  verifyPhoneNewCodeIsSuccess: boolean;
  verifyPhoneNewCodeIsLoading: boolean;
  onSignIn: () => void;
  onRegisterUser: () => void;
  onVerifyPhoneNewCode: () => Promise<void>;
  registerUserData?: UserRegisterResponse;
  verifyPhoneNewCodeData?: VerifyPhoneNewCodeResponse;
}

export const VerifyNumberTab = ({
  verifyPhoneNewCodeData,
  verifyPhoneNewCodeIsSuccess,
  verifyPhoneNewCodeIsLoading,
  verifyUserIsLoading,
  registerUserData,
  onSignIn,
  onRegisterUser,
  onVerifyPhoneNewCode,
}: VerifyNumberProps) => {
  const { isMobile } = useMedia();
  const { setValue, register, formState, clearErrors, getValues } =
    useFormContext<SignupFormInputs>();
  const { errors } = formState;
  const { localized, localizedError } = useTranslation();
  const [code, setCode] = useState('');
  const [getCodeIsLoading, setGetCodeIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const { verificationCodeExpiration } = registerUserData || {};
  const { verificationCodeExpiration: newCodeExpiration } =
    verifyPhoneNewCodeData || {};

  const { timeRemaining, formatTime, isTimerAvailable, resetTimer } =
    useCountdownTimer(verificationCodeExpiration!);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { minimumAge } = useAppSelector((state) => state.settings);
  const [agreedToTerms, setAgreedToTerms] = useState(
    SIGNUP_FORM_DEFAULT_VALUES.agreeToTermsField,
  );

  const handlePolicyClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(closeDialog());
    navigate('/info/privacy_policy');
  };

  const handleConditionsClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(closeDialog());
    navigate('/info/terms_and_conditions');
  };

  const isGetAgainButtonDisabled = isTimerAvailable || getCodeIsLoading;

  const updateNumberCode = (input: string): void => {
    setCode(input);
    setValue(SIGNUP_FORM_FIELDS.NUMBER_CODE_FIELD, input);
  };

  const updatePhoneNumber = (input: string): void => {
    setPhoneNumber(input);
    setValue(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, input);
  };

  const handleVerifyPhoneNewCode = async () => {
    if (!getCookie(COOKIES_NAMES.VERIFICATION_CODE_EXPIRATION)) {
      setGetCodeIsLoading(true);
      await onVerifyPhoneNewCode();
      setGetCodeIsLoading(false);
    }
  };

  useEffect(() => {
    updatePhoneNumber(getValues(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD));
  }, []);

  useEffect(() => {
    if (code.length === INPUT_CODE_LENGTH && !errors.numberCodeField) {
      onRegisterUser();
    }
  }, [code]);

  useEffect(() => {
    if (verifyPhoneNewCodeIsSuccess && newCodeExpiration) {
      resetTimer(newCodeExpiration!);
      setCookie(COOKIES_NAMES.VERIFICATION_CODE_EXPIRATION, newCodeExpiration, {
        expires: new Date(newCodeExpiration),
      });
    }
  }, [verifyPhoneNewCodeIsSuccess, newCodeExpiration]);

  return (
    <>
      <Text
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
        css={{
          fontSize: '$xl',
          lineHeight: '$44',
          mb: '$2',
        }}
      >
        {localized('signUp.checkPhone')}
      </Text>
      <Text
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
        css={{
          fontSize: '17px',
          lineHeight: '$24',
        }}
      >
        {localized('signUp.verifyCodeSent')}
      </Text>
      <SignUpTabList />
      <Box css={{ position: 'relative' }}>
        {errors.root?.serverError?.message && (
          <Box
            css={{
              position: 'absolute',
              top: '-$3',
              width: '100%',
            }}
          >
            <ErrorMessage
              type="centeredError"
              message={errors.root?.serverError?.message}
            />
          </Box>
        )}
        <NumberCodeFieldInput
          code={code}
          setCode={updateNumberCode}
          labelText={
            errors.numberCodeField
              ? localizedError('numberCode.incorrectCode')
              : localized('signUp.fields.numberCode.label')
          }
          error={!!errors.numberCodeField}
          clearError={() => {
            clearErrors(SIGNUP_FORM_FIELDS.NUMBER_CODE_FIELD);
          }}
          {...register(SIGNUP_FORM_FIELDS.NUMBER_CODE_FIELD, {
            validate: {
              isInvalidCode: (value) => value.length === 6,
            },
          })}
        />
      </Box>
      <Text textAlign="center" level="sm-3" css={{ marginTop: '$4', mb: '$1' }}>
        {localized('signUp.dontGetCode')}
      </Text>
      <Text textAlign="center" level="xxs-4" css={{ mb: '$4' }}>
        {localized('signUp.checkPhoneOrGetAgain')}
      </Text>
      <Box
        css={{
          position: 'relative',
        }}
      >
        {errors.phoneNumberField && (
          <Box css={{ position: 'absolute', top: '-$4', width: '100%' }}>
            <ErrorMessage
              type="centeredError"
              message={errors.phoneNumberField.message!}
            />
          </Box>
        )}
        <PhoneNumberField
          phoneNumberId={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
          phoneNumber={phoneNumber}
          setPhoneNumber={updatePhoneNumber}
          isValidPhoneNumber={isValidPhoneNumber}
          setIsValidPhoneNumber={setIsValidPhoneNumber}
          isLoginForm
          {...register(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD)}
        />
      </Box>
      <Button
        size="medium"
        fullWidth
        onClick={handleVerifyPhoneNewCode}
        disabled={isGetAgainButtonDisabled}
        isLoading={verifyPhoneNewCodeIsLoading}
        css={{
          margin: '$5 0',
          opacity: '1 !important',
          position: 'relative',
          backgroundColor: isGetAgainButtonDisabled
            ? '$secondaryOne'
            : '$accentPrimaryOne',
        }}
      >
        <Text
          level={'16-20'}
          color={isGetAgainButtonDisabled ? 'textSecondaryOne' : 'textButtons'}
        >
          {localized('buttons.getAgain')}
        </Text>

        <Text
          color="yellowPrimary"
          textTransform="none"
          level="12-20"
          as="span"
          css={{
            position: 'absolute',
            right: '12px',
            top: '8px',
          }}
        >
          {isTimerAvailable
            ? localized('signUp.availableInN', {
                minutes: formatTime(timeRemaining),
              })
            : ''}
        </Text>
      </Button>
      <Box css={{ position: 'relative', pb: '$3' }}>
        <Checkbox
          id={SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD}
          checked={agreedToTerms}
          isError={!!errors.agreeToTermsField}
          onCheckedChange={() => {
            setAgreedToTerms(!agreedToTerms);
            setValue(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD, !agreedToTerms, {
              shouldValidate: true,
            });
          }}
          {...register(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD, {
            validate: {
              notAgreedToTerms: (value) => {
                if (!value) {
                  return localizedError('agreeToTerms.mustBeTrue');
                }

                return true;
              },
            },
          })}
          label={
            <Text level="xxxs-3">
              {localized('signUp.fields.terms.beginning', { minimumAge })}
              <Text
                as="span"
                level="xxxs-3"
                color="accentPrimaryOne"
                fontWeight="bold"
                underline
                css={{ p: '$1' }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onClick={handleConditionsClick}
              >
                {localized('signUp.fields.terms.linkTerms') + ','}
              </Text>
              <Text
                as="span"
                level="xxxs-3"
                color="accentPrimaryOne"
                fontWeight="bold"
                underline
                css={{ p: '$1' }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onClick={handlePolicyClick}
              >
                {localized('signUp.fields.terms.linkPrivacy')}
              </Text>
              {localized('signUp.fields.terms.ending')}
            </Text>
          }
        />
        {errors.agreeToTermsField && (
          <Box css={{ position: 'absolute', bottom: '-$3', width: '100%' }}>
            <ErrorMessage
              type="centeredError"
              message={errors.agreeToTermsField.message!}
            />
          </Box>
        )}
      </Box>
      <Separator
        verticalSpace={isMobile ? 3 : 6}
        shrinkOut={isMobile ? 4 : 7}
      />
      <Button
        fullWidth
        onClick={onRegisterUser}
        isLoading={verifyUserIsLoading}
        disabled={verifyUserIsLoading}
      >
        {localized('buttons.register')}
      </Button>
      <Text level="sm-3" css={{ margin: '$4' }} textAlign="center">
        {localized('signUp.haveAccount')}
      </Text>
      <Text
        isLink
        underline
        textTransform="uppercase"
        fontWeight="bold"
        textAlign="center"
        onClick={onSignIn}
      >
        {localized('buttons.signIn')}
      </Text>
    </>
  );
};
