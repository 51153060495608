import { useEffect, useRef, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { ScrollableBox } from '~components/atoms/TouchSlider/styled.components';
import { CategoriesList } from '~components/molecules/GamesFilters/CategoriesList';
import { CURRENCY_CODES } from '~constants/common';
import { useAppSelector } from '~store';

interface MobileCategoriesListProps {
  value?: string | null;
  onChange?: (value: string | null) => void;
}

export const MobileCategoriesList = ({
  value,
  onChange,
}: MobileCategoriesListProps) => {
  const { currency } = useAppSelector((state) => state.settings);

  const isNigeria = currency === CURRENCY_CODES.NGN;
  const [startShadow, setStartShadow] = useState(false);
  const [endShadow, setEndShadow] = useState(false);

  const scrollableBoxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { scrollWidth, clientWidth, scrollLeft } = scrollableBoxRef.current;

      if (clientWidth + scrollLeft === scrollWidth) {
        setEndShadow(false);
      } else {
        setEndShadow(true);
      }

      if (scrollLeft > 0) {
        setStartShadow(true);
      } else {
        setStartShadow(false);
      }
    };

    scrollableBoxRef.current &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scrollableBoxRef.current.addEventListener('scroll', handleScroll);

    return () => {
      scrollableBoxRef.current &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        scrollableBoxRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      css={{
        background: '$primaryOne',
        position: 'relative',
      }}
    >
      {startShadow && (
        <Box
          style={{
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '40px',
            zIndex: 2,
            transform: 'rotateY(180deg)',
            background: isNigeria
              ? 'linear-gradient(270deg, #181E26 -11.54%, rgba(24, 30, 38, 0.00) 84.62%)'
              : 'linear-gradient(270deg, #093054 -11.54%, rgba(9, 48, 84, 0.00) 84.62%)',
          }}
        />
      )}
      {endShadow && (
        <Box
          style={{
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '40px',
            zIndex: 2,
            background: isNigeria
              ? 'linear-gradient(270deg, #181E26 -11.54%, rgba(24, 30, 38, 0.00) 84.62%)'
              : 'linear-gradient(270deg, #093054 -11.54%, rgba(9, 48, 84, 0.00) 84.62%)',
          }}
        />
      )}
      <ScrollableBox ref={scrollableBoxRef}>
        <CategoriesList mobile value={value} onChange={onChange} />
      </ScrollableBox>
    </Box>
  );
};
