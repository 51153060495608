import { useCallback, useEffect, useState } from 'react';

type CallbackFunction<T extends unknown[]> = (...args: T) => void;

export const useDebounce = <T extends unknown[]>(
  callback: CallbackFunction<T>,
  delay: number,
) => {
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const debouncedCallback = useCallback(
    (...args: T) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      setDebounceTimeout(
        setTimeout(() => {
          callback(...args);
        }, delay),
      );
    },
    [callback, delay, debounceTimeout],
  );

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  return debouncedCallback;
};
