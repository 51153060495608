import React from 'react';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks';

interface VerifyLinkTabProps {
  isRegisteringWhatsApp?: boolean;
}

export const VerifyLinkTab = ({
  isRegisteringWhatsApp,
}: VerifyLinkTabProps) => {
  const { localized } = useTranslation();

  return (
    <Box flexCol gap={3} alignCenter justifyCenter fullWidth>
      {isRegisteringWhatsApp && (
        <Loader
          css={{
            color: 'textPrimaryOne',
            '& > svg': {
              width: '28px',
              height: '28px',
            },
          }}
        />
      )}
      <Text
        level="14-24"
        textTransform="uppercase"
        textAlign="center"
        css={{
          maxWidth: '419px',
        }}
      >
        {localized('signUp.followLink1')}{' '}
        <Text
          as="span"
          level="14-24"
          textTransform="uppercase"
          color="greenPrimary"
        >
          {localized(
            isRegisteringWhatsApp
              ? 'signUp.whatsApp'
              : 'signUp.fields.email.label',
          )}
        </Text>{' '}
        {localized('signUp.followLink2')}
      </Text>
    </Box>
  );
};
