import { LANGUAGE } from '~constants/common';

export enum BACKEND_BONUS_TYPES {
  FREEBET = 'freeBet',
}

export interface BonusQueryParams {
  Status?: BONUS_STATUS;
  DateFrom?: string;
  DateTo?: string;
}

export interface ProfileBonusPayload {
  contentType?: BACKEND_BONUS_TYPES;
  queryParams?: BonusQueryParams;
}

export interface Translation {
  language: LANGUAGE;
  description: string;
}

export type Translations = Translation[];

export interface Bonus {
  id: string;
  title: string;
  description: string;
  totalAmount: number;
  remainingAmount: number;
  won: number;
  maxWin: number;
  status: number;
  bonusType: number;
  createDate: string;
  expireDate: string;
  translations: Translations;
  betSlipCodes: number[];
}

export type Bonuses = Bonus[];

export interface BonusBalanceResponse {
  userId: string;
  bonusBalance: number;
}

export const enum BONUS_STATUS {
  ACTIVE = 1,
  USED = 2,
  EXPIRED = 3,
}
