import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledMessageWrapper = styled(Box, {
  position: 'absolute',
  bottom: '12px',
  left: '12px',
  width: 'calc(100% - 24px)',
  '@xs_sm': {
    position: 'relative',
    width: '100%',
    left: 0,
    bottom: 0,
  },
});
