import { NavigationLink } from '~components/atoms/NavigationLink';
import { QUERY_PARAMS } from '~constants/common';
import { SPORTS } from '~constants/sport';
import { StoreSportEvent } from '~types/events';
import { ExtraData } from '~types/sportExtraData';
import { SPORT_MENUS } from '~types/sportMenus';
import { buildSearchQuery } from '~utils/url';

import {
  MainScore,
  MatchStatus,
  ScoreboardActionsPanel,
  ScoreDetailsMobile,
  Title,
} from '../../../InplayEventOverview';
import { useFallbackScoreboard } from '../../hooks';
import { SportBackgroundBox } from '../../SportBackgroundBox';

import { FormattedScoreboardStartDate } from './components';

interface FallbackScoreboardProps {
  restEventData: Omit<StoreSportEvent, 'extraData'>;
  parsedExtraData: ExtraData | null;
}

export const FallbackScoreboard = ({
  restEventData,
  parsedExtraData,
}: FallbackScoreboardProps) => {
  const {
    formattedMatchStatus,
    homeTeamName,
    awayTeamName,
    homeTeamScore,
    awayTeamScore,
    sportName,
    leagueName,
    formattedStartDate,
    isMatchStarted,
    isMobileOrTablet,
  } = useFallbackScoreboard(restEventData, parsedExtraData);

  const { sportId, countryId, leagueId, countryCode, countryName } =
    restEventData;

  return (
    <SportBackgroundBox
      sport={sportName as SPORTS}
      isMatchStarted={isMatchStarted}
    >
      <NavigationLink
        to={{
          search: buildSearchQuery({
            [QUERY_PARAMS.SPORT_ID]: sportId,
            [QUERY_PARAMS.COUNTRY_ID]: countryId,
            [QUERY_PARAMS.LEAGUE_ID]: leagueId,
            [QUERY_PARAMS.MENU]: SPORT_MENUS.PREMATCH,
          }),
        }}
        end
      >
        <Title
          countryCode={countryCode as string}
          sportId={sportId as number}
          sport={sportName}
          league={leagueName}
          countryName={countryName}
        />
      </NavigationLink>
      {isMatchStarted ? (
        <MatchStatus matchStatus={formattedMatchStatus} />
      ) : (
        <FormattedScoreboardStartDate formattedStartDate={formattedStartDate} />
      )}
      <MainScore
        firstTeam={homeTeamName}
        firstScore={homeTeamScore}
        secondScore={awayTeamScore}
        secondTeam={awayTeamName}
        formattedStartDate={formattedStartDate}
        isMatchStarted={isMatchStarted}
      />
      {isMatchStarted && isMobileOrTablet && (
        <ScoreDetailsMobile sportName={sportName} extraData={parsedExtraData} />
      )}
      {isMatchStarted && <ScoreboardActionsPanel />}
    </SportBackgroundBox>
  );
};
