import { COLOR_KEYS } from 'stitches.config';

export const enum JACKPOT_SETTLEMENT_STATUS {
  UNSETTLED = 1,
  SETTLED = 2,
}

export const enum JACKPOT_STATUS {
  INACTIVE = 1,
  ACTIVE = 2,
  ONGOING = 3,
  FINISHED = 4,
}

export enum JACKPOT_SELECTION_STATUS {
  NOT_RESULTED = 0,
  LOSER = 1,
  WINNER = 2,
  REFUND = 3,
}

export const JACKPOT_SETTLEMENT_STATUS_LABELS = {
  [JACKPOT_SETTLEMENT_STATUS.UNSETTLED]: 'Unsettled',
  [JACKPOT_SETTLEMENT_STATUS.SETTLED]: 'Settled',
};

export const JACKPOT_SELECTION_STATUS_LABELS = {
  [JACKPOT_SELECTION_STATUS.NOT_RESULTED]: 'No result',
  [JACKPOT_SELECTION_STATUS.LOSER]: 'Lost',
  [JACKPOT_SELECTION_STATUS.WINNER]: 'Win',
  [JACKPOT_SELECTION_STATUS.REFUND]: 'Void',
};

export const JACKPOT_SELECTION_STATUS_COLORS: Record<
  JACKPOT_SELECTION_STATUS,
  COLOR_KEYS
> = {
  [JACKPOT_SELECTION_STATUS.NOT_RESULTED]: 'accentPrimaryTwo',
  [JACKPOT_SELECTION_STATUS.LOSER]: 'redPrimary',
  [JACKPOT_SELECTION_STATUS.WINNER]: 'greenPrimary',
  [JACKPOT_SELECTION_STATUS.REFUND]: 'textSecondaryOne',
};

export const JACKPOT_SETTLEMENT_STATUS_COLORS: Record<
  JACKPOT_SETTLEMENT_STATUS,
  COLOR_KEYS
> = {
  [JACKPOT_SETTLEMENT_STATUS.UNSETTLED]: 'textSecondaryOne',
  [JACKPOT_SETTLEMENT_STATUS.SETTLED]: 'greenPrimary',
};

export const JACKPOT_STATUS_LABELS = {
  [JACKPOT_STATUS.INACTIVE]: 'Inactive',
  [JACKPOT_STATUS.ACTIVE]: 'Active',
  [JACKPOT_STATUS.ONGOING]: 'Ongoing',
  [JACKPOT_STATUS.FINISHED]: 'Finished',
};

export const JACKPOT_STATUS_COLORS: Record<JACKPOT_STATUS, COLOR_KEYS> = {
  [JACKPOT_STATUS.INACTIVE]: 'redPrimary',
  [JACKPOT_STATUS.ACTIVE]: 'greenPrimary',
  [JACKPOT_STATUS.ONGOING]: 'accentPrimaryTwo',
  [JACKPOT_STATUS.FINISHED]: 'textSecondaryOne',
};
