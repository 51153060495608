import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';

export const StyledSearchWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  height: '32px',
  backgroundColor: '$secondaryOne',
  borderRadius: '$4',
  p: '0 $2',
  color: '$textPrimaryOne',
  border: '1px solid $pressedOne',
  '& > input': {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '$12',
    lineHeight: '$20',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
});

export const StyledSearchIconWrapper = styled(Button, {
  color: '$textSecondaryOne',
  background: '$primaryTwo !important',
  p: '$3 $2',
  '@xs_sm': {
    height: '32px',
    p: '$2 $2',
    lineHeight: '$20',
  },
});

export const timeSelectorStyles = {
  width: '32px',
  height: '32px',
  boxShadow: 'none',
  '& > div': {
    backgroundColor: '$primaryTwo !important',
    padding: '$2 !important',
    '& > span': {
      display: 'flex',
    },
  },
};
