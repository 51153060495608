import { useEffect, useLayoutEffect } from 'react';

import { SportEventItem } from '~api/sportEvent/types';
import { useLiveEventConnection } from '~components/molecules/InplayEvents/hooks/useLiveEventConnection';
import { MARKET_STATUS } from '~constants/common';
import { useEventUpdatesListener, useRouterQuery } from '~hooks';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsEventLoaded,
  setLiveEventData,
  setLoadingEventId,
  setMarketGroups,
} from '~store/slices/liveMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { Market, SportEvent } from '~types/events';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { groupByMarketId } from '~utils/markets';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

export const usePrematchEvent = () => {
  const dispatch = useAppDispatch();
  const { eventId, sportId } = useQueryParams();
  const {
    liveEventData: eventData,
    marketGroups,
    isEventLoaded,
    loadingEventId,
  } = useAppSelector((state) => state.liveMenu);
  const { navigateBackOrHome } = useRouterQuery();
  const { isMobileOrTablet } = useMedia();

  useLayoutEffect(() => {
    if (isMobileOrTablet) {
      window.scrollTo(0, 0);
    }
  }, [isMobileOrTablet]);

  const { favoriteMarkets } = useAppSelector((state) => state.mainMarkets);

  const language = useAppSelector(selectLanguage);
  const { isPrimaryDataLoaded, sendWsMessage } = useEventsLoadingSocket();

  useEffect(() => {
    if (eventId && isPrimaryDataLoaded) {
      dispatch(setLiveEventData(null));
      dispatch(setLoadingEventId(eventId));
      sendWsMessage(ACTION_TYPE.GET_PREMATCH_EVENT, {
        Id: eventId,
        Language: language,
      });
    }

    return () => {
      dispatch(setIsEventLoaded(false));
    };
  }, [eventId, language, isPrimaryDataLoaded]);

  const updateEventData = (newData: SportEvent | null) => {
    if (eventId !== newData?.id) return;
    dispatch(setLiveEventData(newData));
  };

  useEventUpdatesListener({
    event: eventData as SportEventItem,
    onUpdate: updateEventData,
  });

  useLiveEventConnection({ loaded: !!eventData });

  useEffect(() => {
    if (eventData && isEventLoaded) {
      const mapUpdatedMarkets = (eventData?.markets || []).filter(
        (item) =>
          item &&
          [MARKET_STATUS.ACTIVE, MARKET_STATUS.SUSPENDED].includes(
            item.status,
          ) &&
          item.selections.length,
      );

      let mapUpdatedMarketsFavorites: (Market | undefined)[] = [];

      if (sportId || eventData.sportId) {
        const ongoingSportFavorites = favoriteMarkets?.find(
          (favoriteMarket) =>
            favoriteMarket.sportId === +(sportId || eventData.sportId || 0),
        );

        mapUpdatedMarketsFavorites = mapUpdatedMarkets.map((market) => {
          if (market?.marketId) {
            return {
              ...market,
              favorite: ongoingSportFavorites?.marketIds.includes(
                parseInt(market.marketId),
              ),
            };
          }

          return market;
        });
      }

      const resGroups = groupByMarketId(mapUpdatedMarketsFavorites);

      if (!areTwoObjectsEqual(resGroups, marketGroups)) {
        dispatch(setMarketGroups(resGroups));
      }
    }
  }, [eventData, favoriteMarkets, isEventLoaded, sportId, marketGroups]);

  return {
    isMobileOrTablet,
    marketGroups,
    eventId,
    eventData,
    isEventLoaded,
    loadingEventId,
    handleBackPress: () => navigateBackOrHome(),
  };
};
