import { MainMarket } from '~api/market/types';
import { InPlayMenuSports, TopPrematchEvents } from '~api/sportEvent/types';
import {
  HighlightsData,
  LAPTOP_SELECTION_WIDTH,
  LIVE_LAPTOP_SELECTION_HEIGHT,
  LIVE_SELECTION_HEIGHT,
  MIN_LAPTOP_MAIN_EVENT_DATA_WIDTH,
  MIN_MAIN_EVENT_DATA_WIDTH,
  PrematchUpcomingHighlightsData,
  SELECTION_WIDTH,
  SELECTIONS_COUNT,
  SELECTIONS_GAP,
  TopEventsDateGroupIds,
  TopPrematchDateGroupsIds,
  TOTAL_MARKET_NAME,
} from '~constants/mainPage';
import { Market, SportEvents, StoreSportEvent } from '~types/events';
import { SVGElement } from '~types/general';
export interface SportOption {
  id: number;
  name: string;
  icon?: SVGElement;
  count?: number;
}

export type SportOptions = SportOption[];

export const prepareLiveHighlightsData = (
  liveData: InPlayMenuSports,
): {
  data: HighlightsData;
  events: StoreSportEvent[];
  markets: Market[];
  sports: SportOptions;
} => {
  let resEvents: StoreSportEvent[] = [];

  let resMarkets: Market[] = [];

  const resSports: SportOptions = [];

  const resData: HighlightsData = {};

  liveData.forEach((sport) => {
    let sportEventsIds: string[] = [];

    const { countries, id: sportId, name: sportName } = sport;

    resSports.push({ id: sportId, name: sportName } as SportOption);

    countries.forEach((country) => {
      const { id: countryId, name: countryName, leagues } = country;

      leagues.forEach((league) => {
        const { events, id: leagueId, name: leagueName } = league;

        const resLeagueEvents = events.map((event) => {
          const { markets, ...restEvent } = event;
          const marketIds = markets.map(({ id }) => id);

          resMarkets = [...resMarkets, ...markets];

          return {
            ...restEvent,
            marketIds,
            sportId,
            sportName,
            countryId,
            countryName,
            leagueId,
            leagueName,
          };
        }) as SportEvents;

        sportEventsIds = [
          ...sportEventsIds,
          ...resLeagueEvents.map((event) => event.id),
        ];
        resEvents = [...resEvents, ...resLeagueEvents];
      });
    });
    resData[sportId.toString()] = sportEventsIds;
  });

  return {
    data: resData,
    events: resEvents,
    markets: resMarkets,
    sports: resSports,
  };
};

export const prepareLiveHighlightsDataAsUpcoming = (
  data: InPlayMenuSports,
): TopPrematchEvents => {
  let resData: TopPrematchEvents = [];

  data.forEach(({ id, name, countries }) => {
    let events: SportEvents = [];

    countries.forEach(({ id: countryId, name: countryName, leagues }) => {
      leagues.forEach(
        ({ id: leagueId, name: leagueName, events: leagueEvents }) => {
          const leagueEventsArray = leagueEvents.map((event) => {
            return {
              ...event,
              sportId: id,
              sportName: name,
              countryId,
              countryName,
              leagueId,
              leagueName,
            };
          }) as SportEvents;

          events = [...events, ...leagueEventsArray];
        },
      );
    });
    resData = [
      ...resData,
      {
        sportId: id,
        sportName: name,
        dateGroups: [
          {
            date: new Date().toISOString(),
            matches: events,
          },
        ],
      },
    ];
  });

  return resData;
};

export const preparePrematchUpcomingHighlightsData = (
  data: TopPrematchEvents,
): {
  data: PrematchUpcomingHighlightsData;
  events: StoreSportEvent[];
  markets: Market[];
  sports: SportOptions;
} => {
  let resEvents: StoreSportEvent[] = [];

  const resMarkets: Market[] = [];

  const resSports: SportOptions = [];

  const resData: PrematchUpcomingHighlightsData = {};

  data.forEach((sport) => {
    const { sportId, sportName, dateGroups } = sport;

    resSports.push({ id: sportId, name: sportName } as SportOption);

    const sportData: TopPrematchDateGroupsIds = [];

    dateGroups.forEach((dateGroup) => {
      const { date, matches } = dateGroup;

      const resDateGroupMatches = matches.map((match) => {
        const { markets, ...restMatch } = match;

        return {
          ...restMatch,
          markets,
          sportId,
          sportName,
          date,
        };
      }) as StoreSportEvent[];

      resEvents = [...resEvents, ...resDateGroupMatches];
      const dateSportObj = {
        date,
        matches: resDateGroupMatches,
      } as TopEventsDateGroupIds;

      sportData.push(dateSportObj);
    });

    resData[sportId.toString()] = sportData;
  });

  return {
    data: resData,
    events: resEvents,
    markets: resMarkets,
    sports: resSports,
  };
};

export const getLiveSelectionHeight = (isLaptop: boolean) => {
  return isLaptop ? LIVE_LAPTOP_SELECTION_HEIGHT : LIVE_SELECTION_HEIGHT;
};

export const getSelectionWidth = (isLaptop: boolean) => {
  return isLaptop ? LAPTOP_SELECTION_WIDTH : SELECTION_WIDTH;
};

export const getMinMainEventDataWidth = (isLaptop: boolean) => {
  return isLaptop
    ? MIN_LAPTOP_MAIN_EVENT_DATA_WIDTH
    : MIN_MAIN_EVENT_DATA_WIDTH;
};

export const determineMainMarketWidth = (
  { name, selections }: MainMarket,
  isLaptop: boolean,
) => {
  const isTotal = name === TOTAL_MARKET_NAME;

  return (
    getSelectionWidth(isLaptop) * (selections.length + (isTotal ? 1 : 0)) +
    SELECTIONS_GAP * (SELECTIONS_COUNT - 1) +
    'px'
  );
};

export enum MAIN_PAGE_TABS {
  LIVE_HIGHLIGHTS = 'mainPage.sections.liveHighlights',
  UPCOMING = 'mainPage.sections.upcoming',
}

export const mobileMainPageTabs = [
  MAIN_PAGE_TABS.UPCOMING,
  MAIN_PAGE_TABS.LIVE_HIGHLIGHTS,
];
