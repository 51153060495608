import { RefObject, useEffect, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { StyledLoadingIcon } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

interface BetslipProcessingBetLoaderProps {
  wrapperRef?: RefObject<HTMLDivElement>;
  scrollPosition?: number;
  isCashout?: boolean;
  isProcessing?: boolean;
}

export const BetslipProcessingBetLoader = ({
  wrapperRef,
  scrollPosition = 0,
  isCashout,
  isProcessing: isProcessingProps = false,
}: BetslipProcessingBetLoaderProps) => {
  const { isMobileOrTablet } = useMedia();
  const { isProcessing: isProcessingStore } = useAppSelector(
    (state) => state.betslip,
  );
  const { localized } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(isProcessingProps);

  const [fixedLoaderContentStyle, setFixedLoaderContentStyle] = useState({});

  useEffect(() => {
    setIsProcessing(isProcessingStore || isProcessingProps);
  }, [isProcessingStore, isProcessingProps]);

  useEffect(() => {
    if (wrapperRef && wrapperRef?.current) {
      const height = wrapperRef.current.getBoundingClientRect().height;
      const viewPortHeight = window.innerHeight;
      //TODO: update height from constants
      const maxHeight = viewPortHeight - 104;

      if (height > maxHeight) {
        setFixedLoaderContentStyle({
          position: 'fixed',
          top: maxHeight / 2 + scrollPosition + 'px',
        });
      } else {
        setFixedLoaderContentStyle({});
      }
    }
  }, [isProcessing, wrapperRef, scrollPosition]);

  return (
    isProcessing && (
      <Box
        absoluteCenter
        flexRow
        alignCenter
        fullHeight
        fullWidth
        justifyCenter
        css={{
          position: isMobileOrTablet ? 'fixed' : 'absolute',
          borderRadius: '$10',
          background: 'rgba(10, 15, 20, 0.70)',
          backdropFilter: 'blur(4px)',
          zIndex: 2,
        }}
      >
        <Box fullHeight fullWidth />
        <Box
          absoluteCenter={Object.keys(fixedLoaderContentStyle).length === 0}
          flexCol
          gap={2}
          alignCenter
          justifyCenter
          css={{
            color: '$textSecondaryOne',
            ...fixedLoaderContentStyle,
          }}
        >
          <StyledLoadingIcon
            css={{
              color: 'textSecondaryOne',
            }}
          />
          <Text
            color="textSecondaryOne"
            noWrap
            css={{
              fontSize: '$15',
              lineHeight: '$24',
              '@xs_sm': {
                fontSize: '$xxs',
                lineHeight: '$20',
              },
            }}
          >
            {localized(
              !isCashout ? 'betslip.processing' : 'betslip.cashoutProcessing',
            )}
          </Text>
        </Box>
      </Box>
    )
  );
};
