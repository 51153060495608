import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApplyPromoCode } from '~api/user/userMutations';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { UserGeneralInfoSection } from '~components/molecules/UserProfile/components';
import {
  StyledContentBox,
  StyledInnerBox,
  StyledProfileBox,
} from '~components/molecules/UserProfile/components/UserProfileDialogMobile/styled.components';
import { UserProfileNavigationMobile } from '~components/molecules/UserProfile/components/UserProfileNavigation';
import {
  StyledBalanceInfoButton,
  StyledBalanceInfoButtonText,
  StyledUserProfileInput,
} from '~components/molecules/UserProfile/components/UserProfileSidebar/styled.components';
import { UserProfileSidebarBalances } from '~components/molecules/UserProfile/components/UserProfileSidebar/UserProfileSidebarBalances';
import { useUserProfileDialog } from '~components/molecules/UserProfile/hooks/useUserProfileDialog';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useRouterQuery, useTranslation } from '~hooks';
import { ModalPagesStyledContainer } from '~pages/styled.components';
import { useAppDispatch } from '~store';
import { logout } from '~store/slices/userSlice';

export enum USER_PROFILE_DIALOG_MOBILE_STATE {
  SIDEBAR = 'sidebar',
  CONTENT = 'content',
}

export const UserProfileDialogMobile = () => {
  const { profile } = useUserProfileDialog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { navigateWithHistory } = useRouterQuery();
  const { localized } = useTranslation();
  const [promoCode, setPromoCode] = useState('');
  const { applyPromoCodeMutation, applyPromoCodeIsLoading } =
    useApplyPromoCode();

  const handleLogout = () => {
    dispatch(logout());
    navigate(ROUTE_TAB_NAMES.BASE);
  };

  const handleApplyPromoCode = async () => {
    try {
      await applyPromoCodeMutation({ code: promoCode }).unwrap();
    } catch (error) {
      console.error('Unable to apply promo code', error);
    }
  };

  if (!profile) {
    return null;
  }

  return (
    <>
      <ModalPagesStyledContainer>
        <StyledInnerBox>
          <StyledContentBox>
            <StyledProfileBox>
              <UserGeneralInfoSection profile={profile} />
              <Box flexCol gap={2}>
                <UserProfileSidebarBalances />
                <Box flexRow justifyContentBetween alignCenter gap={2}>
                  <StyledBalanceInfoButton
                    fullWidth
                    onClick={() => {
                      navigateWithHistory(
                        `${ROUTE_TAB_NAMES.PAYMENTS_DEPOSIT}/`,
                      );
                    }}
                  >
                    <StyledBalanceInfoButtonText isButton>
                      {localized('userProfile.personalProfileInfo.deposit')}
                    </StyledBalanceInfoButtonText>
                  </StyledBalanceInfoButton>
                  <StyledBalanceInfoButton
                    fullWidth
                    onClick={() => {
                      navigateWithHistory(
                        `${ROUTE_TAB_NAMES.PAYMENTS_WITHDRAW}/`,
                      );
                    }}
                  >
                    <StyledBalanceInfoButtonText isButton>
                      {localized('userProfile.personalProfileInfo.withdraw')}
                    </StyledBalanceInfoButtonText>
                  </StyledBalanceInfoButton>
                </Box>
              </Box>
            </StyledProfileBox>
            <UserProfileNavigationMobile />
            <StyledProfileBox>
              <Text level="sm-3">
                {localized('userProfile.personalProfileInfo.havePromoCode')}
              </Text>
              <StyledUserProfileInput
                placeholder={localized(
                  'userProfile.personalProfileInfo.inputYourPromoCode',
                )}
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              <Button
                size="small"
                fullWidth
                css={{
                  height: '32px',
                  borderRadius: '$6',
                }}
                onClick={handleApplyPromoCode}
                isLoading={applyPromoCodeIsLoading}
                disabled={!promoCode}
              >
                <Text
                  isButton
                  level="14-24"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {localized('userProfile.personalProfileInfo.apply')}
                </Text>
              </Button>
            </StyledProfileBox>
          </StyledContentBox>
          <Button
            size="small"
            variant="secondary"
            fullWidth
            onClick={handleLogout}
            css={{
              height: '40px',
              borderRadius: '$6',
              backgroundColor: '$redSecondary',
            }}
          >
            <Text
              color="redPrimary"
              level="18-24"
              fontWeight="bold"
              textAlign="center"
            >
              {localized('userProfile.personalProfileInfo.logout')}
            </Text>
          </Button>
        </StyledInnerBox>
      </ModalPagesStyledContainer>
    </>
  );
};
