export enum VERIFY_PERSONAL_DETAILS_FIELDS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  ID_NUMBER = 'idNumber',
}

export const fieldsArray = [
  VERIFY_PERSONAL_DETAILS_FIELDS.FIRST_NAME,
  VERIFY_PERSONAL_DETAILS_FIELDS.LAST_NAME,
  VERIFY_PERSONAL_DETAILS_FIELDS.MIDDLE_NAME,
  VERIFY_PERSONAL_DETAILS_FIELDS.ID_NUMBER,
];
