import React from 'react';

import { Box } from '~components/atoms/Box';
import { StoreSportEvent } from '~types/events';
import { ExtraData } from '~types/sportExtraData';

import { FallbackScoreboard } from '../scoreboards';

interface FallbackEventOverviewProps {
  restEventData: Omit<StoreSportEvent, 'extraData'>;
  parsedExtraData: ExtraData | null;
}

export const FallbackEventOverview = ({
  restEventData,
  parsedExtraData,
}: FallbackEventOverviewProps) => {
  return (
    <>
      <FallbackScoreboard
        restEventData={restEventData}
        parsedExtraData={parsedExtraData}
      />
      <Box></Box>
    </>
  );
};
