import React from 'react';

import { BetAmount } from '~api/betslip/types';
import { BetslipTotalInfo } from '~components/molecules/Betslip/components/BetslipFooter/BetslipTotalInfo';
import { StyledBetslipValueContainer } from '~components/molecules/Betslip/components/BetslipFooter/styled.components';
import { BetslipInputField } from '~components/molecules/Betslip/components/BetslipInfo/BetslipInputField';
import { BETSLIP_VALUES_MAP_OPTIONS } from '~components/molecules/Betslip/constants';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAdjustedBetAmount,
  setStakePossibleWinLoadingMap,
} from '~store/slices/betslipSlice';

export const BetslipAggregatedValue = () => {
  const dispatch = useAppDispatch();
  const { aggregatedBetAmount } = useAppSelector((state) => state.betslip);

  const handleAggregatedBetAmountChange = (amount: BetAmount) => {
    dispatch(
      setStakePossibleWinLoadingMap({
        [BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED]: true,
      }),
    );

    dispatch(setAdjustedBetAmount(amount as BetAmount));
  };

  const handleMainChange = (value: string) => {
    handleAggregatedBetAmountChange({
      ...aggregatedBetAmount,
      main: value,
    });
  };

  const handleBonusChange = (value: string) => {
    handleAggregatedBetAmountChange({
      ...aggregatedBetAmount,
      bonus: value,
    });
  };

  const handleSingleInputChange = (value: string) => {
    handleAggregatedBetAmountChange({ main: value });
  };

  return (
    <StyledBetslipValueContainer>
      <BetslipInputField
        isMultipleBet={true}
        mainAmount={aggregatedBetAmount.main}
        bonusAmount={aggregatedBetAmount.bonus}
        handleSingleInputChange={handleSingleInputChange}
        handleMainChange={handleMainChange}
        handleBonusChange={handleBonusChange}
      />
      <BetslipTotalInfo />
    </StyledBetslipValueContainer>
  );
};
