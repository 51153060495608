import React, { useEffect } from 'react';

import { UserProfileData } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import { PaymentMethodType } from '~components/molecules/UserProfile/components/Payments/components/PaymentMethodType';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_METHODS,
  PAYMENT_PROVIDER,
} from '~constants/payments';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setActivePaymentTab,
  setAddingNewPaymentMethodFormStatus,
  setIsLoading,
  setSelectedPayment,
} from '~store/slices/paymentsSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';
import { selectIsUganda } from '~store/slices/selectors/settings';

import { PaymentMethod } from './components/CardMethod';

export enum PAYMENT_TYPES {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}

interface PaymentsProps {
  paymentType?: PAYMENT_TYPES;
}

export const Payments = ({ paymentType }: PaymentsProps) => {
  const dispatch = useAppDispatch();
  const isUganda = useAppSelector(selectIsUganda);
  const { paymentProviderSettings } = useAppSelector((state) => state.settings);

  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { selectedPayment, availablePaymentSettings, activePaymentTab } =
    useAppSelector((state) => state.payments);
  const { profile } = useAppSelector((state) => state.userState);
  const { personalInfoConfirmed = false } = profile || ({} as UserProfileData);

  useEffect(() => {
    const cryptoExists = paymentProviderSettings.find(
      ({ paymentProvider }) => paymentProvider === PAYMENT_PROVIDER.CRYPTO,
    );

    if (cryptoExists) {
      dispatch(
        setActivePaymentTab({
          paymentProvider: PAYMENT_PROVIDER.CRYPTO,
          paymentType: contentTab as USER_PAYMENT_TABS,
        }),
      );

      return;
    }

    if (isUganda) {
      dispatch(setSelectedPayment(PAYMENT_METHODS.INSTANT_BANK));
      dispatch(
        setActivePaymentTab({
          paymentProvider: PAYMENT_PROVIDER.RELWORX,
          paymentType: contentTab as USER_PAYMENT_TABS,
        }),
      );
    }

    if (!isUganda && contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      dispatch(setSelectedPayment(PAYMENT_METHODS.INSTANT_BANK));
    }
  }, [isUganda, contentTab, paymentProviderSettings]);

  useEffect(() => {
    if (paymentType === PAYMENT_TYPES.WITHDRAW) {
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.WITHDRAW,
        }),
      );
    } else if (paymentType === PAYMENT_TYPES.DEPOSIT) {
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.DEPOSIT,
        }),
      );
    }

    dispatch(setIsLoading(false));
  }, [paymentType]);

  useEffect(() => {
    const cryptoExists = availablePaymentSettings.find(
      ({ paymentProvider }) => paymentProvider === PAYMENT_PROVIDER.CRYPTO,
    );

    dispatch(
      setAddingNewPaymentMethodFormStatus(
        cryptoExists
          ? ADD_PAYMENT_ACCOUNT_FORM_STATUSES.CRYPTO
          : ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
      ),
    );
  }, [activePaymentTab, selectedPayment, availablePaymentSettings]);

  const isNotVerfiried =
    !personalInfoConfirmed && contentTab === USER_PAYMENT_TABS.WITHDRAW;

  const isCrypto = availablePaymentSettings.find(
    ({ paymentProvider }) => paymentProvider === PAYMENT_PROVIDER.CRYPTO,
  );

  return (
    <Box
      fullHeight
      css={{
        borderRadius: '$8',
        border: '1px solid $secondaryTwo !important',
        position: 'relative',
      }}
    >
      {!isUganda && contentTab === USER_PAYMENT_TABS.DEPOSIT && !isCrypto && (
        <PaymentMethodType />
      )}
      <PaymentMethod isNotVerified={isNotVerfiried} />
    </Box>
  );
};
