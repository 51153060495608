import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExistingPaymentMethod, SavedBanks } from '~api/transaction/types';

export interface PaymentAccountsState {
  paystackDepositCards: ExistingPaymentMethod[] | null;
  flutterwaveDepositCards: ExistingPaymentMethod[] | null;
  withdrawBanks: SavedBanks | null;
}

const initialState: PaymentAccountsState = {
  paystackDepositCards: null,
  flutterwaveDepositCards: null,
  withdrawBanks: null,
};

export const paymentAccountsSlice = createSlice({
  name: 'paymentAccounts',
  initialState,
  reducers: {
    setPaystackDepositCards: (
      state,
      action: PayloadAction<ExistingPaymentMethod[] | null>,
    ) => {
      state.paystackDepositCards = action.payload;
    },
    setFlutterwaveDepositCards: (
      state,
      action: PayloadAction<ExistingPaymentMethod[] | null>,
    ) => {
      state.flutterwaveDepositCards = action.payload;
    },
    setWithdrawBankAccounts: (
      state,
      action: PayloadAction<SavedBanks | null>,
    ) => {
      state.withdrawBanks = action.payload;
    },
    updateDefaultWithdrawBank: (state, action: PayloadAction<string>) => {
      if (!state.withdrawBanks?.length) return;
      state.withdrawBanks = state.withdrawBanks.map((bank) => {
        if (bank.id === action.payload) {
          return { ...bank, isDefault: true };
        }

        return { ...bank, isDefault: false };
      });
    },
    updateDefaultPaystackCard: (state, action: PayloadAction<string>) => {
      if (!state.paystackDepositCards?.length) return;
      state.paystackDepositCards = state.paystackDepositCards?.map((card) => {
        if (card.id === action.payload) {
          return { ...card, isDefault: true };
        }

        return { ...card, isDefault: false };
      });
    },
    updateDefaultFlutterwaveCard: (state, action: PayloadAction<string>) => {
      if (!state.flutterwaveDepositCards?.length) return;
      state.flutterwaveDepositCards = state.flutterwaveDepositCards.map(
        (card) => {
          if (card.id === action.payload) {
            return { ...card, isDefault: true };
          }

          return { ...card, isDefault: false };
        },
      );
    },
    removeDefaultPaystackCard: (state, action: PayloadAction<string>) => {
      if (!state.paystackDepositCards?.length) return;
      state.paystackDepositCards = state.paystackDepositCards.filter((card) => {
        return card.id !== action.payload;
      });
    },
    removeDefaultFlutterwaveCard: (state, action: PayloadAction<string>) => {
      if (!state.flutterwaveDepositCards?.length) return;
      state.flutterwaveDepositCards = state.flutterwaveDepositCards.filter(
        (card) => {
          return card.id !== action.payload;
        },
      );
    },
    removeDefaultWithdrawBank: (state, action: PayloadAction<string>) => {
      if (!state.withdrawBanks?.length) return;
      state.withdrawBanks = state.withdrawBanks.filter((bank) => {
        return bank.id !== action.payload;
      });
    },
    resetAccountsState: (state) => {
      state.paystackDepositCards = null;
      state.flutterwaveDepositCards = null;
      state.withdrawBanks = null;
    },
  },
});

export const {
  setPaystackDepositCards,
  setFlutterwaveDepositCards,
  updateDefaultFlutterwaveCard,
  updateDefaultPaystackCard,
  removeDefaultPaystackCard,
  removeDefaultFlutterwaveCard,
  setWithdrawBankAccounts,
  updateDefaultWithdrawBank,
  removeDefaultWithdrawBank,
} = paymentAccountsSlice.actions;

export default paymentAccountsSlice.reducer;
