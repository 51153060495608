import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { useMedia, useTranslation } from '~hooks';
import { VerifyEmailFailIcon, VerifyEmailSuccessIcon } from '~icons';

interface SuccessTabProps {
  isEmailVerified?: boolean;
  isSuccess?: boolean;
  handleModalClose: () => void;
}

export const SuccessTab = ({
  isEmailVerified = false,
  isSuccess = false,
  handleModalClose,
}: SuccessTabProps) => {
  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();

  return (
    <Box flexCol alignCenter>
      <Box
        css={{
          mb: isMobileOrTablet ? '11px' : '$4',
          lineHeight: 0,
        }}
      >
        {isSuccess ? (
          isMobileOrTablet ? (
            <VerifyEmailSuccessIcon width={26} height={26} />
          ) : (
            <VerifyEmailSuccessIcon />
          )
        ) : isMobileOrTablet ? (
          <VerifyEmailFailIcon width={26} height={26} />
        ) : (
          <VerifyEmailFailIcon />
        )}
      </Box>
      <Box css={{ mb: isMobileOrTablet ? '$1' : '$2' }}>
        <Text
          textTransform="uppercase"
          level={isMobileOrTablet ? '14-20' : '17-24'}
          fontWeight="medium"
        >
          {localized(
            isEmailVerified
              ? `verifyEmailToken.${isSuccess ? 'success' : 'fail'}.title`
              : 'signUp.success.title',
          )}
        </Text>
      </Box>
      <Box css={{ mb: isMobileOrTablet ? '$2' : '$6' }}>
        <Text
          textTransform="uppercase"
          level={isMobileOrTablet ? '12-20' : '14-20'}
        >
          {localized(
            isEmailVerified
              ? `verifyEmailToken.${isSuccess ? 'success' : 'fail'}.subtitle`
              : 'signUp.success.subTitle',
          )}
        </Text>
      </Box>
      {isMobileOrTablet && (
        <Separator
          verticalSpace={0}
          shrinkOut={0}
          css={{
            backgroundColor: '$secondaryOne',
            width: 'calc(100% + (2 * $3))',
            mb: '$3',
          }}
        />
      )}
      <Button
        fullWidth
        variant="secondaryTwo"
        onClick={handleModalClose}
        css={{
          p: '$2',
          '@xs_sm': {
            borderRadius: '$6',
          },
        }}
      >
        <Text level="18-24" textAlign="center">
          {localized(`signUp.success.continue`)}
        </Text>
      </Button>
    </Box>
  );
};
