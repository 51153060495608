import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { useInitializeBreadcrumbForSport } from '~hooks/useInitializeBreadcrumbsForSport';
import { useMedia } from '~hooks/useMedia';
import { ellipsis } from '~utils/cssUtils';

import { FavoriteLeagueButton } from './FavoriteLeagueButton';
import { Flag } from './Flag';

const PrimitiveLeagueMenuItem = styled(Box, {
  position: 'relative',
  display: 'flex',
  backgroundColor: '$secondaryOne',
  py: '10px',
  pl: '$3',
  pr: '52px',
  borderRadius: '$6',
  color: '$textPrimaryOne',
  cursor: 'pointer',
  '& > .league': {
    ...ellipsis,
  },
  '&:last-child': {
    mb: 0,
  },
  '&:hover': {
    backgroundColor: '$secondaryTwo',
    '.count': {
      color: '$textPrimaryOne',
    },
  },
  '&[data-state=active]': {
    backgroundColor: '$secondaryTwo',
    border: '1px solid $accentPrimaryOne',
    '.count': {
      color: '$textPrimaryOne',
    },
  },
});

interface LeagueMenuItemProps {
  id: number;
  countryId: number;
  events: number;
  isActive: boolean;
  countryName: string;
  code: string;
  sportId: number;
  name?: string;
  sportName?: string;
  showFavoriteIcon?: boolean;
  showItemCountryFlag?: boolean;
}

export const LeagueMenuItem = ({
  id,
  name,
  events,
  sportId,
  isActive,
  countryId,
  sportName,
  code,
  countryName,
  showFavoriteIcon,
  showItemCountryFlag,
}: LeagueMenuItemProps) => {
  const { isMobileOrTablet } = useMedia();

  const { initializeBreadcrumb } = useInitializeBreadcrumbForSport(
    sportId,
    countryName,
  );

  const handleOnClick = () => {
    if (isMobileOrTablet) {
      initializeBreadcrumb();
    }
  };

  return (
    <PrimitiveLeagueMenuItem
      alignCenter
      data-state={isActive ? 'active' : 'inActive'}
      onClick={handleOnClick}
    >
      {showItemCountryFlag && (
        <Box css={{ marginRight: '$3', lineHeight: 0 }}>
          <Flag
            countryName={countryName as CountryName}
            code={code}
            size="sm"
          />
        </Box>
      )}

      {showFavoriteIcon && (
        <Box css={{ mr: '$3' }}>
          <FavoriteLeagueButton
            id={id}
            name={name}
            events={events}
            sportId={sportId}
            countryId={countryId}
            sportName={sportName}
            code={code}
            countryName={countryName}
          />
        </Box>
      )}
      <Text className="league" css={{ color: 'inherit' }} level="sm-3">
        {name}
      </Text>
      <Tag
        text={events}
        className="count"
        css={{
          position: 'absolute',
          right: 0,
          backgroundColor: 'transparent !important',
          '@xs_sm': {
            right: '$1',
          },
        }}
      />
    </PrimitiveLeagueMenuItem>
  );
};
