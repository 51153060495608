import { COLOR_KEYS, theme } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  BETSLIP_STATUS_DETAIL_KEY,
  STATUS_CODE_COLORS,
} from '~components/molecules/BetslipHistorySection/constants';
import {
  BET_STATUS_DETAIL_I18N,
  BET_STATUS_I18N,
  BETSLIP_DETAIL_STATUS,
  BETSLIP_STATUS,
} from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';

interface BetslipHistoryStatusProps {
  status: BETSLIP_STATUS | BETSLIP_DETAIL_STATUS;
  isInnerTable?: boolean;
  isRegularText?: boolean;
  isLarge?: boolean;
}

export const StatusCell = ({
  status,
  isInnerTable,
  isRegularText = false,
  isLarge,
}: BetslipHistoryStatusProps) => {
  const statusColor = isInnerTable
    ? (BETSLIP_DETAIL_STATUS[status] as COLOR_KEYS)
    : (BETSLIP_STATUS[status] as COLOR_KEYS);

  const colorValue = STATUS_CODE_COLORS[
    statusColor as unknown as BETSLIP_STATUS_DETAIL_KEY
  ] as COLOR_KEYS;
  const color = colorValue in theme.colors ? colorValue : 'yellowPrimary';
  const { localized } = useTranslation();

  return (
    <Box flexRow>
      <Text
        noWrap
        color={color}
        level={
          isLarge ? '12-20' : isInnerTable && !isRegularText ? '11-20' : '12-20'
        }
        css={{ overflow: 'hidden' }}
      >
        {statusColor &&
          localized(
            isInnerTable
              ? BET_STATUS_DETAIL_I18N[status! as BETSLIP_DETAIL_STATUS]
              : BET_STATUS_I18N[status! as BETSLIP_STATUS],
          )}
      </Text>
    </Box>
  );
};
