import React from 'react';

import { TextWithTooltip } from '~components/atoms/TextWithTooltip';
import { useFormatAmountWithCurrency } from '~hooks';
import { TransactionHistoryCellProps } from '~types/transactions';

export const BalanceCell = ({
  cell: { value },
}: TransactionHistoryCellProps) => {
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  return <TextWithTooltip text={getCommaSeparatedAmount(value)} />;
};
