import React from 'react';

import {
  StyledDropdownTrigger,
  StyledSettingsMobileButton,
} from '~components/atoms/SettingsDropdown/styled.components';
import { StyledUserProfileBarContainer } from '~components/molecules/UserProfile/components/UserProfileBar';
import { useMedia } from '~hooks';
import useIsInIframe from '~hooks/useIsInIframe';
import { SettingsIcon } from '~icons';

interface Props {
  onClick: () => void;
}

export const SettingsDropdownWithoutPopover = ({
  onClick = () => {},
}: Props) => {
  const isInIframe = useIsInIframe();
  const { isMobileOrTablet } = useMedia();

  return (
    <StyledUserProfileBarContainer
      isFooter
      isSquare
      flexRow
      justifyCenter
      alignCenter
      css={{
        borderColor: `${isInIframe ? '$secondaryTwo' : 'transparent'} !important`,
        background: 'transparent',
      }}
    >
      <StyledDropdownTrigger as="div">
        {!isMobileOrTablet ? (
          <SettingsIcon />
        ) : (
          <StyledSettingsMobileButton>
            <SettingsIcon onClick={onClick} />
          </StyledSettingsMobileButton>
        )}
      </StyledDropdownTrigger>
    </StyledUserProfileBarContainer>
  );
};
