import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';

import { SportEventItems } from '../sportEvent/types';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.EVENT }),
  endpoints: (builder) => ({
    getSearchEvents: builder.query<SportEventItems, string>({
      query: (searchTerm: string) => ({
        url: `Event/search?Name=${searchTerm}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetSearchEventsQuery } = searchApi;
