import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import dayjs from 'dayjs';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { Input } from '~components/atoms/Input';
import { PhoneNumberField } from '~components/atoms/PhoneNumberField';
import { Select } from '~components/atoms/Select';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { GreenCheckIcon, RedCrossIcon } from '~icons';
import { useAppSelector } from '~store';
import { validateEmail } from '~utils/validateEmail';

import { UserDetailEditableField } from '../UserDetailEditableField';
import { UserDetailField } from '../UserDetailField';

import { useFieldChangeHandlers } from './hooks/useFieldChangeHandlers';
import { usePersonalDetailsForm } from './hooks/usePersonalDetailsForm';
import { PERSONAL_DETAILS_FORM_FIELDS } from './constants';
import { StyledPersonalDetailsFormWrapper } from './styled.components';

interface PersonalDetailsFormProps {
  onPersonalDetailsChange?: (val: boolean) => void;
}

export const PersonalDetailsForm = ({
  onPersonalDetailsChange,
}: PersonalDetailsFormProps) => {
  const { localized, localizedError } = useTranslation();
  const {
    id,
    email,
    emailConfirmed,
    phoneNumberConfirmed,
    personalInfoConfirmed,
    isAllDetailsConfirmed,
    userName,
    userGender,
    userBirthDate,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
    formMethods,
    editingField,
    localizedGenderOptions,
    updateUserProfileIsLoading,
    isCalendarOpen,
    idNumber,
    middleName,
    hasChanged,
    isMobileOrTablet,
    isPersonalInfoChanged,
    setIsCalendarOpen,
    setEditingField,
    handleDiscardChanges,
    onSubmit,
  } = usePersonalDetailsForm();

  const { setValue, register, formState } = formMethods;
  const { errors } = formState;

  const {
    handleMiddleNameChange,
    handleIdNumberChange,
    handleUserEmailChange,
    handleUserNameChange,
    handleUserSurnameChange,
    handlePhoneNumberUpdate,
    handleBirthDateUpdate,
    handleGenderUpdate,
  } = useFieldChangeHandlers(setValue);
  const { minimumAge } = useAppSelector((state) => state.settings);
  const { personalProfileUpdateError } = useAppSelector(
    (state) => state.userUIState,
  );

  useEffect(() => {
    onPersonalDetailsChange && onPersonalDetailsChange(isPersonalInfoChanged);
  }, [isPersonalInfoChanged]);

  const buttonsContainer = (
    <>
      {!isAllDetailsConfirmed && (
        <Button
          size="small"
          variant="secondary"
          fullWidth
          css={{
            borderRadius: '$6',
            height: '32px',
            backgroundColor: '$secondaryTwo',
            '@xs_sm': {
              height: '$7',
            },
          }}
          onClick={handleDiscardChanges}
          disabled={!hasChanged || updateUserProfileIsLoading}
        >
          <Text level="14-16" textAlign="center">
            {localized('userProfile.personalProfileInfo.discardChanges')}
          </Text>
        </Button>
      )}
      {!isAllDetailsConfirmed && (
        <Button
          size="small"
          fullWidth
          type="submit"
          css={{
            borderRadius: '$6',
            height: '32px',
            '@xs_sm': {
              height: '$7',
            },
          }}
          isLoading={updateUserProfileIsLoading}
          disabled={!hasChanged || updateUserProfileIsLoading}
        >
          <Text isButton level="14-16" fontWeight="bold" textAlign="center">
            {localized('userProfile.personalProfileInfo.saveChanges')}
          </Text>
        </Button>
      )}
    </>
  );

  return (
    <Box fullWidth>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <StyledPersonalDetailsFormWrapper>
            <UserDetailField title="id" data={id} isProtected={false} />
            <UserDetailEditableField
              isProtected={personalInfoConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.USER_FIRST_NAME_FIELD}
              fieldValue={userFirstName}
              isEditing={
                editingField ===
                PERSONAL_DETAILS_FORM_FIELDS.USER_FIRST_NAME_FIELD
              }
              setEditingField={setEditingField}
              closeOnOutsideClick
              errors={errors}
            >
              <Input
                id={PERSONAL_DETAILS_FORM_FIELDS.USER_FIRST_NAME_FIELD}
                {...register(
                  PERSONAL_DETAILS_FORM_FIELDS.USER_FIRST_NAME_FIELD,
                )}
                onChange={handleUserNameChange}
                personalDetail
                autoFocus
              />
            </UserDetailEditableField>
            <UserDetailField
              title="username"
              data={userName}
              isProtected={false}
            />
            <UserDetailEditableField
              isProtected={personalInfoConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.USER_MIDDLE_NAME_FIELD}
              fieldValue={middleName}
              isEditing={
                editingField ===
                PERSONAL_DETAILS_FORM_FIELDS.USER_MIDDLE_NAME_FIELD
              }
              setEditingField={setEditingField}
              closeOnOutsideClick
              errors={errors}
            >
              <Input
                id={PERSONAL_DETAILS_FORM_FIELDS.USER_MIDDLE_NAME_FIELD}
                {...register(
                  PERSONAL_DETAILS_FORM_FIELDS.USER_MIDDLE_NAME_FIELD,
                )}
                onChange={handleMiddleNameChange}
                personalDetail
                autoFocus
              />
            </UserDetailEditableField>
            <UserDetailEditableField
              isProtected={emailConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.USER_EMAIL_FIELD}
              fieldValue={userEmail}
              isEditing={
                editingField === PERSONAL_DETAILS_FORM_FIELDS.USER_EMAIL_FIELD
              }
              setEditingField={setEditingField}
              closeOnOutsideClick
              errors={errors}
            >
              <Input
                id={PERSONAL_DETAILS_FORM_FIELDS.USER_EMAIL_FIELD}
                {...register(PERSONAL_DETAILS_FORM_FIELDS.USER_EMAIL_FIELD, {
                  validate:
                    userEmail || email
                      ? {
                          hasInvalidCharacter: (value) =>
                            value === '' ||
                            validateEmail(value) ||
                            localizedError('email.hasInvalidCharacter'),
                        }
                      : undefined,
                })}
                onChange={handleUserEmailChange}
                css={{
                  width: '100%',
                  padding: '0 0 0 $3',
                  color: !errors.userEmailField ? undefined : '$redPrimary',
                }}
                personalDetail
                autoFocus
              />
            </UserDetailEditableField>
            <UserDetailEditableField
              isProtected={personalInfoConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.USER_LAST_NAME_FIELD}
              fieldValue={userLastName}
              isEditing={
                editingField ===
                PERSONAL_DETAILS_FORM_FIELDS.USER_LAST_NAME_FIELD
              }
              setEditingField={setEditingField}
              closeOnOutsideClick
              errors={errors}
            >
              <Input
                id={PERSONAL_DETAILS_FORM_FIELDS.USER_LAST_NAME_FIELD}
                {...register(PERSONAL_DETAILS_FORM_FIELDS.USER_LAST_NAME_FIELD)}
                onChange={handleUserSurnameChange}
                personalDetail
                autoFocus
              />
            </UserDetailEditableField>
            <UserDetailEditableField
              isProtected={phoneNumberConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD}
              fieldValue={userPhoneNumber}
              isEditing={
                editingField === PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD
              }
              setEditingField={setEditingField}
              errors={errors}
            >
              <Box flexRow css={{ paddingRight: '$3' }}>
                <PhoneNumberField
                  key={PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD}
                  phoneNumberId={
                    PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD
                  }
                  isFullView={false}
                  phoneNumber={userPhoneNumber}
                  setPhoneNumber={handlePhoneNumberUpdate}
                  isValidPhoneNumber={
                    !errors?.[PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD]
                  }
                  {...register(
                    PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD,
                    {
                      validate: undefined,
                    },
                  )}
                  autoFocus
                />
              </Box>
            </UserDetailEditableField>
            <UserDetailEditableField
              isProtected={personalInfoConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.USER_ID_FIELD}
              fieldValue={idNumber}
              isEditing={
                editingField === PERSONAL_DETAILS_FORM_FIELDS.USER_ID_FIELD
              }
              setEditingField={setEditingField}
              closeOnOutsideClick
              errors={errors}
            >
              <Input
                id={PERSONAL_DETAILS_FORM_FIELDS.USER_ID_FIELD}
                {...register(PERSONAL_DETAILS_FORM_FIELDS.USER_ID_FIELD)}
                onChange={handleIdNumberChange}
                personalDetail
                autoFocus
              />
            </UserDetailEditableField>
            <UserDetailEditableField
              isProtected={personalInfoConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.BIRTH_DATE_FIELD}
              fieldValue={userBirthDate}
              isEditing={
                editingField === PERSONAL_DETAILS_FORM_FIELDS.BIRTH_DATE_FIELD
              }
              setEditingField={setEditingField}
              errors={errors}
              closeOnOutsideClick={!isCalendarOpen}
            >
              <DateRangePicker
                isSinglePicker
                initialValue={userBirthDate ? dayjs(userBirthDate) : undefined}
                onOkSingle={handleBirthDateUpdate}
                onSingleChange={handleBirthDateUpdate}
                checkAge
                legalAge={minimumAge}
                onClose={() => setIsCalendarOpen(false)}
                onHandleOpenChange={setIsCalendarOpen}
              />
            </UserDetailEditableField>
            <UserDetailEditableField
              isProtected={personalInfoConfirmed}
              fieldName={PERSONAL_DETAILS_FORM_FIELDS.USER_GENDER_FIELD}
              fieldValue={userGender}
              isEditing={
                editingField === PERSONAL_DETAILS_FORM_FIELDS.USER_GENDER_FIELD
              }
              setEditingField={setEditingField}
              errors={errors}
              localizedGenderOptions={localizedGenderOptions}
            >
              <Box flexRow css={{ width: '176px' }}>
                <Select
                  {...register(PERSONAL_DETAILS_FORM_FIELDS.USER_GENDER_FIELD)}
                  ariaLabel={localized(
                    'userProfile.personalProfileInfo.genderAriaLabel',
                  )}
                  value={userGender ? userGender : undefined}
                  onChange={handleGenderUpdate}
                  options={localizedGenderOptions}
                  isCustomContentWidth
                  contentWidth={isMobileOrTablet ? 196 : 192}
                  isOpened
                  isCombinedWithContent
                  onClose={() => setEditingField(null)}
                />
              </Box>
            </UserDetailEditableField>
            {isMobileOrTablet ? (
              <Box flexRow gap={2}>
                {buttonsContainer}
              </Box>
            ) : (
              buttonsContainer
            )}
          </StyledPersonalDetailsFormWrapper>
        </form>
      </FormProvider>
      {isPersonalInfoChanged && isMobileOrTablet && (
        <Box
          flexCol
          gap={2}
          justifyCenter
          alignCenter
          css={{
            width: 'calc(100vw - 32px)',
            position: 'absolute',
            borderRadius: '$8',
            top: '120px',
            left: '$4',
            padding: '$4',
            backgroundColor: personalProfileUpdateError
              ? '$redSecondary'
              : '$secondaryTwo',
          }}
        >
          {personalProfileUpdateError ? (
            <RedCrossIcon width={26} height={26} />
          ) : (
            <GreenCheckIcon width={26} height={26} />
          )}
          <Text textTransform="uppercase" level="14-20">
            {personalProfileUpdateError
              ? localized(
                  `userProfile.personalProfileInfo.accountSettings.changeSettingsFailed.${personalProfileUpdateError}`,
                )
              : localized(
                  'userProfile.personalProfileInfo.accountSettings.changeSettings.accountChangedTitle',
                )}
          </Text>
        </Box>
      )}
    </Box>
  );
};
