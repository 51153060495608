import { useCallback } from 'react';

import { NumberInput } from '~components/atoms/NumberInput';
import { Text } from '~components/atoms/Typography';
import { AmountAdjustmentButtons } from '~components/molecules/UserProfile/components/Payments/components/AmountAdjustmentButtons';
import { ADD_PAYMENT_ACCOUNT_FORM_STATUSES } from '~constants/payments';
import { useTranslation } from '~hooks';
import { useAppSelector } from '~store';
import { getCurrencySymbol } from '~store/slices/selectors/settings';
import { commaSeparatedNumber } from '~utils/numberUtils';

interface AmountWithButtonsProps {
  isDeposit: boolean;
  withDecimals?: boolean;
  enableInputDecimals?: boolean;
  amount: string;
  onChange: (value: string) => void;
  isError: boolean;
  min?: number;
  max?: number;
}

export const AmountWithButtons = ({
  isDeposit,
  withDecimals = false,
  enableInputDecimals = false,
  amount,
  onChange,
  isError,
  min = 0,
  max = 0,
}: AmountWithButtonsProps) => {
  const { localized } = useTranslation();
  const currencySymbol = useAppSelector(getCurrencySymbol);
  const { addingNewPaymentMethodFormStatus } = useAppSelector(
    (state) => state.payments,
  );
  const minAmountValid = min > 0;
  const maxAmountValid = max > 0;

  const handleChangeAmount = useCallback((amount: string) => {
    onChange(amount);
  }, []);

  const isCrypto =
    addingNewPaymentMethodFormStatus ===
    ADD_PAYMENT_ACCOUNT_FORM_STATUSES.CRYPTO;

  return (
    <>
      <Text level="14-20">
        {localized(
          isDeposit ? 'payments.depositAmount' : 'payments.withdrawalAmount',
        )}
      </Text>
      <NumberInput
        isError={Boolean(
          ((+amount < min && min) || (+amount > max && max)) && +amount !== 0,
        )}
        fullWidth
        enableInputDecimals={enableInputDecimals}
        value={amount}
        onChange={onChange}
        textColor={isError ? '$redPrimary' : '$textPrimaryOne'}
        inputColor="$secondaryTwo"
      />
      {(minAmountValid || maxAmountValid) && (
        <Text
          level="12-20"
          color={
            isError ||
            (((+amount < min && min) || (+amount > max && max)) &&
              +amount !== 0)
              ? 'redPrimary'
              : 'textPrimaryOne'
          }
        >
          {`
            ${
              minAmountValid
                ? localized('payments.minAmount', {
                    min: commaSeparatedNumber(
                      min,
                      isCrypto ? false : !withDecimals,
                    ),
                    currency: isCrypto ? 'USD' : currencySymbol,
                  })
                : ''
            }
            ${minAmountValid && maxAmountValid ? ' - ' : ''}
            ${
              maxAmountValid
                ? localized('payments.maxAmount', {
                    max: commaSeparatedNumber(
                      max,
                      isCrypto ? false : !withDecimals,
                    ),
                    currency: isCrypto ? 'USD' : currencySymbol,
                  })
                : ''
            }
          `}
        </Text>
      )}

      <AmountAdjustmentButtons handleChange={handleChangeAmount} />
    </>
  );
};
