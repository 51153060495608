import React, { useEffect, useRef, useState } from 'react';

import { Market, SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { SELECTION_STATUS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';

import { LEAGUE_SELECTION_STATUS } from '../constants';

import { LeagueOddContent } from './LeagueOddContent';
import { LeagueToggleGroup, LeagueToggleGroupItem } from './LeagueToggleGroup';

const getOddContentStatus = (
  status: SELECTION_STATUS,
  odd: number,
): LEAGUE_SELECTION_STATUS => {
  if (status === SELECTION_STATUS.OPEN) {
    return LEAGUE_SELECTION_STATUS.NORMAL;
  }

  if (status === SELECTION_STATUS.TOTAL) {
    return LEAGUE_SELECTION_STATUS.TOTAL;
  }

  if (odd === 0 && status === SELECTION_STATUS.PLACEHOLDER) {
    return LEAGUE_SELECTION_STATUS.PLACEHOLDER;
  }

  if (odd === 0 && status === SELECTION_STATUS.PLACEHOLDER_TOTAL) {
    return LEAGUE_SELECTION_STATUS.PLACEHOLDER_TOTAL;
  }

  return LEAGUE_SELECTION_STATUS.DISABLED;
};

interface LeagueOddButtonGroupProps {
  market: Market;
  isFirstEvent: boolean;
  eventId?: string;
  eventData: SportEventItem;
}

export const LeagueOddButtonGroup = ({
  market,
  isFirstEvent,
  eventId,
  eventData,
}: LeagueOddButtonGroupProps) => {
  const [activeButtons, setActiveButtons] = useState<string[]>([]);
  const toggleGroupRef = useRef<HTMLDivElement>(null);
  const { isMobileOrTablet } = useMedia();

  const { selections, name, id: marketId } = market || {};

  const onValueChange = (bets: string[]) => {
    setActiveButtons(bets);
  };

  useEffect(() => {
    if (isFirstEvent && toggleGroupRef.current) {
      // otherwise after close and open content absolutely positioned Box will be visible
      // only after the first click somewhere on the page
      // TODO check if it's possible to fix it without useEffect
      toggleGroupRef.current.focus();
    }
  }, [isFirstEvent]);

  if (!market || !selections || selections.length === 0) {
    return null;
  }

  return (
    <Box css={{ position: 'relative' }}>
      <LeagueToggleGroup
        type="multiple"
        onValueChange={onValueChange}
        value={activeButtons}
        loop={false}
        ref={toggleGroupRef}
        css={{
          '@xs_sm': {
            justifyContent: 'flex-end',
            width: 'max-content',
          },
        }}
      >
        {selections.map(({ id, odd, name, status }) => {
          const oddContentStatus = getOddContentStatus(status, odd);

          return (
            <LeagueToggleGroupItem
              key={id}
              eventData={eventData}
              eventId={eventId}
              marketId={marketId}
              selectionId={id}
              isFirstEvent={isFirstEvent}
              name={name}
              disabled={oddContentStatus !== LEAGUE_SELECTION_STATUS.NORMAL}
              total={[
                LEAGUE_SELECTION_STATUS.TOTAL,
                LEAGUE_SELECTION_STATUS.PLACEHOLDER_TOTAL,
              ].includes(oddContentStatus)}
              css={{
                '@xs_sm': {
                  width: '52px',
                  height: '$8',
                },
              }}
            >
              <LeagueOddContent oddContentStatus={oddContentStatus} odd={odd} />
            </LeagueToggleGroupItem>
          );
        })}
      </LeagueToggleGroup>
      {!isMobileOrTablet && isFirstEvent && (
        <Box
          css={{
            position: 'absolute',
            top: `-85px`,
            left: `50%`,
            transform: 'translateX(-50%)',
            width: 'max-content',
          }}
        >
          <Text level="md-3">{name}</Text>
        </Box>
      )}
    </Box>
  );
};
