export const removeEmptySpaces = (str: string) => {
  return str.replace(/\s/g, '');
};

export const removeNotChars = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]/g, '');
};

export const onlyCharsWithSpaces = (str: string) => {
  return str.replace(/[^a-zA-Z\s]/g, '').replace(/\s+/g, ' ');
};

export const keepDigitsAndLetters = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]/g, '');
};
