import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

export const BonusRuleDesktop = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Box
      css={{
        border: '1px solid $secondaryTwo',
        borderRadius: '$8',
        mb: '$2',
      }}
      flexCol
      gap={1}
    >
      <Box
        css={{
          borderBottom: description ? '1px solid $secondaryTwo' : 'none',
        }}
      >
        <Text
          level="14-24"
          color="accentPrimaryTwo"
          css={{
            p: '$2 $3',
          }}
        >
          {title}
        </Text>
      </Box>
      {!!description && (
        <Box
          css={{
            p: '$3',
            color: '$textPrimaryOne',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Box>
      )}
    </Box>
  );
};
