import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { Jackpots } from '~types/jackpot';

export const jackpotApi = createApi({
  reducerPath: 'jackpotApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.JACKPOT }),
  endpoints: (builder) => ({
    getActiveJackpots: builder.query<Jackpots, void>({
      query: () => ({
        url: `/jackpot/active`,
        method: 'GET',
      }),
    }),
    getSettledJackpots: builder.query<Jackpots, void>({
      query: () => ({
        url: `/jackpot/settled`,
        method: 'GET',
      }),
    }),
    getJackpotsBetsHistory: builder.query<Jackpots, void>({
      query: () => ({
        url: `/jackpot/history`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetActiveJackpotsQuery,
  useLazyGetSettledJackpotsQuery,
  useLazyGetJackpotsBetsHistoryQuery,
} = jackpotApi;
