import { formatNumber } from '~utils/numberUtils';

const MILLION = 1_000_000;
const HUNDRED_THOUSAND = 100_000;
const TEN_THOUSAND = 10_000;

export const INVISIBLE_BALANCE_PLACEHOLDER = '*****';

const thresholds = [
  { limit: MILLION, divisor: MILLION, suffix: 'M' },
  { limit: HUNDRED_THOUSAND, divisor: 1_000, suffix: 'K' },
  { limit: TEN_THOUSAND, divisor: 1_000, suffix: 'K' },
];

export const formatBalance = (balance?: number) => {
  if (!balance) return '0';
  const { divisor, suffix } = findThreshold(balance);

  return (formatNumber(balance / divisor) + suffix).replace('.00', '');
};

export const findThreshold = (balance: number) =>
  thresholds.find(({ limit }) => balance >= limit) || {
    divisor: 1,
    suffix: '',
  };

export const getFormattedBalance = (
  balance?: number,
  enableDecimals = false,
) => {
  let formattedBalance = '0';

  if (balance) {
    const roundedBalance = enableDecimals ? balance : Math.floor(balance);

    formattedBalance =
      roundedBalance.toFixed(1).length > 8
        ? formatBalance(roundedBalance)
        : (+roundedBalance.toFixed(1)).toLocaleString('en-US');
  }

  return formattedBalance;
};
