import React from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';

import { useTransactionHistory } from './hooks/useTransactionHistory';
import { TransactionHistoryFilter } from './TransactionHistoryFilter';
import { TransactionHistoryTable } from './TransactionHistoryTable';

interface TransactionHistoryProps {
  isAllTransactions?: boolean;
}

export const TransactionHistory = ({
  isAllTransactions = false,
}: TransactionHistoryProps) => {
  const { transactions, total, isLoading, handleLoadMore } =
    useTransactionHistory(isAllTransactions);

  return (
    <Box fullWidth fullHeight flexCol>
      {!isAllTransactions && (
        <>
          <Separator
            shrinkOut={3}
            verticalSpace={2}
            css={{
              marginTop: '0',
              backgroundColor: '$secondaryTwo',
            }}
          />
          <TransactionHistoryFilter />
          <Separator
            shrinkOut={3}
            verticalSpace={2}
            css={{
              backgroundColor: '$secondaryTwo',
            }}
          />
        </>
      )}
      <Box
        css={{
          maxHeight: isAllTransactions ? '540px' : '440px',
          border: '1px solid $secondaryTwo',
          p: '0 $2',
          borderRadius: '$6',
        }}
      >
        <TransactionHistoryTable
          isAllTransactions={isAllTransactions}
          data={transactions}
          loadMore={handleLoadMore}
          dataLength={total}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
