import React from 'react';

import { Box } from '~components/atoms/Box';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import { JackpotBetEvent } from '~types/jackpot';

export const OddsCell = ({ event }: { event: JackpotBetEvent }) => {
  const { selections } = event;

  return (
    <Box flexCol>
      {selections.map((selection) => (
        <StyledTableText key={selection.selectionId}>
          {selection.odds}
        </StyledTableText>
      ))}
    </Box>
  );
};
