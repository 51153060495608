import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSS } from '@stitches/react';

import BetOnlyLogo from '~assets/logos/bet-only/bet-only-logo.svg';
import BetPrazerLogo from '~assets/logos/betprazerLogo.svg';
import HalaBetLogo from '~assets/logos/halabetLogo.svg';
import OtbetLogo from '~assets/logos/otbetLogo.svg';
import SemaBetLogo from '~assets/logos/sema-bet/sema-bet-logo.webp';
import { StyledLogo } from '~components/atoms/Logo/styled.components';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { CLIENTS } from '~constants/clients';
import { QUERY_PARAMS, ROUTE_TAB_NAMES } from '~constants/common';
import { setActiveMenu } from '~features/sportsMenu/sportsMenuSlice';
import { useRouterQuery } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import {
  setActiveSportTabGroup,
  setMobileActiveTab,
} from '~store/slices/mobileSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { getTenant } from '~utils/getTenant';
import { QueryParams } from '~utils/url';

import { Text } from '../Typography';

export const clientsLogoMap: Record<CLIENTS, string | null> = {
  [CLIENTS.SEMA_BET]: SemaBetLogo,
  [CLIENTS.BET_ONLY]: BetOnlyLogo,
  [CLIENTS.BETPRAZER]: BetPrazerLogo,
  [CLIENTS.HALABET]: HalaBetLogo,
  [CLIENTS.OTBET]: OtbetLogo,
  [CLIENTS.NONE]: null,
};

const containerCssMap: Record<string, CSS> = {
  sm: {
    p: '10px 26px',
  },
  md: {
    p: '$5 70px',
  },
  md2: {
    width: '100%',
    py: '$5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@xs_sm': {
      py: '$3',
    },
  },
  lg: {
    width: '100%',
    p: '38px 123px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const textPropsMap: Record<string, Record<string, string>> = {
  sm: {
    level: 'sm-3',
  },
  md: {
    level: 'xl-4',
  },
  md2: {
    level: '28-24',
  },
};

const textCssMap: Record<string, CSS> = {
  sm: {},
  md: {},
  lg: {
    fontSize: '$xxl2',
    lineHeight: '$24',
  },
};

interface LogoProps {
  size?: 'sm' | 'md' | 'md2' | 'lg';
  type?: 'header' | 'auth';
  css?: CSS;
  isHeader?: boolean;
}

export const Logo = ({ size = 'sm', type, css, isHeader }: LogoProps) => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { updateQueryParams } = useRouterQuery();

  const onLogoClick = useCallback(() => {
    dispatch(setActiveSportTabGroup(SPORT_TAB_GROUPS.UPCOMMING));
    dispatch(setMobileActiveTab(MOBILE_MENU.SPORT));
    navigate({ pathname: ROUTE_TAB_NAMES.BASE });
    dispatch(closeDialog());
    window.scrollTo(0, 0);

    if (!isMobileOrTablet) {
      dispatch(setActiveMenu(SPORT_MENUS.PREMATCH));
      updateQueryParams(
        {
          [QUERY_PARAMS.MENU]: SPORT_MENUS.PREMATCH,
          [QUERY_PARAMS.EVENT_ID]: undefined,
          [QUERY_PARAMS.LEAGUE_ID]: undefined,
          [QUERY_PARAMS.GROUP]: undefined,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
      navigate(ROUTE_TAB_NAMES.BASE);
    }
  }, [isMobileOrTablet, updateQueryParams, dispatch, navigate]);

  return useMemo(() => {
    const tenant = getTenant();
    const logoBackgroundImage = clientsLogoMap[tenant]
      ? `url(${clientsLogoMap[tenant]})`
      : undefined;

    return logoBackgroundImage ? (
      <StyledLogo
        type={type}
        css={{
          backgroundImage: logoBackgroundImage,
          backgroundSize: 'contain',
          backgroundPosition: isHeader ? 'left' : 'center',
          backgroundRepeat: 'no-repeat',
        }}
        onClick={onLogoClick}
      />
    ) : (
      <StyledLogo
        type={type}
        flexRow
        alignCenter
        justifyCenter
        css={{
          cursor: 'pointer',
          backgroundColor: '$primaryTwo',
          borderRadius: '$4',
          width: 'fit-content',
          ...containerCssMap[size],
          ...css,
        }}
        onClick={onLogoClick}
      >
        <Text
          textTransform="uppercase"
          css={{ ...textCssMap[size], width: '100%', textAlign: 'center' }}
          {...textPropsMap[size]}
        >
          logo
        </Text>
      </StyledLogo>
    );
  }, [isMobileOrTablet, dispatch, navigate]);
};
