import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';

export const StyledInputContainer = styled(Box, {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '432px',
  alignItems: 'center',
  borderRadius: '$8',
  border: '1px solid $accentPrimaryOne',
  p: '$1',
  '&:focus': {
    boxShadow: '$elementActiveBorder',
  },
  '&:hover': {
    boxShadow: '$elementHoverBorder',
  },
  '@xs_sm': {
    borderRadius: '$6',
  },
});

export const StyledInput = styled(Input, {
  padding: '0 $1',
  width: '100%',
  fontSize: '$16',
  lineHeight: '$28',
  '@lg_xl': {
    fontSize: '$18',
    lineHeight: '$24',
  },
  '@xs_sm': {
    fontSize: '$14',
    lineHeight: '$20',
  },
});

export const StyledActionButton = styled(Button, {
  minWidth: '172px',
  py: '$1 !important',
  fontWeight: '$bold',
  borderRadius: '$6',
  fontSize: '$16 !important',
  lineHeight: '$28 !important',
  '@lg_xl': {
    py: '$2 !important',
    fontSize: '$18 !important',
    lineHeight: '$24 !important',
  },
  '@xs': {
    minWidth: '100px !important',
    fontSize: '$12 !important',
    lineHeight: '$20 !important',
    borderRadius: '$4 !important',
    py: '$1 !important',
  },
});

export const StyledSuccessMessage = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  p: '$2',
  borderRadius: '$6',
  backgroundColor: '$greenPrimary',
});

export const StyledErrorText = styled(Text, {
  color: '$redPrimary !important',
  fontSize: '14px',
  lineHeight: '20px',
  '@lg_xl': {
    fontSize: '16px',
    lineHeight: '24px',
  },
  '@xs': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});
