import { useMedia } from '~hooks/useMedia';

export const useSearchModalParams = () => {
  const { isMobile, isDesktop, isLargeDesktop } = useMedia();

  let popularSearchesSlidesPerView;

  switch (true) {
    case isMobile:
      popularSearchesSlidesPerView = 3;
      break;
    case isDesktop || isLargeDesktop:
      popularSearchesSlidesPerView = 8;
      break;
    default:
      popularSearchesSlidesPerView = 6;
      break;
  }

  const popularSearchesSlideHeight = isMobile ? 104 : 136;

  let mostLikedSlidesPerView;

  switch (true) {
    case isMobile:
      mostLikedSlidesPerView = 1.5;
      break;
    case isDesktop || isLargeDesktop:
      mostLikedSlidesPerView = 4;
      break;
    default:
      mostLikedSlidesPerView = 3;
      break;
  }

  let mostLikedSearchesSlideHeight;

  switch (true) {
    case isMobile:
      mostLikedSearchesSlideHeight = 104;
      break;
    case isDesktop || isLargeDesktop:
      mostLikedSearchesSlideHeight = 148;
      break;
    default:
      mostLikedSearchesSlideHeight = 136;
      break;
  }

  return {
    popularSearchesSlidesPerView,
    popularSearchesSlideHeight,
    mostLikedSlidesPerView,
    mostLikedSearchesSlideHeight,
  };
};
