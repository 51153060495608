import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { QUERY_PARAMS } from '~constants/common';
import { languagesCode, reversedLanguagesCode } from '~constants/language';
import {
  useCasinoDataLoad,
  useLaunchEventsSocket,
  useListenEventsLoadingSocket,
  useQueryParams,
  useRouterQuery,
  useShare,
  useWebsiteSettings,
} from '~hooks';
import { useIframeAuthorization } from '~hooks/useIframeAuthorization';
import { useInitTranslation } from '~hooks/useInitTranslations';
import { useListenTokens } from '~hooks/useListenTokens';
import { useLoadBannerGroups } from '~hooks/useLoadBannerGroups';
import { useLoadFavouriteMarkets } from '~hooks/useLoadFavouriteMarkets';
import { useLoadSettings } from '~hooks/useLoadSettings';
import { useLoadUserLimits } from '~hooks/useLoadUserLimits';
import { useLoadUserProfile } from '~hooks/useLoadUserProfile';
import { useSetupPlainSockets } from '~hooks/useSetupPlainSockets';
import { useSignalRSockets } from '~hooks/useSignalRSockets';
import { useTransactionsUpdate } from '~hooks/useTransactionsUpdate';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { login } from '~store/slices/userSlice';
import { COOKIES_NAMES, getCookie } from '~utils/cookies';
import { getTheme } from '~utils/getTheme';
import { QueryParams } from '~utils/url';

type AppInitDataType = {
  isAuthStatusConfirmed: boolean;
};

export const useOnAppInit = () => {
  const dispatch = useAppDispatch();
  const { onLanguageChange, language } = useWebsiteSettings();
  const { updateQueryParams } = useRouterQuery();
  const [appInitData, setAppInitData] = useState<AppInitDataType>({
    isAuthStatusConfirmed: false, // auth status is confirmed and profile data is already received (if user is logged in)

    // ...add new parameters that should be confirmed before the content render
  });
  const { verifyEmailToken, lang } = useQueryParams();

  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const { loadProfile } = useLoadUserProfile();

  const updateAppInitData = (updatedValue: Partial<AppInitDataType>) => {
    setAppInitData((prevState) => ({
      ...prevState,
      ...updatedValue,
    }));
  };

  const isAppInitDataReady = useMemo(
    () => Object.values(appInitData).every((v) => v),
    [appInitData],
  );

  useCasinoDataLoad();
  useIframeAuthorization();
  useShare();
  useLaunchEventsSocket();
  useListenEventsLoadingSocket();
  useTransactionsUpdate();
  useListenTokens();
  useSignalRSockets();

  useEffect(() => {
    const path = window.location.pathname;

    if (path.startsWith('//')) {
      window.location.href = '/notFound';
    }

    if (lang) {
      onLanguageChange(reversedLanguagesCode[lang]);
    }

    getTheme();
  }, []);

  const loginUser = async () => {
    const accessToken = getCookie(COOKIES_NAMES.ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      await loadProfile();
      dispatch(login());
    }

    updateAppInitData({ isAuthStatusConfirmed: true });
  };

  useLayoutEffect(() => {
    loginUser();
  }, []);

  useEffect(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken]);

  useEffect(() => {
    if (!lang)
      updateQueryParams({
        [QUERY_PARAMS.LANG]: languagesCode[language],
      } as QueryParams<typeof QUERY_PARAMS>);
  }, [lang]);

  useSetupPlainSockets();
  useLoadBannerGroups();
  useInitTranslation();
  useLoadSettings(setIsSettingsLoaded);
  useLoadUserLimits(isSettingsLoaded);
  useLoadFavouriteMarkets();

  return { isAppInitDataReady };
};
