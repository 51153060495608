import { TOptions } from 'i18next';

import { useAppSelector } from '~store';
import { selectTranslationFunction } from '~store/slices/translationSlice';

export const useTranslation = () => {
  const translations = useAppSelector(selectTranslationFunction);

  const localized = (key: string, args?: object) =>
    translations(key, args as TOptions);

  const localizedError = (key: string, args?: object) =>
    translations(key, { ns: 'error', ...args });

  return { translations, localized, localizedError };
};
