import { ProviderIcon } from '~components/atoms/ProviderCard/ProviderIcon';
import { ProviderCardWrapper } from '~components/atoms/ProviderCard/styled.components';
import { Text } from '~components/atoms/Typography';
import { PROVIDERS_MAP } from '~constants/providers';
import { useMedia } from '~hooks';
import { useAppDispatch } from '~store';
import { setSelectedProviderId } from '~store/slices/gamesSlice';
import { Provider } from '~types/providers';

interface ProviderCardProps extends Provider {
  active?: boolean;
  onClick?: (provider: Provider) => void;
}

export const ProviderCard = ({
  active,
  name,
  id,
  gameCount,
  onClick,
}: ProviderCardProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useMedia();

  const handleProviderClick = (providerId: number) => {
    if (onClick) {
      onClick({ id: providerId, name, gameCount } as Provider);

      return;
    }

    dispatch(setSelectedProviderId(providerId));
  };

  return (
    <ProviderCardWrapper
      active={active}
      onClick={() => handleProviderClick(id)}
    >
      <ProviderIcon
        active={active}
        iconPath={PROVIDERS_MAP[id]?.iconPath}
        providerName={PROVIDERS_MAP[id]?.name}
      />
      <Text level={isMobile ? '11-16' : '12-16'} as="span">
        {name}
      </Text>
      <Text
        className="games-count"
        level={isMobile ? '11-16' : '12-16'}
        as="span"
      >
        {gameCount}
      </Text>
    </ProviderCardWrapper>
  );
};
