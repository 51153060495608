import React from 'react';

import { Box } from '~components/atoms/Box';
import { SportEventsMainData } from '~types/events';

import { useEventList } from '../hooks/useEventListHook';

import { EventListMenuItem } from './EventListMenuItem';

export interface EventListProps {
  events: SportEventsMainData;
}

export const EventListMenu = ({ events }: EventListProps) => {
  const { handleFavoriteClick, handleLoadEventData } = useEventList(events);

  return (
    <Box flexCol gap={1}>
      {events.map((eventData) => {
        const isEventFavorite = !!events?.find((event) => {
          return event.id === eventData.id;
        });

        return (
          <EventListMenuItem
            key={eventData.id}
            event={eventData}
            isFavorite={isEventFavorite}
            handleFavoriteClick={handleFavoriteClick}
            handleLoadEventData={handleLoadEventData}
          />
        );
      })}
    </Box>
  );
};
