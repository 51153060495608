import { CLIENTS } from '~constants/clients';
import { CountryName } from '~constants/flag';
import { getTenant } from '~utils/getTenant';

interface LanguageDetails {
  value: LANGUAGE_TYPES;
  label: string;
  flag: CountryName | null;
  title: string;
}

export enum LANGUAGE_TYPES {
  English = 1,
  French = 2,
  Spanish = 3,
  Portuguese = 4,
  Hindi = 5,
  Farsi = 6,
  Russian = 8,
  Arabic = 9,
  Kurdish = 31,
}

export const languagesCode: Record<LANGUAGE_TYPES, string> = {
  [LANGUAGE_TYPES.English]: 'en',
  [LANGUAGE_TYPES.French]: 'fr',
  [LANGUAGE_TYPES.Spanish]: 'es',
  [LANGUAGE_TYPES.Portuguese]: 'pt',
  [LANGUAGE_TYPES.Hindi]: 'hi',
  [LANGUAGE_TYPES.Farsi]: 'fa',
  [LANGUAGE_TYPES.Arabic]: 'ar',
  [LANGUAGE_TYPES.Kurdish]: 'ku',
  [LANGUAGE_TYPES.Russian]: 'ru',
};

export const reversedLanguagesCode = Object.fromEntries(
  Object.entries(languagesCode).map(([key, value]) => [value, key]),
);

const LANGUAGES_MAP: Record<LANGUAGE_TYPES, LanguageDetails> = {
  [LANGUAGE_TYPES.English]: {
    value: LANGUAGE_TYPES.English,
    label: 'English',
    flag: 'England',
    title: languagesCode[LANGUAGE_TYPES.English],
  },
  [LANGUAGE_TYPES.French]: {
    value: LANGUAGE_TYPES.French,
    label: 'French',
    flag: 'France',
    title: languagesCode[LANGUAGE_TYPES.French],
  },
  [LANGUAGE_TYPES.Spanish]: {
    value: LANGUAGE_TYPES.Spanish,
    label: 'Spanish',
    flag: 'Spain',
    title: languagesCode[LANGUAGE_TYPES.Spanish],
  },
  [LANGUAGE_TYPES.Portuguese]: {
    value: LANGUAGE_TYPES.Portuguese,
    label: 'Portuguese',
    flag: 'Portugal',
    title: languagesCode[LANGUAGE_TYPES.Portuguese],
  },
  [LANGUAGE_TYPES.Hindi]: {
    value: LANGUAGE_TYPES.Hindi,
    label: 'Hindi',
    flag: 'India',
    title: languagesCode[LANGUAGE_TYPES.Hindi],
  },
  [LANGUAGE_TYPES.Farsi]: {
    value: LANGUAGE_TYPES.Farsi,
    label: 'Farsi',
    flag: 'Iran',
    title: languagesCode[LANGUAGE_TYPES.Farsi],
  },
  [LANGUAGE_TYPES.Arabic]: {
    value: LANGUAGE_TYPES.Arabic,
    label: 'Arabic',
    flag: 'United Arab Emirates',
    title: languagesCode[LANGUAGE_TYPES.Arabic],
  },
  [LANGUAGE_TYPES.Kurdish]: {
    value: LANGUAGE_TYPES.Kurdish,
    label: 'Kurdish',
    flag: null,
    title: languagesCode[LANGUAGE_TYPES.Arabic],
  },
  [LANGUAGE_TYPES.Russian]: {
    value: LANGUAGE_TYPES.Russian,
    label: 'Russian',
    flag: 'Russia',
    title: languagesCode[LANGUAGE_TYPES.Russian],
  },
};

const getLanguageByClients = () => {
  const tenant = getTenant();

  switch (tenant) {
    case CLIENTS.BET_ONLY:
      return [];
    case CLIENTS.BETPRAZER:
      return [];
    case CLIENTS.OTBET:
      return [];
    case CLIENTS.SEMA_BET:
      return [];
    case CLIENTS.HALABET:
      return [
        LANGUAGES_MAP[LANGUAGE_TYPES.English],
        LANGUAGES_MAP[LANGUAGE_TYPES.Arabic],
        LANGUAGES_MAP[LANGUAGE_TYPES.Kurdish],
        LANGUAGES_MAP[LANGUAGE_TYPES.Russian],
      ];
    case CLIENTS.NONE:
      return [];
    default:
      return [];
  }
};

export const LANGUAGES = Object.values(getLanguageByClients());
