import { memo, ReactNode } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useAppSelector } from '~store';
import { selectEventsCount } from '~store/slices/selectors/betslip';

interface BetslipTabItemProps {
  children: ReactNode;
}
export const BetslipTabItem = memo(({ children }: BetslipTabItemProps) => {
  const count = useAppSelector(selectEventsCount);

  return (
    <>
      {!!count && (
        <Box
          css={{
            position: 'absolute',
            left: '50%',
            bottom: '3px',
            transform: 'translateX(-50%)',
            width: '$5',
            backgroundColor: '$accentPrimaryOne',
            borderRadius: '$2',
          }}
        >
          <Text isButton level="12-16" fontWeight="bold" textAlign="center">
            {count}
          </Text>
        </Box>
      )}
      {children}
    </>
  );
});
