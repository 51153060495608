export enum LIVE_GROUP_COMPONENTS {
  FAVORITE_EVENT = 'favoriteEvent',
  TOP_EVENT = 'topEvent',
}

export const noDetailsStatuses = [
  'Break_1',
  'Break_2',
  'Break_3',
  'Break_N',
  'Halftime',
  'Awaiting_Extra_Time',
  'Awaiting_Penalties',
  'Penalties',
  'Ended',
  'Not_Started',
];
