import React, { useMemo } from 'react';

import { MainMarket } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { MARKETS_GAP } from '~constants/mainPage';
import { useMedia } from '~hooks/useMedia';

import { determineMainMarketWidth } from './helpers';

interface MainMarketNamesListProps {
  mainMarkets: MainMarket[];
}

export const MainMarketNamesList = ({
  mainMarkets,
}: MainMarketNamesListProps) => {
  const { isLaptop } = useMedia();

  const mainMarketsItems = useMemo(
    () =>
      mainMarkets.map((mainMarket) => {
        const width = determineMainMarketWidth(mainMarket, isLaptop);
        const { shortName, name, id } = mainMarket;

        const resMarketName = name.length > 15 ? shortName : name;

        return (
          <Box
            key={id}
            css={{
              width,
            }}
            flexRow
            alignCenter
            justifyCenter
          >
            <Text level="16-20" textTransform="uppercase">
              {resMarketName}
            </Text>
          </Box>
        );
      }),

    [mainMarkets, isLaptop],
  );

  return (
    <Box flexRow alignCenter css={{ gap: `${MARKETS_GAP}px` }}>
      {mainMarketsItems}
    </Box>
  );
};
