import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks';

const CARD_IMAGES = [
  {
    name: 'visa',
    src: '/visa.png',
  },
  {
    name: 'mastercard',
    src: '/mastercard.png',
  },
  {
    name: 'verve',
    src: '/verve.png',
  },
];

export const PaymentsBy = () => {
  const { localized } = useTranslation();

  return (
    <Box
      flexRow
      fullWidth
      alignCenter
      justifyContentBetween
      gap={5}
      css={{
        border: '1px solid $secondaryTwo',
        borderRadius: '$10',
        padding: '$4 54px',
        mt: '$5',
        position: 'relative',
      }}
    >
      <Text
        css={{
          p: '$0 $3',
          position: 'absolute',
          transform: 'translateX(-50%)',
          left: '50%',
          top: -10,
          background: '$primaryTwo',
        }}
        level={'12-16'}
      >
        {localized('payments.paymentsBy')}
      </Text>
      {CARD_IMAGES.map(({ name, src }) => (
        <img src={src} alt={name} key={name} />
      ))}
    </Box>
  );
};
