import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AUTH_FIELD_TYPE } from '~api/partner/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Checkbox } from '~components/atoms/Checkbox';
import { Input } from '~components/atoms/Input';
import { LabelWithOppositeText } from '~components/atoms/LabelWithOppositeText';
import { PhoneNumberField } from '~components/atoms/PhoneNumberField';
// import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { USERNAME_MAX_LIMIT, USERNAME_MIN_LIMIT } from '~constants/auth';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { EyeIcon, EyeSlashIcon } from '~icons';
import { WaBetonly, WaSemabet } from '~icons/shareSocialMedias';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { selectIsUganda } from '~store/slices/selectors/settings';
import { validateEmail } from '~utils/validateEmail';

import {
  SIGNIN_FORM_FIELDS,
  SigninFormInputs,
  SIGNUP_FORM_FIELDS,
} from '../../types';

// import { WhatsappLoginButton } from '../WhatsappLoginButton';
import { SignInTabList } from './SignInTabList';

interface SignInTabProps {
  isLoginLoading: boolean;
  onRegisterUser: () => void;
  rememberMe: boolean;
  onRememberMeChange: (value: boolean) => void;
  signInButtonRef: React.RefObject<HTMLButtonElement>;
  isValidationError?: string;
  handleSignInWithWhatsApp: () => void;
  isSigningUserLoading: boolean;
}

export const SignInTab = ({
  isLoginLoading,
  onRegisterUser,
  handleSignInWithWhatsApp,
  isSigningUserLoading,
  rememberMe,
  onRememberMeChange,
  signInButtonRef,
  isValidationError,
}: SignInTabProps) => {
  const { isMobileOrTablet } = useMedia();
  const [isPressed, setIsPressed] = useState(false);
  const [showWhatsapp] = useState(false);
  const dispatch = useAppDispatch();
  const isUganda = useAppSelector(selectIsUganda);
  const { authField } = useAppSelector((state) => state.settings);
  const { register, formState, setValue, clearErrors, watch } =
    useFormContext<SigninFormInputs>();
  const { errors } = formState;
  const { localized, localizedError } = useTranslation();

  const { phoneNumberField } = watch();

  const [showPassword, setShowPassword] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    clearErrors(SIGNIN_FORM_FIELDS.USERNAME_FIELD);
    clearErrors('root');
    setValue(SIGNIN_FORM_FIELDS.USERNAME_FIELD, inputValue);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    clearErrors(SIGNIN_FORM_FIELDS.PASSWORD_FIELD);
    clearErrors('root');
    setValue(SIGNIN_FORM_FIELDS.PASSWORD_FIELD, inputValue);
  };

  const handleResetPasswordOpen = () => {
    dispatch(openDialog(DIALOGS.RESET_PASSWORD));
  };

  const formContent = showWhatsapp ? (
    <>
      <Box
        css={{
          mb: '$3',
        }}
      >
        <LabelWithOppositeText
          htmlFor={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
          oppositeText={errors.phoneNumberField?.message || isValidationError}
          error={!!errors.phoneNumberField || !!isValidationError}
        >
          {localized('signUp.fields.phoneNumber.label')}
        </LabelWithOppositeText>
        <PhoneNumberField
          phoneNumberId={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
          phoneNumber={phoneNumberField}
          setPhoneNumber={(value) => {
            setValue(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD, value);
          }}
          setValue={(value) =>
            setValue(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, value)
          }
          isValidPhoneNumber={isValidPhoneNumber}
          setIsValidPhoneNumber={setIsValidPhoneNumber}
          isLoginForm
          {...register(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, {
            required: localizedError('phoneNumber.shouldBeNotEmpty'),
          })}
        />
      </Box>
      <Button
        fullWidth
        onClick={handleSignInWithWhatsApp}
        isLoading={isSigningUserLoading}
        css={{
          marginTop: '$5',
          gap: '$1',
          alignItems: 'center',
          backgroundColor: '$greenPrimary',
          color: '$textPrimaryOne',
        }}
      >
        {isUganda ? <WaSemabet /> : <WaBetonly />}
        {localized('signUp.getLink')}
      </Button>
      <Box
        css={{
          mt: '$4',
        }}
        flexRow={!isMobileOrTablet}
        flexCol={isMobileOrTablet}
        justifyCenter
        gap={1}
        alignCenter
      >
        <Text level="16-24" textTransform="uppercase" textAlign="center">
          {localized('signIn.dontHaveAccount')}
        </Text>
        <Text
          level="16-24"
          textTransform="uppercase"
          textAlign="center"
          underline
          color="greenPrimary"
          fontWeight={isPressed ? 'bold' : 'normal'}
          css={{
            cursor: 'pointer',
            '&:hover': {
              opacity: '0.7',
            },
          }}
          onMouseDown={() => setIsPressed(true)}
          onMouseUp={() => setIsPressed(false)}
          onClick={onRegisterUser}
        >
          {localized('buttons.signUp')}
        </Text>
      </Box>
    </>
  ) : (
    <>
      {/* {isUganda && (
        <>
          <WhatsappLoginButton
            showWhatsapp={showWhatsapp}
            setShowWhatsapp={setShowWhatsapp}
          />
          <Separator verticalSpace={6} shrinkOut={isMobile ? 4 : 7} />
        </>
      )} */}

      <Box
        css={{
          position: 'relative',
        }}
      >
        {/* {isUganda && (
          <>
            <Text
              css={{
                p: '$0 $3',
                position: 'absolute',
                transform: 'translateX(-50%)',
                left: '50%',
                top: -36,
                background: '$primaryOne',
                whiteSpace: 'nowrap',
              }}
              color="textPrimaryOne"
              level={'16-24'}
            >
              {localized('signUp.or')}
            </Text>
          </>
        )} */}
      </Box>
      {authField === AUTH_FIELD_TYPE.USER_NAME && (
        <Box
          css={{
            position: 'relative',
            mb: '$3',
          }}
        >
          <LabelWithOppositeText
            htmlFor={SIGNIN_FORM_FIELDS.USERNAME_FIELD}
            oppositeText={
              errors.root?.wrongCredentials &&
              errors.root.wrongCredentials.message
            }
            error={!!errors.root?.wrongCredentials}
          >
            {localized('signIn.fields.username.label')}
          </LabelWithOppositeText>
          <Input
            id={SIGNIN_FORM_FIELDS.USERNAME_FIELD}
            placeholder={localized('signIn.fields.username.label')}
            {...register(SIGNIN_FORM_FIELDS.USERNAME_FIELD, {
              validate: {
                hasInvalidCharactersNumber: (value) =>
                  (value.length <= USERNAME_MAX_LIMIT &&
                    value.length >= USERNAME_MIN_LIMIT) ||
                  localizedError('username.mustBeMinToMaxCharsLong', {
                    min: USERNAME_MIN_LIMIT,
                    max: USERNAME_MAX_LIMIT,
                  }),
              },
            })}
            onChange={handleUserNameChange}
            css={{
              color:
                !errors.usernameField && !errors.root
                  ? '$textPrimaryOne'
                  : '$redPrimary',
              backgroundColor: '$secondaryTwo',
            }}
          />
        </Box>
      )}
      {authField === AUTH_FIELD_TYPE.PHONE_NUMBER && (
        <Box
          css={{
            mb: '$3',
          }}
        >
          <LabelWithOppositeText
            htmlFor={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
            oppositeText={errors.phoneNumberField?.message || isValidationError}
            error={!!errors.phoneNumberField || !!isValidationError}
          >
            {localized('signUp.fields.phoneNumber.label')}
          </LabelWithOppositeText>
          <PhoneNumberField
            phoneNumberId={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
            phoneNumber={phoneNumberField}
            setPhoneNumber={(value) => {
              setValue(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD, value);
            }}
            setValue={(value) =>
              setValue(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, value)
            }
            isValidPhoneNumber={isValidPhoneNumber}
            setIsValidPhoneNumber={setIsValidPhoneNumber}
            isLoginForm
            {...register(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, {
              required: localizedError('phoneNumber.shouldBeNotEmpty'),
            })}
          />
        </Box>
      )}
      {authField === AUTH_FIELD_TYPE.EMAIL && (
        <Box
          css={{
            position: 'relative',
            mb: '$3',
          }}
        >
          <LabelWithOppositeText
            htmlFor={SIGNIN_FORM_FIELDS.EMAIL_FIELD}
            oppositeText={
              errors.root?.wrongCredentials &&
              errors.root.wrongCredentials.message
            }
            error={!!errors.root?.wrongCredentials}
          >
            {localized('signIn.fields.email.label')}
          </LabelWithOppositeText>
          <Input
            id={SIGNIN_FORM_FIELDS.EMAIL_FIELD}
            placeholder={localized('signIn.fields.email.placeholder')}
            {...register(SIGNIN_FORM_FIELDS.EMAIL_FIELD, {
              validate: {
                hasInvalidCharacter: (value) =>
                  validateEmail(value) ||
                  localizedError('email.hasInvalidCharacter'),
              },
            })}
            onChange={({ target: { value } }) =>
              setValue(SIGNIN_FORM_FIELDS.EMAIL_FIELD, value)
            }
            css={{
              color:
                !errors.emailField && !errors.root
                  ? '$textPrimaryOne'
                  : '$redPrimary',
              backgroundColor: '$secondaryTwo',
            }}
          />
        </Box>
      )}
      <Box
        css={{
          width: '100%',
          mb: '28px',
        }}
      >
        <LabelWithOppositeText
          htmlFor={SIGNIN_FORM_FIELDS.PASSWORD_FIELD}
          oppositeText={
            errors?.passwordField?.message &&
            localizedError(errors.passwordField.message)
          }
          error={!!errors?.passwordField?.message}
        >
          {localized('signIn.fields.password.label')}
        </LabelWithOppositeText>
        <Box css={{ position: 'relative' }}>
          <Input
            id={SIGNIN_FORM_FIELDS.PASSWORD_FIELD}
            placeholder={localized('signIn.fields.password.placeholder')}
            type={showPassword ? 'text' : 'password'}
            {...register(SIGNIN_FORM_FIELDS.PASSWORD_FIELD, {
              required: localizedError('password.shouldBeNotEmpty'),
            })}
            onChange={handlePasswordChange}
            css={{
              color:
                !errors.passwordField && !errors.root
                  ? '$textPrimaryOne'
                  : '$redPrimary',
              backgroundColor: '$secondaryTwo',
            }}
          />
          <Box
            shouldHover
            css={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              p: '$2 $3',
              cursor: 'pointer',
              color: '$textSecondaryOne',
            }}
            role="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
          </Box>
        </Box>
      </Box>
      <Box flexRow justifyContentBetween alignCenter>
        <Checkbox
          checked={rememberMe}
          label={localized('rememberMe')}
          defaultChecked={rememberMe}
          onCheckedChange={(checked) => {
            onRememberMeChange(checked as boolean);
          }}
        />
        <Text
          isLink
          level="md-3"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="medium"
          onClick={handleResetPasswordOpen}
          underline
        >
          {localized('links.forgotPassword')}
        </Text>
      </Box>

      <Button
        fullWidth
        type="submit"
        isLoading={isLoginLoading}
        ref={signInButtonRef}
        css={{
          marginTop: '28px',
        }}
      >
        {localized('buttons.signIn')}
      </Button>
      <Box
        css={{
          mt: '$4',
        }}
        flexRow={!isMobileOrTablet}
        flexCol={isMobileOrTablet}
        justifyCenter
        gap={1}
        alignCenter
      >
        <Text level="16-24" textTransform="uppercase" textAlign="center">
          {localized('signIn.dontHaveAccount')}
        </Text>
        <Text
          isLink
          level="16-24"
          textTransform="uppercase"
          textAlign="center"
          underline
          fontWeight="bold"
          onMouseDown={() => setIsPressed(true)}
          onMouseUp={() => setIsPressed(false)}
          onClick={onRegisterUser}
        >
          {localized('buttons.signUp')}
        </Text>
      </Box>
    </>
  );

  const formTitle = showWhatsapp ? (
    <>
      <Text
        textAlign="center"
        textTransform="uppercase"
        fontWeight="medium"
        css={{
          fontSize: '$xl',
          lineHeight: '$44',
          mb: '$1',
          display: 'flex',
          gap: '$2',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {localized('signIn.whatsappTitle')}
        <Text
          textAlign="center"
          textTransform="uppercase"
          fontWeight="medium"
          color="greenPrimary"
          css={{
            fontSize: '$xl',
            lineHeight: '$44',
          }}
        >
          WhatsApp
        </Text>
      </Text>
      <Text
        level="md-3"
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
      >
        {localized('signIn.whatsappSubTitle')}
      </Text>
      <Text
        level="md-3"
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
      >
        {localized('signIn.whatsappSubTitle2')}
      </Text>
    </>
  ) : (
    <>
      <Text
        textAlign="center"
        textTransform="uppercase"
        fontWeight="medium"
        css={{
          fontSize: '$xxl',
          lineHeight: '$44',
          mb: '$1',
        }}
      >
        {localized('signIn.title')}
      </Text>
      <Text
        level="md-3"
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
      >
        {localized('signIn.subTitle')}
      </Text>
    </>
  );

  return (
    <>
      {formTitle}
      <SignInTabList />
      {formContent}
    </>
  );
};
