import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const ProviderCardWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  height: 'max-content',

  // Text styles
  span: {
    '&.games-count': {
      color: '$textSecondaryOne',
    },

    '@xl': {
      fontSize: '$4 !important',
      lineHeight: '$28 !important',
    },
    '@lg': {
      fontSize: '14px !important',
      lineHeight: '$20 !important',
    },
    '@xs': {
      fontSize: '11px !important',
      width: 'min-content !important',
    },
  },

  '&:hover': {
    cursor: 'pointer',
    span: {
      color: '$hoverPrimary',
      '&.games-count': {
        color: '$hoverPrimary',
      },
    },
  },

  '&:hover > .providerIcon': {
    border: '1px solid $hoverPrimary !important',
  },

  // Selected provider text styles
  variants: {
    active: {
      true: {
        span: {
          color: '$accentPrimaryTwo',

          '&.games-count': {
            color: '$accentPrimaryTwo',
          },
        },
      },
    },
  },
});
