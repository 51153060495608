import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '~store';
import {
  setBetslipErrors,
  setDuplicatedMultipleBetStakes,
} from '~store/slices/betslipSlice';
import { USER_DEFAULT_BALANCE } from '~store/slices/userSlice';
import {
  getBetTotalAmount,
  isValidStakeMax,
  isValidStakeMin,
} from '~utils/betslip';
import { isEmptyObject } from '~utils/objectHelpers';

import {
  BETSLIP_ERRORS,
  BETSLIP_VALUES_MAP_OPTIONS,
  MIN_SYSTEM_BET_EVENTS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../constants';

export const useBetslipErrors = (isQuickBet: boolean = false) => {
  const dispatch = useAppDispatch();
  const {
    aggregatedBetAmount,
    events,
    stakeType,
    singleBetsAmountMap,
    balanceChangesOnBetData,
    stakePossibleWinLoadingMap,
    systemBetOption,
    balanceChangesMap,
  } = useAppSelector((state) => state.betslip);
  const { partnerLimits, separateBonusBalance } = useAppSelector(
    (state) => state.settings,
  );
  const { isUserLoggedIn, defaultBalance } = useAppSelector(
    (state) => state.userState,
  );

  const resErrors: BETSLIP_ERRORS[] = [];

  const possibleWinIsLoading = !isEmptyObject(stakePossibleWinLoadingMap);
  const isMultipleBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE;
  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;

  const isBonusBalanceSelected =
    !separateBonusBalance && defaultBalance === USER_DEFAULT_BALANCE.BONUS;

  const {
    isBalanceSufficient,
    isBonusBalanceSufficient,
    totalActiveBonusesCount = 0,
  } = balanceChangesOnBetData || {};

  // Main hook that checks all the limits and conditions for the betslip
  useEffect(() => {
    if (possibleWinIsLoading) return;

    // If there are no events in the betslip, clear the errors
    if (!events.length) {
      dispatch(setBetslipErrors([]));

      return;
    }

    // User Login
    if (!isUserLoggedIn) {
      dispatch(setBetslipErrors([BETSLIP_ERRORS.LOGIN_TO_BET]));

      return;
    }

    // Check user balance
    const notValidBalance =
      isBalanceSufficient !== undefined &&
      !isBalanceSufficient &&
      isUserLoggedIn;

    if (notValidBalance) {
      dispatch(setBetslipErrors([BETSLIP_ERRORS.LOW_BALANCE]));

      return;
    }

    if (isMultipleBet || isSystemBet) {
      const betTotalAmount = getBetTotalAmount(
        aggregatedBetAmount,
        systemBetOption,
      );
      const { minStakeLimit = 0, maxStakeLimit = 0 } =
        balanceChangesMap[BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED] || {};

      // Minimum number of selections
      if (checkSelectionCountErrors()) {
        dispatch(setBetslipErrors(resErrors));

        return;
      }

      // Minimum/Maximum stake amount
      if (
        checkAggregatedBetHasLimitErrors(
          resErrors,
          betTotalAmount,
          minStakeLimit,
          maxStakeLimit,
        )
      ) {
        dispatch(setBetslipErrors(resErrors));

        return;
      }

      // Duplicate events selections
      if (checkHasDuplicateErrors()) {
        dispatch(setBetslipErrors(resErrors));

        return;
      }

      // Bonus balance sufficient and rules are met
      if (checkAggregatedBetHasBonusErrors()) {
        dispatch(setBetslipErrors(resErrors));

        return;
      }
    } else {
      // Minimun/Maximum stake amount
      if (checkSingleBetHasLimitErrors()) {
        dispatch(setBetslipErrors(resErrors));

        return;
      }

      // Bonus balance sufficient and rules are met
      if (checkSingleBetHasBonusErrors()) {
        dispatch(setBetslipErrors(resErrors));

        return;
      }
    }

    dispatch(setBetslipErrors(resErrors));
  }, [
    aggregatedBetAmount,
    singleBetsAmountMap,
    events,
    stakeType,
    isUserLoggedIn,
    isBalanceSufficient,
    isBonusBalanceSufficient,
    totalActiveBonusesCount,
    possibleWinIsLoading,
    partnerLimits,
  ]);

  const checkHasDuplicateErrors = () => {
    const seenIds: Record<string, boolean> = {};
    const duplicates = events
      .filter(({ eventId }) => {
        if (seenIds[eventId]) {
          return true;
        }

        seenIds[eventId] = true;

        return false;
      })
      .map(({ eventId }) => eventId);

    if (duplicates.length) {
      resErrors.push(BETSLIP_ERRORS.NOT_COMBINABLE);
      dispatch(setDuplicatedMultipleBetStakes(duplicates));

      return true;
    } else {
      dispatch(setDuplicatedMultipleBetStakes([]));
    }

    return false;
  };

  const checkSelectionCountErrors = () => {
    let hasErrors = false;

    const { sportMinSelectionCount, sportMaxSelectionCount } = partnerLimits;

    if (
      sportMinSelectionCount > 0 &&
      isMultipleBet &&
      events.length < sportMinSelectionCount
    ) {
      resErrors.push(BETSLIP_ERRORS.MIN_SELECTIONS);
      hasErrors = true;
    }

    if (isSystemBet && events.length < MIN_SYSTEM_BET_EVENTS) {
      resErrors.push(BETSLIP_ERRORS.MIN_SELECTIONS);
      hasErrors = true;
    }

    if (sportMaxSelectionCount > 0 && events.length > sportMaxSelectionCount) {
      resErrors.push(BETSLIP_ERRORS.MAX_SELECTIONS);
      hasErrors = true;
    }

    return hasErrors;
  };

  const checkAggregatedBetHasLimitErrors = (
    resErrors: string[],
    totalAmount: number,
    minStakeLimit: number,
    maxStakeLimit: number,
  ) => {
    let hasErrors = false;

    if (!isValidStakeMin(totalAmount, minStakeLimit)) {
      resErrors.push(BETSLIP_ERRORS.MIN_STAKE);
      hasErrors = true;
    }

    if (!isValidStakeMax(totalAmount, maxStakeLimit)) {
      resErrors.push(BETSLIP_ERRORS.MAX_STAKE);
      hasErrors = true;
    }

    return hasErrors;
  };

  const checkSingleBetHasLimitErrors = () => {
    let hasErrors = false;

    const selectionsIds = events.map(({ selectionId }) => selectionId);
    const isTotalStake = Object.values(singleBetsAmountMap).some(
      ({ main = 0, bonus = 0 }) => Number(main) + Number(bonus) > 0,
    );

    const isAnyStakeTooLow = selectionsIds.some((id) => {
      const totalAmount = getBetTotalAmount(singleBetsAmountMap[id]!);
      const { minStakeLimit } = balanceChangesMap[id] || {};

      const isValid = isValidStakeMin(totalAmount, minStakeLimit);

      return !isValid;
    });

    const isAnyStakeTooHigh = selectionsIds.some((id) => {
      const totalAmount = getBetTotalAmount(singleBetsAmountMap[id]!);
      const { maxStakeLimit } = balanceChangesMap[id] || {};

      const isValid = isValidStakeMax(totalAmount, maxStakeLimit);

      return !isValid;
    });

    if (
      (isAnyStakeTooHigh || isAnyStakeTooLow) &&
      isTotalStake &&
      !isQuickBet
    ) {
      resErrors.push(BETSLIP_ERRORS.NOT_MEET_REQUIREMENTS);
      hasErrors = true;
    }

    if (isQuickBet && isAnyStakeTooHigh && isTotalStake) {
      resErrors.push(BETSLIP_ERRORS.MAX_STAKE);
      hasErrors = true;
    }

    if (isQuickBet && isAnyStakeTooLow && isTotalStake) {
      resErrors.push(BETSLIP_ERRORS.MIN_STAKE);
      hasErrors = true;
    }

    return hasErrors;
  };

  const calculateBonusAmount = (main?: string, bonus?: string): number => {
    if (separateBonusBalance) return Number(bonus || 0);
    if (isBonusBalanceSelected) return Number(main || 0);

    return 0;
  };

  const checkAggregatedBetHasBonusErrors = () => {
    const { main, bonus } = aggregatedBetAmount;
    const { matchedBonusRules = 0 } =
      balanceChangesMap[BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED] || {};

    const bonusAmount = calculateBonusAmount(main, bonus);

    return checkHasBonusErrors(matchedBonusRules, bonusAmount);
  };

  const checkSingleBetHasBonusErrors = () => {
    return Object.entries(singleBetsAmountMap).some(
      ([selectionId, { main, bonus }]) => {
        const balanceChanges = balanceChangesMap[selectionId];

        if (!balanceChanges) return false;

        const bonusAmount = calculateBonusAmount(main, bonus);

        return checkHasBonusErrors(
          balanceChanges.matchedBonusRules,
          bonusAmount,
        );
      },
    );
  };

  /* X - totalActiveBonusesCount, Y - matchedBonusRules */
  const checkHasBonusErrors = (
    matchedBonusRules: number,
    bonusAmount: number,
  ) => {
    // If "isBonusBalanceSufficient" is true or bonus value not entered - further checks aren't needed
    if (bonusAmount === 0 || isBonusBalanceSufficient) {
      return false;
    }

    // isBonusBalanceSufficient - false, X === Y
    if (totalActiveBonusesCount === matchedBonusRules) {
      resErrors.push(BETSLIP_ERRORS.LOW_BONUS_BALANCE);

      return true;
    }

    // isBonusBalanceSufficient - false, Y > 0, X > Y
    if (matchedBonusRules > 0 && totalActiveBonusesCount > matchedBonusRules) {
      resErrors.push(BETSLIP_ERRORS.PARTIAL_WINBOOST_MET);

      return true;
    }

    // isBonusBalanceSufficient - false, X > 0, Y = 0
    if (totalActiveBonusesCount > 0 && matchedBonusRules === 0) {
      resErrors.push(BETSLIP_ERRORS.NOT_MET_WINBOOST);

      return true;
    }

    return false;
  };
};
