export const breakRegexp = /Break_\d/; // Break_{{N}}

export const periodRegexp = /Period_\d/; // Period_{{N}}

export const halfRegexp = /Half_\d/; // Half_{{N}}

export const setRegexp = /Set_\d/; // Set_{{N}}

export const quarterRegexp = /Quarter_\d/; // Quarter_{{N}}

export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
