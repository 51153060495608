import React, { createElement, forwardRef } from 'react';
import { styled } from 'stitches.config';

import { measurements } from '~styles/themes';

type BoxProps = {
  as?: React.ElementType;
  children?: React.ReactNode;
  gap?: keyof typeof measurements;
};

export const Box = styled(
  forwardRef(
    (
      { as = 'div', gap, ...rest }: BoxProps & JSX.IntrinsicElements['div'],
      ref,
    ) => {
      const resolvedGap =
        typeof gap === 'number' ? measurements[gap] : undefined;

      return createElement(as, {
        style: { gap: resolvedGap },
        ref,
        ...rest,
      });
    },
  ),
  {
    variants: {
      absoluteCenter: {
        true: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
      flexRow: {
        true: {
          display: 'flex',
        },
      },
      flexCol: {
        true: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
      fullWidth: { true: { width: '100%' } },
      fullHeight: { true: { height: '100%' } },
      alignCenter: {
        true: {
          alignItems: 'center',
        },
      },
      alignEnd: {
        true: {
          alignItems: 'flex-end',
        },
      },
      alignStart: {
        true: {
          alignItems: 'flex-start',
        },
      },
      justifyCenter: {
        true: {
          justifyContent: 'center',
        },
      },
      justifyContentEnd: {
        true: {
          justifyContent: 'flex-end',
        },
      },
      justifyContentStart: {
        true: {
          justifyContent: 'flex-start',
        },
      },
      justifyContentBetween: {
        true: {
          justifyContent: 'space-between',
        },
      },
      justifyContentAround: {
        true: {
          justifyContent: 'space-around',
        },
      },
      hidden: {
        true: {
          display: 'none',
        },
      },
      disabled: {
        true: {
          pointerEvents: 'none',
        },
      },
      ellipsis: {
        true: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
      shouldHover: {
        true: {
          '&:hover': {
            cursor: 'pointer',
            color: '$hoverPrimary !important',
            '*': {
              color: '$hoverPrimary !important',
            },
          },
          '&:active': {
            color: '$pressedOne !important',
            '*': {
              color: '$pressedOne !important',
            },
          },
        },
      },
    },
  },
);

Box.displayName = 'Box';
