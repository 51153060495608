import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MenuState {
  sportId?: string;
  leagueId?: string;
  eventId?: string;
  countryId?: string;
}

export interface MenusState {
  liveMenuState: MenuState | null;
  prematchMenuState: MenuState | null;
  breadcrumbLabel?: string | null;
}

const initialState: MenusState = {
  liveMenuState: null,
  prematchMenuState: null,
  breadcrumbLabel: null,
};

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    updateLiveMenuState: (state, action: PayloadAction<MenuState>) => {
      state.liveMenuState = action.payload;
    },
    updatePrematchMenuState: (state, action: PayloadAction<MenuState>) => {
      state.prematchMenuState = action.payload;
    },
    setBreadcrumbsLabel: (state, action: PayloadAction<string | null>) => {
      state.breadcrumbLabel = action.payload;
    },
  },
});

export const {
  updateLiveMenuState,
  updatePrematchMenuState,
  setBreadcrumbsLabel,
} = menusSlice.actions;

export default menusSlice.reducer;
