import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';

export const emailApi = createApi({
  reducerPath: 'emailApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.EMAIL }),
  endpoints: (builder) => ({
    subscribeEmail: builder.mutation<void, string>({
      query: (email) => ({
        url: `email/subscribe/${email}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useSubscribeEmailMutation } = emailApi;
