import React from 'react';

import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { Separator } from '~components/atoms/Separator';
import { StyledFavoriteIcon } from '~components/atoms/StyledFavoriteIcon';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { SportEvent, SportEventMainData } from '~types/events';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { splitStringOnVs } from '~utils/stringUtils';

export interface EventListItemProps {
  isFavorite: boolean;
  event: SportEventMainData;
  handleFavoriteClick: (
    isEventFavorite: boolean,
    eventData: SportEvent,
  ) => Promise<void>;
  handleLoadEventData: (id: string) => void;
}

export const EventListMenuItem = ({
  event,
  isFavorite,
  handleLoadEventData,
  handleFavoriteClick,
}: EventListItemProps) => {
  const { localized } = useTranslation();
  const { isLaptopOrDesktop } = useMedia();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { selectedFavoriteEventId } = useAppSelector((state) => state.events);
  const { id, countryCode, countryName, leagueName, name, startDate } = event;

  const [firstTeam, secondTeam] = splitStringOnVs(name);

  const startDateFormat = `${localized(
    'event.matchStatus.0',
  )}, ${formatDateTime(startDate, TIME_FORMATS.DATE)}, ${formatDateTime(
    startDate,
    TIME_FORMATS.TIME_12H,
  )}`;

  return (
    <Box
      flexCol
      key={id}
      css={{
        cursor: 'pointer',
        background: '$secondaryTwo',
        borderRadius: '6px',
        border:
          selectedFavoriteEventId === id
            ? '1px solid $accentPrimaryOne'
            : 'none',
        p: '$2 $3',
      }}
      onClick={() => handleLoadEventData(id)}
    >
      <Box flexRow alignCenter css={{ lineHeight: '$20' }}>
        <Flag
          code={countryCode}
          countryName={countryName as CountryName}
          size="sm"
        />
        <Text
          css={{
            fontSize: '$12',
            ml: '$2',
          }}
        >
          {leagueName}
        </Text>
      </Box>
      <Separator
        verticalSpace={1}
        shrinkOut={3}
        css={{
          mb: '6px',
        }}
      />
      <Box flexRow alignCenter>
        <Box>
          {isUserLoggedIn && (
            <Box
              css={{
                color: isFavorite ? '$pressedOne' : '$textSecondaryOne',
                width: isLaptopOrDesktop ? '14px' : '16px',
                height: isLaptopOrDesktop ? '14px' : '16px',
                mr: '$2',
                lineHeight: 0,
              }}
              onClick={async (e) => {
                e.preventDefault();
                await handleFavoriteClick(isFavorite, event as SportEvent);
              }}
            >
              <StyledFavoriteIcon
                width={isLaptopOrDesktop ? 14 : 16}
                height={isLaptopOrDesktop ? 14 : 16}
              />
            </Box>
          )}
        </Box>
        <Box flexCol>
          <Text
            css={{
              lineHeight: '$20',
              fontSize: '$14',
            }}
          >
            {firstTeam}
          </Text>
          <Text
            css={{
              lineHeight: '$20',
              fontSize: '$14',
            }}
          >
            {secondTeam}
          </Text>
        </Box>
      </Box>
      <Box flexRow>
        <Text
          color="textSecondaryOne"
          css={{
            lineHeight: '$20',
            fontSize: '$12',
            pt: '$1',
          }}
        >
          {startDateFormat}
        </Text>
      </Box>
    </Box>
  );
};
