import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledDepositFormWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  alignItems: 'center',
  justifyContent: 'center',
  p: '$3 $2',
  maxWidth: '362px',
  width: '100%',
  '@xs_sm': {
    pt: '$2',
    paddingBottom: '$4',
  },
});
