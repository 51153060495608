export const TIMEZONES = [
  {
    value: 'utc+14',
    label: 'UTC +14:00 (LINT) - Line Islands Time',
    title: 'UTC +14:00 (LINT)',
  },
  {
    value: 'utc+13',
    label: 'UTC +13:00 (NZDT) - New Zealand Daylight Time',
    title: 'UTC +13:00 (NZDT)',
  },
  {
    value: 'utc+12',
    label: 'UTC +12:00 (FJT) - Fiji Time',
    title: 'UTC +12:00 (FJT)',
  },
  {
    value: 'utc+11',
    label: 'UTC +11:00 (SBT) - Solomon Islands Time',
    title: 'UTC +11:00 (SBT)',
  },
  {
    value: 'utc+10',
    label: 'UTC +10:00 (AEST) - Australia Eastern Standard Time',
    title: 'UTC +10:00 (AEST)',
  },
  {
    value: 'utc+9:30',
    label: 'UTC +09:30 (ACST) - Australia Central Standard Time',
    title: 'UTC +09:30 (ACST)',
  },
  {
    value: 'utc+9',
    label: 'UTC +09:00 (JST) - Japan Standard Time',
    title: 'UTC +09:00 (JST)',
  },
  {
    value: 'utc+8',
    label: 'UTC +08:00 (AWST) - Australia Western Standard Time',
    title: 'UTC +08:00 (AWST)',
  },
  {
    value: 'utc+7',
    label: 'UTC +07:00 (WIB) - Western Indonesia Time',
    title: 'UTC +07:00 (WIB)',
  },
  {
    value: 'utc+6:30',
    label: 'UTC +06:30 (MMT) - Myanmar Time',
    title: 'UTC +06:30 (MMT)',
  },
  {
    value: 'utc+6',
    label: 'UTC +06:00 (BST) - Bangladesh Standard Time',
    title: 'UTC +06:00 (BST)',
  },
  {
    value: 'utc+5:30',
    label: 'UTC +05:30 (IST) - India Standard Time',
    title: 'UTC +05:30 (IST)',
  },
  {
    value: 'utc+5',
    label: 'UTC +05:00 (PKT) - Pakistan Standard Time',
    title: 'UTC +05:00 (PKT)',
  },
  {
    value: 'utc+4:30',
    label: 'UTC +04:30 (AFT) - Afghanistan Time',
    title: 'UTC +04:30 (AFT)',
  },
  {
    value: 'utc+4',
    label: 'UTC +04:00 (GST) - Gulf Standard Time',
    title: 'UTC +04:00 (GST)',
  },
  {
    value: 'utc+3:30',
    label: 'UTC +03:30 (IRST) - Iran Standard Time',
    title: 'UTC +03:30 (IRST)',
  },
  {
    value: 'utc+3',
    label: 'UTC +03:00 (EAT) - East Africa Time',
    title: 'UTC +03:00 (EAT)',
  },
  {
    value: 'utc+2',
    label: 'UTC +02:00 (CAT) - Central Africa Time',
    title: 'UTC +02:00 (CAT)',
  },
  {
    value: 'utc+1',
    label: 'UTC +01:00 (CET) - Central European Time',
    title: 'UTC +01:00 (CET)',
  },
  {
    value: 'utc+0',
    label: 'UTC +00:00 (GMT) - Greenwich Mean Time',
    title: 'UTC +00:00 (GMT)',
  },
  {
    value: 'utc-1',
    label: 'UTC -01:00 (AZOT) - Azores Time',
    title: 'UTC -01:00 (AZOT)',
  },
  {
    value: 'utc-2',
    label: 'UTC -02:00 (GST) - South Georgia Time',
    title: 'UTC -02:00 (GST)',
  },
  {
    value: 'utc-3',
    label: 'UTC -03:00 (BRT) - Brasília Time',
    title: 'UTC -03:00 (BRT)',
  },
  {
    value: 'utc-3:30',
    label: 'UTC -03:30 (NST) - Newfoundland Standard Time',
    title: 'UTC -03:30 (NST)',
  },
  {
    value: 'utc-4',
    label: 'UTC -04:00 (AST) - Atlantic Standard Time',
    title: 'UTC -04:00 (AST)',
  },
  {
    value: 'utc-5',
    label: 'UTC -05:00 (EST) - Eastern Standard Time',
    title: 'UTC -05:00 (EST)',
  },
  {
    value: 'utc-6',
    label: 'UTC -06:00 (CST) - Central Standard Time',
    title: 'UTC -06:00 (CST)',
  },
  {
    value: 'utc-7',
    label: 'UTC -07:00 (MST) - Mountain Standard Time',
    title: 'UTC -07:00 (MST)',
  },
  {
    value: 'utc-8',
    label: 'UTC -08:00 (PST) - Pacific Standard Time',
    title: 'UTC -08:00 (PST)',
  },
  {
    value: 'utc-9',
    label: 'UTC -09:00 (AKST) - Alaska Standard Time',
    title: 'UTC -09:00 (AKST)',
  },
  {
    value: 'utc-10',
    label: 'UTC -10:00 (HST) - Hawaii Standard Time',
    title: 'UTC -10:00 (HST)',
  },
  {
    value: 'utc-11',
    label: 'UTC -11:00 (SST) - Samoa Standard Time',
    title: 'UTC -11:00 (SST)',
  },
  {
    value: 'utc-12',
    label: 'UTC -12:00 (BIT) - Baker Island Time',
    title: 'UTC -12:00 (BIT)',
  },
];

export const timeZoneOffsets = {
  'Europe/Andorra': 'utc+1',
  'Asia/Dubai': 'utc+4',
  'Asia/Kabul': 'utc+4:30',
  'Europe/Tirane': 'utc+1',
  'Asia/Yerevan': 'utc+4',
  'Antarctica/Casey': 'utc+8',
  'Antarctica/Davis': 'utc+7',
  'Antarctica/DumontDUrville': 'utc+10',
  'Antarctica/Mawson': 'utc+5',
  'Antarctica/Palmer': 'utc-3',
  'Antarctica/Rothera': 'utc-3',
  'Antarctica/Syowa': 'utc+3',
  'Antarctica/Troll': 'utc+0',
  'Antarctica/Vostok': 'utc+6',
  'America/Argentina/Buenos_Aires': 'utc-3',
  'America/Argentina/Cordoba': 'utc-3',
  'America/Argentina/Salta': 'utc-3',
  'America/Argentina/Jujuy': 'utc-3',
  'America/Argentina/Tucuman': 'utc-3',
  'America/Argentina/Catamarca': 'utc-3',
  'America/Argentina/La_Rioja': 'utc-3',
  'America/Argentina/San_Juan': 'utc-3',
  'America/Argentina/Mendoza': 'utc-3',
  'America/Argentina/San_Luis': 'utc-3',
  'America/Argentina/Rio_Gallegos': 'utc-3',
  'America/Argentina/Ushuaia': 'utc-3',
  'Pacific/Pago_Pago': 'utc-11',
  'Europe/Vienna': 'utc+1',
  'Australia/Lord_Howe': 'utc+10:30',
  'Antarctica/Macquarie': 'utc+11',
  'Australia/Hobart': 'utc+10',
  'Australia/Currie': 'utc+10',
  'Australia/Melbourne': 'utc+10',
  'Australia/Sydney': 'utc+10',
  'Australia/Broken_Hill': 'utc+9:30',
  'Australia/Brisbane': 'utc+10',
  'Australia/Lindeman': 'utc+10',
  'Australia/Adelaide': 'utc+9:30',
  'Australia/Darwin': 'utc+9:30',
  'Australia/Perth': 'utc+8',
  'Australia/Eucla': 'utc+8:45',
  'Asia/Baku': 'utc+4',
  'America/Barbados': 'utc-4',
  'Asia/Dhaka': 'utc+6',
  'Europe/Brussels': 'utc+1',
  'Europe/Sofia': 'utc+2',
  'Atlantic/Bermuda': 'utc-4',
  'Asia/Brunei': 'utc+8',
  'America/La_Paz': 'utc-4',
  'America/Noronha': 'utc-2',
  'America/Belem': 'utc-3',
  'America/Fortaleza': 'utc-3',
  'America/Recife': 'utc-3',
  'America/Araguaina': 'utc-3',
  'America/Maceio': 'utc-3',
  'America/Bahia': 'utc-3',
  'America/Sao_Paulo': 'utc-3',
  'America/Campo_Grande': 'utc-4',
  'America/Cuiaba': 'utc-4',
  'America/Santarem': 'utc-3',
  'America/Porto_Velho': 'utc-4',
  'America/Boa_Vista': 'utc-4',
  'America/Manaus': 'utc-4',
  'America/Eirunepe': 'utc-5',
  'America/Rio_Branco': 'utc-5',
  'America/Nassau': 'utc-5',
  'Asia/Thimphu': 'utc+6',
  'Europe/Minsk': 'utc+3',
  'America/Belize': 'utc-6',
  'America/St_Johns': 'utc-3:30',
  'America/Halifax': 'utc-4',
  'America/Glace_Bay': 'utc-4',
  'America/Moncton': 'utc-4',
  'America/Goose_Bay': 'utc-4',
  'America/Blanc-Sablon': 'utc-4',
  'America/Toronto': 'utc-5',
  'America/Nipigon': 'utc-5',
  'America/Thunder_Bay': 'utc-5',
  'America/Iqaluit': 'utc-5',
  'America/Pangnirtung': 'utc-5',
  'America/Atikokan': 'utc-5',
  'America/Winnipeg': 'utc-6',
  'America/Rainy_River': 'utc-6',
  'America/Resolute': 'utc-6',
  'America/Rankin_Inlet': 'utc-6',
  'America/Regina': 'utc-6',
  'America/Swift_Current': 'utc-6',
  'America/Edmonton': 'utc-7',
  'America/Cambridge_Bay': 'utc-7',
  'America/Yellowknife': 'utc-7',
  'America/Inuvik': 'utc-7',
  'America/Creston': 'utc-7',
  'America/Dawson_Creek': 'utc-7',
  'America/Fort_Nelson': 'utc-7',
  'America/Vancouver': 'utc-8',
  'America/Whitehorse': 'utc-8',
  'America/Dawson': 'utc-8',
  'Indian/Cocos': 'utc+6:30',
  'Europe/Zurich': 'utc+1',
  'Africa/Abidjan': 'utc+0',
  'Pacific/Rarotonga': 'utc-10',
  'America/Santiago': 'utc-4',
  'America/Punta_Arenas': 'utc-3',
  'Pacific/Easter': 'utc-6',
  'Asia/Shanghai': 'utc+8',
  'Asia/Urumqi': 'utc+6',
  'America/Bogota': 'utc-5',
  'America/Costa_Rica': 'utc-6',
  'America/Havana': 'utc-5',
  'Atlantic/Cape_Verde': 'utc-1',
  'America/Curacao': 'utc-4',
  'Indian/Christmas': 'utc+7',
  'Asia/Nicosia': 'utc+2',
  'Asia/Famagusta': 'utc+2',
  'Europe/Prague': 'utc+1',
  'Europe/Berlin': 'utc+1',
  'Europe/Copenhagen': 'utc+1',
  'America/Santo_Domingo': 'utc-4',
  'Africa/Algiers': 'utc+1',
  'America/Guayaquil': 'utc-5',
  'Pacific/Galapagos': 'utc-6',
  'Europe/Tallinn': 'utc+2',
  'Africa/Cairo': 'utc+2',
  'Africa/El_Aaiun': 'utc+1',
  'Europe/Madrid': 'utc+1',
  'Africa/Ceuta': 'utc+1',
  'Atlantic/Canary': 'utc+0',
  'Europe/Helsinki': 'utc+2',
  'Pacific/Fiji': 'utc+12',
  'Atlantic/Stanley': 'utc-3',
  'Pacific/Chuuk': 'utc+10',
  'Pacific/Pohnpei': 'utc+11',
  'Pacific/Kosrae': 'utc+11',
  'Atlantic/Faroe': 'utc+0',
  'Europe/Paris': 'utc+1',
  'Europe/London': 'utc+0',
  'Asia/Tbilisi': 'utc+4',
  'America/Cayenne': 'utc-3',
  'Africa/Accra': 'utc+0',
  'Europe/Gibraltar': 'utc+1',
  'America/Godthab': 'utc-3',
  'America/Danmarkshavn': 'utc+0',
  'America/Scoresbysund': 'utc-1',
  'America/Thule': 'utc-4',
  'Europe/Athens': 'utc+2',
  'Atlantic/South_Georgia': 'utc-2',
  'America/Guatemala': 'utc-6',
  'Pacific/Guam': 'utc+10',
  'Africa/Bissau': 'utc+0',
  'America/Guyana': 'utc-4',
  'Asia/Hong_Kong': 'utc+8',
  'America/Tegucigalpa': 'utc-6',
  'America/Port-au-Prince': 'utc-5',
  'Europe/Budapest': 'utc+1',
  'Asia/Jakarta': 'utc+7',
  'Asia/Pontianak': 'utc+7',
  'Asia/Makassar': 'utc+8',
  'Asia/Jayapura': 'utc+9',
  'Europe/Dublin': 'utc+0',
  'Asia/Jerusalem': 'utc+2',
  'Asia/Kolkata': 'utc+5:30',
  'Indian/Chagos': 'utc+6',
  'Asia/Baghdad': 'utc+3',
  'Asia/Tehran': 'utc+3:30',
  'Atlantic/Reykjavik': 'utc+0',
  'Europe/Rome': 'utc+1',
  'America/Jamaica': 'utc-5',
  'Asia/Amman': 'utc+2',
  'Asia/Tokyo': 'utc+9',
  'Africa/Nairobi': 'utc+3',
  'Asia/Bishkek': 'utc+6',
  'Pacific/Tarawa': 'utc+12',
  'Pacific/Enderbury': 'utc+13',
  'Pacific/Kiritimati': 'utc+14',
  'Asia/Pyongyang': 'utc+9',
  'Asia/Seoul': 'utc+9',
  'Asia/Almaty': 'utc+6',
  'Asia/Qyzylorda': 'utc+6',
  'Asia/Qostanay': 'utc+6',
  'Asia/Aqtobe': 'utc+5',
  'Asia/Aqtau': 'utc+5',
  'Asia/Atyrau': 'utc+5',
  'Asia/Oral': 'utc+5',
  'Asia/Beirut': 'utc+2',
  'Asia/Colombo': 'utc+5:30',
  'Africa/Monrovia': 'utc+0',
  'Europe/Vilnius': 'utc+2',
  'Europe/Luxembourg': 'utc+1',
  'Europe/Riga': 'utc+2',
  'Africa/Tripoli': 'utc+2',
  'Africa/Casablanca': 'utc+0',
  'Europe/Monaco': 'utc+1',
  'Europe/Chisinau': 'utc+2',
  'Pacific/Majuro': 'utc+12',
  'Pacific/Kwajalein': 'utc+12',
  'Asia/Yangon': 'utc+6:30',
  'Asia/Ulaanbaatar': 'utc+8',
  'Asia/Hovd': 'utc+7',
  'Asia/Choibalsan': 'utc+8',
  'Asia/Macau': 'utc+8',
  'America/Martinique': 'utc-4',
  'Europe/Malta': 'utc+1',
  'Indian/Mauritius': 'utc+4',
  'Indian/Maldives': 'utc+5',
  'America/Mexico_City': 'utc-6',
  'America/Cancun': 'utc-5',
  'America/Merida': 'utc-6',
  'America/Monterrey': 'utc-6',
  'America/Matamoros': 'utc-6',
  'America/Mazatlan': 'utc-7',
  'America/Chihuahua': 'utc-7',
  'America/Ojinaga': 'utc-7',
  'America/Hermosillo': 'utc-7',
  'America/Tijuana': 'utc-8',
  'America/Bahia_Banderas': 'utc-6',
  'Asia/Kuala_Lumpur': 'utc+8',
  'Asia/Kuching': 'utc+8',
  'Africa/Maputo': 'utc+2',
  'Africa/Windhoek': 'utc+2',
  'Pacific/Noumea': 'utc+11',
  'Pacific/Norfolk': 'utc+11',
  'Africa/Lagos': 'utc+1',
  'America/Managua': 'utc-6',
  'Europe/Amsterdam': 'utc+1',
  'Europe/Oslo': 'utc+1',
  'Asia/Kathmandu': 'utc+5:45',
  'Pacific/Nauru': 'utc+12',
  'Pacific/Niue': 'utc-11',
  'Pacific/Auckland': 'utc+12',
  'Pacific/Chatham': 'utc+12:45',
  'America/Panama': 'utc-5',
  'America/Lima': 'utc-5',
  'Pacific/Tahiti': 'utc-10',
  'Pacific/Marquesas': 'utc-9:30',
  'Pacific/Gambier': 'utc-9',
  'Pacific/Port_Moresby': 'utc+10',
  'Pacific/Bougainville': 'utc+11',
  'Asia/Manila': 'utc+8',
  'Asia/Karachi': 'utc+5',
  'Europe/Warsaw': 'utc+1',
  'America/Miquelon': 'utc-3',
  'Pacific/Pitcairn': 'utc-8',
  'America/Puerto_Rico': 'utc-4',
  'Asia/Gaza': 'utc+2',
  'Asia/Hebron': 'utc+2',
  'Europe/Lisbon': 'utc+0',
  'Atlantic/Madeira': 'utc+0',
  'Atlantic/Azores': 'utc-1',
  'Pacific/Palau': 'utc+9',
  'America/Asuncion': 'utc-4',
  'Asia/Qatar': 'utc+3',
  'Indian/Reunion': 'utc+4',
  'Europe/Bucharest': 'utc+2',
  'Europe/Belgrade': 'utc+1',
  'Europe/Kaliningrad': 'utc+2',
  'Europe/Moscow': 'utc+3',
  'Europe/Simferopol': 'utc+3',
  'Europe/Kirov': 'utc+3',
  'Europe/Astrakhan': 'utc+4',
  'Europe/Volgograd': 'utc+4',
  'Europe/Saratov': 'utc+4',
  'Europe/Ulyanovsk': 'utc+4',
  'Europe/Samara': 'utc+4',
  'Asia/Yekaterinburg': 'utc+5',
  'Asia/Omsk': 'utc+6',
  'Asia/Novosibirsk': 'utc+7',
  'Asia/Barnaul': 'utc+7',
  'Asia/Tomsk': 'utc+7',
  'Asia/Novokuznetsk': 'utc+7',
  'Asia/Krasnoyarsk': 'utc+7',
  'Asia/Irkutsk': 'utc+8',
  'Asia/Chita': 'utc+9',
  'Asia/Yakutsk': 'utc+9',
  'Asia/Khandyga': 'utc+9',
  'Asia/Vladivostok': 'utc+10',
  'Asia/Ust-Nera': 'utc+10',
  'Asia/Magadan': 'utc+11',
  'Asia/Sakhalin': 'utc+11',
  'Asia/Srednekolymsk': 'utc+11',
  'Asia/Kamchatka': 'utc+12',
  'Asia/Anadyr': 'utc+12',
  'Asia/Riyadh': 'utc+3',
  'Pacific/Guadalcanal': 'utc+11',
  'Indian/Mahe': 'utc+4',
  'Africa/Khartoum': 'utc+2',
  'Europe/Stockholm': 'utc+1',
  'Asia/Singapore': 'utc+8',
  'America/Paramaribo': 'utc-3',
  'Africa/Juba': 'utc+3',
  'Africa/Sao_Tome': 'utc+0',
  'America/El_Salvador': 'utc-6',
  'Asia/Damascus': 'utc+2',
  'America/Grand_Turk': 'utc-5',
  'Africa/Ndjamena': 'utc+1',
  'Indian/Kerguelen': 'utc+5',
  'Asia/Bangkok': 'utc+7',
  'Asia/Dushanbe': 'utc+5',
  'Pacific/Fakaofo': 'utc+13',
  'Asia/Dili': 'utc+9',
  'Asia/Ashgabat': 'utc+5',
  'Africa/Tunis': 'utc+1',
  'Pacific/Tongatapu': 'utc+13',
  'Europe/Istanbul': 'utc+3',
  'America/Port_of_Spain': 'utc-4',
  'Pacific/Funafuti': 'utc+12',
  'Asia/Taipei': 'utc+8',
  'Europe/Kiev': 'utc+2',
  'Europe/Uzhgorod': 'utc+2',
  'Europe/Zaporozhye': 'utc+2',
  'Pacific/Wake': 'utc+12',
  'America/New_York': 'utc-5',
  'America/Detroit': 'utc-5',
  'America/Kentucky/Louisville': 'utc-5',
  'America/Kentucky/Monticello': 'utc-5',
  'America/Indiana/Indianapolis': 'utc-5',
  'America/Indiana/Vincennes': 'utc-5',
  'America/Indiana/Winamac': 'utc-5',
  'America/Indiana/Marengo': 'utc-5',
  'America/Indiana/Petersburg': 'utc-5',
  'America/Indiana/Vevay': 'utc-5',
  'America/Chicago': 'utc-6',
  'America/Indiana/Tell_City': 'utc-6',
  'America/Indiana/Knox': 'utc-6',
  'America/Menominee': 'utc-6',
  'America/North_Dakota/Center': 'utc-6',
  'America/North_Dakota/New_Salem': 'utc-6',
  'America/North_Dakota/Beulah': 'utc-6',
  'America/Denver': 'utc-7',
  'America/Boise': 'utc-7',
  'America/Phoenix': 'utc-7',
  'America/Los_Angeles': 'utc-8',
  'America/Anchorage': 'utc-9',
  'America/Juneau': 'utc-9',
  'America/Sitka': 'utc-9',
  'America/Metlakatla': 'utc-9',
  'America/Yakutat': 'utc-9',
  'America/Nome': 'utc-9',
  'America/Adak': 'utc-10',
  'Pacific/Honolulu': 'utc-10',
  'America/Montevideo': 'utc-3',
  'Asia/Samarkand': 'utc+5',
  'Asia/Tashkent': 'utc+5',
  'America/Caracas': 'utc-4',
  'Asia/Ho_Chi_Minh': 'utc+7',
  'Pacific/Efate': 'utc+11',
  'Pacific/Wallis': 'utc+12',
  'Pacific/Apia': 'utc+13',
  'Africa/Johannesburg': 'utc+2',
  'Africa/Kampala': 'utc+3',
};
