import {
  useLazyGetAllTransactionsQuery,
  useLazyGetAuthorizedDepositFlutterwaveQuery,
  useLazyGetAuthorizedDepositPaystackQuery,
  useLazyGetTransactionsQuery,
  useLazyGetTransactionStatusQuery,
  useLazyGetWithdrawalBanksQuery,
  useLazyGetWithdrawalLimitsQuery,
  useLazyGetWithdrawalSavedBanksQuery,
} from './transactionApi';

export const useLazyGetAuthorizedDepositFlutterwave = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetAuthorizedDepositFlutterwaveQuery();

  return {
    lazyGetAuthorizedDepositFlutterwaveQuery: query,
    lazyGetAuthorizedDepositFlutterwaveData: data,
    lazyGetAuthorizedDepositFlutterwaveError: error,
    lazyGetAuthorizedDepositFlutterwaveIsLoading: isLoading,
  };
};

export const useLazyGetAuthorizedDepositPaystack = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetAuthorizedDepositPaystackQuery();

  return {
    lazyGetAuthorizedDepositPaystackQuery: query,
    lazyGetAuthorizedDepositPaystackData: data,
    lazyGetAuthorizedDepositPaystackError: error,
    lazyGetAuthorizedDepositPaystackIsLoading: isLoading,
  };
};

export const useLazyGetTransactions = () => {
  const [query, { data, error, isLoading }] = useLazyGetTransactionsQuery();

  return {
    lazyGetTransactionsQuery: query,
    lazyGetTransactionsData: data,
    lazyGetTransactionsError: error,
    lazyGetTransactionsIsLoading: isLoading,
  };
};

export const useLazyGetAllTransactions = () => {
  const [query, { data, error, isLoading }] = useLazyGetAllTransactionsQuery();

  return {
    lazyGetAllTransactionsQuery: query,
    lazyGetAllTransactionsData: data,
    lazyGetAllTransactionsError: error,
    lazyGetAllTransactionsIsLoading: isLoading,
  };
};

export const useLazyGetTransactionStatus = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetTransactionStatusQuery();

  return {
    lazyGetTransactionStatusQuery: query,
    lazyGetTransactionStatusData: data,
    lazyGetTransactionStatusError: error,
    lazyGetTransactionStatusIsLoading: isLoading,
  };
};

export const useLazyGetWithdrawalBanks = () => {
  const [query, { data, error, isLoading }] = useLazyGetWithdrawalBanksQuery();

  return {
    lazyGetWithdrawalBanksQuery: query,
    lazyGetWithdrawalBanksData: data,
    lazyGetWithdrawalBanksError: error,
    lazyGetWithdrawalBanksIsLoading: isLoading,
  };
};

export const useLazyGetWithdrawalSavedBanks = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetWithdrawalSavedBanksQuery();

  return {
    lazyGetWithdrawalSavedBanksQuery: query,
    lazyGetWithdrawalSavedBanksData: data,
    lazyGetWithdrawalSavedBanksError: error,
    lazyGetWithdrawalSavedBanksIsLoading: isLoading,
  };
};

export const useLazyGetWithdrawalLimits = () => {
  const [query, { data, error, isLoading }] = useLazyGetWithdrawalLimitsQuery();

  return {
    lazyGetWithdrawalLimitsQuery: query,
    lazyGetWithdrawalLimitsData: data,
    lazyGetWithdrawalLimitsError: error,
    lazyGetWithdrawalLimitsIsLoading: isLoading,
  };
};
