import React, {
  ComponentPropsWithRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Box } from '~components/atoms/Box';
import { StyledLoadingIcon } from '~components/atoms/Loader';

import { StyledButton } from './styled.components';

interface ButtonPrimitiveProps extends ComponentPropsWithRef<'button'> {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonPrimitiveProps>(
  ({ children, isLoading, ...rest }, ref) => {
    const localRef = useRef<HTMLButtonElement>(null);
    const [loadingHeight, setLoadingHeight] = useState<string>('0px');

    useEffect(() => {
      const targetRef = ref || localRef;

      if (targetRef && 'current' in targetRef && targetRef.current) {
        const height = targetRef.current.getBoundingClientRect().height;

        setLoadingHeight(height + 'px');
      }
    }, [ref]);

    return (
      <StyledButton
        ref={ref || localRef}
        {...rest}
        style={{ minHeight: isLoading ? loadingHeight : 'auto' }}
      >
        {isLoading ? (
          <Box
            as="span"
            flexRow
            absoluteCenter
            css={{ color: '$textPrimaryOne' }}
          >
            <StyledLoadingIcon />
          </Box>
        ) : (
          children
        )}
      </StyledButton>
    );
  },
);

export const BasicButton = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithRef<'button'>
>((props, ref) => <button ref={ref} {...props} />);
