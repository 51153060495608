import { noDetailsStatuses } from '~components/molecules/LiveMenu/constants';
import { ExtraData } from '~types/sportExtraData';

export const getFormattedMatchStatus = (
  status: number,
  localized: (key: string, args?: object) => string,
) => {
  return localized(`event.matchStatus.${status}`);
};

export const getMatchStatus = (extraData: ExtraData | null) => {
  return extraData?.MatchStatusId || 0;
};

export const shouldShowMatchDetail = (extraData: ExtraData | null): boolean => {
  const matchStatus = getMatchStatus(extraData);

  return !noDetailsStatuses.includes(matchStatus.toString());
};
