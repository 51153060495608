import { useEffect } from 'react';

// import * as Sentry from '@sentry/react';
import { UserProfileData } from '~api/user/types';
import { useLazyGetUserProfile } from '~api/user/userQueries';
import {
  startTokenRefreshScheduler,
  stopTokenRefreshScheduler,
} from '~services/auth/tokenRefreshScheduler';
import { useAppDispatch, useAppSelector } from '~store';
import {
  logout,
  selectIsUserLoggedIn,
  setIsUserProfileLoaded,
  setProfile,
} from '~store/slices/userSlice';

export const useLoadUserProfile = () => {
  const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const { lazyGetUserProfileQuery } = useLazyGetUserProfile();
  const { profile } = useAppSelector((state) => state.userState);

  const loadProfile = async () => {
    try {
      dispatch(setIsUserProfileLoaded(false));
      const { data } = await lazyGetUserProfileQuery();

      if (data) {
        // Sentry.setUser({ id: data.id }); // Set user id for Sentry
        dispatch(setProfile(data as UserProfileData));
        dispatch(setIsUserProfileLoaded(true));
      } else {
        dispatch(logout());
        window.parent.postMessage({ type: 'LOGOUT' }, '*');
      }
    } catch (error) {
      console.error('Error while loading profile', error);
      dispatch(logout());
      window.parent.postMessage({ type: 'LOGOUT' }, '*');
    }
  };

  //TODO: we need to get rid of this listening of isUserLoggedIn and
  // use loadProfile in places when we need it directly

  useEffect(() => {
    if (isUserLoggedIn) {
      if (!profile?.id) loadProfile();
      startTokenRefreshScheduler();
    } else {
      stopTokenRefreshScheduler();
    }
  }, [isUserLoggedIn]);

  return { loadProfile };
};
