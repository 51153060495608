import { NavLink } from 'react-router-dom';
import { styled } from 'stitches.config';

import { ellipsis } from '~utils/cssUtils';

export const NavigationLink = styled(NavLink, {
  display: 'block',
  textDecoration: 'none',
  '&:hover': {
    '@md_lg_xl': {
      cursor: 'pointer',
      color: '$hoverPrimary !important',
      '*': {
        color: '$hoverPrimary !important',
      },
    },
    '@xs_sm': {
      textDecoration: 'none',
    },
  },
  '&:active': {
    color: '$pressedOne !important',
    '*': {
      color: '$pressedOne !important',
    },
  },
  variants: {
    ellipsis: {
      true: {
        ...ellipsis,
      },
    },
  },
});
