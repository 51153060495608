import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Box } from '~components/atoms/Box';
import { useSignUp } from '~components/molecules/Authentication/components/sign-up/hooks/useSignUp';
import { SuccessTab } from '~components/molecules/Authentication/components/sign-up/SuccessTab';
import { VerifyLinkTab } from '~components/molecules/Authentication/components/sign-up/VerifyLinkTab';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';

import { AuthTabs, StyledAuthTabsContent } from '../AuthTabs';
import { AuthTemplate } from '../AuthTemplate';

import { ProvideDetailsTab } from './ProvideDetailsTab';
import { SIGN_UP_TABS } from './SignUpTabList';
import { VerifyNumberTab } from './VerifyNumberTab';

export const SignUpContent = () => {
  const dispatch = useAppDispatch();
  const {
    activeTab,
    formMethods,
    isRegisteredSuccessfully,
    // Loading states
    registerUserIsLoading,
    signUpWhatsAppIsLoading,
    verifyUserIsLoading,
    verifyPhoneNewCodeIsLoading,
    // Data
    registerUserData,
    verifyPhoneNewCodeData,
    verifyPhoneNewCodeIsSuccess,
    // Handlers
    handleNextTab,
    handleRegisterUser,
    handleSignIn,
    handleSignInWithWhatsApp,
    handleVerifyPhoneNewCode,
  } = useSignUp();

  const onSuccessModalClose = () => {
    dispatch(closeDialog());
  };

  if (isRegisteredSuccessfully) {
    return (
      <Box
        css={{
          p: '$7',
          '@xs': {
            p: '$4',
          },
        }}
      >
        <SuccessTab isSuccess handleModalClose={onSuccessModalClose} />
      </Box>
    );
  }

  return (
    <AuthTemplate>
      <AuthTabs activeTab={activeTab}>
        <FormProvider {...formMethods}>
          <form>
            <StyledAuthTabsContent value={SIGN_UP_TABS.PROVIDE_DETAILS}>
              <ProvideDetailsTab
                onNextTab={handleNextTab}
                onSignIn={handleSignIn}
                isRegisterUserLoading={registerUserIsLoading}
                isRegisterWhatsAppLoading={signUpWhatsAppIsLoading}
                handleSignInWithWhatsApp={handleSignInWithWhatsApp}
              />
            </StyledAuthTabsContent>
            <StyledAuthTabsContent value={SIGN_UP_TABS.VERIFY_NUMBER}>
              <VerifyNumberTab
                onSignIn={handleSignIn}
                onRegisterUser={handleRegisterUser}
                verifyUserIsLoading={verifyUserIsLoading}
                onVerifyPhoneNewCode={handleVerifyPhoneNewCode}
                registerUserData={registerUserData}
                verifyPhoneNewCodeData={verifyPhoneNewCodeData}
                verifyPhoneNewCodeIsSuccess={verifyPhoneNewCodeIsSuccess}
                verifyPhoneNewCodeIsLoading={verifyPhoneNewCodeIsLoading}
              />
            </StyledAuthTabsContent>
            <StyledAuthTabsContent value={SIGN_UP_TABS.VERIFY_LINK}>
              <VerifyLinkTab isRegisteringWhatsApp={isRegisteredSuccessfully} />
            </StyledAuthTabsContent>
          </form>
        </FormProvider>
      </AuthTabs>
    </AuthTemplate>
  );
};
