import React from 'react';

import { Box } from '~components/atoms/Box';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import { JackpotBetEvent } from '~types/jackpot';

export const EventNameCell = ({ event }: { event: JackpotBetEvent }) => {
  const { name } = event;
  const [firstTeam = '', secondTeam = ''] = name.split('vs.');

  return (
    <Box flexCol>
      <StyledTableText>{`${firstTeam.trim()} -`}</StyledTableText>
      <StyledTableText>{secondTeam.trim()}</StyledTableText>
    </Box>
  );
};
