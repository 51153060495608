import { styled } from 'stitches.config';

import { Box } from './Box';

export const Card = styled(Box, {
  width: '100%',
  backgroundColor: '$primaryOne',
  p: '$4',
  borderRadius: '$10',
  color: '$textPrimaryOne',
  variants: {
    flatBottom: {
      true: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        pb: 0,
      },
    },
    noXPadding: {
      true: {
        px: 0,
      },
    },
  },
});
