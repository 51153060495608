export const antStylesOverwriteCss = {
  '& .ant-picker-drp[': { display: 'none !important' },
  '& .ant-picker-dropdown': { pointerEvents: 'auto !important' },
  '.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner:before':
    {
      content: '""',
      position: 'absolute',
      top: 0,
      insetInlineEnd: 0,
      bottom: 0,
      insetInlineStart: 0,
      zIndex: 1,
      border: '1px solid transparent',
      borderRadius: '$4',
    },
  '& .rc-virtual-list-scrollbar-thumb': {
    backgroundColor: '$textSecondaryOne !important',
  },
  '& .ant-select': {
    width: '100%',
  },
  '& .ant-select-selector': {
    width: '100%',
    backgroundColor: '$primaryTwo !important',
    border: 'none !important',
    fontFamily: 'Poppins, Noto Sans !important',
  },
  '& .ant-select-item-option-selected': {
    backgroundColor: '$secondaryTwo !important',
  },
  '& .ant-select-selection-search-input': {
    color: '$textPrimaryOne !important',
  },
  '& .ant-select-selection-item': {
    color: '$textPrimaryOne !important',
  },
  '& .ant-select-selection-placeholder': {
    color: '$textSecondaryOne !important',
  },
  '& .ant-select-clear': {
    backgroundColor: '$primaryTwo !important',
    color: '$textSecondaryOne !important',
  },
  '& .ant-select-dropdown': {
    pointerEvents: 'auto !important',
    backgroundColor: '$secondaryTwo !important',
    border: '1px solid $pressedOne',
    color: '$textSecondaryOne !important',
    padding: 0,
  },
  '& .ant-select-open': {
    border: '1px solid $pressedOne',
    borderRadius: '$6',
  },
  '&. .ant-select-item': {
    borderRadius: '0 !important',
  },
  '& .ant-select-item': {
    backgroundColor: '$secondaryTwo !important',
    color: '$textPrimaryOne !important',
    borderRadius: '0 !important',
  },
  '.ant-picker-panel-container': {
    boxShadow: '$elementActiveBorder !important',
  },
  '.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner:before':
    {
      content: '""',
      position: 'absolute',
      top: 0,
      insetInlineEnd: 0,
      bottom: 0,
      insetInlineStart: 0,
      zIndex: 1,
      border: '1px solid transparent',
      borderRadius: '$4',
    },
  '.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner':
    {
      background: '$accentPrimaryOne !important',
    },
  '.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner': {
    background: '$accentPrimaryOne !important',
  },
  '.ant-picker-time-panel': {
    display: 'none !important',
  },
  '.ant-picker-now': {
    display: 'none !important',
  },
  '.ant-picker': {
    color: '$textPrimaryOne',
    backgroundColor: '$secondaryTwo !important',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '$secondaryTwo !important',
      boxShadow: '$elementActiveBorder !important',
    },
    '@xs_sm': {
      backgroundColor: '$secondaryTwo !important',
      '&:hover': {
        backgroundColor: '$secondaryTwo !important',
      },
    },
  },
  '.ant-picker-header *': {
    '&:hover': {
      color: '$hoverPrimary !important',
    },
  },
  '.ant-picker-cell.ant-picker-cell-in-view': {
    '&:hover': {
      color: '$textButtons !important',
    },
  },
  '.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner':
    {
      borderRadius: '$4 !important',
    },
  '.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner':
    {
      borderRadius: '$4 !important',
    },
  '.ant-btn-primary': {
    border: 'none !important',
    backgroundColor: '$accentPrimaryOne !important',
    color: '$textButtons !important',
    '&:hover': {
      backgroundColor: '$hoverPrimary !important',
    },
    '&:active': {
      backgroundColor: '$pressedOne !important',
    },
  },
  '.ant-slider': {
    minWidth: '260px',
  },
  '.ant-input-number-handler-wrap': {
    display: 'none !important',
  },
  '.ant-input-number': {
    background: 'transparent !important',
    color: '$textPrimaryOne',
    border: 'none !important',
  },
  '.ant-input-number-input-wrap > input': {
    color: '$textPrimaryOne !important',
  },
  '.ant-input-number-input-wrap > input::placeholder': {
    color: '$textSecondaryOne !important',
  },

  '.ant-slider-handle::after': {
    boxShadow: '$accentPrimaryOne !important',
    background: '$accentPrimaryOne !important',
    border: '1px solid $textPrimaryOne',
  },
  '.ant-slider-handle::before': {},
  '.ant-slider-rail': {
    background: '$primaryTwo !important',
  },
  '.ant-slider-track': {
    background: '$accentPrimaryOne !important',
  },
  '.ant-input-number-input': {
    padding: '4px 0 !important',
  },
  '.ant-input-number-input > input': {
    padding: '4px 0 !important',
  },
};
