import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import { HTTP_STATUS_CODES } from '~constants/common';

export enum BACKEND_ERROR_CODES {
  DUPLICATE_USER_NAME = 'DuplicateUserName',
  USERNAME_ALREADY_EXISTS = 'usernameAlreadyExists',
  PHONE_NUMBER_ALREADY_EXISTS = 'phoneNumberAlreadyExists',
  WRONG_VERIFICATION_CODE = 'wrongVerificationCode',
  INVALID_PHONE_NUMBER = 'Phone Number is invalid',
  WRONG_CREDENTIALS = 'wrongCredentials',
  WHATSAPP_NOT_FOUND = 'numberDoesntExistInWhatsapp',
  FAILEDS_SEND_MESSAGE = 'failedToSendWhatsAppMessage',
}

interface ErrorDetails {
  status?: number;
  data?: {
    code?: string;
    description?: string;
  };
}

export const getErrorDetails = (
  error: FetchBaseQueryError | undefined,
): ErrorDetails | null => {
  if (!error) return null;
  const { status, data } = error;

  let result: ErrorDetails = {
    status: status as number,
  };

  const parsedMessage = parseErrorMessage(
    (data as { message?: string })?.message || '',
  );

  if (Array.isArray(parsedMessage)) {
    const message = parsedMessage[0];

    result = {
      ...result,
      data: {
        code: message || '',
      },
    };
  } else {
    result = {
      ...result,
      data: {
        code: parsedMessage,
        description: '',
      },
    };
  }

  return result;
};

// for now the backend error response is varying, we get either a plain string
// error message, or we get a stringified array structure that we need to parse.
// This function is handling that.
const parseErrorMessage = (message: string) => {
  try {
    const parsedMessage = JSON.parse(message);

    if (Array.isArray(parsedMessage)) {
      return parsedMessage;
    }
  } catch (error) {
    // Silently ignore the error
  }

  return message;
};

export const getErrorCode = (
  error: FetchBaseQueryError | SerializedError | undefined,
  isError: boolean,
) => {
  const mutationError = isError && (error as FetchBaseQueryError);

  return (
    mutationError &&
    mutationError?.status &&
    (Object.values(HTTP_STATUS_CODES) as unknown as string[]).includes(
      mutationError.status.toString(),
    ) &&
    mutationError.status.toString()
  );
};

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError | undefined,
) => {
  const { data: errorData } =
    getErrorDetails(error as FetchBaseQueryError) || {};
  const { code } = errorData || {};

  return code;
};
