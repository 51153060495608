import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { ellipsis } from '~utils/cssUtils';

export const StyledTable = styled('table', {
  width: '100%',
  borderSpacing: 0,
  tableLayout: 'fixed',
  'td > div': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const StyledCell = styled('td', {
  border: 'none',
  p: 0,
  '@xs_sm': {
    maxWidth: '180px',
  },
});

export const StyledCellContent = styled(Box, {
  color: '$textPrimaryOne',
  fontSize: '$14',
  lineHeight: '$24',
  height: '52px',
  justifyContent: 'space-between',
  background: '$secondaryTwo',
  alignItems: 'center',
  border: 'none',
  p: '$1 $3',
  mb: '$2',
  ...ellipsis,
});

export const StyledPlaceholderCell = styled(StyledCell, {
  color: '$textSecondaryOne',
  p: '$7 0',
  background: 'transparent',
  width: '100%',
  height: '100%',
  '& p': {
    textAlign: 'center',
    justifyContent: 'center',
    ...ellipsis,
  },
});

export const StyledHeaderCell = styled('th', {
  color: '$textSecondaryOne',
  fontSize: '$12',
  lineHeight: '$20',
  textAlign: 'start',
  fontWeight: '$normal !important',
  p: '$2 $3',
});

export const StyledTableHeader = styled('thead', {
  backgroundColor: '$primaryTwo',
});

export const StyledMobileText = styled(Text, {
  fontSize: '$14 !important',
  lineHeight: '$24 !important',
  fontWeight: '$medium !important',
  textAlign: 'center !important',
  variants: {
    smallFont: {
      true: {
        fontSize: '$12 !important',
        lineHeight: '$16 !important',
      },
    },
  },
});

export const StyledMobileButton = styled(Button, {
  height: '32px !important',
  flex: 1,
  borderRadius: '$6 !important',
  defaultVariants: {
    size: 'small',
    noBorder: true,
    variant: 'secondary',
  },
});
