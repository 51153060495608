import { Box } from '~components/atoms/Box';
import { StyledBoardComponentWrapper } from '~components/atoms/StyledBoardComponentWrapper';
import { Market, SportEvent } from '~types/events';
import { isEmptyObject } from '~utils/objectHelpers';

import { InplayEventMarkets } from './components/InplayEventMarkets';
import { useInplayMarketEventGroups } from './hooks/useInplayMarketEventGroups';
import { EmptyMarkets } from './EmptyMarkets';
import MarketGroups from './MarketGroups';

interface InplayMarketEventGroupsProps {
  marketGroups: Record<string, Market[]>;
  isEventLoaded: boolean;
  eventData: SportEvent;
}

export const InplayMarketEventGroups = ({
  marketGroups,
  isEventLoaded,
  eventData,
}: InplayMarketEventGroupsProps) => {
  const {
    eventId,
    groupsToShow,
    selectedGroupId,
    isMobileOrTablet,
    setSelectedGroupId,
    getSelectedMarketData,
  } = useInplayMarketEventGroups(marketGroups, eventData);

  const ongoingMarkets = getSelectedMarketData();

  if (!ongoingMarkets || !isEventLoaded) {
    return null;
  }

  if (isEmptyObject(marketGroups)) {
    return <EmptyMarkets />;
  }

  return (
    <Box
      css={{
        backgroundColor: '$primaryOne',
        borderRadius: isMobileOrTablet ? '0' : '$12',
      }}
    >
      {groupsToShow && (
        <MarketGroups
          groupsToShow={groupsToShow}
          selectedGroupId={selectedGroupId}
          setSelectedGroupId={setSelectedGroupId}
        />
      )}
      {ongoingMarkets && (
        <StyledBoardComponentWrapper
          css={{
            p: '$1',
            borderRadius: '$12',
            border: 'none',
            backgroundColor: '$primaryOne',
          }}
        >
          <InplayEventMarkets
            eventData={eventData}
            marketGroups={ongoingMarkets}
            eventId={eventId || ''}
            isEventLoaded={isEventLoaded}
            isMobileOrTablet={isMobileOrTablet}
          />
        </StyledBoardComponentWrapper>
      )}
    </Box>
  );
};
