import { styled } from 'stitches.config';

import { FavoriteIcon } from '~icons';

export const StyledFavoriteIcon = styled(FavoriteIcon, {
  cursor: 'pointer',
  '@md_lg_xl': {
    '&:hover': {
      color: '$hoverPrimary !important',
    },
  },
  '@xs_sm': {
    '&:focus': {
      color: '$hoverPrimary',
    },
  },
});
