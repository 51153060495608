export function conditionalAdd<T, K extends keyof T>(
  obj: T,
  key: K,
  value?: T[K] | string | number | null | undefined,
): T {
  if (value) {
    obj[key] = value as T[K];
  }

  return obj;
}

export const areTwoObjectsEqual = <T>(obj1: T, obj2: T): boolean => {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);

  return str1 === str2;
};

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

export const convertKeysToUpperCase = (obj: { [key: string]: unknown }) => {
  const newObj: { [key: string]: unknown } = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1);

      newObj[newKey] = obj[key];
    }
  }

  return newObj;
};

// Function to check for changed items in object (1st level)
export const getChangedProperties = <T>(
  newMap: Record<string, T>,
  prevMap: Record<string, T> = {},
) => {
  const changedProps: Record<string, unknown> = {};

  Object.keys(newMap).forEach((key) => {
    if (newMap[key] !== prevMap[key]) {
      changedProps[key] = newMap[key];
    }
  });

  return changedProps;
};
