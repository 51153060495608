import React from 'react';

import { Box } from '~components/atoms/Box';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import { JackpotBetEvent } from '~types/jackpot';

export const ScoreCell = ({ event }: { event: JackpotBetEvent }) => {
  const { homeScore, awayScore } = event;

  return (
    <Box flexCol>
      <StyledTableText>{homeScore ?? '-'}</StyledTableText>
      <StyledTableText>{awayScore ?? '-'}</StyledTableText>
    </Box>
  );
};
