import { useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { SportEventItem } from '~api/sportEvent/types';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { Market } from '~types/events';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

export interface EventsListItemHookProps {
  eventData: SportEventItem;
  mainMarkets: MainMarket[];
  maxScoreWidth?: number;
  isFirst?: boolean;
  maxMarketsCount?: number;
}

export type MarketsData = (Market | undefined)[];

export const useEventsListItem = ({
  eventData,
  mainMarkets = [],
  maxMarketsCount: maxMarketsCountProp,
}: EventsListItemHookProps) => {
  const { maxMarketsCount } = useAppSelector((state) => state.prematchMenu);
  const { isMobileOrTablet } = useMedia();

  const getMarketsData = (markets: MarketsData): MarketsData => {
    let marketsData = mainMarkets.map((mainMarket) => {
      return markets.find(
        (market) => Number(market?.marketId || 0) === mainMarket?.id,
      );
    }) as MarketsData;

    if (!isMobileOrTablet) {
      marketsData = marketsData.slice(
        0,
        Math.max(0, maxMarketsCountProp || maxMarketsCount),
      );
    }

    return marketsData;
  };

  const [marketsData, setMarketsData] = useState<MarketsData>(
    getMarketsData(eventData.markets || []),
  );

  useEffect(() => {
    if (!eventData?.markets) return;
    const markets = getMarketsData(eventData?.markets);

    if (!areTwoObjectsEqual(markets, marketsData)) {
      setMarketsData(markets);
    }
  }, [eventData?.markets, mainMarkets, maxMarketsCount, maxMarketsCountProp]);

  return { marketsData };
};
