import { Fragment } from 'react';

import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
  StyledAccordionRoot,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Separator } from '~components/atoms/Separator';
import { SettingsDropdownWithoutPopover } from '~components/atoms/SettingsDropdown/SettingsDropdownWithoutPopover';
import { WarningAndCopywriting } from '~components/atoms/WarningAndCopywriting';
import {
  StyledBottomPlaceholder,
  StyledLinkContentMenuText,
  StyledMenuText,
  StyledMobileFooter,
  StyledSettingsWrapper,
} from '~components/organisms/FooterComponent/FooterDesktop/styled.components';
import { SubscribeEmail } from '~components/organisms/FooterComponent/SubscribeEmail';
import { useFooter } from '~components/organisms/FooterComponent/useFooter';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useRouterQuery } from '~hooks';

export const MobileFooter = () => {
  const { contentMenus } = useFooter();
  const { navigateWithHistory } = useRouterQuery();

  return (
    <StyledMobileFooter flexCol>
      <StyledSettingsWrapper>
        <SettingsDropdownWithoutPopover
          onClick={() => navigateWithHistory(`${ROUTE_TAB_NAMES.SETTINGS}/`)}
        />
      </StyledSettingsWrapper>
      <Box flexCol css={{ px: '$4' }}>
        <SubscribeEmail />
      </Box>
      <StyledAccordionRoot type="multiple">
        {contentMenus?.map(({ name, items }) => (
          <Fragment key={name}>
            <StyledAccordionItem value={name} mobileMenuNoPadding>
              <Separator shrinkOut={7} verticalSpace={2} />
              <AccordionTrigger
                mobileEventMenu
                isTransparentBackground
                openDataState
              >
                <StyledMenuText>{name}</StyledMenuText>
              </AccordionTrigger>
              <AccordionContent mobileMenuSeparated>
                <Separator shrinkOut={7} verticalSpace={2} />
                <Box flexCol gap={4}>
                  {items.map(({ name, url }) => (
                    <Box
                      flexRow
                      key={name}
                      css={{
                        pb: '$3',
                        borderBottom: '1px solid $primaryTwo',
                        '&:last-child': { borderBottom: 'none' },
                      }}
                    >
                      <NavigationLink to={url}>
                        <StyledLinkContentMenuText as="span">
                          {name}
                        </StyledLinkContentMenuText>
                      </NavigationLink>
                    </Box>
                  ))}
                </Box>
              </AccordionContent>
            </StyledAccordionItem>
          </Fragment>
        ))}
      </StyledAccordionRoot>
      <Separator shrinkOut={0} verticalSpace={2} />
      <Box flexRow css={{ p: '$4 $4 0 $4' }}>
        {/* a temporary block, it will be populated by backend */}
        <StyledBottomPlaceholder>
          <WarningAndCopywriting />
        </StyledBottomPlaceholder>
      </Box>
    </StyledMobileFooter>
  );
};
