import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { ellipsis } from '~utils/cssUtils';
import { getSportIcon } from '~utils/getSportIcon';

const PrimitiveSportMenuItem = styled(Box, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$secondaryOne',
  py: '10px',
  pl: '$3',
  pr: '52px',
  borderRadius: '$6',
  color: '$textPrimaryOne',
  cursor: 'pointer',
  '& > .sport': {
    ...ellipsis,
  },
  '&:last-child': {
    mb: 0,
  },
  '&:hover': {
    backgroundColor: '$secondaryTwo',
    '.count': {
      color: '$textPrimaryOne',
    },
  },
  '&[data-state=active]': {
    backgroundColor: '$secondaryTwo',
    boxShadow: '$elementActiveBorder',
    '.count': {
      color: '$textPrimaryOne',
    },
  },
});

interface SportMenuItemProps {
  sportName: string;
  sportId: number;
  eventsAmount: number;
  isActive: boolean;
  handleOnClick: () => void;
}

export const SportMenuItem = ({
  sportName,
  sportId,
  eventsAmount,
  isActive,
  handleOnClick,
}: SportMenuItemProps) => {
  return (
    <PrimitiveSportMenuItem
      alignCenter
      data-state={isActive ? 'active' : 'inActive'}
      onClick={handleOnClick}
    >
      <Box css={{ marginRight: '$3', lineHeight: 0 }}>
        {getSportIcon(sportId, {
          width: 16,
          height: 16,
        })}
      </Box>
      <Text className="sport" css={{ color: 'inherit' }} level="sm-3">
        {sportName}
      </Text>
      <Tag
        text={eventsAmount.toString()}
        className="count"
        css={{
          position: 'absolute',
          right: 0,
          backgroundColor: 'transparent',
          '@xs_sm': {
            right: '$1',
          },
        }}
      />
    </PrimitiveSportMenuItem>
  );
};
