import React, {
  ComponentPropsWithRef,
  forwardRef,
  memo,
  useCallback,
} from 'react';

import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks';
import { SVGElement } from '~types/general';
import { getSportIcon } from '~utils/getSportIcon';

import {
  StyledSportSliderItem,
  styledSportSliderTagCSS,
} from './styled.components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
interface SportSliderItemProps extends ComponentPropsWithRef<'div'> {
  sport?: number;
  label?: string;
  count?: string | number;
  icon?: SVGElement;
  isDesktop?: boolean;
  withCounts?: boolean;
  isFavorite?: boolean;
  onClick?: () => void;
  onChange?: ((value: string) => void) | undefined;
}

export const SportSliderItemComponent = forwardRef<
  HTMLDivElement,
  SportSliderItemProps
>(
  (
    {
      sport,
      count,
      icon: Icon,
      label,
      isDesktop,
      withCounts = false,
      isFavorite = false,
      onClick,
      onChange,
      ...props
    },
    ref,
  ) => {
    const { isMobileOrTablet } = useMedia();

    const onClickHandler = useCallback(() => {
      if (onClick) {
        onClick();
      }

      if (onChange && sport) {
        onChange(sport.toString() as string);

        return;
      }
    }, [sport, onClick, onChange]);

    return (
      <StyledSportSliderItem
        isFavorite={isFavorite}
        shouldHover={!isMobileOrTablet}
        {...props}
        onClick={onClickHandler}
        ref={ref}
      >
        <Box
          className="icon"
          css={{
            position: 'relative',
            lineHeight: 0,
            '& > *': {
              cursor: 'pointer',
            },
          }}
        >
          {Icon && <Icon />}
          {getSportIcon(sport!)}
          <Tag
            text={count}
            className={`${withCounts && 'with-counts'} tag}`}
            color="transparent"
            css={{ ...styledSportSliderTagCSS }}
          />
        </Box>
        <Text
          className="text"
          level={isDesktop ? '14-20' : '10-20'}
          css={{
            cursor: 'pointer',
          }}
        >
          {label || sport}
        </Text>
      </StyledSportSliderItem>
    );
  },
);

export const SportSliderItem = memo(SportSliderItemComponent);
