import { useEffect } from 'react';

import { useLazyGetFavoriteMarkets } from '~api/market/marketQueries';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoriteMarkets } from '~store/slices/mainMarketsSlice';
import {
  selectIsUserLoggedIn,
  selectIsUserProfileLoaded,
} from '~store/slices/userSlice';

export const useLoadFavouriteMarkets = () => {
  const dispatch = useAppDispatch();
  const { lazyGetFavoriteMarketsQuery } = useLazyGetFavoriteMarkets();

  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const isUserProfileLoaded = useAppSelector(selectIsUserProfileLoaded);

  useEffect(() => {
    const loadFavoriteMarkets = async () => {
      const favoriteMarketsData = await lazyGetFavoriteMarketsQuery().unwrap();

      dispatch(setFavoriteMarkets(favoriteMarketsData));
    };

    if (isUserProfileLoaded && isUserLoggedIn) {
      loadFavoriteMarkets();
    }
  }, [isUserProfileLoaded, isUserLoggedIn]);
};
