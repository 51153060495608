import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import {
  CoinsResponse,
  CryptoTransaction,
  GetAddressPayload,
  MinAmountResponse,
  WithdrawalRequestPayload,
  WithdrawalResponse,
} from '~api/cryptoPayments/types';

export const cryptoPaymentsApi = createApi({
  reducerPath: 'cryptoPaymentsApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.CRYPTO_PAYMENTS }),
  endpoints: (builder) => ({
    getCoinsList: builder.query<CoinsResponse, void>({
      query: () => ({
        url: `/wallets/coins`,
      }),
    }),
    getMinAmount: builder.query<MinAmountResponse, string>({
      query: (coin) => ({
        url: `/wallets/minamount?from=${coin}`,
      }),
    }),
    getAddress: builder.mutation<CryptoTransaction, GetAddressPayload>({
      query: (body) => {
        return {
          url: `/wallets/getAddress`,
          method: 'POST',
          body,
        };
      },
    }),
    withdrawal: builder.mutation<WithdrawalResponse, WithdrawalRequestPayload>({
      query: (body) => {
        return {
          url: `/wallets/withdraw`,
          method: 'POST',
          body,
        };
      },
    }),
    validateAddress: builder.mutation<boolean, { address: string }>({
      query: (body) => {
        return {
          url: `/wallets/withdraw/validate`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useLazyGetCoinsListQuery,
  useLazyGetMinAmountQuery,
  useGetAddressMutation,
  useWithdrawalMutation,
  useValidateAddressMutation,
} = cryptoPaymentsApi;
