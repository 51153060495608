import { useEffect, useState } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';

import { useAppDispatch } from '~store';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';

const Y_AXIS_CLOSE_PLACEMENT = 100;

export const useSwipe = () => {
  const dispatch = useAppDispatch();
  const initialPosition = { x: 0, y: 0 };
  const [position, setPosition] = useState(initialPosition);

  const handleClose = () => {
    dispatch(setIsSwipeOpen(null));
  };

  const handleStop = (_event: DraggableEvent, data: DraggableData) => {
    if (data.y > Y_AXIS_CLOSE_PLACEMENT) {
      handleClose();
      setPosition(initialPosition);
    } else {
      setPosition(initialPosition);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setIsSwipeOpen(null));
    };
  }, []);

  return {
    handleStop,
    position,
  };
};
