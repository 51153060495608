import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  INFO_PAGE_BASE_PATH,
  INFO_PAGE_SECTION_NAMES,
} from '~constants/common';
import { useMedia, useTranslation } from '~hooks';
import { ContactUsIcon } from '~icons';

const StyledContactUsButton = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  color: '$textPrimaryOne',
  cursor: 'pointer',
  svg: {
    width: '36px',
    height: '36px',
  },
  '@xs_sm': {
    flexDirection: 'column',
    svg: {
      width: '24px',
      height: '24px',
    },
    gap: '0',
  },
});

export const ContactUsButton = memo(() => {
  const { localized } = useTranslation();
  const navigate = useNavigate();
  const { isMobileOrTablet } = useMedia();

  const handleContactUsClick = () => {
    navigate(`/${INFO_PAGE_BASE_PATH}/${INFO_PAGE_SECTION_NAMES.CALLBACK}`);
  };

  return (
    <StyledContactUsButton shouldHover onClick={handleContactUsClick}>
      <ContactUsIcon />
      <Box flexCol alignStart>
        {!isMobileOrTablet && (
          <Text level="12-16" textTransform="uppercase" fontWeight="medium">
            {localized('buttons.contactUs')}
          </Text>
        )}
        <Text level="12-16" textTransform="uppercase" fontWeight="medium">
          {localized('buttons.help')}
        </Text>
      </Box>
    </StyledContactUsButton>
  );
});
