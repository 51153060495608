import { AuthTemplate } from '~components/molecules/Authentication/components/AuthTemplate';
import { VerifyPersonalDetailsContent } from '~components/molecules/Authentication/components/VerifyPersonalDetails/VerifyPersonalDetailsContent';
import { useMedia } from '~hooks/useMedia';

export const VerifyPersonalDetails = () => {
  const { isMobileOrTablet } = useMedia();

  return isMobileOrTablet ? (
    <AuthTemplate>
      <VerifyPersonalDetailsContent />
    </AuthTemplate>
  ) : (
    <VerifyPersonalDetailsContent />
  );
};
