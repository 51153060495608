import React from 'react';

import { Games } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { GamesGrid } from '~components/molecules/Games';
import { StyledSearchResultWrapper } from '~components/molecules/GamesModalFilters/components/styled.components';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';

interface SearchResultGamesProps {
  games: Games;
}

export const SearchResultGames = ({ games }: SearchResultGamesProps) => {
  const { localized } = useTranslation();

  const {
    isMobileOrTablet,
    isDesktop,
    isLaptop,
    isLargeDesktop,
    isLaptopOrDesktop,
  } = useMedia();

  return (
    <StyledSearchResultWrapper>
      <Box
        flexRow
        alignCenter
        fullWidth
        justifyContentBetween
        css={{
          pb: '$4',
          pl: '$3',
        }}
      >
        <Text
          level={isMobileOrTablet ? '14-20' : '16-28'}
          textTransform="uppercase"
        >
          {localized('casino.games')}
        </Text>
        <Text
          level={isMobileOrTablet ? '14-20' : '16-28'}
          color="textSecondaryOne"
        >
          {games.length}
        </Text>
      </Box>
      <Box
        css={
          !isMobileOrTablet
            ? {
                p: '$2',
                borderRadius: '$10',
                background: '$primaryOne',
              }
            : {}
        }
      >
        <GamesGrid
          games={games}
          tabletLayout={isDesktop || isLaptop}
          isFromSearch={true}
          gameCardVariant={
            isLaptopOrDesktop || isLargeDesktop ? 'xs' : undefined
          }
        />
      </Box>
    </StyledSearchResultWrapper>
  );
};
