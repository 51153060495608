export enum SPORT_GROUP_COMPONENTS {
  FAVORITE_TOURNAMENT = 'favoriteTournament',
  TOP_TOURNAMENT = 'topTournament',
  FAVORITE_EVENT = 'favoriteEvent',
  TOP_EVENT = 'topEvent',
}

export enum SPORT_TAB_GROUPS {
  ALL = 'all',
  UPCOMMING = 'upcomming',
  TOP = 'top',
}
