import { useLazySettingsQuery } from './partnerApi';

export const useLazySettings = () => {
  const [query, { data, error, isLoading }] = useLazySettingsQuery();

  return {
    lazySettingsQuery: query,
    lazySettingsData: data,
    lazySettingsError: error,
    lazySettingsIsLoading: isLoading,
  };
};
