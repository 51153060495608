import React from 'react';

import { IconCellProps } from '~components/molecules/BetslipHistorySection/types';
import { TOGGLE_DATA_STATE } from '~constants/common';
import { ChevronDownIcon } from '~icons';

import { StyledIconBox } from '../styled.components';

export const TableIconExpandCell = <T extends object>({
  isOpen,
  onClick,
}: IconCellProps<T>) => {
  return (
    <StyledIconBox
      flexRow
      className={isOpen ? 'open' : ''}
      onClick={onClick}
      isOpen={isOpen}
    >
      <ChevronDownIcon
        data-state={isOpen ? TOGGLE_DATA_STATE.OPEN : TOGGLE_DATA_STATE.CLOSED}
      />
    </StyledIconBox>
  );
};
