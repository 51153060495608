import React, { Fragment, memo } from 'react';
import {
  Col,
  ConfigProvider,
  Divider as AntDivider,
  Row,
  Skeleton,
  Space,
} from 'antd';
import { styled } from 'stitches.config';

const AntStyledSkeleton = styled('div', {
  padding: '10px',
});

const StyledDivider = styled(AntDivider, {
  margin: '5px 0',
});

const rowOneLargeInput = (
  <Row gutter={10}>
    <Col span={24}>
      <Skeleton.Input active size={'large'} block />
    </Col>
  </Row>
);

const rowOneSmallInput = (
  <Row gutter={10}>
    <Col span={24}>
      <Skeleton.Input active size={'small'} block />
    </Col>
  </Row>
);

const rowTwoSmallInputs = (
  <Row gutter={10}>
    <Col span={12}>
      <Skeleton.Input active size={'small'} block />
    </Col>
    <Col span={12}>
      <Skeleton.Input active size={'small'} block />
    </Col>
  </Row>
);

const blockThreeRowTwoSmallInputs = (
  <>
    {rowTwoSmallInputs}
    {rowTwoSmallInputs}
    {rowTwoSmallInputs}
  </>
);

interface SkeletonAntProps {
  theme?: 'dark' | 'light';
  isSingleColumn?: boolean;
}

const SkeletonFull = memo(
  ({ theme = 'dark', isSingleColumn }: SkeletonAntProps) => {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorFillContent:
              theme === 'light' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.07)',
          },
        }}
      >
        <AntStyledSkeleton>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            {isSingleColumn ? (
              Array.from({ length: 20 }).map((_, index) => {
                return <Fragment key={index}>{rowOneSmallInput}</Fragment>;
              })
            ) : (
              <>
                {isSingleColumn}
                {rowOneLargeInput}
                {rowOneLargeInput}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
                <StyledDivider />
                {blockThreeRowTwoSmallInputs}
              </>
            )}
          </Space>
        </AntStyledSkeleton>
      </ConfigProvider>
    );
  },
);

export { SkeletonFull };
