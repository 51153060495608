import React, { ReactNode, useState } from 'react';
import * as DropdownUI from '@radix-ui/react-dropdown-menu';
import { styled } from 'stitches.config';

import { Button } from '~components/atoms/Button';
import { SelectOptionType } from '~components/atoms/SelectWithLabel/SelectWithLabel';
import { SettingsDropdownPopoverDesktop } from '~components/atoms/SettingsDropdown/SettingsDropdownPopoverDesktop';
import { SettingsDropdownPopoverMobile } from '~components/atoms/SettingsDropdown/SettingsDropdownPopoverMobile';
import { LANGUAGES } from '~constants/language';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import useWindowDimensions from '~hooks/useWindowDimensions';

import {
  StyledDropdownMenuContent,
  StyledDropdownTrigger,
  StyledFlagWrapper,
} from './styled.components';

interface SettingsDropdownPopoverProps {
  children: ReactNode;
  onOpenChange?: (val: boolean) => void;
}

export const { langOptions } = LANGUAGES.reduce(
  (acc, lang) => {
    acc.langOptions.push({
      ...lang,
      label: <StyledFlagWrapper>{lang.label}</StyledFlagWrapper>,
      title: lang.label,
    } as SelectOptionType);

    return acc;
  },
  {
    langOptions: [] as SelectOptionType[],
  },
);

export const BlurBackdrop = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backdropFilter: 'blur(4px)',
  zIndex: 30,
});

export const SettingsDropdownPopover = ({
  children,
  onOpenChange,
}: SettingsDropdownPopoverProps) => {
  const { isMobileOrTablet } = useMedia();
  const [isOpen, setIsOpen] = useState(false);
  const { localized } = useTranslation();

  const { width } = useWindowDimensions();

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpenChange?.(open);
  };

  return (
    <DropdownUI.Root onOpenChange={handleOpenChange} open={isOpen}>
      <StyledDropdownTrigger asChild>{children}</StyledDropdownTrigger>
      <DropdownUI.Portal>
        <>
          {isOpen && isMobileOrTablet && <BlurBackdrop />}
          <StyledDropdownMenuContent
            sideOffset={isMobileOrTablet ? -45 : 30}
            align="start"
            alignOffset={!isMobileOrTablet ? -337 : -width + 52}
            css={
              isMobileOrTablet
                ? {
                    width: `${width}px !important`,
                  }
                : {}
            }
          >
            {isMobileOrTablet ? (
              <SettingsDropdownPopoverMobile />
            ) : (
              <SettingsDropdownPopoverDesktop />
            )}
            {isMobileOrTablet && (
              <Button
                size="medium"
                variant="secondary"
                onClick={() => handleOpenChange(false)}
                css={{
                  height: '44px',
                  fontSize: '18px',
                  lineHeight: '24px',
                }}
              >
                {localized('buttons.close')}
              </Button>
            )}
          </StyledDropdownMenuContent>
        </>
      </DropdownUI.Portal>
    </DropdownUI.Root>
  );
};
