import React, { useMemo } from 'react';

import { LiveGroup } from '~api/category/types';
import { InPlayMenuSports } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { ToggleFavoriteEventSportMarket } from '~components/atoms/ToggleMarket/ToggleFavoriteEventSportMarket';
import { useMedia } from '~hooks/useMedia';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionRoot,
  MultiAccordionTrigger,
} from '../../MultiAccordion';

import { LiveFavoriteEventListMenu } from './LiveFavoriteEventListMenu';

export interface LiveFavoriteEventGroupProps {
  sportGroupsMemo: LiveGroup[];
  sports: InPlayMenuSports;
}

export const LiveFavoriteEventGroup = ({
  sportGroupsMemo,
  sports = [],
}: LiveFavoriteEventGroupProps) => {
  const { isMobileOrTablet } = useMedia();

  const memoizedAccordionItems = useMemo(
    () =>
      sportGroupsMemo?.map(
        ({ id, name, favoriteEvents }) =>
          !!favoriteEvents?.length && (
            <MultiAccordionItem
              value={id.toString()}
              key={id}
              role={MULTI_ACCORDION_ROLES.CHILD}
            >
              <MultiAccordionTrigger
                title={name}
                icon={id}
                count={favoriteEvents.length}
                role={MULTI_ACCORDION_ROLES.CHILD}
                isPrefixWithPadding={isMobileOrTablet}
              />
              <MultiAccordionContent
                role={MULTI_ACCORDION_ROLES.CHILD}
                css={{ p: isMobileOrTablet ? '$2' : 'default' }}
              >
                <Box flexCol gap={1}>
                  <ToggleFavoriteEventSportMarket sportId={id} />
                  <LiveFavoriteEventListMenu events={favoriteEvents} />
                </Box>
              </MultiAccordionContent>
            </MultiAccordionItem>
          ),
      ),
    [sportGroupsMemo],
  );

  return (
    <MultiAccordionRoot
      disabled
      type="multiple"
      value={sports.map(({ id }) => id.toString())}
      role={MULTI_ACCORDION_ROLES.CHILD}
      css={{
        '@xs_sm': {
          p: '$2 $1',
          backgroundColor: '$primaryOne',
          borderRadius: 'unset',
        },
      }}
    >
      {memoizedAccordionItems}
    </MultiAccordionRoot>
  );
};
