import { Autocomplete } from '~components/atoms/Autocomplete';
import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { Message } from '~components/atoms/Message';
import { AddBankAccountButton } from '~components/molecules/UserProfile/components/Payments/components/AddBankAccountButton';
import { AmountWithButtons } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/components/AmountWithButtons';
import { PaymentsBy } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/components/PaymentsBy';
import { PaymentSubmitButton } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/components/PaymentSubmitButton';
import { StyledMessageWrapper } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/styled.components';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { CURRENCY_CODES, MESSAGE_TYPES } from '~constants/common';
import { PAYMENT_PROVIDER } from '~constants/payments';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setBankCode,
  setBankNumber,
  setCardHolderEmail,
} from '~store/slices/paymentsSlice';

import { useBankPaymentForm } from '../../hooks/useBankPaymentForm';
import { StyledBaseInputWrapper } from '../StyledBaseInput';
import { StyledBaseSelect } from '../StyledBaseSelect';

export const BankPayment = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const {
    isDeposit,
    activePaymentTab,
    addingNewCard,
    currency,
    contentTab,
    cardHolderEmail,
    banks,
    bankNumber,
    bankCode,
    paymentMethodAmount,
    min,
    max,
    selectedNigeriaMethod,
    messageText,
    messageType,
    isLowerOrIsBigger,
    isAmountBiggerThenBalance,
    handleChangeAmount,
    isSubmitButtonDisabled,
  } = useBankPaymentForm();
  const { profile } = useAppSelector((state) => state.userState);

  const userProfileEmail = profile?.email;

  const showAddButton =
    (!addingNewCard && activePaymentTab === PAYMENT_PROVIDER.RELWORX) ||
    (!addingNewCard &&
      contentTab === USER_PAYMENT_TABS.WITHDRAW &&
      [PAYMENT_PROVIDER.PAYSTACK, PAYMENT_PROVIDER.FLUTTERWAVE].includes(
        activePaymentTab,
      ));

  const showFillBankDataForm =
    contentTab === USER_PAYMENT_TABS.WITHDRAW &&
    activePaymentTab !== PAYMENT_PROVIDER.RELWORX;

  const isNigeria = currency === CURRENCY_CODES.NGN;
  const isZambia = currency === CURRENCY_CODES.ZMK;

  return (
    <>
      <AmountWithButtons
        isDeposit={isDeposit}
        amount={paymentMethodAmount}
        onChange={handleChangeAmount}
        isError={isLowerOrIsBigger}
        min={min}
        max={max}
      />
      {showAddButton ? (
        <AddBankAccountButton />
      ) : (
        <Box css={{ mt: '$3' }} fullWidth flexCol gap={2}>
          {!userProfileEmail?.length &&
            activePaymentTab !== PAYMENT_PROVIDER.RELWORX && (
              <StyledBaseInputWrapper>
                <Input
                  onChange={(e) => dispatch(setCardHolderEmail(e.target.value))}
                  value={cardHolderEmail}
                  type="email"
                  placeholder={localized(
                    'cardFormPlaceholders.cardHolderEmail',
                  )}
                  css={{
                    width: '100%',
                    lineHeight: '$24',
                    p: '0 $2',
                    borderRadius: '$6',
                  }}
                />
              </StyledBaseInputWrapper>
            )}
          {showFillBankDataForm ? (
            <>
              <Input
                onChange={(e) => dispatch(setBankNumber(e.target.value))}
                value={bankNumber}
                placeholder={localized('withdrawForm.bankNumber')}
                css={{
                  height: '36px',
                  width: '100%',
                  lineHeight: '$24',
                  p: '0 $3',
                  '&:focus': {
                    boxShadow: '$elementActiveBorder',
                  },
                }}
              />
              <StyledBaseSelect>
                <Autocomplete
                  placeholder={localized('withdrawForm.bankCode')}
                  value={bankCode}
                  onChange={(value) => dispatch(setBankCode(value))}
                  options={banks}
                />
              </StyledBaseSelect>
            </>
          ) : null}
        </Box>
      )}
      {selectedNigeriaMethod === 'card' &&
        contentTab === USER_PAYMENT_TABS.DEPOSIT &&
        (isNigeria || isZambia) && <PaymentsBy />}
      <PaymentSubmitButton disabled={isSubmitButtonDisabled} />
      {!!messageText?.length && !isDeposit && !isAmountBiggerThenBalance ? (
        <StyledMessageWrapper>
          <Message
            type={messageType as MESSAGE_TYPES}
            textArray={messageText}
            css={{ mt: '$3' }}
          />
        </StyledMessageWrapper>
      ) : null}
    </>
  );
};
