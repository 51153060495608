import * as Accordion from '@radix-ui/react-accordion';

import { Box } from '~components/atoms/Box';
import { BetslipTableDetailsExpanderProps } from '~components/molecules/BetslipHistorySection/types';

import { StyledCollapsibleContent } from '../styled.components';

export const TableDetailsExpander = ({
  columnsOuterLength,
  getOpenRowsValue,
  rowIndex,
  openRows,
  children,
}: BetslipTableDetailsExpanderProps) => {
  return (
    <tr>
      <td className="expandableContent" colSpan={columnsOuterLength}>
        <Accordion.Root type="multiple" value={getOpenRowsValue(rowIndex)}>
          <Accordion.Item value={openRows[rowIndex]?.toString() || ''}>
            <Box
              css={{
                background: '$secondaryTwo',
                height: '100%',
                padding: '$2',
                borderRadius: '0px 0px $8 $8',
              }}
            >
              <StyledCollapsibleContent>{children}</StyledCollapsibleContent>
            </Box>
          </Accordion.Item>
        </Accordion.Root>
      </td>
    </tr>
  );
};
