import { GAME_PROVIDERS } from '~constants/providers';
import { SVGElement } from '~types/general';

export enum PROVIDERS_FILTER_TYPE {
  POPULAR = 'popular',
  ALPHABET = 'alphabet',
  AMOUNT = 'amount',
}

export interface ProviderMapObject {
  id: number;
  name: string;
  icon: SVGElement;
  iconPath: string;
}

export interface Provider {
  id: GAME_PROVIDERS;
  name: string;
  icon: SVGElement;
  gameCount: number;
  order: number;
}

export interface ProviderStats {
  gameProvider: GAME_PROVIDERS;
  order: number;
  gameCount: number;
}

export type ProvidersStats = ProviderStats[];

export type ProvidersList = Provider[];

export type ProviderSortState = 'asc' | 'desc';

export interface ProviderFilters {
  popular?: boolean;
  alphabet?: ProviderSortState;
  amount?: ProviderSortState;
}

export type Providers = Provider[];
