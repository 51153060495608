import { Bonus } from '~api/bonus/types';
import { useFormatAmountWithCurrency } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import {
  calculateTimeRemainingInSeconds,
  formatDateTime,
  getDaysHoursMinutesFromSeconds,
  TIME_FORMATS,
} from '~utils/formatDateTime';

export const useActiveBonusCard = ({ bonus }: { bonus: Bonus }) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  const {
    title,
    totalAmount,
    remainingAmount,
    won,
    maxWin,
    createDate,
    expireDate,
    betSlipCodes,
  } = bonus || {};
  const { days, hours, minutes } = getDaysHoursMinutesFromSeconds(
    calculateTimeRemainingInSeconds(expireDate),
  );
  const progress = (1 - remainingAmount / totalAmount) * 100;
  const remainingAmountText = `${getCommaSeparatedAmount(
    totalAmount - remainingAmount,
    true,
  )} / ${getCommaSeparatedAmount(totalAmount)}`;
  const givenDate = `${localized('bonuses.givenDate')} ${formatDateTime(
    createDate,
    TIME_FORMATS.DATE,
  )}`;
  const maxWinText =
    maxWin === null
      ? `${getCommaSeparatedAmount(won)}`
      : `${getCommaSeparatedAmount(won, true)} / ${getCommaSeparatedAmount(maxWin)}`;

  return {
    betSlipCodes,
    days,
    givenDate,
    hours,
    isMobileOrTablet,
    maxWinText,
    minutes,
    progress,
    remainingAmountText,
    title,
    localized,
  };
};
