import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { LeagueEventsMenuMobile } from '~components/molecules/PrematchEvents/components/LeagueEventsMenuMobile';
import { PrematchEventMarkets } from '~components/molecules/PrematchEvents/components/PrematchEventMarkets';
import { MobilePrematchMenu } from '~components/molecules/PrematchMenu';
import { useQueryParams } from '~hooks/useQueryParams';

export enum SPORT_TAB_CONTENTS {
  PREMATCH_MENU = 'prematchMenu',
  PREMATCH_EVENTS = 'prematchEvents',
  PREMATCH_EVENT_MARKETS = 'prematchEventMarkets',
}

export const SportTab = () => {
  const { leagueId, eventId } = useQueryParams();

  if (eventId) {
    return <PrematchEventMarkets />;
  }

  if (leagueId) {
    return <LeagueEventsMenuMobile />;
  }

  return (
    <>
      <BannerList names={[BANNER_GROUP_NAMES.HEADER]} />
      <MobilePrematchMenu />
    </>
  );
};

export default SportTab;
