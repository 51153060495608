import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

import { useSubscribeEmailMutation } from './emailApi';

export const useSubscribeEmail = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSubscribeEmailMutation();

  const subscribeEmail = (email: string) => {
    try {
      mutation(email);
    } catch (error) {
      console.error('Unable to subscribe email', error);
    }
  };

  return {
    subscribeEmailMutation: mutation,
    subscribeEmailData: data,
    subscribeEmailError: error,
    subscribeEmailIsError: isError,
    subscribeEmailErrorCode: getErrorCode(error, isError),
    subscribeEmailErrorMessage: getErrorMessage(error),
    subscribeEmailIsSuccess: isSuccess,
    subscribeEmailIsLoading: isLoading,
    subscribeEmail,
  };
};
