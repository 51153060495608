import React from 'react';
import { PatternFormat } from 'react-number-format';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';
import { DEFAULT_PHONE_MASK_NO_DASH } from '~constants/auth';
import { PHONE_MASKS, PHONE_MASKS_NO_DASH } from '~constants/phoneMasks';
import { useAppSelector } from '~store';
import { getMobileSettings } from '~store/slices/selectors/settings';

interface PhoneNumberInputProps {
  phoneNumber: string;
  onChange: (phoneNumber: string) => void;
  isError?: boolean;
}

const textSize = {
  fontSize: '$16',
  lineHeight: '$28',
  '@lg_xl': {
    fontSize: '$18',
    lineHeight: '$24',
  },
  '@xs': {
    fontSize: '$12',
    lineHeight: '$20',
  },
};

const StyledTitleText = styled(Text, {
  textTransform: 'uppercase',
  ...textSize,
});

const StyledPhoneInput = styled(Input, {
  backgroundColor: 'transparent',
  padding: '0 $1',
  width: '100%',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
  },
  ...textSize,
});

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  phoneNumber,
  onChange,
  isError = false,
}) => {
  const mobileSettings = useAppSelector(getMobileSettings);
  const phoneMask =
    phoneNumber.length > 0
      ? PHONE_MASKS[mobileSettings?.countryAlpha2Code]
      : PHONE_MASKS_NO_DASH[mobileSettings?.countryAlpha2Code];

  return (
    <Box
      flexRow
      css={{
        paddingLeft: '$3',
        '& > input': {
          color: isError ? '$redPrimary' : '$textPrimaryOne',
        },
      }}
    >
      {mobileSettings?.mobileCode && (
        <Box>
          <StyledTitleText color={isError ? 'redPrimary' : 'textSecondaryOne'}>
            {`(${mobileSettings?.mobileCode})`}
          </StyledTitleText>
        </Box>
      )}
      <PatternFormat
        allowEmptyFormatting
        value={phoneNumber}
        displayType="input"
        format={phoneMask || DEFAULT_PHONE_MASK_NO_DASH}
        customInput={StyledPhoneInput}
        onValueChange={(values) => onChange(values.value)}
      />
    </Box>
  );
};
