import { useEffect, useState } from 'react';

import {
  useLazyGetAllTransactions,
  useLazyGetTransactions,
} from '~api/transaction/transactionQueries';
import { TransactionHistoryFilter } from '~api/transaction/types';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setFilterParams,
  setIsLoaded,
  setTransactions,
} from '~store/slices/transactionHistorySlice';

const PAGING_LIMIT = 20;

export const useTransactionHistory = (isAllTransactions?: boolean) => {
  const dispatch = useAppDispatch();
  const [total, setTotal] = useState(0);
  const { transactions, filterParams, isLoaded } = useAppSelector(
    (state) => state.transactionHistory,
  );
  const { lazyGetTransactionsQuery, lazyGetTransactionsIsLoading } =
    useLazyGetTransactions();
  const { lazyGetAllTransactionsQuery, lazyGetAllTransactionsIsLoading } =
    useLazyGetAllTransactions();

  const loadTransactions = async (
    params?: Partial<TransactionHistoryFilter>,
    loadMore?: boolean,
  ) => {
    if (!loadMore) {
      dispatch(setFilterParams({}));
      transactions.length && dispatch(setTransactions([]));
    }

    if (params) {
      dispatch(setFilterParams(params));
    }

    const page = !loadMore
      ? 1
      : Math.ceil(transactions.length / PAGING_LIMIT) + 1;

    try {
      let response;

      if (isAllTransactions) {
        response = await lazyGetAllTransactionsQuery({
          ...(params || {}),
          ...(loadMore ? filterParams : {}),
          limit: PAGING_LIMIT,
          page,
        }).unwrap();
      } else {
        response = await lazyGetTransactionsQuery({
          ...(params || {}),
          ...(loadMore ? filterParams : {}),
          limit: PAGING_LIMIT,
          page,
        }).unwrap();
      }

      if ('total' in response) {
        const { data, total } = response;

        setTotal(total);
        dispatch(setTransactions([...(!params ? transactions : []), ...data]));
        dispatch(setIsLoaded(true));
      }
    } catch (error) {
      console.error('Error while loading transactions', error);
    }
  };

  useEffect(() => {
    if (transactions.length) {
      dispatch(setTransactions([]));
    }

    return () => {
      dispatch(setIsLoaded(false));
    };
  }, [isAllTransactions]);

  useEffect(() => {
    if (!transactions.length && !isLoaded) {
      loadTransactions();
    }
  }, [transactions, isLoaded]);

  return {
    transactions,
    total,
    isLoading: lazyGetTransactionsIsLoading || lazyGetAllTransactionsIsLoading,
    loadTransactions: loadTransactions,
    handleLoadMore: () => loadTransactions(undefined, true),
  };
};
