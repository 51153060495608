import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import {
  AuthorizedDepositPayload,
  BankDepositPaystackPayload,
  PaymentDetailsPayloadPaystack,
  PaystackBank,
  PaystackChargeResponse,
  PaystackWithdraw,
  PaystackWithdrawResponse,
} from '~api/paystack/types';

export const paystackApi = createApi({
  reducerPath: 'paystackApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.PAYSTACK }),
  endpoints: (builder) => ({
    paystackCardCharge: builder.mutation<
      PaystackChargeResponse,
      PaymentDetailsPayloadPaystack
    >({
      query: ({ saveCard, ...body }) => ({
        url: `/cardcharge/${saveCard ? 'true' : 'false'}`,
        method: 'POST',
        body,
      }),
    }),
    paystackWithdraw: builder.mutation<
      PaystackWithdrawResponse,
      PaystackWithdraw
    >({
      query: ({ saveCard, ...body }) => ({
        url: `/bankwithdraw/${saveCard ? 'true' : 'false'}`,
        method: 'POST',
        body,
      }),
    }),
    getPaystackBanks: builder.query<PaystackBank[], void>({
      query: () => ({
        url: `/getbanks`,
        method: 'GET',
      }),
    }),
    authorizedDepositPaystack: builder.mutation<
      PaystackChargeResponse,
      AuthorizedDepositPayload
    >({
      query: (body) => ({
        url: '/cardchargeauthorization',
        method: 'POST',
        body,
      }),
    }),
    bankDepositPaystack: builder.mutation<
      PaystackChargeResponse,
      BankDepositPaystackPayload
    >({
      query: (body) => ({
        url: '/deposit',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  usePaystackCardChargeMutation,
  usePaystackWithdrawMutation,
  useLazyGetPaystackBanksQuery,
  useAuthorizedDepositPaystackMutation,
  useBankDepositPaystackMutation,
} = paystackApi;
