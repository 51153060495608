import { useEffect } from 'react';

import {
  useFlutterwaveCardChargeWithAuth,
  useValidateCartCharge,
} from '~api/flutterwave/flutterwaveMutations';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  ErrorData,
  SUCCESS_STATUS,
} from '~constants/payments';
import { useCountdownTimer } from '~hooks/useCountdownTimer';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNewPaymentMethodFormStatus,
  setCode,
  setCountDown,
  setError,
  setFlwRef,
  setIsLoading,
  setPinPayload,
  setTransactionStatus,
} from '~store/slices/paymentsSlice';
import { TRANSACTION_STATUS } from '~types/transactions';
import { addTwoMinutesToCurrentTimeISO } from '~utils/formatDateTime';

import { extractErrorMessage } from '../helpers';

export const useCardPinOtpForm = () => {
  const dispatch = useAppDispatch();
  const {
    code,
    addingNewPaymentMethodFormStatus,
    txRef,
    flwRef,
    basePayload,
    pinPayload,
    error,
    countDown,
  } = useAppSelector((state) => state.payments);
  const { profile } = useAppSelector((state) => state.userState);

  const { timeRemaining, formatTime, isTimerAvailable, resetTimer } =
    useCountdownTimer(countDown || '');

  const { flutterwaveCardChargeWithAuthMutation } =
    useFlutterwaveCardChargeWithAuth();
  const { validateCartChargeMutation } = useValidateCartCharge();

  const resendInitialCharge = async () => {
    if (
      addingNewPaymentMethodFormStatus ===
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.PIN &&
      basePayload
    ) {
      //await flutterwaveCardChargeMutation(basePayload);
      resetTimer(addTwoMinutesToCurrentTimeISO());
    }

    if (
      addingNewPaymentMethodFormStatus ===
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP &&
      pinPayload
    ) {
      await flutterwaveCardChargeWithAuthMutation(pinPayload);
      resetTimer(addTwoMinutesToCurrentTimeISO());
    }
  };

  const onConfirm = async () => {
    try {
      dispatch(setIsLoading(true));
      if (
        addingNewPaymentMethodFormStatus ===
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.PIN
      ) {
        const payload = {
          txRef,
          pin: code,
          mode: addingNewPaymentMethodFormStatus,
        };

        dispatch(setPinPayload(payload));

        const response = await flutterwaveCardChargeWithAuthMutation(payload);

        if ('data' in response) {
          dispatch(setCode(''));
          const mode = response?.data?.meta?.authorization?.mode;

          if (mode === ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP) {
            const flwRef = response?.data?.data?.flwRef;

            if (flwRef) {
              dispatch(dispatch(setCountDown(null)));
              dispatch(setFlwRef(flwRef));
              dispatch(
                setAddingNewPaymentMethodFormStatus(
                  ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP,
                ),
              );
              dispatch(setCountDown(addTwoMinutesToCurrentTimeISO()));
            }
          }
        } else if ('error' in response) {
          dispatch(setError(extractErrorMessage(response.error as ErrorData)));
        }
      } else if (
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP ===
        addingNewPaymentMethodFormStatus
      ) {
        const payload = {
          flwRef,
          otp: code,
        };
        const response = await validateCartChargeMutation(payload);

        if ('data' in response && response?.data?.status === SUCCESS_STATUS) {
          dispatch(setTransactionStatus(TRANSACTION_STATUS.SUCCESS));
        } else {
          dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
        }

        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
          ),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    dispatch(setError(null));
    dispatch(setCode(value));
  };

  const onBack = () => {
    dispatch(
      setAddingNewPaymentMethodFormStatus(
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
      ),
    );
    setCountDown(null);
    dispatch(setCode(''));
  };

  useEffect(() => {
    resetTimer(addTwoMinutesToCurrentTimeISO());
  }, [countDown]);

  return {
    error,
    code,
    addingNewPaymentMethodFormStatus,
    profile,
    timeRemaining,
    isTimerAvailable,
    dispatch,
    formatTime,
    onConfirm,
    resendInitialCharge,
    handleChangeCode,
    onBack,
  };
};
