import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

import {
  useApplyPromoCodeMutation,
  useCallUserBackMutation,
  useChangeDefaultBalanceMutation,
  useChangeUserPasswordMutation,
  useDeactivateUserMutation,
  useIdVerifyMutation,
  useTerminateOtherSessionsMutation,
  useUpdateUserProfileMutation,
  useUploadUserDocumentMutation,
} from './userApi';

export const useUpdateUserProfile = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useUpdateUserProfileMutation();

  return {
    updateUserProfileMutation: mutation,
    updateUserProfileData: data,
    updateUserProfileError: error,
    updateUserProfileIsError: isError,
    updateUserProfileErrorCode: getErrorCode(error, isError),
    updateUserProfileErrorMessage: getErrorMessage(error),
    updateUserProfileIsSuccess: isSuccess,
    updateUserProfileIsLoading: isLoading,
  };
};

export const useChangeUserPassword = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useChangeUserPasswordMutation();

  return {
    changeUserPasswordMutation: mutation,
    changeUserPasswordError: error,
    changeUserPasswordIsError: isError,
    changeUserPasswordErrorCode: getErrorCode(error, isError),
    changeUserPasswordErrorMessage: getErrorMessage(error),
    changeUserPasswordIsSuccess: isSuccess,
    changeUserPasswordIsLoading: isLoading,
  };
};

export const useTerminateOtherSessions = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useTerminateOtherSessionsMutation();

  return {
    terminateOtherSessionsMutation: mutation,
    terminateOtherSessionsError: error,
    terminateOtherSessionsIsError: isError,
    terminateOtherSessionsErrorCode: getErrorCode(error, isError),
    terminateOtherSessionsErrorMessage: getErrorMessage(error),
    terminateOtherSessionsIsSuccess: isSuccess,
    terminateOtherSessionsIsLoading: isLoading,
  };
};

export const useApplyPromoCode = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useApplyPromoCodeMutation();

  return {
    applyPromoCodeMutation: mutation,
    applyPromoCodeError: error,
    applyPromoCodeIsError: isError,
    applyPromoCodeErrorCode: getErrorCode(error, isError),
    applyPromoCodeErrorMessage: getErrorMessage(error),
    applyPromoCodeIsSuccess: isSuccess,
    applyPromoCodeIsLoading: isLoading,
  };
};

export const useUploadUserDocument = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useUploadUserDocumentMutation();

  return {
    uploadUserDocumentMutation: mutation,
    uploadUserDocumentError: error,
    uploadUserDocumentIsError: isError,
    uploadUserDocumentErrorCode: getErrorCode(error, isError),
    uploadUserDocumentErrorMessage: getErrorMessage(error),
    uploadUserDocumentIsSuccess: isSuccess,
    uploadUserDocumentIsLoading: isLoading,
  };
};

export const useChangeDefaultBalance = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useChangeDefaultBalanceMutation();

  return {
    changeDefaultBalanceMutation: mutation,
    changeDefaultBalanceError: error,
    changeDefaultBalanceIsError: isError,
    changeDefaultBalanceErrorCode: getErrorCode(error, isError),
    changeDefaultBalanceErrorMessage: getErrorMessage(error),
    changeDefaultBalanceIsSuccess: isSuccess,
    changeDefaultBalanceIsLoading: isLoading,
  };
};

export const useIdVerify = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useIdVerifyMutation();

  return {
    idVerifyMutation: mutation,
    idVerifyError: error,
    idVerifyIsError: isError,
    idVerifyErrorCode: getErrorCode(error, isError),
    idVerifyErrorMessage: getErrorMessage(error),
    idVerifyIsSuccess: isSuccess,
    idVerifyIsLoading: isLoading,
  };
};

export const useDeactivateUser = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useDeactivateUserMutation();

  return {
    deactivateUserMutation: mutation,
    deactivateUserError: error,
    deactivateUserIsError: isError,
    deactivateUserErrorCode: getErrorCode(error, isError),
    deactivateUserErrorMessage: getErrorMessage(error),
    deactivateUserIsSuccess: isSuccess,
    deactivateUserIsLoading: isLoading,
  };
};

export const useCallUserBack = () => {
  const [mutation, { error, isError, isSuccess, isLoading }] =
    useCallUserBackMutation();

  return {
    callUserBackMutation: mutation,
    callUserBackError: error,
    callUserBackIsError: isError,
    callUserBackErrorCode: getErrorCode(error, isError),
    callUserBackErrorMessage: getErrorMessage(error),
    callUserBackIsSuccess: isSuccess,
    callUserBackIsLoading: isLoading,
  };
};
