import { QUICK_BET } from '~api/partner/types';
import { EVENT_STATUS } from '~constants/common';

export const isQuickBet = (
  eventStatus: EVENT_STATUS,
  quickBetStatus: QUICK_BET,
): boolean => {
  const isLive = eventStatus === EVENT_STATUS.IN_PROGRESS;

  if (quickBetStatus === QUICK_BET.NONE) return false;

  if (isLive) {
    return [QUICK_BET.ALL, QUICK_BET.INPLAY].includes(quickBetStatus);
  } else {
    return [QUICK_BET.ALL, QUICK_BET.PREMATCH].includes(quickBetStatus);
  }
};
