import { BankPayment } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/BankPayment';
import { CryptoPayment } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/CryptoPayment';
import { StyledDepositFormWrapper } from '~components/molecules/UserProfile/components/Payments/components/styled.components';
import { ADD_PAYMENT_ACCOUNT_FORM_STATUSES } from '~constants/payments';
import { useAppSelector } from '~store';
import { ContentsMap } from '~types/general';

import { AddCardAddressForm } from './CardMethod/AddCardAddressForm';
import { AddCardPinForm } from './CardMethod/AddCardPinForm';
import { Loader } from './CardMethod/Loader';
import { PaymentDone } from './CardMethod/PaymentDone';

export const DepositForm = () => {
  const { addingNewPaymentMethodFormStatus, isLoading } = useAppSelector(
    (state) => state.payments,
  );

  const formContentsMap: ContentsMap = {
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE]: <BankPayment />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.CRYPTO]: <CryptoPayment />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.ADDRESS]: <AddCardAddressForm />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP]: <AddCardPinForm />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.PIN]: <AddCardPinForm />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE]: <PaymentDone />,
  };

  return (
    <StyledDepositFormWrapper>
      {!isLoading ? (
        formContentsMap[addingNewPaymentMethodFormStatus]
      ) : (
        <Loader />
      )}
    </StyledDepositFormWrapper>
  );
};
