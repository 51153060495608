import { initReactI18next } from 'react-i18next';
import i18next, { use } from 'i18next';

import { LANGUAGE } from '~constants/common';

import arCommon from './ar/common.json';
import arError from './ar/error.json';
import common from './en/common.json';
import error from './en/error.json';
import esCommon from './es/common.json';
import esError from './es/error.json';
import faCommon from './fa/common.json';
import faError from './fa/error.json';
import frCommon from './fr/common.json';
import frError from './fr/error.json';
import hiCommon from './hi/common.json';
import hiError from './hi/error.json';
import kuCommon from './ku/common.json';
import kuError from './ku/error.json';
import ptCommon from './pt/common.json';
import ptError from './pt/error.json';
import ruCommon from './ru/common.json';
import ruError from './ru/error.json';

export const defaultNS = 'common';

use(initReactI18next).init({
  lng: LANGUAGE.EN, // if you're using a language detector, do not define the lng option
  debug: false,
  resources: {
    en: {
      common,
      error,
    },
    ru: {
      common: ruCommon,
      error: ruError,
    },
    ku: {
      common: kuCommon,
      error: kuError,
    },
    ar: {
      common: arCommon,
      error: arError,
    },
    pt: {
      common: ptCommon,
      error: ptError,
    },
    fa: {
      common: faCommon,
      error: faError,
    },
    es: {
      common: esCommon,
      error: esError,
    },
    fr: {
      common: frCommon,
      error: frError,
    },
    hi: {
      common: hiCommon,
      error: hiError,
    },
  },
  ns: ['common', 'error'],
  defaultNS,
});

export default i18next;
