import React from 'react';

import { ScrollArea } from '~components/atoms/ScrollArea';
import { GamesGrid } from '~components/molecules/Games';
import { ProvidersModalWrapper } from '~components/molecules/Providers/styled.components';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { selectGamesByProviderId } from '~store/slices/gamesSlice';

export const ProviderGames = () => {
  const { isLaptopOrDesktop, isLargeDesktop } = useMedia();
  const providerGames = useAppSelector(selectGamesByProviderId);

  return (
    <ProvidersModalWrapper css={{ overflow: 'hidden' }}>
      <ScrollArea>
        <GamesGrid
          games={providerGames}
          gameCardVariant={
            isLaptopOrDesktop || isLargeDesktop ? 'xs' : undefined
          }
        />
      </ScrollArea>
    </ProvidersModalWrapper>
  );
};
