import { CSSProperties } from 'react';

interface SvgIconComponentProps {
  iconPath?: string;
  alt?: string;
  styles?: CSSProperties;
}

export const SvgIconComponent = ({
  iconPath,
  alt = 'svg icon',
  styles = {},
}: SvgIconComponentProps) =>
  iconPath ? <img src={iconPath} alt={alt} style={styles} /> : null;
