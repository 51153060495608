import { useEffect, useLayoutEffect } from 'react';

import { EVENT_STATUS, MARKET_STATUS, QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { setMarketGroups } from '~store/slices/liveMenuSlice';
import { Market, SportEvent } from '~types/events';
import { groupByMarketId } from '~utils/markets';
import { areTwoObjectsEqual } from '~utils/objectHelpers';
import { QueryParams } from '~utils/url';

type UseInplayEvent = {
  eventData: SportEvent | null;
  isEventLoaded: boolean;
  marketGroups: Record<string, Market[]>;
};

export const useInplayEvent = ({
  eventData,
  isEventLoaded,
  marketGroups,
}: UseInplayEvent) => {
  const dispatch = useAppDispatch();
  const { isMobileOrTablet } = useMedia();
  const search = useQueryParams();
  const { sportId } = search;
  const { updateQueryParams } = useRouterQuery();
  const { favoriteMarkets } = useAppSelector((state) => state.mainMarkets);

  const handleBackPress = () => {
    const searchCopy = { ...search };

    delete searchCopy.eventId;
    updateQueryParams(searchCopy as QueryParams<typeof QUERY_PARAMS>, true);
  };

  useLayoutEffect(() => {
    if (isMobileOrTablet) {
      window.scrollTo(0, 0);
    }
  }, [isMobileOrTablet]);

  useEffect(() => {
    if (eventData && isEventLoaded) {
      const eventSportId = eventData?.sportId;
      const eventMarkets = eventData?.markets || [];

      const ongoingSportFavorites = favoriteMarkets.find(
        (favoriteMarket) =>
          favoriteMarket.sportId === +(sportId || eventSportId || 0),
      );

      const mapUpdatedMarketsFavorites = eventMarkets
        .filter(
          (item) =>
            item &&
            [MARKET_STATUS.ACTIVE, MARKET_STATUS.SUSPENDED].includes(
              item.status,
            ) &&
            item.selections.length,
        )
        .map((market) => {
          if (market?.marketId) {
            return {
              ...market,
              favorite: ongoingSportFavorites?.marketIds.includes(
                parseInt(market.marketId),
              ),
            };
          }

          return market;
        });
      const resGroups = groupByMarketId(mapUpdatedMarketsFavorites);

      if (!areTwoObjectsEqual(resGroups, marketGroups)) {
        dispatch(setMarketGroups(resGroups));
      }
    }
  }, [isEventLoaded, eventData, favoriteMarkets, sportId]);

  useEffect(() => {
    if (isMobileOrTablet) {
      const { status = EVENT_STATUS.ACTIVE } = eventData || {};

      if (
        ![
          EVENT_STATUS.ACTIVE,
          EVENT_STATUS.IN_PROGRESS,
          EVENT_STATUS.ABOUT_TO_START,
        ].includes(status)
      ) {
        const searchCopy = { ...search };

        delete searchCopy.eventId;
        updateQueryParams(searchCopy as QueryParams<typeof QUERY_PARAMS>, true);
      }
    }
  }, [eventData, isMobileOrTablet]);

  return {
    handleBackPress,
  };
};
