import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { UserProfileData, UserProfilePayload } from '~api/user/types';
import { useUpdateUserProfile } from '~api/user/userMutations';
import { genderOptions } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setProfile } from '~store/slices/userSlice';
import { setPersonalProfileUpdateError } from '~store/slices/userUISlice';
import { conditionalAdd } from '~utils/objectHelpers';

import { PersonalDetailsFormInputs } from '../constants';

export const usePersonalDetailsForm = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.userState);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isPersonalInfoChanged, setIsPersonalInfoChanged] = useState(false);

  const {
    id,
    firstName,
    lastName,
    middleName: middleNameDefault,
    idNumber: idNumberDefault = '',
    email,
    emailConfirmed,
    phoneNumber: initialPhoneNumber,
    phoneNumberConfirmed,
    personalInfoConfirmed,
    birthDate,
    userName,
    gender,
  } = profile as UserProfileData;

  const isAllDetailsConfirmed =
    emailConfirmed && phoneNumberConfirmed && personalInfoConfirmed;

  const defaultValues = {
    userIdField: idNumberDefault,
    userMiddleNameField: middleNameDefault,
    userFirstNameField: firstName,
    userLastNameField: lastName,
    userEmailField: email,
    phoneNumberField: initialPhoneNumber,
    birthDateField: birthDate,
    userGenderField: gender?.toString(),
  };

  const formMethods = useForm<PersonalDetailsFormInputs>({
    defaultValues,
  });
  const { watch, reset, handleSubmit, trigger } = formMethods;
  const data = watch();

  const {
    userIdField: idNumber,
    userMiddleNameField: middleName,
    userFirstNameField: userFirstName,
    userLastNameField: userLastName,
    userEmailField: userEmail,
    phoneNumberField: userPhoneNumber,
    birthDateField: userBirthDate,
    userGenderField: userGender,
  } = data;

  const { updateUserProfileMutation, updateUserProfileIsLoading } =
    useUpdateUserProfile();

  const handleDiscardChanges = () => {
    reset(defaultValues);
    setEditingField(null);
  };

  const onSubmit = handleSubmit(async () => {
    const output = await trigger();

    if (output) {
      const params: UserProfilePayload = {};

      if (idNumber?.length) {
        conditionalAdd(params, 'idNumber', idNumber || '');
      }

      conditionalAdd(params, 'middleName', middleName?.trim());
      conditionalAdd(params, 'firstName', userFirstName?.trim());
      conditionalAdd(params, 'lastName', userLastName?.trim());
      conditionalAdd(params, 'email', userEmail);
      conditionalAdd(params, 'birthDate', userBirthDate);
      conditionalAdd(params, 'gender', parseInt(userGender!));

      try {
        await updateUserProfileMutation(params).unwrap();
        setEditingField(null);
        dispatch(setProfile({ ...profile, ...params } as UserProfileData));
        setTimeout(() => {
          setHasChanged(false);
        }, 0);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(setPersonalProfileUpdateError(error.data.message[0]));
        console.error('Unable to change user details', error);
      } finally {
        setIsPersonalInfoChanged(true);
        setTimeout(() => {
          setIsPersonalInfoChanged(false);
          dispatch(setPersonalProfileUpdateError(null));
        }, 3000);
      }
    }
  });

  const normalizeValue = (value: string | undefined | null): string | null => {
    if (value === undefined || value === '') return null;

    return value;
  };

  const localizedGenderOptions = useMemo(() => genderOptions(localized), []);

  useEffect(() => {
    const hasAnyFieldChanged = (
      Object.keys(data) as Array<keyof PersonalDetailsFormInputs>
    ).some(
      (key) => normalizeValue(data[key]) !== normalizeValue(defaultValues[key]),
    );

    setHasChanged(hasAnyFieldChanged);
  }, [data]);

  return {
    editingField,
    email,
    emailConfirmed,
    formMethods,
    hasChanged,
    id,
    isAllDetailsConfirmed,
    isCalendarOpen,
    localizedGenderOptions,
    personalInfoConfirmed,
    phoneNumberConfirmed,
    updateUserProfileIsLoading,
    userBirthDate,
    userEmail,
    middleName,
    idNumber,
    userFirstName,
    userGender,
    userLastName,
    userName,
    userPhoneNumber,
    isMobileOrTablet,
    isPersonalInfoChanged,
    handleDiscardChanges,
    onSubmit,
    setEditingField,
    setIsCalendarOpen,
  };
};
