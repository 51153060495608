import { QUERY_PARAMS } from '~constants/common';

export type QueryParams<T> = Partial<
  Record<keyof T, string | number | undefined>
>;

export const getQueryParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const queryObject: Partial<Record<QUERY_PARAMS, string>> = {};

  for (const [key, value] of searchParams) {
    if (value !== undefined) {
      queryObject[key as QUERY_PARAMS] = value;
    }
  }

  return queryObject;
};

export const buildSearchQuery = (
  params: Partial<Record<QUERY_PARAMS, string | number | string[]>>,
) => {
  const query = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((arrayValue) => {
          query.append(key, arrayValue.toString());
        });
      } else {
        query.set(key, value.toString());
      }
    }
  });

  return `?${query.toString()}`;
};

export const getParamFromQuery = (
  query: string,
  param: QUERY_PARAMS,
): string | null => {
  const searchParams = new URLSearchParams(query);

  return searchParams.get(param);
};

export const isDebugMode = (): boolean => {
  // Parse the URL query parameters
  const urlParams = new URLSearchParams(window.location.search);

  // Check if the "debug" parameter is set to "true"
  return urlParams.get('debug') === 'true';
};
