import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { buildQueryUrl } from '~utils/buildQueryUrl';

import {
  BACKEND_BONUS_TYPES,
  BonusBalanceResponse,
  Bonuses,
  ProfileBonusPayload,
} from './types';

export const bonusApi = createApi({
  reducerPath: 'bonusApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.BONUS }),
  endpoints: (builder) => ({
    getProfileBonus: builder.query<Bonuses, ProfileBonusPayload>({
      query: ({ contentType, queryParams }) => {
        let url = `profile/bonus/${contentType || BACKEND_BONUS_TYPES.FREEBET}`;

        if (queryParams) {
          url = buildQueryUrl(url, queryParams);
        }

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getBonusBalance: builder.query<BonusBalanceResponse, void>({
      query: () => ({
        url: `profile/bonus/balance`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetProfileBonusQuery, useLazyGetBonusBalanceQuery } =
  bonusApi;
