import React from 'react';

import { useTranslation } from '~hooks/useTranslation';

import { AuthTabsList } from '../AuthTabs';

export const enum SIGN_UP_TABS {
  PROVIDE_DETAILS = 'PROVIDE_DETAILS',
  VERIFY_NUMBER = 'VERIFY_NUMBER',
  VERIFY_LINK = 'VERIFY_LINK',
}

export const RegisterTabs = [
  SIGN_UP_TABS.PROVIDE_DETAILS,
  SIGN_UP_TABS.VERIFY_NUMBER,
  SIGN_UP_TABS.VERIFY_LINK,
];

export const SignUpTabList = () => {
  const { localized } = useTranslation();

  return (
    <AuthTabsList
      tabs={RegisterTabs}
      ariaLabel={localized('signUp.ariaLabel')}
    />
  );
};
