import { ErrorPage } from '~components/atoms/ErrorPage/ErrorPage';
import { useTranslation } from '~hooks/useTranslation';

export const NotFoundPage = () => {
  const { localized } = useTranslation();

  return <ErrorPage title={localized('notFound.pageNotFound')} />;
};

export default NotFoundPage;
