import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MessageProps } from '~components/atoms/Message';
import { RootState } from '~store';
import { Jackpot, Jackpots, JackpotStakes } from '~types/jackpot';

export interface JackpotState {
  messages: MessageProps[];
  activeJackpots: Jackpots;
  settledJackpots: Jackpots;
  selectedJackpot: Jackpot | null;
  jackpotsStakes: Record<string, JackpotStakes>; // { [jackpotId] : JackpotStakes }
}

const initialState: JackpotState = {
  messages: [],
  activeJackpots: [],
  settledJackpots: [],
  selectedJackpot: null,
  jackpotsStakes: {},
};

export const jackpotSlice = createSlice({
  name: 'jackpot',
  initialState,
  reducers: {
    addJackpotMessage: (state, action: PayloadAction<MessageProps>) => {
      state.messages = [action.payload];
    },
    clearJackpotMessages: (state) => {
      state.messages = [];
    },
    setActiveJackpots: (state, action) => {
      state.activeJackpots = action.payload;
    },
    setSettledJackpots: (state, action) => {
      state.settledJackpots = action.payload;
    },
    setSelectedJackpot: (state, action) => {
      state.selectedJackpot = action.payload;
    },
    addJackpotStake: (
      state,
      action: PayloadAction<{
        jackpotId: string;
        selectionId: string;
        eventId: string;
      }>,
    ) => {
      const { jackpotId, selectionId, eventId } = action.payload;

      let jackpotStakes = state.jackpotsStakes[jackpotId];

      if (!jackpotStakes) {
        jackpotStakes = [];
      }

      jackpotStakes.push({ id: selectionId, eventId });
      state.jackpotsStakes[jackpotId] = jackpotStakes;
    },
    removeJackpotStake: (
      state,
      action: PayloadAction<{
        jackpotId: string;
        selectionId: string;
      }>,
    ) => {
      const { jackpotId, selectionId } = action.payload;

      if (state.jackpotsStakes[jackpotId]) {
        state.jackpotsStakes[jackpotId] =
          state.jackpotsStakes[jackpotId]?.filter(
            (stake) => stake.id !== selectionId,
          ) || [];
      }
    },
    setJackpotStakesById: (
      state,
      action: PayloadAction<{
        jackpotId: string;
        stakes: JackpotStakes;
      }>,
    ) => {
      const { jackpotId, stakes } = action.payload;

      state.jackpotsStakes[jackpotId] = stakes;
    },
    clearJackpotStakesById: (state, action: PayloadAction<string>) => {
      state.jackpotsStakes[action.payload] = [];
    },
  },
});

export const getJackpotMessages = (state: RootState) => state.jackpot.messages;

export const getSettledJackpots = (state: RootState) =>
  state.jackpot.settledJackpots;

export const getActiveJackpots = (state: RootState) =>
  state.jackpot.activeJackpots;

export const getJackpotStakes = (state: RootState) =>
  state.jackpot.jackpotsStakes;

export const getSelectedJackpot = (state: RootState) =>
  state.jackpot.selectedJackpot;

export const selectJackpotById = (id?: string) =>
  createSelector(
    [getSettledJackpots, getActiveJackpots],
    (settledJackpots, activeJackpots) => {
      return [...settledJackpots, ...activeJackpots].find(
        (jackpot) => jackpot.id === id,
      );
    },
  );

export const selectJackpotStakesById = (id?: string) => {
  return createSelector(getJackpotStakes, (jackpotsStakes) => {
    return id ? jackpotsStakes[id] || [] : [];
  });
};

export const {
  addJackpotMessage,
  clearJackpotMessages,
  setActiveJackpots,
  setSettledJackpots,
  setSelectedJackpot,
  addJackpotStake,
  removeJackpotStake,
  setJackpotStakesById,
  clearJackpotStakesById,
} = jackpotSlice.actions;

export default jackpotSlice.reducer;
