import React, { memo } from 'react';
import { styled } from 'stitches.config';

import { MainMarket } from '~api/market/types';
import { Market, SportEventItem } from '~api/sportEvent/types';
import { ArrowOddIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { SkeletonCompact } from '~components/atoms/Skeletons/SkeletonCompact';
import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useOddButtonGroup } from '~components/molecules/LiveMenu/components/useOddButtonGroup';
import { MARKET_STATUS, SELECTION_STATUS } from '~constants/common';
import { LockIcon } from '~icons';

const ContentWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
});

interface OddContentProps {
  label: string;
  odd: number;
  isDisabled: boolean;
}

export const OddContent = memo(
  ({ label, odd, isDisabled }: OddContentProps) => {
    return (
      <ContentWrapper flexRow justifyCenter={!label.length} alignCenter>
        <>
          {!!label.length && (
            <Text level="sm-4" ellipsis css={{ maxWidth: '30px' }}>
              {label}
            </Text>
          )}
          {!isDisabled ? (
            <ArrowOddIndicator odd={odd} />
          ) : (
            <Box css={{ color: '$textPrimaryOne' }}>
              <LockIcon width={12} height={16} color={'textPrimaryOne'} />
            </Box>
          )}
        </>
      </ContentWrapper>
    );
  },
);

interface OddButtonGroupProps {
  isLoading: boolean;
  market?: Market;
  sportId: number;
  mainMarketId?: number | null;
  mainMarketData?: MainMarket[];
  isFavoriteEvents?: boolean;
  fullHeight?: boolean;
  eventData: SportEventItem;
}

export const OddButtonGroup = memo(
  ({
    isLoading,
    market,
    sportId,
    isFavoriteEvents,
    fullHeight,
    eventData,
  }: OddButtonGroupProps) => {
    const {
      isTotalMarket,
      activeButtons,
      onValueChange,
      isMobileOrTablet,
      defaultMainMarket,
    } = useOddButtonGroup({
      market,
      isFavoriteEvents,
      sportId,
      eventData,
    });

    if (isLoading) {
      return <SkeletonCompact css={{ width: '100%', height: '$6' }} />;
    }

    const totalMerketLine = market?.line || '-';

    return (
      <ToggleGroup
        type="multiple"
        variant="bet"
        onValueChange={onValueChange}
        value={activeButtons}
        rovingFocus={false}
        loop={false}
        css={{
          '@xs_sm': {
            width: 'max-content',
            justifyContent: 'flex-end',
            height: fullHeight ? '100%' : 'unset',
          },
        }}
      >
        {isTotalMarket && (
          <ToggleGroupItem
            key={totalMerketLine}
            value={'-'}
            type="bet"
            disabled={true}
            css={{
              display: 'flex',
              justifyContent: 'center',
              '@xs_sm': {
                minWidth: '52px',
                maxWidth: '52px',
              },
            }}
          >
            <Text
              level="12-20"
              css={{
                color: '$textSecondaryOne !important',
              }}
            >
              {totalMerketLine}
            </Text>
          </ToggleGroupItem>
        )}
        {market?.selections && market.selections.length
          ? market.selections.map(({ id, name, odd, status }) => {
              const isDisabled =
                status !== SELECTION_STATUS.OPEN ||
                !market ||
                market.status === MARKET_STATUS.SUSPENDED;

              return (
                <ToggleGroupItem
                  key={id}
                  value={id}
                  type="bet"
                  disabled={isDisabled}
                  css={{
                    '@xs_sm': {
                      minWidth: '52px',
                      width: '100%',
                    },
                  }}
                >
                  <OddContent
                    label={isMobileOrTablet ? '' : name}
                    odd={odd}
                    isDisabled={isDisabled}
                  />
                </ToggleGroupItem>
              );
            })
          : defaultMainMarket?.selections
            ? defaultMainMarket.selections.map(({ id }) => {
                return (
                  <ToggleGroupItem
                    key={id}
                    value={id}
                    type="bet"
                    disabled
                    css={{
                      '@xs_sm': {
                        width: '52px',
                      },
                    }}
                  >
                    <Text
                      level="14-20"
                      css={{ width: '100%', textAlign: 'center' }}
                    >
                      -
                    </Text>
                  </ToggleGroupItem>
                );
              })
            : null}
      </ToggleGroup>
    );
  },
);
