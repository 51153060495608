import { useCallback, useEffect, useState } from 'react';

import { InPlayMenuCountries, InPlayMenuSports } from '~api/sportEvent/types';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsFavoriteEventsOpen,
  setIsTopLiveEventsOpen,
} from '~store/slices/liveGroupsSlice';
import { setOpenedSports } from '~store/slices/liveMenuSlice';
import {
  setBreadcrumbsLabel,
  updateLiveMenuState,
} from '~store/slices/menusSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

export const useLiveSportMobileMenu = (sports: InPlayMenuSports) => {
  const { menu, group, sportId } = useQueryParams();
  const dispatch = useAppDispatch();
  const {
    openedSports: [activeSportId],
  } = useAppSelector((state) => state.liveMenu);
  const { eventSocketConnected } = useAppSelector(
    (state) => state.signalRSockets,
  );

  const { pushQueryParams, removeQueryParams } = useRouterQuery();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isTopLiveEventsOpen, isFavoriteEventsOpen } = useAppSelector(
    (state) => state.liveGroupsState,
  );

  useEffect(() => {
    if (!eventSocketConnected) {
      setIsLoaded(false);
    }
  }, [eventSocketConnected]);

  const handleOnValueChange = useCallback(
    (sportId: string, updateQuery: boolean = true) => {
      dispatch(setIsFavoriteEventsOpen(false));
      dispatch(setIsTopLiveEventsOpen(false));
      dispatch(setOpenedSports(sportId));

      if (updateQuery) {
        pushQueryParams({
          [QUERY_PARAMS.SPORT_ID]: sportId,
          [QUERY_PARAMS.MENU]: SPORT_MENUS.LIVE,
        } as QueryParams<typeof QUERY_PARAMS>);

        dispatch(updateLiveMenuState({ sportId }));

        if (group) {
          dispatch(setIsTopLiveEventsOpen(false));
          dispatch(setBreadcrumbsLabel(null));

          removeQueryParams([QUERY_PARAMS.GROUP]);
        }
      }
    },
    [group],
  );

  const getSportEventsCount = useCallback(
    (countries: InPlayMenuCountries | undefined) => {
      if (!countries) {
        return 0;
      }

      return countries.flatMap((country) =>
        country.leagues.flatMap((league) => league.events),
      ).length;
    },
    [],
  );

  useEffect(() => {
    let sportIdValue = sportId || sports[0]?.id.toString();

    const isInSports = sports.some(
      (sport) => sport.id === parseInt(sportIdValue || ''),
    );

    if (!isInSports) {
      sportIdValue = sports[0]?.id.toString();
    }

    if (sportIdValue && !isLoaded) {
      if (!isFavoriteEventsOpen) {
        handleOnValueChange(sportIdValue, false);
      }

      setIsLoaded(true);
    }
  }, [sports, isLoaded, isFavoriteEventsOpen, sportId]);

  const getActiveSportCountriesData = useCallback(() => {
    return sports.find(
      (sport) => sport.id === parseInt(activeSportId as string),
    );
  }, [activeSportId, sports]);

  useEffect(() => {
    const activeMenu = menu || SPORT_MENUS.PREMATCH;

    if (activeMenu === SPORT_MENUS.LIVE && group) {
      dispatch(setIsTopLiveEventsOpen(true));
    }

    if (activeMenu === SPORT_MENUS.LIVE && !group) {
      dispatch(setIsTopLiveEventsOpen(false));
      dispatch(setBreadcrumbsLabel(null));
    }
  }, [menu, group]);

  return {
    activeSportId: !(isTopLiveEventsOpen || isFavoriteEventsOpen)
      ? activeSportId
      : undefined,
    isTopLiveEventsOpen,
    isFavoriteEventsOpen,
    countriesData: getActiveSportCountriesData()?.countries || [],
    handleOnValueChange,
    getSportEventsCount,
  };
};
