import { ReactNode } from 'react';
import { styled } from 'stitches.config';

import FallbackImage from '~assets/sportBackgrounds/Fallback.webp';
import { Box } from '~components/atoms/Box';
import { availableSportBackgroundsMap, SPORTS } from '~constants/sport';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';

export const SportBackgroundWrapper = styled(Box, {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  flexGrow: 1,
});

export const SportBackgroundBox = ({
  sport,
  isMatchStarted = true,
  children,
}: {
  sport: SPORTS;
  children: ReactNode;
  isMatchStarted?: boolean;
}) => {
  const { sportId } = useQueryParams();
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const mainMarketsIdSportNameMap: Record<string, string> = {};

  mainMarkets.forEach(({ id, name }) => {
    mainMarketsIdSportNameMap[id] = name;
  });

  const sportName = mainMarketsIdSportNameMap[sportId || ''] as SPORTS;

  const backgroundImage =
    availableSportBackgroundsMap[(sportName as SPORTS) || sport] ||
    FallbackImage;

  return (
    <SportBackgroundWrapper
      flexCol
      fullWidth
      fullHeight
      css={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        pb: isMatchStarted ? '66px' : 'unset',
      }}
    >
      {children}
    </SportBackgroundWrapper>
  );
};
