import { ReactNode } from 'react';
import { CSS } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { MESSAGE_TYPES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { AlertIcon, GreenCheckIcon } from '~icons';

interface MessageAction {
  text: string;
  action: () => void;
  customRender?: ReactNode;
}

export interface MessageProps {
  type: MESSAGE_TYPES;
  css?: CSS;
  text?: string;
  textArray?: string[];
  action?: MessageAction;
  customRender?: ReactNode;
}

const messagesColorsMap: Record<
  MESSAGE_TYPES,
  { color: string; backgroundColor: string }
> = {
  [MESSAGE_TYPES.ERROR]: {
    backgroundColor: '$redSecondary',
    color: '$redPrimary',
  },
  [MESSAGE_TYPES.WARNING]: {
    backgroundColor: '$yellowSecondary',
    color: '$yellowPrimary',
  },
  [MESSAGE_TYPES.SUCCESS]: {
    color: '$greenPrimary',
    backgroundColor: '$greenSecondary',
  },
};

const messageWrapperCss = {
  p: '6px',
  borderRadius: '$6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$2',
  '& > svg': {
    minWidth: '$4',
  },
};

export const Message = ({
  css,
  text,
  textArray,
  type,
  action,
  customRender,
}: MessageProps) => {
  const specificTypeStyles = messagesColorsMap[type];
  const { isMobile } = useMedia();
  const iconStyles = isMobile ? { height: 14, width: 14 } : {};
  const isSuccess = type === MESSAGE_TYPES.SUCCESS;

  const getContent = () => {
    if (customRender) {
      return customRender;
    }

    return (
      <>
        {textArray && textArray.length > 0 ? (
          <Box fullWidth flexCol gap={0}>
            {textArray.map((text, index) => (
              <Text
                key={index}
                level="12-20"
                fontWeight={index === 0 ? 'bold' : 'medium'}
              >
                {text}
              </Text>
            ))}
          </Box>
        ) : (
          <Text level="12-20">{text}</Text>
        )}

        {action && (
          <Text
            underline
            textTransform="uppercase"
            level="12-20"
            onClick={action.action}
          >
            {action.text}
          </Text>
        )}
      </>
    );
  };

  return (
    <Box
      css={{
        ...css,
        ...messageWrapperCss,
        ...specificTypeStyles,
        '@xs': {
          fontSize: '$xxxxs',
          p: '$1',
        },
      }}
    >
      {isSuccess ? (
        <GreenCheckIcon {...iconStyles} />
      ) : (
        <AlertIcon {...iconStyles} />
      )}
      {getContent()}
    </Box>
  );
};
