import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledLogo = styled(Box, {
  cursor: 'pointer',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: '216px',
  height: '64px',
  variants: {
    type: {
      header: {
        width: 'unset',
        height: 'unset',
        '@md_lg_xl': {
          width: '264px',
          height: '64px',
        },
        '@xs_sm': {
          width: '92px',
          height: '40px',
        },
      },
      auth: {
        height: '68px',
        '@xs_sm': {
          height: '64px',
        },
      },
    },
  },
});
