import React from 'react';

import { Box } from '~components/atoms/Box';
import { StoreSportEvent } from '~types/events';
import { VolleyballExtraData } from '~types/sportExtraData';

import { VolleyballScoreboard } from '../scoreboards';

interface VolleyballEventOverviewProps {
  restEventData: Omit<StoreSportEvent, 'extraData'>;
  parsedExtraData: VolleyballExtraData | null;
}

export const VolleyballEventOverview = ({
  restEventData,
  parsedExtraData,
}: VolleyballEventOverviewProps) => {
  return (
    <>
      <VolleyballScoreboard
        restEventData={restEventData}
        parsedExtraData={parsedExtraData}
      />
      <Box></Box>
    </>
  );
};
