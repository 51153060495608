import { MouseEvent } from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { GreenCheckIcon, RedCrossIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { getCurrencySymbol } from '~store/slices/selectors/settings';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const CashOutSuccess = ({
  onContinue,
}: {
  onContinue?: (e: MouseEvent) => void;
}) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const currencySymbol = useAppSelector(getCurrencySymbol);
  const { cashOutSuccess, cashOutBet } = useAppSelector(
    (state) => state.openBets,
  );
  const { availableCashout = 0 } = cashOutBet || {};

  const getIcon = () => {
    if (cashOutSuccess) {
      return <GreenCheckIcon width={40} height={40} />;
    } else {
      return <RedCrossIcon width={40} height={40} />;
    }
  };

  return (
    <Box
      fullWidth
      flexCol
      alignCenter
      justifyCenter
      gap={2}
      css={{
        p: '28px 73px',
        borderRadius: '$8',
        border: '1px solid $primaryTwo',
        '@xs_sm': {
          p: '28px',
        },
      }}
    >
      {getIcon()}
      <Text textTransform="uppercase" level="17-24">
        {localized(`cashOutRule.${cashOutSuccess ? 'success' : 'fail'}.title`)}
      </Text>
      <Text textTransform="uppercase" level="14-20" textAlign="center">
        {localized(
          `cashOutRule.${cashOutSuccess ? 'success' : 'fail'}.subtitle`,
          {
            amount: commaSeparatedNumber(availableCashout),
            currency: currencySymbol,
          },
        )}
      </Text>
      <Button
        variant="secondaryTwo"
        fullWidth
        onClick={(e: MouseEvent) => {
          e.stopPropagation();

          if (onContinue) {
            onContinue(e);
          } else {
            dispatch(closeDialog());
          }
        }}
      >
        {localized('buttons.continue')}
      </Button>
    </Box>
  );
};
