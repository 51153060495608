import { useEffect, useRef } from 'react';

import { QUERY_PARAMS } from '~constants/common';
import { LANGUAGE_TYPES, languagesCode } from '~constants/language';
import { timeZoneOffsets } from '~constants/timezones';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setLanguage,
  setOdds,
  setTimezone,
} from '~store/slices/websiteSettings';
import { convertOdds } from '~utils/oddsFormatting';
import { QueryParams } from '~utils/url';

const getSettingsString = (
  timezone: string | null,
  odds: string,
  language: string,
): string => {
  return JSON.stringify({ timezone, odds, language });
};

export const useWebsiteSettings = () => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { timezone, odds, language } = useAppSelector(
    (state) => state.websiteSettings,
  );
  const prevSettings = useRef(
    getSettingsString(timezone, odds, language.toString()),
  );

  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  const onTimezoneChange = (value: unknown) => {
    dispatch(setTimezone(value as string));
  };

  const onOddsChange = (value: unknown) => {
    dispatch(setOdds(value as string));
  };

  const onLanguageChange = (value: unknown) => {
    dispatch(setLanguage(value as LANGUAGE_TYPES));
    updateQueryParams({
      [QUERY_PARAMS.LANG]: languagesCode[value as LANGUAGE_TYPES],
    } as QueryParams<typeof QUERY_PARAMS>);
  };

  const getFormattedOdd = (value: number, odds: string): string => {
    const obj = convertOdds(value);

    return obj[odds as keyof typeof obj];
  };

  useEffect(() => {
    if (
      getSettingsString(timezone, odds, language.toString()) ===
      prevSettings.current
    )
      return;

    prevSettings.current = getSettingsString(
      timezone,
      odds,
      language.toString(),
    );
  }, [isUserLoggedIn, timezone, odds, language]);

  useEffect(() => {
    if (!timezone) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const offsetTimezone =
        timeZoneOffsets[timezone as keyof typeof timeZoneOffsets];

      dispatch(setTimezone(offsetTimezone));
    }
  }, [timezone]);

  return {
    odds,
    timezone,
    language,
    onTimezoneChange,
    onOddsChange,
    onLanguageChange,
    getFormattedOdd,
  };
};
