import {
  useAddFavoriteEventMutation,
  useDeleteFavoriteEventMutation,
  useDeleteTournamentFromFavoritesMutation,
  useGetBetslipAddedEventsMutation,
  useSetTournamentAsFavoriteMutation,
} from '~api/sportEvent/sportEventApi';
import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

export const useGetBetslipAddedEvents = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useGetBetslipAddedEventsMutation();

  return {
    getBetslipAddedEventsMutation: mutation,
    getBetslipAddedEventsData: data,
    getBetslipAddedEventsError: error,
    getBetslipAddedEventsIsError: isError,
    getBetslipAddedEventsErrorCode: getErrorCode(error, isError),
    getBetslipAddedEventsErrorMessage: getErrorMessage(error),
    getBetslipAddedEventsIsSuccess: isSuccess,
    getBetslipAddedEventsIsLoading: isLoading,
  };
};

export const useSetTournamentAsFavorite = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSetTournamentAsFavoriteMutation();

  return {
    setTournamentAsFavoriteMutation: mutation,
    setTournamentAsFavoriteData: data,
    setTournamentAsFavoriteError: error,
    setTournamentAsFavoriteIsError: isError,
    setTournamentAsFavoriteErrorCode: getErrorCode(error, isError),
    setTournamentAsFavoriteErrorMessage: getErrorMessage(error),
    setTournamentAsFavoriteIsSuccess: isSuccess,
    setTournamentAsFavoriteIsLoading: isLoading,
  };
};

export const useDeleteTournamentFromFavorites = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useDeleteTournamentFromFavoritesMutation();

  return {
    deleteTournamentFromFavoriteMutation: mutation,
    deleteTournamentFromFavoriteMutationData: data,
    deleteTournamentFromFavoriteMutationError: error,
    deleteTournamentFromFavoriteMutationIsError: isError,
    deleteTournamentFromFavoriteMutationErrorCode: getErrorCode(error, isError),
    deleteTournamentFromFavoriteMutationErrorMessage: getErrorMessage(error),
    deleteTournamentFromFavoriteMutationIsSuccess: isSuccess,
    deleteTournamentFromFavoriteMutationIsLoading: isLoading,
  };
};

export const useAddFavoriteEvent = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useAddFavoriteEventMutation();

  return {
    addFavoriteEventMutation: mutation,
    addFavoriteEventMutationData: data,
    addFavoriteEventMutationError: error,
    addFavoriteEventMutationIsError: isError,
    addFavoriteEventMutationErrorCode: getErrorCode(error, isError),
    addFavoriteEventMutationErrorMessage: getErrorMessage(error),
    addFavoriteEventMutationIsSuccess: isSuccess,
    addFavoriteEventMutationIsLoading: isLoading,
  };
};

export const useDeleteFavoriteEvent = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useDeleteFavoriteEventMutation();

  return {
    deleteFavoriteEventMutation: mutation,
    deleteFavoriteEventMutationData: data,
    deleteFavoriteEventMutationError: error,
    deleteFavoriteEventMutationIsError: isError,
    deleteFavoriteEventMutationErrorCode: getErrorCode(error, isError),
    deleteFavoriteEventMutationErrorMessage: getErrorMessage(error),
    deleteFavoriteEventMutationIsSuccess: isSuccess,
    deleteFavoriteEventMutationIsLoading: isLoading,
  };
};
