import { useState } from 'react';

import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { useFormatAmountWithCurrency } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { CloseIcon, WinboostIcon } from '~icons';

interface WinboostPopoverProps {
  isBetHistory?: boolean;
  winBoostAmount: number;
  winBoostMultiplier: number;
  originalWinAmount: number;
  status: number;
}

export const WinboostPopover = ({
  isBetHistory,
  winBoostAmount,
  winBoostMultiplier,
  originalWinAmount,
  status,
}: WinboostPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  return (
    <Box flexRow>
      <Popover isOpen={isOpen}>
        <PopoverContent
          noArrow={isMobileOrTablet}
          noBorder={isMobileOrTablet}
          side="bottom"
          sideOffset={isMobileOrTablet ? 16 : 8}
          align="center"
          tone="secondary"
          width={isMobileOrTablet ? '100vw' : 300}
          css={{
            mr: '14px',
            p: '$4',
            '@xs_sm': {
              mr: 'unset',
              background: '$footerBackgroundOpacity',
              backdropFilter: 'blur(4px)',
              borderRadius: '$12',
            },
          }}
        >
          <Box
            flexCol
            css={{
              '@xs_sm': {
                p: '$4',
                background: '$secondaryTwo',
                border: '1px solid $pressedOne',
                borderRadius: '$8',
                position: 'relative',
              },
            }}
          >
            <Box flexCol gap={3}>
              {isMobileOrTablet && (
                <Box
                  flexRow
                  css={{
                    position: 'absolute',
                    top: '$3',
                    right: '$3',
                    color: '$textSecondaryOne',
                  }}
                  onClick={() => setIsOpen(false)}
                >
                  <CloseIcon />
                </Box>
              )}
              <Text level="14-20" textTransform="uppercase">
                {localized('winboostPopover.title')}
              </Text>
              <Box flexCol gap={1}>
                <Box
                  flexRow
                  justifyContentBetween
                  css={{
                    backgroundColor: '$primaryTwo',
                    borderRadius: '$6',
                    p: '$2 $3',
                  }}
                >
                  <Text
                    level={isMobileOrTablet ? '13-16' : '12-20'}
                    textTransform="capitalize"
                  >
                    {localized('winboostPopover.winAmount')}
                  </Text>
                  <Text
                    level={isMobileOrTablet ? '13-16' : '12-20'}
                    fontWeight="medium"
                    color={'accentPrimaryTwo'}
                  >
                    {getCommaSeparatedAmount(originalWinAmount)}
                  </Text>
                </Box>
                <Box
                  flexRow
                  justifyContentBetween
                  css={{
                    backgroundColor: '$primaryTwo',
                    borderRadius: '$6',
                    p: '$2 $3',
                  }}
                >
                  <Text
                    level={isMobileOrTablet ? '13-16' : '12-20'}
                    textTransform="capitalize"
                  >
                    {localized('winboostPopover.winboostAmount', {
                      percents: winBoostMultiplier,
                    })}
                  </Text>
                  <Text
                    level={isMobileOrTablet ? '13-16' : '12-20'}
                    fontWeight="medium"
                    color={'accentPrimaryTwo'}
                  >
                    {getCommaSeparatedAmount(winBoostAmount)}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </PopoverContent>
        <PopoverTrigger asChild>
          <Box
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            css={{
              cursor: 'pointer',
              color: status === 1 ? '$textSecondaryOne' : '$accentPrimaryTwo',
              lineHeight: 0,
              '&:hover': {
                color: '$pressedOne',
              },
              ...(isBetHistory
                ? {
                    padding: '$1',
                    backgroundColor: '$primaryTwo',
                    borderRadius: '$4',
                  }
                : {}),
            }}
          >
            <WinboostIcon width={12} height={12} />
          </Box>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};
