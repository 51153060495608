export interface SimpleZIndexes {
  dropdown: number;
  sticky: number;
  fixed: number;
  modalBackdrop: number;
  modal: number;
  popover: number;
  tooltip: number;
}

export interface CompositionZIndexes {
  dropdown: SimpleZIndexes;
  sticky: SimpleZIndexes;
  fixed: SimpleZIndexes;
  modalBackdrop: SimpleZIndexes;
  modal: SimpleZIndexes;
  popover: SimpleZIndexes;
  tooltip: SimpleZIndexes;
}

export interface ZIndexes extends SimpleZIndexes {
  composition: CompositionZIndexes;
}

const simpleZIndexes: SimpleZIndexes = {
  dropdown: 10,
  sticky: 20,
  fixed: 30,
  modalBackdrop: 40,
  modal: 50,
  popover: 60,
  tooltip: 70,
};

const generateZIndexes = () => {
  const result: Partial<ZIndexes> = {
    ...simpleZIndexes,
  };

  const composition: Partial<CompositionZIndexes> = {};

  Object.entries(simpleZIndexes).forEach((parentIndex) => {
    const [parentKey, parentValue] = parentIndex as [
      keyof SimpleZIndexes,
      number,
    ];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    composition[parentKey] = {};

    Object.entries(simpleZIndexes).forEach(([childKey, childValue]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      composition[parentKey][childKey] = parseInt(
        `${parentValue}${childValue}`,
      );
    });
  });

  return { ...result, composition: { ...composition } };
};

// memoized z-indexes
export const zIndex = (() => {
  let config;

  if (!config) {
    config = generateZIndexes();
  }

  return config;
})() as ZIndexes;
