import React from 'react';

import { Box } from '~components/atoms/Box';

import { ScoreColumn } from './utils/getScores';
import {
  BreakdownScoreColumn,
  BreakdownScoreColumnRow,
  BreakdownScoreColumnTitle,
  StyledBreakdownScore,
} from './styled.components';

interface BreakdownScoreProps {
  scores: ScoreColumn[];
}

export const BreakdownScore = ({ scores }: BreakdownScoreProps) => {
  if (!scores) return null;

  return (
    <Box flexRow justifyCenter css={{ mt: '$3' }}>
      <StyledBreakdownScore>
        {scores.map(({ title, home, away }, index) => (
          <BreakdownScoreColumn key={index}>
            <BreakdownScoreColumnTitle>{title}</BreakdownScoreColumnTitle>
            <BreakdownScoreColumnRow>
              {home} : {away}
            </BreakdownScoreColumnRow>
          </BreakdownScoreColumn>
        ))}
      </StyledBreakdownScore>
    </Box>
  );
};
