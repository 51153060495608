import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks/useMedia';
import { FavoriteIcon } from '~icons';
import { useAppSelector } from '~store';

import { useFavoriteLeague } from './hooks/useFavoriteLeague';

interface FavoriteLeagueButtonProps {
  id: number;
  countryId: number;
  events: number;
  countryName: string;
  sportId: number;
  code: string;
  name?: string;
  sportName?: string;
  height?: number;
  width?: number;
}

export const FavoriteLeagueButton = ({
  id,
  name,
  events,
  sportId,
  countryId,
  sportName,
  countryName,
  code,
  height = 16,
  width = 16,
}: FavoriteLeagueButtonProps) => {
  const { isFavorite, favoriteWrapperRef, handleFavoriteClick } =
    useFavoriteLeague(
      name!,
      id,
      sportId,
      countryId,
      events,
      sportName!,
      countryName,
      code,
    );
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { isMobileOrTablet } = useMedia();

  if (!isUserLoggedIn) {
    return;
  }

  return (
    <Box
      flexCol
      justifyCenter
      css={{
        cursor: 'pointer',
        color: isFavorite ? '$textPrimaryOne' : '$textSecondaryOne',
        '&:hover': !isMobileOrTablet && { color: '$textPrimaryOne' },
        '&:focus': { color: '$textPrimaryOne' },
      }}
      onClick={handleFavoriteClick}
      ref={favoriteWrapperRef}
    >
      <FavoriteIcon height={height} width={width} />
    </Box>
  );
};
