import { USER_DEFAULT_BALANCE } from '~store/slices/userSlice';
import { UUID } from '~types/uuid';

export enum DOCUMENT_TYPES {
  INTERNATIONAL_PASSPORT = 'internationalPassport',
  NATIONAL_PASSPORT = 'nationalPassport',
  DRIVING_LICENSE = 'driverLicense',
  ADDRESS = 'address',
  OTHER = 'other',
  SELFIE = 'selfie',
  BILL = 'bill',
  PHOTO = 'photo',
}

export enum DOCUMENT_STATUS {
  VERIFIED = 'verified',
  PENDING = 'pending',
  NOT_VERIFIED = 'notVerified',
}

export enum DOCUMENT_TYPE_STATUSES {
  INTERNATIONAL_PASSPORT = 1,
  NATIONAL_PASSPORT = 2,
  DRIVING_LICENSE = 3,
  ADDRESS = 4,
  OTHER = 5,
  SELFIE = 6,
  BILL = 7,
  PHOTO = 8,
}

export enum USER_DOCUMENT_STATUS {
  AWAITING_REVIEW = 1,
  ACCEPTED = 2,
  DECLINED = 3,
}

export type ExtractedDocumentTypeStatuses = Extract<
  DOCUMENT_TYPE_STATUSES,
  | DOCUMENT_TYPE_STATUSES.INTERNATIONAL_PASSPORT
  | DOCUMENT_TYPE_STATUSES.NATIONAL_PASSPORT
  | DOCUMENT_TYPE_STATUSES.DRIVING_LICENSE
  | DOCUMENT_TYPE_STATUSES.SELFIE
  | DOCUMENT_TYPE_STATUSES.BILL
  | DOCUMENT_TYPE_STATUSES.PHOTO
  | DOCUMENT_TYPE_STATUSES.OTHER
  | DOCUMENT_TYPE_STATUSES.ADDRESS
>;

export const documentsStatusToTypeMap: Record<
  ExtractedDocumentTypeStatuses,
  DOCUMENT_TYPES
> = {
  [DOCUMENT_TYPE_STATUSES.INTERNATIONAL_PASSPORT]:
    DOCUMENT_TYPES.INTERNATIONAL_PASSPORT,
  [DOCUMENT_TYPE_STATUSES.NATIONAL_PASSPORT]: DOCUMENT_TYPES.NATIONAL_PASSPORT,
  [DOCUMENT_TYPE_STATUSES.DRIVING_LICENSE]: DOCUMENT_TYPES.DRIVING_LICENSE,
  [DOCUMENT_TYPE_STATUSES.ADDRESS]: DOCUMENT_TYPES.ADDRESS,
  [DOCUMENT_TYPE_STATUSES.OTHER]: DOCUMENT_TYPES.OTHER,
  [DOCUMENT_TYPE_STATUSES.SELFIE]: DOCUMENT_TYPES.SELFIE,
  [DOCUMENT_TYPE_STATUSES.BILL]: DOCUMENT_TYPES.BILL,
  [DOCUMENT_TYPE_STATUSES.PHOTO]: DOCUMENT_TYPES.PHOTO,
};

export const documentsTypeToStatusMap: Record<
  DOCUMENT_TYPES,
  ExtractedDocumentTypeStatuses
> = {
  [DOCUMENT_TYPES.INTERNATIONAL_PASSPORT]:
    DOCUMENT_TYPE_STATUSES.INTERNATIONAL_PASSPORT,
  [DOCUMENT_TYPES.NATIONAL_PASSPORT]: DOCUMENT_TYPE_STATUSES.NATIONAL_PASSPORT,
  [DOCUMENT_TYPES.DRIVING_LICENSE]: DOCUMENT_TYPE_STATUSES.DRIVING_LICENSE,
  [DOCUMENT_TYPES.ADDRESS]: DOCUMENT_TYPE_STATUSES.ADDRESS,
  [DOCUMENT_TYPES.OTHER]: DOCUMENT_TYPE_STATUSES.OTHER,
  [DOCUMENT_TYPES.SELFIE]: DOCUMENT_TYPE_STATUSES.SELFIE,
  [DOCUMENT_TYPES.BILL]: DOCUMENT_TYPE_STATUSES.BILL,
  [DOCUMENT_TYPES.PHOTO]: DOCUMENT_TYPE_STATUSES.PHOTO,
};

export const documentsStatusMap: Record<USER_DOCUMENT_STATUS, DOCUMENT_STATUS> =
  {
    [USER_DOCUMENT_STATUS.AWAITING_REVIEW]: DOCUMENT_STATUS.PENDING,
    [USER_DOCUMENT_STATUS.ACCEPTED]: DOCUMENT_STATUS.VERIFIED,
    [USER_DOCUMENT_STATUS.DECLINED]: DOCUMENT_STATUS.NOT_VERIFIED,
  };

export interface GetUserResponse {
  id: string;
  userName: string;
  role: string;
}

export interface UserDocument {
  id: UUID;
  documentType: DOCUMENT_TYPES;
  date: Date;
  status: DOCUMENT_STATUS;
  file: string;
}

export interface UserProfileData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  personalInfoConfirmed: boolean;
  userName: string;
  balance: number;
  birthDate: string | null;
  documents: UserDocument[];
  gender: number;
  freeBetBalance: number;
  defaultBalance: USER_DEFAULT_BALANCE;
  middleName: string;
  idNumber: string | null;
  askId: boolean;
  withdrawalAllowed: boolean;
  withdrawalFieldsToVerify: string[];
  languageId: number;
  timezone: string;
  oddsFormat: number;
}

export interface UserProfilePayload {
  firstName?: string;
  lastName?: string;
  birthDate?: string | null;
  email?: string;
  gender?: number;
  idNumber?: string;
  middleName?: string;
}

export type GetUsersResponse = GetUserResponse[];

export interface UserChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
}

export interface PromoCodePayload {
  code: string;
}

export interface VerifyUserIdPayload {
  firstName: string;
  lastName: string;
  middleName?: string;
  idNumber: string;
}

export interface UserSession {
  deviceType: string;
  deviceOperationSystem: string;
  country: string;
  city: string;
  ip: string;
  createdAt: string;
}

export type UserSessions = UserSession[];

export interface UserSessionsResponse {
  activeSessions: UserSessions;
  previousSessions: UserSessions;
}

export interface UserDocumentResponse {
  id: UUID;
  type: DOCUMENT_TYPE_STATUSES;
  fileCount: number;
  status: USER_DOCUMENT_STATUS;
  created: string;
  file: UserDocumentFile;
}

export interface UserDocumentFile {
  fileName: 'string';
  url: 'string';
}
