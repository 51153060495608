import React, { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Cell, useTable, UseTableOptions } from 'react-table';

import { BetslipHistoryItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { ExpandRowCell } from '~components/molecules/BetslipHistorySection/components/ExpandRowCell';
import { TableDetailsExpander } from '~components/molecules/BetslipHistorySection/components/TableDetailsExpander';
import { TableHeader } from '~components/molecules/BetslipHistorySection/components/TableHeader';
import {
  StyledDataTableContainer,
  StyledTable,
  StyledTableRow,
} from '~components/molecules/BetslipHistorySection/styled.components';
import { JackpotBetsHistory } from '~types/jackpot';

import { useBetslipHistoryTable } from './useBetslipHistoryTable';

interface BetHistoryTableProps {
  betHistory: BetslipHistoryItem[];
  loadMore: () => void;
  total: number;
}

export const columnWidths: Record<number, string> = {
  0: '200x',
  1: '60px',
  2: '85px',
  3: '80px',
  4: '50px',
  5: '80px',
};

const SCROLL_CONTAINER_ID = 'sport-betslips-table-scroll-container';

export const BetslipHistoryTable = ({
  betHistory,
  loadMore,
  total,
}: BetHistoryTableProps) => {
  const {
    columnsOuter,
    openRows,
    getOpenRowsValue,
    handleOuterTableRowClick,
    renderExpandedRowContent,
  } = useBetslipHistoryTable();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columnsOuter,
      data: betHistory || [],
    } as UseTableOptions<BetslipHistoryItem>);

  return (
    <StyledDataTableContainer>
      <ScrollArea id={SCROLL_CONTAINER_ID}>
        <InfiniteScroll
          dataLength={betHistory.length}
          next={loadMore}
          hasMore={total > betHistory.length}
          scrollableTarget={SCROLL_CONTAINER_ID}
          loader={null}
        >
          <StyledTable {...getTableProps()}>
            <TableHeader
              headerGroups={headerGroups}
              columnWidths={columnWidths}
            />
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);

                const hasCashoutOption =
                  !!row.original.availableCashout && !row.original.winAmount;

                const isRowOpened = Boolean(openRows[row.id]);

                return (
                  <Fragment key={rowIndex}>
                    <StyledTableRow
                      {...row.getRowProps()}
                      className={isRowOpened ? 'expanded' : ''}
                      key={rowIndex}
                      onClick={handleOuterTableRowClick(rowIndex)}
                    >
                      {row.cells.map((cell, cellIndex) => (
                        <td
                          {...cell.getCellProps()}
                          key={cellIndex}
                          style={{
                            height: hasCashoutOption ? '86.33px' : '52px',
                            maxWidth: columnWidths[cellIndex]
                              ? columnWidths[cellIndex]
                              : 'none',
                          }}
                        >
                          <Box
                            flexRow
                            alignCenter
                            css={{
                              background: '$secondaryTwo',
                              height: hasCashoutOption ? '86.33px' : '52px',
                            }}
                          >
                            <ExpandRowCell
                              cell={
                                cell as Cell<
                                  BetslipHistoryItem | JackpotBetsHistory
                                >
                              }
                              isOpen={isRowOpened}
                            />
                          </Box>
                        </td>
                      ))}
                    </StyledTableRow>
                    {renderExpandedRowContent && isRowOpened && (
                      <TableDetailsExpander
                        columnsOuterLength={columnsOuter.length}
                        getOpenRowsValue={getOpenRowsValue}
                        rowIndex={rowIndex}
                        openRows={openRows}
                      >
                        {renderExpandedRowContent(row.original)}
                      </TableDetailsExpander>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </StyledTable>
        </InfiniteScroll>
      </ScrollArea>
    </StyledDataTableContainer>
  );
};
