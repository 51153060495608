export const semabet = {
  colors: {
    primaryOne: '#003C75',
    primaryTwo: '#092D4F',
    secondaryOne: '#092742',
    secondaryTwo: '#1C3E5C',
    textButtons: '#092742',
    textPrimaryOne: '#FAFAFA',
    textSecondaryOne: '#97ADC2',
    textPrimaryTwo: '#092742',
    accentPrimaryOne: '#F9F503',
    accentSecondaryOne: '#3D3C01',
    accentPrimaryTwo: '#F9F503',
    accentSecondaryTwo: '#3D3C01',
    pressedOne: '#999602',
    redPrimary: '#F05C4F',
    redSecondary: '#3D1815',
    greenPrimary: '#0ACC00',
    greenSecondary: '#033D00',
    yellowPrimary: '#F9F503',
    yellowSecondary: '#3D3C01',
    bluePrimary: '#1F60FF',
    orangePrimary: '#F07300',
    hoverPrimary: '#F5F258',
    hoverSecondary: '#003C75',

    // COLORS WITH OPACITY
    primaryOneOpacity: 'rgba(0, 60, 117, 0.7)',
    greenPrimaryOpacity: 'rgba(10, 204, 0, 0.2)',
    yellowPrimaryOpacity: 'rgba(249, 245, 3, 0.2)',
    primaryTwoOpacity: 'rgba(9, 45, 79, 0.7)',
    footerBackgroundOpacity: 'rgba(9, 39, 66, 0.80)',
    blackOpacity0: 'rgba(7, 33, 56, 0.0)',
  },
  shadows: {
    elementHoverBorder: 'inset 0 0 0 1px #F5F258', // hoverPrimary
    elementActiveBorder: 'inset 0 0 0 1px #999602', // pressedOne
    elementErrorBorder: 'inset 0 0 0 1px #F05C4F', // redPrimary
    elementNeutralBorder: 'inset 0 0 0 1px #FAFAFA', // textPrimaryOne
  },
};
