import { styled } from 'stitches.config';

import { zIndex } from '~utils/zIndex';

import { Box } from '../Box';

export const StyledDragContainer = styled(Box, {
  width: '100%',
  zIndex: zIndex.modal,
  position: 'fixed',
  left: 0,
  bottom: 0,
  borderRadius: '$12 $12 0 0',
  borderTop: '1px solid $primaryTwo',
  background: '$primaryTwoOpacity',
  backdropFilter: 'blur(4px)',
});

export const StyledSwipeHeader = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
});

export const StyledTitleLine = styled(Box, {
  width: '$7',
  height: '2px',
  borderRadius: '$2',
  background: '$textPrimaryOne',
  cursor: 'grab',
  margin: '$2 auto $1',
});

export const StyledTitle = styled(Box, {
  color: '$textPrimaryOne',
  fontSize: '$10',
  lineHeight: '$16',
  textTransform: 'uppercase',
  p: '$1 0 $2 0',
});

export const StyledOverlay = styled(Box, {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.3)',
  zIndex: zIndex.modalBackdrop - 1,
});
