import { TIME_RANGES } from '~types/timeRanges';

export const getLocalizedTimeRanges = (
  localized: (key: string, args?: object) => string,
) => [
  { label: localized('sidebar.timeRanges.all'), value: TIME_RANGES.ALL },
  { label: localized('sidebar.timeRanges.today'), value: TIME_RANGES.TODAY },
  {
    label: localized('sidebar.timeRanges.hours', { count: 3 }),
    value: TIME_RANGES.THREE_HOURS,
  },
  {
    label: localized('sidebar.timeRanges.hours', { count: 6 }),
    value: TIME_RANGES.SIX_HOURS,
  },
  {
    label: localized('sidebar.timeRanges.hours', { count: 12 }),
    value: TIME_RANGES.TWELVE_HOURS,
  },
  {
    label: localized('sidebar.timeRanges.hours', { count: 24 }),
    value: TIME_RANGES.TWENTY_FOUR_HOURS,
  },
  {
    label: localized('sidebar.timeRanges.hours', { count: 48 }),
    value: TIME_RANGES.FORTY_EIGHT_HOURS,
  },
  {
    label: localized('sidebar.timeRanges.hours', { count: 72 }),
    value: TIME_RANGES.SEVENTY_TWO_HOURS,
  },
];
