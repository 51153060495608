import { SportGroup, Sports } from '~api/category/types';
import { Box } from '~components/atoms/Box';
import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionRoot,
} from '~components/molecules/MultiAccordion';
import { SPORT_GROUP_COMPONENTS } from '~components/molecules/PrematchMenu/constants';

import { TournamentsGroup } from './TournamentsGroup';

export interface MobileTournamentGroupProps {
  sportGroupsMemo: SportGroup[];
  componentName: SPORT_GROUP_COMPONENTS;
  sports: Sports;
}

export const MobileTournamentsGroup = ({
  sportGroupsMemo,
  componentName,
  sports,
}: MobileTournamentGroupProps) => {
  return (
    <Box
      css={{
        '@xs_sm': {
          background: '$primaryOne',
          padding: '$2 $1',
        },
      }}
    >
      <MultiAccordionRoot
        role={MULTI_ACCORDION_ROLES.CHILD}
        disabled
        type="multiple"
        mobile
        value={sports.map(({ id }) => id.toString())}
      >
        <TournamentsGroup
          sportGroupsMemo={sportGroupsMemo}
          componentName={componentName as SPORT_GROUP_COMPONENTS}
        />
      </MultiAccordionRoot>
    </Box>
  );
};
