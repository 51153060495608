import { QueryParams } from '~utils/url';

export function buildQueryUrl<T>(base: string, params: QueryParams<T>): string {
  let url = base;

  const queryParams: string[] = [];

  for (const key in params) {
    if (params[key] !== undefined) {
      queryParams.push(`${key}=${params[key]}`);
    }
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  return url;
}
