import { ChangeEvent, useEffect, useState } from 'react';

const INC_VALUE = 5;

interface NumberInputHook {
  value: string;
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement> | string) => void;
}

interface UseNumberInputProps {
  onSuccess: (value: string) => void;
  initialValue: string;
  enableDecimals?: boolean;
}

export const useNumberInput = ({
  onSuccess,
  initialValue,
  enableDecimals,
}: UseNumberInputProps): NumberInputHook => {
  const [value, setValue] = useState(initialValue);

  const handleIncrement = () => {
    const resVal = +value + INC_VALUE;

    setValue(resVal.toString());
    onSuccess(resVal.toString());
  };

  const handleDecrement = () => {
    const nextValue = +value - INC_VALUE <= 0 ? 0 : +value - INC_VALUE;

    setValue(nextValue.toString());
    onSuccess(nextValue.toString());
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement> | string) => {
    let inputValue: string;

    if (typeof event === 'string') {
      inputValue = event;
    } else {
      inputValue = event.target.value;
    }

    if (inputValue.startsWith('0') && !inputValue.startsWith('0.')) {
      inputValue = inputValue.slice(1);
    }

    const regex = enableDecimals ? /^\d+([.,]\d{0,2})?$/ : /^\d+$/;

    if (inputValue === '' || regex.test(inputValue)) {
      setValue(inputValue);
      onSuccess(inputValue.replaceAll(',', '.'));
    }
  };

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue, value]);

  return {
    value,
    onIncrement: handleIncrement,
    onDecrement: handleDecrement,
    onChange: handleChange,
  };
};
