import { CSS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

const styledTouchSliderWrapperPseudoCSS: CSS = {
  content: '""',
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '$8',
  zIndex: 1,
  pointerEvents: 'none',
};

export const StyledTouchSliderWrapper = styled(Box, {
  position: 'relative',
  background: '$primaryOne',

  '&:after': {
    ...styledTouchSliderWrapperPseudoCSS,
    right: 0,
    background: 'linear-gradient(270deg, $primaryOne 0%, $blackOpacity0 100%)',
  },
  '&:before': {
    ...styledTouchSliderWrapperPseudoCSS,
    left: 0,
    background: 'linear-gradient(270deg, $blackOpacity0 0%, $primaryOne 100%)',
  },
  variants: {
    type: {
      desktop: {
        '& > *': {
          padding: '$1 $2',
        },
      },
      sportGroup: {},
      menu: {
        background: '$primaryOne !important',
        '& > *': {
          background: '$primaryOne !important',
        },
      },
    },
    noGradient: {
      true: {
        '&:after, &:before': {
          display: 'none',
        },
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
});

export const StyledTouchSliderInnerWrapper = styled(Box, {
  backgroundColor: '$primaryOne',
  width: 'fit-content',
  display: 'flex',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  '& > *': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    flexGrow: 0,
    p: '$2 $1 $1',
    color: '$textPrimaryOne',
    '&.active': {
      position: 'sticky',
      left: '-1px',
      right: '-1px',
      zIndex: 2,
      '& .icon *, & .tag': {
        fill: '$accentPrimaryTwo',
        color: '$accentPrimaryTwo',
      },
      '& .text': {
        color: '$accentPrimaryTwo',
      },
    },
  },
  variants: {
    type: {
      desktop: {
        '& > *': {
          flexDirection: 'row',
          gap: '$2',
          padding: '$2 $4 $1 $1',
        },
      },
      sportGroup: {
        '& > *': {
          flexDirection: 'row',
          gap: '$2',
          padding: '$3 $3',
        },
        '& .icon svg': {
          width: '$5',
          height: '$5',
        },
        '& .text': {
          fontSize: '$14',
          lineHeight: '$20',
        },
      },
      menu: {
        background: '$primaryOne !important',
        gap: '$3',
        '& > *': {
          flexDirection: 'row',
          gap: '$4',
          padding: 0,
          '&.active': {
            position: 'relative',
            left: 0,
            right: 0,
          },
        },
      },
    },
    fullWidth: {
      true: {
        justifyContent: 'space-around',
      },
    },
  },
});

export const ScrollableBox = styled(Box, {
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-webkit-overflow-scrolling': 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
