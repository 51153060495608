import React, { memo } from 'react';

import { InPlayMenuSports } from '~api/sportEvent/types';

import { MultiAccordionRoot } from '../../MultiAccordion';
import { useLiveSportMenu } from '../hooks/useLiveSportMenu';

import { LiveMenuGroups } from './LiveMenuGroups';
import { LiveMenuGroupsMobile } from './LiveMenuGroupsMobile';
import { LiveSportMenuItem } from './LiveSportMenuItem';

interface LiveSportMenuProps {
  inPlayMenuSports: InPlayMenuSports;
}

export const LiveSportMenu = memo(
  ({ inPlayMenuSports }: LiveSportMenuProps) => {
    const {
      menuRole,
      openedSports,
      isMobileOrTablet,
      handleOnValueChange,
      getSportEventsCount,
    } = useLiveSportMenu();

    return (
      <>
        {isMobileOrTablet ? <LiveMenuGroupsMobile /> : <LiveMenuGroups />}
        <MultiAccordionRoot
          type="multiple"
          role={menuRole}
          onValueChange={handleOnValueChange}
          value={openedSports}
        >
          {inPlayMenuSports.map(({ id, name, countries }) => {
            const defaultOpenLeagues = countries.flatMap(({ leagues }) => {
              return leagues.map(({ id: leagueId }) => leagueId.toString());
            });

            return (
              <LiveSportMenuItem
                key={id}
                {...{
                  id,
                  name,
                  countries,
                  getSportEventsCount,
                  menuRole,
                  defaultOpenLeagues,
                }}
              />
            );
          })}
        </MultiAccordionRoot>
      </>
    );
  },
);
