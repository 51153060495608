import { createSelector } from '@reduxjs/toolkit';

import { MobileSetting } from '~api/partner/types';
import { CURRENCY_CODES } from '~constants/common';
import { RootState } from '~store';

/*  Getters */
export const getCurrency = (state: RootState) => state.settings.currency;

export const getSiteConfiguration = (state: RootState) =>
  state.settings.siteConfiguration;

export const getPartnerLimits = (state: RootState) =>
  state.settings.partnerLimits;

export const getAllowUserDeactivation = (state: RootState) =>
  state.settings.allowUserDeactivation;

export const getCurrencySymbol = (state: RootState) =>
  state.settings.currencySymbol;

export const getMobileSettings = (state: RootState): MobileSetting =>
  state.settings.mobileSettings[0] || ({} as MobileSetting);

/* Selectors */
export const selectIsUganda = createSelector(
  [getCurrency],
  (currency) => currency === CURRENCY_CODES.UGX,
);

export const selectIsBetConfirmationRequired = createSelector(
  [getSiteConfiguration],
  (siteConfiguration) => siteConfiguration?.isBetConfirmationRequired,
);

export const selectIsCallbackAvailable = createSelector(
  [getSiteConfiguration],
  (siteConfiguration) => siteConfiguration?.isCallbackAvailable,
);

export const selectIsInviteFriendAvailable = createSelector(
  [getSiteConfiguration],
  (siteConfiguration) => siteConfiguration?.isInviteFriendAvailable,
);

export const selectPartnerSelectionLimits = createSelector(
  [getPartnerLimits],
  (partnerLimits) => ({
    sportMaxSelectionCount: partnerLimits.sportMaxSelectionCount,
    sportMinSelectionCount: partnerLimits.sportMinSelectionCount,
  }),
);
