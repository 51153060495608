// eslint-disable-next-line import/no-unresolved
import { ConfigType } from '@stitches/react/types/config';

import { mediaQueriesAndCombinations } from '~constants/mediaQueries';
import spaceUtils from '~utils/stitchesSpaceUtils';

export const measurements = {
  0: 0,
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '2.25rem', // 36px
  8: '2.5rem', // 40px
  9: '3rem', // 48px
  10: '3.5rem', // 56px
  11: '4rem', // 64px
  12: '4.5rem', // 72px
  13: '5rem', // 80px
  14: '6rem', // 96px
  15: '7rem', // 112px
};

export const fontSizesArray = [
  8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 24, 25, 28, 32, 34, 48,
];

export const lineHeightsArray = [
  12, 14, 15, 16, 20, 24, 25, 28, 32, 36, 40, 44, 52,
];

export const radiiArray = [2, 3, 4, 6, 8, 10, 12, 16];

const getStyleVariants = (array: number[]) => {
  const resSizes: Record<string, string> = {};

  array.forEach((fontSize) => {
    resSizes[fontSize] = `${fontSize / 16}rem`;
  });

  return resSizes;
};

export const defaultThemeConfig = {
  theme: {
    colors: {
      primaryOne: '#0A0F14',
      primaryTwo: '#1F2730',
      secondaryOne: '#182029',
      secondaryTwo: '#323B47',
      textButtons: '#FAFAFA',
      textPrimaryOne: '#FAFAFA',
      textSecondaryOne: '#CCCCCC',
      textPrimaryTwo: '#0A0F14',
      accentPrimaryOne: '#048531',
      accentSecondaryOne: '#023D17',
      accentPrimaryTwo: '#F5CA14',
      accentSecondaryTwo: '#3D3305',
      pressedOne: '#035720',
      redPrimary: '#EB3731',
      redSecondary: '#3D0E0D',
      greenPrimary: '#059939',
      greenSecondary: '#023D17',
      yellowPrimary: '#F5CA14',
      yellowSecondary: '#3D3305',
      bluePrimary: '#1F60FF',
      orangePrimary: '#F07300',
      hoverPrimary: '#20A850',
      hoverSecondary: '#0A0F14',

      // COLORS WITH OPACITY
      primaryOneOpacity: 'rgba(10, 15, 20, 0.7)',
      greenPrimaryOpacity: 'rgba(5, 153, 57, 0.2)',
      yellowPrimaryOpacity: 'rgba(245, 202, 20, 0.2)',
      primaryTwoOpacity: 'rgba(31, 39, 48, 0.7)',
      footerBackgroundOpacity: 'rgba(24, 32, 41, 0.80)',
      blackOpacity0: 'rgba(10, 15, 20, 0.0)',
    },
    space: { ...measurements },
    sizes: { ...measurements },
    fontSizes: {
      nano: '0.5rem', // 8px
      xxxxs: '0.625rem', // 10px
      xxxs: '0.6875rem', // 11px
      xxs: '0.75rem', // 12px
      xs: '0.8125rem', // 13px
      sm: '0.875rem', // 14px
      sm2: '0.9375rem', // 15px
      md: '1rem', // 16px
      md2: '1.125rem', // 18px
      lg: '1.25rem', // 20px
      xl: '1.5rem', // 24px
      xxl: '1.75rem', // 28px
      xxl2: '2rem',
      xxxl: '2.125rem', // 34px
      xxxxl: '3rem', // 48px
      ...getStyleVariants(fontSizesArray),
    },
    fontWeights: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
    lineHeights: {
      // deprecated, use values from lineHeightsArray instead
      1: '0.75rem', // 12px
      2: '1rem', // 16px
      3: '1.25rem', // 20px
      4: '1.5rem', // 24px
      5: '1.75rem', // 28px
      6: '2rem', // 32px
      7: '2.25rem', // 36px
      8: '2.5rem', // 40px
      9: '3.25rem', // 52px
      //
      ...getStyleVariants(lineHeightsArray),
    },
    radii: {
      // deprecated, use values from radiiArray instead
      xxxs: '0.125rem', // 2px
      xxs: '0.25rem', // 4px
      xs: '0.375rem', // 6px
      sm: '0.5rem', // 8px
      md: '0.625rem', // 10px
      lg: '0.75rem', // 12px
      xl: '1rem', // 16px
      //
      round: '50%',
      full: '100%',
      ...getStyleVariants(radiiArray),
    },
    shadows: {
      elementHoverBorder: 'inset 0 0 0 1px #20A850', // hoverPrimary
      elementActiveBorder: 'inset 0 0 0 1px #035720', // pressedOne
      elementErrorBorder: 'inset 0 0 0 1px #EB3731', // redPrimary
      elementNeutralBorder: 'inset 0 0 0 1px #FAFAFA', // textPrimaryOne
    },
  },
  media: { ...mediaQueriesAndCombinations },
  utils: {
    ...(spaceUtils() as ConfigType.Utils<{ [p: string]: unknown }> | undefined),
  },
};
