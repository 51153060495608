import { useEffect } from 'react';
import { useTranslation as useI18nTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LANGUAGE_TYPES, languagesCode } from '~constants/language';
import usePrevious from '~hooks/usePrevious';
import { useAppSelector } from '~store';
import { setTranslationFunction } from '~store/slices/translationSlice'; // Adjust the path accordingly
import { selectLanguage } from '~store/slices/websiteSettings';

export const useInitTranslation = () => {
  const { i18n, t } = useI18nTranslation();
  const dispatch = useDispatch();
  const language = useAppSelector(selectLanguage);
  const languagePrevious = usePrevious(language);

  useEffect(() => {
    if (language === languagePrevious) return;

    const langCode =
      languagesCode[language as LANGUAGE_TYPES] ||
      languagesCode[LANGUAGE_TYPES.English];

    if (language) {
      i18n.changeLanguage(langCode);
      dispatch(setTranslationFunction(t)); // Save the `t` function in the store
    }
  }, [language, i18n, t, dispatch, languagePrevious]);
};
