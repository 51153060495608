import {
  BalanceManagementIcon,
  BetHistoryIcon,
  BonusesIcon,
  InviteFriendsIcon,
  SettingsIcon,
  TransactionsIcon,
  UserProfileIcon,
} from '~icons';
import { SVGElement } from '~types/general';

import { USER_PROFILE_NAVIGATION_TABS } from '../components/UserProfileDialog/constants';

export interface NavigationItem {
  icon: SVGElement;
  title: string;
  value: string;
  mobileOnly?: boolean;
  hideOnCasino?: boolean;
}

interface GetNavigationItemsProps {
  isCasinoWebsite?: boolean;
  isMobileOrTablet?: boolean;
  isInviteFriendAvailable?: boolean;
}

export const navigationItems: NavigationItem[] = [
  {
    icon: UserProfileIcon,
    title: 'userProfile.menuTitles.personalProfile',
    value: USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE,
  },
  {
    icon: BalanceManagementIcon,
    title: 'userProfile.menuTitles.balanceManagement',
    value: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
  },
  {
    icon: TransactionsIcon,
    title: 'userProfile.menuTitles.transactions',
    value: USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY,
  },
  {
    icon: BetHistoryIcon,
    title: 'userProfile.menuTitles.history',
    value: USER_PROFILE_NAVIGATION_TABS.HISTORY,
    hideOnCasino: true,
  },
  {
    icon: BonusesIcon,
    title: 'userProfile.menuTitles.bonuses',
    value: USER_PROFILE_NAVIGATION_TABS.BONUSES,
  },
  {
    icon: SettingsIcon,
    title: 'userProfile.menuTitles.settings',
    value: USER_PROFILE_NAVIGATION_TABS.SETTINGS,
    mobileOnly: true,
  },
];

export const getNavigationItems = ({
  isCasinoWebsite,
  isMobileOrTablet,
  isInviteFriendAvailable,
}: GetNavigationItemsProps) => {
  const items = [...navigationItems];

  if (isInviteFriendAvailable) {
    items.splice(5, 0, {
      icon: InviteFriendsIcon,
      title: 'userProfile.menuTitles.inviteFriends',
      value: USER_PROFILE_NAVIGATION_TABS.INVITE_FRIENDS,
    });
  }

  return items.filter(({ hideOnCasino, mobileOnly }) => {
    if (hideOnCasino && isCasinoWebsite) return false;
    if (mobileOnly && !isMobileOrTablet) return false;

    return true;
  });
};
