import { styled } from '@stitches/react';

import { Box } from '~components/atoms/Box';
import { SvgIconComponent } from '~components/atoms/SvgIconComponent';
import { ArrowLeftIcon } from '~icons';

const BackButtonWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  cursor: 'pointer',
  color: '$textSecondaryOne',
  backgroundColor: '$secondaryOne',
  backdropFilter: 'blur(4px)',
  padding: '$2 0',
});

interface BackButtonProps {
  onBackButtonPress: () => void;
  iconPath?: string;
  providerName?: string;
}

export const BackButton = ({
  onBackButtonPress,
  iconPath,
  providerName,
}: BackButtonProps) => {
  return (
    <BackButtonWrapper>
      <Box shouldHover>
        <ArrowLeftIcon onClick={onBackButtonPress} />
      </Box>
      <Box flexRow alignCenter>
        <SvgIconComponent
          iconPath={iconPath}
          alt={providerName}
          styles={{ maxWidth: '150px', maxHeight: '24px' }}
        />
      </Box>
    </BackButtonWrapper>
  );
};
