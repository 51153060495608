import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { buildSearchQuery } from '~utils/url';

import {
  BannerGroups,
  BannerGroupsQuery,
  InfoPage,
  InfoPageQueryData,
  MenuContents,
} from './types';

export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.CONTENT }),
  endpoints: (builder) => ({
    getMenuContent: builder.query<MenuContents, string>({
      query: (contentType) => ({
        url: `menu/${contentType}`,
        method: 'GET',
      }),
    }),
    getBannerByGroupName: builder.query<BannerGroups, BannerGroupsQuery>({
      query: (queryData) => ({
        url: `Banner${buildSearchQuery(queryData)}`,
        method: 'GET',
      }),
    }),
    getInfoPage: builder.query<InfoPage, InfoPageQueryData>({
      query: (queryData) => ({
        url: `Info${buildSearchQuery(queryData)}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetMenuContentQuery,
  useLazyGetBannerByGroupNameQuery,
  useLazyGetInfoPageQuery,
} = contentApi;
