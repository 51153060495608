import { memo, useEffect } from 'react';

import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import {
  StyledBetContainer,
  StyledBetsText,
} from '~components/molecules/Betslip/components/BetslipStakes/styled.components';
import { UseBetslipStakesReturn } from '~components/molecules/Betslip/hooks';
import { useMedia } from '~hooks';
import { useAppDispatch } from '~store';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../../constants';

import { useBetslipErrorMessage } from './hooks/useBetslipErrorMessage';
import { BetslipEmptyStakes } from './BetslipEmptyStakes';
import { BetslipInfoMessage } from './BetslipInfoMessage';
import BetslipStake from './BetslipStake';

interface BetslipStakesProps {
  quickBet?: boolean;
  stakeProps: UseBetslipStakesReturn;
}

const BetslipStakes = ({
  quickBet = false,
  stakeProps,
}: BetslipStakesProps) => {
  const { isMobileOrTablet } = useMedia();

  const {
    isProcessingFailed,
    duplicatedMultipleBetStakes,
    singleBetsAmountMap,
    stakeType,
    events,
    eventsData,
    showBetslipNotification,
    localized,
    handleRemoveStake,
    handleErrorMessageContinueClick,
  } = stakeProps;
  const dispatch = useAppDispatch();
  const betslipErrorMessage = useBetslipErrorMessage({ stakeProps });

  useEffect(() => {
    if (quickBet && !showBetslipNotification && !events.length) {
      dispatch(setIsSwipeOpen(null));
    }
  }, [showBetslipNotification]);

  const betslipCardGap =
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE || !isMobileOrTablet
      ? 2
      : 0;

  if (showBetslipNotification) {
    return (
      <StyledBetContainer>
        <BetslipInfoMessage
          isQuickBet={quickBet}
          isProcessingFailed={isProcessingFailed}
          betslipErrorMessages={betslipErrorMessage}
          handleErrorMessageContinueClick={handleErrorMessageContinueClick}
        />
      </StyledBetContainer>
    );
  }

  return (
    <StyledBetContainer>
      {events.length ? (
        <>
          {!quickBet && (
            <StyledBetsText>{localized('betslip.yourBets')}:</StyledBetsText>
          )}
          <Box flexCol gap={betslipCardGap}>
            {events.map((event, index) => {
              let isDuplicated = false;

              const { main, bonus } =
                singleBetsAmountMap[event.selectionId] || {};

              if (stakeType !== SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
                isDuplicated = duplicatedMultipleBetStakes.includes(
                  event.eventId,
                );
              }

              const eventData = eventsData.find(
                ({ id }) => event.eventId === id,
              ) as SportEventItem;

              return (
                <BetslipStake
                  quickBet={quickBet}
                  key={event.selectionId}
                  index={index}
                  eventData={eventData}
                  stakeType={stakeType}
                  isDuplicated={isDuplicated}
                  betslipItem={event}
                  eventsCount={events.length}
                  amountMain={main}
                  amountBonus={bonus}
                  onRemove={handleRemoveStake}
                />
              );
            })}
          </Box>
        </>
      ) : (
        <>{!quickBet && <BetslipEmptyStakes />}</>
      )}
    </StyledBetContainer>
  );
};

export default memo(BetslipStakes);
