import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { FavoriteMarket, SportFavoriteMarkets } from '~api/market/types';

export const marketApi = createApi({
  reducerPath: 'marketApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.MARKET }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getFavoriteMarkets: builder.query<SportFavoriteMarkets[], void>({
      query: () => ({
        url: 'market/favorite',
        method: 'GET',
      }),
    }),
    setMarketAsFavorite: builder.mutation<void, FavoriteMarket>({
      query: (body) => ({
        url: 'profile/market/favorite',
        method: 'POST',
        body,
      }),
    }),
    deleteMarketFromFavorites: builder.mutation<void, FavoriteMarket>({
      query: (body) => ({
        url: 'profile/market/favorite',
        method: 'DELETE',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetFavoriteMarketsQuery,
  useSetMarketAsFavoriteMutation,
  useDeleteMarketFromFavoritesMutation,
} = marketApi;
