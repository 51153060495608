import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { WebsiteSettingsResponse } from '~api/partner/types';

export const partnerApi = createApi({
  reducerPath: 'partnerApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.PARTNER }),
  endpoints: (builder) => ({
    settings: builder.query<WebsiteSettingsResponse, void>({
      query: () => ({
        url: `Settings`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazySettingsQuery } = partnerApi;
