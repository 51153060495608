import React, { ComponentPropsWithRef, forwardRef } from 'react';

import { Box } from './Box';
import { Label } from './Label';
import { ErrorMessage, Text } from './Typography';

interface LabelWithOppositeTextProps extends ComponentPropsWithRef<'label'> {
  children: React.ReactNode;
  isRequired?: boolean;
  oppositeText?: string;
  error?: boolean;
}

export const LabelWithOppositeText = forwardRef<
  HTMLLabelElement,
  LabelWithOppositeTextProps
>(({ children, oppositeText, isRequired, error, ...props }, forwardedRef) => {
  return (
    <Box
      flexRow
      css={{
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Label
        {...props}
        css={
          isRequired
            ? {
                position: 'relative',
                '&:before': {
                  content: '*',
                  left: '-10px',
                  color: '$redPrimary',
                  position: 'absolute',
                },
              }
            : {}
        }
        ref={forwardedRef}
      >
        {children}
      </Label>
      {error ? (
        <ErrorMessage message={oppositeText!} />
      ) : (
        oppositeText && (
          <Text
            level="xs-4"
            css={{ marginRight: '$3', color: '$textSecondaryOne' }}
          >
            {oppositeText}
          </Text>
        )
      )}
    </Box>
  );
});

LabelWithOppositeText.displayName = 'LabelWithOppositeText';
