import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  useGetAddress,
  useValidateAddress,
  useWithdrawal,
} from '~api/cryptoPayments/cryptoPaymentsMutations';
import {
  useLazyGetCoinsList,
  useLazyGetMinAmount,
} from '~api/cryptoPayments/cryptoPaymentsQueries';
import { CryptoTransaction } from '~api/cryptoPayments/types';
import { useLazyGetWithdrawalLimits } from '~api/transaction/transactionQueries';
import { LimitsResponse } from '~api/transaction/types';
import { UserProfileData } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { SelectOptionType } from '~components/atoms/SelectWithLabel/SelectWithLabel';
import { Text } from '~components/atoms/Typography';
import { coinNetworkMap } from '~components/molecules/UserProfile/components/Payments/hooks/coinNetworkMap';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { MESSAGE_TYPES } from '~constants/common';
import { ADD_PAYMENT_ACCOUNT_FORM_STATUSES } from '~constants/payments';
import { useDebouncedEffect, useMedia, useTranslation } from '~hooks';
import { GreenCheckIcon, RedCrossIcon } from '~icons';
import { BTC, DAI, DefaultCoin, ETH, USDC, USDT } from '~icons/coins';

const iconsMap = {
  DAI: DAI,
  USDTTRC20: USDT,
  USDTERC20: USDT,
  USDTSOL: USDT,
  USDC: USDC,
  USDTBSC: USDT,
  BTC: BTC,
  ETH: ETH,
};

import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNewPaymentMethodFormStatus,
  setCryptoPaymentData,
  setIsCryptoWithdraw,
  setPaymentMethodAmount,
  setTransactionId,
  setTransactionStatus,
} from '~store/slices/paymentsSlice';
import { TRANSACTION_STATUS } from '~types/transactions';
interface PaymentLimits {
  min: number;
  max: number;
}

export interface CryptoPaymentData {
  coins?: string[];
  selectedCoin?: string | undefined;
  coinsAmount?: string;
  withdrawAddress?: string;
  paymentLimits?: PaymentLimits;
}

const withdrawalCoins = ['USDTTRC20'];

export const useCryptoPaymentForm = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    transactionStatus,
    updatedTransactionId,
    transactionId,
    paymentSettings,
    cryptoPaymentData,
  } = useAppSelector((state) => state.payments);
  const { paymentSettings: paymentPartnerSettings } = useAppSelector(
    (state) => state.settings,
  );
  const [status, setStatus] = useState<TRANSACTION_STATUS>(
    TRANSACTION_STATUS.PENDING,
  );
  const { lazyGetCoinsListQuery } = useLazyGetCoinsList();
  const { lazyGetMinAmountQuery } = useLazyGetMinAmount();
  const { withdrawalMutation } = useWithdrawal();
  const { getAddressMutation } = useGetAddress();
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { profile } = useAppSelector((state) => state.userState);
  const [addressData, setAddressData] = useState<CryptoTransaction>();
  const { balance } = profile as UserProfileData;
  const [coinsAmount, setCoinsAmount] = useState<string>(
    cryptoPaymentData.coinsAmount || '0',
  );
  const [coins, setCoins] = useState<string[]>(cryptoPaymentData.coins || []);
  const [selectedCoin, setSelectedCoin] = useState<string | undefined>(
    cryptoPaymentData.selectedCoin || undefined,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState<boolean>(false);
  const [paymentLimits, setPaymentLimits] = useState<PaymentLimits>(
    cryptoPaymentData.paymentLimits || {
      min: 0,
      max: 0,
    },
  );
  const [withdrawAddress, setWithdrawAddress] = useState<string>(
    cryptoPaymentData.withdrawAddress || '',
  );
  const [withdrawalLimits, setWithdrawalLimits] = useState<LimitsResponse>();
  const [messageText, setMessageText] = useState<string[]>([]);
  const [messageType, setMessageType] = useState<MESSAGE_TYPES>(
    MESSAGE_TYPES.WARNING,
  );

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const { isMobileOrTablet } = useMedia();

  useEffect(() => {
    dispatch(
      setCryptoPaymentData({
        coins,
        selectedCoin,
        coinsAmount,
        withdrawAddress,
        paymentLimits,
      }),
    );
  }, [coins, selectedCoin, coinsAmount, withdrawAddress, paymentLimits]);

  useEffect(() => {
    const isWithdraw = contentTab === USER_PAYMENT_TABS.WITHDRAW;

    setAddressData(undefined);

    if (isWithdraw) {
      setCoins(withdrawalCoins);
      setSelectedCoin(withdrawalCoins[0]);
    } else {
      setSelectedCoin(undefined);
      setPaymentLimits({ min: 0, max: 0 });
    }
  }, [contentTab]);

  useEffect(() => {
    if (addressData) {
      const { validUntil } = addressData;
      const endTime = new Date(validUntil).getTime();
      const updateRemainingTime = () => {
        const now = new Date().getTime();
        const timeLeft = endTime - now;

        setRemainingTime(timeLeft > 0 ? timeLeft : 0);
      };

      updateRemainingTime();
      const intervalId = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [addressData]);

  useEffect(() => {
    if (!addressData) return;
    if (transactionStatus && updatedTransactionId === transactionId) {
      if (transactionStatus !== 2) {
        setRemainingTime(0);
      }

      setStatus(transactionStatus);
    }

    if (transactionId === 'notProcessed' || !transactionId?.length) {
      setStatus(TRANSACTION_STATUS.FAILED);
    }
  }, [updatedTransactionId, transactionId, transactionStatus, addressData]);

  const formatTime = (time: number) => {
    if (!time) return null;
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const { lazyGetWithdrawalLimitsQuery } = useLazyGetWithdrawalLimits();

  useEffect(() => {
    if (contentTab === USER_PAYMENT_TABS.WITHDRAW) return;

    const loadCoins = async () => {
      try {
        const data = await lazyGetCoinsListQuery().unwrap();

        setCoins(data.coins);
      } catch (e) {
        console.log(e);
      }
    };

    loadCoins();
  }, [contentTab]);

  const handleCoinChange = async (coin: string) => {
    const isDeposit = contentTab === USER_PAYMENT_TABS.DEPOSIT;

    if (isDeposit) {
      setIsLoading(true);
    }

    try {
      setSelectedCoin(coin);
      const data = await lazyGetMinAmountQuery(coin.toLowerCase()).unwrap();

      setPaymentLimits((prevState) => ({ ...prevState, min: data.fiatAmount }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeAmount = (amount: string) => setCoinsAmount(amount);

  useEffect(() => {
    dispatch(setPaymentMethodAmount(coinsAmount));
  }, [coinsAmount]);

  useEffect(() => {
    const loadLimits = async () => {
      try {
        const limits = await lazyGetWithdrawalLimitsQuery().unwrap();

        if (limits) {
          setWithdrawalLimits(limits);
        }
      } catch (e) {
        console.log(e);
      }
    };

    loadLimits();
  }, []);

  function daysLeftInMonth(): number {
    const today: Date = new Date();
    const endOfMonth: Date = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
    );

    return endOfMonth.getDate() - today.getDate();
  }

  const getMessage = (): {
    messageText: string[];
    messageType: MESSAGE_TYPES;
  } => {
    const defaultReturn = {
      messageText: [],
      messageType: MESSAGE_TYPES.WARNING,
    };

    if (!withdrawalLimits) return defaultReturn;

    const {
      allowedWithdrawLimit,
      possibleWithdrawMonthAmount,
      possibleWithdrawTodayAmount,
      possibleWithdrawTodayCount,
      possibleWithdrawMonthCount,
      withdrawalAmountLimitPerMonth,
      withdrawalAmountLimitPerDay,
      withdrawalCountLimitPerMonth,
      withdrawalCountLimitPerDay,
      withdrawalTimeFrom,
      withdrawalTimeTo,
      isWithdrawalAvailable,
    } = withdrawalLimits;

    //todo: extract it to separate hook
    if (allowedWithdrawLimit && allowedWithdrawLimit < +coinsAmount) {
      return {
        messageText: [
          localized('withdrawalLimits.withdrawWegeredTitle'),
          localized('withdrawalLimits.withdrawWegered', {
            amount: allowedWithdrawLimit,
            currency: selectedCoin,
          }),
        ],
        messageType: MESSAGE_TYPES.WARNING,
      };
    }

    if (possibleWithdrawTodayAmount <= 0 && withdrawalAmountLimitPerDay) {
      return {
        messageText: [
          localized('withdrawalLimits.dailyExceed'),
          localized('withdrawalLimits.possibleDayAmountExceeded', {
            amount: withdrawalAmountLimitPerDay,
            currency: selectedCoin,
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (possibleWithdrawMonthAmount <= 0 && withdrawalAmountLimitPerMonth) {
      return {
        messageText: [
          localized('withdrawalLimits.monthlyExceed'),
          localized('withdrawalLimits.possibleMonthAmountExceeded', {
            amount: withdrawalAmountLimitPerMonth,
            currency: selectedCoin,
            daysLeft: daysLeftInMonth(),
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (possibleWithdrawTodayCount <= 0 && withdrawalCountLimitPerDay) {
      return {
        messageText: [
          localized('withdrawalLimits.dailyReached'),
          localized('withdrawalLimits.possibleDayCountExceeded', {
            count: withdrawalCountLimitPerDay,
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (possibleWithdrawMonthCount <= 0 && withdrawalCountLimitPerMonth) {
      return {
        messageText: [
          localized('withdrawalLimits.monthlyReached'),
          localized('withdrawalLimits.possibleMonthCountExceeded', {
            count: withdrawalCountLimitPerMonth,
            daysLeft: daysLeftInMonth(),
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (contentTab === USER_PAYMENT_TABS.DEPOSIT)
      return { messageType: MESSAGE_TYPES.WARNING, messageText: [] };

    if (paymentPartnerSettings.withdrawalLimitMax < +coinsAmount) {
      return {
        messageText: [
          localized('withdrawalLimits.withdrawalRequestCreated'),
          localized('withdrawalLimits.withdrawalRequest', {
            amount: paymentPartnerSettings.withdrawalLimitMax,
            currency: 'USD',
          }),
        ],
        messageType: MESSAGE_TYPES.WARNING,
      };
    }

    if (withdrawalTimeFrom && withdrawalTimeTo && !isWithdrawalAvailable) {
      return {
        messageText: [
          localized('withdrawalLimits.withdrawHoursLimit', {
            withdrawalTimeFrom,
            withdrawalTimeTo,
          }),
        ],
        messageType: MESSAGE_TYPES.WARNING,
      };
    }

    return defaultReturn;
  };

  const handleSubmitGetAddress = async () => {
    setIsLoadingAddress(true);

    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      try {
        const data = await getAddressMutation({
          amount: +coinsAmount,
          currency: selectedCoin || '',
        }).unwrap();

        const { id } = data;

        dispatch(setTransactionId(id));

        setAddressData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingAddress(false);
      }

      return;
    }

    try {
      const { id } = await withdrawalMutation({
        amount: +coinsAmount,
        currency: selectedCoin || '',
        address: withdrawAddress,
      }).unwrap();

      dispatch(setIsCryptoWithdraw(true));
      dispatch(setTransactionId(id));
      dispatch(setTransactionStatus(TRANSACTION_STATUS.PENDING));
      dispatch(
        setAddingNewPaymentMethodFormStatus(
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
        ),
      );
    } catch (e) {
      dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
      dispatch(
        setAddingNewPaymentMethodFormStatus(
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
        ),
      );
    }

    setIsLoadingAddress(false);
  };

  const getIcon = () => {
    if (!remainingTime) return <RedCrossIcon width={16} height={16} />;

    switch (status) {
      case TRANSACTION_STATUS.SUCCESS:
        return <GreenCheckIcon width={16} height={16} />;
      case TRANSACTION_STATUS.PENDING:
        return (
          <Loader
            css={{
              color: '$yellowPrimary',
              '& > svg': {
                width: '16px',
                height: '16px',
              },
            }}
          />
        );
      case TRANSACTION_STATUS.FAILED:
        return <RedCrossIcon width={16} height={16} />;
      default:
        return null;
    }
  };

  const getTextStatus = () => {
    if (!remainingTime) return 'payments.expired';

    switch (status) {
      case TRANSACTION_STATUS.SUCCESS:
        return 'payments.success';
      case TRANSACTION_STATUS.PENDING:
        return 'payments.waiting';
      case TRANSACTION_STATUS.FAILED:
        return 'payments.failed';
      default:
        return '';
    }
  };

  const handleResetAddressData = () => {
    setAddressData(undefined);
    setCoinsAmount('0');
    setRemainingTime(0);
    setPaymentLimits({ min: 0, max: 0 });
  };

  const handleChangeWithdrawAddressValue = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setWithdrawAddress(e.target.value);
    setIsAddressValidationLoading(true);
  };

  const getMinValue = () => {
    if (paymentPartnerSettings) {
      const { withdrawalLimitMin } = paymentPartnerSettings;

      if (withdrawalLimitMin !== null) return withdrawalLimitMin;
    }

    if (paymentSettings) {
      const { minWithdrawAmount } = paymentSettings;

      if (minWithdrawAmount !== null) return minWithdrawAmount;
    }

    return 0;
  };

  const getMaxValue = () => {
    if (paymentSettings) {
      const { maxWithdrawAmount } = paymentSettings;

      if (maxWithdrawAmount !== null) return maxWithdrawAmount;
    }

    if (paymentPartnerSettings) {
      const { withdrawalLimitMax } = paymentPartnerSettings;

      if (withdrawalLimitMax !== null) return withdrawalLimitMax;
    }

    return Infinity;
  };

  const { validateAddressMutation } = useValidateAddress();
  const [isAddressValid, setIsAddressValid] = useState<boolean>(true);
  const [isAddressValidationLoading, setIsAddressValidationLoading] =
    useState<boolean>(false);

  useDebouncedEffect(
    () => {
      const validateAddress = async () => {
        try {
          await validateAddressMutation({
            address: withdrawAddress,
          }).unwrap();

          setIsAddressValid(true);
        } catch (e) {
          setIsAddressValid(false);
        } finally {
          setIsAddressValidationLoading(false);
        }
      };

      setIsAddressValidationLoading(true);

      if (!withdrawAddress.length) {
        setIsAddressValidationLoading(false);
        setIsAddressValid(true);

        return;
      }

      validateAddress();
    },
    [withdrawAddress],
    300,
  );

  const getAddressIcon = () => {
    if (!withdrawAddress.length) {
      return null;
    }

    if (isAddressValidationLoading) {
      return (
        <Loader
          css={{
            alignItems: 'center',
            color: '$yellowPrimary',
            width: '14px',
            height: '14px',
          }}
        />
      );
    }

    if (isAddressValid) {
      return <GreenCheckIcon width={14} height={14} />;
    }

    if (!isAddressValid) {
      return <RedCrossIcon width={14} height={14} />;
    }

    return null;
  };

  const handleClearAddress = () => {
    setWithdrawAddress('');
    setIsLoadingAddress(false);
    setIsAddressValidationLoading(false);
  };

  useEffect(() => {
    const { messageText, messageType } = getMessage();

    setMessageText(messageText);
    setMessageType(messageType);

    const isDeposit = contentTab === USER_PAYMENT_TABS.DEPOSIT;

    const isWithdrawUnavailable =
      withdrawalLimits?.withdrawalTimeFrom &&
      withdrawalLimits?.withdrawalTimeTo &&
      !withdrawalLimits?.isWithdrawalAvailable;

    const isAmountBiggerThenBalance =
      balance < +coinsAmount && contentTab !== USER_PAYMENT_TABS.DEPOSIT;

    if (isDeposit) {
      const isMinMaxValid =
        (!paymentLimits.min || +coinsAmount >= paymentLimits.min) &&
        (!getMaxValue() || +coinsAmount <= getMaxValue());

      const isSubmitButtonDisabled =
        !isMinMaxValid || !selectedCoin || !+coinsAmount;

      setIsSubmitButtonDisabled(isSubmitButtonDisabled);

      return;
    }

    const isAmountOutOfRange =
      +coinsAmount < getMinValue() || +coinsAmount > getMaxValue();

    const isWalletInvalid =
      (!isAddressValidationLoading &&
        withdrawAddress.length &&
        !isAddressValid) ||
      !withdrawAddress.length;

    const isLimitError =
      messageText.length > 0 && messageType === MESSAGE_TYPES.ERROR;

    const isWithdrawDisabled =
      isAmountOutOfRange ||
      !selectedCoin ||
      isWalletInvalid ||
      isWithdrawUnavailable ||
      isAmountBiggerThenBalance ||
      isLimitError;

    setIsSubmitButtonDisabled(isWithdrawDisabled as boolean);
  }, [
    isAddressValid,
    isAddressValidationLoading,
    withdrawAddress,
    withdrawalLimits,
    balance,
    selectedCoin,
    coinsAmount,
    contentTab,
    paymentLimits,
    paymentSettings,
    paymentPartnerSettings,
  ]);

  const renderLabel = (option: SelectOptionType) => {
    const { label, title, value } = option;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Icon = iconsMap[value as any] || DefaultCoin;

    return (
      <Box flexRow alignCenter gap={2}>
        {Icon && (
          <Box
            flexRow
            alignCenter
            justifyCenter
            css={{
              borderRadius: '50%',
              width: '18px',
              height: '18px',
              '& svg': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            {<Icon />}
          </Box>
        )}

        <Text level={'14-20'} color="textPrimaryOne">
          {title}
        </Text>
        <Box
          css={{
            padding: '0 $1',
            background: '$secondaryOne',
            borderRadius: '$4',
          }}
        >
          <Text color="accentPrimaryOne" level={'12-20'}>
            {label as string}
          </Text>
        </Box>
      </Box>
    );
  };

  return {
    isAddressInvalid:
      !isAddressValidationLoading && withdrawAddress.length && !isAddressValid,
    addressData,
    isMobileOrTablet,
    min: !selectedCoin
      ? 0
      : contentTab === USER_PAYMENT_TABS.WITHDRAW
        ? getMinValue()
        : paymentLimits.min,
    max: !selectedCoin ? 0 : getMaxValue(),
    messageText,
    messageType,
    isLowerOrIsBigger: false,
    isAmountBiggerThenBalance: false,
    isSubmitButtonDisabled,
    coinsOptions:
      coins?.map((coin) => ({
        label:
          coinNetworkMap[coin.toUpperCase() as keyof typeof coinNetworkMap],
        value: coin,
        title: `${coin.toUpperCase().includes('USDT') ? 'USDT' : coin.toUpperCase()}`,
      })) || [],
    selectedCoin,
    coinsAmount,
    isLoading,
    isLoadingAddress,
    isDeposit: contentTab === USER_PAYMENT_TABS.DEPOSIT,
    withdrawAddress,
    remainingTime: formatTime(remainingTime),
    handleCoinChange,
    handleChangeAmount,
    handleClearAddress,
    handleChangeWithdrawAddressValue,
    onSubmit: handleSubmitGetAddress,
    status,
    getIcon,
    renderLabel,
    getTextStatus,
    handleResetAddressData,
    getAddressIcon,
    isAddressEmpty: !withdrawAddress.length,
  };
};
