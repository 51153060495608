import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '~components/atoms/Box';
import {
  INFO_PAGE_BASE_PATH,
  INFO_PAGE_SECTION_NAMES,
} from '~constants/common';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { CrossIcon } from '~icons';
import { closeDialog } from '~store/slices/globalDialogSlice';

import {
  StyledActionButton,
  StyledButtonText,
  StyledErrorPageContainer,
  StyledErrorText,
  StyledErrorTitle,
  StyledIconWrapper,
} from './styled.components';

interface ErrorPageProps {
  title: string;
  text?: string;
}

export const ErrorPage = ({ title, text }: ErrorPageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localizedError, localized } = useTranslation();
  const { navigateBack } = useRouterQuery();

  const handleContactUsClick = () => {
    dispatch(closeDialog());
    navigate(`${INFO_PAGE_BASE_PATH}/${INFO_PAGE_SECTION_NAMES.CONTACT_US}`);
  };

  const handleBackClick = () => {
    navigateBack();
  };

  return (
    <StyledErrorPageContainer>
      <StyledIconWrapper>
        <CrossIcon />
      </StyledIconWrapper>
      <Box>
        <StyledErrorTitle>
          {title || localizedError('root.someErrorOccurredTitle')}
        </StyledErrorTitle>
      </Box>
      {text ? (
        <Box>
          <StyledErrorText>{text}</StyledErrorText>
        </Box>
      ) : (
        <Box flexCol justifyCenter alignCenter>
          <StyledErrorText>
            {localizedError('root.somethingNotWorking')}
          </StyledErrorText>
          <StyledErrorText>
            {localizedError('root.apologiesForError')}
          </StyledErrorText>
        </Box>
      )}
      <Box
        flexRow
        fullWidth
        justifyCenter
        gap={3}
        css={{ backgroundColor: '$secondaryOne', padding: '28px' }}
      >
        <StyledActionButton variant="secondaryTwo" onClick={handleBackClick}>
          <StyledButtonText>{localized('buttons.back')}</StyledButtonText>
        </StyledActionButton>
        <StyledActionButton
          variant="secondaryTwo"
          onClick={handleContactUsClick}
        >
          <StyledButtonText>
            {`${localized('buttons.contactUs')}`}
          </StyledButtonText>
        </StyledActionButton>
      </Box>
    </StyledErrorPageContainer>
  );
};
