import React from 'react';

import { Box } from '~components/atoms/Box';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import { JackpotBetEvent } from '~types/jackpot';
import { ellipsis } from '~utils/cssUtils';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

export const LeagueCell = ({ event }: { event: JackpotBetEvent }) => {
  const { countryName, leagueName, startDate } = event;

  return (
    <Box css={{ ...ellipsis }}>
      <StyledTableText
        ellipsis
      >{`${countryName}. ${leagueName}`}</StyledTableText>
      <StyledTableText>
        {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
      </StyledTableText>
    </Box>
  );
};
