import {
  useLazyGetActiveJackpotsQuery,
  useLazyGetJackpotsBetsHistoryQuery,
  useLazyGetSettledJackpotsQuery,
} from './jackpotApi';

export const useLazyGetActiveJackpots = () => {
  const [query, { data, error, isLoading }] = useLazyGetActiveJackpotsQuery();

  return {
    lazyGetActiveJackpotsQuery: query,
    lazyGetActiveJackpotsData: data,
    lazyGetActiveJackpotsError: error,
    lazyGetActiveJackpotsIsLoading: isLoading,
  };
};

export const useLazyGetSettledJackpots = () => {
  const [query, { data, error, isLoading }] = useLazyGetSettledJackpotsQuery();

  return {
    lazyGetSettledJackpotsQuery: query,
    lazyGetSettledJackpotsData: data,
    lazyGetSettledJackpotsError: error,
    lazyGetSettledJackpotsIsLoading: isLoading,
  };
};

export const useLazyGetJackpotsBetsHistory = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetJackpotsBetsHistoryQuery();

  return {
    lazyGetJackpotsBetsHistoryQuery: query,
    lazyGetJackpotsBetsHistoryData: data,
    lazyGetJackpotsBetsHistoryError: error,
    lazyGetJackpotsBetsHistoryIsLoading: isLoading,
  };
};
