import { CLIENTS } from '~constants/clients';
import { THEMES } from '~constants/ui';
import { getTheme } from '~utils/getTheme';

const themesMap: Record<THEMES, CLIENTS> = {
  [THEMES.BET_ONLY]: CLIENTS.BET_ONLY,
  [THEMES.SEMA_BET]: CLIENTS.SEMA_BET,
  [THEMES.OTBET]: CLIENTS.OTBET,
  [THEMES.BETPRAZER]: CLIENTS.BETPRAZER,
  [THEMES.HALABET]: CLIENTS.HALABET,
  [THEMES.DEFAULT]: CLIENTS.NONE,
};

const getClientByDomain = () => {
  if (window.location.hostname.includes('betprazer')) {
    return CLIENTS.BETPRAZER;
  } else if (window.location.hostname.includes('halabet')) {
    return CLIENTS.HALABET;
  } else if (window.location.hostname.includes('otbet')) {
    return CLIENTS.OTBET;
  } else {
    return CLIENTS.NONE;
  }
};

export const getTenant = () => {
  const theme = getTheme();

  let tenant = themesMap[theme];

  if (tenant === CLIENTS.NONE) {
    tenant = getClientByDomain() || CLIENTS.NONE;

    return tenant;
  }

  return tenant;
};
