export type PersonalDetailsFormInputs = {
  userFirstNameField: string;
  userLastNameField: string;
  userEmailField: string;
  phoneNumberField: string;
  birthDateField: string | null;
  userGenderField: string | null;
  userIdField: string | null;
  userMiddleNameField: string | null;
};

export const PERSONAL_DETAILS_I18N_TITLES = {
  username: 'userProfile.personalProfileInfo.username',
  id: 'userProfile.personalProfileInfo.id',
  userFirstNameField: 'userProfile.personalProfileInfo.firstName',
  userLastNameField: 'userProfile.personalProfileInfo.lastName',
  userEmailField: 'userProfile.personalProfileInfo.email',
  phoneNumberField: 'userProfile.personalProfileInfo.phoneNumber',
  birthDateField: 'userProfile.personalProfileInfo.birthDate',
  userGenderField: 'userProfile.personalProfileInfo.gender',
  userIdField: 'userProfile.personalProfileInfo.idNumber',
  userMiddleNameField: 'userProfile.personalProfileInfo.middleName',
};

export enum PERSONAL_DETAILS_FORM_FIELDS {
  USER_FIRST_NAME_FIELD = 'userFirstNameField',
  USER_LAST_NAME_FIELD = 'userLastNameField',
  USER_ID_FIELD = 'userIdField',
  USER_MIDDLE_NAME_FIELD = 'userMiddleNameField',
  USER_EMAIL_FIELD = 'userEmailField',
  PHONE_NUMBER_FIELD = 'phoneNumberField',
  BIRTH_DATE_FIELD = 'birthDateField',
  USER_GENDER_FIELD = 'userGenderField',
}
