import * as TabsUI from '@radix-ui/react-tabs';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { shouldHover } from '~utils/cssUtils';

export const StyledTabsRoot = styled(TabsUI.Root, {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    fullHeight: {
      true: {
        height: '100%',
      },
    },
  },
});

export const StyledTabsList = styled(TabsUI.List, {
  display: 'flex',
  gap: '$6',
  variants: {
    isMobileTabs: {
      true: {
        gap: '$3',
        lineHeight: '20px',
        alignItems: 'center',
      },
    },
    withSlider: {
      true: {
        maxWidth: '100%',
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '-webkit-overflow-scrolling': 'touch',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
  },
});

export const StyledTabsTrigger = styled(TabsUI.Trigger, {
  all: 'unset',
  display: 'flex',
  color: '$textPrimaryOne',
  whiteSpace: 'nowrap',
  '&[data-state="inactive"]': {
    ...shouldHover,
  },
  '&[data-state="active"]': {
    color: '$accentPrimaryTwo',
    fontWeight: '$medium',
    '&:hover': { cursor: 'default' },
  },
  variants: {
    isMobileTabs: {
      true: {
        textTransform: 'capitalize',
        fontWeight: '$normal',
        fontSize: '$14',
      },
    },
    capitalize: {
      true: {
        textTransform: 'capitalize',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
  },
});

export const StyledTabsContent = styled(TabsUI.Content, {
  flexGrow: 1,
  outline: 'none',
  height: '100%',
});

export const ScrollableBox = styled(Box, {
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-webkit-overflow-scrolling': 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
