import React, { ReactNode } from 'react';

import { Box } from '~components/atoms/Box';
import { Logo } from '~components/atoms/Logo/Logo';
import { ScrollAreaMobile } from '~components/atoms/ScrollAreaMobile';
import useIsInIframe from '~hooks/useIsInIframe';
import { useMedia } from '~hooks/useMedia';

interface AuthTemplateProps {
  children: ReactNode;
}

export const AuthTemplate = ({ children }: AuthTemplateProps) => {
  const { isMobile } = useMedia();
  const isInIframe = useIsInIframe();

  return (
    <ScrollAreaMobile>
      <Box
        flexCol
        gap={4}
        alignCenter
        justifyCenter
        css={{
          p: '$7',
          height: '100%',
          '@xs': {
            p: '$4',
            overflow: 'scroll',
          },
        }}
      >
        {!isInIframe && <Logo size={isMobile ? 'md' : 'lg'} type="auth" />}
        {children}
      </Box>
    </ScrollAreaMobile>
  );
};
