import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Countries, CountriesBySportParams } from '~api/category/types';
import {
  SPORT_GROUP_COMPONENTS,
  SPORT_TAB_GROUPS,
} from '~components/molecules/PrematchMenu/constants';
import { QUERY_PARAMS } from '~constants/common';
import { useDebouncedEffect } from '~hooks';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import {
  selectIsLoading,
  selectSports,
  setLeaguesData,
} from '~store/slices/prematchMenuSlice';
import { getParamFromQuery, QueryParams } from '~utils/url';

export const useSportMenuMobile = (activeSportTabState: SPORT_TAB_GROUPS) => {
  const sports = useAppSelector(selectSports);
  const isLoading = useAppSelector(selectIsLoading);
  const { groups } = useAppSelector((state) => state.sportGroupsState);
  const { search } = useLocation();

  let defaultSportId = getParamFromQuery(search, QUERY_PARAMS.SPORT_ID);

  if (!defaultSportId && sports && sports[0]) {
    defaultSportId = sports[0].id.toString();
  }

  let defaultCountriesData = [] as Countries;

  if (defaultSportId && sports) {
    defaultCountriesData = sports.find(
      (sport) => sport.id === parseInt(defaultSportId as string),
    )?.countries as Countries;
  }

  const { breadcrumbLabel } = useAppSelector((state) => state.menus);
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { menu, group } = useQueryParams();

  const [countriesData, setCountriesData] = useState<Countries | undefined>(
    defaultCountriesData,
  );
  const [activeSportId, setActiveSportId] = useState<string | null>(
    defaultSportId,
  );

  const handleOnSportValueChange = (sportId: string) => {
    dispatch(setBreadcrumbsLabel(null));
    const params: CountriesBySportParams = {
      sportId: parseInt(sportId),
    };

    if (!group) {
      updateQueryParams(
        {
          ...params,
          [QUERY_PARAMS.MENU]: menu,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
        '/',
      );
    } else {
      updateQueryParams(
        {
          ...params,
          [QUERY_PARAMS.GROUP]: undefined,
          [QUERY_PARAMS.MENU]: menu,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
        '/',
      );
    }

    setActiveSportId(sportId);
  };

  useDebouncedEffect(
    () => {
      if (isLoading || !sports) return;
      const countriesExists = sports.find(
        (sport) => sport.id === parseInt(activeSportId as string),
      )?.countries;

      if (countriesExists?.length) return;

      const firstSportWithCountries = sports.find(
        (sport) => sport.countries?.length,
      );

      if (firstSportWithCountries) {
        const sportId = firstSportWithCountries.id.toString();

        handleOnSportValueChange(sportId);
      }
    },
    [activeSportId, sports, isLoading],
    300,
  );

  useEffect(() => {
    let sportId = getParamFromQuery(search, QUERY_PARAMS.SPORT_ID);

    if (!sportId && sports && sports[0]) {
      sportId = sports[0].id.toString();
    }

    if (sportId && sports?.length) {
      const countries =
        sports.find((sport) => sport.id === parseInt(sportId as string))
          ?.countries || [];

      countries.forEach(({ id: countryId, leagues }) => {
        if (leagues?.length) {
          dispatch(setLeaguesData({ id: countryId.toString(), data: leagues }));
        }
      });

      setCountriesData(countries);
      setActiveSportId(sportId);
    }
  }, [sports, search]);

  useEffect(() => {
    if (activeSportTabState === SPORT_TAB_GROUPS.ALL) {
      return;
    } else if (activeSportTabState === SPORT_TAB_GROUPS.TOP) {
      const isGroupAlreadySet = groups?.find(
        ({ name }) => name === breadcrumbLabel,
      );

      if (isGroupAlreadySet) return;

      const firstTopGroup = groups?.find(
        ({ name }) =>
          name === SPORT_GROUP_COMPONENTS.TOP_EVENT ||
          name === SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT,
      );

      if (firstTopGroup) {
        dispatch(setBreadcrumbsLabel(firstTopGroup.name));
      }
    }
  }, [activeSportTabState, groups]);

  return {
    sports,
    activeSportId,
    breadcrumbLabel,
    countriesData,
    handleOnSportValueChange,
    dispatch,
  };
};
