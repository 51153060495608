import { MainMarket } from '~api/market/types';
import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
  StyledAccordionRoot,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { Select } from '~components/atoms/Select';
import { SkeletonFull } from '~components/atoms/Skeletons';
import { SportMenuList } from '~components/atoms/SportMenuList';
import { ToggleSportMarket } from '~components/atoms/ToggleMarket/ToggleSportMarket';
import { EventsList } from '~components/molecules/MainHighlights/EventsList';
import { HeaderTopStyled } from '~components/molecules/MainHighlights/styled.components';
import { useTopEventsSocket } from '~socket-service/hooks/useTopEventsSocket/useTopEventsSocket';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';

import { useTopEventGroupSportMobile } from '../hooks/useTopEventGroupSportMobile';

export const TopEventGroupMobile = ({
  isLive = false,
}: {
  isLive?: boolean;
}) => {
  const { data: topSportEvents, isLoading } = useTopEventsSocket({ isLive });

  const {
    sports,
    topEventsData,
    sportId,
    showSports,
    selectedSportIndex,
    sportName,
    mainSportMarket,
    openDateGroups,
    setShowSports,
    handleSportMenuClick,
    handleDateGroupOpenValuesChange,
  } = useTopEventGroupSportMobile({ topSportEvents });

  if (isLoading) {
    return <SkeletonFull />;
  }

  const sportDateGroup = topEventsData[sportId ? sportId.toString() : ''];

  return (
    <Box flexCol>
      <Box
        flexRow
        css={{ px: '$1', paddingTop: '$2', backgroundColor: '$primaryOne' }}
      >
        <Select
          placeholder={sportName}
          shouldClose={!showSports}
          onClose={() => setShowSports(false)}
          isCustomChildren
          icon={getSportIcon(sportId as number, { width: 20, height: 20 })}
          css={{
            flexGrow: 1,
            pl: '$1',
            py: '4px',
            color: '$textPrimaryOne',
            fontSize: '$14',
            lineHeight: '$24',
            fontWeight: '$medium',
          }}
          contentCss={{ left: '-6px' }}
          iconSize={16}
          isCustomContentWidth
          onOpenChange={setShowSports}
          notPreventClickBackdrop
        >
          <Box flexCol css={{ width: 'calc(100vw - 32px)' }}>
            <SportMenuList
              sports={sports}
              activeSportIdIndex={selectedSportIndex}
              onClick={handleSportMenuClick}
            />
          </Box>
        </Select>
      </Box>
      <Box
        flexCol
        css={{
          position: 'relative',
          backgroundColor: '$primaryOne',
          zIndex: 1,
          px: '$1',
        }}
      >
        {sportId && (
          <Box flexRow css={{ backgroundColor: '$primaryOne' }}>
            <ToggleSportMarket sportId={sportId} />
          </Box>
        )}
        <StyledAccordionRoot
          type="multiple"
          value={openDateGroups}
          onValueChange={handleDateGroupOpenValuesChange}
        >
          {sportDateGroup &&
            sportDateGroup?.map(({ matches, date }) => {
              return (
                <StyledAccordionItem
                  key={date}
                  value={date}
                  css={{
                    mb: '$1',
                  }}
                >
                  <AccordionTrigger>
                    <HeaderTopStyled
                      css={{
                        pr: '$2',
                        mr: '-$4',
                        borderBottom: 0,
                      }}
                    >
                      <Box>{formatDateTime(date, TIME_FORMATS.DATE)}</Box>
                    </HeaderTopStyled>
                  </AccordionTrigger>
                  <AccordionContent
                    css={{
                      p: 0,
                      backgroundColor: 'transparent',
                      '& > .contentWrapper': {
                        p: 0,
                      },
                    }}
                  >
                    <Box flexCol gap={1}>
                      <EventsList
                        isLive={isLive}
                        activeEvents={matches}
                        mainMarkets={[mainSportMarket as MainMarket]}
                        withTopPadding
                      />
                    </Box>
                  </AccordionContent>
                </StyledAccordionItem>
              );
            })}
        </StyledAccordionRoot>
        {showSports && (
          <Box
            fullWidth
            fullHeight
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              background: '$primaryTwoOpacity',
              backdropFilter: 'blur(4px)',
              zIndex: 2,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
