import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useChangeUserPassword } from '~api/user/userMutations';
import { useMedia } from '~hooks/useMedia';
import { usePasswordCharacteristics } from '~hooks/usePasswordCharacteristics';
import { useTranslation } from '~hooks/useTranslation';
import { BACKEND_ERROR_CODES } from '~utils/backendErrors';
import { removeEmptySpaces } from '~utils/sanitizers';

import {
  ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS,
  AccountSettingsPasswordFormInputs,
} from './constants';

interface UseChangePasswordFormProps {
  onTogglePasswordChanged: (val: boolean) => void;
}
export const useChangePasswordForm = ({
  onTogglePasswordChanged,
}: UseChangePasswordFormProps) => {
  const { localizedError } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const eyeIconSizes = isMobileOrTablet
    ? { width: 20, height: 20 }
    : { width: 18, height: 18 };

  const formMethods = useForm<AccountSettingsPasswordFormInputs>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
  });
  const {
    watch,
    register,
    formState,
    setValue,
    clearErrors,
    trigger,
    setError,
    setFocus,
  } = formMethods;
  const { errors } = formState;
  const data = watch();

  const { newPassword, currentPassword } = data;
  const isChangePasswordDisabled = !newPassword || !currentPassword;
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const { onBlur: onPasswordBlur } = register(
    ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD,
  );
  const [isPasswordPopoverOpen, setIsPasswordPopoverOpen] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const { isValidPassword, passwordStrength, localizedCharacteristicsItems } =
    usePasswordCharacteristics(newPassword);

  const {
    changeUserPasswordMutation,
    changeUserPasswordErrorMessage,
    changeUserPasswordIsLoading,
  } = useChangeUserPassword();

  const handleNewPasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = removeEmptySpaces(event.target.value);

    clearErrors(ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD);
    setValue(
      ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD,
      sanitizedValue,
    );
  };

  const onSubmit = async () => {
    if (!newPassword || !currentPassword) {
      return;
    }

    const output = await trigger();

    if (errors.newPassword) {
      setFocus(ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD);
    }

    if (output) {
      try {
        await changeUserPasswordMutation({
          currentPassword,
          newPassword,
        }).unwrap();
        setIsPasswordChanged(true);
        setValue(
          ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.CURRENT_PASSWORD_FIELD,
          '',
        );
        setValue(ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.NEW_PASSWORD_FIELD, '');
        setTimeout(() => {
          setIsPasswordChanged(false);
        }, 3000);
      } catch (error) {
        console.error('Unable to change password', error);
      }
    }
  };

  useEffect(() => {
    if (
      changeUserPasswordErrorMessage === BACKEND_ERROR_CODES.WRONG_CREDENTIALS
    ) {
      setError(ACCOUNT_SETTINGS_PASSWORD_FORM_FIELDS.CURRENT_PASSWORD_FIELD, {
        type: BACKEND_ERROR_CODES.WRONG_CREDENTIALS,
        message: localizedError('password.invalid'),
      });
    }
  }, [changeUserPasswordErrorMessage]);

  useEffect(() => {
    onTogglePasswordChanged(isPasswordChanged);
  }, [isPasswordChanged]);

  return {
    isPasswordChanged,
    isPasswordPopoverOpen,
    setIsPasswordPopoverOpen,
    showNewPassword,
    setShowNewPassword,
    showCurrentPassword,
    setShowCurrentPassword,
    passwordStrength,
    isValidPassword,
    handleNewPasswordOnChange,
    onPasswordBlur,
    errors,
    register,
    newPassword,
    onSubmit,
    changeUserPasswordIsLoading,
    localizedCharacteristicsItems,
    isMobileOrTablet,
    eyeIconSizes,
    isChangePasswordDisabled,
  };
};
