import React, { memo } from 'react';

import { FooterComponent } from '~components/organisms/FooterComponent/FooterComponent';
import { HeaderComponent } from '~components/organisms/HeaderComponent';
import { RouteConfig } from '~routes';

export const DesktopPageTemplate = memo(
  ({ component, header, footer, path }: RouteConfig) => {
    return (
      <>
        {header !== undefined ? header : <HeaderComponent path={path} />}
        {component}
        {footer !== undefined ? footer : <FooterComponent path={path} />}
      </>
    );
  },
);
