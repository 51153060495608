import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';

export const StyledErrorPageContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: '$2',
  maxHeight: '320px',
  backgroundColor: '$primaryOne',
  paddingTop: '$6',
  borderTop: '4px solid $primaryTwo',
  '@xs': {
    margin: '32px 0',
  },
});

export const StyledErrorTitle = styled(Text, {
  textTransform: 'uppercase',
  fontSize: '24px',
  lineHeight: '40px',

  '@lg_xl': {
    fontSize: '28px',
    lineHeight: '44px',
  },

  '@xs': {
    fontSize: '18px',
    lineHeight: '32px',
  },
});

export const StyledErrorText = styled(Text, {
  textTransform: 'uppercase',
  fontSize: '14px',
  lineHeight: '24px',

  '@lg_xl': {
    fontSize: '16px',
    lineHeight: '28px',
  },

  '@xs': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

export const StyledButtonText = styled(Text, {
  fontSize: '16px',
  lineHeight: '28px',
  fontWeight: '$medium',

  '@lg_xl': {
    fontSize: '18px',
    lineHeight: '24px',
  },

  '@xs': {
    fontSize: '16px',
    lineHeight: '20px',
  },
});

export const StyledIconWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '48px',
  color: '$textPrimaryOne',

  '@xs': {
    width: '28px',
    height: '28px',
  },
});

export const StyledActionButton = styled(Button, {
  width: '260px',
  height: '36px',

  '@lg_xl': {
    height: '40px',
  },
});
