import {
  useDeleteBankMutation,
  useDeleteCardMutation,
  useMakeWithdrawalMutation,
  useSetDefaultCardFlutterwaveMutation,
  useSetDefaultCardPaystackMutation,
  useSetDefaultWithdrawalBankMutation,
} from '~api/transaction/transactionApi';
import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

export const useSetDefaultWithdrawalBank = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSetDefaultWithdrawalBankMutation();

  return {
    setDefaultWithdrawalBankMutation: mutation,
    setDefaultWithdrawalBankData: data,
    setDefaultWithdrawalBankError: error,
    setDefaultWithdrawalBankIsError: isError,
    setDefaultWithdrawalBankIsSuccess: isSuccess,
    setDefaultWithdrawalBankErrorCode: getErrorCode(error, isError),
    setDefaultWithdrawalBankErrorMessage: getErrorMessage(error),
    setDefaultWithdrawalBankIsLoading: isLoading,
  };
};

export const useSetDefaultCardFlutterwave = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSetDefaultCardFlutterwaveMutation();

  return {
    setDefaultCardFlutterwaveMutation: mutation,
    setDefaultCardFlutterwaveData: data,
    setDefaultCardFlutterwaveError: error,
    setDefaultCardFlutterwaveIsError: isError,
    setDefaultCardFlutterwaveIsSuccess: isSuccess,
    setDefaultCardFlutterwaveErrorCode: getErrorCode(error, isError),
    setDefaultCardFlutterwaveErrorMessage: getErrorMessage(error),
    setDefaultCardFlutterwaveIsLoading: isLoading,
  };
};

export const useSetDefaultCardPaystack = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useSetDefaultCardPaystackMutation();

  return {
    setDefaultCardPaystackMutation: mutation,
    setDefaultCardPaystackData: data,
    setDefaultCardPaystackError: error,
    setDefaultCardPaystackIsError: isError,
    setDefaultCardPaystackIsSuccess: isSuccess,
    setDefaultCardPaystackErrorCode: getErrorCode(error, isError),
    setDefaultCardPaystackErrorMessage: getErrorMessage(error),
    setDefaultCardPaystackIsLoading: isLoading,
  };
};

export const useDeleteCard = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useDeleteCardMutation();

  return {
    deleteCardMutation: mutation,
    deleteCardData: data,
    deleteCardError: error,
    deleteCardIsError: isError,
    deleteCardIsSuccess: isSuccess,
    deleteCardErrorCode: getErrorCode(error, isError),
    deleteCardErrorMessage: getErrorMessage(error),
    deleteCardIsLoading: isLoading,
  };
};

export const useDeleteBank = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useDeleteBankMutation();

  return {
    deleteBankMutation: mutation,
    deleteBankData: data,
    deleteBankError: error,
    deleteBankIsError: isError,
    deleteBankIsSuccess: isSuccess,
    deleteBankErrorCode: getErrorCode(error, isError),
    deleteBankErrorMessage: getErrorMessage(error),
    deleteBankIsLoading: isLoading,
  };
};

export const useMakeWithdrawal = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useMakeWithdrawalMutation();

  return {
    makeWithdrawalMutation: mutation,
    makeWithdrawalData: data,
    makeWithdrawalError: error,
    makeWithdrawalIsError: isError,
    makeWithdrawalIsSuccess: isSuccess,
    makeWithdrawalErrorCode: getErrorCode(error, isError),
    makeWithdrawalErrorMessage: getErrorMessage(error),
    makeWithdrawalIsLoading: isLoading,
  };
};
