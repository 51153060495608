import React from 'react';
import { COLOR_KEYS } from 'stitches.config';

import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';

export const TextCell = ({
  value,
  color,
}: {
  value: string;
  color?: COLOR_KEYS;
}) => <StyledTableText color={color}>{value}</StyledTableText>;
