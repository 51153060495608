import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

export const StyledBetslipInfoWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StyledBetslipText = styled(Text, {
  fontSize: '$13',
  lineHeight: '$16',
  '@xs_sm': {
    fontSize: '$11',
    lineHeight: '$16',
  },
});
