import { useAppSelector } from '~store';
import { getCurrencySymbol } from '~store/slices/selectors/settings';
import {
  commaSeparatedNumber,
  formatNumberToStringWithSpaces,
} from '~utils/numberUtils';

export const useFormatAmountWithCurrency = () => {
  const currencySymbol = useAppSelector(getCurrencySymbol);

  const getCommaSeparatedAmount = (
    number?: number | null,
    noCurrencySymbol?: boolean,
  ): string =>
    `${commaSeparatedNumber(number ?? 0)} ${noCurrencySymbol ? '' : currencySymbol}`;

  const getSpacesSeparatedAmount = (
    number?: number | null,
    noCurrencySymbol?: boolean,
  ): string =>
    `${formatNumberToStringWithSpaces(number ?? 0)} ${noCurrencySymbol ? '' : currencySymbol}`;

  return {
    getCommaSeparatedAmount,
    getSpacesSeparatedAmount,
  };
};
