/* eslint-disable @typescript-eslint/no-explicit-any */
export const deepClone = (obj: any): any => {
  // Handle the 3 simple types, and null or undefined
  if (obj === null || typeof obj !== 'object') return obj;

  // Handle Array
  if (obj instanceof Array) {
    const copy = [];

    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepClone(obj[i]);
    }

    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    const copy: { [key: string]: any } = {};

    for (const attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) copy[attr] = deepClone(obj[attr]);
    }

    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};
