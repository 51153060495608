import { SPORT_GROUP_COMPONENTS } from '~components/molecules/PrematchMenu/constants';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import { setMobileBackClicked } from '~store/slices/prematchMenuSlice';
import { BreadcrumbItem } from '~types/breadcrumb';
import { StoreSportEvent } from '~types/events';

import { useQueryParams } from './useQueryParams';

export const useInitializeBreadcrumbForSport = (
  sportId?: number,
  countryName?: string,
  eventData?: StoreSportEvent,
) => {
  const dispatch = useAppDispatch();
  const { breadcrumbLabel } = useAppSelector((state) => state.menus);
  const { navigateBackOrHome } = useRouterQuery();
  const { localized } = useTranslation();
  const mainMarkets = useAppSelector((state) => state.mainMarkets.mainMarkets);
  const search = useQueryParams();
  const { sports } = useAppSelector((state) => state.prematchMenu);

  const prematchLabelsMap: Record<SPORT_GROUP_COMPONENTS, string> = {
    [SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT]: localized(
      'prematch.favoriteTournaments',
    ),
    [SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT]: localized(
      'prematch.topTournaments',
    ),
    [SPORT_GROUP_COMPONENTS.FAVORITE_EVENT]: localized(
      'prematch.favoriteEvents',
    ),
    [SPORT_GROUP_COMPONENTS.TOP_EVENT]: localized('prematch.topEvents'),
  };

  const initializeBreadcrumb = (
    sportIdProp?: string | undefined,
    countryNameProp?: string | undefined,
  ) => {
    const sportIdValue = sportId || sportIdProp;
    const countryNameValue = countryName || countryNameProp;

    if (!sportIdValue || !countryNameValue) return;
    const searchCopy = { ...search };

    delete searchCopy.eventId;

    const sport = mainMarkets.find((s) => s.id === sportIdValue);
    const { name: sportName } = sport || {};

    if (sportName) {
      const trail: BreadcrumbItem[] = [
        {
          label: localized(
            breadcrumbLabel
              ? prematchLabelsMap[breadcrumbLabel as SPORT_GROUP_COMPONENTS]
              : 'mobile.breadcrumbs.sports',
          ),
          onClickCallback: () => {
            dispatch(setMobileBackClicked(!!sports?.length));
            navigateBackOrHome();
          },
        },
        {
          label: `${sportName} - ${countryNameValue}`,
          onClickCallback: () => {
            dispatch(setMobileBackClicked(!!sports?.length));
            navigateBackOrHome();
          },
        },
      ];

      if (eventData && !breadcrumbLabel) {
        const { name } = eventData || {};

        trail.push({
          label: name || '',
        });
      }

      dispatch(setBreadcrumbTrail(trail));
    }
  };

  return {
    initializeBreadcrumb,
  };
};
