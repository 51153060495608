import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { zIndex } from '~utils/zIndex';

export const BreadcrumbEventWrapper = memo(
  ({
    mobileHeaderHeight,
    handleBackPress,
  }: {
    mobileHeaderHeight: number;
    handleBackPress?: () => void;
  }) => (
    <Box
      flexCol
      css={{
        position: 'fixed',
        top: mobileHeaderHeight,
        zIndex: zIndex.fixed,
        width: '100vw',
      }}
    >
      <Breadcrumb onBackPress={handleBackPress} />
    </Box>
  ),
);
