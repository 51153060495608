import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { BETSLIP_TOGGLE_TYPES } from '~components/molecules/Betslip/constants';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { INVITE_CODE_KEY, QUERY_PARAMS, REF_CODE_KEY } from '~constants/common';
import { useHash } from '~hooks/useHash';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch } from '~store';
import { setShareCode, toggleBetslipType } from '~store/slices/betslipSlice';
import { openDialog } from '~store/slices/globalDialogSlice';

export const useShare = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobileOrTablet } = useMedia();
  const { share, ref, invite } = useQueryParams();
  const hash = useHash();
  const { removeQueryParams } = useRouterQuery();

  const isRegisterHash = hash === '#register';

  useEffect(() => {
    const handleRefOrInvite = () => {
      if (ref) {
        localStorage.setItem(REF_CODE_KEY, ref);
        removeQueryParams([QUERY_PARAMS.REF]);
      }

      if (invite) {
        localStorage.setItem(INVITE_CODE_KEY, invite);
        removeQueryParams([QUERY_PARAMS.INVITE]);
      }

      if (isRegisterHash && (ref || invite)) {
        dispatch(openDialog(DIALOGS.SIGN_UP));
      }
    };

    const handleShare = () => {
      if (share) {
        dispatch(setShareCode(share));
        dispatch(toggleBetslipType(BETSLIP_TOGGLE_TYPES.BETSLIP));
        removeQueryParams([QUERY_PARAMS.SHARE]);

        if (isMobileOrTablet) {
          navigate(`/${MOBILE_MENU.BETSLIP}/`);
        }
      }
    };

    handleRefOrInvite();
    handleShare();
  }, [share, ref, invite, hash, isMobileOrTablet]);
};
