import React, { useLayoutEffect } from 'react';

import { BONUS_STATUS } from '~api/bonus/types';
import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';
import { Text } from '~components/atoms/Typography';
import { useBonuses } from '~components/molecules/Bonuses/useBonuses';
import { Rules } from '~components/molecules/UserProfile/components/BonusRules/Rules';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';

export const BonusRules = () => {
  const { isMobileOrTablet } = useMedia();
  const { loadBonuses, bonuses, isLoading } = useBonuses();
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const newTrail = [
      {
        label: localized('buttons.back'),
        onClickCallback: () => {
          dispatch(closeDialog());
        },
        forceCallback: true,
      },
      {
        label: localized('bonusRules.title'),
      },
    ];

    loadBonuses({ queryParams: { Status: BONUS_STATUS.ACTIVE } });

    dispatch(setBreadcrumbTrail(newTrail));
  }, []);

  const content = (
    <Box
      flexCol
      css={{
        p: '$1',
        borderRadius: '$10',
        backgroundColor: '$primaryOne',
        '@xs_sm': {
          backgroundColor: 'transparent',
        },
        '@md_lg_xl': {
          width: '1052px',
        },
      }}
    >
      {isMobileOrTablet && <Breadcrumb />}
      {!isMobileOrTablet && (
        <Box flexRow gap={1} css={{ p: '$2 0 $3 $4' }}>
          <Text
            color="textSecondaryOne"
            level="sm-3"
            fontWeight="medium"
            textTransform="uppercase"
          >
            {localized(`bonusRules.title`)}:
          </Text>
          <Text color="textSecondaryOne" level="sm-3">
            {localized('bonusRules.description')}
          </Text>
        </Box>
      )}

      <Box
        flexRow
        gap={1}
        css={{
          backgroundColor: 'transparent',
          borderRadius: '$10',
          '@md_lg_xl': {
            height: '576px',
            backgroundColor: '$primaryTwo',
          },
        }}
      >
        <Rules bonuses={bonuses} isLoading={isLoading} />
      </Box>
    </Box>
  );

  if (!isMobileOrTablet) {
    return content;
  }

  return <DialogMobileContent>{content}</DialogMobileContent>;
};
