import { BetslipHistoryPayload } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { FiltersButtons } from '~components/atoms/FiltersButtons';
import { Input } from '~components/atoms/Input';
import { Select } from '~components/atoms/Select';

import { useBetslipHistoryFilters } from './useBetslipHistoryFilters';

interface BetslipHistoryFiltersProps {
  loadBetslipHistory: (
    params: BetslipHistoryPayload | undefined,
    loadMore?: boolean | undefined,
  ) => void;
}

export const BetslipHistoryFilters = ({
  loadBetslipHistory,
}: BetslipHistoryFiltersProps) => {
  const {
    code,
    isValidationFailed,
    selectedDates,
    statusesOptions,
    statusValue,
    handleReset,
    handleSetCode,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  } = useBetslipHistoryFilters({ loadBetslipHistory });

  return (
    <>
      <Box flexRow gap={2}>
        <Box flexRow gap={3}>
          <DateRangePicker
            onOkRange={(dates) => setSelectedDates(dates)}
            value={selectedDates}
          />
        </Box>
        <Select
          ariaLabel={localized('betslipHistory.status.all')}
          placeholder={localized('betslipHistory.status.all')}
          value={statusValue.toString()}
          onChange={(value) => setStatusValue(value as string)}
          options={statusesOptions}
          css={{
            width: '130px',
            height: '32px',
            borderRadius: '$8',
            backgroundColor: '$secondaryTwo',
          }}
          contentCss={{
            backgroundColor: '$secondaryTwo',
          }}
        />
        <Input
          placeholder={localized('betslipHistory.inputPlaceholder')}
          value={code}
          onChange={handleSetCode}
          css={{
            width: '132px',
            height: '32px',
            borderRadius: '$8',
            backgroundColor: '$secondaryTwo',
            padding: '$1 $3',
          }}
        />
        <FiltersButtons
          onReset={handleReset}
          onApply={handleUpdateHistory}
          isDisabled={isValidationFailed}
        />
      </Box>
    </>
  );
};
