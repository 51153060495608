import React from 'react';

import { Box } from '~components/atoms/Box';
import { StoreSportEvent } from '~types/events';
import { SoccerExtraData } from '~types/sportExtraData/Soccer';

import { SoccerScoreboard } from '../scoreboards';

interface SoccerEventOverviewProps {
  restEventData: Omit<StoreSportEvent, 'extraData'>;
  parsedExtraData: SoccerExtraData | null;
}

export const SoccerEventOverview = ({
  restEventData,
  parsedExtraData,
}: SoccerEventOverviewProps) => {
  return (
    <>
      <SoccerScoreboard
        restEventData={restEventData}
        parsedExtraData={parsedExtraData}
      />
      <Box></Box>
    </>
  );
};
