import { useState } from 'react';

import { Button } from '~components/atoms/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { ListItem, UnorderedList } from '~components/atoms/UnorderedList';
import { useTranslation } from '~hooks/useTranslation';
import { InfoIcon } from '~icons';

interface BetslipMultipleBonusProps {
  multipleBetBonusDescriptions: string[];
}

export const BetslipMultipleBonus = ({
  multipleBetBonusDescriptions,
}: BetslipMultipleBonusProps) => {
  const { localized } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const localizedOptions = multipleBetBonusDescriptions.map((item) =>
    localized(item),
  );

  return (
    <Popover isOpen={isActive} handleOpenChange={() => {}}>
      <PopoverContent
        side="bottom"
        sideOffset={16}
        align="center"
        tone="secondary"
      >
        <UnorderedList>
          <Text level="sm-3" textTransform="uppercase">
            {localized('betslip.multipleBonusOptions.title')}
          </Text>
          {localizedOptions.map((option, index) => (
            <ListItem key={index}>{option}</ListItem>
          ))}
        </UnorderedList>
      </PopoverContent>
      <PopoverTrigger asChild>
        <Button
          variant="transparent"
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
          css={{
            outline: 'none',
            color: '$textSecondaryOne',
            p: '$0',
            fontSize: '$0',
            lineHeight: '0',
            '&:hover': { color: '$pressedOne' },
          }}
        >
          <InfoIcon />
        </Button>
      </PopoverTrigger>
    </Popover>
  );
};
