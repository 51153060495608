import {
  useLazyGetCoinsListQuery,
  useLazyGetMinAmountQuery,
} from './cryptoPaymentsApi';

export const useLazyGetCoinsList = () => {
  const [query, { data, error, isLoading }] = useLazyGetCoinsListQuery();

  return {
    lazyGetCoinsListQuery: query,
    lazyGetCoinsListData: data,
    lazyGetCoinsListError: error,
    lazyGetCoinsListIsLoading: isLoading,
  };
};

export const useLazyGetMinAmount = () => {
  const [query, { data, error, isLoading }] = useLazyGetMinAmountQuery();

  return {
    lazyGetMinAmountQuery: query,
    lazyGetMinAmountData: data,
    lazyGetMinAmountError: error,
    lazyGetMinAmountIsLoading: isLoading,
  };
};
