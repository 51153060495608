import React, { lazy, ReactNode, useEffect } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { DesktopPageTemplate } from '~components/atoms/layouts/DesktopPageTemplate';
import { MobilePageTemplate } from '~components/atoms/layouts/MobilePageTemplate';
import { FooterCloseModal } from '~components/organisms/FooterComponent/FooterCloseModal';
import { HeaderModalComponent } from '~components/organisms/HeaderModalComponent';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useAppSelector } from '~store';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';

const NotFoundPage = lazy(() => import('~components/atoms/NotFoundPage'));
const MobileTabContents = lazy(
  () => import('~components/organisms/MobileTabContents/MobileTabContents'),
);
const VerifyMessengerPage = lazy(
  () => import('~components/VerifyMessengerPage'),
);
const SportsPage = lazy(() => import('~pages/SportsPage'));
const InfoPage = lazy(() => import('~pages/Info/InfoPage'));
const JackpotPage = lazy(() => import('~pages/JackpotPage'));
const BetHistoryMobilePage = lazy(() => import('~pages/BetHistoryMobilePage'));
const BetslipMobilePage = lazy(
  () => import('~components/molecules/Betslip/BetslipMobilePage'),
);
const ProfileMobilePage = lazy(() => import('~pages/ProfileMobilePage'));
const TransactionsMobilePage = lazy(
  () => import('~pages/TransactionsMobilePage'),
);
const SettingsMobilePage = lazy(() => import('~pages/SettingsMobilePage'));
const DetailsMobilePage = lazy(() => import('~pages/DetailsMobilePage'));
const PaymentsMobilePage = lazy(() => import('~pages/PaymentsMobilePage'));
const InviteFriendsMobilePage = lazy(
  () => import('~pages/InviteFriendsMobilePage'),
);

const CasinoPage = lazy(() => import('~pages/CasinoPage'));
const GamePage = lazy(() => import('~pages/GamePage'));
const BonusesMobilePage = lazy(() => import('~pages/BonusesMobilePage'));

export const Fallback = () => {
  useEffect(() => {
    return () => {
      // Removing loading div after all chunks are loaded
      document.querySelector('#loading')?.remove();
      document.body.removeAttribute('style');
    };
  }, []);

  return null;
};

export interface RouteConfig {
  path: ROUTE_TAB_NAMES; // provide the path to render the component below
  component: ReactNode; // provide the component to render
  header?: ReactNode | null; // provide custom header
  footer?: ReactNode | null; // provide custom footer
  isMobileOrTablet?: boolean; // this route works on mobile or tablet devices only
  shouldBeAuthorized?: boolean; // only authorized user is able to visit the page, otherwise he will be redirected to the home page
}

export type RoutesConfig = RouteConfig[];
type RouteTabNames = (typeof ROUTE_TAB_NAMES)[keyof typeof ROUTE_TAB_NAMES];

export const routesConfig: RoutesConfig = [
  {
    path: ROUTE_TAB_NAMES.BASE,
    component: <MobileTabContents />,
    isMobileOrTablet: true,
  },
  {
    path: ROUTE_TAB_NAMES.BETSLIP,
    component: <BetslipMobilePage />,
    isMobileOrTablet: true,
    footer: null,
    header: null,
  },
  {
    path: `${ROUTE_TAB_NAMES.PAYMENTS}/:paymentType?` as RouteTabNames,
    component: <PaymentsMobilePage />,
    isMobileOrTablet: true,
    shouldBeAuthorized: true,
    footer: <FooterCloseModal />,
    header: (
      <HeaderModalComponent title="userProfile.menuTitles.balanceManagement" />
    ),
  },
  {
    path: `${ROUTE_TAB_NAMES.PROFILE_DETAILS}/:profileType?` as RouteTabNames,
    component: <DetailsMobilePage />,
    isMobileOrTablet: true,
    shouldBeAuthorized: true,
    footer: <FooterCloseModal />,
    header: (
      <HeaderModalComponent title="userProfile.menuTitles.personalProfile" />
    ),
  },
  {
    path: ROUTE_TAB_NAMES.INVITE_FRIENDS,
    component: <InviteFriendsMobilePage />,
    shouldBeAuthorized: true,
    isMobileOrTablet: true,
    footer: <FooterCloseModal />,
    header: (
      <HeaderModalComponent title="userProfile.menuTitles.inviteFriends" />
    ),
  },
  {
    path: ROUTE_TAB_NAMES.PROFILE,
    component: <ProfileMobilePage />,
    isMobileOrTablet: true,
    shouldBeAuthorized: true,
    footer: <FooterCloseModal />,
    header: <HeaderModalComponent title="userProfile.menuTitles.profile" />,
  },
  {
    path: `${ROUTE_TAB_NAMES.HISTORY}/:historyType?` as RouteTabNames,
    component: <BetHistoryMobilePage />,
    isMobileOrTablet: true,
    shouldBeAuthorized: true,
    footer: <FooterCloseModal />,
    header: <HeaderModalComponent title="userProfile.menuTitles.history" />,
  },
  {
    path: ROUTE_TAB_NAMES.SETTINGS,
    component: <SettingsMobilePage />,
    isMobileOrTablet: true,
    footer: <FooterCloseModal />,
    header: <HeaderModalComponent title="userProfile.menuTitles.settings" />,
  },
  {
    path: `${ROUTE_TAB_NAMES.BONUSES}/:bonusesType?` as RouteTabNames,
    component: <BonusesMobilePage />,
    isMobileOrTablet: true,
    shouldBeAuthorized: true,
    footer: <FooterCloseModal />,
    header: <HeaderModalComponent title="userProfile.menuTitles.bonuses" />,
  },
  {
    path: ROUTE_TAB_NAMES.TRANSACTIONS,
    component: <TransactionsMobilePage />,
    isMobileOrTablet: true,
    shouldBeAuthorized: true,
    footer: <FooterCloseModal />,
    header: (
      <HeaderModalComponent title="userProfile.menuTitles.transactions" />
    ),
  },
  {
    path: ROUTE_TAB_NAMES.MULTI,
    component: <SportsPage isMultiView />,
    isMobileOrTablet: false,
    footer: null,
  },
  {
    path: ROUTE_TAB_NAMES.BASE,
    component: <SportsPage />,
    isMobileOrTablet: false,
    footer: null,
  },
  {
    path: ROUTE_TAB_NAMES.INFO,
    component: <InfoPage />,
  },
  {
    path: ROUTE_TAB_NAMES.JACKPOT,
    component: <JackpotPage />,
  },
  {
    path: ROUTE_TAB_NAMES.NOT_FOUND,
    component: <NotFoundPage />,
  },
  {
    path: ROUTE_TAB_NAMES.VERIFY_MESSANGER,
    component: <VerifyMessengerPage />,
  },
  {
    path: ROUTE_TAB_NAMES.GAMES,
    component: <CasinoPage />,
  },
  {
    path: ROUTE_TAB_NAMES.VIRTUAL,
    component: <CasinoPage isVirtual />,
  },
  {
    path: ROUTE_TAB_NAMES.LIVE,
    component: <CasinoPage isLive />,
  },
  {
    path: ROUTE_TAB_NAMES.GAME,
    component: <GamePage />,
    footer: null,
  },
];

type RenderRoutesTypes = {
  isMobileOrTablet: boolean;
};

export const RenderRoutes = ({ isMobileOrTablet }: RenderRoutesTypes) => {
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);

  return routesConfig
    .filter(
      ({ isMobileOrTablet: isMobile }) =>
        isMobile === undefined || isMobile === isMobileOrTablet,
    )
    .map(({ component: configComponent, shouldBeAuthorized, ...routeItem }) => {
      const component =
        !isUserLoggedIn && shouldBeAuthorized ? (
          <Navigate to={ROUTE_TAB_NAMES.BASE} replace />
        ) : (
          configComponent
        );

      return (
        <Route
          key={routeItem.path}
          path={routeItem.path}
          element={
            isMobileOrTablet ? (
              <MobilePageTemplate {...routeItem} component={component} />
            ) : (
              <DesktopPageTemplate {...routeItem} component={component} />
            )
          }
        />
      );
    });
};

export default RenderRoutes;
