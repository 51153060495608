import React from 'react';

import { MainMarket } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { getSelectionWidth } from '~components/molecules/MainHighlights/helpers';
import {
  MARKETS_GAP,
  SELECTIONS_GAP,
  TOTAL_MARKET_NAME,
} from '~constants/mainPage';
import { useMedia } from '~hooks/useMedia';

interface MainMarketSelectionNamesListProps {
  mainMarkets: MainMarket[];
}

export const MainMarketSelectionNamesList = ({
  mainMarkets = [],
}: MainMarketSelectionNamesListProps) => {
  const { isLaptop } = useMedia();

  return (
    <Box flexRow alignCenter css={{ gap: `${MARKETS_GAP}px` }}>
      {mainMarkets.map(({ name, id, selections }) => {
        return (
          <Box
            key={id}
            flexRow
            alignCenter
            justifyCenter
            css={{ gap: `${SELECTIONS_GAP}px` }}
          >
            {name === TOTAL_MARKET_NAME && (
              <Box
                key={name}
                css={{ width: `${getSelectionWidth(isLaptop)}px` }}
              />
            )}
            {selections.map((selection) => {
              return (
                <Box
                  key={selection.id}
                  flexRow
                  alignCenter
                  justifyCenter
                  css={{ width: `${getSelectionWidth(isLaptop)}px` }}
                >
                  <Text level="16-20">{selection.name}</Text>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
