import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '~components/atoms/Box';
import { ShareLink } from '~components/atoms/ShareLink';
import { useTranslation } from '~hooks';
import {
  StyledInviteFriendsButton,
  StyledSupportText,
  StyledTitleText,
  StyledTopSectionWrapper,
} from '~pages/Info/styled.components';
import { selectUserProfileId } from '~store/slices/userSlice';
import { getInviteUserData } from '~utils/user';

interface InviteFriendsSectionProps {
  embedded: boolean;
}

export const InviteFriendsSection = ({
  embedded = false,
}: InviteFriendsSectionProps) => {
  const { localized } = useTranslation();
  const userId = useSelector(selectUserProfileId);

  return (
    <StyledTopSectionWrapper embedded={embedded}>
      <Box flexCol alignCenter gap={2} css={{ maxWidth: '568px' }}>
        <StyledTitleText>
          {localized('inviteFriendsPage.title')}
        </StyledTitleText>
        <StyledSupportText
          textTransform="uppercase"
          css={{ '@xs': { maxWidth: '240px' } }}
        >
          {localized('inviteFriendsPage.description')}
        </StyledSupportText>
      </Box>

      <ShareLink
        data={getInviteUserData(userId)}
        title={localized('inviteFriendsPage.inviteUsing')}
        css={{
          width: '100%',
          maxWidth: '362px',
          '@xs': {
            maxWidth: 'none',
          },
        }}
        contentWidth="400px"
      >
        <StyledInviteFriendsButton>
          {localized('inviteFriendsPage.inviteFriends')}
        </StyledInviteFriendsButton>
      </ShareLink>
    </StyledTopSectionWrapper>
  );
};
