import { useEffect, useRef } from 'react';

import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useAppSelector } from '~store';
import { selectLanguage } from '~store/slices/websiteSettings';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

export const useSetupPlainSockets = () => {
  const timer2Ref = useRef<NodeJS.Timeout>();
  const { dateToFilter } = useAppSelector((state) => state.prematchMenu);
  const { isPrimaryDataLoaded, isWsConnected, sendWsMessage } =
    useEventsLoadingSocket();

  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    if (isWsConnected) {
      sendWsMessage(ACTION_TYPE.GET_COUNTS);
      if (timer2Ref.current) clearTimeout(timer2Ref.current);
      timer2Ref.current = setTimeout(() => {
        sendWsMessage(ACTION_TYPE.GET_MAIN_MARKETS, {
          Language: language,
        });
      }, 300);
    }

    return () => {
      if (timer2Ref.current) clearTimeout(timer2Ref.current);
    };
  }, [isWsConnected, language]);

  useEffect(() => {
    if (isPrimaryDataLoaded) {
      sendWsMessage(ACTION_TYPE.GET_TOP_TOURNAMENTS, {
        Language: language,
        DateTo: dateToFilter,
      });
    }
  }, [isPrimaryDataLoaded, language, dateToFilter]);
};
