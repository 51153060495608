export const coinNetworkMap = {
  USDTERC20: 'ERC20',
  XMR: 'Monero',
  DGB: 'DigiByte',
  DOGE: 'DOGE',
  BTC: 'BTC',
  USDTTRC20: 'TRC20',
  OMNI: 'Omni',
  THETA: 'Theta',
  MIOTA: 'MIOTA',
  BSV: 'BSV',
  ZINU: 'ERC20',
  BNB: 'BSC20',
  ILV: 'ERC20',
  NFTB: 'BSC20',
  XDC: 'XDC',
  KIBA: 'ERC20',
  KLAY: 'Klaytn',
  KIBABSC: 'BSC20',
  MARSH: 'ERC20',
  FLUF: 'ERC20',
  BRISE: 'Bitgert',
  AVAXC: 'AVAXC',
  GHC: 'BSC20',
  ETHBSC: 'BSC20',
  ETHW: 'ETHW',
  USDTSOL: 'SOL',
  GUARD: 'ERC20',
  VOLT: 'ERC20',
  BTFA: 'ERC20',
  NEAR: 'NEAR',
  XCAD: 'ERC20',
  HOTCROSS: 'BSC20',
  BTTC: 'TRC20',
  BTTCBSC: 'BSC20',
  CUDOS: 'Cudos',
  FLOKIBSC: 'BSC20',
  IOTA: 'MIOTA',
  GAFA: 'ERC20',
  TENSHI: 'ERC20',
  PIKA: 'ERC20',
  TOMO: 'Tomo',
  GAL: 'BSC20',
  GGTKN: 'ERC20',
  USDTBSC: 'BSC20',
  SXP: 'BSC20',
  DAI: 'ERC20',
  '1INCHBSC': 'BSC20',
  APE: 'ERC20',
  ATOM: 'Cosmos',
  AVAX: 'AVAX',
  DOGECOIN: 'DOGE',
  KEANU: 'ERC20',
  KNC: 'ERC20',
  RXCG: 'BSC20',
  GSPI: 'ERC20',
  UST: 'LUNA',
  LEASH: 'ERC20',
  BUSDBSC: 'BSC20',
  PIT: 'BSC20',
  POODL: 'ERC20',
  XCUR: 'ERC20',
  FLOKI: 'ERC20',
  BAT: 'ERC20',
  C98: 'ERC20',
  FRONT: 'ERC20',
  BUSD: 'BSC20',
  QUACK: 'BSC20',
  TTC: 'ERC20',
  IOTX: 'IOTX',
  USDCMATIC: 'Polygon',
  USDDTRC20: 'TRC20',
  CULT: 'ERC20',
  CVC: 'ERC20',
  DAO: 'ERC20',
  DGMOON: 'BSC20',
  '1INCH': 'ERC20',
  LGCY: 'ERC20',
  AVABSC: 'BSC20',
  AVN: 'BSC20',
  BABYDOGE: 'BSC20',
  CAKE: 'BSC20',
  CNS: 'ERC20',
  FTM: 'ERC20',
  LINK: 'ERC20',
  LUNA: 'LUNA',
  LUNC: 'LUNC',
  MANA: 'ERC20',
  MATICMAINNET: 'Polygon',
  POOLZ: 'BSC20',
  RACA: 'BSC20',
  RBIF: 'ERC20',
  AVAERC20: 'ERC20',
  CHR: 'ERC20',
  ENJ: 'ERC20',
  SPI: 'ERC20',
  GALAERC20: 'ERC20',
  XYO: 'ERC20',
  CHZ: 'ERC20',
  AXS: 'ERC20',
  FEG: 'ERC20',
  FTT: 'ERC20',
  GRT: 'ERC20',
  MX: 'ERC20',
  NTVRK: 'ERC20',
  SHIBBSC: 'BSC20',
  SUPER: 'ERC20',
  TFUEL: 'Theta',
  TKO: 'BSC20',
  GT: 'ERC20',
  BEL: 'ERC20',
  GUSD: 'ERC20',
  HOGE: 'ERC20',
  AAVE: 'ERC20',
  ARPA: 'ERC20',
  ARV: 'BSC20',
  BIFI: 'BSC20',
  BLOCKS: 'ERC20',
  CTSI: 'ERC20',
  HOT: 'ERC20',
  HT: 'ERC20',
  OKB: 'ERC20',
  OM: 'ERC20',
  OMG: 'ERC20',
  REP: 'ERC20',
  SFUND: 'BSC20',
  SHIB: 'ERC20',
  TRVL: 'ERC20',
  USDP: 'ERC20',
  QTUM: 'ERC20',
  XLM: 'Stellar',
  VIB: 'ERC20',
  XEM: 'NEM',
  ADA: 'Cardano',
  HBAR: 'Hedera',
  EOS: 'EOS',
  ICX: 'ICON',
  XZC: 'Zcoin',
  RVN: 'Ravencoin',
  KMD: 'Komodo',
  XRP: 'Ripple',
  GAS: 'Neo',
  LTC: 'LTC',
  BONE: 'ERC20',
  SAND: 'ERC20',
  BCD: 'Bitcoin Diamond',
  XVG: 'Verge',
  BCH: 'BCH',
  SRK: 'ERC20',
  BNBMAINNET: 'BSC20',
  BTG: 'Bitcoin Gold',
  COTI: 'ERC20',
  DASH: 'DASH',
  DIVI: 'DIVI',
  DCR: 'DCR',
  GRS: 'GRS',
  NANO: 'NANO',
  NEO: 'Neo',
  NPXS: 'ERC20',
  YFI: 'ERC20',
  ZEC: 'ZEC',
  ZEN: 'ZEN',
  ZBC: 'ERC20',
  TON: 'TON',
  ONE: 'Harmony',
  ONT: 'Ontology',
  SOL: 'SOL',
  ALGO: 'Algorand',
  ARK: 'Ark',
  AVA: 'AVAX',
  BEAM: 'Beam',
  BNBBSC: 'BSC20',
  DOT: 'Polkadot',
  EGLD: 'Elrond',
  STRAX: 'Stratis',
  ETC: 'ETC',
  FIL: 'Filecoin',
  FIRO: 'Firo',
  FTMMAINNET: 'Fantom',
  AE: 'Aeternity',
  WABI: 'ERC20',
  XTZ: 'Tezos',
  XYM: 'Symbol',
  ZIL: 'Zilliqa',
  TRX: 'TRON',
  KISHU: 'ERC20',
  KLV: 'TRC20',
  LSK: 'Lisk',
  MATIC: 'Polygon',
  NWC: 'ERC20',
  OCEAN: 'ERC20',
  MCO: 'Crypto.com',
  CRO: 'Crypto.com',
  FUN: 'ERC20',
  STPT: 'ERC20',
  SXPMAINNET: 'BSC20',
  TUSD: 'ERC20',
  UNI: 'ERC20',
  VET: 'VeChain',
  WAVES: 'Waves',
  NOW: 'ChangeNOW',
  ETH: 'ERC20',
  USDC: 'ERC20',
  USDT: 'ERC20',
};
