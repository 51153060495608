import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  useLoginUser,
  useRegisterUser,
  useSignUpWhatsApp,
  useVerifyPhoneNewCode,
  useVerifyUser,
} from '~api/auth/authMutations';
import { UserRegisterInput } from '~api/auth/types';
import { AUTH_FIELD_TYPE } from '~api/partner/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { SIGN_UP_TABS } from '~components/molecules/Authentication/components/sign-up/SignUpTabList';
import {
  SIGNIN_FORM_FIELDS,
  SIGNUP_FORM_FIELDS,
  SignupFormInputs,
} from '~components/molecules/Authentication/types';
import { SIGNUP_FORM_DEFAULT_VALUES } from '~constants/auth';
import {
  HTTP_STATUS_CODES,
  INVITE_CODE_KEY,
  REF_CODE_KEY,
} from '~constants/common';
import { usePhoneNumber, useTranslation } from '~hooks';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog, openDialog } from '~store/slices/globalDialogSlice';
import { setSessionId } from '~store/slices/signalRSocketsSlice';
import { setIsUserBlocked } from '~store/slices/userSlice';
import { BACKEND_ERROR_CODES } from '~utils/backendErrors';
import { COOKIES_NAMES, setCookie } from '~utils/cookies';

export const useSignUp = () => {
  const { formatPhoneNumberPayload } = usePhoneNumber();
  const { localizedError } = useTranslation();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(SIGN_UP_TABS.PROVIDE_DETAILS);
  const [isRegisteringWhatsApp, setIsRegisteringWhatsApp] = useState(false);
  const [isRegisteredSuccessfully, setIsRegisteredSuccessfully] =
    useState(false);
  const formMethods = useForm<SignupFormInputs>({
    defaultValues: SIGNUP_FORM_DEFAULT_VALUES,
  });
  const { getValues, setError, clearErrors } = formMethods;
  const { requiredRegistrationFields } = useAppSelector(
    (state) => state.settings,
  );
  const {
    verifyUserMutation,
    verifyUserIsLoading,
    verifyUserIsSuccess,
    verifyUserIsError,
    verifyUserErrorMessage,
  } = useVerifyUser();
  const {
    signUpWhatsAppMutation,
    signUpWhatsAppIsLoading,
    signUpWhatsAppIsError,
    signUpWhatsAppErrorMessage,
  } = useSignUpWhatsApp();

  const {
    registerUserMutation,
    registerUserIsLoading,
    registerUserIsError,
    registerUserErrorMessage,
    registerUserData,
    registerUserErrorCode,
  } = useRegisterUser();
  const { loginUserMutation, loginUserData, loginUserIsSuccess } =
    useLoginUser();
  const {
    verifyPhoneNewCodeMutation,
    verifyPhoneNewCodeData,
    verifyPhoneNewCodeIsSuccess,
    verifyPhoneNewCodeIsLoading,
  } = useVerifyPhoneNewCode();

  /* ERROR HOOKS */
  useEffect(() => {
    if (registerUserIsError) {
      if (
        registerUserErrorMessage === BACKEND_ERROR_CODES.DUPLICATE_USER_NAME ||
        registerUserErrorMessage === BACKEND_ERROR_CODES.USERNAME_ALREADY_EXISTS
      ) {
        setError(SIGNUP_FORM_FIELDS.USERNAME_FIELD, {
          type: BACKEND_ERROR_CODES.DUPLICATE_USER_NAME,
          message: localizedError('username.alreadyExists'),
        });
      }

      if (
        registerUserErrorMessage ===
        BACKEND_ERROR_CODES.PHONE_NUMBER_ALREADY_EXISTS
      ) {
        setError(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, {
          type: BACKEND_ERROR_CODES.PHONE_NUMBER_ALREADY_EXISTS,
          message: localizedError('phoneNumber.alreadyExists'),
        });
      }

      if (registerUserErrorCode === HTTP_STATUS_CODES.BAD_REQUEST) {
        setError('root.incompleteOrIncorrectInfo', {
          type: HTTP_STATUS_CODES.BAD_REQUEST,
          message: registerUserErrorMessage,
        });
      }

      if (registerUserErrorCode === HTTP_STATUS_CODES.NOT_AVAILABLE) {
        setError('root.serverError', {
          type: HTTP_STATUS_CODES.BAD_REQUEST,
          message: localizedError('root.serverError'),
        });
      }
    }
  }, [registerUserIsError, registerUserErrorMessage]);

  useEffect(() => {
    if (verifyUserIsError) {
      if (
        verifyUserErrorMessage === BACKEND_ERROR_CODES.WRONG_VERIFICATION_CODE
      ) {
        setError(SIGNUP_FORM_FIELDS.NUMBER_CODE_FIELD, {
          type: BACKEND_ERROR_CODES.WRONG_VERIFICATION_CODE,
          message: localizedError('numberCode.incorrectCode'),
        });
      }

      if (verifyUserErrorMessage === BACKEND_ERROR_CODES.INVALID_PHONE_NUMBER) {
        setError(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, {
          type: BACKEND_ERROR_CODES.INVALID_PHONE_NUMBER,
          message: localizedError('phoneNumber.invalid'),
        });
      }

      if (verifyUserErrorMessage === HTTP_STATUS_CODES.NOT_AVAILABLE) {
        setError('root.serverError', {
          type: HTTP_STATUS_CODES.BAD_REQUEST,
          message: localizedError('root.serverError'),
        });
      }
    }
  }, [verifyUserIsError, verifyUserErrorMessage]);

  useEffect(() => {
    if (signUpWhatsAppIsError) {
      if (
        signUpWhatsAppErrorMessage === BACKEND_ERROR_CODES.WHATSAPP_NOT_FOUND
      ) {
        setError(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD, {
          type: BACKEND_ERROR_CODES.WHATSAPP_NOT_FOUND,
          message: localizedError('phoneNumber.numberDoesntExistInWhatsapp'),
        });
      }

      if (
        signUpWhatsAppErrorMessage === BACKEND_ERROR_CODES.FAILEDS_SEND_MESSAGE
      ) {
        setError(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD, {
          type: BACKEND_ERROR_CODES.FAILEDS_SEND_MESSAGE,
          message: localizedError('phoneNumber.failedToSendWhatsAppMessage'),
        });
      }
    }
  }, [signUpWhatsAppIsError, signUpWhatsAppErrorMessage]);

  useEffect(() => {
    if (!verifyUserIsSuccess) {
      return;
    }

    const loginUser = async () => {
      try {
        const phoneNumberVal = getValues(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD);
        const phoneNumber = formatPhoneNumberPayload(phoneNumberVal);

        await loginUserMutation({
          phoneNumber,
          userName: getValues(SIGNIN_FORM_FIELDS.USERNAME_FIELD),
          password: getValues(SIGNIN_FORM_FIELDS.PASSWORD_FIELD),
        });
      } catch (err) {
        console.error('Failed to login user: ', err);
      }
    };

    loginUser();
  }, [verifyUserIsSuccess]);

  useEffect(() => {
    if (loginUserIsSuccess && loginUserData) {
      dispatch(closeDialog());
    }
  }, [loginUserIsSuccess, loginUserData]);

  /* HANDLERS */
  const goToTab = (tabName: SIGN_UP_TABS) => {
    setActiveTab(tabName);
  };

  const handleNextTab = async () => {
    try {
      const refCode = localStorage.getItem(REF_CODE_KEY);
      const inviteCode = localStorage.getItem(INVITE_CODE_KEY);
      const phoneNumberVal = getValues(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD);
      const phoneNumber = formatPhoneNumberPayload(phoneNumberVal);

      const payload: Record<string, string> = {
        userName: getValues(SIGNUP_FORM_FIELDS.USERNAME_FIELD),
        password: getValues(SIGNUP_FORM_FIELDS.PASSWORD_FIELD),
        promoCode: getValues(SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD),
        email: getValues(SIGNUP_FORM_FIELDS.EMAIL_FIELD),
      };

      if (phoneNumberVal?.length) {
        payload.phoneNumber = phoneNumber;
      }

      if (refCode && refCode.length) {
        payload.referenceCode = refCode;
      }

      if (inviteCode && inviteCode.length) {
        payload.referralUserId = inviteCode;
      }

      const resultPayload: Record<string, string> = {};

      const parsedPayloadKeys = Object.keys(payload).filter(
        (key) => (payload[key] || '').length,
      );

      parsedPayloadKeys.forEach((key) => {
        resultPayload[key] = payload[key] || '';
      });

      const result = await registerUserMutation(
        resultPayload as unknown as UserRegisterInput,
      ).unwrap();
      const { verificationCodeExpiration } = result;

      if (refCode && refCode.length) {
        localStorage.removeItem(REF_CODE_KEY);
      }

      if (inviteCode && inviteCode.length) {
        localStorage.removeItem(INVITE_CODE_KEY);
      }

      setCookie(
        COOKIES_NAMES.VERIFICATION_CODE_EXPIRATION,
        verificationCodeExpiration,
        {
          expires: new Date(verificationCodeExpiration),
        },
      );

      if (requiredRegistrationFields.includes(AUTH_FIELD_TYPE.PHONE_NUMBER)) {
        return goToTab(SIGN_UP_TABS.VERIFY_NUMBER);
      }

      if (requiredRegistrationFields.includes(AUTH_FIELD_TYPE.EMAIL)) {
        return goToTab(SIGN_UP_TABS.VERIFY_LINK);
      }

      if (requiredRegistrationFields.includes(AUTH_FIELD_TYPE.USER_NAME)) {
        return setIsRegisteredSuccessfully(true);
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (['userDeactivated', 'userBlocked'].includes(err.data.message[0])) {
        dispatch(openDialog(DIALOGS.DEACTIVATED_ACCOUNT));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(setIsUserBlocked(err.data.message[0] === 'userBlocked'));
      }

      console.error('Failed to register user: ', err);
    }
  };

  const handleVerifyPhoneNewCode = async () => {
    const phoneNumberVal = getValues(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD);
    const phoneNumber = formatPhoneNumberPayload(phoneNumberVal);

    try {
      await verifyPhoneNewCodeMutation({
        phoneNumber,
      }).unwrap();
    } catch (err) {
      console.error('Failed to verify phone with new code: ', err);
    }
  };

  const handleSignIn = () => {
    dispatch(openDialog(DIALOGS.SIGN_IN));
  };

  const handleRegisterUser = async () => {
    clearErrors();
    const isAgreed = getValues(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD);

    if (!isAgreed) {
      setError(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD, {
        type: 'required',
        message: localizedError('agreeToTerms.mustBeTrue'),
      });

      return;
    }

    const phoneNumberVal = getValues(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD);
    const phoneNumber = formatPhoneNumberPayload(phoneNumberVal);

    try {
      await verifyUserMutation({
        phoneNumber,
        verificationCode: getValues(SIGNUP_FORM_FIELDS.NUMBER_CODE_FIELD),
      }).unwrap();
    } catch (err) {
      console.error('Failed to verify user: ', err);
    }
  };

  const handleSignInWithWhatsApp = async () => {
    clearErrors();
    const isAgreed = getValues(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD);

    if (!isAgreed) {
      setError(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD, {
        type: 'required',
        message: localizedError('agreeToTerms.mustBeTrue'),
      });
    }

    const name = getValues(SIGNUP_FORM_FIELDS.USERNAME_FIELD);

    if (!name.length) {
      setError(SIGNUP_FORM_FIELDS.USERNAME_FIELD, {
        type: 'required',
        message: localizedError('username.required'),
      });
    }

    const phoneNumberVal = getValues(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD);
    const phoneNumber = formatPhoneNumberPayload(phoneNumberVal);

    if (!phoneNumberVal.length) {
      setError(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, {
        type: 'required',
        message: localizedError('phoneNumber.required'),
      });

      return;
    }

    try {
      const response = await signUpWhatsAppMutation({
        phoneNumber,
        userName: getValues(SIGNUP_FORM_FIELDS.USERNAME_FIELD),
        promoCode: getValues(SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD),
      });

      if ('error' in response) {
        setError('root.serverError', {
          type: HTTP_STATUS_CODES.BAD_REQUEST,
          message: localizedError('root.serverError'),
        });

        return;
      }

      const { sessionId } = response.data;

      dispatch(setSessionId(sessionId));

      setIsRegisteringWhatsApp(true);
    } catch (e) {
      setIsRegisteringWhatsApp(false);
    }
  };

  return {
    activeTab,
    formMethods,
    isRegisteringWhatsApp,
    isRegisteredSuccessfully,
    // Loading states
    registerUserIsLoading,
    signUpWhatsAppIsLoading,
    verifyUserIsLoading,
    verifyPhoneNewCodeIsLoading,
    // Data
    registerUserData,
    verifyPhoneNewCodeData,
    verifyPhoneNewCodeIsSuccess,
    // Handlers
    handleNextTab,
    handleRegisterUser,
    handleSignIn,
    handleSignInWithWhatsApp,
    handleVerifyPhoneNewCode,
  };
};
