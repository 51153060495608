import { ReactNode, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { ScrollToTopButton } from '~components/atoms/ScrollToTopButton';
import { Header } from '~components/organisms/HeaderComponent/Header';
import { ROUTE_TAB_NAMES } from '~constants/common';
import {
  SharedRefContext,
  SharedRefContextProps,
} from '~contexts/SharedRefContext';
import { useComponentObserver } from '~hooks/useComponentObserver';
import useIsInIframe from '~hooks/useIsInIframe';
import { useAppDispatch, useAppSelector } from '~store';
import { setMobileHeaderInDialogHeight } from '~store/slices/mobileSlice';
import { setBetslipFooterHeight } from '~store/slices/userUISlice';
import { zIndex } from '~utils/zIndex';

import { StyledDialogHeaderWrapper } from './styled.components';

interface DialogMobileContentProps {
  children: ReactNode;
  isContentState?: boolean;
  isSecondLevelDialog?: boolean;
  headerZIndex?: number;
}

export const DialogMobileContent = ({
  isContentState,
  children,
  isSecondLevelDialog,
  headerZIndex,
}: DialogMobileContentProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const { betslipFooterHeight } = useAppSelector((state) => state.userUIState);

  const context = useContext(SharedRefContext) as SharedRefContextProps;

  const isInFrame = useIsInIframe();

  useComponentObserver(headerWrapperRef, (height) => {
    if (isSecondLevelDialog) return;
    dispatch(setMobileHeaderInDialogHeight(height));
  });

  useEffect(() => {
    if (context) {
      const { setRef } = context;

      setRef(contentWrapperRef);
    }
  }, [contentWrapperRef]);

  useEffect(() => {
    const isBetslip = location.pathname.includes(ROUTE_TAB_NAMES.BETSLIP);

    if (!isBetslip) {
      dispatch(setBetslipFooterHeight(0));
    }
  }, [location]);

  const { mobileHeaderInDialogHeight } = useAppSelector(
    (state) => state.mobileState,
  );
  const marginTop = isSecondLevelDialog ? 61 : mobileHeaderInDialogHeight - 5;

  return (
    <>
      <StyledDialogHeaderWrapper
        ref={headerWrapperRef}
        style={{ zIndex: headerZIndex }}
      >
        {!isInFrame && <Header inModal showMenu={false} />}
        {isContentState && <Breadcrumb />}
      </StyledDialogHeaderWrapper>
      <Box
        fullHeight
        css={{
          minHeight: `calc(100vh - ${marginTop}px)`,
          mt: `${marginTop}px`,
          pb: `${betslipFooterHeight || 200}px`,
          overflowY: 'auto',
          background: '$primaryOne',
        }}
        ref={contentWrapperRef}
      >
        {children}
        <ScrollToTopButton
          scrollableContainerRef={contentWrapperRef}
          elevation={zIndex.composition.modal.fixed}
        />
      </Box>
    </>
  );
};
