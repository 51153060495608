import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTable } from 'react-table';

import { Transactions } from '~api/transaction/types';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

import { useTransactionHistoryTable } from '../hooks/useTransactionHistoryTable';

import {
  StyledCell,
  StyledCellContent,
  StyledHeaderCell,
  StyledPlaceholderCell,
  StyledTable,
  StyledTableHeader,
} from './styled.components';

export interface TransactionHistoryTableProps {
  data: Transactions;
  isLoading: boolean;
  dataLength?: number;
  isAllTransactions?: boolean;
  loadMore?: () => void;
}

export const columnWidths: Record<number, string> = {
  0: '210px',
  1: '80px',
  2: '100px',
  3: '80px',
  4: '100px',
  5: '100px',
};

const scrollContainerId = 'transaction-history-table-scroll-container';

export const TransactionHistoryTable = ({
  data,
  isLoading,
  dataLength = 0,
  isAllTransactions,
  loadMore = () => {},
}: TransactionHistoryTableProps) => {
  const { localized } = useTranslation();
  const { columns, preparedData } = useTransactionHistoryTable(
    data,
    isAllTransactions,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: preparedData });

  return (
    <ScrollArea id={scrollContainerId}>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        hasMore={dataLength > data.length}
        scrollableTarget={scrollContainerId}
        loader={null}
      >
        <StyledTable {...getTableProps()}>
          <StyledTableHeader>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <StyledHeaderCell
                    {...column.getHeaderProps()}
                    css={{ width: columnWidths[index] }}
                  >
                    {column.render('Header')}
                  </StyledHeaderCell>
                ))}
              </tr>
            ))}
          </StyledTableHeader>
          {rows.length && !isLoading ? (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => (
                      <StyledCell {...cell.getCellProps()}>
                        <StyledCellContent
                          css={{
                            ...(cellIndex === 0
                              ? {
                                  borderTopLeftRadius: '$6',
                                  borderBottomLeftRadius: '$6',
                                }
                              : {}),
                            ...(cellIndex === row.cells.length - 1
                              ? {
                                  borderTopRightRadius: '$6',
                                  borderBottomRightRadius: '$6',
                                }
                              : {}),
                          }}
                        >
                          {cell.render('Cell')}
                        </StyledCellContent>
                      </StyledCell>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <StyledPlaceholderCell colSpan={5}>
                  {isLoading ? (
                    <Loader css={{ justifyContent: 'center' }} />
                  ) : (
                    <Box flexCol fullHeight fullWidth justifyCenter>
                      <Text
                        level="20-32"
                        fontWeight="medium"
                        color="textSecondaryOne"
                      >
                        {localized('transactionHistory.emptyTable.title')}
                      </Text>
                    </Box>
                  )}
                </StyledPlaceholderCell>
              </tr>
            </tbody>
          )}
        </StyledTable>
      </InfiniteScroll>
    </ScrollArea>
  );
};
