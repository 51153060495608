import { Leagues } from '~api/category/types';
import { LeagueMenuList } from '~components/atoms/LeagueMenuList';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionTrigger,
} from '../MultiAccordion';

interface CountryMenuItemProps {
  sportId: number;
  id: number;
  name: string;
  events: number;
  leagues: Leagues;
  code: string | null;
  getLeaguesData: (countryId: number) => Leagues;
  countryMenuRole: MULTI_ACCORDION_ROLES;
  loadingCountryId?: number;
}

export const CountryMenuItem = ({
  sportId,
  id,
  name,
  events,
  leagues,
  code,
  getLeaguesData,
  countryMenuRole,
  loadingCountryId,
}: CountryMenuItemProps) => {
  return (
    <MultiAccordionItem value={id.toString()} key={id} role={countryMenuRole}>
      <MultiAccordionTrigger
        title={name}
        count={events}
        role={countryMenuRole}
        country={name}
        code={code}
      />
      <MultiAccordionContent role={countryMenuRole}>
        <LeagueMenuList
          loadingCountryId={loadingCountryId}
          countryId={id}
          sportId={sportId}
          initialLeagues={leagues}
          leagues={getLeaguesData(id)}
          countryName={name}
        />
      </MultiAccordionContent>
    </MultiAccordionItem>
  );
};
