import { SPORTS } from '~constants/sport';
import {
  BasketballExtraData,
  ExtraData,
  IceHockeyExtraData,
  SnookerExtraData,
  SoccerExtraData,
  TennisExtraData,
  VolleyballExtraData,
} from '~types/sportExtraData';
import {
  getBasketballDetails,
  getIceHockeyDetails,
  getSnookerDetails,
  getSoccerDetails,
  getTennisDetails,
  getVolleyballDetails,
} from '~utils/sportHelpers';

export const getScoreDetailsBySportMap = (
  sportName: string | undefined,
  extraData: ExtraData | null,
): string | null => {
  const scoreDetailsBySportMap: Partial<
    Record<SPORTS, () => string | undefined>
  > = {
    [SPORTS.BASKETBALL]: () => {
      const { details } =
        getBasketballDetails(extraData as BasketballExtraData, false) || {};

      return details;
    },
    [SPORTS.ICE_HOCKEY]: () => {
      const { details } =
        getIceHockeyDetails(extraData as IceHockeyExtraData, false) || {};

      return details;
    },
    [SPORTS.SNOOKER]: () => {
      const { details } =
        getSnookerDetails(extraData as SnookerExtraData, false) || {};

      return details;
    },
    [SPORTS.SOCCER]: () => {
      const { details } =
        getSoccerDetails(extraData as SoccerExtraData, false) || {};

      return details;
    },
    [SPORTS.TENNIS]: () => {
      const { details } = getTennisDetails(extraData as TennisExtraData) || {};

      return details;
    },
    [SPORTS.VOLLEYBALL]: () => {
      const { details } =
        getVolleyballDetails(extraData as VolleyballExtraData, false) || {};

      return details;
    },
  };

  if (!sportName || !(sportName in scoreDetailsBySportMap)) {
    return null;
  }

  return scoreDetailsBySportMap[sportName as SPORTS]!() ?? null;
};
