import { SportMainMarkets } from '~api/market/types';
import { SportEventItem } from '~api/sportEvent/types';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { splitStringOnVs } from '~utils/stringUtils';

import { LeagueEventContentMobile } from './LeagueEventContentMobile';
import { useLeagueEvent } from './useLeagueEvent';

interface LeagueEventPropsMobile {
  eventData: SportEventItem;
  isFirstEvent: boolean;
  isFirstDate: boolean;
  mainMarkets?: SportMainMarkets;
}

export const LeagueEventMobile = ({
  eventData,
  isFirstEvent,
  isFirstDate,
  mainMarkets,
}: LeagueEventPropsMobile) => {
  const { sportId: sportIdFromUrl } = useQueryParams();
  const sportId = parseInt(sportIdFromUrl || '');
  const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);

  const { marketData, handleFavoriteClick } = useLeagueEvent({
    eventData,
    mainMarkets,
    sportId,
  });

  if (!eventData) return null;

  const { name, startDate, marketsCount, id } = eventData;
  const [homeTeamName, awayTeamName] = splitStringOnVs(name);
  const time = formatDateTime(startDate, TIME_FORMATS.TIME_12H);
  const mainSportMarketId = mainMarketsSelected[sportId];

  const selectedMarket = marketData.find(
    (market) => parseInt(market?.marketId) === mainSportMarketId,
  );

  return (
    <LeagueEventContentMobile
      eventData={eventData}
      time={time}
      eventId={id}
      homeTeamName={homeTeamName}
      awayTeamName={awayTeamName}
      marketsCount={marketsCount}
      selectedMarket={selectedMarket}
      isFirstEvent={isFirstEvent}
      isFirstDate={isFirstDate}
      handleFavoriteClick={handleFavoriteClick}
    />
  );
};
