import { BONUS_STATUS } from '~api/bonus/types';

export const getLocalizedBonusHistoryStatusOptions = (
  localized: (key: string, args?: object) => string,
) => {
  return [
    {
      title: localized('bonuses.status.all'),
      label: localized('bonuses.status.all'),
      value: '-1',
    },
    {
      title: localized('bonuses.status.active'),
      label: localized('bonuses.status.active'),
      value: BONUS_STATUS.ACTIVE.toString(),
    },
    {
      title: localized('bonuses.status.used'),
      label: localized('bonuses.status.used'),
      value: BONUS_STATUS.USED.toString(),
    },
    {
      title: localized('bonuses.status.expired'),
      label: localized('bonuses.status.expired'),
      value: BONUS_STATUS.EXPIRED.toString(),
    },
  ];
};

export interface BonusHistoryColumn {
  name: string;
  width: string;
}

export type BonusHistoryColumns = BonusHistoryColumn[];

export const getLocalizedBonusHistoryColumns = (
  localized: (key: string, args?: object) => string,
): BonusHistoryColumns => {
  return [
    {
      name: localized('bonuses.history.tableHeaders.bonusName'),
      width: '170px',
    },
    {
      name: localized('bonuses.history.tableHeaders.section'),
      width: '50px',
    },
    {
      name: localized('bonuses.history.tableHeaders.usedAmount'),
      width: '125px',
    },
    {
      name: localized('bonuses.history.tableHeaders.wonMaxWin'),
      width: '125px',
    },
    {
      name: localized('bonuses.history.tableHeaders.status'),
      width: '170px',
    },
  ];
};
