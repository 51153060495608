export const splitStringOnVs = (
  inputString: string,
): [string | undefined, string | undefined] => {
  if (!inputString) {
    return ['', ''];
  }

  const splitArray = inputString?.split('vs.');
  const firstHalf = splitArray[0]?.trim();
  const secondHalf = splitArray[1]?.trim();

  return [firstHalf, secondHalf];
};

export const capitalizeFirstLetter = (inputString: string) => {
  if (inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  return '';
};

export const removeUnderScores = (inputString: string) => {
  return inputString.replace(/_/g, ' ');
};

export const removeNumberLastSymbol = (text: string | number): number => {
  return +`${text}`.slice(0, `${text}`.length - 1);
};
