import {
  useLazyGetBonusBalanceQuery,
  useLazyGetProfileBonusQuery,
} from './bonusApi';

export const useLazyGetProfileBonus = () => {
  const [query, { data, error, isLoading }] = useLazyGetProfileBonusQuery();

  return {
    lazyGetProfileBonusQuery: query,
    lazyGetProfileBonusData: data,
    lazyGetProfileBonusError: error,
    lazyGetProfileBonusIsLoading: isLoading,
  };
};

export const useLazyGetBonusBalance = () => {
  const [query, { data, error, isLoading }] = useLazyGetBonusBalanceQuery();

  return {
    lazyGetBonusBalanceData: data,
    lazyGetBonusBalanceError: error,
    lazyGetBonusBalanceIsLoading: isLoading,
    lazyGetBonusBalanceQuery: query,
  };
};
