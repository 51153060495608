import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';

interface FormattedStartDateProps {
  formattedStartDate: string;
}

export const FormattedScoreboardStartDate = ({
  formattedStartDate,
}: FormattedStartDateProps) => {
  const { isMobileOrTablet } = useMedia();

  if (!isMobileOrTablet) {
    return null;
  }

  return (
    <Box
      flexRow
      alignCenter
      css={{
        backgroundColor: '$primaryTwoOpacity',
        height: '$7',
        pl: '$2',
      }}
    >
      <Text level="15-24" fontWeight="medium">
        {formattedStartDate}
      </Text>
    </Box>
  );
};
