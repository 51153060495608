import React from 'react';

import { DOCUMENT_TYPES } from '~api/user/types';
import { Select } from '~components/atoms/Select';
import { SelectWithLabel } from '~components/atoms/SelectWithLabel';
import { CURRENCY_CODES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

interface DocumentTypeOption {
  value: DOCUMENT_TYPES;
  label: string;
}

interface DocumentTypeSelectProps {
  documentType: DOCUMENT_TYPES;
  documentTypeOptions: DocumentTypeOption[];
  handleDocumentTypeChange: (value: DOCUMENT_TYPES) => void;
}

export const DocumentTypeSelect = ({
  documentType,
  documentTypeOptions,
  handleDocumentTypeChange,
}: DocumentTypeSelectProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { currency } = useAppSelector((state) => state.settings);
  const isNigeria = currency === CURRENCY_CODES.NGN;
  const isZambia = currency === CURRENCY_CODES.ZMK;

  const filteredOptions = !(isNigeria || isZambia)
    ? documentTypeOptions
    : documentTypeOptions.filter(
        ({ value }) => value !== DOCUMENT_TYPES.NATIONAL_PASSPORT,
      );

  if (isMobileOrTablet) {
    return (
      <SelectWithLabel
        value={documentType}
        setValue={(value) => handleDocumentTypeChange(value as DOCUMENT_TYPES)}
        options={filteredOptions}
        style={{
          width: '100%',
          '& .ant-select-selector': {
            backgroundColor: '$secondaryTwo !important',
            padding: '0px 5px !important',
            height: '32px !important',
          },
        }}
      />
    );
  }

  return (
    <Select
      css={{
        width: '100%',
        backgroundColor: '$secondaryTwo',
        color: '$textPrimaryOne',
      }}
      ariaLabel={localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.ariaLabel',
      )}
      value={documentType}
      onChange={(value) => handleDocumentTypeChange(value as DOCUMENT_TYPES)}
      options={filteredOptions}
      isCustomContentWidth={!isMobileOrTablet}
      placeholder={localized(
        'userProfile.personalProfileInfo.accountVerification.documentTypeSelect.placeholder',
      )}
      contentWidth={isMobileOrTablet ? null : 278}
    />
  );
};
