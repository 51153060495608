import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledPersonalDetailsFormWrapper = styled(Box, {
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
  gridAutoRows: '32px',
  gap: '$2',
  '@xs_sm': {
    display: 'flex',
    flexDirection: 'column',
    p: '$3',
    backgroundColor: '$primaryTwo',
    borderRadius: '$8',
  },
});
