import { useEffect, useRef } from 'react';

import { Dialog, DialogContent } from '~components/atoms/Modal';
import { ResetPasswordContent } from '~components/molecules/Authentication/components/reset-password/ResetPasswordContent';
import { SignInContent } from '~components/molecules/Authentication/components/sign-in/SignInContent';
import { SignUpContent } from '~components/molecules/Authentication/components/sign-up/SignUpContent';
import { VerifyEmailToken } from '~components/molecules/Authentication/components/verify-email-token/VerifyEmailToken';
import { VerifyPersonalDetails } from '~components/molecules/Authentication/components/VerifyPersonalDetails';
import { CashOut, CashOutSuccess } from '~components/molecules/CashOut';
import { DeactivatedAccount } from '~components/molecules/DeactivatedAccount';
import { GamesModalFilters } from '~components/molecules/GamesModalFilters/GamesModalFilters';
import { LuckyDipTooltipDialog } from '~components/molecules/Jackpot/Active/components/LuckyDip/LuckyDipTooltipDialog';
import { MobileMenu } from '~components/molecules/MobileMenu';
import { BonusRules } from '~components/molecules/UserProfile/components/BonusRules';
import { UserProfileDialog } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { UserProfileDialogMobile } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { SharedRefProvider } from '~contexts/SharedRefContext';
import { useAppDispatch, useAppSelector } from '~store';
import { toggleDialog } from '~store/slices/globalDialogSlice';
import { setIsCashoutOpen } from '~store/slices/openBetSlice';

export enum DIALOGS {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  RESET_PASSWORD = 'resetPassword',
  USER_PROFILE = 'userProfile',
  USER_PROFILE_MOBILE = 'userProfileMobile',
  VERIFY_EMAIL_TOKEN = 'verifyEmailToken',
  VERIFY_PERSONAL_DETAILS = 'verifyPersonalDetails',
  MENU = 'menu',
  CASHOUT_SUCCESS = 'cashoutSuccess',
  CASHOUT = 'cashout',
  DEACTIVATED_ACCOUNT = 'deactivatedAccount',
  BONUS_RULES = 'bonusRules',
  LUCKY_DIP = 'luckyDip',
  GAMES_FILTERS = 'gamesFilters',
}

const notFullScreenModals = [
  DIALOGS.CASHOUT_SUCCESS,
  DIALOGS.CASHOUT,
  DIALOGS.DEACTIVATED_ACCOUNT,
];

const contentsMap = {
  [DIALOGS.SIGN_IN]: <SignInContent />,
  [DIALOGS.SIGN_UP]: <SignUpContent />,
  [DIALOGS.RESET_PASSWORD]: <ResetPasswordContent />,
  [DIALOGS.USER_PROFILE]: <UserProfileDialog />,
  [DIALOGS.USER_PROFILE_MOBILE]: (
    <>
      <SharedRefProvider>
        <UserProfileDialogMobile />
      </SharedRefProvider>
    </>
  ),
  [DIALOGS.VERIFY_EMAIL_TOKEN]: <VerifyEmailToken />,
  [DIALOGS.VERIFY_PERSONAL_DETAILS]: <VerifyPersonalDetails />,
  [DIALOGS.MENU]: <MobileMenu />,
  [DIALOGS.CASHOUT_SUCCESS]: <CashOutSuccess />,
  [DIALOGS.CASHOUT]: <CashOut />,
  [DIALOGS.DEACTIVATED_ACCOUNT]: <DeactivatedAccount />,
  [DIALOGS.BONUS_RULES]: <BonusRules />,
  [DIALOGS.LUCKY_DIP]: <LuckyDipTooltipDialog />,
  [DIALOGS.GAMES_FILTERS]: <GamesModalFilters />,
};

export const AbsoluteDialogs = () => {
  const dispatch = useAppDispatch();
  const { isOpen, content } = useAppSelector((state) => state.globalDialog);
  const prevContentValue = useRef(content);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Step 1: Define timeoutRef

  const isNotFullScreen = notFullScreenModals.includes(content as DIALOGS);

  useEffect(() => {
    if (isOpen) {
      prevContentValue.current = content;
    }
  }, [content, isOpen]);

  useEffect(() => {
    const scrollTopDialogs = [
      DIALOGS.SIGN_IN,
      DIALOGS.SIGN_UP,
      DIALOGS.USER_PROFILE,
    ];

    if (
      scrollTopDialogs.includes(prevContentValue.current as DIALOGS) &&
      window !== undefined
    ) {
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      dispatch(setIsCashoutOpen(false));
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (!isOpen || !content) return null;

  const Component = contentsMap[content];

  const handleToggleDialog = (value: boolean) => {
    dispatch(toggleDialog(value));

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (!value) {
        const body = document.body;

        body.style.pointerEvents = 'auto';
      }
    }, 100);
  };

  return (
    <Dialog open={isOpen} toggleDialog={handleToggleDialog}>
      <DialogContent isNotFullScreen={isNotFullScreen} hasOverlay>
        {Component}
      </DialogContent>
    </Dialog>
  );
};
