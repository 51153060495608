import React from 'react';
import { HeaderGroup } from 'react-table';

import { StyledBetHistoryHeader } from '~components/molecules/BetslipHistorySection/styled.components';

export const TableHeader = <T extends object>({
  headerGroups,
  columnWidths,
  customStyles,
}: {
  headerGroups: HeaderGroup<T>[];
  columnWidths?: Record<number, string>;
  customStyles?: Record<string, unknown>;
}) => (
  <thead>
    {headerGroups.map((headerGroup, headerGroupIndex) => (
      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
        {headerGroup.headers.map((column, columnIndex) => (
          <StyledBetHistoryHeader
            {...column.getHeaderProps()}
            key={columnIndex}
            css={{
              width: columnWidths?.[columnIndex] || 'auto',
              ...customStyles,
            }}
          >
            {column.render('Header')}
          </StyledBetHistoryHeader>
        ))}
      </tr>
    ))}
  </thead>
);
