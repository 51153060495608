import React, { useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';

import { TableIconExpandCell } from '~components/molecules/BetslipHistorySection/components/TableIconExpandCell';
import { StakeCell } from '~components/molecules/BetslipHistorySection/Jackpot/JackpotBetslipsDesktopTable/components/StakeCell';
import {
  JACKPOT_SETTLEMENT_STATUS,
  JACKPOT_SETTLEMENT_STATUS_COLORS,
  JACKPOT_SETTLEMENT_STATUS_LABELS,
  JACKPOT_STATUS_COLORS,
  JACKPOT_STATUS_LABELS,
} from '~constants/jackpot';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { getCurrencySymbol } from '~store/slices/selectors/settings';
import { JackpotBetsHistory } from '~types/jackpot';
import { formatNumberToStringWithSpaces } from '~utils/numberUtils';

import { DetailsCell, TextCell } from '../components';
import { JackpotBetslipsEventsTable } from '../JackpotBetslipsEventsTable';

export const useNewJackpotBetHistoryTable = () => {
  const { localized } = useTranslation();
  const currencySymbol = useAppSelector(getCurrencySymbol);

  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});

  const renderExpandedRowContent = (original: JackpotBetsHistory) => {
    return <JackpotBetslipsEventsTable events={original.events} />;
  };

  const renderIconOuterCell = ({ cell }: CellProps<JackpotBetsHistory>) => {
    return (
      <TableIconExpandCell cell={cell} isOpen={openRows[cell.row.index]} />
    );
  };

  const handleOuterTableRowClick = (rowIndex: number) => () => {
    setOpenRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const getOpenRowsValue = (rowIndex: number) =>
    openRows[rowIndex] ? [openRows[rowIndex]?.toString() || ''] : [];

  const columns = useMemo(
    () => [
      {
        Header: localized('jackpot.betslipHistory.details'),
        accessor: 'details',
        Cell: DetailsCell,
      },
      {
        Header: localized('jackpot.market'),
        accessor: 'marketName',
        Cell: ({ row }) => <TextCell value={row.original.marketName} />,
      },
      {
        Header: localized('jackpot.betslipHistory.tickets'),
        accessor: 'ticketsCount',
        Cell: ({ row }) => (
          <TextCell value={row.original.ticketsCount.toString()} />
        ),
      },
      {
        Header: localized('jackpot.betslipHistory.stake'),
        accessor: 'stake',
        Cell: StakeCell,
      },
      {
        Header: localized('jackpot.betslipHistory.status'),
        accessor: 'status',
        Cell: ({ row }) => (
          <TextCell
            value={JACKPOT_STATUS_LABELS[row.original.status]}
            color={JACKPOT_STATUS_COLORS[row.original.status]}
          />
        ),
      },
      {
        Header: localized('jackpot.settlement'),
        accessor: 'settlement',
        Cell: ({ row }) => {
          // Set unsettled status if settlement is not provided
          const settlementStatus = row.original.settlement
            ? row.original.settlement
            : JACKPOT_SETTLEMENT_STATUS.UNSETTLED;

          return (
            <TextCell
              value={JACKPOT_SETTLEMENT_STATUS_LABELS[settlementStatus]}
              color={JACKPOT_SETTLEMENT_STATUS_COLORS[settlementStatus]}
            />
          );
        },
      },
      {
        Header: localized('jackpot.betslipHistory.win'),
        accessor: 'userWinAmount',
        Cell: ({ row }) => (
          <TextCell
            value={`${formatNumberToStringWithSpaces(
              row.original.userWinAmount,
            )} ${currencySymbol}`}
          />
        ),
      },
      {
        Header: '',
        accessor: 'icon',
        Cell: renderIconOuterCell,
      },
    ],
    [openRows],
  ) as Column<JackpotBetsHistory>[];

  return {
    columns,
    openRows,
    getOpenRowsValue,
    handleOuterTableRowClick,
    renderExpandedRowContent,
  };
};
