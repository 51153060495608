import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionRoot,
  MultiAccordionTrigger,
} from '~components/molecules/MultiAccordion';
import { TopEvent } from '~components/molecules/PrematchMenu/SportMenu/TopEvent';
import { useTopEventsSocket } from '~socket-service/hooks/useTopEventsSocket/useTopEventsSocket';

import { LIVE_GROUP_COMPONENTS } from '../constants';
import { useLiveMenuGroups } from '../hooks/useLiveMenuGroups';

import { LiveFavoriteEventGroup } from './LiveFavoriteEventGroup';

export const LiveMenuGroups = () => {
  const { data: topLiveEvents } = useTopEventsSocket({ isLive: true });
  const {
    groupsMemo,
    groupCountsMemo,
    openLiveGroups,
    liveGroupsMemo,
    liveMenuSports: sports,
    handleOnValueChange,
  } = useLiveMenuGroups({ topLiveEvents });

  if (!groupsMemo.length) {
    return null;
  }

  return (
    <>
      <Box flexCol css={{ backgroundColor: '$primaryOne' }}>
        {groupsMemo.map(({ id, name, icon: Icon, label }) => {
          if (name == LIVE_GROUP_COMPONENTS.TOP_EVENT) {
            return <TopEvent key={id} count={groupCountsMemo[name]} />;
          }

          return (
            <MultiAccordionRoot
              key={id}
              type="multiple"
              role={MULTI_ACCORDION_ROLES.PARENT}
              value={openLiveGroups}
              onValueChange={handleOnValueChange}
            >
              <MultiAccordionItem
                value={id.toString()}
                role={MULTI_ACCORDION_ROLES.PARENT}
              >
                <MultiAccordionTrigger
                  title={label}
                  iconComponent={
                    <Box
                      css={{
                        color: '$textSecondaryOne',
                        '&:hover': {
                          color: '$textPrimaryOne',
                        },
                      }}
                    >
                      <Icon />
                    </Box>
                  }
                  css={{
                    '& .text': {
                      fontSize: '$16',
                      lineHeight: '$28',
                    },
                  }}
                  count={groupCountsMemo[name]}
                  role={MULTI_ACCORDION_ROLES.PARENT}
                />
                {groupCountsMemo[name] && (
                  <MultiAccordionContent role={MULTI_ACCORDION_ROLES.PARENT}>
                    <LiveFavoriteEventGroup
                      sports={sports}
                      sportGroupsMemo={liveGroupsMemo}
                    />
                  </MultiAccordionContent>
                )}
              </MultiAccordionItem>
            </MultiAccordionRoot>
          );
        })}
      </Box>
      <Separator shrinkOut={0} verticalSpace={0} />
    </>
  );
};
