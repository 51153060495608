import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Message } from '~components/atoms/Message';
import {
  StyledUserTabContentSeparator,
  StyledUserTabContentTitle,
  StyledUserTabContentWrapper,
} from '~components/atoms/TabContent';
import { MESSAGE_TYPES } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

import { PersonalDetailsForm } from '../PersonalDetailsForm';

import { AccountVerificationContent } from './components/AccountVerificationContent';

export const PersonalDetailsContent = () => {
  const { localized } = useTranslation();
  const { personalProfileUpdateError } = useAppSelector(
    (state) => state.userUIState,
  );

  const [isPersonalDetailsChanged, setIsPersonalDetailsChanged] =
    useState(false);

  return (
    <Box flexCol gap={3}>
      <StyledUserTabContentWrapper>
        <StyledUserTabContentTitle>
          {isPersonalDetailsChanged ? (
            <Message
              type={
                personalProfileUpdateError
                  ? MESSAGE_TYPES.ERROR
                  : MESSAGE_TYPES.SUCCESS
              }
              text={
                personalProfileUpdateError
                  ? localized(
                      `userProfile.personalProfileInfo.accountSettings.changeSettingsFailed.${personalProfileUpdateError}`,
                    )
                  : localized(
                      'userProfile.personalProfileInfo.accountSettings.changeSettings.accountChangedTitle',
                    )
              }
            />
          ) : (
            localized('userProfile.personalProfileInfo.details')
          )}
        </StyledUserTabContentTitle>
        <StyledUserTabContentSeparator />
        <PersonalDetailsForm
          onPersonalDetailsChange={setIsPersonalDetailsChanged}
        />
      </StyledUserTabContentWrapper>
      <AccountVerificationContent />
    </Box>
  );
};
