import React from 'react';
import * as ToggleGroupUI from '@radix-ui/react-toggle-group';
import { CSS, styled } from 'stitches.config';

import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { BasicButton } from '~components/atoms/Button';
import { SWIPE_COMPONENT_TYPES } from '~components/atoms/SwipeComponent';
import { Text } from '~components/atoms/Typography';
import { useAppDispatch, useAppSelector } from '~store';
import { addStake, removeStakeBySelection } from '~store/slices/betslipSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
import { isQuickBet } from '~utils/isQuickBet';

export const LeagueToggleGroup = styled(ToggleGroupUI.Root, {
  borderRadius: '$8',
  display: 'flex',
  width: '100%',
  gap: '1px',
});

export const LeagueToggleGroupButton = styled(BasicButton, {
  display: 'flex',
  border: 'none',
  outline: 'none',
  fontFamily: 'inherit',
  alignItems: 'center',
  flexGrow: '1',
  color: '$textButtons',
  fontSize: '$xs',
  lineHeight: '$20',
  backgroundColor: '$primaryTwo',
  height: '52px',
  width: '84px',
  position: 'relative',
  overflow: 'visible',
  '@md': {
    width: '52px',
  },
  '@lg': {
    width: '60px',
  },
  '&:first-child': {
    borderRadius: '$8 0 0 $8',
  },
  '&:last-child': {
    borderRadius: '0 $8 $8 0',
  },
  '&[data-state=on]': {
    backgroundColor: '$accentPrimaryOne',
    '& span': {
      color: '$textButtons',
    },
  },
  '&[data-state=off]:hover': {
    backgroundColor: '$hoverPrimary',
  },
  variants: {
    total: {
      true: {
        backgroundColor: '$secondaryOne',
        pointerEvents: 'none',
        '@xs': {
          width: '52px',
        },
      },
    },
  },
});

const StyledToggleGroupItem = styled(ToggleGroupUI.Item, {
  position: 'relative',
  justifyContent: 'center',
});

interface LeagueToggleGroupItemProps {
  selectionId: string;
  children: React.ReactNode;
  isFirstEvent: boolean;
  eventData: SportEventItem;
  marketId: string;
  disabled?: boolean;
  eventId?: string;
  name?: string;
  total?: boolean;
  css?: CSS;
}

export const LeagueToggleGroupItem = ({
  selectionId,
  children,
  isFirstEvent,
  eventData,
  name,
  eventId,
  marketId,
  total = false,
  disabled = false,
  css,
}: LeagueToggleGroupItemProps) => {
  const dispatch = useAppDispatch();
  const { quickBet } = useAppSelector((state) => state.settings);
  const { eventsMap } = useAppSelector((state) => state.events);
  const betslipEvents = useAppSelector((state) => state.betslip.events);
  const isSelectedItem = betslipEvents.some(
    (event) =>
      event.eventId === eventId &&
      event.marketId === marketId &&
      event.selectionId === selectionId,
  );

  const handleBetClick = () => {
    if (!disabled && marketId && eventId && selectionId) {
      if (!isSelectedItem) {
        const eventStatus = eventsMap[eventId]?.status;

        if (
          betslipEvents.length === 0 &&
          eventStatus &&
          isQuickBet(eventStatus, quickBet)
        ) {
          dispatch(setIsSwipeOpen(SWIPE_COMPONENT_TYPES.QUICK_BET));
        }

        dispatch(addStake({ eventId, marketId, selectionId, eventData }));
      } else {
        dispatch(removeStakeBySelection(selectionId));
      }
    }
  };

  return (
    <StyledToggleGroupItem
      value={selectionId}
      asChild
      disabled={disabled}
      css={css}
    >
      <LeagueToggleGroupButton
        onClick={handleBetClick}
        css={{
          cursor: disabled ? 'default' : 'pointer',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        total={total}
        data-state={isSelectedItem ? 'on' : 'off'}
      >
        {children}
        {isFirstEvent && (
          <Box
            as="span"
            css={{
              position: 'absolute',
              top: '-40px',
              left: `50%`,
              transform: 'translateX(-50%)',
              width: 'max-content',
              pointerEvents: 'none',
            }}
          >
            <Text
              level="16-20"
              as="p"
              css={{
                '@xs_sm': {
                  fontSize: '$12 !important',
                  color: '$textPrimaryOne !important',
                },
              }}
            >
              {name}
            </Text>
          </Box>
        )}
      </LeagueToggleGroupButton>
    </StyledToggleGroupItem>
  );
};
