import { SportEventItem } from '~api/sportEvent/types';
import {
  SPORT_BETSLIP_TYPE_CODES,
  STAKE_TAX_PAYER,
} from '~components/molecules/Betslip/constants';
import { BETSLIP_STATUS, EVENT_STATUS } from '~constants/common';
import { SportIconKey } from '~constants/sport';
import { JackpotStakes } from '~types/jackpot';

interface Selection {
  id: string;
}

interface BetSlip {
  // We can have either stake (if separateBonusBalance is false) or realStake with bonusStake (if true)
  stake?: number;
  realStake?: number;
  bonusStake?: number;
  selections: Selection[];
}

export interface BetSlipInput {
  correlationId: string;
  betSlips: BetSlip[] | BetSlip;
  oddChangePolicy: number;
  betSlipType?: number;
  systemBetCombinationSize?: number;
}

export enum BETSLIP_TYPE {
  SINGLE = 1,
  MULTIPLE = 2,
  JACKPOT = 3,
  SYSTEM = 4,
}

export interface BetslipOutput {
  balance: number;
  codes?: string[];
  status: number;
}

export interface OpenedBetDetails {
  eventId: string;
  market: string;
  selection: string;
  status: number;
  odd: number;
  startDate: string;
  event: string;
  sport: SportIconKey;
  sportId: number;
  country: string;
  league: string;
  countryCode: string;
}

export interface UserBonusBalance {
  userBonusBalanceId: string;
  title: string;
  stake: number;
  winFromBetSlip: number;
  transferredToRealBalance: number;
}

export type UserBonusBalanceChanges = UserBonusBalance[];

export interface OpenedBet {
  type: SPORT_BETSLIP_TYPE_CODES;
  totalOdd: number;
  status: number;
  stake: number;
  playerId: string;
  playerName: string;
  createdAt: string;
  winAmount: number;
  ip: string;
  ipCountry: string;
  code: string;
  transactionId: string;
  details: OpenedBetDetails[];
  bonusBalanceUsages: UserBonusBalanceChanges;
  cutWin: boolean;
  id: string;
  availableCashout: number;
  possibleWin: number;
  winBoostAmount: number;
  winBoostMultiplier: number;
  originalWinAmount: number;
  possibleIncomeTax: number;
  incomeTaxPercent: number;
  stakeTax: number;
  stakeTaxPercent: number;
  stakeBeforeTax: number;
  realStakeAfterTax: number;
  stakeTaxPayer: STAKE_TAX_PAYER;
}

export type OpenedBets = OpenedBet[];

export interface BetslipHistoryDetails {
  eventId: string;
  event: string;
  startDate: string;
  market: string;
  selection: string;
  sport: SportIconKey;
  sportId: number;
  country: string;
  countryCode: string;
  eventStatus: EVENT_STATUS;
  league: string;
  status: number;
  odd: number;
  extraData?: string;
}

export interface BetslipHistoryItem {
  id: string;
  type: SPORT_BETSLIP_TYPE_CODES;
  totalOdd: number;
  status: number;
  stake: number;
  playerId: string;
  playerName: string;
  createdAt: string;
  bonusBalanceUsages: UserBonusBalanceChanges;
  winBoostAmount: number;
  winBoostMultiplier: number;
  originalWinAmount: number;
  details: BetslipHistoryDetails[];
  winAmount: number;
  code: string;
  transactionId: string;
  possibleWin: number;
  availableCashout: number;
  section: string;
  icon: string;
  systemBetCombinationSize: number | null;
  extraData?: string;
  sportId: number;
  // Taxes
  incomeTax: number;
  possibleIncomeTax: number;
  incomeTaxPercent: number;
  stakeTax: number;
  stakeTaxPercent: number;
  stakeBeforeTax: number;
  realStakeAfterTax: number;
  stakeTaxPayer: STAKE_TAX_PAYER;
}

export interface BetslipHistoryResponse {
  data: BetslipHistoryItem[];
  total: number;
}

export type BetslipHistoryItems = BetslipHistoryItem[];

export interface BetslipHistoryPayload {
  limit?: number;
  page?: number;
  Status?: BETSLIP_STATUS;
  Code?: string;
  DateFrom?: string;
  DateTo?: string;
}

export interface BetslipItem {
  eventId: string;
  marketId: string;
  selectionId: string;
  eventData?: SportEventItem;
}

export type BetslipItems = BetslipItem[];

export interface BonusBalanceChange {
  userBonusBalanceId: string;
  title: string;
  betAmount: number;
}

export interface BalanceChange {
  realBetAmount: number;
  selectionId: string;
  totalPossibleWin: number;
  // Bonuses
  bonusChanges: BonusBalanceChange[];
  winBoostDescriptions: string[];
  matchedBonusRules: number;
  winBoostAmount: number;
  winBoostMultiplier: number;
  originalWinAmount: number;
  // Limits
  betMatchesLimits: boolean;
  maxStakeLimit: number;
  minStakeLimit: number;
  // Taxes
  possibleIncomeTax: number;
  incomeTaxPercent: number;
  stake: number;
  stakeTax: number;
  stakeTaxPercent: number;
  stakeBeforeTax: number;
  realStakeAfterTax: number;
  stakeTaxPayer: STAKE_TAX_PAYER;
}

export interface BalanceChangeResponse {
  isBalanceSufficient: boolean;
  correlationId: string;
  isBonusBalanceSufficient?: boolean;
  balanceChanges: BalanceChange[];
  totalActiveBonusesCount: number;
  systemBetOriginalWinAmount?: number;
  systemBetTotalPossibleWin?: number;
  systemBetWinBoostAmount?: number;
}

export interface Bet {
  sportsId: number[];
  marketsId: number[];
  countriesId: number[];
  leaguesId: number[];
  odds: number[];
  selectionIds: string[];
  stake?: number;
  realStake?: number;
  bonusStake?: number;
}

export type BalanceChangePayload = {
  bets: Bet[];
};

export interface CashOutPayload {
  betSlipId: string;
  cashout: number;
  policy: number;
}

export interface BalancePredictionPayload {
  signal?: AbortSignal;
  bets: Bet[];
  separateBonusBalance?: boolean;
  isSystemBet?: boolean;
  systemBetCombinationSize?: number;
  betSlipType: BETSLIP_TYPE;
}

export interface BetAmount {
  main?: string;
  bonus?: string;
}

export interface JackpotBetPayload {
  jackpotId: string;
  eventSelections: JackpotStakes;
}

export interface JackpotBetResponse {
  balance: number;
}

export interface PayoutInfo {
  stake?: number | null;
  stakeAfterTax?: number | null;
  stakeTax?: number | null;
  stakeTaxPercent?: number | null;
  stakeTaxPayer?: STAKE_TAX_PAYER;
  win?: number | null;
  possibleWin?: number | null;
  possibleIncomeTax?: number | null;
  incomeTaxPercent?: number | null;
}
