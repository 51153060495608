import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useBankSubmitButton } from '~components/molecules/UserProfile/components/Payments/hooks/useBankSubmitButton';
import { useTranslation } from '~hooks/useTranslation';

interface PaymentSubmitButtonProps {
  disabled: boolean;
  onSubmit?: () => void;
  isCrypto?: boolean;
}

export const PaymentSubmitButton = ({
  disabled,
  onSubmit,
  isCrypto = false,
}: PaymentSubmitButtonProps) => {
  const { localized } = useTranslation();
  const { isDisabled, isLoading, handleBankChangeClick } =
    useBankSubmitButton();

  const isButtonDisabled = isCrypto ? disabled : disabled || isDisabled;

  return (
    <Button
      fullWidth
      isLoading={isLoading}
      disabled={isButtonDisabled}
      onClick={onSubmit || handleBankChangeClick}
      css={{
        p: '$2',
        marginTop: '$3',
        '@xs_sm': {
          height: '$7',
          mt: '$1',
        },
      }}
    >
      <Text isButton level="16-24" fontWeight="bold" textAlign="center">
        {localized('payments.continue')}
      </Text>
    </Button>
  );
};
