import {
  useDislikeGameMutation,
  useLaunchGameDemoMutation,
  useLaunchGameRealMutation,
  useLikeGameMutation,
} from '~api/games/gamesApi';

export const useLaunchGameReal = () => {
  const [mutation, { data, error, isLoading }] = useLaunchGameRealMutation();

  return {
    launchGameRealMutation: mutation,
    launchGameRealData: data,
    launchGameRealError: error,
    launchGameRealIsLoading: isLoading,
  };
};

export const useLaunchGameDemo = () => {
  const [mutation, { data, error, isLoading }] = useLaunchGameDemoMutation();

  return {
    launchGameDemoMutation: mutation,
    launchGameDemoData: data,
    launchGameDemoError: error,
    launchGameDemoIsLoading: isLoading,
  };
};

export const useLikeGame = () => {
  const [mutation, { data, error, isLoading }] = useLikeGameMutation();

  return {
    likeGameMutation: mutation,
    likeGameData: data,
    likeGameError: error,
    likeGameIsLoading: isLoading,
  };
};

export const useDislikeGame = () => {
  const [mutation, { data, error, isLoading }] = useDislikeGameMutation();

  return {
    dislikeGameMutation: mutation,
    dislikeGameData: data,
    dislikeGameError: error,
    dislikeGameIsLoading: isLoading,
  };
};
