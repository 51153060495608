import { ReactNode } from 'react';

import { NavigationLink } from '~components/atoms/NavigationLink';
import { QUERY_PARAMS, ROUTE_TAB_NAMES } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { SPORT_MENUS } from '~types/sportMenus';
import { buildSearchQuery } from '~utils/url';

interface EventNavigationLinkProps {
  eventId: string;
  children: ReactNode;
  sportId?: number;
  countryId?: number;
  leagueId?: number;
  isLive?: boolean;
  onClick?: () => void;
}

export const EventNavigationLink = ({
  children,
  eventId,
  sportId,
  countryId,
  leagueId,
  isLive,
  onClick,
}: EventNavigationLinkProps) => {
  const {
    sportId: sportIdParam,
    countryId: countryIdParam,
    leagueId: leagueIdParam,
    nextHour,
    menu: activeMenu,
  } = useQueryParams();

  return (
    <NavigationLink
      onClick={onClick}
      to={{
        pathname: ROUTE_TAB_NAMES.BASE,
        search: buildSearchQuery({
          [QUERY_PARAMS.SPORT_ID]: sportId || sportIdParam || '',
          [QUERY_PARAMS.COUNTRY_ID]: countryId || countryIdParam || undefined,
          [QUERY_PARAMS.LEAGUE_ID]: leagueId || leagueIdParam || undefined,
          [QUERY_PARAMS.MENU]:
            activeMenu || (isLive ? SPORT_MENUS.LIVE : SPORT_MENUS.PREMATCH),
          [QUERY_PARAMS.EVENT_ID]: eventId || undefined,
          nextHour: nextHour || undefined,
        }),
      }}
      ellipsis
    >
      {children}
    </NavigationLink>
  );
};
