import { useMemo } from 'react';

import { Transaction, Transactions } from '~api/transaction/types';
import { useTranslation } from '~hooks/useTranslation';

import { AmountCell } from '../TransactionHistoryTable/AmountCell';
import { BalanceCell } from '../TransactionHistoryTable/BalanceCell';
import { DateIdCell } from '../TransactionHistoryTable/DateIdCell';
import { StatusCell } from '../TransactionHistoryTable/StatusCell';
import { TypeCell } from '../TransactionHistoryTable/TypeCell';

export const useTransactionHistoryTable = (
  data: Transactions,
  isAllTransactions?: boolean,
) => {
  const { localized } = useTranslation();

  const columnsMemo = useMemo(() => {
    const columns = [
      {
        Header: localized('transactionHistory.table.dateAndId'),
        accessor: 'id' as keyof Transaction,
        Cell: DateIdCell,
      },
      {
        Header: localized('transactionHistory.table.type'),
        accessor: 'type' as keyof Transaction,
        Cell: TypeCell,
      },
      {
        Header: localized('transactionHistory.table.amount'),
        accessor: 'amount' as keyof Transaction,
        Cell: AmountCell,
      },
      {
        Header: localized('transactionHistory.table.status'),
        accessor: 'status' as keyof Transaction,
        Cell: StatusCell,
      },
      {
        Header: localized('transactionHistory.table.balanceBefore'),
        accessor: 'balanceBefore' as keyof Transaction,
        Cell: BalanceCell,
      },
      {
        Header: localized('transactionHistory.table.finalBalance'),
        accessor: 'balanceAfter' as keyof Transaction,
        Cell: BalanceCell,
      },
    ];

    return isAllTransactions
      ? columns
      : columns.filter(({ accessor }) => accessor !== 'balanceBefore');
  }, [isAllTransactions]);

  return {
    columns: columnsMemo,
    preparedData: data,
  };
};
