import { StoreSportEvent } from '~types/events';
import { VolleyballExtraData } from '~types/sportExtraData';
import { getVolleyballDetails } from '~utils/sportHelpers';

import { getScores } from '../utils/getScores';

import { useSportScoreboard } from './useSportScoreboard';

export const useVolleyballScoreboard = (
  restEventData: Omit<StoreSportEvent, 'extraData'>,
  parsedExtraData: VolleyballExtraData | null,
) => {
  const {
    localized,
    isMobileOrTablet,
    sportName,
    countryName,
    leagueName,
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    formattedStartDate,
    isMatchStarted,
  } = useSportScoreboard(restEventData, parsedExtraData);

  const { server, periodScores } =
    (parsedExtraData && getVolleyballDetails(parsedExtraData, true)) || {};

  return {
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    server,
    periodScores,
    sportName,
    countryName,
    leagueName,
    scores: getScores({
      gamePeriodName: 'Set',
      localized,
      homeTeamScore,
      awayTeamScore,
      periodScores,
    }),
    isMatchStarted,
    formattedStartDate,
    isMobileOrTablet,
  };
};
