import React from 'react';
import { useLocation } from 'react-router-dom';

import { InPlaySportEvent } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { QUERY_PARAMS } from '~constants/common';
import { SportEventsMainData } from '~types/events';
import { getParamFromQuery } from '~utils/url';

import { LiveEventCard } from './LiveEventCard';

export interface EventListProps {
  events: SportEventsMainData;
}

export const LiveFavoriteEventListMenu = ({ events }: EventListProps) => {
  const { search } = useLocation();
  const activeEvent = getParamFromQuery(search, QUERY_PARAMS.EVENT_ID);

  return (
    <Box flexCol gap={1}>
      {events.map((event) => {
        const { sportId, countryId, leagueId, id, countryName, leagueName } =
          event;

        if (!leagueId) {
          return null;
        }

        return (
          <LiveEventCard
            key={id}
            eventData={event as unknown as InPlaySportEvent}
            sportId={sportId}
            countryId={countryId}
            leagueId={leagueId}
            eventId={id}
            isActive={activeEvent ? id === activeEvent : false}
            countryName={countryName}
            leagueName={leagueName}
            isFavoriteEvents
            showLeagues
          />
        );
      })}
    </Box>
  );
};
