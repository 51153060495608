import React from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

interface BetslipFooterInactiveButtonProps {
  isQuickBet?: boolean;
  onClick: () => void;
  text?: string;
}

export const StyledBetslipCloseButton = styled(Button, {
  width: '100%',
  padding: '10px 0 !important',
  '@xs': {
    padding: '6px 0 !important',
  },
});

export const StyledBetslipCloseText = styled(Text, {
  fontSize: '18px',
  lineHeight: '24px',
  '@sm': {
    fontSize: '14px',
    lineHeight: '20px',
  },
  '@xs': {
    fontSize: '14px',
    lineHeight: '20px',
  },
});

export const BetslipFooterInactiveButton = ({
  isQuickBet = false,
  onClick,
  text = 'buttons.close',
}: BetslipFooterInactiveButtonProps) => {
  const { localized } = useTranslation();

  const SectionSeparator = () => (
    <Separator
      verticalSpace={0}
      shrinkOut={4}
      css={{ backgroundColor: '$primaryOne' }}
    />
  );

  return (
    <Box flexCol gap={3}>
      <SectionSeparator />
      <StyledBetslipCloseButton fullWidth variant="secondary" onClick={onClick}>
        <StyledBetslipCloseText>{localized(text)}</StyledBetslipCloseText>
      </StyledBetslipCloseButton>
      {isQuickBet && <SectionSeparator />}
    </Box>
  );
};
