import { StoreSportEvent } from '~types/events';
import { BasketballExtraData } from '~types/sportExtraData';
import { getBasketballDetails } from '~utils/sportHelpers';

import { getScores } from '../utils/getScores';

import { useSportScoreboard } from './useSportScoreboard';

export const useBasketballScoreboard = (
  restEventData: Omit<StoreSportEvent, 'extraData'>,
  parsedExtraData: BasketballExtraData | null,
) => {
  const {
    localized,
    isMobileOrTablet,
    sportName,
    countryName,
    leagueName,
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    isMatchStarted,
    formattedStartDate,
  } = useSportScoreboard(restEventData, parsedExtraData);

  const { time, periodScores } =
    (parsedExtraData && getBasketballDetails(parsedExtraData, false)) || {};

  return {
    formattedMatchStatus,
    homeTeamScore,
    awayTeamScore,
    homeTeamName,
    awayTeamName,
    time,
    periodScores,
    sportName,
    countryName,
    leagueName,
    scores: getScores({
      gamePeriodName: 'Quarter',
      localized,
      periodScores,
    }),
    isMatchStarted,
    formattedStartDate,
    isMobileOrTablet,
  };
};
