import { useEffect } from 'react';

import { useLazyGetBetslipByCode } from '~api/betslip/betslipQueries';
import { useGetBetslipAddedEvents } from '~api/sportEvent/sportEventMutations';
import { SPORT_BETSLIP_TYPES_MAP } from '~components/molecules/Betslip/constants';
import { EVENT_STATUS } from '~constants/common';
import { useReconnectedRequest } from '~hooks/useReconnectedRequest';
import { useAppDispatch, useAppSelector } from '~store';
import {
  resetBetslipChanged,
  setBetslipProcessed,
  setEventsData,
  setReloadBetslip,
  setShareCode,
  setStakes,
  setStakeType,
} from '~store/slices/betslipSlice';

export const useInitialBetslipState = () => {
  const { events, shareCode, reloadBetslip } = useAppSelector(
    (state) => state.betslip,
  );
  const dispatch = useAppDispatch();

  const {
    getBetslipAddedEventsMutation,
    getBetslipAddedEventsData: betslipAddedEventsData,
  } = useGetBetslipAddedEvents();
  const { lazyGetBetslipByCodeQuery, lazyGetBetslipByCodeData } =
    useLazyGetBetslipByCode();

  const handleLoadDefaultBetslip = () => {
    dispatch(setBetslipProcessed(false));

    if (events.length) {
      getBetslipAddedEventsMutation(events);
    }
  };

  useEffect(() => {
    handleLoadDefaultBetslip();
  }, []);

  useEffect(() => {
    if (reloadBetslip) {
      handleLoadDefaultBetslip();
      dispatch(setReloadBetslip(false));
    }
  }, [reloadBetslip]);

  useReconnectedRequest(handleLoadDefaultBetslip);

  useEffect(() => {
    if (betslipAddedEventsData) {
      const filteredEvents = events.filter(({ eventId }) => {
        return betslipAddedEventsData.find(
          ({ id, status, markets }) =>
            id === eventId &&
            [
              EVENT_STATUS.ACTIVE,
              EVENT_STATUS.IN_PROGRESS,
              EVENT_STATUS.ABOUT_TO_START,
            ].includes(status) &&
            markets.length > 0,
        );
      });

      dispatch(setEventsData(betslipAddedEventsData));
      dispatch(setStakes(filteredEvents));
    }

    dispatch(resetBetslipChanged());
  }, [betslipAddedEventsData]);

  useEffect(() => {
    if (shareCode && shareCode.length && !lazyGetBetslipByCodeData) {
      lazyGetBetslipByCodeQuery({ code: shareCode });
      dispatch(setShareCode(null));
    }
  }, [shareCode, lazyGetBetslipByCodeData]);

  useEffect(() => {
    if (lazyGetBetslipByCodeData) {
      const { data, type } = lazyGetBetslipByCodeData;

      dispatch(setEventsData(data));
      dispatch(setStakeType(SPORT_BETSLIP_TYPES_MAP[type]));
      const eventsArray = data.map((betItem) => {
        const { id, markets } = betItem;
        const [market] = markets;
        const [selection] = market?.selections || [];

        return {
          eventId: id,
          marketId: market?.id || '',
          selectionId: selection?.id || '',
        };
      });

      dispatch(setStakes(eventsArray));
    }
  }, [lazyGetBetslipByCodeData]);
};
