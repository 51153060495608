import { useEffect, useRef, useState } from 'react';

import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { SportEventItem } from '~api/sportEvent/types';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoriteInplayEvents } from '~store/slices/liveGroupsSlice';

export const useInplayFavoriteEvents = (eventData?: SportEventItem) => {
  const dispatch = useAppDispatch();
  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();
  const [isEventFavorite, setIsEventFavorite] = useState(false);
  const { favoriteInplayEvents = [] } = useAppSelector(
    (state) => state.liveGroupsState,
  );
  const favoriteInplayEventsRef = useRef(favoriteInplayEvents);

  const { id: eventId } = eventData || {};

  useEffect(() => {
    favoriteInplayEventsRef.current = favoriteInplayEvents;
  }, [favoriteInplayEvents]);

  const handleFavoriteInplayEventClick = async () => {
    if (!eventId) return;

    const isFavorite = !!favoriteInplayEventsRef.current.find(
      (event) => event.id === eventId,
    );

    try {
      if (isFavorite) {
        await deleteFavoriteEventMutation({
          eventId,
        });
        dispatch(
          setFavoriteInplayEvents(
            favoriteInplayEventsRef.current.filter(
              (favoriteEvent) => favoriteEvent.id !== eventId,
            ),
          ),
        );
        setIsEventFavorite(false);
      } else {
        await addFavoriteEventMutation({
          eventId,
        });

        if (eventData) {
          dispatch(
            setFavoriteInplayEvents([
              ...favoriteInplayEventsRef.current,
              eventData,
            ]),
          );
        }

        setIsEventFavorite(true);
      }
    } catch (e) {
      console.error('Failed to handle favorite live event: ', e);
    }
  };

  useEffect(() => {
    const isEventFavorite = !!favoriteInplayEventsRef.current.find(
      (event) => event.id === eventId,
    );

    setIsEventFavorite(isEventFavorite);
  }, [favoriteInplayEventsRef.current]);

  return { handleFavoriteInplayEventClick, isEventFavorite };
};
