import React from 'react';

import { useTranslation } from '~hooks/useTranslation';

import { AuthTabsList } from '../AuthTabs';

export const enum RESET_PASSWORD_TABS {
  PROVIDE_NUMBER = 'PROVIDE_NUMBER',
  VERIFY_NUMBER = 'VERIFY_NUMBER',
  ENTER_NEW_PASSWORD = 'ENTER_NEW_PASSWORD',
}

export const ResetPasswordTabs = [
  RESET_PASSWORD_TABS.PROVIDE_NUMBER,
  RESET_PASSWORD_TABS.VERIFY_NUMBER,
  RESET_PASSWORD_TABS.ENTER_NEW_PASSWORD,
];

export const ResetPasswordTabList = () => {
  const { localized } = useTranslation();

  return (
    <AuthTabsList
      tabs={ResetPasswordTabs}
      ariaLabel={localized('resetPassword.ariaLabel')}
    />
  );
};
