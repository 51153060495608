import { appTheme } from 'stitches.config';

import { THEMES } from '~constants/ui';

export const getTheme = (): THEMES => {
  const partnerName = import.meta.env.VITE_PARTNER_NAME;

  document.documentElement.classList.add(appTheme.className);

  return partnerName;
};
