import React from 'react';

import { ROUTE_TAB_NAMES } from '~constants/common';
import { useMedia } from '~hooks';
import useIsInIframe from '~hooks/useIsInIframe';
import { useAppSelector } from '~store';
import { selectIsCallbackAvailable } from '~store/slices/selectors/settings';

import { Header } from './Header';
import { IframeHeader } from './IframeHeader';

interface HeaderComponentProps {
  path: ROUTE_TAB_NAMES;
}

export const HeaderComponent = ({ path }: HeaderComponentProps) => {
  const isInFrame = useIsInIframe();
  const { isMobileOrTablet } = useMedia();
  const isCallbackAvailable = useAppSelector(selectIsCallbackAvailable);

  const isBetslip = path === ROUTE_TAB_NAMES.BETSLIP;

  return isInFrame ? (
    <IframeHeader />
  ) : (
    <Header
      showMenu={!isBetslip}
      showSettings={!isMobileOrTablet}
      showCloseButton={isBetslip}
      showContactUs={!isBetslip && isCallbackAvailable}
    />
  );
};
