import { getBackendUrl } from '~api/common/baseQuery';

export enum SIGNAL_R_SOCKET_NAMES {
  INPLAY = 'inplay',
  NOTIFICATIONS = 'notifications',
}

export const SIGNAL_R_SOCKET_CONNECTED_STATUS = 'Connected';

export const SIGNAL_R_SOCKET_ENDPOINTS = {
  [SIGNAL_R_SOCKET_NAMES.INPLAY]: 'notifications',
  [SIGNAL_R_SOCKET_NAMES.NOTIFICATIONS]: 'hubs/notification',
};

export const SOCKET_URLS = {
  [SIGNAL_R_SOCKET_NAMES.INPLAY]: getBackendUrl(
    import.meta.env.VITE_BACKEND_SUBSCRIPTIONS_URL as string,
  ),
  [SIGNAL_R_SOCKET_NAMES.NOTIFICATIONS]: getBackendUrl(
    import.meta.env.VITE_BACKEND_NOTIFICATION_URL as string,
  ),
};

export enum SIGNAL_R_SOCKET_MESSAGES {
  BONUS_MESSAGE_KEY = 'CheckBalanceChangesOnBet',
  BET_STOP_MESSAGE = 'OnEventBetStop',
  SUBSCRIBE_EVENTS_MESSAGE = 'SubscribeEventMarkets',
  MARKET_UPDATE_MESSAGE = 'OnEventMarketUpdate',
  MARKET_STATUS_UPDATE = 'OnEventMarketManualStatusUpdate',
  MARKETS_UPDATE_MESSAGE = 'OnEventMarketsUpdate',
  EXTRA_DATA_MESSAGE = 'OnExtraDataUpdate',
  MARKET_COUNT_UPDATE_MESSAGE = 'OnEventMarketCountUpdate',
  SUBSCRIBE_FIXTURE_MESSAGE = 'SubscribeFixture',
  UNSUBSCRIBE_FIXTURE_MESSAGE = 'UnsubscribeFixture',
  SUBSCRIBE_INPLAY_MENU = 'SubscribeInPlay',
  UNSUBSCRIBE_INPLAY_MENU = 'UnsubscribeInPlay',
  SUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS = 'SubscribeEventMarketByMarketId',
  UNSUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS = 'UnsubscribeEventMarketByMarketId',
  ON_INPLAY_REMOVE_EVENT = 'OnInPlayRemove',
  ON_INPLAY_ADD_EVENT = 'OnInPlayAdd',
  ON_PAYMENT_STATUS_UPDATE = 'ReceivePaymentStatusUpdate',
  ON_BALANCE_UPDATE = 'ReceiveBalanceUpdate',
  ON_FREE_BET_BALANCE_UPDATE = 'ReceiveFreeBetBalanceUpdate',
  ON_RECEIVE_AUTH_RESPONSE = 'ReceiveAuthResponse',
}
