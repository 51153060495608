import { useEffect, useState } from 'react';

import { useQueryParams } from '~hooks/useQueryParams';
import { startTokenRefreshScheduler } from '~services/auth/tokenRefreshScheduler';
import { useAppDispatch } from '~store';
import { login, logout } from '~store/slices/userSlice';
import { setAuthCookiesData } from '~utils/cookies';

export const useIframeAuthorization = () => {
  const dispatch = useAppDispatch();

  const [iframeToken, setIframeToken] = useState<string | null>(null);
  const { token } = useQueryParams();

  useEffect(() => {
    if (token) {
      setIframeToken(token);
    }

    const handleMessage = (event: MessageEvent) => {
      if (!event.data || token) return;

      setIframeToken(event.data.token);
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!iframeToken) return;

    const loginWithIframeToken = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_BACKEND_IFRAME_TOKEN_VERIFY,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: iframeToken }),
          },
        );

        if (response.status === 200) {
          const { token, refreshToken, expiration } = await response.json();

          setAuthCookiesData({
            token,
            refreshToken,
            expiration,
          });
          dispatch(login());
          startTokenRefreshScheduler();
        } else {
          dispatch(logout());
        }
      } catch (error) {
        console.error('Error verifying token:', error);
      }
    };

    loginWithIframeToken();
  }, [iframeToken]);
};
