import React from 'react';

import { AuthTemplate } from '~components/molecules/Authentication/components/AuthTemplate';

import { useEmailTokenVerify } from './useEmailTokenVerify';
import { VerifyEmailTokenContent } from './VerifyEmailTokenContent';

export const VerifyEmailToken = () => {
  const {
    isSuccess,
    isLoading,
    isMobileOrTablet,
    verifyEmailTokenData,
    handleModalClose,
  } = useEmailTokenVerify();

  return isMobileOrTablet ? (
    <AuthTemplate>
      <VerifyEmailTokenContent
        isSuccess={isSuccess}
        isLoading={isLoading}
        handleModalClose={handleModalClose}
        verifyEmailTokenData={verifyEmailTokenData}
      />
    </AuthTemplate>
  ) : (
    <VerifyEmailTokenContent
      isSuccess={isSuccess}
      isLoading={isLoading}
      handleModalClose={handleModalClose}
      verifyEmailTokenData={verifyEmailTokenData}
    />
  );
};
