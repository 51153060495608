import { useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { SkeletonFull } from '~components/atoms/Skeletons';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import {
  SPORT_GROUP_COMPONENTS,
  SPORT_TAB_GROUPS,
} from '~components/molecules/PrematchMenu/constants';
import { FavoriteIcon } from '~icons';
import { useTopEventsSocket } from '~socket-service/hooks/useTopEventsSocket/useTopEventsSocket';
import { useAppSelector } from '~store';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import { setIsFavoriteSelected } from '~store/slices/mobileSlice';
import { setMobileBackClicked } from '~store/slices/prematchMenuSlice';
import { SVGElement } from '~types/general';

import { CountryMenuMobile } from '../CountryMenu/CountryMenuMobile';
import { useSportMenuGroupsMobile } from '../hooks/useSportMenuGroupsMobile';
import { useSportMenuMobile } from '../hooks/useSportMenuMobile';
import { SportSliderItem } from '../SportSliderItem/SportSliderItem';

interface SportMenuMobileProps {
  isLoading?: boolean;
}

const favoriteId = 0;

export const SportMenuMobile = ({ isLoading }: SportMenuMobileProps) => {
  const { activeSportTabGroup = SPORT_TAB_GROUPS.ALL, isFavoriteSelected } =
    useAppSelector((state) => state.mobileState);
  const {
    sports = [],
    activeSportId,
    countriesData,
    breadcrumbLabel,
    handleOnSportValueChange,
    dispatch,
  } = useSportMenuMobile(activeSportTabGroup as SPORT_TAB_GROUPS);

  const { data: topSportEvents } = useTopEventsSocket();

  const {
    groups,
    sportGroupsMemo,
    groupComponent: GroupComponent,
    handleOnGroupValueChange,
    localized,
  } = useSportMenuGroupsMobile({ topSportEvents });

  const topGroups = groups.filter(
    ({ name }) =>
      name === SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT ||
      name === SPORT_GROUP_COMPONENTS.TOP_EVENT,
  );

  const favoriteGroups = groups.filter(
    ({ name }) =>
      name === SPORT_GROUP_COMPONENTS.FAVORITE_EVENT ||
      name === SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT,
  );

  const favoritesSliderItem = useMemo(
    () => ({
      name: localized('prematch.favorite'),
      icon: FavoriteIcon as SVGElement,
      id: favoriteId,
      countries: [],
      count: null,
      events: favoriteGroups.reduce(
        (sum, group) => sum + (group.count || 0),
        0,
      ),
    }),
    [favoriteGroups, favoriteId],
  );

  const resSportsArray = favoriteGroups.length
    ? [favoritesSliderItem, ...sports]
    : sports;

  return (
    <Box>
      <Box css={{ mb: '2px', gap: '2px' }} flexCol fullWidth>
        {activeSportTabGroup === SPORT_TAB_GROUPS.TOP && (
          <TouchSlider type="sportGroup" css={{ padding: '0 $1' }}>
            {topGroups.map(({ id, label, name, icon, onClick }) => (
              <SportSliderItem
                key={id}
                label={label
                  .replace('Top ', '')
                  .replace('Favorite ', '')
                  .replace('TOP', '')}
                className={name.toString() === breadcrumbLabel ? 'active' : ''}
                icon={icon as SVGElement}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  } else {
                    handleOnGroupValueChange(
                      name.toString() as SPORT_GROUP_COMPONENTS,
                    );
                  }
                }}
              />
            ))}
          </TouchSlider>
        )}
        {activeSportTabGroup !== SPORT_TAB_GROUPS.TOP && (
          <>
            {isFavoriteSelected ? (
              <Box
                flexRow
                fullWidth
                fullHeight
                alignCenter
                css={{
                  background: '$primaryOne',
                }}
              >
                <TouchSlider>
                  {[favoritesSliderItem].map(({ id, name, icon }) => {
                    return (
                      <SportSliderItem
                        key={id}
                        label={name}
                        icon={icon}
                        className="active"
                        isFavorite={id === favoriteId}
                        onClick={() => {
                          if (id === favoriteId) {
                            dispatch(setIsFavoriteSelected(true));
                            const firstFavoriteGroup = favoriteGroups[0];
                            const firstFavoriteGroupLabel =
                              firstFavoriteGroup?.name || '';

                            dispatch(
                              setBreadcrumbsLabel(firstFavoriteGroupLabel),
                            );
                          }
                        }}
                      />
                    );
                  })}
                </TouchSlider>
                <TouchSlider type="sportGroup">
                  {favoriteGroups.map(({ id, label, name, icon, onClick }) => (
                    <SportSliderItem
                      key={id}
                      label={label
                        .replace('Top ', '')
                        .replace('Favorite ', '')
                        .replace('TOP', '')}
                      className={
                        name.toString() === breadcrumbLabel ? 'active' : ''
                      }
                      icon={icon as SVGElement}
                      onClick={() => {
                        if (onClick) {
                          onClick();
                        } else {
                          handleOnGroupValueChange(
                            name.toString() as SPORT_GROUP_COMPONENTS,
                          );
                        }
                      }}
                    />
                  ))}
                </TouchSlider>
              </Box>
            ) : (
              <TouchSlider>
                {resSportsArray.map(({ id, name, events, icon, count }) => {
                  return (
                    <SportSliderItem
                      key={id}
                      sport={id}
                      label={name}
                      count={events || count || 0}
                      icon={icon as unknown as SVGElement}
                      className={
                        id.toString() === activeSportId ? 'active' : ''
                      }
                      onClick={() => {
                        dispatch(setMobileBackClicked(false));

                        if (id === favoriteId) {
                          dispatch(setIsFavoriteSelected(true));
                          const firstFavoriteGroup = favoriteGroups[0];
                          const firstFavoriteGroupLabel =
                            firstFavoriteGroup?.name || '';

                          dispatch(
                            setBreadcrumbsLabel(firstFavoriteGroupLabel),
                          );
                        } else {
                          handleOnSportValueChange(id.toString());
                        }
                      }}
                    />
                  );
                })}
              </TouchSlider>
            )}
          </>
        )}
      </Box>
      {isLoading && !breadcrumbLabel ? (
        <SkeletonFull />
      ) : (
        <Box
          flexCol
          css={{
            gap: '1px',
            mt: '2px',
          }}
        >
          {breadcrumbLabel ? (
            <GroupComponent
              sports={sports}
              sportGroupsMemo={sportGroupsMemo}
              componentName={breadcrumbLabel as SPORT_GROUP_COMPONENTS}
            />
          ) : (
            <CountryMenuMobile
              sportId={parseInt(activeSportId!)}
              countries={countriesData || []}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
