import { useState } from 'react';

import { UserProfileData } from '~api/user/types';
import { useApplyPromoCode } from '~api/user/userMutations';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';
import { logout } from '~store/slices/userSlice';

import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '../UserProfileDialog/constants';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '../UserProfileDialogMobile';

interface UserProfileSidebarProps {
  profile: UserProfileData;
}

export const useUserProfileSidebar = ({ profile }: UserProfileSidebarProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const [promoCode, setPromoCode] = useState('');
  const dispatch = useAppDispatch();
  const { applyPromoCodeMutation, applyPromoCodeIsLoading } =
    useApplyPromoCode();
  //const { loadBonusBalance } = useLoadBonusBalance();
  const { bonusBalance } = useAppSelector((state) => state.userState);

  const { balance } = profile;

  const handleLogout = () => {
    dispatch(logout());
    dispatch(closeDialog());
  };

  const handleApplyPromoCode = async () => {
    try {
      await applyPromoCodeMutation({ code: promoCode });
    } catch (error) {
      console.error('Unable to apply promo code', error);
    }
  };

  const handleWithdrawClick = () => {
    if (isMobileOrTablet) {
      dispatch(
        setMobileUserProfileDialogState(
          USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
        ),
      );
      dispatch(
        setMobileUserProfileDialogActiveContent(USER_PAYMENT_TABS.WITHDRAW),
      );
    } else {
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.WITHDRAW,
        }),
      );
    }
  };

  const handleDepositClick = () => {
    if (isMobileOrTablet) {
      dispatch(
        setMobileUserProfileDialogState(
          USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
        ),
      );
      dispatch(
        setMobileUserProfileDialogActiveContent(USER_PAYMENT_TABS.DEPOSIT),
      );
    } else {
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.DEPOSIT,
        }),
      );
    }
  };

  return {
    applyPromoCodeIsLoading,
    balance,
    bonusBalance,
    isMobileOrTablet,
    promoCode,
    handleApplyPromoCode,
    handleDepositClick,
    handleLogout,
    handleWithdrawClick,
    localized,
    setPromoCode,
  };
};
