import * as DropdownUI from '@radix-ui/react-dropdown-menu';
import { styled } from 'stitches.config';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '~utils/keyframes';
import { zIndex } from '~utils/zIndex';

export const StyledDropdownTrigger = styled(DropdownUI.Trigger, {
  height: '$6',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '$2',
  color: '$textSecondaryOne',
  '&[data-state=open] .chevron': {
    transform: 'rotate(180deg)',
    color: '$textPrimaryOne',
  },
  '&:hover': {
    color: '$textPrimaryOne',
  },
});

export const StyledDropdownMenuContent = styled(DropdownUI.Content, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  minWidth: '260px',
  backgroundColor: '$primaryTwo',
  borderRadius: '$8',
  p: '$4',
  paddingBottom: '$4',
  border: '1px solid $pressedOne',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: zIndex.dropdown + 100,
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});
