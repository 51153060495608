import React, { useState } from 'react';
import { QRCode } from 'antd';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Loader } from '~components/atoms/Loader';
import { Message } from '~components/atoms/Message';
import { SelectWithLabel } from '~components/atoms/SelectWithLabel';
import { Text } from '~components/atoms/Typography';
import { AmountWithButtons } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/components/AmountWithButtons';
import { PaymentSubmitButton } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/components/PaymentSubmitButton';
import { StyledMessageWrapper } from '~components/molecules/UserProfile/components/Payments/components/PaymentForm/styled.components';
import { useCryptoPaymentForm } from '~components/molecules/UserProfile/components/Payments/hooks/useCryptoPaymentForm';
import { MESSAGE_TYPES } from '~constants/common';
import { useCopyToClipboard, useTranslation } from '~hooks';
import { CopyToClipboardIcon, GrayCrossIcon, GreenCheckIcon } from '~icons';
import { TRANSACTION_STATUS } from '~types/transactions';

const StyledAddressWrapper = styled(Box, {
  padding: '1.5rem',
  background: '$secondaryTwo',
  borderRadius: '$8',
  '@xs_sm': {
    padding: '1rem',
  },
});

const StyledQRCodeWrapper = styled(Box, {
  borderRadius: '$12',
  background: '$primaryOne',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '$2',
  gap: '$1',
  width: 'max-content',
});

export const CryptoPayment = () => {
  const { localized } = useTranslation();

  const {
    coinsAmount,
    min,
    max,
    messageText,
    messageType,
    isLoading,
    isLowerOrIsBigger,
    isAmountBiggerThenBalance,
    isSubmitButtonDisabled,
    isLoadingAddress,
    addressData,
    coinsOptions,
    selectedCoin,
    remainingTime,
    status,
    withdrawAddress,
    isDeposit,
    isMobileOrTablet,
    isAddressEmpty,
    isAddressInvalid,
    handleCoinChange,
    handleResetAddressData,
    onSubmit,
    handleChangeAmount,
    handleChangeWithdrawAddressValue,
    getIcon,
    getTextStatus,
    getAddressIcon,
    handleClearAddress,
    renderLabel,
  } = useCryptoPaymentForm();

  const [addressCopied, setAddressCopied] = useState(false);
  const [amountCopied, setAmountCopied] = useState(false);

  const { handleCopy } = useCopyToClipboard();

  if (isLoadingAddress) {
    return (
      <Loader
        css={{
          color: '$textPrimaryOne',
          '& > svg': {
            width: '28px',
            height: '28px',
          },
        }}
      />
    );
  }

  //todo: separate into anther standalone component
  if (addressData) {
    const { address, payAmount, amount } = addressData;

    return (
      <Box flexCol gap={6} alignCenter>
        <Text level="16-24" textTransform="uppercase" fontWeight={'medium'}>
          {localized('payments.paymentVia', {
            coin: selectedCoin,
          })}
        </Text>
        <StyledAddressWrapper
          css={
            isMobileOrTablet
              ? {
                  flexDirection: 'column-reverse !important',
                }
              : {}
          }
          alignCenter
          flexRow
          gap={6}
        >
          <Box flexCol gap={2}>
            <Box
              flexRow
              alignCenter
              justifyContentBetween
              css={{
                background: '$primaryTwo',
                padding: '6px 12px',
                gap: isMobileOrTablet ? '.5rem' : '2rem',
                borderRadius: '$8',
              }}
            >
              <Text color="textSecondaryOne" level="14-24" noWrap>
                {localized('payments.amountToSend')}:
              </Text>
              <Box flexRow gap={2} alignCenter>
                <Text level="14-24" noWrap>
                  {payAmount} {selectedCoin}
                </Text>
                <Box
                  shouldHover
                  css={{
                    display: 'flex',
                    cursor: 'pointer',
                    color: '$textSecondaryOne',
                    width: '$3',
                  }}
                  onClick={() => {
                    handleCopy(payAmount.toString());
                    setAmountCopied(true);
                    setTimeout(() => {
                      setAmountCopied(false);
                    }, 3000);
                  }}
                >
                  {amountCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
                </Box>
              </Box>
            </Box>

            <Box
              flexRow
              alignCenter
              justifyContentBetween
              css={{
                background: '$primaryTwo',
                padding: '6px 12px',
                gap: '2rem',
                borderRadius: '$8',
              }}
            >
              <Text
                color="textSecondaryOne"
                level="14-24"
                css={{
                  whiteSpace: 'nowrap',
                }}
              >
                {localized('payments.amount')}:
              </Text>
              <Text level="14-24">{amount} USD</Text>
            </Box>

            <Box
              flexRow
              alignCenter
              justifyContentBetween
              css={{
                background: '$primaryTwo',
                padding: '6px 12px',
                gap: '2rem',
                borderRadius: '$8',
              }}
            >
              <Text color="textSecondaryOne" level="14-24" noWrap>
                {localized('payments.address')}:
              </Text>
              <Box flexRow gap={2} alignCenter>
                <Text
                  level="14-24"
                  ellipsis
                  css={{
                    maxWidth: '100px',
                  }}
                >
                  {address}
                </Text>
                <Box
                  shouldHover
                  css={{
                    display: 'flex',
                    cursor: 'pointer',
                    color: '$textSecondaryOne',
                    width: '$3',
                  }}
                  onClick={() => {
                    handleCopy(address);
                    setAddressCopied(true);
                    setTimeout(() => {
                      setAddressCopied(false);
                    }, 3000);
                  }}
                >
                  {addressCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
                </Box>
              </Box>
            </Box>

            <Box
              flexRow
              alignCenter
              justifyContentBetween
              css={{
                background: '$primaryTwo',
                padding: '6px 12px',
                gap: '2rem',
                borderRadius: '$8',
              }}
            >
              <Text
                color="textSecondaryOne"
                level="14-24"
                css={{
                  whiteSpace: 'nowrap',
                }}
              >
                {localized('payments.status')}:
              </Text>
              <Box flexRow gap={2} alignCenter>
                <Text
                  level="14-24"
                  color={
                    status === TRANSACTION_STATUS.SUCCESS
                      ? 'greenPrimary'
                      : status === TRANSACTION_STATUS.FAILED || !remainingTime
                        ? 'redPrimary'
                        : 'yellowPrimary'
                  }
                >
                  {localized(getTextStatus())}
                </Text>
                {getIcon()}
              </Box>
            </Box>
          </Box>
          <StyledQRCodeWrapper>
            <Box
              css={{
                margin: '$1',
                borderRadius: '$8',
                background: 'white',
                overflow: 'hidden',
                '@xs_sm': {
                  margin: '$2',
                },
              }}
            >
              <QRCode size={isMobileOrTablet ? 250 : 144} value={address} />
            </Box>

            <Text level="12-16" color="yellowPrimary">
              {remainingTime
                ? localized('payments.validUntil', {
                    validUntil: remainingTime,
                  })
                : localized('payments.expired')}
            </Text>
          </StyledQRCodeWrapper>
        </StyledAddressWrapper>
        {!remainingTime && (
          <Button
            fullWidth
            onClick={handleResetAddressData}
            css={{
              p: '$2',
              marginTop: '$3',
              '@xs_sm': {
                height: '$7',
                mt: '$1',
              },
            }}
          >
            <Text isButton level="16-24" fontWeight="bold" textAlign="center">
              {localized('payments.continue')}
            </Text>
          </Button>
        )}
      </Box>
    );
  }

  return (
    <>
      <Text level="14-20">{localized('payments.payCurrency')}</Text>
      <Box
        fullWidth
        css={{
          borderRadius: '$8',
          backgroundColor: '$secondaryTwo',
          padding: '$2',
        }}
      >
        <SelectWithLabel
          labelRender={renderLabel}
          disabled={!isDeposit}
          placeholder="Select"
          value={selectedCoin}
          setValue={handleCoinChange}
          options={coinsOptions}
          style={{
            '.ant-select-selection-placeholder, .ant-select-selection-item': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
          dropdownStyles={{
            '.ant-select-item': {
              backgroundColor: '$primaryTwo !important',
              borderBottom: '1px solid $secondaryOne !important',
              '& > div': {
                justifyContent: 'flex-start !important',
              },
            },
            '.ant-select-item-option-content': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        />
      </Box>

      {isLoading ? (
        <Loader
          css={{
            color: '$textPrimaryOne',
            '& > svg': {
              width: '28px',
              height: '28px',
            },
          }}
        />
      ) : (
        <Box flexCol alignCenter justifyCenter fullWidth gap={2}>
          {!isDeposit && (
            <>
              <Text level="14-20">{localized('payments.address')}</Text>
              <Box
                fullWidth
                css={{
                  marginBottom: isAddressInvalid ? '16px' : 0,
                  position: 'relative',
                }}
              >
                <Input
                  value={withdrawAddress}
                  onChange={handleChangeWithdrawAddressValue}
                  placeholder={localized('payments.enterAddress')}
                  css={{
                    textAlign: 'center',
                    padding: '0 $9',
                    ...(isAddressInvalid
                      ? {
                          boxShadow: '$elementErrorBorder',
                          '&:focus-within': {
                            boxShadow: '$elementErrorBorder',
                          },
                        }
                      : {}),
                  }}
                />
                <Box
                  flexRow
                  alignCenter
                  gap={2}
                  css={{
                    position: 'absolute',
                    right: '$2',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Box flexRow alignCenter>
                    {getAddressIcon()}
                  </Box>
                  {!isAddressEmpty && (
                    <Box
                      shouldHover
                      flexRow
                      alignCenter
                      css={{
                        color: '$textSecondaryOne',
                      }}
                      onClick={handleClearAddress}
                    >
                      <GrayCrossIcon width={14} height={14} />
                    </Box>
                  )}
                </Box>
                {Boolean(isAddressInvalid) && (
                  <Text
                    level="10-16"
                    color="redPrimary"
                    noWrap
                    css={{
                      position: 'absolute',
                      bottom: '-20px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      textAlign: 'center',
                    }}
                  >
                    {localized('payments.invalidAddress')}
                  </Text>
                )}
              </Box>
            </>
          )}
          <AmountWithButtons
            enableInputDecimals
            withDecimals
            isDeposit={isDeposit}
            amount={coinsAmount}
            onChange={handleChangeAmount}
            isError={isLowerOrIsBigger}
            min={min}
            max={max}
          />
          <PaymentSubmitButton
            disabled={isSubmitButtonDisabled}
            onSubmit={onSubmit}
            isCrypto
          />
          {!!messageText?.length && !isDeposit && !isAmountBiggerThenBalance ? (
            <StyledMessageWrapper>
              <Message
                type={messageType as MESSAGE_TYPES}
                textArray={messageText}
                css={{ mt: '$3' }}
              />
            </StyledMessageWrapper>
          ) : null}
        </Box>
      )}
    </>
  );
};
