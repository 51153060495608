import { Countries, Leagues } from '~api/category/types';
import { ACTION_TYPE, findAddedOrRemovedItem } from '~utils/arrayHelpers';

interface HandleOnValueChangeHelperParams {
  e: string[];
  openOptions: string[];
  existingData: Record<string, Leagues | Countries>;
}

export const handleOnValueChangeHelper = ({
  e,
  openOptions,
  existingData,
}: HandleOnValueChangeHelperParams): {
  item: string;
  shouldFetchData: boolean;
} => {
  const { item = '', action } = findAddedOrRemovedItem(openOptions, e);

  const isItemNotDefaultOpenOptionAndNotInData =
    action === ACTION_TYPE.ADDED && item && !(item in existingData);

  return {
    item: isItemNotDefaultOpenOptionAndNotInData ? item : '',
    shouldFetchData: !!isItemNotDefaultOpenOptionAndNotInData,
  };
};
