import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QUERY_PARAMS } from '~constants/common';
import { SPORT_MENUS } from '~types/sportMenus';

type QueryParamsInterface = Partial<Record<QUERY_PARAMS, string>>;

export interface SportsMenuCounts {
  inplay: number;
  prematch: number;
}

export interface SportsMenuState {
  counts: SportsMenuCounts;
  liveParams: QueryParamsInterface;
  prematchParams: QueryParamsInterface;
  activeMenu: SPORT_MENUS | null;
}

const initialState: SportsMenuState = {
  counts: {
    inplay: 0,
    prematch: 0,
  },
  liveParams: {},
  prematchParams: {},
  activeMenu: SPORT_MENUS.PREMATCH,
};

export const sportsMenuSlice = createSlice({
  name: 'sportsMenu',
  initialState,
  reducers: {
    setCounts: (state, action: PayloadAction<SportsMenuCounts>) => {
      state.counts = action.payload;
    },
    decreaseInplayCount: (state) => {
      state.counts.inplay -= 1;
    },
    increaseInplayCount: (state) => {
      state.counts.inplay += 1;
    },
    setActiveMenu: (state, action: PayloadAction<SPORT_MENUS>) => {
      state.activeMenu = action.payload;
    },
  },
});

export const {
  setCounts,
  setActiveMenu,
  increaseInplayCount,
  decreaseInplayCount,
} = sportsMenuSlice.actions;

export default sportsMenuSlice.reducer;
