import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { GreenCheckIcon, RedCrossIcon } from '~icons';

interface CompletedContentProps {
  isSuccess: boolean;
  errorMessage: string | null;
  onContinue: () => void;
}

export const CompletedContent = ({
  isSuccess,
  errorMessage,
  onContinue,
}: CompletedContentProps) => {
  const { localized } = useTranslation();

  const getIcon = () => {
    if (isSuccess) {
      return <GreenCheckIcon width={40} height={40} />;
    } else {
      return <RedCrossIcon width={40} height={40} />;
    }
  };

  return (
    <Box
      fullWidth
      flexCol
      alignCenter
      justifyCenter
      gap={2}
      css={{
        p: '28px 73px',
        borderRadius: '$8',
        border: '1px solid $primaryTwo',
      }}
    >
      {getIcon()}
      <Text textTransform="uppercase" level="17-24">
        {localized(`verifyIdNumber.${isSuccess ? 'success' : 'fail'}.title`)}
      </Text>
      <Text textTransform="uppercase" level="14-20">
        {errorMessage
          ? localized(`verifyIdNumber.${errorMessage}`)
          : localized(
              `verifyIdNumber.${isSuccess ? 'success' : 'fail'}.subtitle`,
            )}
      </Text>
      <Button variant="secondary" fullWidth onClick={onContinue}>
        {localized('buttons.continue')}
      </Button>
    </Box>
  );
};
