import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BalanceChangePayload,
  BalanceChangeResponse,
  BalancePredictionPayload,
  BetslipHistoryPayload,
  BetslipHistoryResponse,
  BetSlipInput,
  BetslipOutput,
  CashOutPayload,
  JackpotBetPayload,
  JackpotBetResponse,
  OpenedBets,
} from '~api/betslip/types';
import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { BetslipByCode } from '~api/sportEvent/types';
import { BETSLIP_EVENT_ERRORS } from '~constants/betslip';
import {
  resetBetslipChanged,
  setBetErrorCode,
  setBetErrorEvent,
  setBetErrorMessage,
  setBetslipProcessed,
  setBetslipProcessing,
  setBetslipProcessingFailed,
  setLastPlacedBetslipIds,
} from '~store/slices/betslipSlice';
import { buildQueryUrl } from '~utils/buildQueryUrl';

export const betslipApi = createApi({
  reducerPath: 'betslipApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.BETSLIP }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    openBet: builder.mutation<BetslipOutput, BetSlipInput>({
      query: (body) => {
        // If systemBetCombinationSize is present, it's a system bet
        const url = body.systemBetCombinationSize
          ? 'BetSlip/system'
          : 'BetSlip';

        return {
          url,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          dispatch(setBetslipProcessing(true));
          const { data } = await queryFulfilled;

          dispatch(setLastPlacedBetslipIds(data?.codes || []));
          dispatch(setBetslipProcessed(true));
          dispatch(resetBetslipChanged());
        } catch (err: any) {
          dispatch(setBetslipProcessingFailed(true));

          const status = err?.error?.status;
          const message = err?.error?.data?.message;

          if (status) {
            dispatch(setBetErrorCode(status));
          }

          if (message) {
            // If we have event error, message will be event id
            if (BETSLIP_EVENT_ERRORS.includes(status)) {
              dispatch(setBetErrorEvent(message?.[0]));
            } else {
              dispatch(setBetErrorMessage(message?.[0]));
            }
          }
        }
      },
    }),
    getOpenBets: builder.query<OpenedBets, void>({
      query: () => ({
        url: `BetSlip/openbets`,
        method: 'GET',
      }),
    }),
    getBetslipHistory: builder.query<
      BetslipHistoryResponse,
      BetslipHistoryPayload
    >({
      query: (params) => {
        const url = buildQueryUrl('BetSlip/profile', params);

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getBetslipByCode: builder.query<BetslipByCode, { code: string }>({
      query: (params) => {
        const url = buildQueryUrl('BetSlip/GetBetSlipByCode', params);

        return {
          url,
          method: 'GET',
        };
      },
    }),
    checkBalanceChangesOnBet: builder.mutation<
      BalanceChangeResponse,
      BalanceChangePayload
    >({
      query: (body) => ({
        url: 'BetSlip/CheckBalanceChangesOnBet',
        method: 'POST',
        body,
      }),
    }),
    cashOut: builder.mutation<void, CashOutPayload>({
      query: (body) => ({
        url: 'BetSlip/cashout',
        method: 'POST',
        body,
      }),
    }),
    balanceChangePrediction: builder.mutation<
      BalanceChangeResponse,
      BalancePredictionPayload
    >({
      query: ({ signal, separateBonusBalance, ...body }) => {
        return {
          url: `/BalanceChangePrediction${
            separateBonusBalance && !body?.isSystemBet ? '/separate' : ''
          }`,
          method: 'POST',
          body,
          signal,
        };
      },
    }),
    createJackpotBet: builder.mutation<JackpotBetResponse, JackpotBetPayload>({
      query: (body) => ({
        url: `BetSlip/jackpot`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useOpenBetMutation,
  useLazyGetOpenBetsQuery,
  useLazyGetBetslipHistoryQuery,
  useLazyGetBetslipByCodeQuery,
  useCheckBalanceChangesOnBetMutation,
  useCashOutMutation,
  useBalanceChangePredictionMutation,
  useCreateJackpotBetMutation,
} = betslipApi;
